import React from 'react';
import { PermissionsFormProvider } from 'context';

import { CreateAndEditRoleForm } from './createAndEditRole';
import { OnboardingHooks } from './hooks';
import { PermissionsTable } from './permissionsTable';
import { RolesTable } from './rolesTable';
import { RolesPageContainer } from './styles';
import { permissionsData } from './tableConfig';

export const RolesPermissions = () => {
  const [showPermissions, setShowPermissions] = React.useState(false);

  const {
    showEdit,
    editAction,
    setShowEdit,
    setEditingRole,
    editing,
    roleName,
    editingRoleData,
    refetch,
  } = OnboardingHooks();

  return (
    <RolesPageContainer data-testid="roles-permissions-page">
      <h1 className="header">Roles And Permissions</h1>
      {!showPermissions && (
        <RolesTable
          setShowEdit={setShowEdit}
          editAction={editAction}
          setShowPermissions={setShowPermissions}
        />
      )}

      {(showPermissions || showEdit) && (
        <PermissionsFormProvider permissionsData={editing ? editingRoleData : permissionsData}>
          {showEdit && !showPermissions && (
            <CreateAndEditRoleForm
              rolename={roleName}
              show={showEdit}
              isEditing={editing}
              setShow={setShowEdit}
              setEditingRole={setEditingRole}
              showPermissionTable={setShowPermissions}
            />
          )}

          {showPermissions && (
            <PermissionsTable
              setShow={setShowPermissions}
              setShowEdit={setShowEdit}
              isEditing={editing}
              refetch={refetch}
            />
          )}
        </PermissionsFormProvider>
      )}
    </RolesPageContainer>
  );
};
