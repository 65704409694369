import { useDrag, useDrop } from 'react-dnd';
import { IoCloseOutline } from 'react-icons/io5';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import cx from 'classnames';
import { useScoreCardContext } from 'context';
import { DND_TYPES } from 'utils';

import { css } from '@emotion/css';

function PipelineCard({ stage }) {
  const { title, id, colorCode } = stage;
  const { list, setList } = useScoreCardContext();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: DND_TYPES.ScoreCard,
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver(),
    }),
    drop: (d) => onDrop(d),
  });

  function onDrop(item) {
    const newList = list.map((listItem) => {
      return listItem.id === item.id ? { ...listItem, stage: id } : listItem;
    });
    setList(newList);
  }

  function onRemove(id) {
    const newList = list.map((listItem) => {
      return listItem.id === id ? { ...listItem, stage: null } : listItem;
    });
    setList(newList);
  }

  const related = list.filter((item) => item.stage === id);

  return (
    <Grid gap="8px" p="8px 16px" border="1px solid var(--grey-250)" borderRadius="4px" ref={drop}>
      <GridItem>
        <Box w="100%" h="5px" bgColor={colorCode} borderRadius="8px"></Box>
      </GridItem>
      <GridItem
        fontSize="1.4rem"
        fontWeight="600"
        display="grid"
        alignItems="center"
        gridTemplateColumns="max-content auto"
        gridAutoRows="max-content"
        gap="1.6rem">
        <Box>{title}</Box>
        <Flex
          className={cx(containerStyles, { isOver, canDrop })}
          h="100%"
          minH="35px"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          border="2px dashed var(--grey-150)"
          borderColor={canDrop ? 'var(--grey-300)' : ''}
          background={canDrop ? 'var(--grey-100)' : ''}>
          {related.map((item) => (
            <PlacedScoreCard key={item.title} item={item} onRemove={() => onRemove(item.id)} />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  );
}

function PlacedScoreCard({ item, onRemove }) {
  const [{ isDragging }, drag] = useDrag({
    item,
    type: DND_TYPES.ScoreCard,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <Box className={placedCardStyles} ref={drag} opacity={isDragging ? '0.3' : '1'}>
      {item.title}
      <div className="remove-btn" onClick={onRemove}>
        <IoCloseOutline />
      </div>
    </Box>
  );
}

const containerStyles = css`
  &:not(:empty):not(.isOver):not(.canDrop) {
    border-color: transparent;
  }

  &.isOver {
    background: var(--green-light);
    border-color: var(--green);
  }
`;

const placedCardStyles = css`
  position: relative;
  padding: 4px 32px 4px 16px;
  margin: 3px 8px 3px 0;
  border-radius: 2rem;
  border: 1px solid var(--grey-150);
  background-color: var(--bg);
  color: var(--grey-700);
  cursor: move;

  .remove-btn {
    display: none;
    position: absolute;
    right: 4px;
    top: 50%;
    padding: 2px;
    border-radius: 50%;
    background: var(--grey-600);
    color: white;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &:hover {
    .remove-btn {
      display: block;
    }
  }
`;

export default PipelineCard;
