export const columns = [
  {
    Header: 'Day',
    accessor: 'day',
  },
  {
    Header: 'Time',
    accessor: 'time',
  },
  {
    Header: 'In',
    accessor: 'in',
  },
  {
    Header: 'Out',
    accessor: 'out',
  },
];
export const tData = [
  {
    _id: 1,
    day: 'Fri, Jul 21 ',
    time: '8 Hours 46 Minutes',
    timeIn: '9:34 AM',
    timeOut: '5:34 PM',
  },
  {
    _id: 2,
    day: 'Fri, Jul 21 ',
    time: '8 Hours 46 Minutes',
    timeIn: '9:34 AM',
    timeOut: '5:34 PM',
  },
];
