import React from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';

import { LeftButton, RightButton } from './Buttons';
import { DayGrid } from './DayGrid';
import { getMonth, isValidDate } from './helpers';
import { MonthGrid } from './MonthGrid';
import { RangeDisplay } from './RangeDisplay';
import { YearGrid } from './YearGrid';

const useDate = (date) => (date && isValidDate(date) ? date : new Date());

function Calendar(props) {
  const { date, setDate = () => {}, isRange, toDate, setToDate } = props;

  const [month, setMonth] = React.useState(() => useDate(date).getMonth());
  const [year, setYear] = React.useState(() => useDate(date).getFullYear());
  const [day, setDay] = React.useState(() => useDate(date).getDate());
  const [active, setActive] = React.useState(() =>
    isRange && date && isValidDate(date) && !toDate ? 1 : 0
  );
  const firstRef = React.useRef(true);
  const [mode, setMode] = React.useState(() => 'day');
  const [tempYear, setTempYear] = React.useState(() => year);

  function goToPrevMonth() {
    if (month !== 0) setMonth((m) => m - 1);
    else {
      setMonth(11);
      setYear(year - 1);
    }
  }

  function goToNextMonth() {
    if (month !== 11) setMonth((m) => m + 1);
    else {
      setMonth(0);
      setYear(year + 1);
    }
  }

  function goToPrevYear() {
    if (tempYear !== 1970) {
      setTempYear((y) => y - 1);
    }
  }

  function goToNextYear() {
    setTempYear((y) => y + 1);
  }

  function goToPrevYearPage() {
    if (tempYear - 12 >= 1970) setTempYear((y) => y - 12);
  }

  function goToNextYearPage() {
    setTempYear((y) => y + 12);
  }

  function handleLeftButtonClick() {
    const actions = {
      day: goToPrevMonth,
      month: goToPrevYear,
      year: goToPrevYearPage,
    };
    actions[mode]();
  }

  function handleRightButtonClick() {
    const actions = {
      day: goToNextMonth,
      month: goToNextYear,
      year: goToNextYearPage,
    };
    actions[mode]();
  }

  React.useEffect(() => {
    if (isRange) {
      if (toDate && isValidDate(toDate) && date > toDate) setToDate(date);
    }
    if (firstRef.current && date) {
      setActive(1);
      firstRef.current = false;
    }
  }, [date]);

  React.useEffect(() => {
    if (isRange && isValidDate(toDate) && isValidDate(date) && toDate < date) setDate(toDate);
  }, [toDate]);

  function handleSelectDay(selectedDay) {
    setDay(selectedDay);
    const newDate = new Date(year, month, selectedDay);
    if (isRange) {
      [setDate, setToDate][active](newDate);
    } else {
      setDate(newDate);
    }
  }

  function handleActiveClick() {
    const moves = {
      day: () => {
        setMode('month');
        setTempYear(year);
      },
      month: () => setMode('year'),
      year: () => setMode('day'),
    };
    moves[mode]();
  }

  return (
    <CalendarRoot className={cx({ isRange })}>
      <div className="calendar-wrapper">
        <header className="calendar__header">
          <LeftButton onClick={handleLeftButtonClick} data-testid="left-btn" />
          <div
            className="calendar__active_month_year"
            onClick={handleActiveClick}
            data-testid="context-display">
            {mode === 'day' ? `${getMonth(month, 3)}-${year}` : null}
            {mode === 'month' ? `${tempYear}` : null}
            {mode === 'year' ? `${tempYear}-${tempYear + 11}` : null}
          </div>
          <RightButton onClick={handleRightButtonClick} data-testid="right-btn" />
        </header>

        {isRange ? (
          <RangeDisplay active={active} setActive={setActive} date={date} toDate={toDate} />
        ) : null}

        {mode === 'day' ? (
          <DayGrid
            day={day}
            month={month}
            year={year}
            selectedDate={date}
            selectedToDate={toDate}
            onSelectDay={handleSelectDay}
            isRange={isRange}
            active={active}
            setActive={setActive}
          />
        ) : null}

        {mode === 'month' ? (
          <MonthGrid
            year={year}
            month={month}
            setYear={setYear}
            setMonth={setMonth}
            tempYear={tempYear}
            setTempYear={setTempYear}
            setMode={setMode}
            date={[date, toDate][active]}
          />
        ) : null}

        {mode === 'year' ? (
          <YearGrid
            year={year}
            setYear={setYear}
            setMonth={setMonth}
            tempYear={tempYear}
            setTempYear={setTempYear}
            setMode={setMode}
            date={[date, toDate][active]}
          />
        ) : null}
      </div>
    </CalendarRoot>
  );
}

const CalendarRoot = styled.div`
  position: relative;
  width: 100%;
  padding-top: 110%;

  &.isRange {
    padding-top: 125%;
  }

  .calendar-wrapper {
    position: absolute;
    inset: 0;
    display: grid;
    grid: auto-flow max-content / 1fr;
    gap: 8px;
    font-size: 1.4rem;
    padding: 1rem;

    .calendar {
    }

    .calendar__header {
      display: flex;
      justify-content: space-between;
      padding-block: 0.6rem;
    }

    .calendar__active_month_year {
      height: 4rem;
      display: flex;
      align-items: center;
      padding: 0 2.4rem;
      border-radius: 2rem;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
`;
export { Calendar };
