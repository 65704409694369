import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import plus from '../../../assets/icons/plus copy.svg';
import { BaseButton } from '../../../components';
import { Tabs } from '../../../components/shared/tabs';

import { CreateGoal } from './goals/create-goals/CreateGoal';
import { CreateReview } from './reviews/CreateReview/CreateReview';
import { GoalsView } from './goals';
import { OverviewView } from './overview';
import { useAdminPerformance } from './PerformanceView.hooks';
import { ReviewsView } from './reviews';
import { CreateTemplate, TemplatesView } from './templates';

export const AdminPerformance = () => {
  const { selectedButton, setSelectedButton, tabs } = useAdminPerformance();
  const [showCreateReview, setShowCreateReview] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [showCreateGoal, setCreateGoal] = React.useState(false);
  const props = {
    setSelected: setSelectedButton,
    setShowCreateReview,
    setOpenModal,
    setCreateGoal,
  };

  return (
    <PageRoot>
      <Header>
        <Flex justifyContent="space-between" alignItems="center" mb="56px" minHeight="5.6rem">
          <h1>{selectedButton}</h1>
          {selectedButton === 'templates' ? (
            <BaseButton h="5.6rem" smp onClick={() => setOpenModal(true)}>
              <img src={plus} alt="" />
              Create template
            </BaseButton>
          ) : null}
          {selectedButton === 'goals' ? (
            <BaseButton h="5.6rem" smp onClick={() => setCreateGoal(true)}>
              <img src={plus} alt="" />
              Create Goals
            </BaseButton>
          ) : null}
          {selectedButton === 'reviews' ? (
            <BaseButton h="5.6rem" smp onClick={() => setShowCreateReview(true)}>
              <img src={plus} alt="" />
              Create review
            </BaseButton>
          ) : null}
        </Flex>
        <Tabs tabs={tabs} setSelected={setSelectedButton} selected={selectedButton} />
      </Header>
      <Content className="scroll-bar">
        {selectedButton === 'overview' ? <OverviewView {...props} /> : null}
        {selectedButton === 'reviews' ? <ReviewsView /> : null}
        {selectedButton === 'goals' ? <GoalsView /> : null}
        {selectedButton === 'templates' ? <TemplatesView /> : null}
      </Content>
      {showCreateReview ? (
        <CreateReview showModal={showCreateReview} setShowModal={setShowCreateReview} />
      ) : null}
      {showCreateGoal ? (
        <CreateGoal showModal={showCreateGoal} setShowModal={setCreateGoal} />
      ) : null}
      {openModal ? <CreateTemplate openModal={openModal} setOpenModal={setOpenModal} /> : null}
    </PageRoot>
  );
};

const PageRoot = styled.div`
  height: calc(100% - 108px);
  padding: 0 24px;
`;

const Header = styled.div`
  border-bottom: 1px solid var(--grey-150);
  position: sticky;
  top: 0;
  background: var(--bg);
  z-index: 10;
  padding-top: 2.4rem;

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .tab-btn-toggle {
    :hover {
      border-bottom: 1px solid #3e7eee;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 144px);
  overflow-y: auto;
  padding-block: 24px;
`;
