import React from 'react';
import stringHelpers from 'utils/helpers/string-helpers';

import myCountries from '../data/countries-states-cities.json';

export function useCountriesStatesCities({ country, state, setValue, stateName, cityName }) {
  const countryRef = React.useRef(country);
  const stateRef = React.useRef(state);

  const countries = [];
  Object.values(myCountries).forEach((cty) => {
    countries.push({
      value: cty.name,
      label: cty.name,
    });
  });
  const states = React.useMemo(() => {
    const selectedCountry = Object.values(myCountries).find((c) => c.name === country?.value);
    return selectedCountry
      ? Object.keys(selectedCountry?.states)?.map((st) => ({
          value: st,
          label: st,
        }))
      : [];
  }, [country]);

  const cities = React.useMemo(() => {
    const selectedCountry = Object.values(myCountries).find((c) => c.name === country?.value);
    return selectedCountry && state
      ? (
          selectedCountry?.states[state.value] ||
          selectedCountry?.states[stringHelpers.titleCase(state.value)]
        )?.map((cityName) => ({
          label: cityName,
          value: cityName,
        }))
      : [];
  }, [state]);

  React.useEffect(() => {
    if (country && country !== countryRef.current) {
      if (stateName && setValue) setValue(stateName, null);
      if (cityName && setValue) setValue(cityName, null);
      countryRef.current = country;
    }
  }, [country]);

  React.useEffect(() => {
    if (state && state !== stateRef.current) {
      if (cityName && setValue) setValue(cityName, null);
      stateRef.current = state;
    }
  }, [state]);
  return { states, cities, countries };
}
