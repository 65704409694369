export function getFilters(obj, arr) {
  if (arr === null || arr === undefined) return [];
  const result = arr.map((el) => {
    return {
      ...el,
      value: modifyObj(obj, el.type)[el.name] || null,
    };
  });
  return result;
}

function modifyObj(data, type) {
  let obj = {};
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'object' && type === 'Date Picker' && value !== null) {
      obj[key] = `${value.from},${value.to}`;
    } else if (typeof value === 'object' && type === 'Dropdown' && value !== null) {
      obj[key] = value.value;
    } else {
      obj[key] = value;
    }
  });

  return obj;
}

export function includeLabel(data) {
  let arr = [];

  for (let item of data) {
    let obj = {
      label: item,
      value: item,
    };

    arr.push(obj);
  }

  return arr;
}

export function getObj(arr) {
  let obj = {};
  if (arr === undefined || arr === null) return obj;

  for (let item of arr) {
    if (item.value !== null) {
      if (item.type === 'Date Picker') {
        obj[item.name] = {
          from: item.value.split(',')[0],
          to: item.value.split(',')[1],
        };
      } else {
        obj[item.name] = item.value;
      }
    }
  }

  return obj;
}

export function removeFields(obj, arr) {
  let keys = [];
  let object = {};
  for (let item of arr) {
    keys.push(item.name);
  }

  Object.entries(obj).forEach(([key, value]) => {
    if (keys.includes(key)) {
      object[key] = value;
    } else {
      object[key] = null;
    }
  });

  return object;
}

// export function getDate(date) {

// }
