import React from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const ProgressText = styled(Text)`
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.0012em;
  color: #83889d;
`;

const ProgressBar = ({ bgcolor, completed, withoutGoals }) => {
  const containerStyles = {
    height: 12,
    width: '100%',
    backgroundColor: '#F3F4F6',
    borderRadius: 20,
  };

  const fillerStyles = {
    height: '100%',
    transition: 'width 1s ease-in-out',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  return (
    <Grid gap={'8px'} width={'100%'}>
      {!withoutGoals ? (
        <Flex justifyContent={'space-between'}>
          <ProgressText>
            <Box as={'span'} color={'#23273B'}>
              Current
            </Box>
            : {completed} %
          </ProgressText>
          <ProgressText>
            <Box as={'span'} color={'#23273B'}>
              Shared Goals
            </Box>
            : 100%
          </ProgressText>
        </Flex>
      ) : null}
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </div>
    </Grid>
  );
};

export { ProgressBar };
