export const ReviewtableColumns = [
  {
    Header: 'Review Title',
    accessor: 'col1',
  },
  {
    Header: 'Date Created',
    accessor: 'col2',
  },
  {
    Header: 'Cycle Period',
    accessor: 'col3',
  },
  {
    Header: 'Status',
    accessor: 'col4',
  },
];

export const ReviewtableData = [
  {
    _id: 'jehkhadgfshagdhfjagsw',
    reviewTitle: 'Enyata 2022 product team Q3 Appraisal ',
    dates: '01 March, 2019',
    cyclePeriod: '01 March, 2019 - 01 March, 2019',
    status: 'completed',
  },
  {
    _id: 'jehkhadgfshagdhfjgsw',
    reviewTitle: 'Enyata 2022 product team Q3 Appraisal ',
    dates: '01 March, 2019',
    cyclePeriod: '01 March, 2019 - 01 March, 2019',
    status: 'upcoming',
  },
  {
    _id: 'jehkhadgfshadhfjagsw',
    reviewTitle: 'Enyata 2022 product team Q3 Appraisal ',
    dates: '01 March, 2019',
    cyclePeriod: '01 March, 2019 - 01 March, 2019',
    status: 'overdue',
  },
  {
    _id: 'jehkhadgfshagdhfjagswr',
    reviewTitle: 'Enyata 2022 product team Q3 Appraisal ',
    dates: '01 March, 2019',
    cyclePeriod: '01 March, 2019 - 01 March, 2019',
    status: 'in progress',
  },
];
