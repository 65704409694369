import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useApplicantForm, useApplicantsFormSections } from 'context';

import { INPUT_TYPES } from '../helpers';

import { EditableInputField } from './EditableInputField';
import { EmptySpace } from './EmptySpace';

function EditableInputFieldList({ name, inSection, parentField, parentUpdate, parentIndex }) {
  const { control } = useApplicantForm();
  const { fields, update, append, insert, remove } = useFieldArray({ control, name });
  const [insertIndex, setInsertIndex] = React.useState(null);

  const { appendRef } = useApplicantsFormSections();
  if (name === 'fields') appendRef.current = append;

  const parent = { parentIndex, parentField, parentUpdate };

  let modUpdate = modify(update, parent, updateChild);
  let modInsert = modify(insert, parent, insertChild);
  let modAppend = modify(append, parent, appendChild);
  let modRemove = modify(remove, parent, removeChild);

  return (
    <>
      {fields.map((field, index) => {
        const isSection = field.type === INPUT_TYPES.Section;
        const prevNotSection = index !== 0 && fields[index - 1].type !== INPUT_TYPES.Section;
        const showEmptySpace = isSection && prevNotSection;
        if (showEmptySpace && index !== insertIndex) setInsertIndex(index);
        return (
          <React.Fragment key={field.id}>
            {!inSection && showEmptySpace && (
              <EmptySpace append={modAppend} insert={insert} index={insertIndex} />
            )}
            <EditableInputField
              index={index}
              field={field}
              fieldName={`${name}.${index}`}
              update={modUpdate}
              remove={modRemove}
              insert={modInsert}
              control={control}
              inSection={inSection}
            />
          </React.Fragment>
        );
      })}
      {inSection && <EmptySpace append={modAppend} insert={insert} inSection={inSection} />}
    </>
  );
}

function modify(func, parent, callback) {
  const { parentField, parentUpdate, parentIndex } = parent;

  const updateParentChildren = (children) => {
    parentUpdate(parentIndex, { ...parentField, children: children });
  };

  const children = parentField?.children ? parentField.children : [];
  return (...args) => {
    if (parentField && parentUpdate) {
      const newChildren = callback(children, args);
      updateParentChildren(newChildren);
    } else {
      func(...args);
    }
  };
}

function updateChild(children, args) {
  const [index, field] = args;
  return children.map((child, i) => (i === index ? field : child));
}

function insertChild(children, args) {
  const [index, field] = args;
  let newChildren = [...children];
  if (children.length === index) newChildren = [...children, field];
  else newChildren.splice(index, 0, field);
  return newChildren;
}

function appendChild(children, args) {
  const [field] = args;
  return [...children, field];
}

function removeChild(children, args) {
  const [index] = args;
  let newChildren = [...children];
  newChildren.splice(index, 1);
  return newChildren;
}

export { appendChild, EditableInputFieldList, insertChild, removeChild, updateChild };
