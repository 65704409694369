import React from 'react';
import { useFieldArray } from 'react-hook-form';
import {} from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ConfigureSection, DefaultInputFields, SectionArea } from './component';

export const AssessmentContent = ({ defaultData, control, reset, register }) => {
  const { fields, remove, append } = useFieldArray({ control, name: 'sections' });

  const addSection = () => {
    append();
  };

  React.useEffect(() => {
    reset(defaultData);
  }, [defaultData]);

  return (
    <>
      <Root className="scroll-bar" data-testid="assessment-content">
        <DefaultInputFields register={register} />

        {fields.map((field, index) => {
          return (
            <SectionArea
              key={field.id}
              register={register}
              control={control}
              index={index}
              removeSection={() => remove(index)}
            />
          );
        })}
      </Root>
      <ConfigureSection addNewSection={addSection} />
    </>
  );
};

const Root = styled.div`
  width: 100%;
  height: 568px;
  border: 1px solid #f2f2f2;
  padding: 2.4rem 2.4rem 8rem 2.4rem;
  overflow-y: auto;
`;
