import { useState } from 'react';
// import { useReturnStateContext } from 'context';

function LeaveLayoutHook() {
  const [showModal, setShowModal] = useState(false);

  const [selectedButton, setSelectedButton] = useState('requests');

  let tabs = [
    { displayName: `REQUESTS`, value: 'requests' },
    { displayName: `TYPES & POLICIES `, value: 'policies' },
    { displayName: `SETTINGS `, value: 'settings' },
  ];

  const Tabs = {
    requests: 'requests',
    policies: 'policies',
    settings: 'settings',
  };

  const setSelected = (tab) => {
    Object.keys(Tabs).forEach((key) => {
      if (Tabs[key] === tab) {
        setSelectedButton(Tabs[key]);
      }
    });
  };

  function createLeaveType() {
    setShowModal(true);
  }

  return {
    tabs,
    setSelected,
    selectedButton,
    createLeaveType,
    showModal,
    setShowModal,
  };
}

export { LeaveLayoutHook };
