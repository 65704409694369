import React from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { FETCH_NOTIFICATIONS } from 'pages/employee/dashboard/query';

import { NotificationDropdown } from './NotificationDropdown';

const NotificationIcon = () => {
  const [showModal, setShowModal] = React.useState();
  const { data } = useQuery(FETCH_NOTIFICATIONS, {
    variables: {
      filter: {
        status: 'recent',
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const allMessages = data?.fetchNotifications?.data;
  const check = allMessages?.some((allMessages) => allMessages?.readAt === null);

  return (
    <>
      <Wrap onClick={() => setShowModal((prev) => !prev)}>
        {check && (
          <>
            <svg
              width="18"
              height="24"
              viewBox="0 0 18 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0008 4.06837C9.68305 4.02319 9.36311 4 9.04423 4H8.95577C6.91935 4 4.86106 4.94167 3.577 6.5128C2.71333 7.58842 2.29293 8.68822 2.29293 10.3701V10.7972C2.29293 12.0531 1.98284 12.7923 1.23049 13.6453C0.676907 14.2738 0.5 15.0815 0.5 15.9557C0.5 16.8309 0.787226 17.6598 1.36367 18.3336C2.11602 19.1413 3.17846 19.6569 4.26375 19.7466C5.83505 19.9258 7.40634 19.9933 9.0005 19.9933C10.5937 19.9933 12.165 19.8805 13.7372 19.7466C14.8215 19.6569 15.884 19.1413 16.6363 18.3336C17.2118 17.6598 17.5 16.8309 17.5 15.9557C17.5 15.0815 17.3231 14.2738 16.7695 13.6453C16.039 12.7923 15.7071 12.0531 15.7071 10.7972V10.3701C15.7071 8.73354 15.3304 7.67907 14.5115 6.62459C14.5057 6.61708 14.4999 6.60958 14.494 6.6021C14.0541 6.85522 13.544 7 13 7C11.366 7 10.0371 5.69365 10.0008 4.06837Z"
                fill="var(--primary)"
              />
              <path
                opacity="0.4"
                d="M11.0097 21.2285C10.5098 21.1217 7.46364 21.1217 6.96372 21.2285C6.53636 21.3272 6.07422 21.5568 6.07422 22.0604C6.09907 22.5408 6.38033 22.9648 6.76992 23.2337L6.76893 23.2347C7.27282 23.6275 7.86416 23.8773 8.48334 23.9669C8.8133 24.0122 9.14923 24.0102 9.49111 23.9669C10.1093 23.8773 10.7006 23.6275 11.2045 23.2347L11.2035 23.2337C11.5931 22.9648 11.8744 22.5408 11.8992 22.0604C11.8992 21.5568 11.4371 21.3272 11.0097 21.2285Z"
                fill="#999FA3"
              />
            </svg>
            <div className={'animate_circle'}></div>
          </>
        )}
      </Wrap>

      <NotificationWrapper showModal={showModal}>
        <NotificationDropdown closeModal={() => setShowModal(false)} />
      </NotificationWrapper>
    </>
  );
};

const Wrap = styled.div`
  position: relative;
  .animate_svg {
    z-index: 7;
  }

  .animate_circle {
    background: #fa4a4a;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    animation: animationBell 1000ms infinite;
  }
  .circle {
    background: #fa4a4a;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }

  @keyframes animationBell {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    20.0% {
      transform: rotate(10deg);
      opacity: 1;
    }
    40.0% {
      transform: rotate(16deg);
      opacity: 1;
    }
    62.0% {
      transform: scale(1.25);
      opacity: 1;
    }
    80.0% {
      transform: scale(1.4);
      opacity: 1;
    }
  }
`;

export default NotificationIcon;

const NotificationWrapper = styled.div`
  background-color: var(--white);
  display: ${(props) => (props.showModal ? 'block' : 'none')};
  position: absolute;
  top: 50px;
  border-radius: 8px;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);

  @media screen and (max-width: 450px) {
    left: 15px;
    right: 15px;
    .notification__content {
      max-width: 100%;
    }
  }
`;
