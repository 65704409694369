export function formatQuestions(qst) {
  const { correctAnswers, options, points, question, files, questionType, attachedFiles } = qst;
  if (qst?.files?.length > 0) {
    return {
      correctAnswers: correctAnswers,
      options: options?.map((opt) => {
        return opt?.value;
      }),
      points: Number(points),
      question: question,
      questionType: questionType,
      files: files,
      attachedFiles: attachedFiles,
    };
  } else {
    return {
      correctAnswers: correctAnswers,
      options: options?.map((opt) => {
        return opt?.value;
      }),
      points: Number(points),
      question: question,
      questionType: questionType,
      attachedFiles: attachedFiles,
    };
  }
}
export function revertFormatQuestions(qst) {
  const { correctAnswers, options, points, question, questionType, type, attachedFiles } = qst;
  if (qst?.attachedFiles?.length > 0) {
    return {
      correctAnswers: correctAnswers,
      options: options?.map((opt) => {
        return { value: opt };
      }),
      isEditing: true,
      points: Number(points),
      question: question,
      questionType: questionType || type,
      files: qst?.files,
      attachedFiles: attachedFiles,
    };
  } else {
    return {
      correctAnswers: correctAnswers,
      options: options?.map((opt) => {
        return { value: opt };
      }),
      isEditing: true,
      points: Number(points),
      question: question,
      questionType: questionType || type,
      attachedFiles: attachedFiles,
    };
  }
}
