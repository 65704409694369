import { gql } from '@apollo/client';

export const CREATE_TASK = gql`
  mutation createTask($data: TaskInput!) {
    createTask(data: $data) {
      status
      message
      data {
        _id
        title
      }
    }
  }
`;

export const FETCH_ALL_TASKS = gql`
  query fetchAllTasks($filter: TaskFilter!) {
    fetchAllTasks(filter: $filter) {
      status
      message
      totalCount {
        totalCount
        completedCount
      }
      data {
        _id
        title
        date
        endTime
        startTime
        description
        completed
        daysToRepeat
        priority
        repeatInterval
        createdBy {
          _id
        }
      }
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation mutation($id: ID!, $data: TaskUpdateInput!) {
    updateTaskByID(id: $id, data: $data) {
      status
      message
      data {
        _id
        completed
      }
    }
  }
`;
