import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_JOBS } from 'query';

function useApplicationView() {
  const [page, setPage] = useState(1);
  let sortingList = [
    { label: 'Title', value: 'title' },
    { label: 'Department', value: 'department' },
    { label: 'City', value: 'city' },
  ];

  let statusList = [
    { label: 'All Status', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Closed', value: 'closed' },
  ];

  const [sortedField, setSortedField] = useState('title');
  const [statusField, setStatusField] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  let [applications, setApplications] = useState();
  let [length, setLength] = useState(0);

  const { data, loading, refetch } = useQuery(FETCH_JOBS, {
    variables: {
      filter: { ...(statusField ? { status: statusField } : {}) },
      page: page,
    },
    fetchPolicy: 'network-only',
    onError: (err) => {
      const errorResponse = err.graphQLErrors.find((item) => item.status === 403);
      setErrorMessage(errorResponse.message);
    },
  });

  function responseToNull() {
    if (errorMessage) return;
    const fetchjobs = data?.fetchJobs?.data;
    const totalPages = Math.ceil(length / 10);

    return {
      fetchjobs,
      hasNextPage,
      totalPages,
    };
  }
  const hasNextPage = useMemo(() => data?.fetchJobs?.hasNextPage);

  const sortJobs = (applications) => {
    if (sortedField || applications?.length) {
      const newState = applications?.slice()?.sort((a, b) => {
        if (sortedField !== 'department') {
          if (a[sortedField]?.toLowerCase() < b[sortedField]?.toLowerCase()) {
            return -1;
          }
          if (a[sortedField]?.toLowerCase() > b[sortedField]?.toLowerCase()) {
            return 1;
          }
        } else {
          if (a.department?.name.toLowerCase() < b.department?.name.toLowerCase()) {
            return -1;
          }
          if (a.department?.name.toLowerCase() > b.department?.name.toLowerCase()) {
            return 1;
          }
        }
        return 0;
      });
      setApplications(newState);
    }
  };

  useEffect(() => {
    refetch();

    responseToNull();
    if (!errorMessage) return sortJobs(data?.fetchJobs?.data);
    setLength(data?.fetchJobs?.totalCount);
  }, [data, sortedField, statusField, errorMessage]);

  return {
    applications,
    length,
    loading,
    sortingList,
    statusList,
    statusField,
    sortedField,
    hasNextPage,
    totalPages: responseToNull.totalPages,
    setPage,
    page,
    setSortedField,
    setStatusField,
    errorMessage,
  };
}

export { useApplicationView };
