import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import arrow from 'assets/icons/arrow-left.svg';
import tree from 'assets/icons/coconut-tree.svg';
import plus from 'assets/icons/plusLarge.svg';
import { FETCH_EMPLOYEE_LEAVE, FETCH_LEAVE_HISTORY } from 'query';
import { formatDate } from 'utils';

import { CustomButton, EmptyState, SpinnerComponent } from 'components';

import { LeaveItem } from './LeaveItem';
import { LeaveRequestsTable } from './RequestsTable';

export const EmployeeLeave = () => {
  const leaveItemsRef = React.useRef();
  const navigate = useNavigate();

  function handleRightClick() {
    const rightScroll = leaveItemsRef.current.scrollLeft;
    leaveItemsRef.current.scrollLeft = rightScroll + 246;
  }
  function handleLeftClick() {
    const leftScroll = leaveItemsRef.current.scrollLeft;
    leaveItemsRef.current.scrollLeft = leftScroll - 246;
  }

  const { data, refetch, loading } = useQuery(FETCH_LEAVE_HISTORY);
  const leaveHistory = data?.fetchUserLeaveTypes?.data;

  const { data: employeeLeave } = useQuery(FETCH_EMPLOYEE_LEAVE);
  const fetchedData = employeeLeave?.fetchUserLeaveRequests?.data;

  const disablebtn = fetchedData?.some((fetchedData) =>
    fetchedData?.leaveStatus?.includes('pending')
  );

  React.useEffect(() => {
    refetch();
  }, []);

  const requestChange = () => {
    const leave = fetchedData?.find((one) => one?.leaveStatus?.includes('pending'));

    navigate(`/employee/leave-request/${leave?._id}`);
  };

  return (
    <LeaveRoot data-testid="employee-leave">
      <div className="content">
        <div className="header">
          <h1 className="heading">Leave Management</h1>
          <CustomButton
            onClick={() => navigate('/employee/leave-request')}
            name="New Request"
            variant="primary"
            h="4.8rem"
            mW="152px"
            src={plus}
          />
        </div>
        <div className="top__content">
          <div className="top__content--left">
            <Text fontWeight={'700'} fontSize="1.4rem" lineHeight={'2rem'}>
              Your next leave date is
            </Text>
            <div className="tree-wrapper">
              <img src={tree} alt="tree" />
              <div>
                <Text className="small-text">from</Text>
                {loading ? (
                  <div className={'date__loading'}></div>
                ) : (
                  <Text className={'date'}>
                    {formatDate(leaveHistory?.[0]?.nextLeaveDate?.startDate) || 'N/A'}
                  </Text>
                )}
                <Text className="small-text">to</Text>
                {loading ? (
                  <div className={'date__loading'}></div>
                ) : (
                  <Text className={'date'}>
                    {formatDate(leaveHistory?.[0]?.nextLeaveDate?.endDate) || 'N/A'}
                  </Text>
                )}
              </div>
            </div>
            <button disabled={!disablebtn} className="btn" type="button" onClick={requestChange}>
              Request for Change
            </button>
          </div>

          <div className="top__content--right">
            {loading ? (
              <SpinnerComponent />
            ) : (
              <>
                {leaveHistory?.length > 3 && (
                  <img
                    src={arrow}
                    data-testid="left-arr"
                    alt="arr"
                    className="arrow-left"
                    onClick={handleLeftClick}
                  />
                )}
                <div className="top__content--right-items" ref={leaveItemsRef}>
                  {leaveHistory?.length === 0 ? (
                    <EmptyState message="No Leave History Found" h="30vh" />
                  ) : (
                    <>
                      {leaveHistory?.map((item, i) => {
                        return <LeaveItem key={i} data={item} />;
                      })}
                    </>
                  )}
                </div>
                {leaveHistory?.length > 3 && (
                  <img
                    onClick={handleRightClick}
                    src={arrow}
                    alt="arr"
                    className="arrow-right"
                    data-testid="right-arr"
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="bottom__content">
          <LeaveRequestsTable />
        </div>
      </div>
    </LeaveRoot>
  );
};

const LeaveRoot = styled.div`
  width: 100%;
  height: calc(100vh - 8.4rem);
  padding: 24px;

  .content {
    background: var(--white);
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    height: calc(100vh - 8.4rem);
    padding: 0rem 2.4rem 3rem;
    overflow-y: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--white);
      position: sticky;
      top: 0;
      margin-bottom: 4rem;
      padding: 2.4rem 0 0.8rem 0;
      z-index: 1;
      .heading {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3.3rem;
        color: var(--black);
      }
    }

    .top__content {
      width: 100%;
      display: grid;
      grid: 1fr 1fr / max-content;
      grid-gap: 4rem;
      overflow-x: hidden;
      @media (min-width: 1100px) {
        grid: 1fr / max-content auto;
      }

      &--right {
        position: relative;
        padding-left: 2rem;
        width: 100%;
        padding-right: 3rem;
        height: 260px;
        @media (min-width: 1100px) {
          max-width: 730px;
          width: 100%;
          padding-right: 0;
        }

        .arrow-left {
          position: absolute;
          left: -0.6rem;
          top: 10.5rem;
          cursor: pointer;
          padding: 0.8rem;
        }

        .arrow-right {
          position: absolute;
          right: -2.6rem;
          top: 10.5rem;
          transform: rotate(180deg);
          cursor: pointer;
          padding: 0.8rem;
        }

        &-items {
          display: flex;
          gap: 1.6rem;
          overflow-x: auto;
          width: 100%;
          /* Hide scrollbar for Chrome, Safari and Opera */
          ::-webkit-scrollbar {
            display: none;
          }

          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
      }

      &--left {
        width: max-content;
        min-width: 300px;
        height: 248px;
        background: var(--blue);
        border-radius: 4px;
        color: var(--white);
        padding: 1.6rem;
        display: grid;
        grid-gap: 2.2rem;

        .tree-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .small-text {
            fontsize: 1.2rem;
            letter-spacing: -0.808889px;
          }

          .date {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.808889px;
          }

          .date__loading {
            position: relative;
            width: 100%;
            height: 24px;
            background: rgb(235, 235, 235);
            overflow: hidden;
          }

          .date__loading::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: -32px;
            background: rgba(255, 255, 255, 0.9);
            filter: blur(5px);
            animation: suspense 0.7s ease infinite;
          }
        }
        .btn {
          width: 100%;
          height: 4rem;
          background: var(--white);
          border-radius: 3px;
          color: var(--black);
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          justify-self: flex-end;
          &:disabled {
            cursor: not-allowed;
            :hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
`;
