import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ROLES_FOR_DROPDOWN } from 'query';

function AdminOptions() {
  const [roleOptions, setRoleOptions] = React.useState([]);

  const { data, refetch } = useQuery(GET_ROLES_FOR_DROPDOWN);
  React.useEffect(() => {
    const Rdata = data?.fetchRolesFiltered?.data?.map(function (item) {
      return {
        label: item.rolename,
        value: item._id,
      };
    });
    setRoleOptions(Rdata);
  }, [data]);

  return {
    roleOptions,
    refetch,
  };
}

export { AdminOptions };
