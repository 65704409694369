import React, { useState } from 'react';
import { Stack } from '@chakra-ui/react';

import { SimpleModal } from 'components';

import EditBankAccountInfo from './Modals/EditBankAccountInfo';
import { InfoContainer } from './InfoContainer';

function JobnPay({ profileDetails, profileData, userId }) {
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal((prev) => !prev);
  }
  return (
    <>
      <Stack gap={'2.4rem'}>
        <InfoContainer label={'Employment Information'} isEditable={false} data={profileDetails} />
        <InfoContainer
          label={'Bank Account Information'}
          isEditable={true}
          data={profileDetails}
          openModal={toggleModal}
        />
        <InfoContainer label={'Current Compensation'} isEditable={false} data={profileDetails} />
      </Stack>

      <SimpleModal showModal={showModal}>
        <EditBankAccountInfo
          closeModal={toggleModal}
          bankAccountInfo={profileData?.bankAccount}
          userId={userId}
        />
      </SimpleModal>
    </>
  );
}

export default JobnPay;
