import {
  CREATE_REVIEW,
  FETCH_ALL_EMPLOYEES,
  FETCH_ALL_TEAMS,
  RETRIEVE_BULK_EMPLOYEES,
} from '../../../../../query';

export const mocks = [
  {
    request: {
      query: RETRIEVE_BULK_EMPLOYEES,
      variables: { employeeIds: ['637b44faa460a83766a846d7', '637234c24ee82c08f6fe98f2'] },
    },
    result: {
      data: {
        retrieveBulkEmployees: {
          status: 200,
          message: 'Request was successfully processed',
          data: [
            {
              _id: '637234c24ee82c08f6fe98f2',
              firstName: 'salma',
              lastName: 'sali',
              imageUrl: null,
              lineManager: null,
            },
            {
              _id: '637b44faa460a83766a846d7',
              firstName: 'Kwabena',
              lastName: 'Agyapong',
              imageUrl:
                'https://photow-profile-images.s3.amazonaws.com/1669207496768XcSXwdNugfrUq_RbVFIJ2.png',
              lineManager: null,
            },
          ],
        },
      },
    },
  },
  {
    request: { query: FETCH_ALL_TEAMS, variables: {} },
    result: {
      data: {
        fetchAllTeams: {
          status: 200,
          message: 'Resource fetched successfully',
          data: [],
        },
      },
    },
  },
  {
    request: {
      query: FETCH_ALL_EMPLOYEES,
      variables: {
        filters: {
          isDefaultAdmin: false,
        },
        page: 1,
      },
    },
    result: {
      data: {
        fetchUsersFiltered: {
          status: 200,
          message: 'Resource fetched successfully',
          hasNextPage: false,
          pageCount: 1,
          totalCount: 3,
          data: [
            {
              _id: '637f5602c4189bfab704cdff',
              firstName: 'Adam',
              lastName: 'Smith',
              status: 'active',
              imageUrl: null,
              email: 'developer.anane@gmail.com',
              phoneNumber: '+2330541312238',
              team: 'Code 4 Fun',
              isDefaultAdmin: false,
              employmentInfo: {
                hireDate: '2022-02-14T00:00:00.000Z',
              },
              department: {
                name: 'Engineering',
                designation: [
                  {
                    name: 'Frontend',
                  },
                  {
                    name: 'Backend',
                  },
                  {
                    name: 'Devops',
                  },
                  {
                    name: 'Quality Assurance',
                  },
                ],
              },
            },
            {
              _id: '637f5541c4189bfab704cdf8',
              firstName: 'Gawusu',
              lastName: 'Razak',
              status: 'active',
              imageUrl:
                'https://photow-profile-images.s3.us-west-2.amazonaws.com/1669298900292E6U3l3g3lkAeS8LRwzDsD.png',
              email: 'gawusurazak@enyata.com',
              phoneNumber: '+2330207940815',
              team: 'Code For Fun',
              isDefaultAdmin: false,
              employmentInfo: {
                hireDate: '2022-09-21T00:00:00.000Z',
              },
              department: {
                name: 'Engineering',
                designation: [
                  {
                    name: 'Frontend',
                  },
                  {
                    name: 'Backend',
                  },
                  {
                    name: 'Devops',
                  },
                  {
                    name: 'Quality Assurance',
                  },
                ],
              },
            },
            {
              _id: '637f5230c4189bfab704cdc2',
              firstName: 'kwabena',
              lastName: 'kwabena',
              status: 'active',
              imageUrl: null,
              email: 'kwabenaagyapong@enyata.com',
              phoneNumber: '+233323567809',
              team: 'Code for fun',
              isDefaultAdmin: false,
              employmentInfo: {
                hireDate: '2022-11-22T00:00:00.000Z',
              },
              department: {
                name: 'Engineering',
                designation: [
                  {
                    name: 'Frontend',
                  },
                  {
                    name: 'Backend',
                  },
                  {
                    name: 'Devops',
                  },
                  {
                    name: 'Quality Assurance',
                  },
                ],
              },
            },
          ],
        },
      },
    },
  },
  {
    request: {
      query: CREATE_REVIEW,
      variables: {
        data: {
          frequency: 'monthly',
          isSeenByAll: true,
          isSeenByReviewee: false,
          reviewCycle: [
            {
              employee: '637b44faa460a83766a846d7',
              reviewers: [
                '637234c24ee82c08f6fe98f2',
                '63764a6dcba003249bb29e0e',
                '63722e404ee82c08f6fe9832',
              ],
            },
            {
              employee: '637234c24ee82c08f6fe98f2',
              reviewers: ['63764a6dcba003249bb29e0e', '63722e404ee82c08f6fe9832'],
            },
          ],
          reviewPeriod: {
            endDate: '2022-11-30',
            startDate: '2022-11-25',
          },
          reviewType: [
            {
              description: 'Employee completes a self-evaluation of themselves',
              name: 'Self Evaluation',
              score: 30,
            },
            {
              description: 'Reviewers complete a performance evaluation for thier direct reports.',
              name: "Manager's Review",
              score: 40,
            },
            {
              description:
                'Employee completes an evaluation of their peers, teammates and colleagues',
              name: 'Peer Review',
              score: 30,
            },
          ],
          schedule: {
            endDate: '2022-11-30',
            startDate: '2022-11-23',
          },
          templateId: '637cf19138e954aaf360d638',
          title: 'Test Review',
        },
      },
    },
    result: {
      data: {
        addReview: {
          status: 201,
          message: 'Resource created successfully',
          data: {
            _id: '638093dcdee3d5239b38618c',
            title: 'Test Review',
            frequency: 'monthly',
            schedule: {
              startDate: '2022-11-23',
              endDate: '2022-11-30',
            },
            reviewPeriod: {
              startDate: '2022-11-25',
              endDate: '2022-11-30',
            },
          },
        },
      },
    },
  },
];

export const reviewCycleFormTestDefaults = {
  isSeenByAll: true,
  isSeenByReviewee: false,
  title: 'Test Review',
  frequency: {
    value: 'monthly',
    label: 'Monthly',
  },
  schedule: {
    startDate: '2022-11-23',
    endDate: '2022-11-30',
  },
  reviewPeriod: {
    startDate: '2022-11-25',
    endDate: '2022-11-30',
  },
  team: {
    value: 'Engineering team',
    label: 'Engineering team',
  },
  templateId: {
    value: '637cf19138e954aaf360d638',
    label: 'Q1 Review Template',
  },
  reviewType: [
    {
      name: 'Self Evaluation',
      description: 'Employee completes a self-evaluation of themselves',
      score: '30',
    },
    {
      name: "Manager's Review",
      description: 'Reviewers complete a performance evaluation for thier direct reports.',
      score: '40',
    },
    {
      name: 'Peer Review',
      description: 'Employee completes an evaluation of their peers, teammates and colleagues',
      score: '30',
    },
  ],
  reviewCycle: [
    {
      employee: '637b44faa460a83766a846d7',
      reviewers: [
        {
          _id: '637234c24ee82c08f6fe98f2',
          firstName: 'salma',
          lastName: 'sali',
          status: 'suspended',
          imageUrl: null,
          email: 'salma@enyata.com',
          phoneNumber: '+233127834924',
          team: 'Code for fun',
          isDefaultAdmin: false,
          employmentInfo: {
            hireDate: '2022-11-03T00:00:00.000Z',
          },
          isSelected: true,
        },
        {
          _id: '63764a6dcba003249bb29e0e',
          firstName: 'ammiel',
          lastName: 'yawson',
          status: 'active',
          imageUrl:
            'https://photow-profile-images.s3.us-west-2.amazonaws.com/1669132209365jtZuCebyc_ALgJPbTMIMg.jpg',
          email: 'ammiel@enyata.com',
          phoneNumber: '+233558249492',
          team: 'Knight',
          isDefaultAdmin: false,
          employmentInfo: {
            hireDate: '2022-11-08T00:00:00.000Z',
          },
          isSelected: true,
        },
        {
          _id: '63722e404ee82c08f6fe9832',
          firstName: 'Razak',
          lastName: 'Gawusu',
          status: 'pending',
          imageUrl: null,
          email: 'gawusurazak@enyata.com',
          phoneNumber: '+233321872173',
          team: 'Code for fun',
          isDefaultAdmin: false,
          employmentInfo: {
            hireDate: '2022-10-03T00:00:00.000Z',
          },
          isSelected: true,
        },
      ],
    },
    {
      employee: '637234c24ee82c08f6fe98f2',
      reviewers: [
        {
          _id: '63764a6dcba003249bb29e0e',
          firstName: 'ammiel',
          lastName: 'yawson',
          status: 'active',
          imageUrl:
            'https://photow-profile-images.s3.us-west-2.amazonaws.com/1669132209365jtZuCebyc_ALgJPbTMIMg.jpg',
          email: 'ammiel@enyata.com',
          phoneNumber: '+233558249492',
          team: 'Knight',
          isDefaultAdmin: false,
          employmentInfo: {
            hireDate: '2022-11-08T00:00:00.000Z',
          },
          isSelected: true,
        },
        {
          _id: '63722e404ee82c08f6fe9832',
          firstName: 'Razak',
          lastName: 'Gawusu',
          status: 'pending',
          imageUrl: null,
          email: 'gawusurazak@enyata.com',
          phoneNumber: '+233321872173',
          team: 'Code for fun',
          isDefaultAdmin: false,
          employmentInfo: {
            hireDate: '2022-10-03T00:00:00.000Z',
          },
          department: {
            name: 'Engineering',
          },
          isSelected: true,
        },
      ],
    },
  ],
};
