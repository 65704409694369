import { gql } from '@apollo/client';

export const FETCH_COMPANY_DETAILS = gql`
  query Query {
    fetchCompanyDetails {
      status
      message
      data {
        _id
        companyName
        companyEmail
        companySize
        country
        isActive
        industry
        country
        logoUrl
        idPrefix
        locations {
          _id
          address
          city
          state
          isHeadQuarter
        }
      }
    }
  }
`;

export const EDIT_COMPANY_DETAILS = gql`
  mutation Mutation($data: EditCompanyDetailsData!) {
    editCompanyDetails(data: $data) {
      status
      message
      data {
        _id
        companyName
        companyEmail
        companySize
        country
        logoUrl
        idPrefix
        locations {
          _id
          address
          city
          state
          isHeadQuarter
        }
      }
    }
  }
`;

export const UPLOAD_LOGO = gql`
  mutation uploadCompanyLogo($file: Upload!) {
    uploadCompanyLogo(file: $file) {
      status
      message
    }
  }
`;
