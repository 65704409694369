import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AnswerType } from './AnswerType';
import { FilesPreview, UrlFilesPreview } from './filesDisplay';

export const AssessmentQuestions = ({ questions, index, optIsObj }) => {
  return (
    <ContentWrap data-testid="assessment-question">
      <div className="assessment" key={index}>
        {questions?.map((field, i) => {
          const formattedOptions = field?.options?.map((val) => ({
            label: optIsObj ? val?.value : val,
            value: optIsObj ? val?.value : val,
          }));

          return (
            <div key={field.question} className="assessment__instructions--row">
              <Flex alignItems={'center'} gap="1.6rem" mt={'2.4rem'}>
                <p className="bullet">{i + 1}.</p>

                <p className="question--item">{field?.question}</p>
              </Flex>
              {field?.files?.length ? (
                <div style={{ paddingLeft: '3.6rem' }}>
                  <FilesPreview files={field?.files} />{' '}
                </div>
              ) : null}
              <UrlFilesPreview files={field?.attachedFiles} />
              <div className="assessment__instructions--row-right">
                <AnswerType
                  index={i}
                  type={field?.questionType || field?.type}
                  options={formattedOptions}
                  files={field?.files || []}
                  answers={field?.correctAnswers}
                />
              </div>
            </div>
          );
        })}
      </div>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  overflow-x: auto;
  padding-bottom: 2.4rem;
  margin-top: 3.2rem;

  .question--item {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #23273b;
  }

  .assessment__instructions--row-right {
    display: flex;
    align-items: center;
    gap: 3.2rem;
    padding: 2.4rem 3.4rem;
    width: max-content;

    label {
      margin-right: 0;
    }
  }

  .assessment__instructions {
    min-width: 1100px;

    &--row {
      display: grid;
      border-bottom: 1px solid #f2f2f2;
      padding: 0 1.6rem;

      .hidden-input {
        display: none;
      }

      .bullet {
        font-weight: 700;
        font-size: 1.4rem;
      }
    }
  }

  .start-wrap {
    cursor: pointer;
  }
`;
