import { gql } from '@apollo/client';

export const RETRIEVE_DOCUMENT = gql`
  query fetchFolders($filter: FolderFilter!) {
    fetchFolders(filter: $filter) {
      status
      message
      data {
        privateFolders {
          _id
          name
          isPrivate
          subFolders {
            _id
            name
          }
          subFiles {
            _id
            name
          }
          createdBy {
            _id
            firstName
          }
          parentFolder
        }
        sharedFolders {
          _id
          name
          isPrivate
          subFolders {
            _id
            name
          }
          subFiles {
            _id
            name
          }
          createdBy {
            _id
            firstName
          }
          parentFolder
        }
      }
    }
  }
`;

export const ADD_FOLDER = gql`
  mutation addNewFolder($data: FolderInputData!) {
    addNewFolder(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const FETCH_ROOT_FOLDERS = gql`
  query fetchRootFolders($filter: FolderFilter!) {
    fetchRootFolders(filter: $filter) {
      status
      message
      data {
        _id
        name
        isPrivate
        createdBy {
          _id
          firstName
          lastName
          imageUrl
        }
        parentFolder
        createdAt
      }
    }
  }
`;

export const FETCH_FILTERED_FOLDERS = gql`
  query fetchFilteredFolders($filter: FolderFilter!) {
    fetchFilteredFolders(filter: $filter) {
      status
      message
      hasNextPage
      data {
        _id
        name
        subFiles {
          _id
          name
          url
          createdAt
          size
          employee {
            _id
            firstName
            lastName
            imageUrl
          }
        }
        subFolders {
          _id
          name
          createdBy {
            _id
            firstName
            lastName
            imageUrl
          }
          updatedAt
          subFolders {
            _id
          }
        }
      }
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation uploadDocument($document: DocumentInputData!) {
    uploadDocument(document: $document) {
      status
      message
      data {
        url
      }
    }
  }
`;

export const EDIT_FOLDER = gql`
  mutation editFolder($data: FolderEditInputData!) {
    editFolder(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const DELETE_FOLDER = gql`
  mutation DeleteFolder($_id: ObjectID!) {
    deleteFolder(_id: $_id) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const SHARE_FOLDER = gql`
  mutation shareFolder($data: FolderShareInputData!) {
    shareFolder(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;
