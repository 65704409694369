import React from 'react';
import { useDrag } from 'react-dnd';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ASSESSMENT_DND_TYPES, FIELD_TYPES } from './Constants';
import { addsection, icons } from './icons';
import { addSectionStyle, fieldStyle } from './style';

const Button = styled.button``;

function IconType({ type }) {
  return (
    <>
      <img src={icons[type]} alt="" />
    </>
  );
}

function ConfigureSection({ addNewSection }) {
  return (
    <Root className="scroll-bar">
      <div className="configure" style={{ marginBottom: '1.6rem' }}>
        <p className="title">Configure</p>
      </div>
      <Button
        data-testid={'add-Section'}
        onClick={addNewSection}
        type="button"
        style={addSectionStyle}>
        <Flex mr="8px">
          <img src={addsection} alt="" />
        </Flex>
        Add section
      </Button>
      <Selectfield>
        <p className="title">Select Field</p>
        {Object.entries(FIELD_TYPES).map(([key, value]) => {
          return (
            <FieldType key={key} type={key}>
              <Flex mr="8px">
                <IconType type={key} />
              </Flex>
              {value}
            </FieldType>
          );
        })}
      </Selectfield>
    </Root>
  );
}

function FieldType({ type, ...props }) {
  const [{ isDragging }, dragRef] = useDrag({
    type: ASSESSMENT_DND_TYPES.AssessmentInputOption,
    item: { type: type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <Grid
      style={fieldStyle}
      ref={dragRef}
      opacity={isDragging ? 0.5 : 1}
      cursor="move"
      _hover={{ boxShadow: '0 4px 12px rgba(0,0,0,0.05)' }}
      userSelect="none"
      {...props}
    />
  );
}

const Root = styled.div`
  width: 100%;
  height: 568px;
  border: 1px solid #f2f2f2;
  border-radius: 8px 8px 0px 0px;
  overflow-y: auto;
  .configure {
    padding: 2.4rem 1.6rem;
    background: #f3f4f6;
    border: 1px solid #f2f2f2;
    border-radius: 8px 8px 0px 0px;
    position: sticky;
    top: 0;
    z-index: 5;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242424;
  }
`;

const Selectfield = styled.div`
  padding: 1.6rem 2.4rem;
  margin-top: 1.6rem;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 4px;
  display: grid;
  gap: 2.4rem;
  overflow-y: auto;
`;

export { ConfigureSection };
