import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { CREATE_LEAVE_TYPE, FETCH_LEAVE_TYPE } from 'query';

import { EditLeaveTypeAndPolicy, FullModal } from '../../../../../components';

export const Box = styled.div`
  .right-modal-overlay {
    background: #fafcff;
    border: none;
  }
`;
const CreateLeaveType = ({ setShowModal, showModal }) => {
  const [state, setState] = useState();
  const { successToast, errorToast } = useToastify();
  const [addLeaveType, { loading: LeaveTypeLoading }] = useMutation(CREATE_LEAVE_TYPE);

  const onSave = async () => {
    try {
      const res = await addLeaveType({
        variables: { data: state },
        refetchQueries: [
          {
            query: FETCH_LEAVE_TYPE,
            variables: {},
          },
        ],
      });
      const { data } = res;
      if (data && data.addLeaveType.status === 201) {
        successToast('Leave Type added sucessfully');
        goBack();

        // props.refetchNotes();
      } else errorToast(data.addLeaveType.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  function goBack() {
    setShowModal(false);
  }

  const isDisabled = !(state?.name && state?.paidTimeSetting) || LeaveTypeLoading;

  return (
    <Box>
      <FullModal noHeader setShowModal={setShowModal} showModal={showModal} mt="8.3rem">
        <EditLeaveTypeAndPolicy
          isDisabled={isDisabled}
          leavePolicy={false}
          title={'Create Leave Type'}
          goBack={goBack}
          setState={setState}
          loading={LeaveTypeLoading}
          onClick={onSave}
        />
      </FullModal>
    </Box>
  );
};

export { CreateLeaveType };
