import { gql } from '@apollo/client';

export const REVIEW_REPORT = gql`
  query retrieveReviews($filters: ReviewFilters, $page: Int) {
    retrieveReviews(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      pageCount
      totalCount
      data {
        _id
        title
        createdAt
        schedule {
          startDate
          endDate
        }
        reviewPeriod {
          startDate
          endDate
        }
        reviewCycle {
          employee {
            firstName
            lastName
            imageUrl
          }
          reviewers {
            firstName
            lastName
          }
        }
        status
      }
    }
  }
`;

export const RETRIEVE_REVIEW_REPORTS = gql`
  query retrieveReviewReport($reviewId: ObjectID!, $page: Int) {
    retrieveReviewReport(reviewId: $reviewId, page: $page) {
      status
      message
      totalCount
      pageCount
      hasNextPage
      data {
        reports {
          _id
          status
          reports_count
          reviewers_count
          reviewee
          revieweeId
          reviewer
          deadline
        }
        scores {
          _id
          reviewScore
        }
      }
    }
  }
`;
