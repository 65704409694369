import * as yup from 'yup';

export const InviteAdminSchema = yup.object().shape({
  firstName: yup.string().required('first Name is a required field'),
  lastName: yup.string().required('last Name is a required field'),
  email: yup.string().email().required('email is a required field'),
  role: yup.object().nullable(true).required('role is a required field'),
  location: yup.string().required('location is a required field'),
});

export const assessmentSchemer = yup.object().shape({
  title: yup.string().required('Provide an assessment Title'),
  // duration: yup.string().required('Provide an assessment duration'),
  duration: yup
    .string()
    .matches(/\d{1,2}:\d{2}/, 'duration should match format: hh:mm (01:30)')
    .required('Provide an assessment duration'),
  deadline: yup.string().required('Provide an assessment deadline'),
  sections: yup
    .array()
    .required('Add at least one section')
    .of(
      yup.object().shape({
        name: yup.string().required('Provide a Section name'),
        instructions: yup.string().required('Provide a Section instructions'),
        questions: yup.array().required('Add at least one question'),
      })
    ),
});

export const questionSchemer = yup.object().shape({
  question: yup.string().required('Provide a question'),
  points: yup.string().required('Provide a score'),
});
