import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import ellipsesTop from '../../../assets/icons/ellipses-top.svg';
import Logo from '../../../assets/icons/Logo.svg';
import iconBlue from '../../../assets/icons/onboarding-icon-blue.svg';
import iconBlueSmall from '../../../assets/icons/onboarding-icon-blue-small.svg';
import iconGreen from '../../../assets/icons/onboarding-icon-green.svg';
import iconOrange from '../../../assets/icons/onboarding-icon-orange.svg';
import bg from '../../../assets/images/onboardingbg.png';

import { SvgAnimation } from './authSvg';

const icon1 = {
  position: 'absolute',
  top: '4.5rem',
};
const icon2 = {
  position: 'absolute',
  bottom: '-2.5rem',
  transform: 'translateX(80%)',
};
const icon3 = {
  position: 'absolute',
  top: '5rem',
  right: '3rem',
};
const icon4 = {
  position: 'absolute',
  top: '-5.5rem',
  right: '7.3rem',
};

function generateRandomArray(length, from = 0, to = 40) {
  return Array.from({ length }, () => Math.floor(Math.random() * (to - from + 1) + from));
}

const iconBlueVariants = {
  hidden: {
    scale: 0.8,
    opacity: 0.8,
  },
  visible: {
    x: generateRandomArray(5, 0, 80),
    y: [-15, 20, -10, 0],
    opacity: 1,
    transition: {
      duration: 10,
      repeat: Infinity,
    },
  },
};

const iconGreenVariants = {
  hidden: {
    scale: 0.8,
    opacity: 0.8,
  },
  visible: {
    x: generateRandomArray(5, -60, 80),
    y: [-15, 20, -10, 0],
    opacity: 1,
    transition: {
      duration: 10,
      repeat: Infinity,
    },
  },
};
const iconOrangeVariants = {
  hidden: {
    scale: 0.8,
    opacity: 0.8,
  },
  visible: {
    x: generateRandomArray(5, -80, 80),
    y: [-15, 20, -10, 0],
    opacity: 1,
    transition: {
      duration: 10,
      repeat: Infinity,
    },
  },
};
const iconBlueSmallVariants = {
  hidden: {
    scale: 0.8,
    opacity: 0.8,
  },
  visible: {
    x: generateRandomArray(5, -40, 80),
    y: [-15, 20, -10, 0],
    opacity: 1,
    transition: {
      duration: 10,
      repeat: Infinity,
    },
  },
};

export const AuthSidebar = () => {
  return (
    <Box
      className="auth-sidebar"
      color="white"
      width="100%"
      backgroundColor="#333758"
      height="100vh"
      position="relative"
      data-testid="signup-sidebar-component">
      <Box pl="2.4rem" pt="5.4rem">
        <img src={Logo} alt="" />
      </Box>
      <Box position="absolute" top="0" right="0">
        <img src={ellipsesTop} alt="" />
      </Box>
      <Box position="relative" w="100%" height="28.5rem" mt="9rem">
        <Box maxW="50rem" mx="auto">
          <img src={bg} alt="" />
        </Box>
        <motion.div style={icon1} variants={iconBlueVariants} initial="hidden" animate="visible">
          <img src={iconBlue} alt="" />
        </motion.div>
        <motion.div style={icon2} variants={iconGreenVariants} initial="hidden" animate="visible">
          <img src={iconGreen} alt="" />
        </motion.div>
        <motion.div style={icon3} variants={iconOrangeVariants} initial="hidden" animate="visible">
          <img src={iconOrange} alt="" />
        </motion.div>
        <motion.div
          style={icon4}
          variants={iconBlueSmallVariants}
          initial="hidden"
          animate="visible">
          <img src={iconBlueSmall} alt="" />
        </motion.div>
      </Box>
      <Flex flexDir="column" p="2.4rem" width={'100%'} position={'absolute'} bottom={'5rem'}>
        <Text fontSize="2.4rem" lineHeight="3.2rem" fontWeight="700" textAlign="center">
          Your HR Management
        </Text>
        <Text fontSize="2.4rem" lineHeight="3.2rem" fontWeight="700" textAlign="center">
          processes on one platform.
        </Text>
      </Flex>
      <Box position="absolute" bottom="0" right="0">
        <SvgAnimation />
      </Box>
    </Box>
  );
};
