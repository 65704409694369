import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RETRIEVE_REVIEW_REPORTS } from 'query/reviewReport/reviewsreport';
import { formatDate } from 'utils';

import {
  EmptyState,
  EventsActionContainer,
  EventsPopupAction,
  Pagination,
  Search,
} from 'components';
import { TableComponent } from 'components';

import { useActionOnRevieweeData } from '../hooks';

import { ReviewReportStatus } from './ReviewReportStatus';
import { ReviewRating, TableCol } from './ReviewReportTable';

const isTesting = process.env.NODE_ENV === 'test';

export function ReviewsReport() {
  const params = useParams();
  const [page, setPage] = React.useState(1);
  const [checked, setChecked] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const { actionOnData } = useActionOnRevieweeData();

  let reviewId;

  if (isTesting) {
    reviewId = 'mockId';
  } else {
    reviewId = params.id;
  }

  const { data } = useQuery(RETRIEVE_REVIEW_REPORTS, {
    variables: {
      reviewId,
      page,
    },
  });

  const allReviewsData = data?.retrieveReviewReport?.data[0].reports;
  const allReviewsScore = data?.retrieveReviewReport?.data[0].scores;
  const totalPages = data?.retrieveReviewReport?.totalCount;

  function scoreReview(score) {
    return score > 0 ? `${score}%` : '--';
  }

  const newwTableData = allReviewsData?.map(
    ({ _id, status, reviewee, revieweeId, reviewer, reviewers_count, reports_count, deadline }) => {
      const score = allReviewsScore.find((score) => score._id === _id);
      return {
        col1: reviewee,
        col2: `${reviewer} +${reviewers_count}` || 'N/A',
        col3: scoreReview(score.reviewScore),
        col4: ReviewReportStatus({ status: status?.replace(/_/, ' ') }) || '---',
        col5: ReviewRating(reports_count, reviewers_count),
        col6: formatDate(deadline) || '',
        col7: <KhebabAction revieweeId={revieweeId} />,
      };
    }
  );
  function KhebabAction({ revieweeId }) {
    return (
      <EventsActionContainer>
        <EventsPopupAction
          id={revieweeId}
          dropdownActions={dropdownActions}
          actionOnData={actionOnData}></EventsPopupAction>
      </EventsActionContainer>
    );
  }

  const handleChecked = (id) => {
    setChecked((prev) => (checked.includes(id) ? prev.filter((cur) => cur !== id) : [...prev, id]));
  };

  const handleSelectAll = (e) => {
    const { checked: isChecked } = e.target;
    if (!isChecked) {
      return setChecked([]);
    }
    let checkedCopy = Array(allReviewsData?.length);
    allReviewsData?.forEach((elem, i) => {
      checkedCopy[i] = elem._id;
    });
    setChecked(checkedCopy);
  };

  React.useEffect(() => {
    handleSelectAll({ target: { checked: selectAll } });
  }, [selectAll]);

  const dropdownActions = ['View', 'Download Report'];
  const checkedBool = allReviewsData?.map((emp) => {
    return checked.includes(emp._id);
  });

  return (
    <>
      {allReviewsData ? (
        <Wrapper overflowX={'auto'}>
          <Flex marginBottom="24px">
            <Search placeholder={'Search Employee'} />
          </Flex>
          <Box w="100%" className={'scroll-bar'} overflowX={'auto'}>
            <TableComponent
              noAction
              columns={TableCol}
              data={newwTableData}
              checked={checked}
              ids={allReviewsData?.map((e) => e._id)}
              dropdownActions={dropdownActions}
              actionOnData={actionOnData}
              checkedBool={checkedBool}
              handleChecked={handleChecked}
              setAllChecked={setSelectAll}
              allChecked={selectAll}
              noSort
            />
          </Box>
          <Box mt="1rem" position="fixed" right={'4rem'} left={'27.2rem'} bottom={'1.2rem'}>
            {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
          </Box>
        </Wrapper>
      ) : (
        <EmptyState />
      )}
    </>
  );
}
const Wrapper = styled.div`
  background: var(--white);
  width: 100%;
  margin: auto;
  padding-block: 24px;
  thead th {
    background: #f3f4f6;
  }
  .th-parent {
    // padding: 16px;
  }
  td:last-child {
    display: flex;
    justify-content: center;
  }
  th:nth-child(5) {
    text-align: center;
  }
  td:nth-child(5) {
  }
  .tableCell:nth-child(5) {
    display: flex;
    justify-content: center;
  }
`;
export default ReviewsReport;
