export const currenciesData = [
  {
    cc: 'AED',
    symbol: '\u062f.\u0625;',
    name: 'UAE dirham',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ae.svg',
  },
  {
    cc: 'AFN',
    symbol: 'Afs',
    name: 'Afghan afghani',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/af.svg',
  },
  {
    cc: 'ALL',
    symbol: 'L',
    name: 'Albanian lek',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/al.svg',
  },
  {
    cc: 'AMD',
    symbol: 'AMD',
    name: 'Armenian dram',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/am.svg',
  },
  {
    cc: 'ANG',
    symbol: 'NA\u0192',
    name: 'Netherlands Antillean gulden',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/an.svg',
  },
  {
    cc: 'AOA',
    symbol: 'Kz',
    name: 'Angolan kwanza',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ao.svg',
  },
  {
    cc: 'ARS',
    symbol: '$',
    name: 'Argentine peso',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ar.svg',
  },
  {
    cc: 'AUD',
    symbol: '$',
    name: 'Australian dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/au.svg',
  },
  {
    cc: 'AWG',
    symbol: '\u0192',
    name: 'Aruban florin',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/aw.svg',
  },
  {
    cc: 'AZN',
    symbol: 'AZN',
    name: 'Azerbaijani manat',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/az.svg',
  },
  {
    cc: 'BAM',
    symbol: 'KM',
    name: 'Bosnia and Herzegovina konvertibilna marka',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ba.svg',
  },
  {
    cc: 'BBD',
    symbol: 'Bds$',
    name: 'Barbadian dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bb.svg',
  },
  {
    cc: 'BDT',
    symbol: '\u09f3',
    name: 'Bangladeshi taka',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bd.svg',
  },
  {
    cc: 'BGN',
    symbol: 'BGN',
    name: 'Bulgarian lev',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bg.svg',
  },
  {
    cc: 'BHD',
    symbol: '.\u062f.\u0628',
    name: 'Bahraini dinar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bh.svg',
  },
  {
    cc: 'BIF',
    symbol: 'FBu',
    name: 'Burundi franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bi.svg',
  },
  {
    cc: 'BMD',
    symbol: 'BD$',
    name: 'Bermudian dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bm.svg',
  },
  {
    cc: 'BND',
    symbol: 'B$',
    name: 'Brunei dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bn.svg',
  },
  {
    cc: 'BOB',
    symbol: 'Bs.',
    name: 'Bolivian boliviano',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bo.svg',
  },
  {
    cc: 'BRL',
    symbol: 'R$',
    name: 'Brazilian real',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg',
  },
  {
    cc: 'BSD',
    symbol: 'B$',
    name: 'Bahamian dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bs.svg',
  },
  {
    cc: 'BTN',
    symbol: 'Nu.',
    name: 'Bhutanese ngultrum',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bt.svg',
  },
  {
    cc: 'BWP',
    symbol: 'P',
    name: 'Botswana pula',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bw.svg',
  },
  {
    cc: 'BYR',
    symbol: 'Br',
    name: 'Belarusian ruble',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/by.svg',
  },
  {
    cc: 'BZD',
    symbol: 'BZ$',
    name: 'Belize dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bz.svg',
  },
  {
    cc: 'CAD',
    symbol: '$',
    name: 'Canadian dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ca.svg',
  },
  {
    cc: 'CDF',
    symbol: 'F',
    name: 'Congolese franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cg.svg',
  },
  {
    cc: 'CHF',
    symbol: 'Fr.',
    name: 'Swiss franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ch.svg',
  },
  {
    cc: 'CLP',
    symbol: '$',
    name: 'Chilean peso',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
  },
  {
    cc: 'CNY',
    symbol: '\u00a5',
    name: 'Chinese/Yuan renminbi',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cn.svg',
  },
  {
    cc: 'COP',
    symbol: 'Col$',
    name: 'Colombian peso',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/co.svg',
  },
  {
    cc: 'CRC',
    symbol: '\u20a1',
    name: 'Costa Rican colon',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cr.svg',
  },
  {
    cc: 'CUC',
    symbol: '$',
    name: 'Cuban peso',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cu.svg',
  },
  {
    cc: 'CVE',
    symbol: 'Esc',
    name: 'Cape Verdean escudo',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cv.svg',
  },
  {
    cc: 'CZK',
    symbol: 'K\u010d',
    name: 'Czech koruna',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cz.svg',
  },
  {
    cc: 'DJF',
    symbol: 'Fdj',
    name: 'Djiboutian franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dj.svg',
  },
  {
    cc: 'DKK',
    symbol: 'Kr',
    name: 'Danish krone',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dk.svg',
  },
  {
    cc: 'DOP',
    symbol: 'RD$',
    name: 'Dominican peso',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/do.svg',
  },
  {
    cc: 'DZD',
    symbol: '\u062f.\u062c',
    name: 'Algerian dinar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dz.svg',
  },
  {
    cc: 'EEK',
    symbol: 'KR',
    name: 'Estonian kroon',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ee.svg',
  },
  {
    cc: 'EGP',
    symbol: '\u00a3',
    name: 'Egyptian pound',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/eg.svg',
  },
  {
    cc: 'ERN',
    symbol: 'Nfa',
    name: 'Eritrean nakfa',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/er.svg',
  },
  {
    cc: 'ETB',
    symbol: 'Br',
    name: 'Ethiopian birr',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/et.svg',
  },
  { cc: 'EUR', symbol: '\u20ac', name: 'European Euro', flag: 'https://flagcdn.com/16x12/eu.png' },
  {
    cc: 'FJD',
    symbol: 'FJ$',
    name: 'Fijian dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fj.svg',
  },
  {
    cc: 'FKP',
    symbol: '\u00a3',
    name: 'Falkland Islands pound',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fk.svg',
  },
  {
    cc: 'GBP',
    symbol: '\u00a3',
    name: 'British pound',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gb.svg',
  },
  {
    cc: 'GEL',
    symbol: 'GEL',
    name: 'Georgian lari',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ge.svg',
  },
  {
    cc: 'GHS',
    symbol: 'GH\u20b5',
    name: 'Ghanaian cedi',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gh.svg',
  },
  {
    cc: 'GIP',
    symbol: '\u00a3',
    name: 'Gibraltar pound',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gi.svg',
  },
  {
    cc: 'GMD',
    symbol: 'D',
    name: 'Gambian dalasi',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gm.svg',
  },
  {
    cc: 'GNF',
    symbol: 'FG',
    name: 'Guinean franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gn.svg',
  },
  {
    cc: 'GQE',
    symbol: 'CFA',
    name: 'Central African CFA franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cf.svg',
  },
  {
    cc: 'GTQ',
    symbol: 'Q',
    name: 'Guatemalan quetzal',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gt.svg',
  },
  {
    cc: 'GYD',
    symbol: 'GY$',
    name: 'Guyanese dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gy.svg',
  },
  {
    cc: 'HKD',
    symbol: 'HK$',
    name: 'Hong Kong dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hk.svg',
  },
  {
    cc: 'HNL',
    symbol: 'L',
    name: 'Honduran lempira',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hn.svg',
  },
  {
    cc: 'HRK',
    symbol: 'kn',
    name: 'Croatian kuna',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hr.svg',
  },
  {
    cc: 'HTG',
    symbol: 'G',
    name: 'Haitian gourde',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ht.svg',
  },
  {
    cc: 'HUF',
    symbol: 'Ft',
    name: 'Hungarian forint',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hu.svg',
  },
  {
    cc: 'IDR',
    symbol: 'Rp',
    name: 'Indonesian rupiah',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/id.svg',
  },
  {
    cc: 'ILS',
    symbol: '\u20aa',
    name: 'Israeli new sheqel',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/il.svg',
  },
  {
    cc: 'INR',
    symbol: '\u20B9',
    name: 'Indian rupee',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/in.svg',
  },
  {
    cc: 'IQD',
    symbol: '\u062f.\u0639',
    name: 'Iraqi dinar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/iq.svg',
  },
  {
    cc: 'IRR',
    symbol: 'IRR',
    name: 'Iranian rial',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ir.svg',
  },
  {
    cc: 'ISK',
    symbol: 'kr',
    name: 'Icelandic kr\u00f3na',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/is.svg',
  },
  {
    cc: 'JMD',
    symbol: 'J$',
    name: 'Jamaican dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jm.svg',
  },
  {
    cc: 'JOD',
    symbol: 'JOD',
    name: 'Jordanian dinar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jo.svg',
  },
  {
    cc: 'JPY',
    symbol: '\u00a5',
    name: 'Japanese yen',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jp.svg',
  },
  {
    cc: 'KES',
    symbol: 'KSh',
    name: 'Kenyan shilling',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ke.svg',
  },
  {
    cc: 'KGS',
    symbol: '\u0441\u043e\u043c',
    name: 'Kyrgyzstani som',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kg.svg',
  },
  {
    cc: 'KHR',
    symbol: '\u17db',
    name: 'Cambodian riel',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kh.svg',
  },
  {
    cc: 'KMF',
    symbol: 'KMF',
    name: 'Comorian franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/km.svg',
  },
  {
    cc: 'KPW',
    symbol: 'W',
    name: 'North Korean won',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kp.svg',
  },
  {
    cc: 'KRW',
    symbol: 'W',
    name: 'South Korean won',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kr.svg',
  },
  {
    cc: 'KWD',
    symbol: 'KWD',
    name: 'Kuwaiti dinar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kw.svg',
  },
  { cc: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  {
    cc: 'KZT',
    symbol: 'T',
    name: 'Kazakhstani tenge',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kz.svg',
  },
  {
    cc: 'LAK',
    symbol: 'KN',
    name: 'Lao kip',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/la.svg',
  },
  {
    cc: 'LBP',
    symbol: '\u00a3',
    name: 'Lebanese lira',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lb.svg',
  },
  {
    cc: 'LKR',
    symbol: 'Rs',
    name: 'Sri Lankan rupee',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lk.svg',
  },
  {
    cc: 'LRD',
    symbol: 'L$',
    name: 'Liberian dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lr.svg',
  },
  {
    cc: 'LSL',
    symbol: 'M',
    name: 'Lesotho loti',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ls.svg',
  },
  {
    cc: 'LTL',
    symbol: 'Lt',
    name: 'Lithuanian litas',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lt.svg',
  },
  {
    cc: 'LVL',
    symbol: 'Ls',
    name: 'Latvian lats',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lv.svg',
  },
  {
    cc: 'LYD',
    symbol: 'LD',
    name: 'Libyan dinar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ly.svg',
  },
  {
    cc: 'MAD',
    symbol: 'MAD',
    name: 'Moroccan dirham',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ma.svg',
  },
  {
    cc: 'MDL',
    symbol: 'MDL',
    name: 'Moldovan leu',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/md.svg',
  },
  {
    cc: 'MGA',
    symbol: 'FMG',
    name: 'Malagasy ariary',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mg.svg',
  },
  {
    cc: 'MKD',
    symbol: 'MKD',
    name: 'Macedonian denar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mk.svg',
  },
  { cc: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  {
    cc: 'MNT',
    symbol: '\u20ae',
    name: 'Mongolian tugrik',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mn.svg',
  },
  {
    cc: 'MOP',
    symbol: 'P',
    name: 'Macanese pataca',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mo.svg',
  },
  {
    cc: 'MRO',
    symbol: 'UM',
    name: 'Mauritanian ouguiya',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mr.svg',
  },
  {
    cc: 'MUR',
    symbol: 'Rs',
    name: 'Mauritian rupee',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mu.svg',
  },
  {
    cc: 'MVR',
    symbol: 'Rf',
    name: 'Maldivian rufiyaa',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mv.svg',
  },
  {
    cc: 'MWK',
    symbol: 'MK',
    name: 'Malawian kwacha',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mw.svg',
  },
  {
    cc: 'MXN',
    symbol: '$',
    name: 'Mexican peso',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mx.svg',
  },
  {
    cc: 'MYR',
    symbol: 'RM',
    name: 'Malaysian ringgit',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/my.svg',
  },
  {
    cc: 'MZM',
    symbol: 'MTn',
    name: 'Mozambican metical',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mz.svg',
  },
  {
    cc: 'NAD',
    symbol: 'N$',
    name: 'Namibian dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/na.svg',
  },
  {
    cc: 'NGN',
    symbol: '\u20a6',
    name: 'Nigerian naira',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ng.svg',
  },
  {
    cc: 'NIO',
    symbol: 'C$',
    name: 'Nicaraguan c\u00f3rdoba',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ni.svg',
  },
  {
    cc: 'NOK',
    symbol: 'kr',
    name: 'Norwegian krone',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/no.svg',
  },
  {
    cc: 'NPR',
    symbol: 'NRs',
    name: 'Nepalese rupee',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/np.svg',
  },
  {
    cc: 'NZD',
    symbol: 'NZ$',
    name: 'New Zealand dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nz.svg',
  },
  {
    cc: 'OMR',
    symbol: 'OMR',
    name: 'Omani rial',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/om.svg',
  },
  {
    cc: 'PAB',
    symbol: 'B./',
    name: 'Panamanian balboa',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pa.svg',
  },
  {
    cc: 'PEN',
    symbol: 'S/.',
    name: 'Peruvian nuevo sol',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pe.svg',
  },
  {
    cc: 'PGK',
    symbol: 'K',
    name: 'Papua New Guinean kina',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pg.svg',
  },
  {
    cc: 'PHP',
    symbol: '\u20b1',
    name: 'Philippine peso',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ph.svg',
  },
  {
    cc: 'PKR',
    symbol: 'Rs.',
    name: 'Pakistani rupee',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pk.svg',
  },
  {
    cc: 'PLN',
    symbol: 'z\u0142',
    name: 'Polish zloty',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pl.svg',
  },
  {
    cc: 'PYG',
    symbol: '\u20b2',
    name: 'Paraguayan guarani',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/py.svg',
  },
  {
    cc: 'QAR',
    symbol: 'QR',
    name: 'Qatari riyal',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/qa.svg',
  },
  {
    cc: 'RON',
    symbol: 'L',
    name: 'Romanian leu',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ro.svg',
  },
  {
    cc: 'RSD',
    symbol: 'din.',
    name: 'Serbian dinar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/rs.svg',
  },
  {
    cc: 'RUB',
    symbol: 'R',
    name: 'Russian ruble',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ru.svg',
  },
  {
    cc: 'SAR',
    symbol: 'SR',
    name: 'Saudi riyal',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sa.svg',
  },
  {
    cc: 'SBD',
    symbol: 'SI$',
    name: 'Solomon Islands dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sb.svg',
  },
  {
    cc: 'SCR',
    symbol: 'SR',
    name: 'Seychellois rupee',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sc.svg',
  },
  {
    cc: 'SDG',
    symbol: 'SDG',
    name: 'Sudanese pound',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ss.svg',
  },
  {
    cc: 'SEK',
    symbol: 'kr',
    name: 'Swedish krona',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/se.svg',
  },
  {
    cc: 'SGD',
    symbol: 'S$',
    name: 'Singapore dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sg.svg',
  },
  {
    cc: 'SHP',
    symbol: '\u00a3',
    name: 'Saint Helena pound',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sh.svg',
  },
  {
    cc: 'SLL',
    symbol: 'Le',
    name: 'Sierra Leonean leone',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sl.svg',
  },
  {
    cc: 'SOS',
    symbol: 'Sh.',
    name: 'Somali shilling',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/so.svg',
  },
  {
    cc: 'SRD',
    symbol: '$',
    name: 'Surinamese dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sr.svg',
  },
  {
    cc: 'SYP',
    symbol: 'LS',
    name: 'Syrian pound',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sy.svg',
  },
  { cc: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  {
    cc: 'THB',
    symbol: '\u0e3f',
    name: 'Thai baht',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/th.svg',
  },
  {
    cc: 'TJS',
    symbol: 'TJS',
    name: 'Tajikistani somoni',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tj.svg',
  },
  {
    cc: 'TMT',
    symbol: 'm',
    name: 'Turkmen manat',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tm.svg',
  },
  {
    cc: 'TND',
    symbol: 'DT',
    name: 'Tunisian dinar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tn.svg',
  },
  {
    cc: 'TRY',
    symbol: 'TRY',
    name: 'Turkish new lira',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tr.svg',
  },
  {
    cc: 'TTD',
    symbol: 'TT$',
    name: 'Trinidad and Tobago dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tt.svg',
  },
  {
    cc: 'TWD',
    symbol: 'NT$',
    name: 'New Taiwan dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tw.svg',
  },
  {
    cc: 'TZS',
    symbol: 'TZS',
    name: 'Tanzanian shilling',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tz.svg',
  },
  {
    cc: 'UAH',
    symbol: 'UAH',
    name: 'Ukrainian hryvnia',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ua.svg',
  },
  {
    cc: 'UGX',
    symbol: 'USh',
    name: 'Ugandan shilling',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ug.svg',
  },
  {
    cc: 'USD',
    symbol: 'US$',
    name: 'United States dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/us.svg',
  },
  {
    cc: 'UYU',
    symbol: '$U',
    name: 'Uruguayan peso',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/uy.svg',
  },
  {
    cc: 'UZS',
    symbol: 'UZS',
    name: 'Uzbekistani som',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/uz.svg',
  },
  {
    cc: 'VEB',
    symbol: 'Bs',
    name: 'Venezuelan bolivar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ve.svg',
  },
  {
    cc: 'VND',
    symbol: '\u20ab',
    name: 'Vietnamese dong',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vn.svg',
  },
  {
    cc: 'VUV',
    symbol: 'VT',
    name: 'Vanuatu vatu',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vu.svg',
  },
  {
    cc: 'WST',
    symbol: 'WS$',
    name: 'Samoan tala',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ws.svg',
  },
  {
    cc: 'XAF',
    symbol: 'CFA',
    name: 'Central African CFA franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cf.svg',
  },
  {
    cc: 'XCD',
    symbol: 'EC$',
    name: 'East Caribbean dollar',
    flag: 'https://flagpedia.net/data/org/w1160/caricom.webp',
  },
  { cc: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  {
    cc: 'XOF',
    symbol: 'CFA',
    name: 'West African CFA franc',
    flag: 'https://flagpedia.net/data/org/w1160/au.webp',
  },
  {
    cc: 'XPF',
    symbol: 'F',
    name: 'CFP franc',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pf.svg',
  },
  {
    cc: 'YER',
    symbol: 'YER',
    name: 'Yemeni rial',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ye.svg',
  },
  {
    cc: 'ZAR',
    symbol: 'R',
    name: 'South African rand',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/za.svg',
  },
  {
    cc: 'ZMK',
    symbol: 'ZK',
    name: 'Zambian kwacha',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/zm.svg',
  },
  {
    cc: 'ZWR',
    symbol: 'Z$',
    name: 'Zimbabwean dollar',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/zw.svg',
  },
];
