import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useToastify } from 'hooks/useToastify';

const AssessmentFormContext = React.createContext();

function AssessmentFormProvider(props) {
  const { children, initialValue } = props;
  const init = React.useMemo(() => initialValue || {}, []);
  const formMethods = useForm(init);
  const { control } = formMethods;
  const formState = useFormState({ control });
  const { errorToast } = useToastify();

  React.useEffect(() => {
    const errorList = Object.entries(formState.errors);
    if (errorList.length) {
      errorList.forEach(([, value]) => {
        const message = value.message;
        const innerList = Object.entries(value);
        if (!message && innerList.length) {
          innerList.forEach((inner) => {
            Object.entries(inner[1]).forEach(([, value]) => {
              const message = value.message;
              errorToast(message);
            });
          });
        } else {
          errorToast(message);
        }
      });
    }
  }, [formState.errors]);
  return (
    <AssessmentFormContext.Provider value={formMethods}>{children}</AssessmentFormContext.Provider>
  );
}

function useAssessmentForm() {
  const context = React.useContext(AssessmentFormContext);
  if (!context) throw Error('useAssessmentForm must be wrapped in AssessmentFormProvider');
  return context;
}

export { AssessmentFormProvider, useAssessmentForm };
