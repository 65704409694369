import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import plus from '../../../../assets/icons/plusLarge.svg';
import { Modal } from '../../../modals';
import { CustomButton } from '../../../shared';

import { AddTask } from './AddTask';
import { FETCH_ALL_TASKS } from './query';
import { TaskButtonTab, TasksHeaderBox } from './styles';
import { TaskItem } from './tasks';

export const Task = () => {
  const [activeTab, setActiveTab] = React.useState('day');
  const [editing, setediting] = React.useState(false);
  const [editObj, setObj] = React.useState({});

  const { data } = useQuery(FETCH_ALL_TASKS, {
    variables: {
      filter: {
        filterBy: activeTab,
      },
    },
  });

  const openTask = (task) => {
    setediting(true);
    setObj(task);
    setIsOpen(true);
  };
  const closemodal = () => {
    setediting(false);
    setIsOpen((v) => !v);
    setObj({});
  };
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Box data-testid="main-task">
      <TaskHeader
        stats={data?.fetchAllTasks?.totalCount}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        openAddTaskModal={() => setIsOpen(true)}></TaskHeader>
      <Box height="27.2rem" overflowY="auto" pr={'0.4rem'}>
        <TaskItem open={openTask} tasks={data?.fetchAllTasks?.data || []}></TaskItem>
      </Box>
      <Modal showModal={isOpen} closeModal={closemodal} title={'Add Task'}>
        <AddTask editing={editing} defaultdata={editObj} closeModal={closemodal}></AddTask>
      </Modal>
    </Box>
  );
};

const TaskPeriod = ({ activeTab, setTab }) => {
  return (
    <Flex
      data-testid="layout"
      borderRadius={'0.4rem'}
      border="1px solid #F2F2F2"
      height={'3.6rem'}
      width="10.5rem">
      <TaskButtonTab
        layoutType={'grid'}
        onClick={() => setTab('day')}
        active={activeTab === 'day' ? 'active' : ''}
        data-testid="day">
        <Text className="text">Day</Text>
      </TaskButtonTab>
      <TaskButtonTab
        layoutType={'table'}
        onClick={() => setTab('week')}
        active={activeTab === 'week' ? 'active' : ''}
        data-testid="week">
        <Text className="text">Week</Text>
      </TaskButtonTab>
    </Flex>
  );
};

const TaskHeader = ({ openAddTaskModal, setActiveTab, activeTab, stats }) => {
  return (
    <TasksHeaderBox>
      <Grid alignItems={'center'} templateColumns={'1.3fr 1fr'}>
        <Text fontSize="1.6rem" fontWeight="600" color={'brand.black'}>
          My Tasks
        </Text>
        <TaskPeriod activeTab={activeTab} setTab={(val) => setActiveTab(val)} />
      </Grid>
      <Flex alignItems={'center'}>
        <Text className="completed" mr={'1.6rem'}>
          {`${stats?.completedCount}/${stats?.totalCount} completed`}
        </Text>
        <CustomButton
          name="Add Task"
          variant="primary"
          src={plus}
          h={'4rem'}
          mW={'11rem'}
          handleButtonClick={openAddTaskModal}
        />
      </Flex>
    </TasksHeaderBox>
  );
};
