import React from 'react';
import { IoClose } from 'react-icons/io5';
import { Box, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { ModalButton } from './ModalButton';

export const Title = styled(Text)`
  color: #23273b;
  font-weight: 600;
  font-size: 2.4rem;
`;

export const Wrapper = styled(Box)`
  height: auto;
  display: grid;
  padding: 1.6rem;
  grid-gap: 1.6rem;
  background: #fff;
  border-radius: 0.4rem;
  @media (min-width: 768px) {
    padding: 2.4rem;
    width: 100%;
    max-width: 42.5rem;
    margin: 0;
  }
`;

const RightOverlay = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background: rgba(35, 39, 59, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;

  .right__wrapper {
    background: #fff;
    justify-content: center;
    height: auto;
    width: 100%;
    overflow-y: auto;
    @media (min-width: 820px) {
      justify-content: flex-end;
    }

    &.without-button .modal__content {
      padding-inline: 3.2rem;
    }
  }

  .modal__content {
    padding-inline: 3.2rem;

    @media (min-width: 820px) {
      padding-inline: 4.8rem;
    }
  }
`;

const Header = styled(Box)`
  display: grid;
  grid: 1fr / 1fr max-content;
  width: 100%;
  justify-content: space-between;
  gap: 2.4rem;
  align-items: center;
  padding: 4rem 3.2rem;
  background-color: var(--black);
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;

  &.without-button {
    padding: 4rem 3.2rem;
  }

  @media (min-width: 820px) {
    padding: 4rem 4.8rem;
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.8rem;
    letter-spacing: -0.05em;
    white-space: break-spaces;
  }

  .btn__wrapper {
    margin-inline: 0 1.7rem;
    &:empty {
      margin-inline: 0;
    }

    button {
      display: flex;
      align-items: center;
      padding-left: 0.8rem;
    }
  }
`;

const RightModal = (props) => {
  const { showModal, closeModal, width, children, title, button, withoutHeader } = props;
  return (
    <>
      {showModal && (
        <RightOverlay>
          <Box
            className={`right__wrapper ${!button ? 'without-button' : ''}`}
            maxWidth={{ base: '100%', md: width || '45rem' }}>
            {!withoutHeader && (
              <Header backgroundColor="brand.black" className={!button ? 'without-button' : ''}>
                <Grid
                  className="content"
                  alignItems="center"
                  gridTemplateColumns="max-content 1fr"
                  w="100%">
                  <div className="btn__wrapper">{button}</div>
                  <h2>{title}</h2>
                </Grid>
                <div className="close">
                  <ModalButton
                    w="3.6rem"
                    h="3.6rem"
                    p="0"
                    data-testid="close"
                    onClick={() => closeModal()}>
                    <IoClose size={20} />
                  </ModalButton>
                </div>
              </Header>
            )}
            <Box className="modal__content">{children}</Box>
          </Box>
        </RightOverlay>
      )}
    </>
  );
};

RightModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  withoutHeader: PropTypes.bool,
  title: PropTypes.string,
  button: PropTypes.object,
  width: PropTypes.string,
};

export { RightModal };
