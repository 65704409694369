import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Flex, Heading, Stack } from '@chakra-ui/react';

import {
  CustomButton,
  DeletePromptModal,
  EventsActionContainer,
  EventsPopupAction,
  FullModal,
  NameWithImage,
  TableComponent,
  ViewDocument,
} from 'components';

import plus from '../../../../../assets/icons/plusLarge.svg';
import { useToastify } from '../../../../../hooks/useToastify';
import {
  DELETE_EMPLOYEE_DOCUMENT,
  FETCH_EMPLOYEE_DOCUMENTS,
  UPLOAD_EMPLOYEE_DOCUMENT,
} from '../../query';
import { formatIncomingDate } from '../helpers';

const dropdownActions = {
  edit: 'View',
  delete: 'Delete',
};
export function Documents({ docs, loadingDocuments }) {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const documentUploader = useRef(null);
  const [documentId, setDocumentId] = useState();
  const [documentName, setDocumentName] = useState('');
  const [documentUrl, setDocumentUrl] = useState('');
  function toggleModal() {
    setShowModal((prev) => !prev);
  }
  function toggleDeleteModal() {
    setShowDeleteModal((prev) => !prev);
  }
  const [uploadEmployeeDocument, { loading }] = useMutation(UPLOAD_EMPLOYEE_DOCUMENT, {
    refetchQueries: [
      {
        query: FETCH_EMPLOYEE_DOCUMENTS,
      },
    ],
  });

  const { successToast, errorToast } = useToastify();

  const [deleteEmployeeDocument, { loading: deleteLoading }] = useMutation(
    DELETE_EMPLOYEE_DOCUMENT,
    {
      refetchQueries: [
        {
          query: FETCH_EMPLOYEE_DOCUMENTS,
        },
      ],
    }
  );

  async function deleteDocument(id, name) {
    const res = await deleteEmployeeDocument({
      variables: {
        data: {
          _id: id,
          name,
        },
      },
    });

    if (res) {
      successToast(res.data.deleteEmployeeDocument.message);
      toggleDeleteModal();
    }
  }

  function viewDocument(name, url) {
    setDocumentName(name);
    setDocumentUrl(url);
    toggleModal();
  }

  function action(doc) {
    switch (doc.action) {
      case 'view':
        viewDocument(doc.name, doc.url);
        break;
      case 'delete':
        setDocumentName(doc.name);
        setDocumentId(doc.id);
        toggleDeleteModal();
        break;

      default:
        break;
    }
  }

  function ActionContainer({ id, name, url }) {
    return (
      <EventsActionContainer>
        <EventsPopupAction
          dropdownActions={dropdownActions}
          actionOnData={action}
          id={id}
          name={name}
          url={url}></EventsPopupAction>
      </EventsActionContainer>
    );
  }
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
    },
    {
      Header: 'Date Modified',
      accessor: 'date',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
  ];

  const tableData = docs?.map(({ employee, createdAt, name, _id, url }) => {
    return {
      name: name,
      createdBy: <NameWithImage data={employee} />,
      date: formatIncomingDate(createdAt),
      action: <ActionContainer id={_id} name={name} url={url} />,
    };
  });

  async function handleDocUpload(e) {
    const [file] = e.target.files;

    const res = await uploadEmployeeDocument({
      variables: {
        document: { file },
      },
    });

    if (res.data.uploadEmployeeDocument.status === 201) {
      successToast(res.data.uploadEmployeeDocument.message);
      documentUploader.current.value = null;
    }
    if (res.data.uploadEmployeeDocument.status === 400) {
      errorToast(res.data.uploadEmployeeDocument.message);
      documentUploader.current.value = null;
    }
  }

  return (
    <>
      <Stack p={'32px 16px'} border={'solid 1px var(--grey-100)'} borderRadius={'4px'} gap={'40px'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Heading fontSize={'2rem'}>Documents</Heading>
          <Box>
            <input
              ref={documentUploader}
              type={'file'}
              style={{ display: 'none' }}
              onChange={handleDocUpload}
            />
            <CustomButton
              data-testId={'btn-clicked-upload'}
              name={'Add Document'}
              fS={'1.4rem'}
              src={plus}
              h={'4rem'}
              type={'button'}
              loading={loading}
              onClick={() => documentUploader.current.click()}
            />
          </Box>
        </Flex>

        <Stack overflow={'hidden'}>
          <Box overflowX={'auto'}>
            <TableComponent
              noCheckbox
              noSort
              noAction
              loading={loadingDocuments}
              data={tableData}
              columns={columns}
              minW={'450px'}
              maxW={'100%'}
              empty={docs?.length === 0}
            />
          </Box>
        </Stack>
      </Stack>

      <FullModal showModal={showModal} noHeader padding={'0px'}>
        <ViewDocument
          closeModal={toggleModal}
          documentName={documentName}
          documentUrl={documentUrl}
        />
      </FullModal>

      <DeletePromptModal
        show={showDeleteModal}
        loading={deleteLoading}
        cancel={toggleDeleteModal}
        deleteItem={() => deleteDocument(documentId, documentName)}
      />
    </>
  );
}
