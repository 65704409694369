import React from 'react';
import { useQuery } from '@apollo/client';
import { Flex, Grid, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { usePerformanceReview } from 'pages/employee/line-manager/leave-approvals/hooks';
import { FETCH_ALL_TEAMS } from 'query';

import { BaseDropdown, Search } from 'components';

import { PerformanceReviewTable } from './components/PerformanceReviewTable';
import { AddPerformanceReview } from './AddPerformanceReview';

const PerformanceReview = () => {
  const [showPerformanceReview, setShowPerformanceReview] = React.useState(false);
  const [reviewData, setData] = React.useState('');
  const { data } = useQuery(FETCH_ALL_TEAMS, {});

  const {
    setFilter,
    setSearchString,
    setPage,
    filter,
    search,
    page,
    performanceReviews,
    pageCount,
    loading,
    refetch,
    setSearch,
  } = usePerformanceReview();

  const teamsData =
    data?.fetchTeams?.data.map((team) => {
      return {
        label: team.name,
        value: team._id,
      };
    }) || [];
  const list = [{ label: 'All Teams', value: '' }, ...teamsData];

  return (
    <Container>
      <Flex className="review" justifyContent={'space-between'} alignItems={'center'}>
        <Heading>All Performance Reviews</Heading>
        <Flex className="review">
          <Search
            placeholder={'Search by name'}
            value={search}
            setSearchString={setSearchString}
            setPage={setPage}
            onChange={(e) => setSearch(e.target.value)}
          />
          <BaseDropdown
            w={'100%'}
            dataTestId={'filter-status'}
            placeholder={'status'}
            value={filter}
            onChange={(e) => setFilter(e.value)}
            options={list}
          />
        </Flex>
      </Flex>
      <PerformanceReviewTable
        page={page}
        tData={performanceReviews}
        pageCount={pageCount}
        setPage={setPage}
        setShowPerformanceReview={setShowPerformanceReview}
        setData={setData}
        loading={loading}
      />
      {showPerformanceReview ? (
        <AddPerformanceReview
          showModal={showPerformanceReview}
          setShowModal={setShowPerformanceReview}
          data={reviewData}
          refetch={refetch}
        />
      ) : null}
    </Container>
  );
};
const Container = styled(Grid)`
  margin: 32px 24px;
  padding: 27px 35px 76px;
  background-color: #fff;
  border-radius: 16px;
  gap: 32px;

  .css-12xxamh-control {
    box-shadow: none !important;
  }

  .dropDown {
    width: 140px;
  }

  @media (max-width: 768px) {
    margin: 20px;
    padding: 20px;

    .review {
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }

    .search-wrapper {
      max-width: 100%;
      margin: 0;
    }

    .dropDown {
      width: 100%;
    }
  }

  .status {
    display: flex;
    justify-content: center;
    width: 120px;
    padding: 8px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px; /* 100% */

    &.is-completed {
      border: 1px solid var(--green-status, #36ca8b);
      background: rgba(54, 202, 139, 0.1);
      color: #36ca8b;
    }

    &.not-completed {
      border: 1px solid var(--pending, #f0a800);
      background: rgba(240, 168, 0, 0.1);
      color: #f0a800;
    }
  }
`;
export { PerformanceReview };
