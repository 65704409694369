import React from 'react';
import { useMutation } from '@apollo/client';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { BaseInput } from 'components';

import { useToastify } from '../../../../../../hooks/useToastify';
import {
  CREATE_WORK_SCHEDULE,
  FETCH_WORK_SCHEDULE,
} from '../../../../../../query/admin/leave-management';
import { CustomButton } from '../Buttons';

import { SimpleModal } from './SimpleModal';

export function CreateWorkSchedule({ closeModal, setEditing }) {
  const [name, setName] = React.useState(() => '');
  const [createWorkSchedule, { loading }] = useMutation(CREATE_WORK_SCHEDULE);
  const { successToast, errorToast } = useToastify();

  async function onSubmit(e) {
    e.preventDefault();
    const res = await createWorkSchedule({
      variables: {
        data: { name, weekend: ['sat', 'sun'] },
      },
      refetchQueries: [{ query: FETCH_WORK_SCHEDULE, variables: { filter: { page: 1 } } }],
      awaitRefetchQueries: true,
    });
    const id = res?.data?.createWorkSchedule?.data?._id;
    if (!id)
      return errorToast(
        res?.data?.createWorkSchedule?.message ||
          'Failed to create work schedule. Check your data and try again.'
      );
    successToast('Work schedule created successfully');
    closeModal();
    setEditing(id);
  }

  return (
    <SimpleModal>
      <form onSubmit={onSubmit}>
        <Stack
          p={'2.4rem'}
          gap={'3.2rem'}
          border={'solid 1px var(--grey-250)'}
          borderRadius={'0.4rem'}
          marginBottom={'2.4rem'}>
          <Text fontSize={'2.4rem'} color={'var(--black)'} data-testid={'modalTitle'}>
            {'Create Work Schedule'}
          </Text>
          <Stack>
            <BaseInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              label={'Work Schedule Name'}
              placeholder={'Ontario Employee'}
              isRequired
            />
          </Stack>
        </Stack>

        <Flex justifyContent={'flex-end'} gap={'0.8rem'}>
          <CustomButton
            variant={'light'}
            name={'Cancel'}
            mW={'9.4rem'}
            h={'4rem'}
            onClick={closeModal}
            testId={'closeModal'}
            type="button"
          />
          <CustomButton
            variant={'dark'}
            name={'Next'}
            mW={'9.4rem'}
            h={'4rem'}
            onClick={onSubmit}
            testId={'nextBtn'}
            loading={loading}
          />
        </Flex>
      </form>
    </SimpleModal>
  );
}
