import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RETRIEVE_SINGLE_GOAL } from 'query';

import {
  ActionDropdown,
  DeletePromptModal,
  EmptyState,
  FullModal,
  ModalBackButton,
  SpinnerComponent,
} from 'components';

import { GoalsIndicator, Timelines } from './components';

const Wrapper = styled.div`
  .buttonStyle {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
  }
  .header {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    background-color: var(--white);

    &-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.05em;
    }
  }
`;

const Heading = styled(Flex)`
  justify-content: space-between;
  span {
    width: fit-content;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #23273b;
  }
`;

const ViewGoals = ({ showModal, setShowModal, id, onDelete, deleting, onEdit }) => {
  const goalsActions = [
    {
      label: 'Edit ',
      value: 'edit',
    },
    { label: 'Delete', value: 'delete' },
  ];

  const {
    data: singleGoalData,
    loading,
    refetch,
  } = useQuery(RETRIEVE_SINGLE_GOAL, {
    variables: {
      filters: {
        _id: id,
      },
    },
  });
  const [show, setShow] = React.useState(false);

  const fetchedData = singleGoalData?.retrieveGoals?.data[0];

  const initialState = fetchedData?.milestones
    ?.filter((mile) => mile?.isCompleted === true)
    ?.map((mile) => mile?.metricTitle);

  const handleActionClick = (status, id) => {
    if (status?.value === 'edit') {
      onEdit(id);
    }
    if (status?.value === 'delete') {
      setShow(true);
    }
  };

  return (
    <>
      <Wrapper>
        <FullModal noHeader showModal={showModal} mt="8.3rem">
          {loading ? (
            <>
              <header className="header">
                <Box>
                  <ModalBackButton onClick={() => setShowModal(false)} />
                </Box>
                <SpinnerComponent />
              </header>
            </>
          ) : [fetchedData]?.length ? (
            <Box maxW={'758px'}>
              <header className="header">
                <Box>
                  <ModalBackButton onClick={() => setShowModal(false)} />
                </Box>
                <Heading>
                  <span>{fetchedData?.title}</span>
                  <ActionDropdown
                    actions={goalsActions}
                    onClick={handleActionClick}
                    id={fetchedData?._id}
                  />
                </Heading>
              </header>
              <Flex flexDirection={'column'} gap={'24px'}>
                <GoalsIndicator data={fetchedData} refetch={refetch} initialState={initialState} />
                <Timelines data={fetchedData} refetch={refetch} />
              </Flex>
            </Box>
          ) : (
            <EmptyState message={'No Goals Data'} />
          )}
        </FullModal>
      </Wrapper>
      {show && (
        <DeletePromptModal
          show={show}
          cancel={() => setShow(false)}
          deleteItem={() => onDelete(id)}
          loading={deleting}
        />
      )}
    </>
  );
};

export { ViewGoals };
