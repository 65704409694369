import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';

import { BaseButton } from '../../../../components';
import { useToastify } from '../../../../hooks/useToastify';
import { APPLICANTS_FORM_BREAKPOINT } from '../../../../utils';
import { NON_EDITABLE_FIELD_NAMES } from '../../../admin/jobs/add-jobs/ApplicantsForm/helpers';
import { useApplyToJob } from '../hooks';

import { InputFieldList } from './InputFieldList';

export function ApplicationForm(props) {
  const { formFields } = props;
  const params = useParams();
  const navigate = useNavigate();

  const { successToast } = useToastify();
  const formMethods = useForm({
    defaultValues: { fields: formFields },
    resolver: props.schema ? yupResolver(props.schema) : null,
  });
  window.fValues = formMethods.getValues;
  window.fState = formMethods.formState;

  const { mutate, isLoading } = useApplyToJob({
    onSuccess() {
      formMethods.reset({ fields: formFields });
      successToast('Application submitted successfully');
    },
  });

  function onSubmit(values) {
    const requestBody = transformFormData(values.fields || []);
    navigate('/applicant');
    mutate({ id: params.id, body: requestBody });
  }

  return (
    <ApplicationFormWrapper>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid
            w={'100%'}
            templateColumns={{ base: '100%', [APPLICANTS_FORM_BREAKPOINT]: '1fr 1fr' }}
            alignContent={'flex-start'}
            gap={'32px 24px'}>
            <InputFieldList listName="fields" />
          </Grid>
          <Box mt="24px">
            <BaseButton isLoading={isLoading}>Submit</BaseButton>
          </Box>
        </form>
      </FormProvider>
    </ApplicationFormWrapper>
  );
}

const ApplicationFormWrapper = styled.div`
  [data-testid='employee-upload'] {
    margin: 0;
  }

  .file-list:empty {
    margin: 0;
  }

  .file-list {
    line-height: 1.2;
  }
`;

function transformFormData(fields) {
  const fieldName = {
    'First name': 'firstName',
    'Last name': 'lastName',
    'Email address': 'email',
  };
  const ready = { applicationData: [] };
  fields.forEach((field) => {
    if (NON_EDITABLE_FIELD_NAMES.includes(field.name)) {
      const fieldNameKey = fieldName[field.name];
      ready[fieldNameKey] = field.value;
    }
    ready.applicationData.push(getNeededFields(field));
  });

  function getNeededFields(field) {
    const fieldNames = ['value', 'name', 'type', 'options', 'children'];
    const copy = {};
    Object.entries(field).forEach(([key, value]) => {
      if (fieldNames.includes(key)) {
        if (key === 'children') copy[key] = value.map(getNeededFields);
        else if (key === 'value') copy[key] = JSON.stringify(value?.value ?? value);
        else copy[key] = value;
      }
    });
    return copy;
  }

  return ready;
}
