import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { AttachFile } from 'pages/employee/dashboard/training/component/file';

const FileItem = styled(Flex)`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 1.2rem 1.6rem;
  max-width: 28.5rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  .file-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const AttachFiles = ({ handleSelectFiles, value }) => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();

  let fileData = files?.length ? files[0] : Object.keys(value)?.length ? value : '';

  const handleOnRemove = () => {
    setFiles([]);
    setFile();
  };

  useEffect(() => {
    setFile(fileData);
  }, [fileData, value]);

  useEffect(() => {
    handleSelectFiles(file);
  }, [file]);

  return (
    <>
      {file?.path || file ? (
        <FileItem>
          <Text className="file-text">{file?.path || file}</Text>
          <IoClose cursor={'pointer'} onClick={() => handleOnRemove()} />
        </FileItem>
      ) : (
        <AttachFile type="file" setFiles={setFiles} />
      )}
    </>
  );
};

export { AttachFiles };
