import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { BaseDropdown, SpinnerComponent } from 'components';

import plusIcon from '../../../../../../assets/icons/plusIcon.svg';
import { FETCH_ALL_ADMINS } from '../../../../../../query/admin/leave-management';

export function NotifyUponApprovalForm({ control }) {
  const { fields, remove, append } = useFieldArray({ control, name: 'notifyUsers' });

  const { data: allAdmins, loading } = useQuery(FETCH_ALL_ADMINS, {
    variables: {
      filter: {
        status: 'active',
        isDefaultAdmin: true,
      },
      page: 1,
    },
  });

  function modifyData(data) {
    return {
      value: data._id,
      label: `${data.firstName} ${data.lastName}`,
    };
  }

  function extractData(data) {
    let arr = [];
    for (let person of data) {
      arr.push(modifyData(person));
    }
    return arr;
  }

  if (loading) <SpinnerComponent />;

  return (
    <Stack>
      <Text marginBottom={'0.8rem'}>Notification Upon Approval</Text>
      <Stack
        w={'73.7rem'}
        p={'3.2rem'}
        border={'solid 1px var(--grey-250)'}
        gap={'2.4rem'}
        borderRadius={'0.4rem'}>
        {fields.map((field, index) => {
          return (
            <div key={field.id}>
              <Text fontSize={'1.2rem'} fontWeight={'600'} marginBottom={'0.8rem'}>
                {`Person or Group ${index + 1}`}
              </Text>
              <Flex alignItems={'center'} gap={'2.4rem'}>
                <Controller
                  defaultValue=""
                  control={control}
                  name={`notifyUsers.${index}`}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder={'Manager'}
                      options={extractData(allAdmins?.fetchAllAdmins?.data || [])}
                      w={'33.2rem'}
                    />
                  )}
                />
                <Text
                  color={'red'}
                  textDecoration={'underline'}
                  cursor={'pointer'}
                  data-testid={'removeNotifyUponApproval'}
                  onClick={() => {
                    remove(index);
                  }}>
                  Remove
                </Text>
              </Flex>
            </div>
          );
        })}
        <Flex
          gap={'0.8rem'}
          cursor={'pointer'}
          data-testid={'addNotifyUponApproval'}
          onClick={() => {
            append({ name: '' });
          }}>
          <img src={plusIcon} alt="" width={'15px'} height={'15px'} />
          <Text fontSize={'1.4rem'} color={'var(--green)'} textDecoration={'underline'}>
            Add Person
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
}
