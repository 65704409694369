import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastify } from 'hooks/useToastify';
import { CREATE_DEPARTMENT } from 'query';
import * as yup from 'yup';

import { BaseInput, CustomButton } from 'components';

import plusIcon from '../../../../../assets/icons/plusIcon.svg';

import { DesignationFields } from './Fields';

export const DepartmentSchema = yup.object().shape({
  name: yup.string().required('Department is a required field'),
});

export const AddDepartment = ({ setShow, refetch }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(DepartmentSchema),
  });

  const { successToast, errorToast } = useToastify();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'designation',
  });

  const [addNewDepartment, { loading }] = useMutation(CREATE_DEPARTMENT);

  const onSubmit = async (data) => {
    const newData = { name: data?.name, designation: [] };

    const dataToSend = data?.designation?.[0]?.name !== '' ? data : newData;

    try {
      const result = await addNewDepartment({ variables: { data: dataToSend } });
      const status = result?.data?.addNewDepartment?.status;
      if (status === 201) {
        const message = result?.data?.addNewDepartment?.message;
        successToast(message);
      } else if (status === 409) {
        const message = result?.data?.addNewDepartment?.message;
        errorToast(message);
      }
    } catch (error) {
      errorToast(error?.message || 'Error Occured');
    } finally {
      refetch();
      setShow(false);
    }
  };

  return (
    <DepartmentRoot>
      <h1>Create Department</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="form" data-testid="form">
        <BaseInput {...register('name')} label="Department" placeholder="Department" type="text" />
        <ErrorMessage>{errors?.name?.message}</ErrorMessage>
        <div className="add-designation">
          <Text>Designations</Text>
          <button
            data-testid="add-designation-btn"
            onClick={() => {
              append({ name: '' });
            }}
            className="btn"
            type="button">
            <img src={plusIcon} alt="add" />
            Add designations
          </button>
        </div>
        <div className="designations">
          <DesignationFields
            fields={fields}
            register={register}
            remove={remove}
            fieldName={'designation'}
          />
        </div>
        <CustomButton
          name="Save & Continue"
          variant="primary"
          h="4.8rem"
          type="submit"
          loading={loading}
        />
      </form>
    </DepartmentRoot>
  );
};

const ErrorMessage = styled.p`
  color: var(--error);
  font-size: 1.2rem;
  text-transform: capitalize;
`;

export const DepartmentRoot = styled.div`
  max-width: 42rem;
  margin: auto;
  h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    color: var(--black);
    font-weight: 500;
  }
  .form {
    margin-top: 4.8rem;
    display: grid;
    grid-gap: 1.6rem;

    .inputType {
      border: 1px solid transparent;
    }

    .designations {
      max-width: 36rem;
    }

    .remove-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.8rem;
      height: 3.4rem;
      background: #fafcff;
      border: 1px solid #f2f2f2;
      border-radius: 50%;
    }
  }

  .add-designation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      img {
        margin-right: 0.95rem;
      }
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
      letter-spacing: -0.117188px;
      text-decoration-line: underline;
      color: var(--green);
    }
  }
`;
