import { gql } from '@apollo/client';

export const FETCH_TIMELINE = gql`
  query fetchTimeline($jobId: ObjectID!) {
    fetchTimeline(jobId: $jobId) {
      status
      message
      data {
        timeline_month {
          userId {
            firstName
            lastName
            imageUrl
          }
          _id
          activity
          date
          jobId
        }
      }
    }
  }
`;
export const CREATE_NOTE = gql`
  mutation CreateNote($data: NoteInputData!) {
    addNewNote(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation Mutation($data: NoteEditInput!, $filters: NoteFilters!) {
    editNote(data: $data, filters: $filters) {
      status
      message
      data {
        _id
        title
        body
        jobId
      }
    }
  }
`;

export const FETCH_NOTES = gql`
  query fetchNotes($jobId: ObjectID!) {
    fetchNotes(jobId: $jobId) {
      status
      message
      data {
        note_month {
          createdBy {
            firstName
            lastName
            imageUrl
          }
          date
          _id
          title
          body
          jobId
          hour
          minute
        }
      }
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation Mutation($noteId: ObjectID!) {
    removeNote(noteId: $noteId) {
      status
      message
    }
  }
`;
