import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Avatar, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { format } from 'date-fns';

import { EmptyState, SpinnerComponent } from 'components';

import arrowdown from '../../../../assets/icons/ArrowDownOutline.svg';
import arrowright from '../../../../assets/icons/ArrowRight.svg';
import deletebtn from '../../../../assets/icons/DeleteBlack.svg';
import edittop from '../../../../assets/icons/Edit copy 2.svg';
import edit from '../../../../assets/icons/EditBlack.svg';
import { useToastify } from '../../../../hooks/useToastify';
import { CREATE_NOTE, DELETE_NOTE, UPDATE_NOTE } from '../../../../query';
import { Button } from '../button';
import { EditNote } from '../edit-notes';

const Container = styled.div`
  position: relative;
`;

const TimelineBox = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  margin: 32px 0;
  z-index: 10;

  .image {
    background: rgba(62, 126, 238, 0.1);
    padding: 10px;
    border-radius: 50%;
    color: #3e7eee;
    font-size: 14px;
    line-height: 12px;
    text-transform: uppercase;
  }
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242424;
  }

  .heading {
    position: sticky;
    top: 0px;
    display: flex;
    justify-content: space-between;
    background: #f3f4f6;
    padding: 16px 24px 16px 16px;
    border-radius: 4px 4px 0px 0px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #242424;
  }

  .date-header {
    display: flex;
    padding: 16px;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    &.withNotes {
      border: 1px solid #f2f2f2;
    }
  }

  .timelines {
    margin: 0 24px 0;

    border-top: 1px solid #f2f2f2;
  }
`;

const Note = styled.div`
  position: relative;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  z-index: 0;

  .column {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    gap: 16px;
  }

  .note-card {
    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #23273b;
      margin: 0px 0 4px;
    }

    .time {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #999fa3;
    }

    .note {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #23273b;
      margin: 8px 0 0px;
    }
  }

  .buttons {
    display: flex;
    gap: 16px;
  }

  hr {
    position: absolute;
    width: 100%;
    height: 1px;
    border-top-width: none;
    background-color: #f2f2f2;
    bottom: 0;
  }
`;

const Timeline = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 8px;
  transition: height ease 0.2s;
 
  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #83889d;
  }

  .timeline-indicator {
    position: relative;

    .timeline-circle {
      background: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #999fa3;
    }

    hr{
      position: absolute;
      left: 50%;
      transform: translateX(-50%)
      width: 1px;
      border: 0.5px dashed #000000;
      height: calc(100%);     
    }
  }

  .time-card {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: #23273b;
    margin: 10px 0px 14px;

    img {
      width: 24px;
    }

  
    .name{
      text-transform: capitalize;
    }
  }
`;

const TimelineCard = (props) => {
  const { id } = useParams();
  const [openEdit, setOpenEdit] = useState(false);
  const [state, setState] = useState();
  const [data, setData] = useState();

  const { successToast, errorToast } = useToastify();
  const [addNewNote, { loading: editNoteLoading }] = useMutation(CREATE_NOTE);

  const openEditNote = () => {
    setState();
    setOpenEdit(!openEdit);
  };

  const sortDates = (list) => {
    if (list && Array?.isArray(list)) {
      const newState = list
        ?.slice()
        ?.sort((a, b) => {
          if (props.withNotes) {
            return (
              new Date(a?.note_month[0]?.date).getTime() -
              new Date(b?.note_month[0]?.date).getTime()
            );
          } else {
            return (
              new Date(a?.timeline_month[0]?.date).getTime() -
              new Date(b?.timeline_month[0]?.date).getTime()
            );
          }
        })
        ?.reverse();
      setData(newState);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      jobId: id,
      [name]: value,
    }));
  };

  const onSave = async () => {
    try {
      const res = await addNewNote({ variables: { data: state } });
      const { data } = res;
      if (data && data.addNewNote.status === 201) {
        successToast('Note added sucessfully');
        props.refetchNotes();
      } else errorToast(data.addNewNote.message);
    } catch (err) {
      errorToast(err.message);
    }
    setOpenEdit(false);
  };

  const isDisabled = !(state?.title && state?.body);

  useEffect(() => {
    sortDates(props?.data);
  }, [props.data]);
  return (
    <Container>
      <TimelineBox>
        <div className="heading">
          <h1>{props?.title}</h1>
          {props.withNotes && (
            <Button img={edittop} dataTestId="openEditNote" onClick={() => openEditNote()} />
          )}
        </div>
        <EditNote
          heading={'Add Note'}
          isOpen={openEdit}
          handleChange={handleChange}
          onClose={openEditNote}
          onSave={onSave}
          isDisabled={isDisabled}
          value={state}
          isLoading={editNoteLoading}
        />
        {data?.length > 0 ? (
          data?.map((item, index) =>
            props.withNotes ? (
              <CardView
                data={item?.note_month}
                key={index}
                index={index}
                refetchNotes={props.refetchNotes}
                withNotes={props.withNotes}
              />
            ) : (
              <CardView data={item?.timeline_month} key={index} index={index} />
            )
          )
        ) : (
          <EmptyState message={'No Data Found'} />
        )}
        {props.loading && (
          <Grid minH="20rem" placeContent="center" mt="20rem">
            <SpinnerComponent data-testid="loading" />
          </Grid>
        )}
      </TimelineBox>
    </Container>
  );
};

const CardView = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const getDate = () => {
    {
      if (props?.data[0]?.date) {
        setDate(format(new Date(props?.data[0]?.date), 'MMMM dd, yyyy'));
      }
    }
  };
  useEffect(() => {
    getDate();
  }, [props.data]);

  return (
    <Card key={props.index}>
      <div>
        <div
          onClick={() => handleToggle()}
          className={`date-header ${props?.withNotes ? 'withNotes' : ''}`}>
          <Button dataTestId="handleToggle" img={isOpen ? arrowdown : arrowright} />
          <h2>{date}</h2>
        </div>
        <div className={`timelines ${props?.withNotes}`}>
          {props.withNotes
            ? isOpen &&
              props.data?.length > 0 &&
              props.data?.map((user, index) => (
                <NoteView
                  key={index}
                  data={user}
                  index={index}
                  refetchNotes={props.refetchNotes}
                  length={props.data?.length}
                />
              ))
            : isOpen &&
              props.data?.length > 0 &&
              props.data?.map((user, index) => (
                <TimelineView key={index} data={user} index={index} length={props.data?.length} />
              ))}
        </div>
      </div>
    </Card>
  );
};

const NoteView = (props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [state, setState] = useState();
  const isDisabled = !(state?.title && state?.body);
  const { successToast, errorToast } = useToastify();
  const [editNote, { loading: editNoteLoading }] = useMutation(UPDATE_NOTE);
  const [removeNote] = useMutation(DELETE_NOTE);

  const openEditNote = () => {
    setState({ title: props.data?.title, body: props.data?.body });
    setOpenEdit(!openEdit);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onUpdate = async () => {
    try {
      const res = await editNote({
        variables: {
          filters: {
            _id: props.data?._id,
          },
          data: state,
        },
      });

      const { data } = res;

      if (data && data.editNote.status === 200) {
        successToast('Note was edited sucessfully');
        props.refetchNotes();
      } else errorToast(data.editNote.message);
    } catch (err) {
      errorToast(err.message);
    }
    setOpenEdit(false);
  };

  const onDelete = async (id) => {
    try {
      const res = await removeNote({
        variables: {
          noteId: id,
        },
      });
      const { data } = res;
      if (data && data.removeNote.status === 200) {
        successToast('Note was deleted sucessfully');
        props.refetchNotes();
      } else errorToast(data.removeNote.message);
    } catch (err) {
      errorToast(err.message);
    }
    setOpenEdit(false);
  };

  return openEdit ? (
    <EditNote
      heading={'Edit Note'}
      isOpen={openEdit}
      handleChange={handleChange}
      onClose={openEditNote}
      onSave={onUpdate}
      isDisabled={isDisabled}
      value={state}
      isLoading={editNoteLoading}
    />
  ) : (
    <Note key={props.index}>
      <div className="column">
        {props.data?.createdBy[0]?.imageUrl ? (
          <img src={props.data?.createdBy[0]?.imageUrl} alt="" />
        ) : (
          <Avatar
            className="image"
            name={props.data?.createdBy[0]?.firstName + ' ' + props.data?.createdBy[0]?.lastName}
          />
        )}

        <div className="note-card">
          <h3>{props.data?.title}</h3>
          <p className="time" data-testid={'date'}>
            {props.data?.date && format(new Date(props.data?.date), 'hh:mm a')}
          </p>
          <p className="note">{props.data?.body}</p>
        </div>
      </div>
      <div className="buttons">
        <Button img={edit} style={{}} dataTestId="openEdit" onClick={() => openEditNote()} />
        <Button img={deletebtn} dataTestId="onDelete" onClick={() => onDelete(props.data?._id)} />
      </div>
      {props.index !== props?.length - 1 && <hr />}
    </Note>
  );
};
const TimelineView = (props) => {
  return (
    <Timeline key={props.index} data-testid={'timeline'}>
      <div className="timeline-indicator">
        <div className="timeline-circle"></div>
        {props.index !== props.length - 1 && <hr />}
      </div>
      <div className="timeline">
        <h3>{props.data?.date && format(new Date(props.data?.date), 'hh:mm a')}</h3>
        <div className="time-card">
          <p>{props.data?.activity}</p>
          {props.data?.userId[0]?.imageUrl ? (
            <img src={props.data?.userId[0]?.imageUrl} />
          ) : (
            <Avatar
              className="image"
              name={props.data?.userId[0]?.firstName + ' ' + props.data?.userId[0]?.lastName}
            />
          )}
          <p>{props.data?.userId[0]?.firstName + ' ' + props.data?.userId[0]?.lastName}</p>
        </div>
      </div>
    </Timeline>
  );
};

export { CardView, NoteView, TimelineCard, TimelineView };
