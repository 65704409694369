import React from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import clockIcon from '../../../../assets/icons/clock.svg';
import { EmptyState } from '../../../common';
import { CheckboxWrapper, CustomCheckbox } from '../../../shared';

import { TaskHook } from './hook';

const taskCat = {
  high: '#FF0000',
  medium: '#F0A800',
  low: '#3E7EEE',
};

const findCategory = (category) => {
  return taskCat[category];
};

export const TaskItem = ({ tasks, open }) => {
  const { updateTask, refetchQueries } = TaskHook({
    editing: false,
    state: {},
    closeModal: () => {},
    id: '',
    recurringDays: [],
  });
  const setAsCompleted = (e, task, id) => {
    const toSend = {
      completed: e.target.checked,
      date: task.date,
      daysToRepeat: task.daysToRepeat,
      description: task.description,
      endTime: task.endTime,
      priority: task.priority,
      repeatInterval: task.repeatInterval,
      startTime: task.startTime,
      title: task.title,
    };

    setCompleted(toSend, id);
  };

  async function setCompleted(task, id) {
    await updateTask({
      variables: { id: id, data: { ...task } },
      awaitRefetchQueries: true,
      refetchQueries: refetchQueries,
    });
  }

  return (
    <>
      {!tasks || tasks.length === 0 ? <EmptyState h="10rem" message="No tasks found" /> : null}
      {tasks.map((task) => {
        return (
          <Wrap key={task._id}>
            <TaskBox
              data-testid="edit-task"
              tasktype={task.priority}
              completed={task?.completed?.toString()}
              onClick={() => open(task)}>
              <Grid
                height="100%"
                templateColumns="auto max-content"
                alignItems="center"
                gridGap="1.2rem">
                <Grid templateColumns="21rem auto" gridGap="3rem" alignItems={'center'}>
                  <Box
                    height={'2.4rem'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRight="2px solid var(--grey-600)">
                    <Box mr="1.7rem">
                      <img src={clockIcon} alt="" />
                    </Box>
                    <Text className="time_value">{`${task.startTime} - ${task.endTime}`}</Text>
                  </Box>
                  <Text className="title_value">{task.title}</Text>
                </Grid>
              </Grid>
            </TaskBox>
            <CheckboxWrapper className="checkbox-wrap" data-testid={'checkbox'}>
              <CustomCheckbox
                testid="set-as-completed"
                checked={task.completed}
                variant={'blue-checkbox'}
                handleChecked={(e) => setAsCompleted(e, task, task?._id)}
              />
            </CheckboxWrapper>
          </Wrap>
        );
      })}
    </>
  );
};

const Wrap = styled.div`
  position: relative;
  .checkbox-wrap {
    height: 4rem;
    position: absolute;
    right: 1.3rem;
    top: 0.4rem;
    padding: 0 0.5rem;
    cursor: pointer;
  }
`;
const TaskBox = styled(Box)`
  height: 4.8rem;
  width: 100%;
  border-width: 0.1rem;
  border-color: rgba(97, 211, 206, 0.2);
  border-radius: 0.4rem;
  background-color: #fff;
  padding-right: 2rem;
  margin-bottom: 0.8rem;
  cursor: pointer;

  ${({ tasktype, completed }) =>
    `
    border-left: 3px solid ${tasktype ? findCategory(tasktype) : ''};
    opacity: ${completed === 'true' ? '0.5' : ''};
`}
  .title_value {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #23273b;
  }

  .time_value {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #999fa3;
  }
`;
