import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useReviewCycleForm } from 'context/reviewCycleForm';

import { CheckboxWrapper, CustomCheckbox } from '../../../../../../components';

import { SectionTitle } from './SectionTitle';

export function ResponseAccessStack() {
  const { register } = useReviewCycleForm();

  return (
    <Box fontSize="1.4rem">
      <SectionTitle mb="8px">Response Access</SectionTitle>
      <Text color="brand.grey.text" fontSize="1.2rem">
        By default, review responses can be seen the reviewee’s manager and admin
      </Text>
      <Box mt="16px" mb="5px" display="flex">
        <CheckboxWrapper>
          <CustomCheckbox
            {...register('isSeenByReviewee')}
            variant="blue-checkbox"
            type="checkbox"
            id="reviewee"
            style={{ accentColor: 'var(--primary)' }}
          />
        </CheckboxWrapper>
        <Text ml="10px" as="label" htmlFor="reviewee">
          Visible to reviewee
        </Text>
      </Box>
      <Box display="flex">
        <CheckboxWrapper>
          <CustomCheckbox
            {...register('isSeenByAll')}
            variant="blue-checkbox"
            type="checkbox"
            id="everyone"
          />
        </CheckboxWrapper>
        <Text ml="10px" as="label" htmlFor="everyone">
          Visible to everyone
        </Text>
      </Box>
    </Box>
  );
}
