import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Link } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import EyeCloseIcon from 'assets/icons/hide-password.svg';
import EyeOpenIcon from 'assets/icons/show-password.svg';
import { useToastify } from 'hooks/useToastify';
import { getItem, removeItem } from 'utils';
import * as yup from 'yup';

import { BaseButton, BaseInput } from '../../../components';
import { resetApplicantPassword } from '../../../query';

import { ErrorMessage, InputFields } from './styles';

const ApplicantResetForm = () => {
  const email = getItem('email');
  const [showIcon, setShowIcon] = useState(false);
  const [showConPass, setShowConPass] = useState(false);

  const getToken = getItem('resetToken');
  const token = getToken?.slice(1, -1);

  const { successToast, errorToast } = useToastify();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await resetApplicantPassword(token, data);
      successToast('Password reset successful, go back to login');
      removeItem('email');
      setIsLoading(false);
    } catch (error) {
      errorToast(error.message);
      setIsLoading(false);
    }
  };
  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const resetPassword = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .matches(
        passwordRules,
        'Must be at least 8 characters and contain a lowercase, uppercase, number and special case character'
      ),

    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], 'Passwords do not match'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      confirmPassword: '',
      password: '',
      applicant: {
        email,
      },
    },
    mode: 'onChange',
    resolver: yupResolver(resetPassword),
  });

  return (
    <>
      <InputFields onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <BaseInput
            {...register('password', { required: true })}
            label={'Password'}
            type={showIcon ? 'text' : 'password'}
            rightIcon={showIcon ? EyeOpenIcon : EyeCloseIcon}
            IconClick={() => setShowIcon(!showIcon)}
            name={'password'}
            placeholder={'Enter password'}
          />
          {errors?.password?.message && <ErrorMessage>{errors?.password?.message}</ErrorMessage>}
        </Box>
        <Box>
          <BaseInput
            {...register('confirmPassword', { required: true })}
            name="confirmPassword"
            type={showConPass ? 'text' : 'password'}
            rightIcon={showConPass ? EyeOpenIcon : EyeCloseIcon}
            IconClick={() => setShowConPass(!showConPass)}
            label={'Confirm Password'}
            placeholder={'Enter password'}
          />
          {errors?.confirmPassword?.message && (
            <ErrorMessage>{errors?.confirmPassword?.message}</ErrorMessage>
          )}
        </Box>
        <Flex alignItems={'center'} gap={'16px'} flexDirection={'column'} mt={'24px'}>
          <BaseButton w={'100%'} type={'submit'} isLoading={isLoading}>
            Save password
          </BaseButton>
          <p className="text">
            Take me back to{' '}
            <Link className="link" onClick={() => window.location.replace(`/applicant/login`)}>
              Login
            </Link>
          </p>
        </Flex>
      </InputFields>
    </>
  );
};

export { ApplicantResetForm };
