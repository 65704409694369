import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RETRIEVE_REVIEWS } from 'query/admin/performance/reviews';

import { SpinnerComponent } from 'components';
import { TableComponent } from 'components';
import { Pagination, Search } from 'components';

import { formatDate } from '../../../../../utils';
import { EditReviewDetails } from '../EditReview/EditReviewDetails';
import { EditReviewees } from '../EditReview/EditReviewees';

import ReviewsReport from './ReviewReport/ReviewsReport';
import { useActionOnData } from './hooks';
import { ReviewtableColumns } from './ReviewTableData';
import { ReviewStatus } from './ReviewTableStatus';

export const ReviewTableComponent = () => {
  const [searchString, setSearchString] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);

  const { data, loading, refetch } = useQuery(RETRIEVE_REVIEWS, {
    variables: {
      filters: {
        ...(searchString ? { searchString } : {}),
      },
      page,
    },
  });

  const {
    actionOnData,
    reviewIdDetails,
    setReviewIdDetails,
    reviewCycle,
    setReviewCycle,
    reviewReportCycle,
    setReviewReportCycle,
  } = useActionOnData({ data });

  const totalPages = data?.retrieveReviews?.pageCount || 1;
  const newTableData = data?.retrieveReviews?.data.map(
    ({ title, createdAt, reviewPeriod, status }) => {
      return {
        col1: title || '',
        col2: formatDate(createdAt) || '',
        col3: `${reviewPeriod.startDate} - ${reviewPeriod.endDate}` || '',
        col4: ReviewStatus({ status: status?.replace(/_/, ' ') }) || '',
      };
    }
  );

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const dropdownActions = {
    view: 'View Report',
    EditReview: 'Edit Review',
    EditReviewee: 'Edit Reviewee',
  };
  // if (loading) return <SpinnerComponent h="30rem" />;

  return (
    <Wrapper overflowX={'auto'} data-testid="ReviewTableComponent">
      <Flex marginBottom="24px">
        <Search
          placeholder={'Search Review'}
          value={search}
          setPage={setPage}
          setSearchString={setSearchString}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </Flex>
      {loading ? (
        <SpinnerComponent h="30rem" />
      ) : (
        <>
          <Box w="100%" className={'scroll-bar'} overflowX={'auto'}>
            <TableComponent
              columns={ReviewtableColumns}
              noCheckbox
              actionOnData={actionOnData}
              data={newTableData}
              ids={data?.retrieveReviews?.data.map((d) => d._id)}
              dropdownActions={dropdownActions}
            />
          </Box>
          <Box mt="24px">
            {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
          </Box>
        </>
      )}
      {reviewIdDetails ? (
        <EditReviewDetails
          reviewId={reviewIdDetails}
          setReviewId={setReviewIdDetails}
          refetch={refetch}
        />
      ) : null}
      {reviewCycle ? (
        <EditReviewees
          reviewCycle={reviewCycle}
          setReviewCycle={setReviewCycle}
          refetch={refetch}
        />
      ) : null}
      {reviewReportCycle ? (
        <ReviewsReport
          reviewReportCycle={reviewReportCycle}
          setReviewReportCycle={setReviewReportCycle}
          refetch={refetch}
        />
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: var(--white);
  margin: auto;
  padding: 24px;
  border: 1px solid var(--grey-150);

  thead th {
    background: #f3f4f6;
  }

  .th-parent {
    padding: 16px;
  }

  .tableCell {
    padding: 16px;
  }
`;
