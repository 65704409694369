import React from 'react';
import { useForm } from 'react-hook-form';
import { GridItem } from '@chakra-ui/react';
import cx from 'classnames';
import { format } from 'date-fns';

import { useHandleCreateJob, useJobDetailsForm } from '../../../../../../context';
import { useEditField } from '../hooks';

import { EditDetail } from './EditDetail';
import { PreviewDetail } from './PreviewDetail';

import { css } from '@emotion/css';

function AsideDetail({ item }) {
  const { name, type } = item;
  const { watch, setValue } = useJobDetailsForm();
  const { onEditJob } = useHandleCreateJob();
  const valueInForm = watch(name);
  let value = valueInForm;
  if (type === 'date') value = value instanceof Date && format(value, 'MMMM dd, yyyy');
  if (type === 'dropdown') value = value?.label;

  const defaults = { [name]: valueInForm };
  const formMethods = useForm({ defaultValues: defaults });
  const { handleSubmit, reset } = formMethods;

  const editOptions = useEditField({
    onSave: handleSubmit(onSave),
    reset,
    defaults,
  });
  const { isEditing } = editOptions;

  function onSave(data) {
    setValue(name, data[name]);
    onEditJob();
  }

  const childProps = {
    value,
    formMethods,
    ...item,
    ...editOptions,
  };

  return (
    <GridItem py="1.2rem" className={cx(detailStyles, { preview: !isEditing })} px="1.2rem">
      {!isEditing ? <PreviewDetail {...childProps} /> : <EditDetail {...childProps} />}
    </GridItem>
  );
}

const detailStyles = css`
  position: relative;
  border-radius: 4px;
  transition: background-color 300ms ease;

  &.preview:hover {
    background-color: #3e7eee1a;
  }

  .edit-control {
    display: none;
    position: absolute;
    cursor: pointer;
  }

  .edit-control .label {
    display: none;

    @media (min-width: 1300px) {
      display: block;
    }
  }

  &:hover .edit-control {
    display: grid;
    grid: 1fr / auto-flow max-content;
    align-items: center;
    gap: 8px;
    right: 1.6rem;
    top: 2rem;
    color: var(--primary);
  }
`;

export default AsideDetail;
