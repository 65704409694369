import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';

import { BaseDropdown, JobInputLabel } from '../../../../components';
import { useJobDetailsForm } from '../../../../context';
import { FETCH_ASSESSMENT_TITLES } from '../../../../query';

import ToggleSwitch from './ApplicantsForm/components/ToggleSwitch';

export function JobAssessmentSwitch() {
  const formMethods = useJobDetailsForm();
  const { control, setValue } = formMethods;
  const [defaultOptions, setOptions] = React.useState([]);

  const assessmentSwitch = useWatch({
    control,
    name: 'assessment-switch',
  });

  const [fetchAssessments] = useLazyQuery(FETCH_ASSESSMENT_TITLES);

  React.useEffect(() => {
    getAssessmentsAsOptions('').then((res) => setOptions(res));
  }, []);

  React.useEffect(() => {
    if (!assessmentSwitch) {
      setValue('assessment', null);
    }
  }, [assessmentSwitch]);

  function loadOptions(searchString) {
    return getAssessmentsAsOptions(searchString);
  }

  async function getAssessmentsAsOptions(searchString) {
    return fetchAssessments({
      variables: {
        filter: { keyword: searchString },
        page: 1,
      },
    }).then((res) => {
      return res.data?.fetchTitles?.data?.map((assessment) => ({
        value: assessment._id.id,
        label: assessment._id.title,
      }));
    });
  }

  return (
    <Box w="100%">
      <Flex justifyContent="space-between" my="26px" fontSize="1.2rem">
        Add assessment for applicants
        <ToggleSwitch
          data-testid={'assessment-switch'}
          {...formMethods.register('assessment-switch')}
        />
      </Flex>

      {assessmentSwitch ? (
        <>
          <JobInputLabel>Select Assessment</JobInputLabel>
          <Controller
            control={control}
            name="assessmentId"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                defaultOptions={defaultOptions}
                loadOptions={loadOptions}
                placeholder="Choose assessment"
                w={'100%'}
                clearable
                async
              />
            )}
          />
        </>
      ) : null}
    </Box>
  );
}
