import { gql } from '@apollo/client';

export const FETCH_LEAVE_TYPE = gql`
  query retrieveLeaveTypes {
    retrieveLeaveTypes {
      status
      message
      data {
        _id
        name
        description
        paidTimeSetting
      }
    }
  }
`;

export const CREATE_LEAVE_TYPE = gql`
  mutation addLeaveType($data: LeaveTypeInput!) {
    addLeaveType(data: $data) {
      status
      message
      data {
        _id
        name
        description
        paidTimeSetting
      }
    }
  }
`;
export const UPDATE_LEAVE_TYPE = gql`
  mutation editLeaveType($filters: LeaveTypeFilters!, $data: LeaveTypeEdit!) {
    editLeaveType(filters: $filters, data: $data) {
      status
      message
      data {
        _id
        name
        description
        paidTimeSetting
      }
    }
  }
`;
export const DELETE_LEAVE_TYPE = gql`
  mutation deleteLeaveType($leaveId: ObjectID!) {
    deleteLeaveType(leaveId: $leaveId) {
      status
      message
    }
  }
`;
