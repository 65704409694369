export const columns = [
  {
    Header: 'Employee Name',
    accessor: 'employeeName',
  },
  {
    Header: 'Team',
    accessor: 'team',
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
  },
  {
    Header: 'Status',
    accessor: 'isCompleted',
  },
];
