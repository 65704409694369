import * as yup from 'yup';

const testStringOrObject =
  (validation = null, msg = null) =>
  (val) => {
    const isString = typeof val === 'string';
    const schema = yup.object().shape({
      val: isString ? yup.string().required(msg) : validation || yup.object().required(msg),
    });
    return schema.isValidSync({ val });
  };

export const ReviewFormSchema = yup.object().shape({
  title: yup.string().required(),
  templateId: yup.object().required('select a template for this review'),
  frequency: yup.mixed().test({
    message: 'choose frequency of review',
    test: testStringOrObject(null, 'choose frequency of review'),
  }),
  schedule: yup
    .object()
    .shape({
      startDate: yup.string().required('select schedule start date'),
      endDate: yup.string().required('select schedule end date'),
    })
    .required(),
  reviewPeriod: yup
    .object()
    .shape({
      startDate: yup.string().required('select period start date'),
      endDate: yup.string().required('select period end date'),
    })
    .required(),
  // REVIEW TYPE
  reviewType: yup
    .array()
    .required('select at least 1 review type')
    .min(1, 'select at least 1 review type')
    .of(
      yup.object().shape({
        name: yup.string().required(),
        description: yup.string().required(),
        score: yup.number().required().min(1, 'score must be more than 0'),
      })
    )
    .test({
      message: 'Each Review Type should have a score',
      test: (val) => val?.every((type) => type.score),
    })
    .test({
      message: 'Review Type Scores should add up to 100',
      test: (val) => val?.reduce((accum, cur) => accum + Number(cur.score), 0) === 100,
    }),
  // REVIEWEES
  reviewCycle: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        employee: yup.mixed().test({ test: testStringOrObject() }).required(),
        reviewers: yup
          .array()
          .of(yup.mixed().test({ test: testStringOrObject() }))
          .required('select at least 1 reviewer')
          .min(1, 'select at least 1 reviewer'),
      })
    )
    .required('select at least 1 employee to review'),
});
