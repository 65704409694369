import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, SimpleModal, SpinnerComponent } from '../../../../../components';
import {
  ReviewCycleFormProvider,
  useReviewCycleForm,
} from '../../../../../context/reviewCycleForm';
import { RETRIEVE_REVIEWS } from '../../../../../query';
import { ReviewDetails } from '../CreateReview/components/ReviewDetails';
import { useSubmitReview } from '../hooks/useSubmitReview';

import { transformReviewForForm } from './transformReviewForForm';

export function EditReviewDetails(props) {
  const { data, loading } = useQuery(RETRIEVE_REVIEWS, {
    variables: {
      filters: {
        _id: props.reviewId,
      },
    },
  });

  const reviewData = data?.retrieveReviews?.data?.[0];

  return (
    <Wrapper>
      <SimpleModal showModal={!!props.reviewId} setShowModal={props.setReviewId}>
        <Box
          mt="24px"
          mx="24px"
          p="24px"
          w="826px"
          maxW="90vw"
          border="1px solid var(--grey-150)"
          boxSizing="border-box">
          <Text as="h2" className="header-text">
            Edit Review Details
          </Text>
          {loading ? (
            <SpinnerComponent h="60vh" />
          ) : (
            <ReviewCycleFormProvider
              initialValues={{ defaultValues: transformReviewForForm(reviewData) }}>
              <EditReviewDetailsForm setReviewId={props.setReviewId} refetch={props.refetch} />
            </ReviewCycleFormProvider>
          )}
        </Box>
      </SimpleModal>
    </Wrapper>
  );
}

function EditReviewDetailsForm(props) {
  const { handleSubmit } = useReviewCycleForm();
  const { onEditDetails, updating } = useSubmitReview({
    setShowModal: props.setReviewId,
    onAfterSubmit: props.refetch,
  });

  return (
    <>
      <ReviewDetails maxW="auto" mx="auto" p="0" border="none" />
      <Flex justifyContent="space-between" mt="24px">
        <BaseButton variant="outline" onClick={() => props.setReviewId(false)}>
          Back
        </BaseButton>
        <BaseButton variant="dark" onClick={handleSubmit(onEditDetails)} isLoading={updating}>
          Save
        </BaseButton>
      </Flex>
    </>
  );
}

const Wrapper = styled.div`
  .header-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.05em;
  }
`;
