import React from 'react';
import { useEffect, useState } from 'react';
import { Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import styled from '@emotion/styled';

import arrowdown from '../../../../assets/icons/Arrowdown white.svg';
import draft from '../../../../assets/icons/draft white.svg';
import published from '../../../../assets/icons/published white.svg';

const Container = styled.div`
  background: #3e7eee;

  .draft {
    background: #999fa3;
  }

  border-radius: 4px;
`;

const StatusDropdown = (props) => {
  const initialState = {
    label: 'Draft',
    value: 'draft',
  };
  const [active, setActive] = useState(initialState);
  const handleStatusChange = (status) => {
    setActive(status);
    props.onClick(status.value);
  };

  const setSelected = (value) => {
    if (value) {
      const newState = props.status?.filter((status) => {
        return status?.value?.toLowerCase() === value?.toLowerCase();
      });
      setActive(...newState);
    }
  };

  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  useEffect(() => {
    props.refetchTimeLine();
    props.refetchJobDetails();
  }, [active]);

  return (
    <Container>
      <Menu>
        <MenuButton width={'145px'} height={'40px'} className={`${active.value}`}>
          <Flex padding={'12px 18px'} justifyContent={'space-between'}>
            <Flex gap={'6px'} fontWeight={500} fontSize={'14px'}>
              {active.value === 'draft' ? (
                <img src={draft} alt="" />
              ) : (
                <img src={published} alt="" />
              )}
              <p>{active.label}</p>
            </Flex>
            <img src={arrowdown} />
          </Flex>
        </MenuButton>
        <MenuList zIndex={100} padding={'16px'} maxH="30rem" overflow="auto" color={'black'}>
          {props.status?.map((status) => (
            <MenuItem
              data-testid={status.value}
              gap={'10px'}
              display={'flex'}
              padding={'12px'}
              value={status.value}
              key={status.value}
              onClick={() => handleStatusChange(status)}>
              {status.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Container>
  );
};

export { StatusDropdown };
