import { Route, Routes } from 'react-router-dom';
import { Annoucements } from 'pages/admin/announcements/Annoucements';
import { ApplicantViewTable } from 'pages/admin/candidates/CandidateTableView';
import { Documents } from 'pages/admin/documents/DocumentView';
import { ExitedEmployees } from 'pages/admin/exit-management/ExitedEmployees';
import { ExitManagement } from 'pages/admin/exit-management/ExitManagementView';
import { AdminTraining } from 'pages/admin/learning-and-development/TrainingView';
import { SingleRequest } from 'pages/admin/leave-management/Requests/SingleRequest';
import RevieweeReportView from 'pages/admin/performance/reviews/RevieweeReport/RevieweeReportView';
import ReviewsReport from 'pages/admin/performance/reviews/ReviewTable/ReviewReport/ReviewsReport';
import { Query } from 'pages/admin/Query/Query';
import { ReportView } from 'pages/admin/reports';
import {
  AgesProfileReport,
  Assets,
  DepartmentProfile,
  HeadCount,
  HireExits,
  TrainingReports,
} from 'pages/admin/reports';
import { Timesheet } from 'pages/admin/timesheet';

import { AdminDashboard } from '../layouts';
import {
  AddAsset,
  AddJobs,
  AddNewAssessment,
  AdminPerformance,
  ApplicationView,
  AssessmentView,
  AssetManagement,
  DashboardHome,
  EditTemplate,
  EmployeePage,
  EmployeePerformance,
  GoalsView,
  Inbox,
  JobsView,
  LeaveManagementView,
  LineManagerView,
  PreviewPage,
  PublishedJob,
  ReviewTemplates,
  Settings,
} from '../pages';
import { ReviewsRevieweeList } from '../pages/admin/performance/reviews/RevieweeList/RevieweeList';
import { GeneralMessageSubscription } from '../pages/inbox/GeneralMessageSubscription';

import PrivateRoute from './PrivateRoute';

function AdminRoutes() {
  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <PrivateRoute authRoute="/login">
              <AdminDashboard />
            </PrivateRoute>
          }>
          <Route path="ReviewsReport" element={<ReviewsReport />} />
          <Route path="" element={<DashboardHome />} />
          <Route path="welcome" element={<h1>Welcome mr Admin</h1>} />
          <Route path="employee" element={<EmployeePage />} />
          <Route path="timesheets" element={<Timesheet />} />
          <Route path="announcements" element={<Annoucements />} />
          <Route path="leave-management">
            <Route path="" element={<LeaveManagementView />} />
          </Route>
          <Route path="leave-management/request/:id" element={<SingleRequest />} />
          <Route path="jobs">
            <Route path="" element={<JobsView />} />
            <Route path="add" element={<AddJobs />} />
            <Route path=":id" element={<PublishedJob />} />
          </Route>
          <Route path="candidates">
            <Route path="" element={<ApplicationView />} />
            <Route path=":id" element={<ApplicantViewTable />} />
          </Route>
          <Route path="exit-management">
            <Route path="" element={<ExitManagement />} />
            <Route path="exited-employees" element={<ExitedEmployees />} />
          </Route>
          <Route path="performance">
            <Route path="" element={<AdminPerformance />} />
            <Route path="">
              <Route path="" element={<GoalsView />} />
            </Route>
            <Route path="reviews/:id" element={<ReviewsRevieweeList />} />
            <Route path="reviews/:id/reviewee/:rid" element={<RevieweeReportView />} />
            <Route path="" element={<ReviewTemplates />} />
            <Route path="edit-template/:id" element={<EditTemplate />} />
            <Route path="review-report" element={<ReviewsReport />} />
          </Route>
          <Route path="appraisal" element={<EmployeePerformance />}></Route>

          <Route path="training" element={<AdminTraining />} />
          <Route path="asset">
            <Route path="" element={<AssetManagement />} />
            <Route path="add" element={<AddAsset />} />
          </Route>
          <Route path="line-manager" element={<LineManagerView />} />
          <Route path="assessments">
            <Route path="" element={<AssessmentView />} />
            <Route path="add" element={<AddNewAssessment />} />
            <Route path="preview/:title" element={<PreviewPage />} />
          </Route>
          <Route path="documents" element={<Documents />} />
          <Route path="query" element={<Query />} />
          <Route path="inbox">
            <Route path="" element={<Inbox />} />
          </Route>
          <Route path="reports">
            <Route path="" element={<ReportView />}></Route>
            <Route path="training-report" element={<TrainingReports />}></Route>
            <Route path="hire-exits" element={<HireExits />}></Route>
            <Route path="ages" element={<AgesProfileReport />}></Route>
            <Route path="department-profile" element={<DepartmentProfile />}></Route>
            <Route path="head-count" element={<HeadCount />}></Route>
            <Route path="assets" element={<Assets />} />
          </Route>
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
      <GeneralMessageSubscription />
    </>
  );
}

export { AdminRoutes };
