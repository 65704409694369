import React from 'react';
import styled from '@emotion/styled';

import { FullModal } from 'components';

import { OnboardingSetupView } from './setup-view/onboardingSetupView';
import { WelcomePage } from './Welcome';

export const OnboardingPage = (props) => {
  const [showSetupDetails, setShowSetupDetails] = React.useState(false);
  const { setOnboarded, continueSetup, setSetup } = props;
  return (
    <OnboardingWrapper>
      <WelcomePage
        skip={() => setOnboarded(true)}
        setShowSetupDetails={setShowSetupDetails}
        continueSetup={continueSetup}
        setSetup={setSetup}
      />

      {showSetupDetails && (
        <FullModal noHeader style={{ marginTop: '8rem' }} showModal={showSetupDetails}>
          <OnboardingSetupView setShowDetails={setShowSetupDetails} />
        </FullModal>
      )}
    </OnboardingWrapper>
  );
};

const OnboardingWrapper = styled.div`
  height: auto;

  .full-modal-wrapper {
    padding: 2.4rem;
  }

  .right-modal-overlay {
    border: none;
    padding-top: 2rem;
  }
`;
