import { gql } from '@apollo/client';

export const CREATE_JOB = gql`
  mutation CreateJob($data: JobInput!) {
    createJob(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;
export const DELETE_JOB = gql`
  mutation Mutation($jobId: ObjectID!) {
    deleteJob(jobId: $jobId) {
      status
      message
    }
  }
`;

export const EDIT_JOB = gql`
  mutation Mutation($filter: JobFilters!, $data: JobUpdateInput!) {
    updateJob(filter: $filter, data: $data) {
      status
      message
      data {
        _id
        status
      }
    }
  }
`;

export const FETCH_JOBS = gql`
  query Query($filter: JobFilters, $page: Int!) {
    fetchJobs(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      totalCount
      data {
        _id
        title
        createdBy {
          _id
          firstName
          lastName
        }
        formFields {
          name
        }
        department {
          name
        }
        country
        state
        city
        type
        applicants {
          firstName
          lastName
        }
        status
      }
    }
  }
`;

export const FETCH_SINGLE_JOB = gql`
  query Query($filters: JobFilters!) {
    fetchSingleJob(filters: $filters) {
      status
      message
      data {
        _id
        title
        createdBy {
          _id
          imageUrl
        }
        department {
          _id
          name
        }
        headerImage
        createdAt
        country
        deadline
        description
        state
        city
        type
        experience
        state
        salary
        skills
        formFields {
          name
          required
          multiple
          options
          type
          children {
            name
            required
            multiple
            options
            type
          }
        }
        scoreCards {
          id: _id
          type
          title
          hint
          stage
        }
        applicants {
          firstName
          lastName
        }
        timeline {
          user {
            _id
            firstName
            lastName
            imageUrl
          }
          date
          activity
        }
        notes {
          user {
            _id
            firstName
            lastName
            imageUrl
          }
          title
          description
          date
        }
        status
        assessmentId {
          _id
          title
        }
      }
    }
  }
`;

export const UPLOAD_HEADER_IMAGE = gql`
  mutation UploadHeaderImage($file: Upload!) {
    uploadHeaderImage(file: $file) {
      data
      message
      status
    }
  }
`;
