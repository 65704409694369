import { gql } from '@apollo/client';

const GET_DEPARTMENT = gql`
  query Query($page: Int) {
    fetchCompanyDepartments(page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        name
        employeesCount
        createdBy {
          _id
          firstName
          lastName
        }
        designation {
          name
          _id
        }
        createdAt
      }
    }
  }
`;

const GET_SINGLE_DEPARTMENT = gql`
  query Query($id: ObjectID!) {
    fetchSingleDepartment(id: $id) {
      status
      message
      data {
        _id
        name
        designation {
          _id
          name
        }
      }
    }
  }
`;

const CREATE_DEPARTMENT = gql`
  mutation Mutation($data: [DepartmentInput]!) {
    addNewDepartment(data: $data) {
      status
      message
      data {
        _id
        name
        designation {
          name
        }
      }
    }
  }
`;

const EDIT_DEPARTMENT = gql`
  mutation Mutation($_id: ObjectID!, $data: DepartmentUpdateInput!) {
    editDepartment(_id: $_id, data: $data) {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;

const DELETE_DEPARTMENT = gql`
  mutation Mutation($_id: ObjectID!) {
    deleteDepartment(_id: $_id) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export {
  CREATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  EDIT_DEPARTMENT,
  GET_DEPARTMENT,
  GET_SINGLE_DEPARTMENT,
};
