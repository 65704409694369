import React from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AnimatedSection } from 'components/AnimateSection';
import { Logo, WhiteEnyataText, WhiteLogo } from 'components/shared/Svgs';

import bg from '../../../assets/images/bg.svg';

export const LFooter = () => {
  function onSubmit() {
    const emailSubject = encodeURIComponent('Inquiry about Your HRMS System');
    const emailBody = encodeURIComponent(
      `Hello, \n\nI am reaching out to express my interest in your HRMS (Human Resource Management System) after learning about it online.\n\nI am currently exploring options for an HRMS system to streamline our human resources processes and enhance overall efficiency within our organization.\n\nThank you in advance for your time and assistance. I look forward to hearing from you soon.`
    );

    const mailtoLink = `mailto:partnerships@enyata.com?subject=${emailSubject}&body=${emailBody}`;

    window.location.href = mailtoLink;
  }

  const Wrapper = styled(Stack)`
    @media (max-width: 768px) {
      .textContainer {
        max-width: 397px;
        width: 100%;
      }
      .textSize {
        font-size: 36px;
        line-height: 44px;
      }
      .textsub {
        font-size: 16px;
        line-height: 30px;
      }
    }
  `;
  const RowReverse = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: flex-start;
    max-width: calc(1440px - 159px);
    width: 100%;
    margin: 0 auto;
    @media (max-width: 768px) {
      max-width: 397px;
      width: 100%;
      margin: 0 auto;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 53px;
    }
  `;
  return (
    <Wrapper>
      <div className="container">
        <AnimatedSection>
          <Box
            p={{ base: '90px 0', md: '170px' }}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            backgroundImage={bg}
            backgroundPosition={'cover'}
            backgroundRepeat={'repeat'}>
            <Flex
              flexDirection={'column'}
              gap={'24px'}
              margin={'0 auto'}
              className="textContainer"
              justifyContent={'center'}>
              <Box margin={'0 auto'}>{Logo}</Box>
              <Text
                className="textSize"
                fontSize={'56px'}
                textAlign={'center'}
                fontWeight={'700'}
                lineHeight={'72px'}
                maxWidth={'633px'}
                width={'100%'}>
                <span style={{ color: '#5380E0' }}>Experience </span>
                the HRMS Process and Business.
              </Text>
              <Text
                maxWidth={'620px'}
                width={'100%'}
                color={'#475467'}
                textAlign={'center'}
                fontSize={'18px'}
                lineHeight={'30px'}
                className="textsub">
                {`With a wide array of powerful features, HRMS empowers you to efficiently manage every
          aspect of your organisation's human capital.`}
              </Text>
              <Box
                padding={'5px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={'10px'}
                borderRadius={'1000px'}
                background={'#CAD8F3'}
                width={'155px'}
                margin={'0 auto'}>
                <button
                  style={{
                    padding: '16px 0',
                    borderRadius: '1000px',
                    background: '#3E7EEE',
                    width: '145px',
                    color: '#fff',
                  }}
                  onClick={onSubmit}>
                  Get Started
                </button>
              </Box>
            </Flex>
          </Box>
        </AnimatedSection>
      </div>
      <Stack bgColor={'#23273B'}>
        <AnimatedSection>
          <div className="container">
            <Flex flexDirection={'column'} gap={'41px'} pt={'48px'}>
              <RowReverse>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'12px'}
                  maxWidth={'457px'}
                  width={'100%'}>
                  <Flex alignItems={'center'} gap={'4px'}>
                    {WhiteLogo}
                    <Box>
                      <Text fontSize={'5.203px'} color={'#fff'} fontWeight={'700'}>
                        HRMs by
                      </Text>
                      {WhiteEnyataText}
                    </Box>
                  </Flex>
                  <Text color={'#A9AEC1'} fontSize={'14px'} lineHeight={'24px'}>
                    A technology company providing engineering-as-a-service solutions at scale to
                    organizations across multiple industries. We are a legion of product,
                    engineering, and design experts helping organisations develop world-class
                    products.
                  </Text>
                </Box>
                <Flex flexDirection={'column'} gap={'24px'}>
                  <Box>
                    <Text
                      color={'#fff'}
                      fontSize={'18px'}
                      lineHeight={'24px'}
                      fontWeight={'700'}
                      textTransform={'capitalize'}>
                      Our Newsletter
                    </Text>
                    <Text
                      color={'#A9AEC1'}
                      fontSize={'14px'}
                      lineHeight={'24px'}
                      maxWidth={'457px'}
                      width={'100%'}>
                      Be the first one to know about discounts, offers and events. Unsubscribe
                      whenever you like.
                    </Text>
                  </Box>
                </Flex>
              </RowReverse>
              <Box
                borderTop={'1px solid #34384E'}
                color={'#9699A8'}
                fontSize={'14px'}
                flexWrap={'wrap'}
                p={'24px 0'}
                gap={{ base: '8px', md: '56px' }}
                display={'flex'}
                justifyContent={'space-between'}>
                <ul style={{ display: 'flex', gap: '40px', listStyleType: 'none' }}>
                  <li>Contact</li>
                  <li>Privacy</li>
                  <li>Terms of Use</li>
                </ul>
                <Text>© 2023, All Rights Reserved</Text>
              </Box>
            </Flex>
          </div>
        </AnimatedSection>
      </Stack>
    </Wrapper>
  );
};
