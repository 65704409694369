import React from 'react';
import { useDrop } from 'react-dnd';
import { Box, Grid, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import cx from 'classnames';
import { DND_TYPES } from 'utils';

import { AddOptionIcon } from 'components/shared/Svgs';

import { SpanDouble } from '../../../../../../components';
import { createInput, INPUT_TYPES } from '../helpers';

import { InputTypeIcon } from './InputTypeIcon';

import { css } from '@emotion/css';

function EmptySpace({ append, insert, inSection, index }) {
  function onAddClick(type) {
    const newField = createInput({ name: '', type, isEditing: true });
    if (inSection) append(newField);
    if (!inSection) insert(index, newField);
  }

  const onDrop = (d) => {
    const newField = createInput({ type: d.type, isEditing: true });
    if (inSection) append(newField);
    if (!inSection) insert(index, newField);
  };

  const [{ isOver }, drop] = useDrop({
    accept: DND_TYPES.ApplicantInputOption,
    drop: (d) => onDrop(d),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <SpanDouble>
      <Menu>
        <MenuButton className={cx(rootStyles, { isOver })} ref={drop} w="100%">
          <Grid
            minH="4.8rem"
            borderRadius="4px"
            border="2px dashed var(--grey-300)"
            placeContent="center"
            alignItems="center"
            gap="1rem"
            templateColumns="repeat(2, max-content)"
            cursor="pointer"
            color="brand.primary"
            className="button-root">
            {AddOptionIcon}Add Field
          </Grid>
        </MenuButton>
        <MenuList>
          {Object.values(INPUT_TYPES)
            .filter((type) => type !== INPUT_TYPES.Section)
            .map((type) => (
              <MenuItem key={type} style={{ paddingBlock: '8px' }} onClick={() => onAddClick(type)}>
                <Box
                  display="grid"
                  gap="8px"
                  gridTemplateColumns="repeat(2, max-content)"
                  alignItems="center">
                  <Box transform="scale(0.75)">
                    <InputTypeIcon type={type} />
                  </Box>
                  {type}
                </Box>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </SpanDouble>
  );
}

const rootStyles = css`
  .button-root {
    transition: transform 200ms ease, color 200ms ease, background-color 200ms ease,
      border-color 200ms ease;
    color: var(--grey-700);
  }

  svg * {
    transition: fill 200ms ease;
    fill: var(--grey-700);
  }

  &:hover .button-root,
  &:active .button-root,
  &[aria-expanded='true'] .button-root {
    color: var(--primary);
    background: var(--primary-light);
    border-color: var(--primary);

    svg * {
      fill: var(--primary);
    }
  }

  &.isOver {
    .button-root {
      background: var(--green-light);
      border-color: var(--green);
      color: var(--green);
      box-shadow: 8px 12px 4px rgba(24, 180, 119, 0.1);
      transform: translate(-4px, -6px);

      svg * {
        fill: var(--green);
      }
    }
  }
`;

export { EmptySpace };
