import React from 'react';
import { IoClose } from 'react-icons/io5';
import { Box, Flex } from '@chakra-ui/react';

import { BaseInput } from 'components';

export const DesignationFields = (props) => {
  const { register, fieldName, remove, fields } = props;

  return (
    <>
      {fields.map((item, index) => (
        <Box key={item.id} mb="1.2rem">
          <Flex gap="1.2rem" alignItems="center" justifyContent="flex-end" mb="1.2rem">
            <BaseInput {...register(`${fieldName}.${index}.name`)} placeholder="Designation" />
            <button
              onClick={() => {
                remove(index);
              }}
              className="remove-btn">
              <IoClose size="18" />
            </button>
          </Flex>
        </Box>
      ))}
    </>
  );
};
