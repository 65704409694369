export const columns = [
  {
    Header: 'Employee Name',
    accessor: 'employeeName',
  },
  {
    Header: 'Leave Type',
    accessor: 'leaveType',
  },
  {
    Header: 'Leave Duration',
    accessor: 'leaveDuration',
  },
  {
    Header: 'Count Balance',
    accessor: 'countBalance',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
export const tData = [
  {
    _id: 1,
    employee: {
      firstName: 'Gawusu',
      lastName: 'Razak',
    },
    leaveType: 'Annual Leave',
    leaveDuration: 'Jul 27 - Aug 4, 2023',
    countBalance: '3 days',
    status: 'approved',
  },
  {
    _id: 2,
    employee: {
      firstName: 'Jason',
      lastName: 'Appiatu',
    },
    leaveType: 'Holidays',
    leaveDuration: 'Jul 27 - Aug 4, 2023',
    countBalance: '7 days',
    status: 'rejected',
  },
  {
    _id: 3,
    employee: {
      firstName: 'Daverose',
      lastName: 'Tholley',
    },
    leaveType: 'Holidays',
    leaveDuration: 'Jul 27 - Aug 4, 2023',
    countBalance: '7 days',
    status: 'pending',
  },
];
