import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { UPDATE_LEAVE } from 'query';
import { FETCH_SINGLE_LEAVE_REQUEST } from 'query';
import { formatDate } from 'utils';

import { BaseInput, CustomButton, SpinnerComponent } from 'components';

const DenyPrompt = ({ cancel, leaveId, refetch }) => {
  const [reason, setReason] = React.useState('');
  const [changeLeaveStatus, { loading }] = useMutation(UPDATE_LEAVE);

  const { errorToast, successToast } = useToastify();

  const { data, loading: fetching } = useQuery(FETCH_SINGLE_LEAVE_REQUEST, {
    variables: { leaveId: leaveId },
  });

  const leaveData = data?.fetchSingleRequest?.data;

  async function denyLeave() {
    const date = new Date().toISOString();
    const toSend = {
      date: date,
      status: 'rejected',
      reasonForDenial: reason,
    };
    if (reason !== '') {
      try {
        const response = await changeLeaveStatus({
          variables: {
            data: toSend,
            filters: {
              _id: leaveId,
            },
          },
        });
        const res = response.data.leaveRequestApproval;
        const { status, message } = res;
        status === 200
          ? successToast(message)
          : errorToast('You are not allowed to perform this action');
      } catch (error) {
        return error;
      } finally {
        refetch();
        cancel({ showModal: false });
      }
    } else {
      errorToast('Provide a reason');
    }
  }

  const startDate = formatDate(leaveData?.startDate);
  const endDate = formatDate(leaveData?.endDate);

  return (
    <DenyRoot className="" data-testid="deny-prompt">
      <h2>Deny Leave Request</h2>
      {fetching ? (
        <SpinnerComponent />
      ) : (
        <>
          <div className="subtexts">
            <p className="qst">
              You are about to deny
              <span>
                {` ${leaveData?.employeeId?.firstName} ${leaveData?.employeeId?.lastName} `}
              </span>
              leave request:
            </p>
            <p className="req--date">{`${startDate || ''} - ${endDate || ''}`}</p>
          </div>

          <BaseInput
            onChange={(e) => setReason(e.target.value)}
            type="textarea"
            fontSize={'1.6rem'}
            color={'var(--grey-600'}
            placeholder={'Provide a reason for denying the leave request'}
            data-testid="reason"
          />
        </>
      )}

      <Flex justifyContent={'flex-end'} gap="0.8rem" mt="6.4rem">
        <CustomButton
          onClick={() => cancel({ showModal: false })}
          name="Cancel"
          variant="black--outline"
          mW="9.5rem"
          h="4.3rem"
        />
        <CustomButton
          onClick={denyLeave}
          name="Deny Request"
          variant="black"
          mW="14.5rem"
          h="4.3rem"
          loading={loading}
        />
      </Flex>
    </DenyRoot>
  );
};

export const DenyRoot = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 1.6rem 2.4rem;
  border: 1px solid var(--grey-150);
  border-radius: 0.4rem;
  .subtexts {
    margin-bottom: 2.4rem;

    .qst {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: var(--black);

      span {
        text-transform: capitalize;
      }
    }
    .req--date {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: var(--black);
    }
  }
  .stress--qst {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--black);
    margin-bottom: 3.2rem;
  }

  h2 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.117188px;
    color: var(--black);
    margin-bottom: 1.6rem;
  }
`;

export { DenyPrompt };
