import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';

const isTesting = process.env.NODE_ENV === 'test';

import { useMutation } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import {
  ACTIVATE_LINE_MANAGER,
  DEACTIVATE_LINE_MANAGER,
  REMOVE_LINE_MANAGER,
  RESEND_LINE_MANAGER,
} from 'query/admin/line-manager/line-manager';

import { ActionDropdown, NameWithImage, SpinnerComponent, TableComponent } from 'components';

import call from '../../../../../assets/icons/Call.svg';
import chat from '../../../../../assets/icons/Message.svg';
import threedots from '../../../../../assets/icons/three-dots.svg';

import { LineManagerTableColumns } from './TableCols';

const keys = {
  'Manager Name': 'firstName',
  Department: { first: 'department', second: 'name' },
  'Date Hired': { first: 'employmentInfo', second: 'hireDate' },
  Status: 'lineManagerStatus',
};

export function ManagerTable(props) {
  const { search, data, loading: load, refetch } = props;
  const [sortBy, setSortBy] = useState('');
  const [tableData, setTableData] = useState([]);
  const [deactivateLineManager] = useMutation(DEACTIVATE_LINE_MANAGER);
  const [activateLineManager] = useMutation(ACTIVATE_LINE_MANAGER);
  const [resendLineManager] = useMutation(RESEND_LINE_MANAGER);
  const [removeLineManager] = useMutation(REMOVE_LINE_MANAGER);

  const { successToast, errorToast } = useToastify();

  function formatDate(date) {
    const dateLabel = new Date(date).toLocaleDateString('en-uk', {
      day: `2-digit`,
      month: 'long',
      year: 'numeric',
    });
    return dateLabel;
  }

  const actionPerform = (action, id) => {
    switch (action?.value) {
      case 'deactivate':
        onDeactivate(id);
        break;
      case 'activate':
        onActivate(id);
        break;
      case 'remove':
        onRemove(id);
        break;
      case 'resend':
        onResend(id);
        break;
    }
  };

  const onResend = async (id) => {
    try {
      const res = await resendLineManager({
        variables: { id },
      });
      const { data } = res;
      if (data && data.resendLineManagerEmail.status === 200) {
        refetch();
        successToast('Email sent sucessfully');
      } else errorToast(data.resendLineManagerEmail.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const onRemove = async (id) => {
    try {
      const res = await removeLineManager({
        variables: { id },
      });
      const { data } = res;
      if (data && data.removeLineManager.status === 200) {
        refetch();
        successToast('Line Manager removed sucessfully');
      } else errorToast(data.removeLineManager.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const onActivate = async (id) => {
    try {
      const res = await activateLineManager({
        variables: { id },
      });
      const { data } = res;
      if (data && data.activateLineManager.status === 200) {
        refetch();
        successToast('Line manager activated sucessfully');
      } else errorToast(data.activateLineManager.message);
    } catch (err) {
      errorToast(err.message);
    }
  };
  const onDeactivate = async (id) => {
    try {
      const res = await deactivateLineManager({
        variables: { id },
      });
      const { data } = res;
      if (data && data.deactivateLineManager.status === 200) {
        refetch();
        successToast('Line manager deactivated sucessfully');
      } else errorToast(data.deactivateLineManager.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];

    const sortedData = tableData?.map((e) => {
      return e;
    });
    if (key) {
      if (key.first && key.second) {
        sortedData.sort((a, b) => {
          return desc
            ? b[key?.first][key?.second].toString().localeCompare(a[key?.first][key?.second])
            : a[key?.first][key?.second].toString().localeCompare(b[key?.first][key?.second]);
        });
      } else {
        sortedData.sort((a, b) => {
          return desc
            ? b[key].toString().localeCompare(a[key])
            : a[key].toString().localeCompare(b[key]);
        });
      }
      setTableData(sortedData);
    }
  };

  const removeAction = {
    label: 'Remove Line Manager',
    value: 'remove',
    color: 'red',
  };
  const getActions = (action) => {
    const dropDownForTable =
      action !== 'unverified'
        ? action === 'active'
          ? [
              {
                label: 'Deactivate Line Manager',
                value: 'deactivate',
                color: 'red',
              },
            ]
          : [
              {
                label: 'Activate Line Manager',
                value: 'activate',
                color: 'green',
              },
              { ...removeAction },
            ]
        : [
            {
              label: 'Resend Email',
              value: 'resend',
            },
            { ...removeAction },
          ];
    return dropDownForTable;
  };

  const newTableData = tableData?.map((data) => {
    return {
      col1: <NameWithImage data={data} />,
      col2: (
        <Grid gap={'8px'}>
          <Flex className="contact">
            <Image src={chat} />
            <Text>{data?.email}</Text>
          </Flex>
          <Flex className="contact">
            <Image src={call} />
            <Text>{data?.phoneNumber}</Text>
          </Flex>
        </Grid>
      ),
      col3: <Text>{data?.department?.name}</Text>,
      col4: `${formatDate(data?.employmentInfo?.hireDate)}`,
      col5: (
        <Text className={`status ${data?.lineManagerStatus}`} textTransform={'capitalize'}>
          {data?.lineManagerStatus}
        </Text>
      ),
      col6: (
        <ActionDropdown
          actions={getActions(data?.lineManagerStatus)}
          btn={threedots}
          onClick={actionPerform}
          id={data?._id}
        />
      ),
    };
  });

  useEffect(() => {
    const fetchApplicantApplications = data;
    setTableData(fetchApplicantApplications);
  }, [data, search]);

  if (tableData === undefined && !isTesting)
    return (
      <Box height={'40vh'}>
        <SpinnerComponent />
      </Box>
    );

  return (
    <>
      <Box overflow={'scroll'}>
        <TableComponent
          minH={'450px'}
          loading={load}
          key={sortBy}
          noCheckbox
          data={newTableData}
          columns={LineManagerTableColumns}
          noSort
          ids={tableData?.map((e) => e._id)}
          empStatus={tableData?.map((e) => e.email)}
          noAction
          handleMultiSortBy={handleMultiSortBy}
          empty={tableData?.length === 0}
        />
      </Box>
    </>
  );
}
