import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// import { useToastify } from '../hooks/useToastify';
import { JobDetailsSchema } from '../schemas';

const JobDetailsFormContext = React.createContext();

function JobDetailsFormProvider(props) {
  const { children, initialValue = { defaultValues: {} } } = props;

  const defaultValues = { description: initialDescriptionValue, ...initialValue.defaultValues };
  const initializeState = () => ({
    ...initialValue,
    defaultValues,
    resolver: yupResolver(JobDetailsSchema),
  });
  const init = React.useMemo(initializeState, []);

  const formMethods = useForm(init);

  return (
    <JobDetailsFormContext.Provider value={formMethods}>{children}</JobDetailsFormContext.Provider>
  );
}

function useJobDetailsForm() {
  const context = React.useContext(JobDetailsFormContext);
  if (!context) throw Error('useJobDetailsForm must be wrapped in JobDetailsFormProvider');
  return context;
}

const initialDescriptionValue = [{ type: 'paragraph', children: [{ text: '' }] }];

export { JobDetailsFormProvider, useJobDetailsForm };
