import React from 'react';

import { SpinnerComponent } from '../../../common';
import { CardContainer } from '../styles';

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const d = new Date();
let currentMonth = month[d.getMonth()];

export const BirthdayCard = ({ totalBirthdays }) => {
  const loading = false;

  if (loading) return <SpinnerComponent />;

  return (
    <CardContainer data-testid="birthday-card-container">
      <p className="month">{currentMonth}</p>
      <span className="birthdays">{totalBirthdays} birthdays</span>
    </CardContainer>
  );
};
