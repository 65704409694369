import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Flex, Grid, Text } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';
import { ASSIGN_LEAVE_POLICY, DELETE_LEAVE_POLICY, UPDATE_LEAVE_POLICY } from 'query';

import { ActionDropdown, DeletePromptModal, EditLeaveTypeAndPolicy } from 'components';

import threedots from '../../../../assets/icons/three-dots.svg';
import { AssignEmployeesModal } from '../assign-employees-modal';

const LeavePolicy = (props) => {
  const { successToast, errorToast } = useToastify();
  const [modalOpen, setModalOpen] = useState(false);
  const [editPolicy, setEditPolicy] = useState(false);
  let [leavePolicy, setLeavePolicy] = useState();
  let leaveTypeId = props.leaveTypeId;
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const [state, setState] = useState({});

  const [editLeavePolicy, { loading: LeavePolicyLoading }] = useMutation(UPDATE_LEAVE_POLICY);

  const [assignLeavePolicy, { loading: assignLeavePolicyLoading }] =
    useMutation(ASSIGN_LEAVE_POLICY);

  const [deleteLeavePolicy, { loading: deleting }] = useMutation(DELETE_LEAVE_POLICY);

  const handleActionClick = async (status, id) => {
    if (status.value === 'assign') {
      setModalOpen(true);
    }

    if (status.value === 'edit') {
      openEditPolicy();
    }

    if (status.value === 'default') {
      onMarkAsDefault(id);
    }

    if (status.value === 'delete') {
      setShowDeletePrompt(true);
    }
  };

  const openEditPolicy = () => {
    const { leaveDuration, ...rest } = leavePolicy;
    setState({ leaveTypeId, ...leaveDuration, ...rest });
    setEditPolicy(!editPolicy);
  };

  const onMarkAsDefault = async (id) => {
    try {
      const res = await editLeavePolicy({
        variables: {
          filters: {
            _id: id,
            leaveTypeId: leaveTypeId,
          },
          data: {
            isDefault: true,
          },
        },
      });

      const { data } = res;
      if (data && data.updateLeavePolicy.status === 200) {
        successToast('Leave Policy marked as default sucessfully');
        props.refetchLeavePolicy();
      } else errorToast(data.updateLeavePolicy.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const onUpdate = async () => {
    try {
      const { _id, duration, durationType, name, isDefault } = state;

      const res = await editLeavePolicy({
        variables: {
          filters: {
            _id: _id,
          },
          leaveTypeId: leaveTypeId,
          data: {
            name,
            isDefault,
            leaveDuration: { duration, durationType },
          },
        },
      });

      const { data } = res;
      if (data && data.updateLeavePolicy.status === 200) {
        successToast('Leave Policy updated sucessfully');
        props.refetchLeavePolicy();
        setModalOpen(false);
      } else errorToast(data.updateLeavePolicy.message);
    } catch (err) {
      errorToast(err.message);
    }
    setEditPolicy(false);
  };

  const onAssign = async (employeeIds) => {
    try {
      const res = await assignLeavePolicy({
        variables: {
          employeeIds: employeeIds,
          data: {
            leavePolicyId: leavePolicy?._id,
            leaveTypeId: leaveTypeId,
          },
        },
      });

      const { data } = res;
      if (data && data.assignPolicyToEmployee.status === 200) {
        successToast('Leave Policy assigned sucessfully');
        props.refetchLeavePolicy();
        setModalOpen(false);
      } else errorToast(data.assignPolicyToEmployee.message);
    } catch (err) {
      errorToast(err.message);
    }
    setEditPolicy(false);
  };

  const onDelete = async (id) => {
    try {
      const res = await deleteLeavePolicy({
        variables: {
          policyId: id,
        },
      });
      const { data } = res;
      if (data && data.removeLeavePolicy.status === 200) {
        successToast('Leave Policy deleted sucessfully');
        props.refetchLeavePolicy();
      } else errorToast(data.removeLeavePolicy.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const isDisabled = !(state?.name && state?.leaveTypeId && state?.duration && state?.durationType);

  useEffect(() => {
    setLeavePolicy(props.leavePolicy);
  }, [props.leavePolicy]);

  return editPolicy ? (
    <EditLeaveTypeAndPolicy
      isDisabled={isDisabled}
      leavePolicy={true}
      loading={LeavePolicyLoading}
      editMode={editPolicy}
      title={props.title}
      setState={setState}
      value={state}
      onClick={onUpdate}
      goBack={() => setEditPolicy(!editPolicy)}
    />
  ) : (
    <Grid
      key={leavePolicy?._id}
      className="body"
      templateColumns={'1fr 1fr 1fr'}
      margin={'0 24px'}
      padding={'16px 0'}>
      <Flex alignItems={'center'} gap={'8px'}>
        <Text textTransform={'capitalize'}>{leavePolicy?.name}</Text>
        {leavePolicy?.isDefault && <span className="default">default</span>}
      </Flex>
      <p className="center">{leavePolicy?.employeeCount || 0} </p>

      <ActionDropdown
        btn={threedots}
        actions={props.policyActions}
        id={leavePolicy?._id}
        style={{
          width: 'fit-content',
        }}
        onClick={handleActionClick}
      />
      <AssignEmployeesModal
        title={'Assign Employees'}
        showModal={modalOpen}
        isModal={true}
        setShowModal={setModalOpen}
        onClick={onAssign}
        assignLoading={assignLeavePolicyLoading}
      />
      {showDeletePrompt && (
        <DeletePromptModal
          show={showDeletePrompt}
          cancel={() => setShowDeletePrompt(false)}
          deleteItem={() => onDelete(leavePolicy?._id)}
          loading={deleting}
        />
      )}
    </Grid>
  );
};

export { LeavePolicy };
