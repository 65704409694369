import { gql } from '@apollo/client';

export const FETCH_EXISTING_CONVO = gql`
  query fetchExistingConversations {
    fetchExistingConversations {
      status
      message
      data {
        _id
        participants {
          _id
          firstName
          lastName
          imageUrl
        }
        messages {
          _id
          text
          timestamp
          attachment
          author {
            _id
            firstName
            lastName
            imageUrl
          }
        }
        unread: UnreadMessages
      }
    }
  }
`;

export const FETCH_MESSAGES = gql`
  query fetchMessages($filter: MessageFilter!) {
    fetchMessages(filter: $filter) {
      status
      message
      hasNextPage
      data {
        _id
        participants {
          _id
          firstName
          lastName
          imageUrl
        }
        messages {
          author {
            _id
            firstName
            lastName
            imageUrl
          }
          _id
          text
          attachment
          timestamp
        }
      }
    }
  }
`;

export const SEND_CHAT_MESSAGE = gql`
  mutation sendNewMessage($data: MessageInputData!) {
    sendNewMessage(data: $data) {
      status
      message
      data {
        _id
        participants {
          _id
          firstName
          lastName
          imageUrl
        }
        messages {
          _id
          author {
            _id
            firstName
            lastName
            imageUrl
          }
          text
          attachment
          timestamp
        }
      }
    }
  }
`;

export const SUBSCRIBE_TO_MESSAGES = gql`
  subscription NewMessage($receipientId: ObjectID!) {
    newMessage(receipientId: $receipientId) {
      _id
      author {
        _id
        firstName
        lastName
        imageUrl
      }
      text
      timestamp
      attachment
    }
  }
`;

export const INITIATE_CONVO = gql`
  mutation initiateConversation($data: MessageFilter!) {
    initiateConversation(data: $data) {
      status
      message
      data {
        _id
        participants {
          _id
          firstName
          lastName
          imageUrl
        }
        messages {
          _id
          author {
            _id
            firstName
            lastName
            imageUrl
          }
          text
          attachment
          timestamp
        }
      }
    }
  }
`;

export const UPDATE_UNREAD_MESSAGES = gql`
  mutation updateUnreadMessages($data: MessageFilter!) {
    updateUnreadMessages(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const USER_TYPING = gql`
  mutation userTyping($data: MessageFilter!) {
    userTyping(data: $data) {
      status
      message
      data {
        isTyping
      }
    }
  }
`;

export const USER_TYPING_SUBSCRIPTION = gql`
  subscription userTyping($receipientId: ObjectID!) {
    userTyping(receipientId: $receipientId) {
      isTyping
    }
  }
`;
