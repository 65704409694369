import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';

import eyeClose from '../../../../assets/icons/eyeClose.svg';
import eyeOpen from '../../../../assets/icons/eyeOpen.svg';
import { BaseInput, CustomButton } from '../../../../components';
import { AuthSidebar } from '../../../../components/auth/Login';
import { AdminAuth } from '../../../../layouts';
import { signIn } from '../../../../query/admin/auth';

import { LoginHook } from './hook.js';

export const Login = () => {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  let navigate = useNavigate();
  const { successToast, errorToast } = useToastify();

  const iconClick = () => {
    setShow(!show);
  };

  const { routeType } = LoginHook();
  const location = useLocation();
  const checkpath = location?.pathname?.includes('/employee');

  const { register, handleSubmit, reset } = useForm({ defaultValues: { email: '', password: '' } });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await signIn(data);
      if (['success'].includes(res?.status)) {
        const message = res?.message;
        successToast(message);

        const checkUser = res?.data?.user?.isDefaultAdmin;
        // an admin on an admin route
        if (checkUser && !checkpath) {
          navigate('/admin');
          // an employee on an employee route
        } else if (!checkUser && checkpath) {
          navigate('/employee');
          // an admin on an employee route
        } else if (checkUser && checkpath) {
          navigate('/admin');
          // an employee on an admin route
        } else if (!checkUser && !checkpath) {
          navigate('/employee');
        }
      }
      reset({ email: '', password: '' });
    } catch (error) {
      const message = error?.response?.data?.message;
      errorToast(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginWrapper>
      <Box className="login-content">
        {routeType && routeType === 'admin' && <h2>Welcome Back</h2>}
        {routeType && routeType === 'employee' && (
          <div className="employee-login-heading">
            <h2>Login</h2>
            <p>Login with the details sent to your email</p>
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid gridGap={'2rem'} mb={'4.8rem'}>
            <BaseInput
              {...register('email', { required: true })}
              label={'Email Address'}
              type="email"
              name={'email'}
              placeholder={'janecooper@janc.com'}
            />
            <BaseInput
              data-testid="password"
              {...register('password', { required: true })}
              name="password"
              label="Password"
              placeholder="Enter password"
              type={show ? 'text' : 'password'}
              rightIcon={show ? eyeOpen : eyeClose}
              IconClick={iconClick}
            />
          </Grid>
          <CustomButton
            name={'Login'}
            type="submit"
            data-testid="login-btn"
            h={'4.8rem'}
            loading={loading}
          />
        </form>
        <p className="forgot-password">
          Forgot Password? <Link to="/reset">Reset</Link>
        </p>
      </Box>
      <AuthSidebar />
    </LoginWrapper>
  );
};

export const AdminLogin = () => {
  return (
    <AdminAuth>
      <Login />
    </AdminAuth>
  );
};

const LoginWrapper = styled(Grid)`
  grid-template-columns: 1fr;
  overflow: hidden;
  place-content: center;
  height: 100vh;
  @media (min-width: 980px) {
    grid-template-columns: 1fr minmax(54rem, 1fr);
    height: 100vh;
  }
  .auth-sidebar {
    display: none;
    @media (min-width: 980px) {
      display: block;
    }
  }

  .login-content {
    width: 100%;
    max-width: 42rem;
    height: auto;
    margin: auto;
    padding: 0 1rem;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.117188px;
      color: #333758;
      text-align: center;
      margin-bottom: 4.6rem;
    }

    .employee-login-heading {
      margin-bottom: 4.8rem;

      h2 {
        margin-bottom: 1.6rem;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.117188px;
        color: #85879b;
      }
    }
  }
  .forgot-password {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    color: rgba(51, 55, 88, 0.3);
    text-align: center;
    margin-top: 1.3rem;

    a {
      color: var(--green);
      text-decoration-line: underline;
    }
  }
`;
