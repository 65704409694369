import { gql } from '@apollo/client';

export const CREATE_REVIEW_TEMPLATE = gql`
  mutation createReviewTemplate($data: ReviewTemplateInput!) {
    createReviewTemplate(data: $data) {
      status
      message
      data {
        name
        description
      }
    }
  }
`;

export const EDIT_REVIEW_TEMPLATE = gql`
  mutation editReviewTemplate($filters: ReviewTemplateFilters, $data: ReviewTemplateInput!) {
    editReviewTemplate(filters: $filters, data: $data) {
      status
      message
      data {
        questions {
          title
          instruction
          questions {
            questionType
            question
            options {
              choice
              value
            }
          }
        }
      }
    }
  }
`;

export const FETCH_ALL_TEAMS = gql`
  query fetchTeams {
    fetchTeams {
      status
      message
      data {
        _id
        name
        members
        createdAt
      }
    }
  }
`;
export const DELETE_TEAM = gql`
  mutation deleteTeam($filter: TeamMutateInput!) {
    deleteTeam(filter: $filter) {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;
export const EDIT_TEAM = gql`
  mutation editTeam($filter: TeamMutateInput!, $data: TeamInput!) {
    editTeam(filter: $filter, data: $data) {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;

export const FETCH_SINGLE_TEMPLATE = gql`
  query fetchSingleTemplate($templateId: ObjectID!) {
    fetchSingleTemplate(templateId: $templateId) {
      status
      message
      data {
        name
        description
        team
        questions {
          title
          instruction
          questions {
            questionType
            question
            options {
              choice
              value
            }
          }
        }
      }
    }
  }
`;

export const DELETE_REVIEW_TEMPLATE = gql`
  mutation deleteReviewTemplate($templateId: ObjectID!) {
    deleteReviewTemplate(templateId: $templateId) {
      status
      message
      data {
        name
        description
      }
    }
  }
`;

export const FETCH_ALL_REVIEW_TEMPLATES = gql`
  query fetchAllTemplates($filter: ReviewTemplateFilters, $page: Int) {
    fetchAllTemplates(filter: $filter, page: $page) {
      status
      message
      data {
        _id
        name
        description
        team {
          _id
          name
        }
        createdAt
      }
      pageCount
    }
  }
`;
