import { useNavigate } from 'react-router-dom';
import { Flex, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, ModalBackButton } from '../../../../components';
import { useApplicantForm, useHandleCreateJob, useJobDetailsForm } from '../../../../context';

function StatusBar(props) {
  const navigate = useNavigate();
  const back = () => {
    navigate('/admin/jobs');
  };
  return (
    <Flex justifyContent="space-between" h="4.8rem" alignItems="center">
      <Flex alignItems={'center'} gap={'8px'}>
        <ModalBackButton onClick={back} />
        <Heading fontSize="2.4rem" lineHeight="2.8rem" letterSpacing="-0.5px">
          Add New Job
        </Heading>
      </Flex>
      {props.active !== props.totalPages && <StatusBarActions {...props} />}
    </Flex>
  );
}

const StatusBarActionsRoot = styled.div`
  display: grid;
  grid: 1fr / auto-flow max-content;
  gap: 1.6rem;
`;

function StatusBarActions({ active, totalPages, setActive }) {
  const { onPublish, onSaveAsDraft, createJobLoading, saveAsDraftLoading } = useHandleCreateJob();
  const { handleSubmit: handleJobSubmit } = useJobDetailsForm();
  const { handleSubmit: handleApplicationSubmit } = useApplicantForm();
  const showPrevious = active !== 0;
  const showNext = active < totalPages + 1;
  const onLast = active === totalPages - 1;
  const onPreviousClick = () => setActive(active - 1);

  const onNextClick = () => {
    if (active === 0) handleJobSubmit(() => moveForward())();
    if (active === 1)
      handleApplicationSubmit(() => {
        moveForward();
      })();
    else moveForward();
    if (active === 1)
      setTimeout(
        () =>
          document
            .querySelector('.editable-input-field.error')
            ?.scrollIntoView({ behavior: 'smooth' }),
        0
      );
  };

  const moveForward = () => {
    if (active < totalPages - 1) setActive(active + 1);
    if (onLast) onPublish();
  };

  const nextBtnContent = onLast ? 'Publish' : 'Next';
  const nextBtnWidth = onLast ? '11.3rem' : '9.4rem';

  return (
    <StatusBarActionsRoot>
      <BaseButton variant="text" onClick={onSaveAsDraft} isLoading={saveAsDraftLoading}>
        Save & Close
      </BaseButton>
      {showPrevious ? (
        <BaseButton variant="outline" onClick={onPreviousClick} w="11.9rem">
          Previous
        </BaseButton>
      ) : null}
      {showNext ? (
        <BaseButton onClick={onNextClick} w={nextBtnWidth} isLoading={createJobLoading}>
          {nextBtnContent}
        </BaseButton>
      ) : null}
    </StatusBarActionsRoot>
  );
}

export default StatusBar;
