import React from 'react';
import { useMutation } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { CREATE_GOAL, RETRIEVE_GOALS } from 'query';

import { GoalsComponent } from '../components';

const CreateGoal = ({ showModal, setShowModal }) => {
  const { errorToast, successToast } = useToastify();

  const [createGoal, { loading: createGoalLoading }] = useMutation(CREATE_GOAL);

  const onCreateGoals = async (state) => {
    try {
      const { employees: employeesIds, ...rest } = state;
      const employees = employeesIds?.map((emp) => emp._id);

      const res = await createGoal({
        variables: {
          data: { employees, ...rest },
        },
        refetchQueries: [
          {
            query: RETRIEVE_GOALS,
            variables: {
              page: 1,
            },
          },
        ],
      });
      const { data } = res;
      if (data && data.addGoal.status === 201) {
        successToast('Goals created sucessfully');
        setInterval(setShowModal(false), 2000);
      } else errorToast(data.addGoal.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  return (
    <GoalsComponent
      title={'Create a new goal'}
      showModal={showModal}
      setShowModal={setShowModal}
      onSave={onCreateGoals}
      loading={createGoalLoading}
    />
  );
};

export { CreateGoal };
