import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { EDIT_GOAL, RETRIEVE_GOALS, RETRIEVE_SINGLE_GOAL } from 'query';

import { EmptyState } from 'components';

import { GoalsComponent } from '../components';

const EditGoals = ({ showModal, setShowModal, id }) => {
  const { errorToast, successToast } = useToastify();
  const [editGoal, { loading: editGoalLoading }] = useMutation(EDIT_GOAL);

  const {
    data: singleGoalData,
    loading,
    refetch,
  } = useQuery(RETRIEVE_SINGLE_GOAL, {
    variables: {
      filters: {
        _id: id,
      },
    },
  });

  const fetchedData = singleGoalData?.retrieveGoals?.data[0];

  const deleteData = (state) => {
    delete state?.creator;
    delete state?.createdAt;
    delete state?._id;
    delete state?.frequency;
  };

  const onEditGoal = async (state) => {
    try {
      deleteData(state);
      const { employees: employeesIds, ...rest } = state;
      const employees = employeesIds?.map((emp) => emp._id);

      const res = await editGoal({
        variables: {
          filters: {
            _id: id,
          },
          data: { employees, ...rest },
        },
        refetchQueries: [
          {
            query: RETRIEVE_GOALS,
            variables: {
              page: 1,
            },
          },
        ],
      });
      const { data } = res;
      if (data && data.editGoal.status === 200) {
        successToast('Goals updated sucessfully');
        setInterval(setShowModal(false), 2000);
        refetch();
      } else errorToast(data.editGoal.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  return [fetchedData]?.length ? (
    <GoalsComponent
      title={'Edit Goal'}
      showModal={showModal}
      setShowModal={setShowModal}
      onSave={onEditGoal}
      pageLoading={loading}
      loading={editGoalLoading}
      value={fetchedData}
    />
  ) : (
    <EmptyState message={'No Goals Found'} />
  );
};

export { EditGoals };
