export const tableColumns = [
  {
    Header: 'Employee Name',
    accessor: 'col1',
  },
  {
    Header: 'Team',
    accessor: 'col2',
  },
  {
    Header: 'Grade',
    accessor: 'col3',
  },
  {
    Header: 'Date Hired',
    accessor: 'col4',
  },
  {
    Header: 'status',
    accessor: 'col5',
  },
];

export const tableData = [
  {
    _id: 'jehkhadgfshagdhfjags',
    firstName: 'Betty',
    lastName: 'Annang',
    team: 'Frontend',
    grade: '10',
    status: 'active',
    employeeInfo: {
      hireDate: '28th September,2022',
    },
  },
  {
    _id: 'jehkhadgfshagdhfjagr',
    firstName: 'Salma',
    lastName: 'Mumin',
    team: 'Frontend',
    grade: '12',
    status: 'deactivated',
    employeeInfo: {
      hireDate: '28th September,2021',
    },
  },
];
