import React from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';

import { getDay, getDaysArray, getEdges, isSameDate, isValidDate } from './helpers';

const daysShort = [...Array(7)].map((_, i) => getDay(i, 3));

export function DayGrid(props) {
  const {
    month,
    year,
    selectedDate,
    selectedToDate,
    isRange,
    onSelectDay = () => {},
    active,
    setActive,
  } = props;
  const [start, end, startAt] = getEdges(month, year);
  const daysArray = React.useMemo(() => getDaysArray(start, end), [month, year]);

  function isStartDay(day) {
    return isSelectedDay(day, selectedDate);
  }

  function isEndDay(day) {
    return isSelectedDay(day, selectedToDate);
  }

  function isSelectedDay(day, comparableDate) {
    return (
      comparableDate &&
      isValidDate(comparableDate) &&
      month === comparableDate.getMonth() &&
      year === comparableDate.getFullYear() &&
      day === comparableDate.getDate()
    );
  }

  function isBetween(day, comparableDate, comparableToDate) {
    if (
      !comparableDate ||
      !comparableToDate ||
      !isValidDate(comparableDate) ||
      !isValidDate(comparableToDate)
    )
      return false;

    const thisDate = new Date(year, month, day);
    return thisDate > comparableDate && thisDate < comparableToDate;
  }

  function handleCellClick(options) {
    const { day, start, end, target } = options;
    if (!target.classList.contains('active')) onSelectDay(day);
    if (start) setActive(0);
    if (end) setActive(1);
  }

  const isStart = (day, additionalCheck = false) => {
    return (
      isStartDay(day) &&
      isRange &&
      selectedToDate &&
      (additionalCheck ? !isSameDate(selectedDate, selectedToDate) : true)
    );
  };
  const isEnd = (day, additionalCheck = false) => {
    return (
      isEndDay(day) &&
      isRange &&
      selectedToDate &&
      (additionalCheck ? !isSameDate(selectedDate, selectedToDate) : true)
    );
  };

  return (
    <DayGridRoot>
      <div className="day-grid__header">
        {daysShort.map((day) => (
          <div className="day-grid__header-item" key={day}>
            {day}
          </div>
        ))}
      </div>

      <div className="day-grid__days" style={{ '--start-at': startAt }}>
        {daysArray.map((day) => (
          <div
            data-testid={`day-${day}`}
            className={cx('day-grid__days__cell', {
              active: isStartDay(day) || isEndDay(day),
              start: isStart(day, true),
              on_start: isStart(day) && isRange && active === 0,
              on_end: isEnd(day) && isRange && active === 1,
              end: isEnd(day),
              between: isBetween(day, selectedDate, selectedToDate),
            })}
            key={day}
            onClick={(e) =>
              handleCellClick({
                day,
                start: isStart(day),
                end: isEnd(day),
                target: e.target,
              })
            }>
            <span className={cx('day-grid__days__cell__number')}>{day}</span>
          </div>
        ))}
      </div>
    </DayGridRoot>
  );
}

const DayGridRoot = styled.div`
  .day-grid__header {
    display: grid;
    grid: 1fr / repeat(7, 1fr);
    align-items: center;
    height: 4rem;

    &-item {
      font-weight: 500;
      text-align: center;
    }
  }

  .day-grid__days {
    --start-at: 1;
    display: grid;
    grid: repeat(6, 1fr) / repeat(7, 1fr);
  }

  .day-grid__days__cell {
    --border-size: 2px;
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    cursor: pointer;
    // transition: background-color 250ms ease;

    &:hover {
      background: #f2f2f2;
    }

    &:first-of-type {
      grid-column: var(--start-at) / span 1;
    }

    &.active {
      position: relative;
      color: white;
      transition: none;
      z-index: 4;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #3e7eee;
        z-index: 1;
      }
    }

    &.on_start,
    &.on_end {
      padding-top: calc(100% - (var(--border-size) * 2));
      border: var(--border-size) solid var(--white);
      outline: var(--border-size) solid var(--blue);
      outline-offset: 0;
    }

    &.start,
    &.end {
      &:before {
        content: '';
        top: 0;
        width: 50%;
        height: 100%;
        position: absolute;
        background: #ecf2fd;
        z-index: -1;
      }

      &.on_start,
      &.on_end {
        &:before {
          top: calc(-1 * var(--border-size));
          left: calc(-1 * var(--border-size));
          width: calc(50% + var(--border-size));
          height: calc(100% + (var(--border-size) * 2));
        }
      }
    }

    &.start:before {
      left: auto;
      right: 0;
    }

    &.on_start,
    &.on_end {
      &.start:before {
        left: auto;
        right: calc(-1 * var(--border-size));
      }
    }

    &.between {
      border-radius: 0;
      background: #ecf2fd;
    }
  }

  .day-grid__days__cell__number {
    position: absolute;
    inset: 50% 0 0 50%;
    translate: -50% -50%;
    width: max-content;
    height: max-content;
    pointer-events: none;
    z-index: 2;
  }
`;
