import React from 'react';
import styled from '@emotion/styled';

export default function ReviewResponseWrapper({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  padding: 16px;
  padding-bottom: 0;
  font-size: 14px;
  color: #23273b;
  border: 1px solid #f2f2f2;
`;
