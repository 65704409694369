export const SharedColumns = [
  {
    Header: 'Training Program',
    accessor: 'col1',
  },
  {
    Header: 'Repeats',
    accessor: 'col2',
  },
  {
    Header: 'Due (After enrollment)',
    accessor: 'col3',
  },
  {
    Header: 'Status',
    accessor: 'col4',
  },
  {
    Header: '',
    accessor: 'col5',
  },
];

export const IndividualColumns = [
  {
    Header: 'Training Program',
    accessor: 'col1',
  },
  {
    Header: 'Name',
    accessor: 'col2',
  },
  {
    Header: 'Cost of Training',
    accessor: 'col3',
  },
  {
    Header: 'Due (After enrollment)',
    accessor: 'col4',
  },
  {
    Header: 'Status',
    accessor: 'col5',
  },
  {
    Header: '',
    accessor: 'col6',
  },
];
