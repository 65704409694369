import { Box, Grid } from '@chakra-ui/react';

export function FlatSteps(props) {
  const { steps, active, setActive, ...rest } = props;

  return (
    <Grid templateColumns={`repeat(${steps.length}, 1fr)`} gap="16px" {...rest}>
      {steps.map((step, index) => (
        <Grid
          key={step.name}
          templateRows="repeat(2, max-content)"
          gap="12px"
          onClick={() => setActive(index)}>
          <Box
            background={index <= active ? 'brand.primary' : 'brand.grey.300'}
            borderRadius="16px"
            h="0.8rem"
            w="100%"></Box>
          <Box
            px="4px"
            color={index <= active ? 'brand.black' : 'brand.grey.700'}
            fontWeight={index <= active ? '600' : '400'}>
            {index + 1}. {step.name}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
