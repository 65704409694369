import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Text } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';
import { EDIT_DEPARTMENT, GET_SINGLE_DEPARTMENT } from 'query';

import { BaseInput, CustomButton, SpinnerComponent } from 'components';

import plusIcon from '../../../../../assets/icons/plusIcon.svg';

import { DepartmentRoot } from './AddDepartment';
import { DesignationFields } from './Fields';

export const EditDepartment = ({ refetch, deptID, setShow }) => {
  const [addingDept, setLoading] = React.useState(false);

  const [formDefaultValues, setDefaultValues] = React.useState({});
  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: formDefaultValues,
  });

  const {
    data,
    refetch: fetchSingle,
    loading,
  } = useQuery(GET_SINGLE_DEPARTMENT, {
    variables: { id: deptID },
  });

  React.useEffect(() => {
    fetchSingle();
    const formValues = data?.fetchSingleDepartment?.data;
    setDefaultValues(formValues);
    reset(formDefaultValues);
  }, [formDefaultValues, data]);

  const { successToast, errorToast } = useToastify();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'designation',
  });

  const [editDepartment] = useMutation(EDIT_DEPARTMENT);

  const onSubmit = async (data) => {
    setLoading(true);

    const designationWithoutID = data?.designation?.map(function (item) {
      delete item._id;
      return {
        name: item.name,
      };
    });

    const dataToSend = { name: data.name, designation: designationWithoutID };

    try {
      const result = await editDepartment({ variables: { _id: data._id, data: dataToSend } });
      if (result?.data?.editDepartment?.status === 200) {
        const message = result?.data?.editDepartment?.message;
        successToast(message);
      }
      setLoading(false);
    } catch (error) {
      errorToast(error.message || 'Error occured');
      setLoading(false);
    } finally {
      refetch();
      reset({});
      setShow(false);
    }
  };

  return (
    <DepartmentRoot data-testid="EditDepartment">
      <h1>Edit Department</h1>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="form" data-testid="form">
          <BaseInput
            {...register('name')}
            label="Department"
            placeholder="Department"
            type="text"
          />
          <div className="add-designation">
            <Text>Designations</Text>
            <button
              data-testid="add-designation-btn"
              onClick={() => {
                append({ name: '' });
              }}
              className="btn"
              type="button">
              <img src={plusIcon} alt="add" />
              Add designations
            </button>
          </div>
          <div className="designations">
            <DesignationFields
              fields={fields}
              register={register}
              remove={remove}
              fieldName={'designation'}
            />
          </div>
          <CustomButton
            name="Save & Continue"
            variant="primary"
            h="4.8rem"
            type="submit"
            loading={addingDept}
          />
        </form>
      )}
    </DepartmentRoot>
  );
};
