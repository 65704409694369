import { Box, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AnimatedSection } from 'components/AnimateSection';

import hierarchyIcon from '../../assets/icons/hierarchy.svg';
import securityIcon from '../../assets/icons/security-safe.svg';
import userFriendlyIcon from '../../assets/icons/user-friendly.svg';
import bgImg from '../../assets/images/choose-hrms-bg.png';

let data = [
  {
    id: 1,
    icon: userFriendlyIcon,
    heading: 'User-Friendly Interface',
    desc: 'Ensuring a smooth user experience for HR professionals and employees.',
  },
  {
    id: 2,
    icon: hierarchyIcon,
    heading: 'Seamless Integration',
    desc: ' Integrate HRMS with existing systems to enhance efficiency and data flow.',
  },
  {
    id: 2,
    icon: securityIcon,
    heading: 'Data Security',
    desc: 'Advanced measures to keep sensitive HR information safe and confidential.',
  },
];

export function WhyChooseHrms() {
  return (
    <div className="container">
      <Root bg={'#23273B'} color={'var(--white)'} bgImage={bgImg}>
        <Stack textAlign={'center'} maxW={'578px'}>
          <AnimatedSection>
            <Stack textAlign={'center'}>
              <Text fontSize={{ base: '14px', md: '16px' }} color={'#3E7EEE'} fontWeight={600}>
                BENEFITS OF USING HRMS
              </Text>
              <Text fontSize={{ base: '36px', md: '46px' }} fontWeight={700}>
                Why Choose HRMS?
              </Text>
            </Stack>
          </AnimatedSection>

          <AnimatedSection>
            <Text color={'#A9AEC1'} fontSize={{ base: '16px', md: '18px' }}>
              The ultimate Human Resource Management web application designed to optimize and
              elevate your HR processes
            </Text>
          </AnimatedSection>
        </Stack>

        <AnimatedSection>
          <Grid gap={'64px'} gridTemplateColumns={{ md: '1fr 1fr', lg: '1fr 1fr 1fr' }}>
            {data.map((item) => {
              return (
                <WhyChooseHrmsCard
                  key={item.id}
                  heading={item.heading}
                  desc={item.desc}
                  icon={item.icon}
                />
              );
            })}
          </Grid>
        </AnimatedSection>
      </Root>
    </div>
  );
}

function WhyChooseHrmsCard({ heading, desc, icon }) {
  return (
    <Stack className="card">
      <Box className="icon">
        <img src={icon} alt="" />
      </Box>
      <Stack textAlign={'center'}>
        <Heading fontSize={'24px'}>{heading}</Heading>
        <Text color={'#A9AEC1'} fontSize={'18px'}>
          {desc}
        </Text>
      </Stack>
    </Stack>
  );
}

const Root = styled(Stack)`
  border-radius: 16px;
  align-items: center;
  gap: 100px;
  padding: 52px 32px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    padding: 72px 52px;
  }

  .card {
    max-width: 400px;
    position: relative;
    display: flex;
    padding: 66px 37.5px 17px 38.5px;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    @media (min-width: 768px) {
      gap: 28px;
    }
    border-radius: 16px;
    border: 1px solid #3b3f57;
    background: var(--linear-card, linear-gradient(219deg, #292e46 0%, #262a3f 98.28%));

    .icon {
      position: absolute;
      top: -30px;
      display: flex;
      width: 60px;
      height: 60px;
      padding: 14px;
      justify-content: center;
      align-items: center;
      border-radius: 1000px;
      border: 1px solid #3b3f57;
      background: var(--linear-card, linear-gradient(219deg, #292e46 0%, #262a3f 98.28%));
    }
  }
`;
