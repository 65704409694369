import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { getItem } from 'utils';

import { LineManagerIcon } from 'components/shared/Svgs';

import changeDp from '../../../../../assets/icons/changeDp.svg';
import { useToastify } from '../../../../../hooks/useToastify';
import { EDIT_EMPLOYEE_PROFILE_PIC, FETCH_EMPLOYEE_DETAILS } from '../../query';
import { setImage } from '../helpers';
import { ProfileCardWrapper } from '../styles';

const isTesting = process.env.NODE_ENV === 'test';
export function ProfileCard({ userData }) {
  const userDetails = getItem('hrm_user');

  const [editEmployeeProfilePic] = useMutation(EDIT_EMPLOYEE_PROFILE_PIC, {
    refetchQueries: [
      {
        query: FETCH_EMPLOYEE_DETAILS,
        variables: {
          filters: {
            _id: userDetails?._id,
          },
        },
      },
    ],
  });

  let testingFile;
  if (isTesting) {
    testingFile = {};
  }

  const { successToast } = useToastify();

  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);

  const handleImageUpload = async (e) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      const res = await editEmployeeProfilePic({
        variables: {
          file: file || testingFile,
        },
      });

      if (res.data.uploadProfilePic.status === 200) {
        successToast(res.data.uploadProfilePic.message);
      }
    }
  };

  return (
    <ProfileCardWrapper>
      <Stack>
        <Flex
          flexDir={'column'}
          alignItems={'center'}
          paddingBottom={'20px'}
          borderBottom={'solid 1px var(--grey-150)'}>
          <Box position={'relative'} maxW={'170px'}>
            <Box
              borderRadius={'50%'}
              overflow={'hidden'}
              width={'160px'}
              height={'160px'}
              backgroundColor={'var(--grey-300)'}>
              <Image
                borderRadius={'50%'}
                width={'100%'}
                height={'100%'}
                objectFit={'cover'}
                ref={uploadedImage}
                maxW={'100%'}
                src={setImage(userData?.firstName, userData?.lastName, userData?.imageUrl || '')}
              />
            </Box>
            <div>
              <Image
                src={changeDp}
                alt="profile picture"
                cursor={'pointer'}
                data-testid={'changeDpBtn'}
                onClick={() => imageUploader.current.click()}
                position={'absolute'}
                bottom={'15px'}
                right={'12px'}
              />
              <input
                type={'file'}
                onChange={handleImageUpload}
                ref={imageUploader}
                accept={'image/*'}
                style={{ display: 'none' }}
              />
            </div>
          </Box>

          <Stack textAlign={'center'} gap={'0.8rem'} marginTop={'1.6rem'}>
            <Flex gap={'8px'} justifyContent={'center'}>
              <Text fontSize={'2rem'} fontWeight={'600'} textTransform={'capitalize'}>
                {userData?.firstName}
              </Text>
              <Text fontSize={'2rem'} fontWeight={'600'} textTransform={'capitalize'}>
                {userData?.lastName}
              </Text>
            </Flex>
            <Text>{userData?.designation?.name}</Text>
            <Text fontSize={'1.4rem'}>{userData?.email}</Text>
            {userData?.lineManagerStatus === 'active' ? (
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'100px'}
                bgColor={'rgba(255, 255, 255, 0.20)'}
                p={'6px 9px'}
                gap={'6px'}>
                {LineManagerIcon}
                <Text>Line Manager</Text>
              </Flex>
            ) : null}
          </Stack>
        </Flex>

        <Stack paddingTop={'20px'} gap={'24px'}>
          <Flex justify={'space-between'}>
            <Box>
              <Text fontSize={'1.4rem'}>ID Number</Text>
              <Text fontWeight={'500'}> {userData?.staffId}</Text>
            </Box>
            <Box gap={'1.6rem'}>
              <Text fontSize={'1.4rem'}>Status</Text>
              <Text className={`status__${userData?.status}`}>{userData?.status}</Text>
            </Box>
          </Flex>
          <Stack>
            <Text fontSize={'1.4rem'}>Department</Text>
            <Text fontWeight={'500'}>{userData?.department?.name}</Text>
          </Stack>
          <Stack>
            <Text fontSize={'1.4rem'}>Employment Type</Text>
            <Text textTransform={'capitalize'} fontWeight={'500'}>
              {userData?.employmentInfo?.employmentType}
            </Text>
          </Stack>
          <Stack>
            <Text fontSize={'1.4rem'}>Office</Text>
            <Text fontWeight={'500'}>{userData?.location}</Text>
          </Stack>
        </Stack>
      </Stack>
    </ProfileCardWrapper>
  );
}
