import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import { FullModal, ModalBackButton, SpinnerComponent } from '../../../../../components';
import {
  useWorkScheduleForm,
  WorkScheduleFormProvider,
} from '../../../../../context/workScheduleForm';
import { useToastify } from '../../../../../hooks/useToastify';
import {
  EDIT_WORK_SCHEDULE,
  FILTER_WORK_SCHEDULE,
} from '../../../../../query/admin/leave-management';

import { BlackoutPeriods, Holidays, OverallSettings } from './components';

function WorkSchedule(props) {
  const { editing, setEditing } = props;
  const [date, setDate] = React.useState(() => new Date().getFullYear().toString());
  const [startDate, setStartDate] = React.useState(() => new Date().getFullYear().toString());

  const editingFilter = editing ? { workScheduleId: editing } : {};
  const { loading, data, refetch } = useQuery(FILTER_WORK_SCHEDULE, {
    variables: {
      filter: {
        ...editingFilter,
        date,
        startDate,
      },
    },
  });

  React.useEffect(() => {
    refetch();
  }, [startDate, date]);

  let matchingSchedule = data?.filterWorkSchedule?.data;
  if (matchingSchedule?.blackoutPeriods) {
    matchingSchedule = { ...matchingSchedule };
    matchingSchedule.blackoutPeriods = matchingSchedule.blackoutPeriods.map(
      ({ startDate, endDate, name }) => ({
        id: Math.random(),
        name,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      })
    );
    matchingSchedule.holidays =
      matchingSchedule.holidays?.map(({ date, ...rest }) => ({
        ...rest,
        date: new Date(date),
      })) || [];
  }

  return (
    <Wrapper>
      <FullModal noHeader showModal={!!editing} mt="8.4rem">
        <header className="schedule__header">
          <ModalBackButton onClick={() => setEditing(null)} />
          <h1 className="schedule__header-text">Edit Work Schedule</h1>
        </header>
        {loading ? (
          <SpinnerComponent h="64rem" />
        ) : (
          <WorkScheduleFormProvider
            initialValue={{ defaultValues: matchingSchedule }}
            other={{ date, setDate, startDate, setStartDate }}>
            <WorkScheduleContent refetchSingle={refetch} onCancel={() => setEditing(null)} />
          </WorkScheduleFormProvider>
        )}
      </FullModal>
    </Wrapper>
  );
}

function WorkScheduleContent(props) {
  const { refetchSingle, onCancel } = props;
  const [editWorkSchedule, { loading }] = useMutation(EDIT_WORK_SCHEDULE);
  const { getValues } = useWorkScheduleForm();
  const { successToast } = useToastify();

  async function onSaveChanges() {
    const data = getAPITransformedData(getValues());
    try {
      await editWorkSchedule({ variables: { data } });
      await refetchSingle();
      successToast('Work Schedule Updated');
    } catch (e) {
      void e;
    }
  }

  return (
    <>
      <OverallSettings onSave={onSaveChanges} onCancel={onCancel} saving={loading} />
      <Holidays onSaveSchedule={onSaveChanges} />
      <BlackoutPeriods onSaveSchedule={onSaveChanges} />
    </>
  );
}

function getAPITransformedData(data) {
  const allowedFields = ['_id', 'blackoutPeriods', 'isDefault', 'holidays', 'name', 'weekend'];
  return Object.entries(data).reduce((previousValue, [key, value]) => {
    if (!allowedFields.includes(key)) return previousValue;
    previousValue[key] = value;
    if (key === 'weekend') previousValue[key] = value.map((d) => d.toLowerCase());
    if (['blackoutPeriods', 'holidays'].includes(key)) {
      previousValue[key] = value.map(({ id, ...rest }) => (id ? { ...rest } : { ...rest }));
    }
    return previousValue;
  }, {});
}

const Wrapper = styled.div`
  .schedule__header {
    position: sticky;
    z-index: 10;
    top: 0;
    padding: 2.4rem 0;
    display: grid;
    grid: 1fr / auto-flow max-content;
    gap: 2.4rem;
    align-items: center;
    background-color: var(--white);

    &-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.05em;
    }
  }

  .work-schedule__label {
    display: block;
    margin-bottom: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 600;
  }

  .red {
    color: var(--error);
  }
`;

export { WorkSchedule };
