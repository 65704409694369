export function formateDate(date) {
  const dateLabel = new Date(date).toLocaleDateString('en-us', {
    day: `2-digit`,
    month: 'short',
    year: 'numeric',
  });
  return dateLabel;
}

export function modifyUsersData(data) {
  let arr = [];
  if (data !== null && data !== undefined) {
    for (let user of data) {
      arr.push({
        label: `${user?.firstName} ${user?.lastName}`,
        value: user._id,
      });
    }
  }
  return arr;
}

export function isIssuer(issuer, loginUser) {
  if (issuer?._id === loginUser?._id) {
    return true;
  } else {
    return false;
  }
}
