import React from 'react';
import { useQuery } from '@apollo/client';
import { EmployeeViewForm } from 'pages/admin/employee/ViewForm';
import { FETCH_SINGLE_EMPLOYEE_DETAILS } from 'query';

import { FullModal, SpinnerComponent } from 'components';

const ViewSingleEmployee = ({ empId, empModal, toggleModal }) => {
  const { data, loading } = useQuery(FETCH_SINGLE_EMPLOYEE_DETAILS, {
    variables: { filters: { _id: empId } },
  });

  const viewData = data?.fetchUsersFiltered?.data[0] || {};

  const employeeData = {
    ...viewData,
    name: `${viewData?.firstName} ${viewData?.lastName}`,
    status: viewData?.status === 'pending' ? 'unverified' : viewData?.status,
  };
  return (
    <>
      <FullModal
        showModal={empModal}
        dontShowActions
        setShowModal={toggleModal}
        title={employeeData && !loading && employeeData?.name}
        addedOn={employeeData?.employmentInfo?.hireDate}>
        {loading ? <SpinnerComponent h="60vh" /> : <EmployeeViewForm employeeData={employeeData} />}
      </FullModal>
    </>
  );
};

export { ViewSingleEmployee };
