import React from 'react';
import { useWatch } from 'react-hook-form';
import { TiPlus } from 'react-icons/ti';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, BaseDropdown, SimpleModal } from '../../../../../../components';
import { DateIconWhite } from '../../../../../../components/shared/Svgs';
import { useWorkScheduleForm } from '../../../../../../context/workScheduleForm';
import { getDateOptions } from '../../helpers';

import { ActionDropdown } from './ActionDropdown';
import { CustomHolidayForm } from './CustomHolidayForm';
import { ScheduleTableWrapper } from './ScheduleTableWrapper';

// const isTesting = process.env.NODE_ENV === 'test';
const dateFieldName = 'date';
// const dummyHolidays = [
//   { id: Math.random(), name: 'Christmas Eve', [dateFieldName]: new Date(2022, 11, 24) },
//   { id: Math.random(), name: 'Christmas Day', [dateFieldName]: new Date(2022, 11, 25) },
//   { id: Math.random(), name: 'Boxing Day', [dateFieldName]: new Date(2022, 11, 26) },
// ];

function Holidays(props) {
  const { onSaveSchedule = () => {} } = props;
  const [showCustomHolidayModal, setShowCustomHolidayModal] = React.useState(false);
  const [editing, setEditing] = React.useState(null);

  const { control, setValue, date: current, setDate: setCurrent } = useWorkScheduleForm();
  const holidays = useWatch({ control, name: 'holidays' }) || [];
  holidays.sort((a, b) => a[dateFieldName] - b[dateFieldName]);

  function setHolidays(holidayList) {
    setValue('holidays', holidayList);
  }

  const actions = [
    { name: 'edit', display: 'Edit Holiday', method: handleEditHoliday },
    { name: 'delete', display: 'Delete Holiday', method: handleDeleteHoliday },
  ];

  function getDayUpdateHandler(id, payload) {
    return (day) => (day.id === id ? { ...day, ...payload } : day);
  }

  function onSave(payload, id = null) {
    if (id) {
      setHolidays(holidays.map(getDayUpdateHandler(id, payload)));
    } else {
      setHolidays([...holidays, { id: Math.random(), ...payload, type: 'custom' }]);
    }
    onSaveSchedule();
    closeCustomHolidayModal();
  }

  function closeCustomHolidayModal() {
    setShowCustomHolidayModal(false);
    setEditing(false);
  }

  function handleAddCustomHolidayClick() {
    setShowCustomHolidayModal(true);
  }

  function handleEditHoliday(id) {
    setEditing(holidays.find((h) => h.id === id));
    setShowCustomHolidayModal(true);
  }

  function handleDeleteHoliday(id) {
    setHolidays(holidays.filter((day) => day.id !== id));
    onSaveSchedule();
  }

  return (
    <HolidayRoot className="holiday">
      <header className="holiday__header">
        <h2>Holidays</h2>

        <div className="options">
          <BaseDropdown
            options={getDateOptions()}
            onChange={(v) => setCurrent(v.value)}
            value={current}
            border="1px solid #f2f2f2"
            w="100%"
            h="4.2rem"
          />
          <BaseButton variant="outline" h="4.2rem" smp>
            <TiPlus />
            Add Stat Holidays
          </BaseButton>
          <BaseButton
            data-testid="add-custom-holiday"
            variant="outline-blue"
            h="4.2rem"
            smp
            onClick={handleAddCustomHolidayClick}>
            <TiPlus />
            Add Custom Holiday
          </BaseButton>
        </div>
      </header>

      <ScheduleTableWrapper className="scroll-bar">
        <table className="holiday-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Date Observed</th>
              <th>
                <Box w="2.4rem"></Box>
              </th>
            </tr>
          </thead>
        </table>
        {holidays.length ? (
          <div className="body-table scroll-bar">
            <table>
              <tbody>
                {holidays.map((holiday) => (
                  <tr key={holiday.id}>
                    <td>{holiday.name}</td>
                    <td>{holiday[dateFieldName]?.toDateString()}</td>
                    <td>
                      <ActionDropdown actionList={actions} id={holiday.id} name={holiday.name} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        {!holidays.length ? (
          <NoHolidays>
            <h2>No holidays saved for {current}.</h2>
            <BaseButton variant="dark" smp>
              {DateIconWhite} Copy holidays from previous year
            </BaseButton>
          </NoHolidays>
        ) : null}
      </ScheduleTableWrapper>

      <SimpleModal width="77rem" showModal={showCustomHolidayModal}>
        <CustomHolidayForm editing={editing} onSave={onSave} onCancel={closeCustomHolidayModal} />
      </SimpleModal>
    </HolidayRoot>
  );
}

const HolidayRoot = styled.div`
  margin-top: 4.8rem;

  .holiday__header {
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    @media (min-width: 992px) {
      align-items: center;
      flex-flow: row;
    }

    .options {
      display: grid;
      grid: auto-flow 1fr / 1fr;
      gap: 0.8rem;
      margin-top: 2.4rem;

      @media (min-width: 992px) {
        grid: 1fr / 13rem max-content max-content;
        margin-top: 0;
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const NoHolidays = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 22rem;
  margin-top: 0.4rem;
  border: 1px solid var(--grey-150);
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.05em;
    margin-bottom: 1rem;
  }
`;

export { Holidays };
