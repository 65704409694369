import { Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Container = styled(Grid)`
  max-width: 910px;
  width: 100%;
  justify-items: center;
  margin: auto;
  gap: 24px;
  z-index: 10;

  .greeting {
    color: #fff;
    font-family: Karla;
    font-size: 20px;
    font-weight: 700;
    line-height: 16px; /* 80% */
    letter-spacing: 0.4px;
    padding: 20px 18px;
    border-radius: 30px;
    background: #2e334a;
    height: fit-content;
    width: fit-content;
  }

  @media only screen and (max-width: 910px) {
    gap: 12px;

    .greeting {
      font-size: 16px;
    }
  }
`;
export const Tabs = styled.div`
  padding: 51px 46px 31px 45px;
  width: 100%;
  height: 60vh;
  gap: 36px;
  border-radius: 12px;
  color: #23273b;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);

  @media only screen and (max-width: 700px) {
    padding: 20px;
  }
`;

export const FormErrorMessage = styled.p`
  color: var(--error);
  font-size: 1.2rem;
  margin-top: 5px;
`;
