import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Calendar from 'assets/icons/dateCalendar.svg';
import {
  bloodGenotype,
  bloodGroup,
  genderData,
  maritalStatus,
  twoAnswers,
} from 'data/dropDownOptions';

import { BaseDropdown, BaseInput, CustomButton } from 'components';

import { PhotoUpload } from '../../../../../admin/onboarding/setup-view/CompanyDetails/PhotoUpload';
import { HeadingText } from '../onboard-components/Heading';

export const Basics = ({ register, control, setNextTab, imageFile, setImageFile, logoUrl }) => {
  return (
    <BasicsRoot>
      <Grid className="upload-grid-wrap">
        <GridItem>
          <Box>
            <HeadingText headingText="Upload Profile Picture" mb="10px" />
          </Box>
        </GridItem>
        <GridItem>
          <PhotoUpload
            employee={true}
            imageFile={imageFile || []}
            setImageFile={setImageFile}
            logoUrl={logoUrl}
          />
        </GridItem>
      </Grid>
      <Grid className="status-grid-wrap">
        <GridItem>
          <Box>
            <HeadingText headingText="Status & Notice Period" mb="10px" />
          </Box>
        </GridItem>
        <GridItem>
          <Grid className="status-form-grid-wrap">
            <GridItem>
              <BaseInput
                {...register('status')}
                label="Status"
                placeholder="Status"
                fontSize="1.6rem"
                border={'none'}
                disabled
              />
            </GridItem>
            <GridItem>
              <BaseInput
                {...register('exitNoticePeriod')}
                label="Exit Notice Period"
                placeholder="Enter Exit Notice Period"
                fontSize="1.6rem"
                border={'none'}
                disabled
              />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <Grid className="bio-data-grid-wrap">
        <GridItem w="218px">
          <Box>
            <HeadingText headingText="Bio-data" mb="10px" />
          </Box>
        </GridItem>
        <GridItem>
          <Wrapper>
            <Grid className="bio-data-form-grid-wrap">
              <GridItem>
                <BaseInput
                  {...register('firstName')}
                  label="First Name"
                  fontSize="1.6rem"
                  disabled
                />
              </GridItem>
              <GridItem>
                <BaseInput {...register('lastName')} label="Last Name" fontSize="1.6rem" disabled />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="gender"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      options={genderData}
                      w="100%"
                      fontSize="1.6rem"
                      color="##333758"
                      label="Gender"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="maritalStatus"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      options={maritalStatus}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Marital Status"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('dob')}
                  fontSize="1.6rem"
                  type="date"
                  right={'1rem'}
                  color="#333758"
                  rightIcon={Calendar}
                  label="Date of Birth"
                  disabled
                />
              </GridItem>
              <GridItem>
                <BaseInput {...register('staffId')} label="Staff ID" fontSize="1.6rem" disabled />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="bloodGroup"
                  control={control}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose a Blood Group"
                      options={bloodGroup}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Blood Group"
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="bloodType"
                  control={control}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose a Genotype"
                      options={bloodGenotype}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Blood Type"
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('notableAilment')}
                  label="Notable Illness"
                  placeholder="Enter Notable Illness"
                  fontSize="1.6rem"
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="isDisabled"
                  control={control}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      options={twoAnswers}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Disability"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem mb="2.4rem">
                <BaseInput
                  {...register('disabilityType')}
                  label="If (Yes) State it"
                  fontSize="1.6rem"
                />
              </GridItem>
            </Grid>
            <CustomButton
              name="Confirm & Proceed"
              variant="primary"
              mW="240px"
              onClick={setNextTab}
              data-testid="basics-btn"
              type="button"
            />
          </Wrapper>
        </GridItem>
      </Grid>
    </BasicsRoot>
  );
};

const BasicsRoot = styled.div`
  padding: 40px 0 130px 16px;
  .inputType {
    border: none;
  }
  .css-1ksmwq8 {
    margin-top: 0;
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .css-1rdfat6 {
        width: 100%;
      }
    }
  }
  .css-1iyp8s0,
  .css-kcgnc0 {
    position: absolute;
    right: 0;
    margin-top: 48px;
    padding: 12px 48px;
  }

  .css-4302v8 {
    margin-top: 8px;
  }

  .css-b62m3t-container,
  .react-select--is-disabled {
    margin-top: 8px;
  }

  .css-18v3ng0-control {
    border: none;
  }

  .upload-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-bottom: 40px;
    @media (min-width: 1200px) {
      gap: 105px;
      grid-template-columns: 218px 500px;
    }
  }
  .status-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-bottom: 40px;
    @media (min-width: 1200px) {
      gap: 105px;
      grid-template-columns: 218px 500px;
    }
  }
  .status-form-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
    @media (min-width: 1200px) {
      gap: 16px;
      grid-template-columns: repeat(2, 242px);
    }
  }
  .bio-data-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 1200px) {
      gap: 105px;
      grid-template-columns: 218px 500px;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;

  .bio-data-form-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
    @media (min-width: 1200px) {
      gap: 16px;
      grid-template-columns: repeat(2, 242px);
    }
  }
`;
