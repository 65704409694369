import React from 'react';
import styled from '@emotion/styled';

import down from '../../../../assets/icons/down.svg';
import up from '../../../../assets/icons/up.svg';

const Container = styled.button``;
const SortAction = (props) => {
  return (
    <Container onClick={props.onClick}>
      <img src={up} alt="" />
      <img src={down} alt="" />
    </Container>
  );
};

export { SortAction };
