import React from 'react';
import { Box } from '@chakra-ui/react';
import { EmployeeOptionsProvider } from 'context';

import {
  BankDetails,
  ContactInformation,
  EmployementInformation,
  KinInformation,
  MedicalDetails,
  PensionDetails,
  PersonalDetails,
} from './employeeForms';

const FirstPage = ({ countryCode, getCountryCode, register, control, errors }) => {
  return (
    <Box>
      <PersonalDetails
        countryCode={countryCode}
        getCountryCode={getCountryCode}
        register={register}
        control={control}
        errors={errors}
      />
      <MedicalDetails register={register} control={control} errors={errors} />
    </Box>
  );
};

const SecondPage = ({ register, control, getCurrencyCode, currencyCode, getValues, errors }) => {
  const benefits = [
    'Pension',
    'Life Insurance',
    'Health Insurance',
    'Stock',
    'Training and development',
  ];
  return (
    <EmployeeOptionsProvider>
      <EmployementInformation
        benefits={benefits}
        register={register}
        control={control}
        getCurrencyCode={getCurrencyCode}
        currencyCode={currencyCode}
        getValues={getValues}
        errors={errors}
      />
    </EmployeeOptionsProvider>
  );
};

const ThirdPage = ({ register, control, errors }) => {
  return (
    <Box marginTop="4rem">
      <PensionDetails register={register} control={control} errors={errors} />
      <BankDetails register={register} control={control} errors={errors} />
    </Box>
  );
};

const FourthPage = ({
  contactStates,
  getCountryCode,
  countryCode,
  countries,
  register,
  control,
  kinStates,
  citiesData,
  allKinCountries,
  secondcontactCountries,
  secondcontactStates,
  errors,
}) => {
  return (
    <Box>
      <ContactInformation
        countries={countries}
        register={register}
        contactStates={contactStates}
        control={control}
        secondcontactStates={secondcontactStates}
        secondcontactCountries={secondcontactCountries}
        errors={errors}
      />
      <KinInformation
        register={register}
        control={control}
        getCountryCode={getCountryCode}
        countryCode={countryCode}
        allKinCountries={allKinCountries}
        citiesData={citiesData}
        kinStates={kinStates}
        errors={errors}
      />
    </Box>
  );
};

export { FirstPage, FourthPage, SecondPage, ThirdPage };
