import styled from '@emotion/styled';

export const Card = styled.div`
  padding: 2.4rem;
  border: 1px solid #f2f2f2;

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 2.4rem;
  }

  &.card {
    &__content {
      margin-top: 2.4rem;
    }

    .card__ctas {
      display: grid;
      grid: 1fr / auto-flow max-content;
      gap: 0.8rem;
      justify-content: flex-end;
    }
  }
`;
