import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { DELETE_ROLE, GET_ROLES } from 'query';
import { formatDate } from 'utils';

import { Status } from 'components/tables/TableConfig';

import { RoleNameFormat } from './tableConfig';

function OnboardingHooks() {
  const [checked, setChecked] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [rolesData, setRolesData] = React.useState([]);
  const [searchString, setSearchString] = React.useState('');
  const [{ showEdit, editing, roleName, editingRoleData }, setEditState] = React.useReducer(
    editRoleReducer,
    () => initialState
  );

  const { successToast, errorToast } = useToastify();

  const { data, loading, refetch } = useQuery(GET_ROLES, {
    variables: { page: page, filters: { searchString: searchString } },
  });

  const [deleteRole] = useMutation(DELETE_ROLE);

  React.useEffect(() => {
    const fetchedData = data?.fetchRolesFiltered?.data;
    setRolesData(fetchedData);
    setTotalPages(data?.fetchRolesFiltered?.totalPages);
  }, [data]);

  const rolesTableData = rolesData?.map(({ rolename, userCount, status, createdAt }) => {
    return {
      col1: RoleNameFormat({ rolename }) || null,
      col2: userCount || 0,
      col3: Status({ status }) || null,
      col4: formatDate(createdAt) || null,
    };
  });

  const setShowEdit = (val) => setEditState({ showEdit: val });
  const setEditingRole = (val) => setEditState({ roleName: val, editing: val });

  const handleChecked = (_email, id) => {
    setChecked((prev) => (checked.includes(id) ? prev.filter((cur) => cur !== id) : [...prev, id]));
  };

  const checkedBool = rolesData?.map((emp) => {
    return checked.includes(emp._id);
  });

  const editAction = ({ id }) => {
    const editData = rolesData?.find((elem) => elem._id === id);
    const editDataCopy = { ...editData };
    const newPermissions = editDataCopy?.permissions.map(function (permission) {
      if (permission.moduleName === 'Administrator') {
        return {
          moduleName: 'Admin Management',
          privileges: permission.privileges,
        };
      } else if (permission.moduleName === 'Exit') {
        return {
          moduleName: 'Exit Management',
          privileges: permission.privileges,
        };
      } else if (permission.moduleName === 'Leave') {
        return {
          moduleName: 'Leave Management',
          privileges: permission.privileges,
        };
      } else {
        return {
          moduleName: permission.moduleName,
          privileges: permission.privileges,
        };
      }
    });
    const newEditData = {
      ...editData,
      permissions: newPermissions,
    };

    setEditState({
      editingRoleData: newEditData,
      roleName: editData?.rolename,
      showEdit: true,
      editing: true,
    });
  };
  const deleteAction = async ({ id }) => {
    try {
      const result = await deleteRole({ variables: { _id: id } });
      if (result?.data?.deleteRole?.status === 200) {
        const message = result?.data?.deleteRole?.message;
        successToast(message);
      }
    } catch (error) {
      errorToast(error?.message || 'Error Occured');
    } finally {
      refetch();
    }
  };

  return {
    page,
    totalPages,
    setPage,
    showEdit,
    setShowEdit,
    handleChecked,
    checkedBool,
    editAction,
    deleteAction,
    checked,
    editing,
    setEditingRole,
    loading,
    rolesData,
    rolesTableData,
    editingRoleData,
    roleName,
    refetch,
    setSearchString,
  };
}

const initialState = { editingRoleData: {}, roleId: '', showEdit: false, editing: false };
function editRoleReducer(state, action) {
  return { ...state, ...action };
}

export { OnboardingHooks };
