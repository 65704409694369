import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { Grid } from '@chakra-ui/react';
import { FETCH_EXISTING_CONVO, SUBSCRIBE_TO_MESSAGES } from 'query';
import { getItem } from 'utils';

import { EmptyState, SpinnerComponent } from 'components';

import { getFrom, getLatestMessage } from '../helpers';

// import { dummyChats } from '../data';
import { ChatDetail, getChatName } from './ChatDetail';

export function ChatList(props) {
  const [convoList, setConvoList] = React.useState(() => []);
  const loading = React.useRef(true);
  const [, setSearchParams] = useSearchParams();
  const [fetchConversations] = useLazyQuery(FETCH_EXISTING_CONVO, {
    fetchPolicy: 'cache-and-network',
  });

  const userDetails = getItem('hrm_user');
  async function getConversationList(first = true) {
    try {
      if (first) loading.current = true;
      const res = await fetchConversations();

      setConvoList((prev) => {
        const convoIDs = first ? prev.map((c) => c._id) : [];
        const newList = res.data?.fetchExistingConversations?.data
          .filter((c) => !convoIDs.includes(c._id))
          .map((c) => ({
            ...c,
            from: getFrom(c?.participants, userDetails?._id),
            latest_message: getLatestMessage(c.messages),
          }));
        return [...(newList || []), ...(first ? prev : [])];
      });
    } finally {
      if (first) loading.current = false;
    }
  }

  const { data } = useSubscription(SUBSCRIBE_TO_MESSAGES, {
    variables: {
      receipientId: userDetails?._id,
    },
  });

  React.useEffect(() => {
    getConversationList(false);
  }, [props.refetch, data]);

  React.useEffect(() => {
    getConversationList();
    return () => setConvoList([]);
  }, []);

  React.useLayoutEffect(() => {
    if (props.activeChat?._id !== props.active)
      props.setActiveChat(convoList.find((c) => c._id === props.active));
  }, [props.active, convoList]);

  function setActive(id) {
    props.setActive(id);
    setSearchParams({ id }, { replace: true });
  }

  if (loading.current) return <SpinnerComponent h="40rem" />;

  function keywordInName(chat) {
    const name = getChatName(chat.from);
    return props.searchString ? name.includes(props.searchString) : true;
  }

  const ready = [...convoList].filter(keywordInName);
  ready.sort((a, b) => b?.latest_message?.timestamp?.localeCompare(a?.latest_message?.timestamp));
  const chatList = convoList.length ? (
    ready.map((chat) => (
      <ChatDetail
        {...chat}
        key={chat._id + chat?.latest_message?.timestamp}
        active={chat._id === props.active}
        onSelect={() => setActive(chat._id)}
      />
    ))
  ) : (
    <EmptyState message="No conversations yet" />
  );

  return <Grid autoRows="max-content">{chatList}</Grid>;
}
