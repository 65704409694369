import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { FETCH_EMPLOYEE_TRAINING, REMOVE_EMPLOYEE_TRAINING } from 'query';
import { getItem } from 'utils';

import {
  CustomButton,
  DeletePromptModal,
  FullModal,
  SimpleModal,
  SpinnerComponent,
} from 'components';

import { BaseLayout } from '../Layout';
import { ProfileCard } from '../overview/components';
import { getGreeting } from '../overview/helpers';
import { FETCH_EMPLOYEE_DETAILS } from '../query';

import { ActiveTraining } from './ActiveTraining';
import { CompletedTraining } from './CompletedTraining';
import { RecordPrompt } from './component';
import { SingleTrainingView } from './SingleTraining';
import { UpcomingTraining } from './UpcomingTraining';

const isTesting = process.env.NODE_ENV === 'test';

export const Training = () => {
  const [showRecord, setShowModal] = React.useState(false);
  const [showFull, setShowFull] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [trainingId, setId] = React.useState('');

  const { successToast } = useToastify();

  const actionToPerform = ({ action, id }) => {
    setId(id);
    switch (action) {
      case 'View Details':
        setShowFull(true);
        break;
      case 'Record Completion':
        setShowModal(true);
        break;
      case 'Delete Training':
        setShowDelete(true);
        break;
    }
  };

  const navigate = useNavigate();

  const user = getItem('hrm_user');

  let id = user?._id;
  if (isTesting) {
    id = 'mock_id';
  }

  const [removeTraining, { loading: deleting }] = useMutation(REMOVE_EMPLOYEE_TRAINING);

  async function deleteResource() {
    const response = await removeTraining({
      variables: { trainingId: trainingId },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: FETCH_EMPLOYEE_TRAINING,
          variables: {
            filter: {
              status: 'in_progress',
            },
          },
        },
        {
          query: FETCH_EMPLOYEE_TRAINING,
          variables: {
            filter: {
              status: 'upcoming',
            },
          },
        },
      ],
    });
    const message = response?.data?.removeTraining?.message;
    successToast(message);
    setShowDelete(false);
  }

  const { data: profileData, loading } = useQuery(FETCH_EMPLOYEE_DETAILS, {
    variables: {
      filters: {
        _id: id,
      },
    },
  });

  return (
    <MainContent data-testid="employee-training">
      <BaseLayout>
        <>
          <div>
            <p>{`Good ${getGreeting()}, ${user?.firstName}`}</p>
            <p className="text-16-400 sub-heading">
              {`Here’s what’s going on at ${user?.companyId?.companyName} today`}
            </p>
          </div>
          <CustomButton
            variant={'primary'}
            name="Create Training"
            mW="180px"
            h="48px"
            onClick={() => navigate('/employee/training-create')}
          />
        </>
        <Content>
          <div className="bio">
            {loading ? (
              <SpinnerComponent />
            ) : (
              <ProfileCard userData={profileData?.fetchUsersFiltered?.data[0]} />
            )}
          </div>
          <div className="right__panel">
            <UpcomingTraining action={actionToPerform} />
            <ActiveTraining action={actionToPerform} />
            <CompletedTraining action={actionToPerform} />
          </div>
        </Content>
      </BaseLayout>
      <SimpleModal showModal={showRecord} padding="2.4rem" width={'75rem'}>
        <RecordPrompt cancel={() => setShowModal(false)} id={trainingId} />
      </SimpleModal>
      <FullModal showModal={showFull} noHeader mt="8rem">
        <SingleTrainingView setShowFull={() => setShowFull(false)} id={trainingId} />
      </FullModal>
      <DeletePromptModal
        show={showDelete}
        cancel={() => setShowDelete(false)}
        deleteItem={() => deleteResource()}
        loading={deleting}
      />
    </MainContent>
  );
};

const MainContent = styled.div`
  .full-modal-wrapper {
    padding-left: 1.2rem;
  }
  .right-modal-overlay {
    padding: 2.4rem;
  }

  .modal__content {
    .header {
      display: flex;
      align-items: center;
      gap: 2.4rem;
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #23273b;
      }
    }
  }
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding-bottom: 4rem;

  @media (min-width: 1050px) {
    grid-template-columns: 274px auto;
  }

  .right__panel {
    display: grid;
    gap: 2.4rem;
    .table-container {
      background: #ffffff;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      padding: 24px 32px 0;
    }
  }
`;
