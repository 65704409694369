import React from 'react';
import styled from '@emotion/styled';

import { SpinnerLoader } from '../spinner-loader';

const ReusableButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  &.isDisabled {
    background-color: #939aa3 !important;
  }

  &.false {
    background-color: #3e7eee;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  :hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 700px) {
    font-size: 14px;
  }
`;

const Button = (props) => {
  return (
    <ReusableButton
      data-testid={props.dataTestId}
      className={props.isDisabled ? 'isDisabled' : props.isDisabled}
      style={props.style}
      onClick={props.onClick}
      isLoading={props.isLoading}
      disabled={props.isDisabled}>
      {props.isLoading ? (
        <SpinnerLoader isLoading={props.isLoading} />
      ) : (
        !props.isLoading && (
          <div className="button" style={props.imgStyle}>
            {props.img && <img src={props.img} alt="" />}
            {props.title}
          </div>
        )
      )}
    </ReusableButton>
  );
};

export { Button };
