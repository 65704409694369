import React from 'react';
import { Box, Flex, Image, Input, Link } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Wrapper = styled(Box)`
  .image {
    width: 48px;
    height: 48px;
  }
  .button {
    gap: 16px;
    font-weight: 700;
    font-size: 14px;
    color: #3b74d9;

    &-1 {
      color: #475467;
    }

    &-2 {
      cursor: pointer;
    }
  }
`;

export const BaseFile = React.forwardRef(({ name, onChange, value, edit }, ref) => {
  const isString = typeof value === 'string' ? value : URL.createObjectURL(value);
  const isValue = value ? isString : '';

  const file = React.useMemo(() => {
    return isValue;
  }, [value]);

  const handleUpload = (e) => {
    const imageFile = e.target.files[0];
    onChange(imageFile);
  };

  const handleDelete = () => {
    onChange();
  };

  return (
    <Wrapper>
      <Flex gap={'16px'} justifyContent={'space-between'}>
        {file ? (
          <Image className="image" objectFit={'cover'} borderRadius={'50%'} src={file} />
        ) : (
          ''
        )}
        {edit ? (
          <Flex className="button">
            {file ? (
              <Link className="button-1" onClick={handleDelete}>
                Delete
              </Link>
            ) : (
              ''
            )}

            <label htmlFor={name} className="button-2">
              <Input
                className="file"
                display={'none'}
                name={name}
                type="file"
                id={name}
                ref={ref}
                onChange={handleUpload}
              />
              Update
            </label>
          </Flex>
        ) : null}
      </Flex>
    </Wrapper>
  );
});

BaseFile.displayName = 'BaseFile';
