import React from 'react';
import { Grid, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Cookies from 'universal-cookie';

import { SimpleModal } from 'components';

import Confetti from './Confetti';

const Container = styled(Grid)`
  height: fit-content !important;
  width: 500px !important;
  gap: 24px;

  .event {
    position: relative;
    justify-items: center;
    padding: 0 30px;
  }

  .text {
    color: var(--black-hr-ms, #23273b);
    text-align: center;
    gap: 1.4rem;
    padding: 0 30px 30px;

    &-1 {
      font-size: 26px;
      font-weight: 700;
      text-transform: capitalize;
    }
    &-2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 175.5%; /* 24.57px */
    }
  }

  .cake {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 235px;
  }

  .anniversary {
    &.event {
      padding: 0px;
    }

    .cake {
      width: 100%;
      margin-top: 30px;
    }

    .confetti {
      width: 100%;
    }

    .text {
      padding: 30px;
    }
  }
`;

const EventPopUp = ({
  confetti,
  popUpPicture,
  heading,
  text,
  showModal,
  setShowModal,
  employeeDate,
  className,
  type,
}) => {
  let date = new Date(employeeDate).toLocaleDateString().slice(0, 5);
  let todayDate = new Date().toLocaleDateString().slice(0, 5);
  const cookies = new Cookies(type);

  React.useEffect(() => {
    if (todayDate === date) {
      if (cookies.get(type)) {
        setShowModal(false); //Modal does not open if cookie exists
      } else {
        setShowModal(true);
        cookies.set(type, 'true', {
          path: '/',
        });
      }
    } //Creates a cookie and shows modal.
    else {
      setShowModal(false);
    }
  }, [date]);

  return (
    <SimpleModal showModal={showModal} setShowModal={setShowModal}>
      <Confetti />
      <Container>
        <Grid className={`event ${className}`}>
          <Image src={confetti} className={'confetti'} />
          <Image src={popUpPicture} className={'cake'} />
        </Grid>
        <Grid className="text">
          <Text className="text-1">{heading}</Text>
          <Text className="text-2">{text}</Text>
        </Grid>
      </Container>
    </SimpleModal>
  );
};

export { EventPopUp };
