import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Grid, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatDate } from 'pages/admin/announcements/helpers';

import { SpinnerComponent } from 'components';
import RichTextEditor from 'components/admin/jobs/job-details/RichTextEditor';

import { LinkContainer } from './components/LinkContainer';
import { BackButton, Socials } from './components';
import { useJobDetails } from './hooks';

const PublicJobDescription = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const params = useParams();

  const jobId = params.id;
  const { isLoading, data: jobDetails } = useJobDetails(jobId);
  const jobDescription = React.useMemo(() => {
    return jobDetails?.description
      ? jobDetails?.description?.startsWith('[')
        ? JSON.parse(jobDetails?.description)
        : [{ type: 'paragraph', children: [{ text: jobDetails?.description }] }]
      : null;
  }, [jobDetails]);

  useEffect(() => {}, [params.id]);

  if (isLoading) {
    return (
      <Grid minH="20rem" placeContent="center" mt="20rem">
        <SpinnerComponent data-testid="loading" />
      </Grid>
    );
  }
  const handleBack = () => {
    navigate(-1);
  };

  const handleApply = () => {
    navigate(`/jobs/${jobId}/apply`);
  };

  return (
    <Grid
      key={jobDetails ? jobDetails?.updatedAt : null}
      gridTemplateColumns={{ base: '100%', md: 'auto 35%', lg: 'auto 444px' }}
      alignItems="flex-start"
      gap="2.4rem"
      maxWidth="128rem"
      mx="auto">
      <Box p="3.2rem" border="1px solid #E8E8E8" backgroundColor="#fff">
        <BackButton onClick={handleBack} data-testid={'back-btn'}>
          Back
        </BackButton>
        <Box mt="3.2rem" mb="2.4rem">
          {!jobDetails && !isLoading ? (
            <Text fontSize="1.4rem" textAlign={'center'}>
              We couldn&apos;t retreive any data for this job id.
              <br />
              Check the id and try again!!
            </Text>
          ) : null}
          {jobDetails?.title && (
            <Text fontSize="2.4rem" fontWeight="bold">
              {jobDetails?.title}
            </Text>
          )}
          {jobDetails?.city && jobDetails?.country && (
            <Text fontSize="1.2rem">
              {jobDetails?.city}, {jobDetails?.country}
            </Text>
          )}
        </Box>

        <Divider orientation="horizontal" mb="4rem" />

        {jobDetails?.headerImage ? (
          <Box mb="20px">
            <Image
              src={jobDetails?.headerImage}
              w="100%"
              h="360px"
              objectFit="cover"
              objectPosition="center"
            />
          </Box>
        ) : null}

        <DescriptionWrapper>
          {jobDescription && (
            <>
              <Text fontSize="1.8rem" fontWeight="600" lineHeight="1.1">
                Job Description
              </Text>
              <RichTextEditor initialValue={jobDescription} preview />
            </>
          )}
        </DescriptionWrapper>
      </Box>

      <Grid templateColumns="100%" gap="2.4rem" w="100%">
        <Box p="3.2rem" border="1px solid #E8E8E8" backgroundColor="#fff">
          <Button
            h="5.6rem"
            w="100%"
            color="#fff"
            backgroundColor="brand.blue"
            _hover={{ backgroundColor: 'hsla(218, 84%, 65%, 1)' }}
            _active={{ backgroundColor: 'hsla(218, 84%, 62%, 1)' }}
            fontSize="1.6rem"
            fontWeight="700"
            onClick={handleApply}>
            Apply
          </Button>
          <Divider orientation="horizontal" marginBlock="1.6rem 2.4rem" />
          <LinkContainer />
          <Socials link="#" />
        </Box>

        <Box p="3.2rem" border="1px solid #E8E8E8" backgroundColor="#fff">
          <Text fontWeight="700" fontSize="1.6rem" mb="2.4rem">
            Role Overview
          </Text>

          <BlueLabeled label="Role">
            {jobDetails?.title && (
              <Text fontSize="1.6rem" mb="2.4rem">
                {jobDetails?.title}
              </Text>
            )}
          </BlueLabeled>

          <BlueLabeled label="Type">
            {jobDetails?.type && (
              <Text fontSize="1.6rem" mb="2.4rem">
                {jobDetails?.type}
              </Text>
            )}
          </BlueLabeled>

          <BlueLabeled label="Deadline">
            {jobDetails?.deadline && (
              <Text fontSize="1.6rem" mb="2.4rem">
                {formatDate(jobDetails?.deadline)}
              </Text>
            )}
          </BlueLabeled>

          <BlueLabeled label="Years of Experience">
            {jobDetails?.experience && (
              <Text fontSize="1.6rem" mb="2.4rem">
                {jobDetails?.experience}
              </Text>
            )}
          </BlueLabeled>

          <BlueLabeled label="Location">
            {jobDetails?.city && jobDetails?.country && (
              <Text fontSize="1.6rem" mb="2.4rem">
                {jobDetails?.city}, {jobDetails?.country}
              </Text>
            )}
          </BlueLabeled>

          <BlueLabeled label="Skills">
            <ul style={{ paddingLeft: '24px', marginBottom: '24px' }}>
              {jobDetails?.skills &&
                jobDetails?.skills.map((skill) => (
                  <li key={skill}>
                    <Text textTransform="capitalize" fontSize="1.6rem">
                      {skill}
                    </Text>
                  </li>
                ))}
            </ul>
          </BlueLabeled>

          <BlueLabeled label="Salary">
            {jobDetails?.salary && (
              <Text fontSize="1.6rem" mb="2.4rem">
                {jobDetails?.salary}
              </Text>
            )}
          </BlueLabeled>
        </Box>
      </Grid>

      {/* <Box
        gridColumn={{ base: 'span 1', lg: 'span 2' }}
        backgroundColor="hsla(210, 33%, 98%, 1)"
        p="4rem 4rem 7rem"
        mb="-3rem"
        lineHeight="2.4rem">
        <Text fontWeight="600" fontSize="1.6rem">
          About Enyata
        </Text>
        <Text maxWidth="71.2rem">
          Enyata is building an HR and payroll solution for growing teams in Africa. We are
          providing these businesses with tools for performance management, Payroll, compliance,
          benefits, and salary disbursement.
        </Text>
      </Box> */}
    </Grid>
  );
};

const BlueLabeled = (props) => {
  return (
    <>
      <Text
        fontSize="1.4rem"
        textTransform="uppercase"
        color="brand.blue"
        fontWeight="500"
        mb="0.4rem">
        {props.label}
      </Text>
      {props.children}
    </>
  );
};

const DescriptionWrapper = styled.div`
  font-size: 1.6rem;
  line-height: 1.6;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-block: 1.6rem 0.8rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 2.4rem;
    margin-bottom: 2.4rem;
    margin-top: -0.4rem;

    li {
      margin-bottom: 0.25rem;
    }
  }
`;

export const useScrollToTop = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export { PublicJobDescription };
