import React from 'react';

export function useEditField(options = {}) {
  const { onSave = () => Promise.resolve(), reset = () => void 0, defaults } = options;
  const [isEditing, setIsEditing] = React.useState();

  const onOpenEdit = () => setIsEditing(true);
  const onCloseEdit = () => {
    setIsEditing(false);
    reset(defaults);
  };
  const onSaveChanges = async () => {
    await onSave();
    setIsEditing(false);
  };

  return { isEditing, onOpenEdit, onCloseEdit, onSaveChanges };
}
