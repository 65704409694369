import React from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import {
  BroadCastMessage,
  CustomButton,
  DashboardCards,
  EmployeesCard,
  HiringPipeline,
  SimpleModal,
  SingleWishes,
  SpinnerComponent,
  Task,
  UpcomingEventsCard,
} from '../../../components';
import { useHook } from '../onboarding/hooks';
import { SetupChecklistPage } from '../onboarding/setupChecklist';
import { WelcomePage } from '../onboarding/Welcome';

const isTesting = process.env.NODE_ENV === 'test';
const DashboardHome = () => {
  const [showWelcome, setShowWelcome] = React.useState(true);
  const [showWishModal, setShowWishModal] = React.useState(false);
  const [showBroadcastModal, setShowBroadcastModal] = React.useState(false);
  const [showChecklist, setShowChecklist] = React.useState(false);
  const [employeeObj, setEmployeeObj] = React.useState({});
  const {
    compD,
    AdminD,
    RolesD,
    Department,
    loading1,
    loading2,
    loading3,
    loading4,
    user,
    pipeline,
  } = useHook();

  if ((loading1 || loading2 || loading3 || loading4) && !isTesting) {
    return <SpinnerComponent h="80vh" />;
  }

  const onboarded = compD && AdminD && RolesD && Department;
  const welcome = isTesting ? false : !onboarded && showWelcome;

  const skip = () => {
    setShowWelcome(false);
    setShowChecklist(false);
  };
  const completeSetup = () => {
    setShowWelcome(false);
    setShowChecklist(true);
  };

  const closeBroadcastModal = () => {
    setShowBroadcastModal(false);
  };

  const closeWishModal = () => {
    setShowWishModal(false);
  };

  const wish = `Special Wishes to ${employeeObj?.firstName}`;
  const broadcastTitle = `Broadcast ${employeeObj?.firstName}'s birthday`;

  return (
    <>
      {!welcome && !showChecklist && (
        <HomeWrapper>
          <Box className="top__panel">
            <div>
              <Text className="heading">DASHBOARD</Text>
              <Text className="sub-heading">
                Hello{' '}
                {`${user?.firstName}. Welcome to your ${user?.companyId?.companyName} workspace`}
              </Text>
            </div>
            <div className="btn_wrapper">
              <CustomButton
                onClick={() => completeSetup(true)}
                name={onboarded ? 'Edit Details' : 'Complete Setup'}
                variant={'primary'}
                mW={'21.8rem'}
                h={'4.8rem'}
              />
            </div>
          </Box>
          <DashboardCards />
          <CGrid>
            <Task />
            <EmployeesCard />
          </CGrid>
          <CGrid paddingBottom={'5rem'}>
            <HiringPipeline pipelineData={pipeline} />
            <UpcomingEventsCard
              setShowWishModal={setShowWishModal}
              setShowBroadcastModal={setShowBroadcastModal}
              setEmployeeDetails={setEmployeeObj}
            />
          </CGrid>
          <SimpleModal showModal={showWishModal} bR="1.6rem" closeModal={closeWishModal}>
            <SingleWishes closeModal={closeWishModal} employee={employeeObj} wish={wish} />
          </SimpleModal>
          <SimpleModal bR="1.6rem" showModal={showBroadcastModal} closeModal={closeBroadcastModal}>
            <BroadCastMessage
              closeModal={closeBroadcastModal}
              broadcastTitle={broadcastTitle}
              closeBroadcastModal={closeBroadcastModal}
              wish={wish}
            />
          </SimpleModal>
        </HomeWrapper>
      )}

      {welcome && <WelcomePage skip={skip} completeSetup={completeSetup} />}

      {showChecklist && <SetupChecklistPage skip={skip} />}
    </>
  );
};

const HomeWrapper = styled(Grid)`
  padding: 4rem 2.4rem;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;

  .top__panel {
    position: sticky;
    top: 6rem;
    z-index: 1;
    height: 8.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 0.4rem;
    color: #fff;
    align-items: center;
    padding: 0 1.6rem;
    background-color: var(--deep_blue);

    .btn_wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  .heading {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  .sub-heading {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 400;
  }
`;
export const CGrid = styled(Grid)`
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media (min-width: 1200px) {
    grid-template-columns: auto 35.9rem;
    grid-gap: 2rem;
  }
`;

export { DashboardHome };
