import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import folderIcon from 'assets/icons/Folder.svg';

import { CustomButton } from 'components';

const Empty = ({ addNew }) => {
  return (
    <EmptyRoot data-testid="empty-assessment">
      <div className="content__emptyState">
        <Flex className="icon__wrapper">
          <img src={folderIcon} alt="" />
        </Flex>
        <p className="black-text">Start by creating an assesment</p>
        <p className="grey">
          Click add new to create assessment questions that is shown to applicants when they apply
          for this Job.
        </p>

        <CustomButton onClick={addNew} name="Add new" variant="primary" h="42px" mW="180px" />
      </div>
    </EmptyRoot>
  );
};

const EmptyRoot = styled.div`
  width: 100%;
  height: calc(100vh - 20rem);
  display: flex;
  justify-content: center;
  align-items: center;
  .grey {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #999fa3;
    margin-bottom: 3.2rem;
  }

  .black-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4d4d4d;
  }

  .content__emptyState {
    // border: 1px solid green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    max-width: 450px;
    height: auto;
    text-align: center;
    .icon__wrapper {
      justify-content: center;
      align-items: center;
      width: 4.2rem;
      height: 4.2rem;
      background: rgba(62, 126, 238, 0.08);
      border-radius: 4px;
    }
  }
`;

export { Empty };
