import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { UserImage } from 'pages/admin/employee/tableConfig';
import { FETCH_EXITED_EMPLOYEES } from 'query/admin/exit-management';
import { formatDate } from 'utils';

import { ModalBackButton, Pagination, Search, SpinnerComponent, TableComponent } from 'components';

import { ViewSingleEmployee } from './Modals/ViewSingleEmployee';

const ExitedEmployees = () => {
  const navigate = useNavigate();
  const columns = [
    {
      Header: 'Employee Name',
      accessor: 'col1',
    },
    {
      Header: 'Job Title',
      accessor: 'col2',
    },
    {
      Header: 'Department',
      accessor: 'col3',
    },
    {
      Header: 'Type of Exit',
      accessor: 'col4',
    },
    {
      Header: 'Resignation Date',
      accessor: 'col5',
    },
  ];
  const [page, setPage] = React.useState(1);
  const [empModal, setEmpModal] = React.useState(false);
  const [empId, setEmpId] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [searchString, setSearchString] = React.useState('');
  const [search, setSearch] = React.useState('');
  const totalPages = 1;

  const { data, loading, refetch } = useQuery(FETCH_EXITED_EMPLOYEES, {
    variables: {
      page: page,
      filter: searchString ? { name: searchString } : {},
    },
    // pollInterval: 500,
  });
  React.useEffect(() => {
    refetch();
    const exitedEmployeesData = data?.fetchExitedEmployees?.data;
    setTableData(exitedEmployeesData);
  }, [data, searchString]);

  const newTableData = tableData?.map(
    ({ employeeId, designation, department, type, createdAt }) => {
      const name = `${employeeId?.firstName} ${employeeId?.lastName}`;
      return {
        col1: UserImage({
          name,
          imageUrl: employeeId?.imageUrl,
          firstName: employeeId?.firstName,
          lastName: employeeId?.lastName,
        }),
        col2: designation,
        col3: department,
        col4: type,
        col5: formatDate(createdAt),
      };
    }
  );

  function toggleEmpModal(id) {
    setEmpModal(!empModal);
    setEmpId(id);
  }

  function handleSearchChange(val) {
    setSearch(val);
  }

  const actionOnData = ({ action, id }) => {
    switch (action) {
      case 'view':
        toggleEmpModal(id);
        break;
    }
  };

  const back = () => {
    navigate('/admin/exit-management');
  };

  const dropdownActions = ['View'];

  if (loading)
    return (
      <Flex minH="20rem" justifyContent="center" alignItems={'center'} mt="20rem">
        <SpinnerComponent data-testid="loading" />
      </Flex>
    );

  return (
    <>
      <Wrapper>
        <Flex gap="24px" alignItems={'center'}>
          <ModalBackButton onClick={back} />
          <h1 className="exit-header">EXIT MANAGEMENT</h1>
        </Flex>
        <Box padding={'24px'} className="table-wrapper">
          <Search
            placeholder={'Search Employee'}
            value={search}
            setPage={setPage}
            setSearchString={setSearchString}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <Box mt={'24px'}>
            {!loading && (
              <TableComponent
                noCheckbox
                noSort
                data={newTableData}
                empty={tableData?.length === 0}
                columns={columns}
                ids={tableData?.map((e) => e?.employeeId?._id)}
                dropdownActions={dropdownActions}
                actionOnData={actionOnData}
              />
            )}
          </Box>
        </Box>
        <Box mt="1rem" position="fixed" right={'4rem'} left={'27.2rem'} bottom={'1.2rem'}>
          {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
        </Box>
        <ViewSingleEmployee toggleModal={toggleEmpModal} empId={empId} empModal={empModal} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Box)`
  padding: 24px;
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-transform: uppercase;
    color: #23273b;
  }

  .table-wrapper {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    margin-top: 24px;
    height: 100%;
  }
`;

export { ExitedEmployees };
