import React from 'react';
import { Flex, FormControl } from '@chakra-ui/react';

import { MessageCardContainer } from './styles';

export const MessageCardComponent = ({ wish, children }) => {
  return (
    <MessageCardContainer>
      <Flex alignItems="center" justifyContent="space-between"></Flex>
      <p>{wish}</p>
      <form>
        <FormControl mt="10px">{children}</FormControl>
      </form>
    </MessageCardContainer>
  );
};
