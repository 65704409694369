import { gql } from '@apollo/client';

export const FETCH_ALL_DEPARTMENTS = gql`
  query Query($page: Int) {
    fetchCompanyDepartments(page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        name
        employeesCount
        createdBy {
          _id
          firstName
          lastName
        }
        designation {
          name
        }
        createdAt
      }
    }
  }
`;
