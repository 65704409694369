import { stringToOption } from 'utils';

import { dummyDescription } from './dummy';

// TODO - replace later
export const createInitJobDetailsForm = (options = {}) => {
  return {
    defaultValues: {
      ...options,
    },
  };
};

export const createTestInitJobDetailsForm = (options = {}) => {
  return {
    defaultValues: {
      title: 'Senior Product Design',
      description: dummyDescription,
      skills: [
        'UI Design',
        'Communication',
        'Wireframing',
        'Design Thinking',
        'User Centred Design',
      ].map((s) => stringToOption(s)),
      createdAt: new Date(2022, 6, 23),
      deadline: new Date(2022, 10, 15),
      hiringManager: { label: 'Kingsley Omin', value: 'Kingsley Omin' },
      department: { label: 'Design', value: 'Design' },
      type: { label: 'Full-Time', value: 'Full-Time' },
      experience: '5+ years',
      country: 'Ghana',
      state: 'Greater Accra',
      city: 'Osu',
      salary: '$150,000 per annum',
      ...options,
    },
  };
};
