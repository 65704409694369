import React from 'react';
import { Flex, Heading, Stack, Text } from '@chakra-ui/react';

import { CustomButton, CustomCheckbox, NameWithImage } from 'components';

import RichTextEditor from '../../../../../components/admin/jobs/job-details/RichTextEditor';

import { RichTextEditorWrapper, ViewAnnouncementWrapper } from './styles';

export function ViewAnnouncement(props) {
  return (
    <ViewAnnouncementWrapper>
      <Stack gap={'24px'}>
        <Stack
          p={'16px 24px'}
          border={'solid 1px var(--grey-150)'}
          borderRadius={'4px'}
          gap={'32px'}>
          <Heading textTransform={'capitalize'}>{props?.announcement?.type}</Heading>

          <Flex gap={'8px'} alignItems={'center'}>
            <Heading fontSize={'16px'}>Subject:</Heading>
            <Text>{props?.announcement?.title}</Text>
          </Flex>

          {props?.announcement?.isImportant && (
            <CustomCheckbox label={'Mark as important'} checked={true} />
          )}

          <Stack>
            <Text fontSize={'12px'} fontWeight={'600'}>
              Content
            </Text>
            <RichTextEditorWrapper>
              <RichTextEditor initialValue={JSON.parse(props?.announcement?.content)} preview />
            </RichTextEditorWrapper>
          </Stack>

          <Flex gap={'32px'} fontWeight={'600'} alignItems={'center'}>
            <Text fontSize={'12px'} color={'var(--grey-500)'}>
              Issued by:{' '}
            </Text>
            <NameWithImage data={props?.announcement?.issuedBy} />
          </Flex>
        </Stack>
      </Stack>

      <Flex justifyContent={'flex-end'} p={'24px 0 0 0'}>
        <CustomButton
          name={'cancel'}
          onClick={props.cancelModal}
          variant={'red--outline'}
          h={'43px'}
          mW={'96px'}
        />
      </Flex>
    </ViewAnnouncementWrapper>
  );
}
