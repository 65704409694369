import { useMutation, useQuery } from 'react-query';
import { get, post } from 'query/axios-client';

export function useJobDetails(id) {
  function getJob(options) {
    const { queryKey } = options;
    return get(`/jobs/${queryKey[1]}`);
  }

  const queryKey = ['job-details', id];
  return useQuery(queryKey, getJob);
}

export function useJobList(query) {
  function getJobList() {
    return get(`/jobs?title=${query}`);
  }

  const queryKey = ['job-list', query];
  return useQuery(queryKey, getJobList);
}

export function useApplyToJob(options = {}) {
  function applyToJob({ id, body } = {}) {
    return post(`/apply/${id}`, body);
  }

  return useMutation({
    mutationFn: applyToJob,
    ...options,
  });
}
