import React from 'react';
import styled from '@emotion/styled';

export const CustomRadio = React.forwardRef(
  ({ options = [], showLabel, valueIsObj, ...rest }, ref) => {
    return (
      <>
        {options.map((option) => (
          <RadioLabel key={option?.label} htmlFor={`${option?.label}|${option?.value}`}>
            <input
              ref={ref}
              type="radio"
              value={valueIsObj ? `${option?.label}|${option?.value}` : option?.value}
              id={`${option?.label}|${option?.value}`}
              className="radio-input"
              {...rest}
            />
            {showLabel ? <span>{option?.label}</span> : null}
          </RadioLabel>
        ))}
      </>
    );
  }
);

CustomRadio.displayName = 'CustomRadio';

export const RadioLabel = styled.label`
  display: grid;
  grid: 1fr / auto-flow max-content;
  align-items: center;
  gap: 8px;
  margin-right: 3.2rem;
  margin-bottom: 4px;

  input[type='radio'],
  input[type='checkbox'] {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--grey-300);
    appearance: none;
    cursor: pointer;

    &:checked {
      border-color: var(--primary);
    }

    &:checked:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 8px;
      width: 8px;
      border-radius: inherit;
      background: var(--primary);
      transform: translate(-50%, -50%);
    }
  }

  &.checkbox {
    input[type='checkbox'] {
      border-radius: 3px;

      &:checked:before {
        border-radius: 1px;
        height: 9px;
        width: 9px;
        transform: translate(-51%, -51%);
      }
    }
  }
`;
