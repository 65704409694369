import React from 'react';
import styled from '@emotion/styled';

import person from '../../../../assets/icons/person.svg';
import { StarRating } from '../../../../components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 32px;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0 56px;
  gap: 8px;
`;

const Card = styled.div`
  width: 200px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;

  .top {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 8px;
    margin: 16px 16px 0;
    border-bottom: 1px solid rgba(62, 126, 238, 0.2);
  }

  .bottom {
    position: relative;
    padding: 8px 16px 16px;
    display: flex;
    align-items: center;

    p {
      text-align: right;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #83889d;
    }
  }
`;
const Stage = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;

  .category {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #ffffff;
    border-radius: 4px;

    &.new {
      border-top: 3px solid #3d7471;
    }

    &.screening {
      border-top: 3px solid #a8c690;
    }

    &.assessment {
      border-top: 3px solid #ff844b;
    }

    &.interview {
      border-top: 3px solid #a8e7e2;
    }

    &.hired {
      border-top: 3px solid #c57ccc;
    }
  }

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #23273b;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #83889d;
    padding: 4px;
    background: #f3f4f6;
    border-radius: 4px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

const Category = (props) => {
  let stages = [
    { displayName: `New Applied`, value: 'new' },
    {
      displayName: `Screening`,
      value: 'screening',
    },
    { displayName: `Assessment`, value: 'assessment' },
    { displayName: `Interview`, value: 'interview' },
    { displayName: `Hired`, value: 'hired' },
  ];

  // const getFilteredlist = () => {
  //   const filteredList = props.list.map((filteredList) =>
  //     return value === filteredList.stage.value && (
  //   ))
  // };

  return (
    <Container>
      {stages?.map(
        ({ displayName, value }) =>
          value && (
            <Stage key={value}>
              <div className={`category ${value}`}>
                <p>{displayName}</p>
                <span>5</span>
              </div>
              <Cards>
                {props.list?.map(
                  (filteredList) =>
                    value === filteredList.stage.value && (
                      <Card key={filteredList.id}>
                        <div className="top">
                          <img src={person} />
                          <p>{filteredList.name}</p>
                        </div>
                        <div className="bottom">
                          <StarRating rating={filteredList.rating} />
                          <p>2 days ago</p>
                        </div>
                      </Card>
                    )
                )}
              </Cards>
            </Stage>
          )
      )}
    </Container>
  );
};

export { Category };
