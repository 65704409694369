import React from 'react';
import { useMutation } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';
import { EDIT_LEAVE, FETCH_ALL_LEAVE_REQUEST, FETCH_SINGLE_LEAVE_REQUEST } from 'query';

import { Calendar, CustomButton } from 'components';

import { formatD } from '../helper';

const EditLeaveRequest = ({ cancel, editData, leaveId, refetch, employee }) => {
  const [date, setDate] = React.useState(new Date(editData?.startDate || new Date()));
  const [toDate, setToDate] = React.useState(new Date(editData?.endDate || new Date()));

  const [editLeaveRequest, { loading }] = useMutation(EDIT_LEAVE);

  const { successToast, errorToast } = useToastify();

  const editLeave = async () => {
    const startDate = formatD(date);
    const endDate = formatD(toDate);

    try {
      const response = await editLeaveRequest({
        variables: {
          data: {
            startDate: startDate,
            endDate: endDate,
          },
          filters: {
            _id: leaveId,
          },
        },
        refetchQueries: [
          {
            query: FETCH_ALL_LEAVE_REQUEST,
            variables: {
              filter: {},
              page: 1,
            },
          },
          {
            query: FETCH_SINGLE_LEAVE_REQUEST,
            variables: { leaveId: leaveId },
          },
        ],
      });
      const res = response.data.editLeaveRequest;
      const { message, status } = res;
      status === 200 ? successToast(message) : errorToast('Error occured');
    } catch (error) {
      return error;
    } finally {
      refetch();
      cancel({ showModal: false });
    }
  };

  const username = editData?.employeeId?.firstName || 'floyd';
  return (
    <>
      {
        <Box
          data-testid="edit-leave"
          border="1px solid #F2F2F2"
          borderRadius={'0.4rem'}
          padding="1.6rem 2.4rem"
          minW={'44rem'}>
          <>
            <Calendar date={date} setDate={setDate} isRange toDate={toDate} setToDate={setToDate} />

            <Flex justifyContent={'flex-end'} gap="0.8rem" mt="3.2rem">
              <CustomButton
                onClick={() => cancel({ showModal: false })}
                name="Cancel"
                variant="black--outline"
                mW="9.5rem"
                h="4.3rem"
              />
              <CustomButton
                onClick={editLeave}
                name={employee ? 'Edit Leave Date' : `Notify ${username}`}
                variant="black"
                mW="14.5rem"
                h="4.3rem"
                loading={loading}
              />
            </Flex>
          </>
        </Box>
      }
    </>
  );
};

export { EditLeaveRequest };
