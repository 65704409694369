import React from 'react';
import { useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import appraisalIcon from 'assets/icons/appraisal.svg';
import { FETCH_PERFORMANCE_DASHBOARD } from 'query';

import { EmptyState } from 'components';

import { BaseLayout } from '../Layout';

import { ActiveAppraisal } from './ActiveAppraisal';
import { LastAppraisalReport } from './LastAppraisalReport';
import { PastAppraisalReview } from './PastReview';

export const EmployeePerformance = () => {
  const { data, loading } = useQuery(FETCH_PERFORMANCE_DASHBOARD);

  const dashboardData = data?.fetchAppraisalDashboard?.data;

  const activeReviews = dashboardData?.activeReviews;

  const toNextAppraisal = dashboardData?.daysToNextAppraisal;
  const lastReport = dashboardData?.lastReport;

  return (
    <PerformanceRoot data-testid={'employee-performance'}>
      <BaseLayout className="content">
        <h1 className="heading">Appraisal</h1>
        <div>
          <div className="top__panel">
            <div className="top__panel-left card">
              <p className="custom-text">How is it going</p>
              <Flex className="" alignItems={'center'} justifyContent={'space-between'} mt="3.2rem">
                <img src={appraisalIcon} alt="i" />
                <div>
                  <p className="small-text">{toNextAppraisal} Days left</p>
                  <p className="next">to the next appraisal</p>
                </div>
              </Flex>
            </div>
            {lastReport?.length === 0 && !loading ? (
              <EmptyState message="No Report Available" h="20vh" />
            ) : (
              <LastAppraisalReport report={lastReport} loading={loading} />
            )}
          </div>
          <div className="active__appraisal">
            <ActiveAppraisal review={activeReviews} loading={loading} />
          </div>
          <div className="past__review">
            <PastAppraisalReview />
          </div>
        </div>
      </BaseLayout>
    </PerformanceRoot>
  );
};

const PerformanceRoot = styled.div`
  .custom-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.13265px;
    color: #333758;
  }
  .top__panel {
    display: grid;
    gap: 2.4rem;
    @media (min-width: 1200px) {
      grid-template-columns: 398px auto;
    }
    .small-text {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.117188px;
      color: #85879b;
    }

    .blue {
      color: #3e7eee;
    }

    .grey {
      font-weight: 500;
      color: #83889d;
      text-transform: capitalize;
    }
    .card {
      border: 1px solid #f2f2f2;
      border-radius: 8px;
      padding: 2.4rem;
    }
    &-left {
      width: 398px;

      .next {
        font-weight: 400;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: -0.12px;
        color: #333758;
      }
    }
  }
`;
