import style from '@emotion/styled';

export const DepartmentWrapper = style.div`
  position: relative;
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;
    h1 {
      margin-bottom: 0;
      font-weight: 500; 
      font-size: 2.4rem;
      line-height: 2.4rem;
      color: #83889D;
    }
  }
  
  .departments {
    overflow-x: auto;
    padding-bottom: 2.4rem;
    min-height: 60vh;

   table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0px;
  }

   thead tr {
    background: #fafafa;
   }

   thead th:first-of-type {
    padding-left: 1.6rem;
  }
   
   tbody td:first-of-type  {
    padding-left: 1.6rem;
  }
  tbody td {
    text-transform: capitalize;
  }
 
   thead th {
     border-bottom: none;
     border-top: none;
   }

   thead th:first-of-type {
    border-top-left-radius: none;
    border-bottom-left-radius: none;
    border-left: none;
   }

   thead th:last-child {
    border-top-right-radius: none;
    border-bottom-right-radius: none;
    border-right:none;
   }

  }
`;
