import { Box, Grid } from '@chakra-ui/react';

import { ConfigureJobDetails, ConfigureOtherDetails } from './components';

function JobDetails() {
  return (
    <Grid
      templateColumns={{ base: '1fr', slg: '60% auto', lg: '65rem minmax(auto, 44.4rem)' }}
      gap="2.4rem"
      mt="0.8rem">
      <Box className="left-panel">
        <ConfigureJobDetails />
      </Box>
      <Box className="right-panel">
        <ConfigureOtherDetails />
      </Box>
    </Grid>
  );
}

export { JobDetails };
