import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import close from '../../../../assets/icons/thin_close.svg';
import { Button } from '../../jobs';

const Card = styled.div`
  text-transform: capitalize;

  &:hover {
    opacity: 0.8;
  }

  &.selected {
    opacity: 0.4;
  }

  max-width: 313px;
  padding: 16px 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  align-items: center;
  cursor: pointer;

  &:not(:first-of-type) {
    border-top: 1px solid #f2f2f2;
    margin-top: -1px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.4px;
  }

  .avatar {
    background: rgba(62, 126, 238, 0.1);
    padding: 10px;
    border-radius: 50%;
    color: #3e7eee;
    font-size: 14px;
    line-height: 12px;
    text-transform: uppercase;
  }

  .employee-details {
    img {
      border-radius: 50%;
    }

    img,
    .avatar {
      width: 40px;
      height: 40px;
      display: grid;
      place-content: center;
    }
  }
`;

const EmployeeCard = (props) => {
  const [avatar, setAvatar] = useState();
  const [employee, setEmployee] = useState();

  const AvatarMaker = (fName, lName) => {
    let fL = fName.charAt(0);
    let lL = lName.charAt(0);
    setAvatar(fL + lL);
  };

  const selected = props.selectedEmployeeIds?.includes(props?.employee?._id);

  useEffect(() => {
    AvatarMaker(props.employee?.firstName, props.employee?.lastName);
    setEmployee(props?.employee);
  }, [props?.employee]);

  return (
    <Card
      data-testid={'employeeCard'}
      className={`${selected ? 'selected' : ''}`}
      onClick={props.selected ? null : () => props.handleAdd(employee)}>
      <Flex className="employee-details" gap={'16px'} alignItems={'center'}>
        {employee?.imageUrl ? (
          <img src={employee?.imageUrl} alt="" />
        ) : (
          <span className="avatar">{avatar}</span>
        )}
        {employee?.firstName} {employee?.lastName}
      </Flex>
      {props.selected && (
        <Button
          dataTestId={'handleRemove'}
          img={close}
          onClick={() => props?.handleRemove(employee?._id)}
        />
      )}
    </Card>
  );
};
export { EmployeeCard };
