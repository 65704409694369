import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { NameWithImage, Pagination, Search, TableComponent } from 'components';
import { Status } from 'components/tables/TableConfig';

import { FETCH_USER_QUERIES } from '../../query';
import { columns } from '../data';
import { formatDateLabel } from '../helpers';

const keys = {
  Status: 'status',
};

export function QueryTable({ openModal, page, setPage, setSingleQuery, setQuery, query }) {
  const [tData, setTData] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const { loading, data } = useQuery(FETCH_USER_QUERIES, {
    variables: {
      filter: {
        keyword: query,
      },
      page,
    },
  });

  useEffect(() => {
    const queryData = data?.fetchUserQueries?.data;
    if (queryData !== undefined) {
      setTData(queryData);
    }
  }, [data]);

  const tableData = tData?.map(({ subject, issuer, status, createdAt, _id }) => {
    return {
      id: _id,
      queryTitle: subject,
      issuedBy: <NameWithImage data={issuer} />,
      date: formatDateLabel(createdAt),
      status: Status({ status }) || 'N/A',
    };
  });

  const performAction = (data) => {
    switch (data.action) {
      case 'view':
        setSingleQuery(data.id);
        openModal();
        break;

      default:
        break;
    }
  };

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];

    const sortedData = tData?.map((e) => {
      return {
        ...e,
      };
    });
    if (key) {
      sortedData?.sort((a, b) => {
        return desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
      });
      setTData(sortedData);
    }
  };

  return (
    <QueryTableWrapper>
      <Stack>
        <Search
          placeholder={'Search by name'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </Stack>

      <Stack overflow={'auto'} gap={'16px'}>
        <>
          <Stack overflow={'auto'}>
            <TableComponent
              key={sortBy}
              columns={columns}
              noSort
              include="Status"
              noCheckbox
              loading={loading}
              data={tableData}
              handleMultiSortBy={handleMultiSortBy}
              dropdownActions={{ view: 'View' }}
              actionOnData={performAction}
              empty={tData?.length === 0}
              ids={tData?.map((item) => item._id)}
            />
          </Stack>

          {tableData.length > 0 && (
            <Pagination
              page={page}
              setPage={setPage}
              totalPages={data?.fetchUserQueries?.pageCount || 1}
            />
          )}
        </>
      </Stack>
    </QueryTableWrapper>
  );
}

const QueryTableWrapper = styled.div`
  display: grid;
  grid-template-column: 1fr;
  gap: 24px;
`;
