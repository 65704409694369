import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { DELETE_JOB } from 'query';

import { DeletePromptModal } from 'components';

import arrowleft from '../../../../assets/icons/ArrowDown.svg';
import draft from '../../../../assets/icons/draft 1.svg';
import candidates from '../../../../assets/icons/no-candidates.svg';
import published from '../../../../assets/icons/published.svg';
import { ActionDropdown } from '../action-dropdown';
import { Button } from '../button';
import { ColourCoder } from '../color-coder';

const CardStyle = styled.div`
  position: relative;
  width: 210px;
  padding: 16px;
  background: #ffffff;
  font-weight: 500;
  border-radius: 0px 0px 8px 8px;
  text-transform: capitalize;
  transition: all 0.5s ease; /* back to normal */
  cursor: pointer;

  :hover {
    z-index: 100;
    transition: all 0.4s ease; /* zoom in */
    transform: translate3D(0, -1px, 0) scale(1.03);
  }

  h3 {
    font-family: 'Karla';
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    color: #23273b;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 600;

    h2 {
      font-family: 'Karla';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #83889d;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
  }

  .middle {
    margin: 32px 0;
    h3 {
      margin-bottom: 8px;
    }

    .display-cont {
      display: flex;
      gap: 48px;
      padding: 16px;
      align-items: center;
      height: 96px;
      background: #fafcff;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      text-align: center;

      .empty-field {
        margin: auto;
      }
      .display {
        padding-left: 4px;
        border-left: 1px solid #83889d;
        width: fit-content;
        height: fit-content;
      }

      h3 {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #999fa3;
        text-transform: uppercase;
      }

      span {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #23273b;
      }

      p {
        font-weight: 500;
        font-size: 10px;
        color: #83889d;
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.5px;

    /* Gray text */

    color: #83889d;

    .dot {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #83889d;
    }
  }

  .bottom {
    border-top: 1px solid #f2f2f2;
    margin-top: 16px;
    padding-top: 11px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      display: flex;
      align-items: center;
      gap: 2px;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.5px;
      color: #666e7d;
    }
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

const LinkStyle = {
  fontWeight: 500,
  fontSize: '10px',
  color: '#666E7D',
  flexDirection: 'row-reverse',
};

const ImgStyle = {
  flexDirection: 'row-reverse',
};

const Card = (props) => {
  const navigate = useNavigate();
  const [colorCoder, setColorCoder] = useState();
  const [jobs, setJobs] = useState();
  const [id, setId] = useState('');

  const [deleteJob, { loading: deleting }] = useMutation(DELETE_JOB);
  const { errorToast, successToast } = useToastify();
  const [show, setShow] = useState(false);

  const colors = ['orange', 'green', 'blue'];
  const actions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Delete', value: 'delete' },
  ];
  const handleActionClick = (status, id) => {
    if (status.value === 'edit') {
      handleCardAction(id);
    } else if (status.value === 'delete') {
      setId(id);
      setShow(true);
    }
  };

  const handleCardAction = (jobId) => {
    navigate(`${props.path}/${jobId}`);
  };

  const onDelete = async (id) => {
    try {
      const res = await deleteJob({
        variables: {
          jobId: id,
        },
      });
      const { data } = res;
      if (data && data.deleteJob.status === 200) {
        setShow(false);
        successToast('Job was deleted sucessfully');
        props.refetchJobs();
      } else errorToast(data.deleteJob.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const filterDepartment = (list) => {
    const dataArr = list?.map((item) => {
      return item.department?.name.toLowerCase();
    });

    const dupArr = [...new Set(dataArr)].sort();

    const newArray = dupArr.map((department, i) => {
      if (i <= colors?.length - 1) {
        let arrWithColors = {
          department: department,
          color: colors[i],
        };
        return arrWithColors;
      } else {
        let arrWithColors = {
          department: department,
          color: colors[i % colors?.length],
        };
        return arrWithColors;
      }
    });
    setColorCoder(newArray);
  };

  useEffect(() => {
    setJobs(props.jobs);
  }, [props.jobs]);

  useEffect(() => {
    filterDepartment(jobs);
  }, [jobs]);

  return (
    props.jobs &&
    props.jobs?.map((job, index) => (
      <>
        <CardStyle
          key={index}
          data-testid="handleCard"
          onClick={() => {
            handleCardAction(job?._id);
          }}>
          <ColourCoder colorCoder={colorCoder} department={job.department?.name} />
          <div className="top">
            <div>
              <h2>{job.department?.name}</h2>
              <h3>{job.title}</h3>
            </div>

            <ActionDropdown actions={actions} onClick={handleActionClick} id={job._id} />
          </div>
          <div className="middle">
            <h3>Candidates</h3>
            {job?.applicants?.length > 0 ? (
              <div className="display-cont">
                <div className="display">
                  <h3>Total</h3>
                  <span>{job?.applicants?.length}</span>
                </div>
                <div className="display">
                  <h3>New</h3>
                  <span>{job?.applicants?.length}</span>
                </div>
              </div>
            ) : (
              <div className="display-cont">
                <div className="empty-field">
                  <img src={candidates} />
                  <p>No Candidates</p>
                </div>
              </div>
            )}
          </div>
          <span>
            {job?.city}
            <span className="dot"></span>
            {job?.type}
          </span>
          <div className="bottom">
            <div>
              {job.status === 'draft' ? (
                <p>
                  <img src={draft} />
                  {job.status}
                </p>
              ) : (
                <p>
                  <img src={published} />
                  {job.status}
                </p>
              )}
              <Button
                dataTestId="see-details"
                title={'See Details'}
                style={LinkStyle}
                img={arrowleft}
                imgStyle={ImgStyle}
                onClick={() => handleCardAction(job?._id)}
              />
            </div>
          </div>
        </CardStyle>
        {show && (
          <DeletePromptModal
            show={show}
            cancel={() => setShow(false)}
            deleteItem={() => onDelete(id)}
            loading={deleting}
          />
        )}
      </>
    ))
  );
};

export { Card };
