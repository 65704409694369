import React from 'react';
import { LandingPageLayout } from 'layouts';

import {
  IntegratedFeatures,
  LetHelpYouSetup,
  Section2,
  Section3,
  SimpleModal,
  WhyChooseHrms,
} from 'components';

export const LandingPage = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <LandingPageLayout>
        <Section2 />
        <Section3 />
        <IntegratedFeatures />
        <WhyChooseHrms />
      </LandingPageLayout>

      <SimpleModal showModal={showModal} setShowModal={setShowModal}>
        <LetHelpYouSetup />
      </SimpleModal>
    </>
  );
};
