import styled from '@emotion/styled';

export const addSectionStyle = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: ' 19px',
  color: '#666E7D',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  width: ' 100%',
  height: ' 48px',
  borderTop: '1px solid #F2F2F2',
  borderBottom: '1px solid #F2F2F2',
  borderRadius: '4px',
};

export const fieldStyle = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '14px',
  color: ' #666E7D',
  border: '1px solid #F2F2F2',
  borderRadius: '4px',
  padding: '12px 32px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
};

export const SectionInput = styled.input`
  background: #fafcff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 8px;
  height: 40px;
  &:focus {
    outline: none;
  }
`;
export const OptionsInput = styled.input`
  background: #fafcff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  border-radius: 20px;
  height: 2.8rem;
  cursor: pointer;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  img {
    margin-right: 0.95rem;
  }
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: -0.117188px;
  color: var(--green);
  border-radius: 0.4rem;

  &:disabled: {
    cursor: not-allowed;
  }
`;

export const Root = styled.div`
  border: 1px solid #3e7eee;
  border-radius: 4px;
  padding: 16px 24px;

  .select--answer {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.4px;
    color: #83889d;
    cursor: pointer;
  }

  .delete-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem 0;
    img {
      cursor: pointer;
    }
  }

  .text_area {
    display: grid;
    grid-template-columns: max-content auto 200px;
    gap: 16px;
    align-items: center;

    .add-score {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.117188px;
      color: #3e7eee;
      align-items: center;
      border-left: 2px solid #f2f2f2;
      padding-left: 1.6rem;
      gap: 8px;
      p {
        white-space: nowrap;
      }
      input {
        width: 100%;
      }
    }
  }

  .add__answer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 2px solid #f2f2f2;
    padding-top: 2.4rem;
    margin-top: 2.4rem;
  }

  .options__section {
    .options__wrapper {
      display: flex;
      flex-flow: row wrap;
      padding: 1.6rem 0;
      gap: 0.8rem;

      .added_as_answer {
        position: relative;
        width: max-content;
        z-index: 2;
        background: #fafcff;
        border: 1px solid #36ca8b;
        border-radius: 20px;
        padding: 0 2.4rem 0 0.4rem;
        height: 3.4rem;
        width: 120px;

        .close {
          position: absolute;
          right: 0.6rem;
          z-index: 20;
          top: 1rem;
          cursor: pointer;
        }
      }
      &--item {
        position: relative;
        width: max-content;
        z-index: 2;
        background: #fafcff;
        border: 1px solid #f2f2f2;
        border-radius: 20px;
        padding: 0 2.4rem 0 0.4rem;
        height: 3.4rem;
        width: 120px;

        .close {
          position: absolute;
          right: 0.6rem;
          z-index: 20;
          top: 1rem;
          cursor: pointer;
        }
      }
    }
  }
`;

export const PreviewRoot = styled.div`
  padding: 2.4rem;
  .modal--header {
    padding: 2.4rem 0;
    align-items: center;
  }
  .assessment-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #23273b;
  }
  .assessment__content {
    .assessment__summary {
      background: #fcfcfd;
      border: 1px solid #f0f2f5;
      border-radius: 2px;
      margin-top: 3.4rem;
      overflow-x: auto;

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #4d4d4d;
      }
      &--header {
        display: grid;
        grid: 1fr / repeat(3, minmax(150px, auto));
        border-bottom: 1px solid #dee2e8;
        padding: 3.2rem 4.6rem 1.6rem;
        min-width: 500px;
      }
      &--row {
        min-width: 500px;
        display: grid;
        grid: 1fr / repeat(3, minmax(155px, auto));
        padding: 2rem 4.6rem 1.6rem;
      }
    }

    .assessment__section {
      margin-top: 3.2rem;
      .top {
        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.0012em;
          color: #83889d;
          margin-bottom: 2.4rem;

          span {
            color: #272932;
          }
        }
      }
    }
  }
`;
