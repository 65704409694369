import React from 'react';
import { useWatch } from 'react-hook-form';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { EmployeeOptionsProvider } from 'context';

import { CustomButton } from '../../../components/shared';
import { useEmployeeEditForm } from '../../../context/employeeDetailsForm';
import { useCountriesStatesCities } from '../../../hooks';

import {
  BankDetails,
  ContactInformation,
  EmployementInformation,
  KinInformation,
  MedicalDetails,
  PensionDetails,
  PersonalDetails,
} from './employeeForms';
import { SubmitForm } from './SubmitEditForm';

const Heading = ({ header }) => {
  return (
    <Box borderBottom="1px solid #F2F2F2" mb="3.6rem" pb={'0.8rem'}>
      <Text fontSize="2.4rem" lineHeight={'2.8rem'} fontWeight={'500'} color="#807F7F">
        {header}
      </Text>
    </Box>
  );
};

export const EditEmployeeProfile = ({ refetch, closeEditModal }) => {
  const [page, setPage] = React.useState(0);
  const [countryCode, setCountry] = React.useState({});
  const [currencyCode, setCurrency] = React.useState({});
  const { register, control, handleSubmit, setValue } = useEmployeeEditForm();
  const watchKinCountry = useWatch({ control, name: 'emergencyContact.country' });
  const watchKinState = useWatch({ control, name: 'emergencyContact.state' });
  const watchCountry = useWatch({ control, name: `address.${0}.country` });
  const watchState = useWatch({ control, name: `address.${0}.state` });
  const watchCountryContact = useWatch({ control, name: `address.${1}.country` });
  const watchStateContact = useWatch({ control, name: `address.${1}.state` });

  const { states: contactStates, countries: contactCountries } = useCountriesStatesCities({
    country: watchCountry,
    state: watchState,
    setValue,
    stateName: `address.${0}.state`,
  });

  const { states: secondcontactStates, countries: secondcontactCountries } =
    useCountriesStatesCities({
      country: watchCountryContact,
      state: watchStateContact,
      setValue,
      stateName: `address.${1}.state`,
    });

  const {
    states: kinStates,
    cities: kinCities,
    countries: allCountries,
  } = useCountriesStatesCities({
    country: watchKinCountry,
    state: watchKinState,
    setValue,
    stateName: 'emergencyContact.state',
    cityName: 'emergencyContact.city',
  });

  const {
    submitPersonalDetails,
    submitMedicalDetails,
    submitEmploymentInfo,
    submitPensionDetails,
    submitBankDetails,
    submitContactDetails,
    submitEmergencyContact,
    loading,
  } = SubmitForm({
    closeModal: closeEditModal,
    refetch: refetch,
  });
  const getCountryCode = (e) => {
    setCountry(e);
  };

  const getCurrencyCode = (e) => {
    setCurrency(e);
  };

  const benefits = [
    'Pension',
    'Life Insurance',
    'Health Insurance',
    'Stock',
    'Training and development',
  ];
  return (
    <EditWrapper data-testid={'employee-edit-profile'}>
      {page === 0 && <PageNavigation setPage={setPage} />}

      {page === 1 && (
        <Box>
          <Heading header={'Personal Data'} />
          <PersonalDetails
            isEdit
            register={register}
            control={control}
            countryCode={countryCode}
            getCountryCode={getCountryCode}
          />
          <PaginationButtons
            loading={loading}
            page={page}
            cancel={() => setPage(0)}
            next={() => setPage(page + 1)}
            disabled={page === 7}
            saveChanges={handleSubmit(submitPersonalDetails)}
          />
        </Box>
      )}
      {page === 2 && (
        <Box className="medical__data">
          <MedicalDetails register={register} control={control} />
          <PaginationButtons
            loading={loading}
            page={page}
            cancel={() => setPage(0)}
            next={() => setPage(page + 1)}
            disabled={page === 7}
            saveChanges={handleSubmit(submitMedicalDetails)}
          />
        </Box>
      )}
      {page === 3 && (
        <Box>
          <Heading header={'Employee Data'} />
          <EmployeeOptionsProvider>
            <EmployementInformation
              isEdit
              register={register}
              control={control}
              benefits={benefits}
              getCurrencyCode={getCurrencyCode}
              currencyCode={currencyCode}
            />
          </EmployeeOptionsProvider>
          <PaginationButtons
            loading={loading}
            page={page}
            cancel={() => setPage(0)}
            next={() => setPage(page + 1)}
            disabled={page === 7}
            saveChanges={handleSubmit(submitEmploymentInfo)}
          />
        </Box>
      )}
      {page === 4 && (
        <Box>
          <Heading header={'Pension Data'} />
          <PensionDetails register={register} control={control} />
          <PaginationButtons
            loading={loading}
            page={page}
            cancel={() => setPage(0)}
            next={() => setPage(page + 1)}
            disabled={page === 7}
            saveChanges={handleSubmit(submitPensionDetails)}
          />
        </Box>
      )}
      {page === 5 && (
        <Box className="bank__data">
          <BankDetails register={register} control={control} />
          <PaginationButtons
            loading={loading}
            page={page}
            cancel={() => setPage(0)}
            next={() => setPage(page + 1)}
            disabled={page === 7}
            saveChanges={handleSubmit(submitBankDetails)}
          />
        </Box>
      )}
      {page === 6 && (
        <Box>
          <Heading header={'Contact Data'} />
          <ContactInformation
            register={register}
            control={control}
            contactStates={contactStates}
            countries={contactCountries}
            secondcontactStates={secondcontactStates}
            secondcontactCountries={secondcontactCountries}
          />
          <PaginationButtons
            loading={loading}
            page={page}
            cancel={() => setPage(0)}
            next={() => setPage(page + 1)}
            disabled={page === 7}
            saveChanges={handleSubmit(submitContactDetails)}
          />
        </Box>
      )}
      {page === 7 && (
        <Box className="kin__data">
          <KinInformation
            isEdit
            register={register}
            control={control}
            getCountryCode={getCountryCode}
            countryCode={countryCode}
            allKinCountries={allCountries}
            citiesData={kinCities}
            kinStates={kinStates}
          />
          <PaginationButtons
            loading={loading}
            page={page}
            cancel={() => setPage(0)}
            disabled={page === 7}
            saveChanges={handleSubmit(submitEmergencyContact)}
          />
        </Box>
      )}
    </EditWrapper>
  );
};

const pages = {
  'Personal Data': 1,
  'Medical Details': 2,
  'Employment Information': 3,
  'Pension Details': 4,
  'Bank Details': 5,
  'Contact Information': 6,
  'Next of Kin Information': 7,
};

const PageNavigation = ({ setPage }) => {
  return (
    <>
      <Grid className="menu__wrapper">
        {Object.entries(pages).map(([key, value]) => {
          return (
            <Box data-testid={key} className="menu__items" key={key} onClick={() => setPage(value)}>
              <Text className="item">{key}</Text>
            </Box>
          );
        })}
      </Grid>
    </>
  );
};

const PaginationButtons = ({ cancel, next, saveChanges, disabled, loading }) => {
  return (
    <Flex gap={'2.4rem'} justifyContent={'center'} mt={'4.8rem'}>
      <CustomButton
        handleButtonClick={cancel}
        name={'Cancel'}
        variant={'secondary'}
        h={'4.8rem'}
        mW={'14.8rem'}
      />
      <CustomButton
        handleButtonClick={saveChanges}
        name={'Save Changes'}
        variant={'primary'}
        h={'4.8rem'}
        mW={'20rem'}
        loading={loading}
      />
      <CustomButton
        handleButtonClick={next}
        name={'Next'}
        variant={'tertiary'}
        h={'4.8rem'}
        mW={'14.8rem'}
        disabled={disabled}
      />
    </Flex>
  );
};

const EditWrapper = styled.div`
  padding: 4.8rem 0;

  .menu__wrapper {
    grid-template-columns: repeat(auto-fill, minmax(27.5rem, 1fr));
    grid-row-gap: 2.4rem;
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 5.6rem;
      grid-row-gap: 4.8rem;
    }
  }

  .menu__items {
    width: 27.5rem;
    height: 8.8rem;
    background: #fafcff;
    border: 2px solid #f2f2f2;
    border-radius: 4px;
    display: flex;
    padding: 2.4rem;
    cursor: pointer;
    margin: auto;
    :hover {
      border: 2px solid var(--blue);
    }
    @media (min-width: 768px) {
      margin: 0;
    }
  }

  .item {
    border-left: 3px solid #999fa3;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.01em;
      color: #999fa3;
    }
  }

  .medical__data {
    .header__box {
      p {
        margin-top: 0;
        font-weight: 500;
        line-height: 2.8rem;
      }
    }
  }
  .bank__data {
    .bank__details {
      margin-top: 0;
      p {
        margin-top: 0;
        font-weight: 500;
        line-height: 2.8rem;
      }
    }
  }
  .kin__data {
    .kin__details {
      margin-top: 0;
      p {
        margin-top: 0;
        font-weight: 500;
        line-height: 2.8rem;
      }
    }
  }
`;
