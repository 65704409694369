import React from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';

import { monthsFull } from './helpers';

export function MonthGrid(props) {
  const { tempYear, setYear, setMonth, setMode, date, month: selectedMonth } = props;

  function handleMonthClick(index) {
    setMonth(index);
    setYear(tempYear);
    setMode('day');
  }

  function isActive(month) {
    return (
      tempYear === (date || new Date()).getFullYear() &&
      month === (selectedMonth || (date || new Date()).getMonth())
    );
  }

  return (
    <MonthGridRoot>
      {monthsFull.map((month, index) => (
        <div
          className={cx('month', { active: isActive(index) })}
          key={month}
          onClick={() => handleMonthClick(index)}>
          <div className="month__text">{month}</div>
        </div>
      ))}
    </MonthGridRoot>
  );
}

const MonthGridRoot = styled.div`
  display: grid;
  grid: 1fr / repeat(3, 1fr);
  gap: 8px;
  border: 1px solid #f2f2f2;
  border-radius: 0.8rem;
  padding: 1rem;
  //margin-bottom: 5.4rem;

  .month {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    width: 100%;
    border-radius: 0.4rem;
    padding-top: max(60%, 4rem);

    &:hover {
      background: #f2f2f2;
    }

    &.active {
      background: var(--blue);
      color: white;
    }

    &__text {
      position: absolute;
      inset: 50% 0 0 50%;
      translate: -50% -50%;
      width: max-content;
      height: max-content;
    }
  }
`;
