import React, { useState } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { SimpleModal } from 'components';

import { ViewQuery } from './components/modals/ViewQuery';
import { QueryTable } from './components/QueryTable';

export const Query = () => {
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [singleQuery, setSingleQuery] = useState({});
  const [query, setQuery] = useState('');

  function toggleModal() {
    setShowModal((prev) => !prev);
  }

  return (
    <>
      <ContentWrapper>
        <Flex marginBottom={'24px'}>
          <Heading>QUERY</Heading>
        </Flex>
        <QueryTable
          page={page}
          setPage={setPage}
          setSingleQuery={setSingleQuery}
          openModal={toggleModal}
          setQuery={setQuery}
          query={query}
        />
      </ContentWrapper>

      <SimpleModal showModal={showModal}>
        <ViewQuery closeModal={toggleModal} singleQuery={singleQuery} query={query} />
      </SimpleModal>
    </>
  );
};

const ContentWrapper = styled.div`
  padding: 24px;
  gap: 24px;
`;
