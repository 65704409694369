import { gql } from '@apollo/client';

export const CLOCK_IN = gql`
  mutation clockin {
    clockin {
      status
      message
      data {
        _id
      }
    }
  }
`;
export const CLOCK_OUT = gql`
  mutation clockout {
    clockout {
      status
      message
      data {
        _id
      }
    }
  }
`;
export const FETCH_TIMESHEETS = gql`
  query fetchTimesheets($filters: TimesheetFilters, $page: Int!) {
    fetchTimesheets(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      pageCount
      data {
        _id
        time
        in
        out
        employee {
          _id
          firstName
          lastName
          email
          status
          staffId
          imageUrl
          phoneNumber
          employmentInfo {
            hireDate
          }
          department {
            name
          }
          designation {
            name
          }
        }
        createdAt
      }
    }
  }
`;
