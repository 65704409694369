import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { EmptyState } from 'components';

import arrowDown from '../../../../../assets/icons/arrowDownFilled.svg';

export default function ReviewResponses({ section, comments, name }) {
  const [toggle, setToggle] = useState(false);

  const ArrowIcon = styled.img`
    transform: ${toggle === true ? 'rotate(180deg)' : 'rotate(0deg)'};
  `;

  const scores = section.section[0]?.questions?.map((q) => {
    const choice = q.options?.find((opt) => (opt.isAnswer === true ? opt.value : null));
    return choice.value;
  });

  return (
    <Box paddingBottom="16px">
      <Box border="1px solid #f2f2f2">
        <Flex
          cursor="pointer"
          style={{
            padding: '12px 16px',
            borderBottom: toggle ? '1px solid rgba(0, 0, 0, 0)' : '1px solid var(--primary)',
          }}
          justifyContent="space-between"
          alignItems="center"
          borderRadius="4px 0 0 0"
          border="1px solid var(--primary)"
          onClick={() => setToggle(!toggle)}>
          <p>{section.section[0]?.title}</p>
          <figure>
            <ArrowIcon src={arrowDown} alt="" />
          </figure>
        </Flex>
        {toggle && (
          <div>
            {section.section[0]?.questions?.length < 0 ? (
              <EmptyState message="Question not found" />
            ) : (
              <>
                <ResponseWrapper>
                  <Flex
                    justifyContent="space-between"
                    style={{ padding: '12px 16px', position: 'relative', bottom: '5px' }}
                    borderRadius="4px 0 0 4px"
                    background="#F3F4F6">
                    <QuestionHeaders>Questions</QuestionHeaders>
                    <QuestionHeaders>Score</QuestionHeaders>
                  </Flex>

                  {section.section[0]?.questions?.map((q, index) => (
                    <Flex
                      key={q.question}
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom="1px solid #f2f2f2"
                      style={{ padding: '12px 16px' }}>
                      <p>
                        {index + 1}. {q.question}
                      </p>
                      <PointWrapper>{scores[index]}</PointWrapper>
                    </Flex>
                  ))}
                </ResponseWrapper>
                <div
                  style={{
                    padding: '16px',
                    borderRadius: '8px 0 0 8px',
                    border: '1px solid #f2f2f2',
                  }}>
                  <Box padding="12px 16px">
                    {comments} - <span style={{ color: 'var(--primary)' }}>{name}</span>
                  </Box>
                </div>
              </>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
}

const PointWrapper = styled.div`
  width: 40px;
  height: 28px;
  border-radius: 20px;
  border: 1px solid #999fa3;
  background: rgba(153, 159, 163, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionHeaders = styled.h3`
  font-weight: 700;
`;

const ResponseWrapper = styled.div`
  padding: 0 16px 0 16px;
  border: 1px solid #f2f2f2;
  border-bottom: none;
`;
