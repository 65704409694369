import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { checkApplicantToken } from 'query';

import { SpinnerComponent } from 'components';

import ErroCircle from '../../../assets/icons/Error-circle.png';
import { setItem } from '../../../utils';

import { confirmApplicantToken } from './confirmApplicantToken';
import { Container } from './styles';
import { ApplicantResetForm } from '.';

export const ApplicantResetPassword = () => {
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(false);
  const [validity, setValidity] = useState('');

  const getToken = location?.search?.split('=')?.pop();
  setItem('resetToken', getToken);

  const data = { token: getToken };

  useEffect(() => {
    confirmApplicantToken({ setIsChecking, checkApplicantToken, data, setValidity });
  }, []);

  if (isChecking) {
    return <SpinnerComponent h="80vh" />;
  }
  return (
    <Container data-testid="reset-password">
      <h1>Forgot your password</h1>
      {!validity ? (
        <Box>
          <Flex mt="7.4rem" justify={'center'}>
            <Image src={ErroCircle} />
          </Flex>
          <Text mt="2.6rem" fontSize={'24px'}>
            Reset failed. Your token probably expired.
            <a href={`/applicant/forgot-password`}>
              <Text
                cursor={'pointer'}
                color="#36CA8B"
                fontWeight="600"
                textDecor="underline"
                ml=".5rem"
                as={'span'}>
                Try reset again!
              </Text>
            </a>
          </Text>
        </Box>
      ) : (
        <ApplicantResetForm />
      )}
    </Container>
  );
};
