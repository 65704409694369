import React from 'react';
import { Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, FullModal, InputBox, ModalBackButton, SpinnerComponent } from 'components';

import { useTrainingComponent } from './hooks';
import {
  AttachFiles,
  AttachLink,
  RecurringPeriod,
  SelectEmployee,
  SelectTrainingDetails,
  SetSchedule,
} from './sub-components';

const Wrapper = styled(Grid)`
  .buttonStyle {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
  }
  .header {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
    display: grid;
    grid: 1fr / max-content auto max-content;
    gap: 2.4rem;
    align-items: center;
    background-color: var(--white);

    &-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.05em;
    }
  }

  .view-container {
    width: 100%;
    overflow-x: scroll;
  }

  .heading {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.117188px;
  }

  .btn {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    text-decoration-line: underline;
    color: #3e7eee;
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    text-transform: capitalize;

    svg {
      margin-right: 11.5px;
    }

    span {
      color: #83889d;
      font-style: italic;
    }
  }
`;
const TrainingComponent = ({
  openModal,
  setOpenModal,
  title,
  onCreateTraining,
  loading,
  value,
  buttonName,
  pageLoading,
  individual,
}) => {
  const {
    isDisabled,
    handleChange,
    handleSelectFiles,
    errorMessage,
    setShowField,
    showField,
    handleTrainingDetails,
    handleSchedule,
    handleSelectEmployees,
    handleRecurringPerioid,
    setRepeat,

    state,
    setState,
    openRepeat,
  } = useTrainingComponent(value);

  return (
    <Wrapper>
      <FullModal showModal={openModal} noHeader mt="8.3rem">
        <header className="header">
          <ModalBackButton onClick={() => setOpenModal(false)} />
          <span className="header-text">{title}</span>
          <BaseButton
            disabled={isDisabled}
            onClick={() => onCreateTraining(state)}
            isLoading={loading}
            data-testid="create">
            {buttonName}
          </BaseButton>
        </header>
        {pageLoading ? (
          <SpinnerComponent />
        ) : (
          <Grid
            maxW={'860px'}
            m={'24px auto'}
            templateColumns="1fr"
            gap="24px"
            border={'1px solid #3E7EEE'}
            borderRadius={'4px'}
            padding={'16px 24px'}
            overflow={'auto'}>
            <InputBox
              label={'Name'}
              required={true}
              id={'name'}
              value={state?.name}
              placeholder="E.g Leadership Training"
              handleChange={handleChange}
            />
            <InputBox
              label={'Description'}
              type={'textarea'}
              id={'description'}
              value={state?.description}
              placeholder="E.g Rate team member understanding SOLID principle and how to apply them?"
              handleChange={handleChange}
            />
            <AttachFiles handleSelectFiles={handleSelectFiles} value={state?.attachedFile || {}} />
            <AttachLink
              errorMessage={errorMessage}
              handleChange={handleChange}
              value={state?.attachedLink}
              setShowField={setShowField}
              showField={showField}
              setState={setState}
            />
            <SelectTrainingDetails
              handleTrainingDetails={handleTrainingDetails}
              state={state?.details}
            />
            <SetSchedule handleSchedule={handleSchedule} state={state?.schedule} />
            <RecurringPeriod
              handleRecurringPerioid={handleRecurringPerioid}
              setState={setState}
              setRepeat={setRepeat}
              openRepeat={openRepeat}
              state={state?.repeatProgram}
            />

            {!individual && (
              <SelectEmployee
                handleSelectEmployees={handleSelectEmployees}
                state={state?.employeeId}
                assigned={state?.employees}
              />
            )}
          </Grid>
        )}
      </FullModal>
    </Wrapper>
  );
};

export { TrainingComponent };
