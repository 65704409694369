import React from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_APPLICANT } from 'query';

import { Logo } from 'components/shared/Svgs';
import { Tabs } from 'components/shared/tabs';

import { UserDropdown } from '../dropdown';

import { SideBar } from './SideBar';

const Container = styled(Grid)`
  position: relative;
  gap: 74px;

  .wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;
  }

  .userdropdown {
    grid-template-columns: max-content max-content;
    align-items: center;

    .notification {
      border-right: 1px solid #60657c;
      padding: 10px 30px;

      path {
        stroke: #000000;
      }
    }
  }

  &.dashboard {
    color: #fff;

    .notification {
      path {
        stroke: #fff;
      }
    }

    .wrapper {
      background: linear-gradient(to bottom, #23273b 0%, #23273b 50%, #fff 50%, #fff 100%);
    }

    .navbar {
      background-color: #23273b;

      .menu-list {
        color: #000;
      }

      .arrow {
        stroke: #fff;
      }
    }

    .logo {
      fill: #fff;
    }
    .button-tabs {
      .tab-btn-toggle {
        color: #b6bacc;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding: 0px 9.5px 12px;

        &:hover,
        &.active {
          border-bottom: 1px solid #fff;
          color: #fff;
        }
      }
    }
  }

  .navbar {
    position: sticky;
    top: 0;
    max-width: 1200px;
    margin: 0px auto;
    padding: 35px 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    z-index: 100;
  }

  .sidebar {
    display: none;
  }

  .userdropdown {
    display: block;
  }

  @media only screen and (max-width: 754px) {
    gap: 20px;

    .sidebar {
      display: block;
    }

    .userdropdown {
      display: none;
    }

    .button-tabs {
      display: none;
    }

    .profile {
      p {
        display: none;
      }

      span {
        margin: 0;
      }

      img {
        margin-left: 0;
      }
    }
  }
`;
const tabs = [
  {
    displayName: 'Jobs',
    value: 'appliedJobs',
  },
  {
    displayName: 'My Assessment',
    value: 'assessments',
  },
];

const NavBar = (props) => {
  const [selected, setSelected] = React.useState('appliedJobs');

  const { data } = useQuery(FETCH_APPLICANT, {
    variables: {},
  });

  const user = React.useMemo(() => data?.fetchApplicant?.data, [data]);

  return (
    <Container className={`${props.isDashboard ? 'dashboard' : ''}`}>
      <Box className={'wrapper'}></Box>
      <Flex className="navbar">
        {Logo}
        {props.isDashboard ? (
          <Box className="button-tabs">
            <Tabs tabs={tabs} setSelected={setSelected} selected={selected} />
          </Box>
        ) : null}
        <SideBar
          tabs={tabs}
          setSelected={setSelected}
          selected={selected}
          isDashboard={props.isDashboard}
          user={user}
        />
        <Grid className="userdropdown" gap={'22px'}>
          <UserDropdown dashboard={props.isDashboard} user={user} />
        </Grid>
      </Flex>
      <Outlet />
    </Container>
  );
};

export { NavBar };
