export const confirmApplicantToken = async ({
  setIsChecking,
  checkApplicantToken,
  data,
  setValidity,
}) => {
  setIsChecking(true);
  try {
    const res = await checkApplicantToken(data);
    setValidity(res.message);
    setIsChecking(false);
  } catch (error) {
    setIsChecking(false);
    throw error;
  }
};
