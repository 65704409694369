import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';

import { Button, EmptyState, InputBox } from 'components';

import { sectionImage } from '../images';

import { ConfigureCard } from './ConfigureCard';
import {
  cancelButton,
  ConfigureCards,
  Container,
  Fields,
  Heading,
  inputStyle,
  saveButton,
} from './styles';

const SectionBox = ({
  setSectionData,
  sectionData,
  handleSectionActions,
  isDisabled,
  onSaveSection,
  id,
}) => {
  const { errorToast } = useToastify();
  const { questions, ...rest } = sectionData;

  const handleChange = (e, id) => {
    let { name, value } = e.target;

    if (name === 'question') {
      const newArray = questions?.map((question, index) => {
        if (index === id) {
          return { ...question, [name]: value };
        } else {
          return question;
        }
      });
      setSectionData({ ...rest, questions: newArray });
    } else {
      setSectionData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleOptionActions = (action, option, id, optionId) => {
    const newArray = questions?.map((question, index) => {
      if (index === id) {
        if (action === 'add') {
          const found = question?.options.some(
            (el) => el?.choice?.toLowerCase() === option?.choice?.toLowerCase()
          );
          if (!found) {
            return {
              ...question,
              options: [...question.options, { ...option }],
            };
          } else {
            errorToast('Duplicate Item!');
            return { ...question, options: [...question.options] };
          }
        }
        if (action === 'edit') {
          const found = question?.options.some(
            (el, index) =>
              el?.choice?.toLowerCase() === option?.choice?.toLowerCase() && index !== optionId
          );
          if (!found) {
            const options = question.options.map((opt, index) => {
              if (index === optionId) return { ...option };
              return opt;
            });

            return {
              ...question,
              options: [...options],
            };
          } else {
            errorToast('Duplicate Item!');
            return { ...question, options: [...question.options] };
          }
        }
        if (action === 'delete') {
          return {
            ...question,
            options: [
              ...question.options.filter(
                (opt) => opt?.choice?.toLowerCase() !== option?.choice?.toLowerCase()
              ),
            ],
          };
        }
      } else {
        return question;
      }
    });
    setSectionData({ ...rest, questions: newArray });
  };

  const handleFieldActions = (id, action) => {
    if (action === 'duplicate') {
      questions?.map((question, index) => {
        if (index === id) {
          const copyField = { ...question };
          setSectionData({
            ...rest,
            questions: [
              ...questions,
              {
                ...copyField,
              },
            ],
          });
        } else {
          return question;
        }
      });
    }

    if (action === 'delete') {
      setSectionData({
        ...rest,
        questions: questions?.filter((question, index) => index !== id),
      });
    }
  };

  return (
    <Container>
      <Heading>
        <Flex gap={'16px'} alignItems={'center'}>
          <img src={sectionImage} />
          <InputBox
            dataTestId={'title'}
            inputStyle={{ ...inputStyle, textTransform: 'capitalize' }}
            value={sectionData?.title}
            placeholder={sectionData?.title}
            id={'title'}
            handleChange={handleChange}
          />
        </Flex>
        <Flex gap={'24px'} alignItems={'center'}>
          <Button
            dataTestId={'cancelButton'}
            title={'Cancel'}
            style={cancelButton}
            onClick={() => handleSectionActions('cancel')}
          />
          <Button
            dataTestId={'saveButton'}
            title={'Save'}
            isDisabled={isDisabled}
            style={saveButton}
            onClick={() => onSaveSection(sectionData, id)}
          />
        </Flex>
      </Heading>
      <Fields>
        <InputBox
          dataTestId={'instruction'}
          label={'Instruction'}
          required={true}
          type={'textarea'}
          value={sectionData?.instruction}
          placeholder={
            'Write instructions e.g \nPlease assign each item a score of 1-5 using the scale below. If an item is not applicable, mark as N/A. \n1 - Unsatisfactory 2. Needs Improvement 3. Competent 4. Excellent 5. Outstanding'
          }
          id={'instruction'}
          handleChange={handleChange}
        />
        <ConfigureCards>
          {sectionData?.questions?.length ? (
            sectionData?.questions?.map((question, index) => (
              <ConfigureCard
                key={index}
                handleChange={handleChange}
                question={question}
                id={index}
                handleFieldActions={handleFieldActions}
                handleOptionActions={handleOptionActions}
              />
            ))
          ) : (
            <EmptyState message={'No Questions Found'} />
          )}
        </ConfigureCards>
      </Fields>
    </Container>
  );
};

export { SectionBox };
