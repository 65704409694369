import * as yup from 'yup';

export const personalSchema = yup.object().shape({
  firstName: yup.string().required('firstName is a required'),
  email: yup.string().email().required('email is a required'),
  lastName: yup.string().required('lastName is a required'),
  workLocation: yup.object().nullable(true).required('workLocation is a required'),
  isDisabled: yup.object().nullable(true).required('Medical disability is a required'),
  dob: yup.string().required('employee date of birth is a required'),
});
export const employmentSchema = yup.object().shape({
  department: yup.object().nullable(true).required('department name is a required'),
  designation: yup.object().nullable(true).required('designation name is a required'),
  team: yup.string().required('team is a required'),
  staffId: yup.string().required('staff Id is a required'),
  employmentInfo: yup.object().shape({
    hireDate: yup.string().required('date hired is required'),
    workEmail: yup.string().email('provide a valid email').required('work email is required'),
    // monthlyPayRate: yup.string().required('pay rate is required'),
  }),
});
export const accountSchema = yup.object().shape({
  bankAccount: yup.object().shape({
    pensionFundAdministrator: yup.string().required('pension Administrator fund is required'),
    paymentType: yup.object().nullable(true).required('payment type is required'),
    pensionAccountNo: yup.string().required('pension account number is required'),
  }),
});
export const kinSchema = yup.object().shape({
  emergencyContact: yup.object().shape({
    fullName: yup.string().notRequired('enter Full Name of next of kin'),
  }),
});
export const EmployeeOnboardSchema = yup.object().shape({
  bankAccount: yup.object().shape({
    accountName: yup.string().required('account Name is required'),
    accountNo: yup.string().required('account Number is required'),
    bankName: yup.string().required('bank Name is required'),
    pensionFundAdministrator: yup.string().required('pension Administrator fund is required'),
    paymentType: yup.object().nullable(true).required('payment type is required'),
    accountType: yup.object().nullable(true).required('account type is required'),
    pensionAccountNo: yup.string().required('pension account number is required'),
  }),
  emergencyContact: yup.object().shape({
    fullName: yup.string().required('Full Name of emergencyContact is required'),
    phoneNumber: yup.string().required('phone Number of emergencyContact is required'),
    relationship: yup.string().required('relationship of emergencyContact is required'),
  }),
  department: yup.object().shape({
    value: yup.string().required('department name is required'),
  }),
  designation: yup.object().shape({
    value: yup.string().required('designation name is required'),
  }),
  team: yup.object().shape({
    value: yup.string().required('team name is required'),
  }),
  gender: yup.object().nullable(true).required('gender is required'),
  maritalStatus: yup.object().nullable(true).required('marital status is required'),
  address: yup.object().shape({
    address: yup.string().required('Address is required'),
  }),
  employmentInfo: yup.object().shape({
    hireDate: yup.string().required('date hired is required'),
    employmentType: yup.object().shape({
      value: yup.string().required('Employment type is required'),
    }),
    workEmail: yup
      .string()
      .email('provide a valid email')
      .nullable()
      .required('work email is required'),
  }),
  isDisabled: yup.object().shape({
    value: yup.string().required('Disability is required'),
  }),
});
