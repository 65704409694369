import React from 'react';
import { useForm } from 'react-hook-form';

const EmployeeAppraisalContext = React.createContext();

function EmployeeAppraisalFormProvider(props) {
  const { children, initialValue } = props;
  const init = React.useMemo(() => initialValue || {}, []);
  const formMethods = useForm(init);
  return (
    <EmployeeAppraisalContext.Provider value={formMethods}>
      {children}
    </EmployeeAppraisalContext.Provider>
  );
}

function useEmployeeApparaisalForm() {
  const context = React.useContext(EmployeeAppraisalContext);
  if (!context)
    throw Error('useEmployeeApparaisalForm must be wrapped in EmployeeAppraisalFormProvider');
  return context;
}

export { EmployeeAppraisalFormProvider, useEmployeeApparaisalForm };
