import styled from '@emotion/styled';

export const RichTextEditorWrapper = styled.div`
  border-radius: 8px;
  min-height: 300px;
  background-color: #fafcff;
  border: solid 1px var(--grey-150);
  p {
    padding: 10px 30px;
  }
  ol,
  ul {
    padding: 10px 40px;
  }
`;

export const ViewAnnouncementWrapper = styled.div`
  padding: 24px;

  @media screen and (min-width: 400px) {
    width: 350px;s
  }
  @media screen and (min-width: 450px) {
    width: 400px;
  }

  @media screen and (min-width: 600px) {
    width: 550px;
  }
  @media screen and (min-width: 700px) {
    width: 560px;
  }
  @media screen and (min-width: 800px) {
    width: 700px;
  }
  @media screen and (min-width: 900px) {
    width: 750px;
  }
`;

export const DraftLabel = styled.div`
  background-color: var(--grey-100);
  padding: 4px 20px;
  border: solid 1px var(--grey-700);
  border-radius: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
`;
