import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Flex, Heading, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RETRIEVE_HEAD_COUNT } from 'query';

import { CustomButton, ModalBackButton, TableComponent } from 'components';

import arrowDown from '../../../../assets/images/arrowdown.svg';

import {
  exportCsv,
  getCsv,
  getHeadCountColumns,
  getHeadCountData,
  modifyHeadCountCsv,
} from './helpers';

export function HeadCount() {
  const navigate = useNavigate();
  const { data, loading } = useQuery(RETRIEVE_HEAD_COUNT);

  return (
    <Wrapper overflowX={'auto'}>
      <Flex
        border={'solid 1px var(--grey-150)'}
        alignItems={'center'}
        borderRadius={'4px'}
        gap={'24px'}
        p={'24px'}
        backgroundColor={'var(--white)'}>
        <ModalBackButton
          onClick={() => navigate('/admin/reports')}
          data-testid="modal-back-button"
        />
        <Heading className="header-text">Headcount History </Heading>
      </Flex>
      <Flex justifyContent="flex-end" marginBottom="24px" marginTop="24px">
        <CustomButton
          srcRight={arrowDown}
          name={'Export'}
          mW={'122px'}
          h={'48px'}
          onClick={() => {
            exportCsv(
              getCsv(modifyHeadCountCsv, getHeadCountData(data?.retrieveHeadCount?.data)),
              'headCount'
            );
          }}
        />
      </Flex>

      <Stack overflowX={'auto'}>
        <TableComponent
          loading={loading}
          noCheckbox
          noAction
          noSort
          data={getHeadCountData(data?.retrieveHeadCount?.data)}
          columns={getHeadCountColumns(data?.retrieveHeadCount?.data)}
          empty={getHeadCountData(data?.retrieveHeadCount?.data).length === 0}
        />
      </Stack>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  padding: 24px;
  thead th {
    background: #f3f4f6;
  }
  .th-parent,
  td {
    padding: 16px;
  }

  tr > td:first-of-type {
    color: #23273b;
    font-weight: 500;
  }
`;
