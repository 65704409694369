import React from 'react';
import { useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import { useReviewCycleForm } from '../../../../../../context/reviewCycleForm';
import { RETRIEVE_BULK_EMPLOYEES } from '../../../../../../query';
import { UserImage } from '../../../../employee/tableConfig';

export function useReviewers() {
  const totalPages = 1;
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [tableData, setTableData] = React.useState([]);
  const [searchString, setSearchString] = React.useState('');

  const { control } = useReviewCycleForm();
  const reviewCycle = useWatch({ control, name: 'reviewCycle' }) ?? [];
  const employeeIds = reviewCycle.map((empObj) => empObj.employee);
  const tableIds = tableData.map((e) => e._id);
  const reviewersDict = reviewCycle.reduce((prev, cur) => {
    prev[cur.employee] = cur.reviewers ?? [];
    return prev;
  }, {});

  const filters = { employeeIds };
  const { data, loading, refetch } = useQuery(RETRIEVE_BULK_EMPLOYEES, {
    variables: {
      ...filters,
    },
  });

  const employeeDetailsDict = data?.retrieveBulkEmployees?.data
    ? employeeDataToDict(data?.retrieveBulkEmployees?.data)
    : {};

  const [selectAll, setSelectAll] = React.useState(false);

  const readyTableData = tableData.map(({ _id, firstName, lastName, imageUrl, lineManager }) => {
    const name = `${firstName} ${lastName}`;
    let lineM = lineManager ? getName(lineManager) : 'N/A';
    const reviewersCount = reviewersDict[_id]?.length;
    const additional = reviewersCount && reviewersCount - 1 ? `+${reviewersCount - 1}` : '';
    if (lineM === 'N/A' && reviewersCount) lineM = getName(reviewersDict[_id][0]);

    return {
      col1: UserImage({ name, firstName, lastName, imageUrl }),
      col2: lineM + ' ' + additional,
    };
  });

  const dropdownActions = ['Change reviewer', 'Add reviewer', 'Remove from cycle'];

  /* ---- TABLE ACTIONS ---- */
  const {
    addingReviewerFor,
    setAddingReviewerFor,
    actionOnData,
    handleChangeReviewer,
    currentReviewers,
  } = useReviewerTableActions({ reviewCycle, reviewersDict });

  function handleSearchChange(val) {
    setSearch(val);
  }

  /* ---- EFFECTS ---- */
  function setTableDataAfterRetrieval() {
    const employeesData = data?.retrieveBulkEmployees?.data || [];
    setTableData(employeesData);
  }

  function refetchOnLoad() {
    refetch().then(() => void 0);
  }

  function handleSearch() {
    const employeesData =
      data?.retrieveBulkEmployees?.data.filter((e) => {
        const name = getName(e)?.toLowerCase();
        return search ? name.includes(search.trim().toLowerCase()) : true;
      }) || [];
    setTableData(employeesData);
  }

  React.useEffect(refetchOnLoad, []);
  React.useEffect(setTableDataAfterRetrieval, [data, searchString]);
  React.useEffect(handleSearch, [search]);

  return {
    totalPages,
    page,
    setPage,
    loading,
    search,
    setSearchString,
    selectAll,
    employeeIds,
    tableIds,
    employeeDetailsDict,
    setSelectAll,
    setTableData,
    dropdownActions,
    handleSearchChange,
    actionOnData,
    readyTableData,

    addingReviewerFor,
    setAddingReviewerFor,
    handleChangeReviewer,
    currentReviewers,
  };
}

/**
 * Custom hook to handle table dropdown actions.
 *
 * @param {object} options - object containing reviewCycle
 *                           current state and reviewersDict
 * */
function useReviewerTableActions(options = {}) {
  const { reviewCycle, reviewersDict } = options;

  const [addingReviewerFor, setAddingReviewerFor] = React.useState(null);
  const { setValue } = useReviewCycleForm();
  const currentReviewers = addingReviewerFor ? reviewersDict[addingReviewerFor] : [];

  function actionOnData(payload) {
    switch (payload.action) {
      case 'add-reviewer':
        setAddingReviewerFor(payload.id);
        break;
      case 'change-reviewer':
        setAddingReviewerFor(payload.id);
        break;
      case 'remove-from-cycle':
        handleRemove(payload.id);
        break;
    }
  }

  function handleRemove(employee_id) {
    const updatedCycle = reviewCycle.filter((emp) => emp.employee !== employee_id);
    setValue('reviewCycle', updatedCycle);
  }

  function handleChangeReviewer(_, reviewers) {
    function updateReviewers(employeeObject) {
      if (employeeObject.employee !== addingReviewerFor) return employeeObject;
      else return { ...employeeObject, reviewers: reviewers };
    }

    const reviewCycleCopy = reviewCycle.map(updateReviewers);
    setValue('reviewCycle', reviewCycleCopy);
    setAddingReviewerFor(null);
  }

  return {
    addingReviewerFor,
    setAddingReviewerFor,
    actionOnData,
    handleChangeReviewer,
    currentReviewers,
  };
}

function employeeDataToDict(data) {
  return data.reduce((accum, cur) => {
    accum[cur._id] = cur;
    return accum;
  }, {});
}

function getName(obj) {
  return `${obj.firstName} ${obj.lastName}`;
}
