import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import {
  DeletePromptModal,
  EmptyState,
  Pagination,
  Search,
  SpinnerComponent,
  TableComponent,
} from 'components';

import { EditTraining } from '../edit-training';
import { ViewTrainingDetails } from '../view-training-details/ViewTrainingDetails';

import { useTableView } from './hooks';
import { IndividualColumns, SharedColumns } from './TableCols';

const Wrapper = styled(Flex)`
  gap: 24px;
  flex-direction: column;

  thead th {
    background: #f3f4f6;
  }
  thead th:first-of-type,
  tbody td:first-of-type {
    padding-left: 2.4rem;
  }
`;

export const TableView = ({ selectedButton }) => {
  const {
    search,
    setPage,
    loading,
    setSearchString,
    handleSearchChange,
    tData,
    sortBy,
    information,
    handleMultiSortBy,
    totalPages,
    page,
    openModal,
    setOpenModal,
    dropdownActions,
    showViewTraining,
    id,
    deleting,
    onDelete,
    onEdit,
    refetch,
    actOnData,
    setShowViewTraining,
    show,
    setShow,
  } = useTableView(selectedButton);

  return (
    <Wrapper>
      <Search
        placeholder={'Search name, year,'}
        value={search}
        setPage={setPage}
        setSearchString={setSearchString}
        onChange={(e) => handleSearchChange(e.target.value)}
      />

      {loading ? (
        <SpinnerComponent />
      ) : tData?.length ? (
        <>
          <Box overflowX={'auto'}>
            <TableComponent
              key={sortBy}
              noSort
              noCheckbox
              include={'Status'}
              ids={
                selectedButton === 'shared training'
                  ? tData?.map((e) => e?._id)
                  : tData?.map((e) => e?.trainingId?._id)
              }
              data={information}
              actionOnData={actOnData}
              dropdownActions={dropdownActions}
              columns={selectedButton === 'shared training' ? SharedColumns : IndividualColumns}
              handleMultiSortBy={handleMultiSortBy}
            />
          </Box>
        </>
      ) : (
        <EmptyState message={'No Training Found'} />
      )}
      <Box mt="1rem">
        {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
      </Box>
      {openModal && (
        <EditTraining
          openModal={openModal}
          setOpenModal={setOpenModal}
          id={id}
          individual={selectedButton === 'shared training' ? false : true}
          refetch={refetch}
        />
      )}

      {showViewTraining && (
        <ViewTrainingDetails
          showModal={showViewTraining}
          setShowModal={setShowViewTraining}
          id={id}
          deleting={deleting}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      )}
      {show && (
        <DeletePromptModal
          show={show}
          cancel={() => setShow(false)}
          deleteItem={() => onDelete(id)}
          loading={deleting}
        />
      )}
    </Wrapper>
  );
};
