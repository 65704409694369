import { gql } from '@apollo/client';

export const FETCH_APPROVAL_FLOW = gql`
  query fetchApprovalFlow($filter: ApprovalFlowFilter!) {
    fetchApprovalFlow(filter: $filter) {
      status
      message
      data {
        _id
        name
        leavePolicies
        steps {
          level {
            _id
            rolename
          }
        }
        notifyUsers {
          _id
          firstName
          lastName
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_APPROVAL_FLOW = gql`
  mutation deleteApprovalFlow($_id: ObjectID!) {
    deleteApprovalFlow(_id: $_id) {
      status
      message
      data {
        _id
        name
        leavePolicies
        steps {
          level {
            _id
            rolename
          }
        }
        notifyUsers {
          _id
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const FETCH_ROLES = gql`
  query Query($filters: Rolefilters, $page: Int) {
    fetchRolesFiltered(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      totalCount
      data {
        _id
        rolename
        userCount
        status
        createdAt
        updatedAt
        permissions {
          moduleName
          privileges
        }
      }
    }
  }
`;

export const EDIT_APPROVAL_FLOW = gql`
  mutation editApprovalFlow($data: EditApprovalInputData!) {
    editApprovalFlow(data: $data) {
      status
      message
      data {
        _id
        name
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const FETCH_ALL_POLICIES = gql`
  query fetchAllLeavePolicies {
    fetchAllLeavePolicies {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;

export const ASSIGN_APPROVAL_FLOW = gql`
  mutation assignApprovalFlow($data: AssignApprovalInputData!) {
    assignApprovalFlow(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const CREATE_LEAVE_APPROVAL_FLOW = gql`
  mutation createApprovalFlow($data: ApprovalInputData!) {
    createApprovalFlow(data: $data) {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;

export const FETCH_ALL_ADMINS = gql`
  query fetchAllAdmins($filter: AdminFilter, $page: Int, $limit: Int) {
    fetchAllAdmins(filter: $filter, page: $page, limit: $limit) {
      status
      message
      hasNextPage
      data {
        _id
        firstName
        lastName
        status
        role {
          rolename
        }
        createdAt
        isDefaultAdmin
      }
    }
  }
`;
