import React from 'react';
import { useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import ArrowRight from 'assets/icons/ArrowRight.svg';
import PrivateFolderIcon from 'assets/icons/private-folder-icon.svg';
import SharedFolderIcon from 'assets/icons/shared-folder-icon.svg';
import { FETCH_FILTERED_FOLDERS } from 'query/admin/document/document';

import { SpinnerComponent } from 'components';

export const renderFolders = (
  data,
  path,
  setPath,
  folderId,
  setFolderId,
  setTab,
  type = 'private'
) => {
  return data?.map((item, itemIndex) => (
    <FolderTemplate
      folderarray={item}
      renderFolders={renderFolders}
      key={itemIndex}
      type={type}
      id={item._id}
      isPrivate={item.isPrivate}
      path={path}
      setPath={setPath}
      setTab={setTab}
      folderId={folderId}
      setFolderId={setFolderId}
    />
  ));
};

export const FolderTemplate = ({
  folderarray,
  renderFolders,
  type,
  id,
  isPrivate,
  path,
  setPath,
  setTab,
  folderId,
  setFolderId,
}) => {
  const [selected, setSelected] = React.useState('');
  const [subType, setSubType] = React.useState('');
  let isPrivateStatus;
  if (type === 'private') {
    isPrivateStatus = true;
  } else {
    isPrivateStatus = false;
  }
  const { data, loading } = useQuery(FETCH_FILTERED_FOLDERS, {
    variables: {
      filter: {
        page: 1,
        isPrivate: isPrivateStatus,
        folderId: id,
      },
    },
  });

  const subFolder = data?.fetchFilteredFolders?.data;

  const openFolder = (id, isPrivate) => {
    setSelected(selected === '' ? 'active' : '');
    setPath('active');
    if (isPrivate !== undefined) {
      if (isPrivate === false) {
        setTab('shared');
        setSubType('shared');
      } else {
        setTab('private');
        setSubType('private');
      }
    } else {
      setTab(type);
      setSubType(type);
    }
    setFolderId(id);
  };

  const hasChildren = (folderarray) => {
    return Array.isArray(folderarray.subFolders) && folderarray.subFolders.length > 0;
  };

  return (
    <>
      <Flex
        key={folderarray._id}
        className="folder-structure"
        alignItems={'center'}
        mb="32px"
        onClick={() => openFolder(id, isPrivate)}
        style={{ cursor: 'pointer' }}
        data-testid="folder-list">
        <img
          src={ArrowRight}
          alt="arrow right"
          style={{
            transform: selected === 'active' ? 'rotate(90deg)' : '',
            marginRight: '15px',
          }}
        />
        {type === 'private' ? (
          <img src={PrivateFolderIcon} className="drive-icon" />
        ) : (
          <img src={SharedFolderIcon} className="drive-icon" />
        )}
        <p className="folder--name">
          {folderarray.name} {hasChildren(folderarray)}
        </p>
      </Flex>
      {selected && (
        <div style={{ marginLeft: '20px' }}>
          {loading ? (
            <Grid h={'70vh'} placeItems={'center'}>
              <SpinnerComponent />
            </Grid>
          ) : (
            renderFolders(
              subFolder.subFolders,
              path,
              setPath,
              folderId,
              setFolderId,
              setTab,
              subType
            )
          )}
        </div>
      )}
    </>
  );
};
