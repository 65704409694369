export function getFrom(participants, myID) {
  return participants?.length > 1 ? participants?.find((p) => p._id !== myID) : participants?.[0];
}

export function getLatestMessage(messages) {
  return messages[messages.length - 1];
}

export function getActiveChat(chatList, activeId) {
  return chatList.find((chat) => chat._id === activeId);
}
