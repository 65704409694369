import React from 'react';
import { Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Tabs } from 'components/shared/tabs';

import { ApplicantChangePassword, ProfileDetails } from './sub-pages';

const tabs = [
  {
    displayName: 'My Profile',
    value: 'profile',
  },
  {
    displayName: 'Password',
    value: 'password',
  },
];
const EditJobDetails = () => {
  const [selected, setSelected] = React.useState('profile');

  return (
    <Container>
      <Tabs withLine tabs={tabs} setSelected={setSelected} selected={selected} />
      <View>
        {selected === 'profile' ? <ProfileDetails /> : null}
        {selected === 'password' ? <ApplicantChangePassword /> : null}
      </View>
    </Container>
  );
};

const Container = styled(Grid)`
  max-width: 1240px;
  margin: auto;
  padding: 32px;
  gap: 32px;


    .line{
        height: 2px;
        width: 100%;
    }

   .tab-btn-toggle {
      color: #667085;
      font-family: Karla;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px; /* 125% */ 
      padding: 0px 4px 12px;
      border-bottom: 2px solid transparent;

      &:hover,
      &.active {
       border-bottom: 2px solid #3B74D9;
       color: #3B74D9; 
      }

  &:not(:last-child) {
    margin-right: 16px;
  }
    }
  }
`;

const View = styled(Grid)`
  gap: 3rem;

  .desktop {
    gap: 0.4rem;
  }
  .primary {
    color: var(--base-white, #fff);
    font-size: 14px;
    font-weight: 500;
    padding: 10px 16px;
    border-radius: 8px;
  }

  .outline {
    border-radius: 8px;
    border: 1px solid #d0d5dd !important;
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .text-area,
  .inputType {
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0;
    min-height: 3rem;
    color: var(--gray-900, #101828);
    font-size: 14px;
    font-weight: 500;

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    color: var(--gray-900, #101828);
    font-size: 24px;
    font-weight: 700;
    line-height: 20px; /* 83.333% */
    padding: 0 0 20px;
    border-bottom: 1px solid var(--gray-200, #eaecf0);

    .desc {
      color: var(--gray-600, #475467);
      font-size: 14px;
      font-weight: 500;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }

  .form-buttons {
    border-top: 1px solid #eaecf0;
    padding: 2rem 0;
  }

  .input {
    display: flex;
    border-radius: 8px;
    gap: 8px;
    align-items: center;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--base-white, #fff);
    padding: 10px 14px;
    height: fit-content;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .inputType {
      padding: 0;
      background-color: #fff;
      height: 100%;
      border: none;
    }

    .inputType[type='date']::-webkit-calendar-picker-indicator {
      opacity: 1;
    }

    .input-image {
      position: relative;
      right: 0;
      z-index: 100;
      transform: none;
      top: 0;
    }

    &.error {
      flex-direction: row;
    }
  }

  .settings-form {
    .fields-wrapper {
      display: grid;
      grid-template-columns: 28rem 61.2rem;
      border-bottom: 1px solid #eaecf0;
      padding-bottom: 2rem;

      .input {
        flex-direction: row-reverse;
      }

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }
  }

  input:auto-fill {
    background-color: #fff;
  }

  @media (max-width: 900px) {
    .settings-form {
      .fields-wrapper {
        grid-template-columns: 1fr;
      }
    }

    .heading {
      flex-wrap: wrap;
    }
  }
`;
export { EditJobDetails };
