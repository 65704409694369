import React from 'react';
import styled from '@emotion/styled';

import { CustomButton } from 'components';

import { useHook } from './hooks';

export const WelcomePage = (props) => {
  const { user } = useHook();

  const { skip, completeSetup } = props;

  return (
    <WelcomeWrapper data-testid="welcome-wrapper">
      <div className="welcome-content">
        {user && <h1>{`Welcome ${user?.firstName} ${user?.lastName} `}</h1>}
        <p>
          Let`s finish setting up your account to enjoy full functionalities. Click the below to get
          started.
        </p>
        <div className="btn-wrapper">
          <CustomButton
            name="Set up your Account"
            variant={'primary'}
            h="4.8rem"
            mW="40rem"
            m="auto"
            onClick={completeSetup}
          />
        </div>
        <div className="skip-btn-wrapper">
          <button className="skip-btn" onClick={skip}>
            skip
          </button>
        </div>
      </div>
    </WelcomeWrapper>
  );
};

const WelcomeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;

  height: calc(100vh - 6.2rem);
  .welcome-content {
    max-width: 50rem;
    margin: auto;
    padding: 0 1rem;

    h1 {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.8rem;
      letter-spacing: -0.02em;
      color: var(--black);
      text-align: center;
      margin-bottom: 2.4rem;
      text-transform: capitalize;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      color: var(--black);
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 4.8rem;
    }

    .skip-btn-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      .skip-btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.01em;
        color: var(--black);
        padding: 1rem;
        :hover {
          color: var(--primary);
        }
      }
    }
  }
`;
