import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Flex, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { conditional } from 'utils';

import fuelStationIcon from '../../../../assets/icons/fuel-station.svg';
import locationIcon from '../../../../assets/icons/location.svg';

export const StartAssessment = (props) => {
  const jobDetails = props?.jobDetails;

  const navigate = useNavigate();

  const assessment_submitted = jobDetails?.jobId?.assessmentId?.submitted;

  return (
    <Wrapper key={jobDetails?.jobId?.assessmentId?._id}>
      <main className="mainP">
        <Flex alignItems={'center'} gap={'20px 22px'} wrap={'wrap'}>
          <Flex alignItems={'center'} gap={'8px 8px'} wrap>
            <figure>
              <Avatar
                bgColor={'var(--blue)'}
                color={'var(--white)'}
                name={jobDetails?.jobId?.title}
              />
            </figure>
            <div>
              <h3 className="title">{jobDetails?.jobId?.title}</h3>
              <div className="subtitle">
                <Flex gap={'0px 6px'} alignItems={'center'}>
                  <Image src={locationIcon} height={'14px'} width={'14px'} />
                  <p>{jobDetails?.jobId?.country}</p>
                </Flex>
                <Flex gap={'0px 6px'} alignItems={'center'}>
                  <Image src={fuelStationIcon} height={'14px'} width={'14px'} />

                  <p>
                    {conditional(!jobDetails?.jobId?.location, 'Remote')}{' '}
                    {conditional(jobDetails?.jobId?.location, jobDetails?.jobId?.location)}
                  </p>
                </Flex>
              </div>
            </div>
          </Flex>
          <div className="border"></div>
          <div>
            <h3 className="title">Date Applied</h3>
            <div className="subtitle">
              <Flex gap={'0px 6px'} alignItems={'center'}>
                <p>{jobDetails?.createdAt?.slice(0, 10)?.split('-')?.join('/')}</p>
              </Flex>
            </div>
          </div>
        </Flex>
      </main>
      <Flex
        className="btnWidth"
        alignItems={'center'}
        justifyContent={'center'}
        textTransform={'capitalize'}
        border={!assessment_submitted ? '1px solid #3A71D1' : '1px solid #CDD0D6'}
        background={!assessment_submitted ? 'var(--blue)' : '#E3E6EA'}
        color={!assessment_submitted ? 'var(--white)' : '#232638'}
        cursor={!assessment_submitted ? 'pointer' : 'not-allowed'}
        fontSize={'12px'}
        height={'40px'}
        borderRadius={'6px'}>
        {conditional(assessment_submitted, 'Submitted')}
        {conditional(
          !assessment_submitted,
          <p
            onClick={() =>
              navigate(`/applicant/assessment/${jobDetails?.jobId?.assessmentId?._id}`)
            }>
            Start Assessment
          </p>
        )}
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 11px 29px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 0px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ecf0f5;
  background: var(--white);
  .title {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  .subtitle {
    display: flex;
    gap: 12px;
    color: #6a7585;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
  }
  .mainP {
    display: flex;
    align-items: center;
  }
  .border {
    width: 2px;
    height: 24px;
    background: #e9e9e9;
  }
  .btnWidth {
    width: 120px;
  }
  @media only screen and (max-width: 664px) {
    padding: 19px;
  }
  @media only screen and (max-width: 520px) {
    .btnWidth {
      width: 100%;
    }
  }
`;
