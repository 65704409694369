import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatDistanceToNow } from 'date-fns';

import fuelStationIcon from '../../../assets/icons/fuel-station.svg';
import locationIcon from '../../../assets/icons/location.svg';
import clockIcon from '../../../assets/icons/time.svg';
import { BaseButton, EmptyState, Search, SpinnerComponent } from '../../../components';

import { useJobList } from './hooks';

export function JobList() {
  const [query, setQuery] = React.useState('');
  const [searchString, setSearchString] = React.useState('');
  const { data: jobList, isLoading } = useJobList(searchString);
  const navigate = useNavigate();

  function routeToJob(jobId) {
    navigate(`/jobs/${jobId}`);
  }

  if (isLoading) return <SpinnerComponent h="40rem" />;

  return (
    <Root>
      <Flex
        className="discover__jobs"
        bgColor={'var(--deep_blue)'}
        p={'40px 35px'}
        borderRadius={'12px'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Stack maxW={'590px'}>
          <Text fontSize={'24px'} fontWeight={800} color={'var(--white)'}>
            Discover Your Next Job
          </Text>

          <Text color={'var(--grey-100)'}>
            Find where you fit in at enyata around africa here on the Enyata job board. Search jobs
            matching your skills and apply today
          </Text>
        </Stack>
        <Stack className="discover__jobs--search">
          <Search
            placeholder={'Search Job title, keyword'}
            h={'17px'}
            setSearchString={setSearchString}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            setPage={() => {}}
          />
        </Stack>
      </Flex>

      <Grid gap="16px" w={'100%'}>
        {!jobList.length ? <EmptyState h="20rem" message="No jobs currently available" /> : null}

        {jobList?.map((job) => (
          <Flex
            key={job._id}
            justifyContent="space-between"
            alignItems="center"
            p="24px"
            bgColor={'#FAFCFF'}
            borderRadius={'12px'}
            className="discover__jobs--item">
            <Flex gap={'16px'} alignItems={'center'} className="job__item--desc">
              <Avatar name={job.title} size={'xl'} src={job.headerImage} />
              <Stack className="description" gap={'12px'}>
                <Text fontSize={'24px'} fontWeight={500}>
                  {job.title}
                </Text>
                <Flex gap={'12px'} color={'var(--grey-500)'}>
                  <Flex gap={'6px'}>
                    <img src={locationIcon} alt="" />
                    <Text>{job.country}</Text>
                  </Flex>
                  <Flex gap={'6px'}>
                    <img src={fuelStationIcon} alt="" />
                    <Text>{job.location || 'N/A'}</Text>
                  </Flex>
                  <Flex gap={'6px'}>
                    <img src={clockIcon} alt="" />
                    <Text>{job.type}</Text>
                  </Flex>
                </Flex>
              </Stack>
            </Flex>
            <Stack className="job__item--desc">
              <Text color={'var(--grey-500)'}>
                {' '}
                {formatDistanceToNow(new Date(job.createdAt), { addSuffix: true })}
              </Text>
              <BaseButton onClick={() => routeToJob(job._id)}>View Job</BaseButton>
            </Stack>
          </Flex>
        ))}
      </Grid>
    </Root>
  );
}

const Root = styled(Stack)`
  gap: 32px;
  width: 100%;
  max-width: 128rem;

  @media screen and (min-width: 768px) {
    width: 90%;
    margin: auto;
  }

  .discover__jobs {
    flex-direction: column;
    gap: 24px;

    &--item {
      flex-direction: column;
      gap: 16px;

      .job__item--desc {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .description {
          align-items: center;
        }
      }
    }

    @media screen and (min-width: 650px) {
      &--item {
        flex-direction: row;

        .job__item--desc: first-of-type {
          flex-direction: row;

          .description {
            align-items: flex-start;
          }
        }
      }
    }
    @media screen and (min-width: 860px) {
      flex-direction: row;
    }
  }
`;
