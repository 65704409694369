import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { UserImage } from 'pages/admin/employee/tableConfig';
import { formatDateRange } from 'pages/admin/leave-management/Requests/helper';
import { PEER_REVIEW } from 'query';
import { getItem } from 'utils';

import { ModalBackButton, Pagination, TableComponent } from 'components';
import { Status } from 'components/tables/TableConfig';

import { BaseLayout } from '../Layout';

import { EditCancel } from './component';
const isTesting = process.env.NODE_ENV === 'test';

export const ReviewPage = () => {
  const [page, setPage] = React.useState(1);

  const navigate = useNavigate();
  const location = isTesting
    ? {
        state: {
          reviewType: '093834hjfkf672423s',
        },
      }
    : useLocation();
  const totalPages = 5;
  const user = getItem('hrm_user');

  const { data, loading } = useQuery(PEER_REVIEW);

  const peerReviews = data?.fetchPeerReviews?.data;

  const reviewType = location?.state?.reviewType;

  const tableData = peerReviews
    ?.filter((pr) => pr?.reviewee?._id !== user?._id)
    ?.map(({ _id, reviewee, status, scheduleDate, score }) => {
      const { firstName, lastName, imageUrl } = reviewee;
      const { startDate, endDate } = scheduleDate;
      const name = `${firstName} ${lastName}`;
      const new_status = status?.toLowerCase();
      return {
        col1: UserImage({ name, imageUrl, firstName, lastName }),
        col2: score || 'N/A',
        col3: Status({ status: new_status }) || '',
        col4: formatDateRange({ startDate, endDate }) || '',
        col5: (
          <EditCancel status={new_status} reviewId={_id} userId={reviewee?._id} type={reviewType} />
        ),
      };
    });

  return (
    <BaseLayout className="content" data-testid="employee-review-page">
      <Flex gap="2.4rem" alignItems={'center'}>
        <ModalBackButton
          data-testid="modal-back-btn"
          onClick={() => navigate({ pathname: '/employee/appraisal' })}
        />
        <h1 className="heading">Peer Review</h1>
      </Flex>
      <ContentWrap>
        <p className="text">Review your teammate and colleagues.</p>
        <Box overflowX={'auto'} pb="2.4rem" mt="1.6rem">
          <div className="table-wrap">
            <TableComponent
              noAction
              noCheckbox
              exclude={'Scheduled Date'}
              loading={loading}
              columns={columns}
              data={tableData}
              empty={peerReviews?.length === 0}
            />
            <Box mt="1rem" position="fixed" right={'4rem'} left={'27.2rem'} bottom={'1.2rem'}>
              {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
            </Box>
          </div>
        </Box>
      </ContentWrap>
    </BaseLayout>
  );
};

const ContentWrap = styled.div`
  .text {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 3.2rem;
    color: #333758;
  }

  .table-wrap {
    min-width: 1000px;

    thead th:first-of-type,
    tbody td:first-of-type {
      padding-left: 2.4rem;
    }

    thead th:first-of-type {
      border-top-left-radius: 0rem;
      border-bottom-left-radius: 0rem;
    }
    thead th:last-child {
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
  }
`;

export const columns = [
  {
    Header: 'Reviewee',
    accessor: 'col1',
  },
  {
    Header: 'Score',
    accessor: 'col2',
  },
  {
    Header: 'Status',
    accessor: 'col3',
  },
  {
    Header: 'Scheduled Date',
    accessor: 'col4',
  },
  {
    Header: 'Action',
    accessor: 'col5',
  },
];
