import { Box } from '@chakra-ui/react';

import { ReviewTableComponent } from './ReviewTable/ReviewTable';

export function ReviewsView() {
  return (
    <Box>
      <ReviewTableComponent />
    </Box>
  );
}
