import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RETRIEVE_SINGLE_REVIEW_REPORT } from 'query';

import { ModalBackButton, SpinnerComponent } from 'components';

import RevieweeReport from './RevieweeReport';

export default function RevieweeReportView() {
  const params = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery(RETRIEVE_SINGLE_REVIEW_REPORT, {
    variables: {
      reviewId: params.id,
      employeeId: params.rid,
    },
  });
  const allData = data?.retrieveSingleReviewReport?.data;
  const firstName =
    data?.retrieveSingleReviewReport?.data[0]?.reportData[0]?.report[0]?.reviewee?.firstName;
  const lastName =
    data?.retrieveSingleReviewReport?.data[0]?.reportData[0]?.report[0]?.reviewee?.lastName;

  return (
    <Box backgroundColor="white" m="24px" p="24px" mb={'0px'}>
      <Header>
        <ModalBackButton onClick={() => navigate(-1)} />
        <h1 style={{ marginLeft: '16px' }}>
          Performance review for {firstName} {lastName}
        </h1>
      </Header>

      {loading ? <SpinnerComponent h="440px" /> : <RevieweeReport data={allData} />}
    </Box>
  );
}

const Header = styled.div`
  display: flex;

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 48px;
  }
`;
