import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_ALL_EMPLOYEES } from 'query';

import { Button, EmployeeCard, EmptyState, Search, SpinnerComponent } from 'components';

import close from '../../../../../../assets/icons/thin_close.svg';

const Column = styled.div`
  width: 50%;
  overflow: auto;
  height: calc(90vh - 310px);
`;

const EmployeeCards = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 90%;
  overflow: auto;
`;

const Modal = styled.div`
  width: 930px;
  height: 80%;
  background: #ffffff;
  height: 90%;
  border-radius: 4px;
  padding: 24px;
  overflow-x: scroll;

  .grid {
    width: 100%;
    height: 100%;
    padding: 24px;
    grid-gap: 24px;
  }

  .row {
    background: #ffffff;
    z-index: 1;
    align-items: center;

    .column-head {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.117188px;
      color: #83889d;
    }
  }

  .heading {
    margin: 0 0 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    letter-spacing: -0.117188px;
    color: #23273b;

    img {
      width: 16px;
    }
  }

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  @media only screen and (max-width: 800px) {
    width: 95%;

    .grid {
      width: 930px;
    }
  }
`;

export function SelectParticipant({ state, setState }) {
  const employee = [
    {
      _id: '637f5e3ec4189bfab704ce5d',
      firstName: 'David',
      lastName: 'Roe',
      imageUrl: close,
    },
    {
      _id: '637f5e3ec4189bfab704cd5d',
      firstName: 'Daid',
      lastName: 'Roe',
      imageUrl: null,
    },
    {
      _id: '637f5e3ec4189bfab794ce5d',
      firstName: 'vid',
      lastName: 'Roe',
      imageUrl: null,
    },
  ];
  const filters = { isDefaultAdmin: false };
  const [search, setSearch] = useState();
  const [searchString, setSearchString] = useState();
  const [participants, setParticipants] = useState();
  const [selectedParticipants, setSelectedParticipants] = useState(
    state?.employees?.length > 0 ? state?.employees : []
  );

  const { data, loading } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      page: 1,
      filters: searchString ? { ...filters, searchString: searchString } : { ...filters },
    },
  });

  const participantsData = data?.fetchUsersFiltered?.data;

  const employeesIds = selectedParticipants?.map((emp) => emp._id);

  const isTesting = process.env.NODE_ENV === 'test';

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const handleAdd = (employee) => {
    if (!selectedParticipants?.some((em) => em?._id === employee?._id)) {
      setSelectedParticipants([...selectedParticipants, employee]);
    }
  };

  const handleRemove = (id) => {
    const newList = selectedParticipants.filter((item) => item._id !== id);
    setSelectedParticipants(newList);
  };

  const handleRemoveAll = () => {
    setSelectedParticipants([]);
  };

  useEffect(() => {
    setParticipants(isTesting ? employee : participantsData);
  }, [participantsData, searchString]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      employees: selectedParticipants,
    }));
  }, [selectedParticipants]);

  return (
    <Modal>
      <Grid
        className="grid"
        templateRows={'max-content 1fr max-content'}
        border={'1px solid #F2F2F2'}
        flexDirection={'column'}>
        <Flex flexDirection={'column'}>
          <Flex className="heading" justifyContent={'space-between'}>
            <h1>Select Participants</h1>
          </Flex>
          <Grid className="row" gridTemplateColumns={'1fr 1fr'}>
            <Search
              type={'search'}
              placeholder={'Search name, team'}
              value={search}
              setSearchString={setSearchString}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <Flex justifyContent={'space-between'}>
              <h2 className="column-head">Selected</h2>
              <Button
                dataTestId={'handleRemoveAll'}
                title={'Remove all'}
                style={{
                  color: '#3E7EEE',
                  fontWeight: 400,
                  fontSize: '16px',
                }}
                onClick={handleRemoveAll}
              />
            </Flex>
          </Grid>
        </Flex>
        <Flex className="cards">
          <Column>
            {loading && !isTesting ? (
              <Grid minH="20rem" placeContent="center" mt="20rem">
                <SpinnerComponent data-testid="loading" />
              </Grid>
            ) : (
              <EmployeeCards>
                {participants?.length ? (
                  participants?.map((employee) => (
                    <EmployeeCard
                      employee={employee}
                      key={employee?._id}
                      handleAdd={handleAdd}
                      selectedEmployeeIds={employeesIds}
                    />
                  ))
                ) : (
                  <EmptyState message={'No Participants Found'} />
                )}
              </EmployeeCards>
            )}
          </Column>
          <Column>
            <EmployeeCards>
              <div className="container">
                {selectedParticipants?.length ? (
                  selectedParticipants?.map((employee) => (
                    <EmployeeCard
                      employee={employee}
                      key={employee?._id}
                      handleRemove={handleRemove}
                      selected={true}
                    />
                  ))
                ) : (
                  <EmptyState message={'No Participants Selected'} />
                )}
              </div>
            </EmployeeCards>
          </Column>
        </Flex>
      </Grid>
    </Modal>
  );
}
