import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { UserImage } from 'pages/admin/employee/tableConfig';

import { TableComponent } from 'components';
import { Status } from 'components/tables/TableConfig';

import { overlapColumns } from './data';

const dropdown = {
  view: 'View Details',
};

import { formatDateRange } from './helper';

export const OverlapRequestsTable = ({ data }) => {
  let navigate = useNavigate();

  const tableData = data?.map(
    ({ employeeId, leaveStatus, leaveTypeId, startDate, endDate, imageUrl }) => {
      const { firstName, lastName } = employeeId;
      const name = `${firstName} ${lastName}`;
      return {
        col1: UserImage({ name, imageUrl, firstName, lastName }),
        col2: leaveTypeId?.name || 'N/A',
        col3: formatDateRange({ startDate, endDate }) || '',
        col4: Status({ status: leaveStatus }) || '',
      };
    }
  );

  const viewUser = ({ id }) => {
    navigate({ pathname: `/admin/leave-management/request/${id}` });
  };

  return (
    <OverlapRoot data-testid="overlap-request">
      <h2>Overlapping Requests in Department</h2>
      <div className="table--wrap">
        <TableComponent
          noSort
          include={'Status'}
          noCheckbox
          loading={false}
          columns={overlapColumns}
          data={tableData}
          dropdownActions={dropdown}
          actionOnData={viewUser}
          empty={tableData?.length === 0}
          ids={data?.map((e) => e._id)}
        />
      </div>
    </OverlapRoot>
  );
};

const OverlapRoot = styled.div`
  border: 1px solid var(--grey-150);
  border-radius: 0.4rem;
  padding: 2.4rem;
  width: 100%;
  overflow: hidden;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: -0.02em;
    color: var(--black);
  }

  .table--wrap {
    padding-bottom: 5rem;
    margin-top: 4.8rem;
    overflow-x: auto;
  }

  thead tr {
    background: #f3f4f6;
  }

  thead th:first-of-type {
    padding-left: 1.6rem;
  }

  tbody td:first-of-type {
    padding-left: 1.6rem;
  }

  tbody td {
    text-transform: capitalize;
  }
`;
