import React from 'react';
import { IoClose } from 'react-icons/io5';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import PrivateHardDrive from 'assets/icons/hard-drive-blue.svg';
import SharedHardDrive from 'assets/icons/hard-drive-orange.svg';
import { useToastify } from 'hooks/useToastify';
import {
  ADD_FOLDER,
  FETCH_FILTERED_FOLDERS,
  FETCH_ROOT_FOLDERS,
  UPLOAD_DOCUMENT,
} from 'query/admin/document/document';

import { BaseInput, DropFile, SimpleModal, SpinnerComponent } from 'components';

import { CustomButton } from '../leave-management/leave-settings/components/Buttons';

import { getExtFromLink } from './helper';
import { PrivateDocument } from './MainFolders';
import { renderFolders } from './renderFolderStructure';
import { SingleFolder } from './ViewFolders';

const acceptableFileTypes = {
  'text/csv': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-excel': ['.doc', '.docx'],
  'image/*': ['.jpeg', '.png'],
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/msword': [],
};

export const Documents = () => {
  const [tab, setTab] = React.useState('private');
  const [openModal, setOpenModal] = React.useState(false);
  const [openDocModal, setOpenDocModal] = React.useState(false);
  const [documentName, setDocumentName] = React.useState('');
  const [folderName, setFolderName] = React.useState('');
  const [files, setFiles] = React.useState([]);
  const { errorToast, successToast } = useToastify();
  const [path, setPath] = React.useState('');
  const [folderId, setFolderId] = React.useState(null);
  const { data: sharedDocumentsFolder, refetch: sharedRootRefetch } = useQuery(FETCH_ROOT_FOLDERS, {
    variables: {
      filter: {
        isPrivate: false,
      },
    },
    fetchPolicy: 'cache-first',
  });
  const {
    data: privateDocumentsFolder,
    loading: foldersLoading,
    refetch: privateRootRefetch,
  } = useQuery(FETCH_ROOT_FOLDERS, {
    variables: {
      filter: {
        isPrivate: true,
      },
    },
    fetchPolicy: 'cache-first',
  });

  let isPrivateStatus;
  if (tab === 'private') {
    isPrivateStatus = true;
  } else {
    isPrivateStatus = false;
  }
  const {
    data: singleData,
    loading: singleDataLoading,
    refetch,
  } = useQuery(FETCH_FILTERED_FOLDERS, {
    variables: {
      filter: {
        page: 1,
        isPrivate: isPrivateStatus,
        folderId,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const [uploadDocument, { loading: uploadDocumentLoading }] = useMutation(UPLOAD_DOCUMENT, {});

  const singleFolder = singleData?.fetchFilteredFolders?.data;
  const [addNewFolder, { loading: folderUploadLoading }] = useMutation(ADD_FOLDER, {});
  const privateFolder = privateDocumentsFolder?.fetchRootFolders?.data;
  const sharedFolder = sharedDocumentsFolder?.fetchRootFolders?.data;

  function toggleModal() {
    setOpenModal((prev) => !prev);
    setFolderName('');
  }
  function closeDocModal() {
    setOpenDocModal((prev) => !prev);
    setDocumentName('');
    if (files[0]) {
      handleRemoveFile(files[0].name);
    }
  }
  function toggleDocModal() {
    setOpenDocModal((prev) => !prev);
  }

  const onUploadDocument = async (e) => {
    e.preventDefault();
    try {
      const response = await uploadDocument({
        variables: {
          document: { folderId, documentName, file: files[0] },
        },
      });
      if (response?.data?.uploadDocument?.status === 201) {
        successToast('Document created sucessfully');
        setDocumentName('');
        setFiles([]);
        toggleDocModal();
        refetch();
        privateRootRefetch();
        sharedRootRefetch();
      } else errorToast(response?.data?.uploadDocument?.message);
    } catch (error) {
      const message = error?.response?.data?.message || 'Error Occured';
      errorToast(message);
    }
  };

  const onSubmitFolder = async (e) => {
    e.preventDefault();
    let isPrivateStatus;
    if (tab === 'private') {
      isPrivateStatus = true;
    } else {
      isPrivateStatus = false;
    }
    let mainFolder;
    if (folderId !== null) {
      mainFolder = folderId;
    } else {
      mainFolder = null;
    }
    try {
      const response = await addNewFolder({
        variables: {
          data: {
            name: folderName,
            isPrivate: isPrivateStatus,
            parentFolder: mainFolder,
          },
        },
      });
      if (response?.data?.addNewFolder?.status === 201) {
        successToast('Folder created sucessfully');
        toggleModal();
        refetch();
        privateRootRefetch();
        sharedRootRefetch();
      } else errorToast(response?.data?.addNewFolder?.message);
      setFolderName('');
      // reset();
    } catch (error) {
      const message = error?.response?.data?.message || 'Error Occured';
      errorToast(message);
    }
  };
  const handleRemoveFile = (file) => {
    setFiles((prev) => {
      return prev.filter((f) => f.name !== file);
    });
  };

  React.useEffect(() => {
    refetch();
    privateRootRefetch();
    sharedRootRefetch();
    files[0] ? setDocumentName(files[0].name) : setDocumentName('');
  }, [files]);

  if (singleDataLoading)
    return (
      <Grid h={'70vh'} placeItems={'center'}>
        <SpinnerComponent />
      </Grid>
    );

  return (
    <>
      <DocumentRoot>
        <Heading className="title">Document</Heading>
        <div className="main-view">
          <div className="folders hide__scroll">
            <Heading fontWeight="600" fontSize="24px" lineHeight="33px" color="#23273B" mb={'32px'}>
              Folders
            </Heading>
            <div className="main-tabs">
              <div
                className="documents-tab"
                style={{
                  background: tab === 'private' ? 'rgba(62, 126, 238, 0.1)' : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setTab('private');
                  setPath('');
                }}>
                <img src={PrivateHardDrive} alt="" />
                <h1>Private Documents</h1>
              </div>
              {!foldersLoading &&
                renderFolders(
                  privateFolder,
                  path,
                  setPath,
                  folderId,
                  setFolderId,
                  setTab,
                  'private'
                )}
            </div>
            <div className="main-tabs">
              <div
                className="documents-tab"
                style={{
                  background: tab === 'shared' ? 'rgba(240, 168, 0, 0.1)' : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setTab('shared');
                  setPath('');
                }}>
                <img src={SharedHardDrive} alt="" />
                <h1>Shared Documents</h1>
              </div>
              {!foldersLoading &&
                renderFolders(sharedFolder, path, setPath, folderId, setFolderId, setTab, 'shared')}
            </div>
          </div>
          <div className="tab-section">
            {path === '' && tab === 'private' && (
              <PrivateDocument
                folderType={privateFolder}
                header="Private Documents"
                tabType={tab}
                addFolder={toggleModal}
                setPath={setPath}
                setFolderId={setFolderId}
                tab={tab}
                refetch={refetch}
                privateRootRefetch={privateRootRefetch}
                sharedRootRefetch={sharedRootRefetch}
              />
            )}
            {path === '' && tab === 'shared' && (
              <PrivateDocument
                folderType={sharedFolder}
                header="Shared Documents"
                tabType={tab}
                addFolder={toggleModal}
                setPath={setPath}
                setFolderId={setFolderId}
                tab={tab}
                refetch={refetch}
                privateRootRefetch={privateRootRefetch}
                sharedRootRefetch={sharedRootRefetch}
              />
            )}
            {!singleDataLoading && path === 'active' && (
              <SingleFolder
                folderType={singleFolder}
                tabType={tab}
                addFolder={toggleModal}
                addDocument={toggleDocModal}
                isClicked
                folderName={singleFolder?.name}
                setPath={setPath}
                setFolderId={setFolderId}
                folderId={folderId}
                tab={tab}
                refetch={refetch}
                privateRootRefetch={privateRootRefetch}
                sharedRootRefetch={sharedRootRefetch}
              />
            )}
          </div>
        </div>
        <SimpleModal showModal={openModal}>
          <form onSubmit={onSubmitFolder} style={{ padding: '2.4rem' }}>
            <Stack
              p={'2.4rem'}
              gap={'3.2rem'}
              border={'solid 1px var(--grey-250)'}
              borderRadius={'0.4rem'}
              marginBottom={'2.4rem'}>
              <Text fontSize={'2.4rem'} color={'var(--black)'} data-testid={'modalTitle'}>
                {folderId === null
                  ? `Create a new folder in ${tab === 'private' ? 'Private' : 'Shared'} Documents`
                  : `Create a new folder in ${singleFolder?.name} folder`}
              </Text>
              <Stack>
                <BaseInput
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  label={'Folder Name'}
                  placeholder={'Enter folder name'}
                  isRequired
                />
              </Stack>
            </Stack>
            <Flex justifyContent={'flex-end'} gap={'0.8rem'}>
              <CustomButton
                variant={'light'}
                name={'Cancel'}
                mW={'9.4rem'}
                h={'4rem'}
                onClick={toggleModal}
                testId={'closeModal'}
                type="button"
              />
              <CustomButton
                variant={'dark'}
                name={'Save'}
                mW={'9.4rem'}
                h={'4rem'}
                type="submit"
                testId={'saveBtn'}
                loading={folderUploadLoading}
              />
            </Flex>
          </form>
        </SimpleModal>
        <SimpleModal showModal={openDocModal} width="749px">
          <form onSubmit={onUploadDocument}>
            <div className="doc-modal">
              <h1>
                {folderId === null
                  ? `Add a new Document in ${tab === 'private' ? 'Private' : 'Shared'} folder`
                  : `Add a new Document in ${singleFolder?.name} folder`}
              </h1>
              <BaseInput
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                label="Document Name"
                placeholder="Document Name"
                isRequired
              />
              {files?.length === 0 && <DropFile setFiles={setFiles} accept={acceptableFileTypes} />}
              {files?.map((file) => (
                <FileItem key={file.path}>
                  {getExtFromLink(file.name)}
                  <Text className="file-name">{file.name}</Text>
                  <Flex
                    cursor={'pointer'}
                    data-testid="close-file"
                    p="2px"
                    onClick={() => handleRemoveFile(file.name)}>
                    <IoClose cursor={'pointer'} />
                  </Flex>
                </FileItem>
              ))}
            </div>
            <Flex justifyContent={'flex-end'} gap={'0.8rem'}>
              <CustomButton
                variant={'light'}
                name={'Cancel'}
                mW={'9.4rem'}
                h={'4rem'}
                onClick={closeDocModal}
                testId={'closeModal'}
                type="button"
              />
              <CustomButton
                variant={'dark'}
                name={'Save'}
                mW={'9.4rem'}
                h={'4rem'}
                // onClick={onUploadDocument}
                testId={'nextBtn'}
                loading={uploadDocumentLoading}
                type="submit"
              />
            </Flex>
          </form>
        </SimpleModal>
      </DocumentRoot>
    </>
  );
};

const DocumentRoot = styled.div`
  background: #fafcff;
  padding: 2.4rem;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #23273b;
  }
  .documents-tab {
    display: flex;
    align-items: center;
    background: rgba(62, 126, 238, 0.1);
    border-radius: 4px;
    padding: 12px 24px;
    position: relative;
    left: -24px;
    right: 0;
    margin-bottom: 20px;
    width: calc(100% + 24px);

    h1 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #23273b;
      margin-left: 18px;
    }

    img {
      width: 18px;
      aspect-ratio: 1;
    }
  }

  .tab-section-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 8px;
    -webkit-align-items: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 32px 24px;

    @media (min-width: 1400px) {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding: 32px 24px;
    }
  }

  .main-view {
    display: flex;
    width: 100%;
    gap: 24px;
    height: auto;
    min-height: 650px;
    padding-top: 31px;
    overflow: hidden;
  }

  .folders {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    padding: 32px 0 0 24px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 200px;
    min-width: 200px;

    @media (min-width: 900px) {
      width: 250px;
      min-width: 250px;
    }
    @media (min-width: 1441px) {
      min-width: 350px;
    }
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .main-tabs {
    margin-bottom: 52px;
  }

  .tab-section {
    max-height: 600px;
    width: calc(100% - 48px);
  }

  .private-drive,
  .shared-drive {
    margin-right: 16px;
  }

  .folder-structure {
    padding-left: 24px;
    svg {
      margin-right: 16px;
    }
  }

  form {
    padding: 24px;
  }

  .folder--name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #23273b;
  }

  .doc-modal {
    padding: 24px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    margin-bottom: 24px;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.117188px;
      color: #23273b;
      margin-bottom: 32px;
    }
  }
`;

const FileItem = styled(Flex)`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 1.2rem 1.6rem;
  max-width: 28.5rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;

  .file-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .icon.loading {
    animation: spin 500ms linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .file-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
