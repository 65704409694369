import React from 'react';
import {
  Avatar,
  Box,
  Flex,
  Grid,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import dashboard from '../../../assets/icons/dashboard-black.svg';
import user from '../../../assets/icons/employee-black.svg';
import logoutImage from '../../../assets/icons/red-logout.svg';
import { logout } from '../../../utils';

const Container = styled(Box)`
  .menu {
    grid-template-columns: max-content max-content max-content;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.4px;
  }

  .dropdown-menu {
    align-items: center;
    color: #232638;
    font-size: 14px;
    font-weight: 400;

    .name {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .avatar {
    width: 42px;
    height: 42px;
    background: #3e7eee;
    border-radius: 50%;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -0.13265px;
    text-transform: uppercase;
    color: #fff;
  }

  .menu-item {
    display: flex;
    gap: 8px;
    color: #232638;
    font-size: 16px;
    line-height: 24px; /* 150% */

    &.red {
      color: #fa4a4a;
      font-weight: 500;
    }
  }
  .menu-list {
    display: grid;
    gap: 15px;
    border-radius: 20px;
    border: 1px solid #e6e6e6;
    background: #fff;
    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 16px;
    max-height: 30rem;
    overflow-x: scroll;
    flex-direction: column;
    background-color: #ffffff;
    zindex: 100;
    margin: 20px 0;
  }

  @media only screen and (max-width: 700px) {
  }
`;

const UserDropdown = (props) => {
  const handleLogout = () => {
    logout();
    window.location.replace(`/applicant/login`);
  };

  const goToProfile = () => {
    return props.dashboard
      ? window.location.replace(`/applicant/${props?.user?._id}`)
      : window.location.replace(`/applicant/dashboard`);
  };

  return (
    <Container>
      <Menu>
        <MenuButton>
          <Grid className="menu">
            <Avatar
              size={'lg'}
              className="avatar"
              name={props?.user?.fullName}
              src={props?.user?.imageUrl}
            />
            <p>{props?.user?.fullName}</p>
            {Arrow}
          </Grid>
        </MenuButton>
        <MenuList className="menu-list">
          <Flex className="dropdown-menu" gap={'4px'}>
            <Avatar
              size={'lg'}
              className="avatar"
              name={props?.user?.fullName}
              src={props?.user?.imageUrl}
            />
            <Grid>
              <p className="name">{props?.user?.fullName}</p>
              <p>{props?.user?.email}</p>
            </Grid>
          </Flex>
          {props.dashboard ? (
            <MenuItem className="menu-item" onClick={goToProfile}>
              <Image src={user} />
              Edit Profile
            </MenuItem>
          ) : (
            <MenuItem className="menu-item" onClick={goToProfile}>
              <Image src={dashboard} />
              Go to Dashboard
            </MenuItem>
          )}
          <hr />
          <MenuItem className="menu-item red" data-testid="logout-applicant" onClick={handleLogout}>
            <Image src={logoutImage} />
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Container>
  );
};

const Arrow = (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="arrow"
      d="M1.5 1.25L5 4.75L8.5 1.25"
      stroke="#130F26"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { UserDropdown };
