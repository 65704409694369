import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function useActionOnData(options) {
  const { data } = options;
  const [reviewIdDetails, setReviewIdDetails] = React.useState(null);
  const [reviewCycle, setReviewCycle] = React.useState(null);

  const navigate = useNavigate();

  function actionOnData(payload) {
    const reviewCycle =
      data?.retrieveReviews?.data.find((r) => r._id === payload.id)?.reviewCycle ?? [];
    switch (payload.action) {
      case 'edit-review':
        setReviewIdDetails(payload.id);
        break;
      case 'edit-reviewee':
        setReviewCycle({ id: payload.id, reviewCycle });
        break;
      case 'view-report':
        navigate(`/admin/performance/reviews/${payload.id}`);
        break;
    }
  }

  return {
    actionOnData,
    reviewIdDetails,
    setReviewIdDetails,
    reviewCycle,
    setReviewCycle,
  };
}

export function useActionOnRevieweeData() {
  const navigate = useNavigate();
  const params = useParams();

  function actionOnData(payload) {
    switch (payload.action) {
      case 'view':
        navigate(`/admin/performance/reviews/${params.id}/reviewee/${payload.id}`);
        break;
    }
  }

  return {
    actionOnData,
  };
}
