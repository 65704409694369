import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 2.4rem;
  border-radius: 0.4rem;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
  .simple__modal {
    border-radius: 0.4rem;
  }
`;

export const ModalWrapper = styled.div`
  .simple__modal {
    border-radius: 0.4rem;
  }

  @media screen and (min-width: 600px) {
    .css-1l30idc {
      width: 450px;
    }
  }
  @media screen and (min-width: 800px) {
    .css-1l30idc {
      width: 550px;
    }
  }
  @media screen and (min-width: 900px) {
    .css-1l30idc {
      width: 620px;
    }
  }
  @media screen and (min-width: 1000px) {
    .css-1l30idc {
      width: 700px;
    }
  }
`;
