import React from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_PAST_REVIEWS } from 'query';

import { TableComponent } from 'components';

import { formatStartEndDate } from './ActiveAppraisal';

export const columns = [
  {
    Header: 'Appraisal Name',
    accessor: 'col1',
  },
  {
    Header: 'Appraisal Type',
    accessor: 'col2',
  },
  {
    Header: 'Year',
    accessor: 'col3',
  },
  {
    Header: 'Score',
    accessor: 'col4',
  },
  {
    Header: 'Scheduled Date',
    accessor: 'col5',
  },
];

export const PastAppraisalReview = () => {
  const { data, loading } = useQuery(FETCH_PAST_REVIEWS);

  const pastReviews = data?.fetchPastReviews?.data;

  const tableData = pastReviews?.map(({ title, type, year, scheduleDate, score }) => {
    const { startDate, endDate } = scheduleDate;
    return {
      col1: title || 'N/A',
      col2: type || 'N/A',
      col3: year || '',
      col4: `${score}%` || 'N/A',
      col5: formatStartEndDate({ startDate, endDate }) || '',
    };
  });

  return (
    <Root className="past__appraisal">
      <p className="custom-text">Past Review</p>
      <Box overflowX={'auto'} pb="2.4rem" mt="1.6rem">
        <Box minW="1080px">
          <TableComponent
            noAction
            noCheckbox
            exclude={'Scheduled Date'}
            loading={loading}
            columns={columns}
            data={tableData}
            empty={pastReviews?.length === 0}
            message={'No Review History Found'}
          />
        </Box>
      </Box>
    </Root>
  );
};

const Root = styled.div`
  margin: 2.4rem 0;

  thead th:first-of-type,
  tbody td:first-of-type {
    padding-left: 2.4rem;
    width: 35%;
    text-transform: capitalize;
  }
  thead th,
  tbody td {
    padding: 0 1.6rem;
  }

  thead th:first-of-type {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }
  thead th:last-child {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
  }

  tbody td {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #83889d;
  }
`;
