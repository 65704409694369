import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_DEPARTMENT } from 'query';

const EmployeeOptionsContext = React.createContext();

const spreadActionReducer = (state, action) => ({ ...state, ...action });

function EmployeeOptionsProvider(props) {
  const [options, spread] = React.useReducer(spreadActionReducer, {});
  const [depId, setDepartmentId] = React.useState('');
  const { data: departmentsData } = useQuery(GET_DEPARTMENT, { variables: { page: null } });

  React.useEffect(() => {
    if (departmentsData) {
      const departmentList = departmentsData.fetchCompanyDepartments.data;
      const departmentOptions = departmentList.map(({ _id, name }) => ({
        label: name,
        value: _id,
      }));
      spread({ department: departmentOptions });
    }
  }, [departmentsData]);

  React.useEffect(() => {
    if (depId && departmentsData) {
      const departmentList = departmentsData?.fetchCompanyDepartments?.data;
      const oneDepartment = departmentList.find((dep) => {
        return dep._id === depId;
      });
      const designationOptions = oneDepartment.designation.map(({ _id, name }) => ({
        label: name,
        value: _id,
      }));

      spread({ designation: designationOptions });
    }
  }, [depId, departmentsData]);

  function getOption(name) {
    return options[name] || [];
  }

  return (
    <EmployeeOptionsContext.Provider value={{ options, getOption, setDepartmentId }}>
      {props.children}
    </EmployeeOptionsContext.Provider>
  );
}

function useEmployeeOptions({ department }) {
  const context = React.useContext(EmployeeOptionsContext);
  React.useEffect(() => {
    if (context && department) {
      const { setDepartmentId } = context;
      setDepartmentId(department.value);
    }
  }, [department]);
  if (!context) throw Error('useOptions must be wrapped in EmployeeOptionsProvider');
  return context;
}

export { EmployeeOptionsProvider, useEmployeeOptions };
