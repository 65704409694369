import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import DeleteIcon from 'assets/icons/DeleteBlack.svg';
import EditIcon from 'assets/icons/Edit copy 2.svg';
import { formatDistanceToNow } from 'date-fns';
import { useToastify } from 'hooks/useToastify';
import { UserImage } from 'pages/admin/employee/tableConfig';
import { formatIncomingDate } from 'pages/employee/dashboard/profile/helpers';
import { FETCH_ALL_ADMINS } from 'query';
import { UPDATE_INTERVIEWS } from 'query/admin/candidates';

import { CustomButton, DeletePromptModal } from 'components';

import { ScheduleInterview } from './Modals/ScheduleInterviewModal';

export const RenderInterview = ({
  applicantInfo,
  interviewData,
  onDelete,
  loading,
  refetch,
  show,
  setShow,
}) => {
  const [state, setState] = React.useState(null);
  const [selectedInterviewId, setSelectedInterviewId] = React.useState(null);
  const [selectedInterview, setSelectedInterview] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const { errorToast, successToast } = useToastify();
  const [editInterview, { loading: editInterviewLoading }] = useMutation(UPDATE_INTERVIEWS);
  const { data: fetchAllAdmins } = useQuery(FETCH_ALL_ADMINS, {
    variables: {
      filter: {
        status: 'active',
      },
      page: 1,
    },
  });
  const fetchedAllAdmins = fetchAllAdmins?.fetchAllAdmins?.data;

  const admins = selectedInterview?.interviewers.map((interviewer) => {
    for (let i = 0; i < fetchedAllAdmins.length; i++) {
      if (interviewer?._id === fetchedAllAdmins[i]?._id)
        return {
          label: `${fetchedAllAdmins[i]?.firstName} ${fetchedAllAdmins[i]?.lastName}`,
          value: fetchedAllAdmins[i]?._id,
        };
    }
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  async function onEditInterview(data) {
    const interviewsArea = data?.interviewers?.map((value) => {
      return value.value;
    });
    const dataToSend = {
      ...data,
      interviewers: [...interviewsArea],
      applicationId: applicantInfo?._id,
      type: data.type.label,
    };
    try {
      const response = await editInterview({
        variables: {
          filters: {
            _id: selectedInterviewId,
          },
          data: dataToSend,
        },
      });
      response?.data?.editInterview?.status === 200
        ? successToast(response?.data?.editInterview?.message)
        : errorToast(response?.data?.editInterview?.message);
      setOpenModal(false);
      refetch();
    } catch (error) {
      errorToast(error);
    }
  }

  React.useEffect(() => {
    watch('type')?.value === 'Physical' ? setState('Physical') : setState('Online');
  }, [watch('type')]);

  React.useEffect(() => {
    if (selectedInterview !== null)
      reset({
        interviewers: admins,
        interviewName: selectedInterview?.interviewName,
        type: { label: selectedInterview?.type, value: selectedInterview?.type },
        date: formatIncomingDate(selectedInterview?.date),
        startTime: selectedInterview?.startTime,
        endTime: selectedInterview?.endTime,
        link: selectedInterview?.link,
        additionalNotes: selectedInterview?.additionalNotes,
        address: selectedInterview?.address,
      });
  }, [selectedInterview]);

  function getMeridian(end) {
    return parseInt(end) >= 12 ? 'PM' : 'AM';
  }

  function getDuration(start, end) {
    const newStart = start.split(':');
    const newEnd = end.split(':');
    const newNewStart = parseInt(newStart[0]) * 60 + parseInt(newStart[1]);
    const newNewEnd = parseInt(newEnd[0]) * 60 + parseInt(newEnd[1]);
    const time = newNewEnd - newNewStart;
    const hours =
      Math.floor(time / 60) > 1
        ? Math.floor(time / 60) + ' ' + 'hours'
        : Math.floor(time / 60) + ' ' + 'hour';
    const minutes = ((time / 60) % 1) * 60 + ' ' + 'minutes';
    return ((time / 60) % 1) * 60 > 1
      ? Math.floor(time / 60) >= 1
        ? hours + ' ' + minutes
        : '' + minutes
      : hours;
  }

  function toggleModal() {
    setOpenModal(!openModal);
  }

  return (
    <div>
      {interviewData.map((interviewInfo) => {
        return (
          <Wrapper key={interviewInfo?._id}>
            <Flex justify={'space-between'} alignItems={'center'}>
              <Flex className="date" gap={'12px'}>
                <p>{formatDistanceToNow(new Date(interviewInfo.date), { addSuffix: true })}</p>
                <p>•</p>
                <p>{new Date(interviewInfo.date).toString().split('00')[0]}</p>
              </Flex>
              <Flex className="icons" gap={'12px'}>
                <img
                  src={EditIcon}
                  alt="edit icon"
                  data-testid="edit-icon-btn"
                  style={{ color: '#83889D', cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedInterviewId(interviewInfo?._id);
                    setSelectedInterview(interviewInfo);
                    setOpenModal(true);
                  }}
                />
                <img
                  src={DeleteIcon}
                  alt="delete icon"
                  data-testid="delete-icon-btn"
                  style={{ color: '#83889D', cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedInterviewId(interviewInfo?._id);
                    setShow(true);
                  }}
                />
              </Flex>
            </Flex>
            <Flex justify={'space-between'} className="single-interview" alignItems={'center'}>
              {UserImage({
                imageUrl: applicantInfo?.imageUrl,
                firstName: applicantInfo?.firstName,
                lastName: applicantInfo?.lastName,
              })}
              <div className="sect-1">
                <p className="candidate-name">
                  {applicantInfo?.firstName} {applicantInfo?.lastName}
                </p>
                <p className="candidate-assessment">
                  {interviewInfo.interviewName} ({interviewInfo?.type.split(' ')[0]})
                </p>
              </div>
              <div className="sect-2">
                <p className="interview-time">
                  {interviewInfo.startTime} - {interviewInfo.endTime}{' '}
                  {getMeridian(interviewInfo.endTime)}
                </p>
                <p className="interview-duration">
                  {getDuration(interviewInfo.startTime, interviewInfo.endTime)} interview
                </p>
              </div>
              <div className="sect-3">
                <p className="interview-room">
                  {interviewInfo.address !== '' && interviewInfo.address !== null
                    ? interviewInfo.address
                    : interviewInfo.link}
                </p>
                <p className="scheduled-by">
                  Scheduled by: {interviewInfo?.createdBy?.firstName}{' '}
                  {interviewInfo?.createdBy?.lastName}
                </p>
              </div>
              <CustomButton
                variant={'tertiary'}
                name={'Submit Feedback'}
                mW={'95px'}
                h={'22px'}
                fS={'1rem'}
              />
            </Flex>
          </Wrapper>
        );
      })}
      <DeletePromptModal
        show={show}
        deleteItem={() => {
          onDelete(selectedInterviewId);
        }}
        cancel={() => setShow(false)}
        loading={loading}
      />
      <ScheduleInterview
        register={register}
        control={control}
        id={applicantInfo?._id}
        handleSubmit={handleSubmit(onEditInterview)}
        errors={errors}
        state={state}
        openModal={openModal}
        setOpenModal={setOpenModal}
        loading={editInterviewLoading}
        toggleModal={toggleModal}
      />
    </div>
  );
};

const Wrapper = styled.div`
  padding: 16px;

  .date {
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #83889d;
    }
  }

  .icons {
    img {
      width: 16px;
      height: 16px;
    }
  }

  .single-interview {
    padding: 16px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    border-left: 6px solid #3e7eee;
    margin-top: 4px;

    img {
      width: 40px;
      height: 40px;
    }

    .sect-1 {
      width: 140px;

      .candidate-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #23273b;
        margin-bottom: 8px;
      }

      .candidate-assessment {
        font-weight: 300;
        font-size: 10px;
        line-height: 13px;
        color: #253858;
      }
    }

    .sect-2 {
      width: 140px;

      .interview-time {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #23273b;
        margin-bottom: 8px;
      }

      .interview-duration {
        font-weight: 300;
        font-size: 10px;
        line-height: 13px;
        color: #253858;
      }
    }

    .sect-3 {
      .interview-room {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #23273b;
        margin-bottom: 8px;
      }

      .scheduled-by {
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #253858;
      }
    }
  }
`;
