import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { BaseInput } from 'components';

import { useToastify } from '../../../../../../hooks/useToastify';
import {
  CREATE_LEAVE_APPROVAL_FLOW,
  FETCH_APPROVAL_FLOW,
} from '../../../../../../query/admin/leave-management';
import { CustomButton } from '../Buttons';

import { SimpleModal } from './SimpleModal';

const isTesting = process.env.NODE_ENV === 'test';

export function CreateLeaveApprovalFlow({ closeModal, openEditModal, setApprovalFlowId }) {
  const { successToast, errorToast } = useToastify();
  const [createApprovalFlow, { loading }] = useMutation(CREATE_LEAVE_APPROVAL_FLOW, {
    refetchQueries: [
      {
        query: FETCH_APPROVAL_FLOW,
        variables: {
          filter: {},
        },
      },
    ],
  });
  let defaultApproval = '';
  if (isTesting) {
    defaultApproval = 'Accountant Approval Flow';
  }
  const { register, handleSubmit } = useForm({
    defaultValues: { approvalFlowName: defaultApproval },
  });

  async function onSubmit(data) {
    try {
      const res = await createApprovalFlow({
        variables: {
          data: {
            name: data.approvalFlowName,
          },
        },
      });

      if (res.data.createApprovalFlow.status === 409) {
        errorToast(res.data.createApprovalFlow.message);
      } else {
        successToast(res.data.createApprovalFlow.message);
      }

      if (res) {
        setApprovalFlowId(res.data.createApprovalFlow.data._id);
      }
      closeModal();
      openEditModal();
    } catch (error) {
      errorToast(error);
    }
  }
  return (
    <SimpleModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          p={'2.4rem'}
          gap={'3.2rem'}
          border={'solid 1px var(--grey-250)'}
          borderRadius={'0.4rem'}
          marginBottom={'2.4rem'}>
          <Text fontSize={'2.4rem'} color={'var(--black)'} data-testid={'modalTitle'}>
            {'Create Leave Approval'}
          </Text>
          <Stack>
            <BaseInput
              {...register('approvalFlowName', { required: true })}
              label={'Approval Flow Name'}
              placeholder={'E.g: Manager Flow'}
              isRequired
            />
          </Stack>
        </Stack>

        <Flex justifyContent={'flex-end'} gap={'0.8rem'}>
          <CustomButton
            variant={'light'}
            name={'Cancel'}
            mW={'9.4rem'}
            h={'4rem'}
            type={'button'}
            onClick={closeModal}
            testId={'closeModal'}
          />
          <CustomButton
            variant={'dark'}
            name={'Next'}
            type={'submit'}
            mW={'9.4rem'}
            h={'4rem'}
            testId={'createApprovalBtn'}
            loading={loading}
          />
        </Flex>
      </form>
    </SimpleModal>
  );
}
