import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { BaseDropdown } from 'components';

import { useToastify } from '../../../../../../hooks/useToastify';
import {
  ASSIGN_APPROVAL_FLOW,
  FETCH_APPROVAL_FLOW,
} from '../../../../../../query/admin/leave-management';
import { extractPolicies } from '../../helpers';
import { CustomButton } from '../Buttons';

import { SimpleModal } from './SimpleModal';
export function AssignLeaveApprovalFlow({ closeModal, leavePolicies, approvalFlowId }) {
  const { handleSubmit, control } = useForm({
    defaultValues: {},
  });

  const [assignApprovalFlow, { loading }] = useMutation(ASSIGN_APPROVAL_FLOW, {
    refetchQueries: [
      {
        query: FETCH_APPROVAL_FLOW,
        variables: {
          filter: {},
        },
      },
    ],
  });
  const { successToast, errorToast } = useToastify();

  async function onSubmit(data) {
    try {
      const res = await assignApprovalFlow({
        variables: {
          data: {
            _id: approvalFlowId,
            leaveId: data.leavePolicy.id,
          },
        },
      });

      if (res) {
        closeModal();
        successToast(res.data.assignApprovalFlow.message);
      }
    } catch (error) {
      errorToast(error);
    }
  }

  return (
    <SimpleModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          p={'2.4rem'}
          gap={'3.2rem'}
          border={'solid 1px var(--grey-250)'}
          borderRadius={'0.4rem'}
          marginBottom={'2.4rem'}>
          <Text fontSize={'2.4rem'} color={'var(--black)'} data-testid={'modalTitle'}>
            {'Assign Leave Approval Flow'}
          </Text>
          <Stack>
            <Controller
              defaultValue=""
              control={control}
              name={`leavePolicy`}
              render={({ field }) => (
                <BaseDropdown
                  label={'Select Leave Policies that should use AUTOMATIC Flow'}
                  {...field}
                  placeholder={'Manager'}
                  options={extractPolicies(leavePolicies.fetchAllLeavePolicies.data)}
                  w={'100%'}
                />
              )}
            />
          </Stack>
        </Stack>
        <Flex justifyContent={'flex-end'} gap={'0.8rem'}>
          <CustomButton
            variant={'light'}
            name={'Cancel'}
            mW={'9.4rem'}
            h={'4rem'}
            type={'button'}
            onClick={closeModal}
            testId={'closeModal'}
          />
          <CustomButton
            variant={'dark'}
            name={'Assign'}
            mW={'9.4rem'}
            h={'4rem'}
            type={'submit'}
            loading={loading}
            testId={'assignApprovalBtn'}
          />
        </Flex>
      </form>
    </SimpleModal>
  );
}
