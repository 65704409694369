import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { accountType, paymentType } from 'data/dropDownOptions';

import { BaseDropdown, BaseInput, CustomButton } from 'components';

import { HeadingText } from '../onboard-components/Heading';

export const Account = ({ register, control, handleSubmit, loading }) => {
  return (
    <AccountRoot data-testid="account">
      <Grid className="bank-details-grid-wrap">
        <GridItem>
          <Box>
            <HeadingText headingText="Bank Details" mb="10px" />
          </Box>
        </GridItem>
        <GridItem>
          <Grid className="bank-details-form-grid-wrap">
            <GridItem>
              <BaseInput
                {...register('bankAccount.accountName')}
                label="Bank Account Name"
                placeholder="Account Holder's Name"
                fontSize="1.6rem"
                isRequired
              />
            </GridItem>
            <GridItem>
              <Controller
                defaultValue=""
                name="bankAccount.accountType"
                control={control}
                render={({ field }) => (
                  <BaseDropdown
                    {...field}
                    placeholder=""
                    dataTestId="account-type"
                    options={accountType}
                    w="100%"
                    fontSize="1.6rem"
                    color="black"
                    label="Account Type"
                    isRequired
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <BaseInput
                {...register('bankAccount.accountNo')}
                label="Bank Account Number"
                placeholder="Bank Account Number"
                fontSize="1.6rem"
                isRequired
              />
            </GridItem>
            <GridItem>
              <BaseInput
                {...register('bankAccount.bankName')}
                label="Bank Name"
                placeholder="Name of Bank"
                fontSize="1.6rem"
                color="black"
                isRequired
              />
            </GridItem>
            <GridItem mb="2.4rem">
              <Controller
                defaultValue=""
                name="bankAccount.paymentType"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <BaseDropdown
                    {...field}
                    placeholder=""
                    options={paymentType}
                    w="100%"
                    fontSize="1.6rem"
                    color="black"
                    label="Payment Type"
                    isRequired
                  />
                )}
              />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <Grid className="pension-details-grid-wrap">
        <GridItem>
          <Box>
            <HeadingText headingText="Pension Account Details" mb="10px" />
          </Box>
        </GridItem>
        <GridItem>
          <AccountFormRoot>
            <Grid className="pension-details-form-grid-wrap">
              <GridItem>
                <BaseInput
                  {...register('bankAccount.pensionAccountNo')}
                  label="Pension Number"
                  placeholder=""
                  fontSize="1.6rem"
                  color="black"
                />
              </GridItem>
              <GridItem mb="2.4rem">
                <BaseInput
                  {...register('bankAccount.pensionFundAdministrator')}
                  label="Administrative Body"
                  placeholder=""
                  fontSize="1.6rem"
                />
              </GridItem>
            </Grid>
            <CustomButton
              name="Submit"
              variant="primary"
              mW="131px"
              onClick={handleSubmit}
              type="submit"
              loading={loading}
              data-testid="account-btn"
            />
          </AccountFormRoot>
        </GridItem>
      </Grid>
    </AccountRoot>
  );
};

const AccountRoot = styled.div`
  padding: 40px 0 100px 16px;
  .inputType {
    border: none;
  }
  input:disabled {
    color: black;
  }
  .css-1iyp8s0 {
    position: absolute;
    right: 0;
    margin-top: 48px;
    padding: 12px 48px;
  }

  .css-4302v8 {
    margin-top: 8px;
  }

  .css-b62m3t-container,
  .react-select--is-disabled {
    margin-top: 8px;
  }

  .css-18v3ng0-control {
    border: none;
  }

  .bank-details-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-bottom: 40px;
    ${
      '' /* @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    } */
    }
    @media (min-width: 1200px) {
      gap: 105px;
      grid-template-columns: 218px 500px;
    }
  }
  .bank-details-form-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
    @media (min-width: 1200px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
  }
  .pension-details-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    ${
      '' /* @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    } */
    }
    @media (min-width: 1200px) {
      gap: 105px;
      grid-template-columns: 218px 500px;
    }
  }
  .pension-details-form-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
    @media (min-width: 1200px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
  }
`;

const AccountFormRoot = styled.div`
  position: relative;
`;
