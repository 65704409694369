import React from 'react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import Clipboard from 'utils/Clipboard';

import { Button } from 'components';

import writing from '../../../../../assets/icons/writing 1.svg';
import jobImage from '../../../../../assets/images/jobImage.jpg';
import { useJobDetailsForm } from '../../../../../context';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 445px;
  }

  .image1 {
    width: 105px;
    margin: 0 auto;
  }

  .job-image {
    width: 300px;
    max-width: 100%;
    height: 120px;
    object-fit: cover;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #23273b;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    color: #23273b;
    margin: 16px 0 0;
  }

  .jobDetails {
    width: fit-content;
    margin: 0 auto;

    .jobTitle {
      font-weight: 600;
      font-size: 14px;
      color: #23273b;
      margin: 16px 0 0;
    }

    .add-details {
      display: flex;
      gap: 12px;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      color: #83889d;
      line-height: 16px;
      margin: 9px 0 0;

      .dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #83889d;
      }
    }

    .buttons {
      display: flex;
      gap: 24px;
      margin: 24px 0 0;
    }
  }
`;

const JobCreatedMessage = () => {
  const { watch } = useJobDetailsForm();
  const { successToast } = useToastify();

  const title = watch('title');
  const type = watch('type');
  const city = watch('city');
  const country = watch('country');
  const department = watch('department');
  const headerImage = watch('headerImage');
  const jobId = watch('id');
  const departmentName = getOptionValue(department, 'label');
  const typeDisplay = getOptionValue(type);
  const cityDisplay = getOptionValue(city);
  const countryDisplay = getOptionValue(country);

  const jobLink = `${window.location.origin}/jobs/${jobId}`;

  function copy(text) {
    Clipboard.copy(text);
    successToast('Copied to clipboard');
  }
  return (
    <Container>
      <div className="content">
        <img src={writing} alt="" className="image1" />
        <div>
          <h1 className="title">Job Successfully Created</h1>
          <p className="description">
            {title} has been successfully created. You can view it on your career site and don’t
            forget to share it!
          </p>
        </div>
        <div className="jobDetails">
          <img src={headerImage || jobImage} alt="" className="job-image" />
          <h2 className="jobTitle">{title}</h2>
          <span className="add-details">
            {departmentName}
            <span className="dot"></span>
            {cityDisplay || countryDisplay}
            <span className="dot"></span>
            {typeDisplay}
          </span>
          <div className="buttons">
            <Button
              style={{
                color: '#3E7EEE',
                border: '1px solid #3E7EEE',
                padding: '12px 24px',
                borderRadius: '4px',
              }}
              title={'View on Career Site'}
              onClick={() => {
                window.open('/jobs', '_blank');
              }}
            />
            <Button
              title={'Copy Link'}
              style={{
                color: '#fff',
                background: ' #3E7EEE',
                padding: '12px 24px',
                borderRadius: '4px',
              }}
              onClick={() => copy(jobLink)}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

function getOptionValue(val, field = 'value') {
  if (val?.[field]) return val[field];
  return val;
}

export { JobCreatedMessage };
