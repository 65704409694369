import React from 'react';
import styled from '@emotion/styled';

const Container = styled.label`
  position: relative;
  display: flex; 
  align-items: center;
  cursor: pointer;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  user-select: none;
}

/* Hide default checkbox */
input[type='checkbox'] {
  appearance: none
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #83889D;
  border-radius: 4px;
}

/* When the checkbox is checked, add background */
input:checked ~ .checkmark {
  background-color: #83889D;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
 content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='8' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.738 0.352336C14.0957 -0.0684719 14.7268 -0.119641 15.1476 0.238045C15.5684 0.595731 15.6196 1.22682 15.2619 1.64763L6.76192 11.6476C6.39862 12.075 5.75488 12.1201 5.33562 11.7474L0.835616 7.74739C0.422833 7.38048 0.385653 6.7484 0.75257 6.33562C1.11949 5.92284 1.75156 5.88566 2.16434 6.25257L5.90019 9.57333L13.738 0.352336Z' fill='white'/%3e%3c/svg%3e");
 display: block;
  
}
`;

const Checkbox = ({ text, ...props }) => {
  return (
    <Container style={props.style}>
      <input
        type="checkbox"
        {...props}
        onChange={props.onChange}
        id={props.id}
        // checked={props.check ? true : false}
      />
      <span className="checkmark"></span>
      {text}
    </Container>
  );
};

export { Checkbox };
