import { Route, Routes } from 'react-router-dom';
import { LandingPage } from 'pages/landing-page/LandingPage';

import {
  AdminLogin,
  EmployeeLogin,
  EmployeeReset,
  EmployeeSetNewPassword,
  VerifyLineManager,
} from '../pages';

import { AdminRoutes } from './AdminRoutes';
import { ApplicantRoutes } from './ApplicantDashboard';
import { EmployeeRoutes } from './EmployeeRoutes';
import { JobRoutes } from './JobRoutes';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<AdminLogin />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="/applicant/*" element={<ApplicantRoutes />} />
      {/* employee routes */}
      <Route path="/employee/login" element={<EmployeeLogin />} />
      <Route path="/reset" element={<EmployeeReset />} />
      <Route path="/setpassword" element={<EmployeeSetNewPassword />} />
      <Route path="/employee/*" element={<EmployeeRoutes />} />
      <Route path="/jobs/*" element={<JobRoutes />} />
      <Route path="/verify-line-manager/:token" element={<VerifyLineManager />} />
    </Routes>
  );
}

export { AppRoutes };
