import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import Calendar from 'assets/icons/dateCalendar.svg';
import { useToastify } from 'hooks/useToastify';
import { FilesPreview } from 'pages/admin/assessment/component/filesDisplay';
import { FETCH_COMPLETED_TRAINING, FETCH_SINGLE_TRAINING, RECORD_COMPLETION } from 'query';

import { BaseInput, CustomButton, CustomRadio, SpinnerComponent } from 'components';

import { recordSchema } from '../data';

import { AttachFile } from './file';

const isTesting = process.env.NODE_ENV === 'test';

const defaultValues = isTesting
  ? {
      results: 'Pass',
      attachedLink: 'http://somelink',
      note: 'some note to do something',
      cost: 500,
      completionDate: '02-02-2023',
    }
  : {};

const RecordPrompt = ({ cancel, id, completed }) => {
  const [attachedFile, setFiles] = React.useState([]);

  const { data, loading, refetch } = useQuery(FETCH_SINGLE_TRAINING, {
    variables: {
      filter: {
        trainingId: id,
      },
    },
  });
  const single = data?.fetchSingleTraining?.data;

  React.useEffect(() => {
    reset(single);
    refetch();
  }, [data]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: completed ? { ...single } : { ...defaultValues },
    resolver: yupResolver(recordSchema),
  });

  const filterFiles = (filename) => {
    setFiles((prev) => prev?.filter((file) => file?.name !== filename));
  };

  const { refetch: refetchCompleted } = useQuery(FETCH_COMPLETED_TRAINING);

  const { errorToast, successToast } = useToastify();

  const [recordCompletion, { loading: recording }] = useMutation(RECORD_COMPLETION);
  const onSubmit = async (data) => {
    const attachedLink = data?.attachedLink !== '' ? data?.attachedLink : null;

    const toSend = {
      result: data.result,
      completionDate: data.completionDate,
      cost: Number(data?.cost),
      note: data.note,
      attachedLink: attachedLink,
      trainingId: id,
    };

    const variables = { data: toSend };

    try {
      const res = await recordCompletion({
        variables:
          attachedFile?.length !== 0 ? { ...variables, file: attachedFile[0] } : { ...variables },
      });

      if (res?.data?.addLearningResponse?.status === 201) {
        const msg = res?.data?.addLearningResponse?.message;
        successToast(msg);
        cancel();
      }
    } catch (error) {
      errorToast('Error Occurred');
    } finally {
      refetchCompleted();
      cancel();
    }
  };

  return (
    <RecordRoot data-testid="Record-prompt" onSubmit={handleSubmit(onSubmit)}>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <>
          <div className="content">
            <h2>Record Completion</h2>
            <BaseInput
              label="Completion Date"
              type="date"
              isRequired
              rightIcon={Calendar}
              fontW="600"
              labelSize="12px"
              {...register('completionDate', { required: true })}
            />
            <ErrorMessage>{errors?.completionDate?.message || null}</ErrorMessage>

            <Box mt="2.4rem">
              <p className="text-12">Results</p>
              <Grid>
                <CustomRadio
                  options={[
                    { label: 'Pass', value: 'pass' },
                    { label: 'Fail', value: 'fail' },
                  ]}
                  showLabel={true}
                  {...register('results')}
                />
                <ErrorMessage>{errors?.results?.message || null}</ErrorMessage>
              </Grid>
            </Box>

            <Grid gap="0.8rem">
              <AttachFile type="file" setFiles={setFiles} />
              {attachedFile.length ? (
                <FilesPreview files={attachedFile} addclose={true} removeFile={filterFiles} />
              ) : null}
              <AttachFile type="link" register={register} />
            </Grid>

            <Box mt="1.6rem">
              <BaseInput
                label="Cost (NGN)"
                type="text"
                fontW="600"
                labelSize="12px"
                placeholder="50,000"
                {...register('cost')}
              />
              <ErrorMessage>{errors?.cost?.message || null}</ErrorMessage>
            </Box>

            <BaseInput
              label="Note"
              type="textarea"
              fontW="600"
              rows={3}
              labelSize="12px"
              placeholder="Add a note"
              {...register('note')}
            />
            <ErrorMessage>{errors?.note?.message || null}</ErrorMessage>
          </div>

          <Flex justifyContent={'flex-end'} gap="0.8rem" mt="6.4rem">
            <CustomButton
              onClick={cancel}
              name="Cancel"
              variant="black--outline"
              mW="9.5rem"
              h="4.3rem"
              type="button"
            />
            <CustomButton
              loading={recording}
              type="submit"
              name="Save"
              variant="black"
              mW="14.5rem"
              h="4.3rem"
            />
          </Flex>
        </>
      )}
    </RecordRoot>
  );
};

const ErrorMessage = styled.p`
  color: var(--error);
  font-size: 1.2rem;
  text-transform: capitalize;
`;

const RecordRoot = styled.form`
  .content {
    width: 100%;
    max-width: 100%;
    padding: 1.6rem 2.4rem;
    border: 1px solid var(--grey-150);
    border-radius: 0.4rem;
  }

  h2 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.117188px;
    color: var(--black);
    margin-bottom: 1.6rem;
  }

  .text-12 {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.117188px;
    color: #333758;
  }
`;

export { RecordPrompt };
