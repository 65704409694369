import React from 'react';
import { Flex, Grid, Heading, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton } from 'components';

const Container = styled(Grid)`
  padding: 18px 36px 18px 25px;
  border-radius: 8px;
  background: #fafcff;
  gap: 11px;

  .heading {
    color: var(--Black---HRMs, #333758);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  .label {
    color: #999fa3;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
  }

  .line {
    height: 100%;
    width: 1px;
    background-color: #e3e6eb;
  }

  .value {
    color: #242424;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
`;
const Summary = ({ onSubmit, loading, evauations, isCompleted }) => {
  return (
    <Container>
      <Heading className={'heading'}>Summary</Heading>
      <Flex gap={'24px'} justifyContent={'space-between'}>
        {evauations.map((evaluation) => (
          <Grid key={evaluation.name} gap={'4px'}>
            <Text className="label">{evaluation.name}</Text>
            <Text className="value">{evaluation.score + '%' || 'N/A'}</Text>
          </Grid>
        ))}
        {!isCompleted ? (
          <Flex gap={'24px'}>
            <hr className="line" />
            <BaseButton isLoading={loading} onClick={onSubmit} type={'submit'}>
              Save
            </BaseButton>
          </Flex>
        ) : null}
      </Flex>
    </Container>
  );
};

export { Summary };
