import React from 'react';
import { Controller } from 'react-hook-form';
import { Flex, FormControl, FormLabel, GridItem, Input, SimpleGrid } from '@chakra-ui/react';

import { BaseDropdown, BaseInput, PhoneInput } from '../../../../components';
import { genderData, maritalStatus } from '../../../../data/dropDownOptions';

const colSpan = { base: 2, md: 1 };
import Calendar from 'assets/icons/dateCalendar.svg';
import { inputError } from 'utils';

import { LocationOptions } from '../getLocations';

export const PersonalDetails = ({
  register,
  control,
  getCountryCode,
  countryCode,
  isEdit,
  errors,
}) => {
  const { workLocationOptions } = LocationOptions();

  return (
    <SimpleGrid columns={2} gap="6">
      <GridItem colSpan={colSpan}>
        <FormControl isRequired>
          <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
            First name
          </FormLabel>
          <Input
            {...register('firstName', { required: true })}
            fontSize="1.6rem"
            height="4.8rem"
            backgroundColor="#F7F9FB"
          />
          {inputError(errors, 'firstName')}
        </FormControl>
      </GridItem>
      <GridItem colSpan={colSpan}>
        <FormControl isRequired>
          <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
            Last name
          </FormLabel>
          <Input
            {...register('lastName')}
            fontSize="1.6rem"
            height="4.8rem"
            backgroundColor="#F7F9FB"
          />
          {inputError(errors, 'lastName')}
        </FormControl>
      </GridItem>
      <GridItem colSpan={colSpan}>
        {!isEdit ? (
          <FormControl>
            <Flex gap="4" alignItems="center">
              <PhoneInput
                label="Phone Number"
                getCountryCode={getCountryCode}
                countryCode={countryCode}
              />
              <Input
                {...register('phoneNumber')}
                h="4.8rem"
                type="number"
                fontSize="1.6rem"
                mt="2.5rem"
                backgroundColor="#F7F9FB"
              />
            </Flex>
          </FormControl>
        ) : (
          <BaseInput
            {...register('phoneNumber')}
            h="4.8rem"
            fontSize="1.6rem"
            mt="0.3rem"
            label="Phone number"
          />
        )}
      </GridItem>
      <GridItem colSpan={colSpan}>
        <FormControl isRequired>
          <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
            Email Address
          </FormLabel>
          <BaseInput {...register('email')} fontSize="1.6rem" />
          {inputError(errors, 'email')}
        </FormControl>
      </GridItem>
      <GridItem colSpan={colSpan}>
        <Controller
          defaultValue=""
          control={control}
          name="gender"
          render={({ field }) => (
            <BaseDropdown
              {...field}
              w="322"
              labelSize="1.4rem"
              label="Gender"
              border="1px solid #E0E0E0"
              options={genderData}
            />
          )}
        />
      </GridItem>
      <GridItem colSpan={colSpan}>
        <BaseInput {...register('location')} fontSize="1.6rem" label="Address" />
      </GridItem>
      <GridItem colSpan={colSpan}>
        <Controller
          defaultValue=""
          control={control}
          name="maritalStatus"
          render={({ field }) => (
            <BaseDropdown
              {...field}
              w="322"
              labelSize="1.4rem"
              label="Marital Status"
              border="1px solid #E0E0E0"
              options={maritalStatus}
            />
          )}
        />
      </GridItem>
      <GridItem colSpan={colSpan}>
        <Controller
          defaultValue=""
          control={control}
          name="workLocation"
          render={({ field }) => (
            <BaseDropdown
              {...field}
              w="322"
              labelSize="1.4rem"
              label="Work Location"
              border="1px solid #E0E0E0"
              options={workLocationOptions}
              isRequired
            />
          )}
        />
        {inputError(errors, 'workLocation')}
      </GridItem>
      <GridItem colSpan={colSpan}>
        <FormControl isRequired>
          <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
            Date of Birth
          </FormLabel>
          <BaseInput
            {...register('dob')}
            fontSize="1.6rem"
            type="date"
            right={'1rem'}
            color={'rgba(0,0,0,0.5)'}
            rightIcon={Calendar}
          />
        </FormControl>
        {inputError(errors, 'dob')}
      </GridItem>
    </SimpleGrid>
  );
};
