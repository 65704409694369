import React, { useReducer, useState } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { CustomButton, SimpleModal } from 'components';

import plusIcon from '../../../assets/icons/plusLarge.svg';

import { IssueQuery } from './components/Modals/IssueQuery';
import { ViewQueryDetails } from './components/Modals/ViewQueryDetails';
import { QueryTable } from './components/QueryTable';

const initialState = { showModal: false, contentType: '' };

function simpleModalreducer(state, action) {
  return { ...state, ...action };
}

export const Query = () => {
  const [page, setPage] = useState(1);
  const [singleQuery, setSingleQuery] = useState({});
  const [{ showModal, contentType }, setModalType] = useReducer(
    simpleModalreducer,
    () => initialState
  );

  return (
    <>
      <ContentWrapper>
        <Flex justifyContent={'space-between'} alignItems={'center'} marginBottom={'24px'}>
          <Heading>QUERY</Heading>
          <CustomButton
            name={'Issue Query'}
            mW={'141px'}
            src={plusIcon}
            h={'50px'}
            onClick={() => setModalType({ showModal: true, contentType: 'issueQuery' })}
          />
        </Flex>
        <QueryTable
          page={page}
          setPage={setPage}
          showModal={showModal}
          setSingleQuery={setSingleQuery}
          openModal={() => setModalType({ showModal: true, contentType: 'viewQueryDetails' })}
        />
      </ContentWrapper>

      <SimpleModal
        showModal={showModal}
        setShowModal={() => setModalType({ showModal: false, contentType: '' })}>
        {contentType === 'issueQuery' && (
          <IssueQuery closeModal={() => setModalType({ showModal: false, contentType: '' })} />
        )}
        {contentType === 'viewQueryDetails' && (
          <ViewQueryDetails
            singleQuery={singleQuery}
            page={page}
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
          />
        )}
      </SimpleModal>
    </>
  );
};

const ContentWrapper = styled.div`
  padding: 24px;
`;
