import React from 'react';
import { Box, Grid } from '@chakra-ui/react';

import { ApplicantsFormSectionsProvider } from '../../../../../context';

import { ConfigurePanel } from './components/ConfigurePanel';
import { ApplicantsForm } from './components';

function ApplicantsFormView(props) {
  const { gridProps = {}, leftPanelProps = {} } = props;
  const leftPanelRef = React.useRef();

  return (
    <ApplicantsFormSectionsProvider panelRef={leftPanelRef}>
      <Grid
        templateColumns={{
          base: '1fr',
          md: '6fr 3fr',
          lg: 'minmax(auto ,76rem) minmax(auto, 33rem)',
        }}
        height={{ md: `calc(100vh - 17rem)` }}
        pb="2.4rem"
        {...gridProps}>
        <Box
          className="left-panel scroll-bar"
          px={{ base: '2.4rem', lg: '3.2rem' }}
          borderLeft="1px solid var(--grey-150)"
          ref={leftPanelRef}
          overflowY="auto"
          {...leftPanelProps}>
          <ApplicantsForm />
        </Box>
        <Box className="right-panel" ml={leftPanelProps.border ? '-1px' : null}>
          <ConfigurePanel />
        </Box>
      </Grid>
    </ApplicantsFormSectionsProvider>
  );
}

export default ApplicantsFormView;
