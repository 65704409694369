import React from 'react';
import { useDrop } from 'react-dnd';
import { useFormState } from 'react-hook-form';
import styled from '@emotion/styled';
import cx from 'classnames';

import { SpanDouble, SpanSingle } from 'components';

import { DND_TYPES } from '../../../../../../utils';
import { getDeep } from '../../../../../../utils';
import { createInput, INPUT_TYPES, NON_EDITABLE_FIELD_NAMES } from '../helpers';

import { EditButton } from './EditButton';
import { InputField } from './InputField';
import { InputFieldEdit } from './InputFieldEdit';
import { SectionField } from './SectionField';

function EditableInputField(props) {
  const { field, update, index, insert } = props;
  const isEditing = field.isEditing;
  const isEditable = !(NON_EDITABLE_FIELD_NAMES.includes(field.name) && !props.inSection);
  const isTextArea = field.type === INPUT_TYPES.TextArea;
  const isInput = field.type !== INPUT_TYPES.Section;
  const isSection = field.type === INPUT_TYPES.Section;
  const doubleSpan = isTextArea || isSection || isEditing;
  const [prevPosition, setPreviewPosition] = React.useState(null);
  const formState = useFormState({ control: props.control });

  const setIsEditing = (val) => update(index, { ...field, isEditing: val });
  const handleEditIconClick = () => setIsEditing(true);

  const onDrop = React.useCallback(
    (d) => {
      if (typeof prevPosition === 'number')
        insert(prevPosition, createInput({ type: d.type, isEditing: true }));
    },
    [prevPosition]
  );
  const { drop, collected } = useFieldDroppable({ setPreviewPosition, index, onDrop, field });

  return (
    <Wrapper
      type={field.type}
      isSectionOrTextarea={doubleSpan}
      className={cx('editable-input-field', {
        'show-preview': typeof prevPosition === 'number' && collected.isOver,
        'preview-bottom': prevPosition > index,
        error: !!getDeep(formState.errors, `${props.fieldName}.name`),
      })}
      ref={drop}
      onMouseOut={() => setPreviewPosition(null)}>
      {!isEditing && isInput && (
        <>
          <InputField {...props} />
          {isEditable ? <EditButton onClick={handleEditIconClick} /> : null}
        </>
      )}
      {isEditing && isInput && <InputFieldEdit {...props} />}
      {isSection && <SectionField {...props} isEditing={isEditing} />}
    </Wrapper>
  );
}

function useFieldDroppable({ setPreviewPosition, index, field, onDrop }) {
  const ref = React.useRef(null);

  const [collected, drop] = useDrop({
    accept: DND_TYPES.ApplicantInputOption,
    hover: (item, monitor) => {
      const hovElement = ref.current;
      const noChildren = !field.children || (field.children && field.children.length < 1);
      const isSection = field.type === INPUT_TYPES.Section;
      if (!hovElement || !noChildren || isSection) return;

      let previewIndex = null;
      const { y, height } = hovElement.getBoundingClientRect();
      const mid = y + height / 2 + 4;
      const { y: pointerY } = monitor.getClientOffset();

      if (monitor.isOver({ shallow: true })) {
        if (pointerY <= mid) previewIndex = index;
        else previewIndex = index + 1;
        setPreviewPosition(previewIndex);
      }
    },
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      };
    },
    drop: (d) => onDrop(d),
  });

  React.useEffect(() => {
    drop(ref);
  });

  return {
    collected,
    drop: ref,
  };
}

const Wrapper = React.forwardRef(function ({ isSectionOrTextarea, children, type, ...rest }, ref) {
  const Wrapper = React.useMemo(
    () => (isSectionOrTextarea ? SpanDouble : SpanSingle),
    [type, isSectionOrTextarea]
  );
  const StyledWrapper = styled(Wrapper)`
    position: relative;
    pointer-events: auto;
    background: #fff;

    &:hover {
      > .icon {
        opacity: 1;
        pointer-events: all;
      }
    }

    &:after {
      border-radius: 1rem;
      opacity: 0.5;
    }

    &.show-preview:after {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 4px;
      background: var(--green);
    }

    &.preview-bottom:after {
      top: auto;
      bottom: -8px;
    }

    /* &.error {
      border: 1px solid red;
    } */
  `;
  return (
    <StyledWrapper {...rest} ref={ref}>
      {children}
    </StyledWrapper>
  );
});
Wrapper.displayName = 'Wrapper';

export { EditableInputField };
