import React from 'react';
import { Avatar, Flex, Stack, Text } from '@chakra-ui/react';

import { Pagination, TableComponent } from 'components';

import { columns } from './data';

export function PerformanceReviewTable({
  page,
  setPage,
  pageCount,
  setShowPerformanceReview,
  loading,
  setData,
  tData,
}) {
  const tableData = tData?.map(({ _id, employee, reviewPeriod, lineManagerCompleted }) => {
    return {
      id: _id,
      employeeName: (
        <Flex alignItems={'center'} gap={'16px'}>
          <Avatar
            w={'40px'}
            h={'40px'}
            size={'lg'}
            name={employee?.firstName + ' ' + employee?.lastName}
            src={employee?.imageUrl}
          />
          <Text>{employee?.firstName + ' ' + employee?.lastName}</Text>
        </Flex>
      ),
      team: <Text textTransform={'capitalize'}>{employee.team?.name}</Text>,
      startDate: reviewPeriod?.startDate,
      isCompleted: (
        <Text className={`status ${lineManagerCompleted ? `is-completed` : `not-completed`}`}>
          {lineManagerCompleted ? `Completed` : `Awaiting Review`}
        </Text>
      ),
    };
  });

  const dropdownActions = { view: 'View Review' };

  const performAction = (data) => {
    switch (data.action) {
      case 'view-review':
        setShowPerformanceReview(true);
        setData(data?.id);
        break;
      default:
        break;
    }
  };

  return (
    <Stack overflow={'auto'} gap={'16px'}>
      <>
        <Stack overflow={'auto'}>
          <TableComponent
            columns={columns}
            noSort
            noCheckbox
            loading={loading}
            data={tableData}
            dropdownActions={dropdownActions}
            actionOnData={performAction}
            empty={tData?.length === 0}
            ids={tData?.map((item) => item)}
          />
        </Stack>

        {tableData.length > 0 && (
          <Pagination page={page} setPage={setPage} totalPages={pageCount || 1} />
        )}
      </>
    </Stack>
  );
}
