import React from 'react';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Grid } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';
import moment from 'moment';

import { LoadingImage, ModalBackButton, SpinnerComponent } from '../../../../components';
import { AssessmentFormProvider, useAssessmentForm } from '../../../../context/assessmentForm';
import { useEaseToast } from '../../../../hooks';
import { FETCH_ASSESSMENT, SUBMIT_ASSESSMENT } from '../../../../query';
import { getItem, setItem } from '../../../../utils';

import { ApplicantQuestions } from './ApplicantQuestions';
import { AssessmentControlsRoot, AssessmentHeaderRoot, SectionRoot, Wrapper } from './styles';

let interval;
const assessmentPassed = (id) => localStorage.getItem(`${id}-sent-${getItem('token')}`);
const existingDeadline = (id) => localStorage.getItem(`${id}-deadline-${getItem('token')}`);
const defaultState = { endTime: null, timePassed: false };

const ApplicantAssessment = () => {
  const { assessmentId } = useParams();

  const [deadlinePassed, setDeadlinePassed] = React.useState(false);
  const { endTime, setEndTime, timePassed, setTimePassed } = useAssessmentDeadline(
    spreadReducer,
    defaultState,
    () => {
      const fromStorage = existingDeadline(assessmentId);
      const end = fromStorage ? moment(JSON.parse(fromStorage)) : null;
      const passed = !!assessmentPassed(assessmentId);
      return { endTime: end, timePassed: passed };
    }
  );
  // Assessment
  const { data: assessmentData, loading } = useQuery(FETCH_ASSESSMENT, {
    variables: { id: assessmentId },
  });
  const assessment = assessmentData?.fetchAssessmentById?.data || {};
  const duration = assessment ? assessment?.duration : null;

  if (duration) {
    const fromStorage = existingDeadline(assessment._id);
    if (!fromStorage) {
      const timeParts = duration.split(':');
      const hours = timeParts.length === 1 ? 0 : timeParts[0];
      const minutes = timeParts.length === 2 ? timeParts[1] : timeParts[0];
      const timeToEnd = moment().add(hours, 'hours').add(minutes, 'minutes');
      setItem(`${assessment._id}-deadline-${getItem('token')}`, timeToEnd.toDate());
      if (!endTime) setEndTime(timeToEnd);
    } else {
      if (!endTime) setEndTime(moment(JSON.parse(fromStorage)));
    }
  }

  if (loading) {
    return (
      <Grid minH="30vh" placeContent="center">
        <SpinnerComponent data-testid="loading" />
      </Grid>
    );
  }

  const deadline = new Date(assessment?.deadline);
  if (deadline < new Date() && !deadlinePassed) {
    setDeadlinePassed(true);
  }

  return (
    <AssessmentFormProvider initialValue={{ defaultValues: assessment }}>
      <ApplicantAssessmentForm
        assessment={assessment}
        endTime={endTime}
        timePassed={timePassed}
        setTimePassed={setTimePassed}
        deadlinePassed={false}
      />
    </AssessmentFormProvider>
  );
};

function ApplicantAssessmentForm(props) {
  const { assessment, endTime, timePassed, setTimePassed, deadlinePassed } = props;
  const { successToast, errorToast } = useToastify();
  const [currentSectionIndex, setCurrentSectionIndex] = React.useState(0);

  const formMethods = useAssessmentForm();
  const { handleSubmit, control } = formMethods;
  const currentSectionName = `sections.${currentSectionIndex}`;
  const sections = useWatch({ control });
  const currentSection = sections?.[currentSectionIndex];
  const user = getItem('hrm_user');
  const { assessmentId } = useParams();

  // Submit Assessment
  const [submitAssessment, { loading: submitLoading }] = useMutation(SUBMIT_ASSESSMENT);

  function done() {
    const sentPrev = assessmentPassed(assessmentId);
    if (!sentPrev) {
      const submit = handleSubmit(onSubmit);
      submit();
    } else {
      setTimePassed(true);
    }
  }

  async function onSubmit(value) {
    try {
      if (!deadlinePassed) {
        const assessmentId = value?._id;
        const applicantId = user?._id;

        const assessmentData = {
          assessmentId,
          applicantId,
          answers: getQuestionIdsAndAnswers(value),
        };

        const submitAssessmentVars = { data: assessmentData };
        await submitAssessment({ variables: submitAssessmentVars })
          .then((res) => {
            if (res.data.createResponse.status === 409)
              throw new Error(res.data.createResponse.message);
            setTimePassed(true);
            setItem(`${assessmentId}-sent-${getItem('token')}`, true);
            successToast('Assessment submitted successfully');
          })
          .catch((err) => {
            errorToast(err.message || 'Error submitting assessment');
            if (err.message === 'Applicant Assessment already exists!') {
              setTimePassed(true);
              setItem(`${assessmentId}-sent-${getItem('token')}`, true);
            }
          });
      }
    } catch (err) {
      errorToast(err.message);
    }
  }

  return (
    <Wrapper>
      <div className="main">
        <Box mb={'24px'}>
          <ModalBackButton
            onClick={() => {
              window.location.replace(`/applicant/assessment/${assessmentId}`);
            }}
          />
        </Box>
        <AssessmentHeader
          title={assessment.title}
          endTime={endTime}
          done={done}
          timePassed={timePassed || deadlinePassed}
          deadlinePassed={deadlinePassed}
        />
        {deadlinePassed && <Box mt="5rem">Assessment deadline passed.</Box>}
        {timePassed && !deadlinePassed && (
          <Box mt="5rem">{"You've completed this assessment."}</Box>
        )}
        {!timePassed && !deadlinePassed && (
          <AssessmentSection
            section={currentSection}
            index={currentSectionIndex}
            name={currentSectionName}
            instructions={assessment.instructions}
          />
        )}
        {!timePassed && !deadlinePassed && (
          <AssessmentControls
            currentSectionIndex={currentSectionIndex}
            setCurrentSectionIndex={setCurrentSectionIndex}
            numberOfSections={assessment?.sections?.length}
            handleSubmit={handleSubmit(onSubmit)}
            submitting={submitLoading}
          />
        )}
      </div>
    </Wrapper>
  );
}

function AssessmentHeader({ title, description, endTime, done, timePassed, deadlinePassed }) {
  const [remaining, setRemaining] = React.useState(
    () => getRemainingTime(endTime, timePassed).timeRemaining
  );
  const { showErrorToast } = useEaseToast();
  const alertedRef = React.useRef(false);
  const endRef = React.useRef(false);

  function alertTime(description) {
    showErrorToast({ title: 'Alert', description, status: 'warning' });
    alertedRef.current = true;
  }

  React.useEffect(() => {
    if (endTime && !endRef.current) {
      interval = setInterval(() => {
        const { timeRemaining, minutes, hours, timeUp } = getRemainingTime(endTime, timePassed);
        const fiveRemaining = minutes === 5 && hours === 0 && !alertedRef.current;
        setRemaining(timeRemaining);
        if (fiveRemaining) alertTime('5 minutes remaining');
        if ((timeUp || timePassed) && !deadlinePassed) {
          done();
          endRef.current = true;
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [endTime, timePassed]);

  return (
    <AssessmentHeaderRoot>
      <Grid>
        <h1 className="assessment-title">{title}</h1>
        {description ? <p className="value">{description}</p> : null}
      </Grid>
      <div className="assessment-duration">
        <p className="label">Duration: </p>
        <p className="value">{remaining.split(':').join(' : ')}</p>
      </div>
    </AssessmentHeaderRoot>
  );
}

export function AssessmentSection({ section, index, instructions, name }) {
  return (
    <SectionRoot>
      <header className="section__header">Section {index + 1}</header>
      {instructions && <Instructions instructions={instructions} />}
      <ApplicantQuestions
        key={name}
        sectionName={name}
        questions={section?.questions}
        sectionIndex={index}
      />
    </SectionRoot>
  );
}

function AssessmentControls({
  setCurrentSectionIndex,
  currentSectionIndex,
  numberOfSections,
  handleSubmit,
  submitting,
}) {
  const lastSectionIndex = numberOfSections - 1;
  const isFirst = currentSectionIndex === 0;
  const isLast = currentSectionIndex === lastSectionIndex;

  function handlePreviousClick() {
    const previous = currentSectionIndex - 1;
    setCurrentSectionIndex(isFirst ? 0 : previous);
  }

  function handleNextClick() {
    const next = currentSectionIndex + 1;
    setCurrentSectionIndex(isLast ? lastSectionIndex : next);
  }

  return (
    <AssessmentControlsRoot>
      <button className="btn-empty" disabled={isFirst} onClick={handlePreviousClick}>
        Previous Section
      </button>
      <button className="btn-empty" disabled={isLast} onClick={handleNextClick}>
        Next Section
      </button>
      <button className="btn-filled" onClick={handleSubmit}>
        {submitting && <LoadingImage white mr="1rem" />}
        Submit
      </button>
    </AssessmentControlsRoot>
  );
}

function Instructions({ instructions }) {
  return (
    <>
      <h2>Instructions</h2>
      <p>{instructions}</p>
    </>
  );
}

function getRemainingTime(endTime, finished = false) {
  const now = moment();
  const newTime = moment(endTime);
  newTime.subtract(now);
  const d = moment.duration(endTime.diff(now));
  const hours = d.hours();
  const minutes = d.minutes();
  const seconds = d.seconds();
  const timeUp = minutes <= 0 && hours <= 0 && seconds <= 0;
  const timeRemaining =
    timeUp || finished ? '00:00:00' : `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  return {
    timeRemaining,
    hours,
    minutes,
    seconds,
    timeUp,
  };
}

function pad(val) {
  return val.toString().padStart(2, '0');
}

function getQuestionIdsAndAnswers(data) {
  const questions = data?.sections?.map((section) => section.questions).flat();
  return questions?.map(({ userAnswer }) => {
    return getUserAnswer(userAnswer);
  });
}

function getUserAnswer(answer) {
  return Array.isArray(answer) ? answer : [answer || ''];
}

function spreadReducer(s, a) {
  return { ...s, ...a };
}

function useAssessmentDeadline(...args) {
  const [{ endTime, timePassed }, dispatch] = React.useReducer(...args);

  const setTimePassed = React.useCallback((val) => dispatch({ timePassed: val }), [dispatch]);
  const setEndTime = React.useCallback((val) => dispatch({ endTime: val }), [dispatch]);

  return { endTime, timePassed, setTimePassed, setEndTime };
}

export { ApplicantAssessment };
