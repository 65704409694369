// import { useToastify } from 'hooks/useToastify';

import { useQuery } from '@apollo/client';

import { FETCH_APPLICANT_DETAILS, FETCH_ASSESSMENT_RESPONSE } from '../../../query';
import { getItem } from '../../../utils';

const useApplicantDashboardView = () => {
  const user = getItem('hrm_user');
  const email = user?.email;
  const { data: jobDetailsData, loading } = useQuery(FETCH_APPLICANT_DETAILS, {
    variables: { filter: { email: email } },
  });
  const jobDetails = jobDetailsData?.fetchApplicantApplications.data || [];

  const id = user?._id;
  const { data: assessmentData } = useQuery(FETCH_ASSESSMENT_RESPONSE, {
    variables: { applicantId: id },
  });
  const assessmentScores = assessmentData?.fetchResponse.data || [];

  const assessments = jobDetails
    ?.filter((el) => {
      return !assessmentScores.some((el2) => {
        return el?.jobId?.assessmentId?._id === el2.assessmentId;
      });
    })
    ?.map((data) => {
      return {
        id: data?.jobId?.assessmentId?._id,
        title: data?.jobId?.title,
        deadline: data?.jobId?.assessmentId?.deadline,
      };
    });

  return {
    jobDetails,
    assessments,
    loading,
  };
};

export default useApplicantDashboardView;
