import React from 'react';
import { useForm } from 'react-hook-form';

const PermissionsFormContext = React.createContext();

function PermissionsFormProvider(props) {
  const { options = {} } = props;
  const formMethods = useForm({
    defaultValues: React.useMemo(() => {
      return props.permissionsData;
    }, [props]),
    ...options,
  });

  return (
    <PermissionsFormContext.Provider value={formMethods}>
      {props.children}
    </PermissionsFormContext.Provider>
  );
}

function usePermissionsForm() {
  return React.useContext(PermissionsFormContext);
}

export { PermissionsFormContext, PermissionsFormProvider, usePermissionsForm };
