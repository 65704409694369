import React from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { EditInputFieldTemplate } from './EditInputField';
import { FilesPreview, UrlFilesPreview } from './filesDisplay';
import { dustbin, edit, icons } from './icons';

const QuestionsPreview = ({ field, index, remove, update, setFiles, files }) => {
  const [editObj, setObject] = React.useState({});
  const [editing, setEditing] = React.useState(false);

  const iconSrc = icons[field.type] || icons[field?.questionType];

  const editQuestion = () => {
    const obj = { ...field, index: index };
    setObject(obj);
    setEditing(true);
  };

  function filterFiles(name) {
    const updatedQst = field?.attachedFiles?.filter((file) => file !== name);
    update(index, { ...field, attachedFiles: updatedQst });
  }

  return (
    <div key={field.id}>
      {field?.question !== '' || field?.points ? (
        <Root data-testid="preview-questions-display" key={field.id}>
          <Grid
            templateColumns={'repeat(2, max-content) auto repeat(3, max-content)'}
            mb="1.6rem"
            alignItems={'center'}
            gap={'1rem'}>
            <p>{index + 1}.</p>
            {field?.question !== '' && <img src={iconSrc} alt="" />}
            <p className="question">{field?.question}</p>
            {field?.points && <p className="score">{field?.points}</p>}
            <img
              src={edit}
              data-testid={'edit-question'}
              onClick={() => editQuestion()}
              alt=""
              className="edit-question"
            />
            <img
              src={dustbin}
              data-testid={'remove-option'}
              onClick={() => remove(index)}
              className="edit-question"
              aria-label="delete"
            />
          </Grid>
          <Flex gap={'1.2rem'} alignItems={'center'} flexWrap={'wrap'} pl="3.6rem">
            {field?.options?.map((opt) => {
              const isAnswer = field?.correctAnswers?.includes(opt?.value);
              return (
                <OptionsInput
                  key={opt?.value}
                  style={{
                    border: isAnswer ? '1px solid var(--green)' : '1px solid #f2f2f2',
                  }}>
                  {opt?.value}
                </OptionsInput>
              );
            })}
          </Flex>

          {(field?.files || field?.attachedFiles) && (
            <>
              <div style={{ paddingLeft: '3.6rem' }}>
                <FilesPreview files={field?.files} />
              </div>
              <div style={{ padding: '0 1.2rem' }}>
                <UrlFilesPreview
                  files={field?.attachedFiles}
                  addclose={true}
                  removeFile={(file) => filterFiles(file)}
                />
              </div>
            </>
          )}
        </Root>
      ) : null}

      {editing && (
        <>
          <EditInputFieldTemplate
            setEditing={setEditing}
            update={update}
            question={editObj}
            questionIndex={editObj.index}
            type={editObj.questionType}
            setFiles={setFiles}
            files={files}
          />
        </>
      )}
    </div>
  );
};

const Root = styled.div`
  margin: 1.6rem 0 0 0;
  border-bottom: 1px solid var(--primary-light);
  padding-bottom: 1.2rem;
  .question {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    color: #333758;
  }
  .score {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.117188px;
    color: var(--primary);
    border: 1px solid var(--primary);
    padding: 0.2rem 0.6rem;
    border-radius: 4px;
    height: max-content;
  }

  .edit-question {
    cursor: pointer;
  }
`;
const OptionsInput = styled.div`
  width: auto;
  background: #fafcff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  border: 1px solid #f2f2f2;
  &:focus {
    outline: none;
  }
`;

export { QuestionsPreview };
