import { useEffect, useState } from 'react';

function useCandidatesInfo() {
  let tabs = [
    { displayName: `DETAILS`, value: 'details' },
    { displayName: `HIRING PIPELINE `, value: 'hiringPipeline' },
    { displayName: `DOCUMENTS `, value: 'documents' },
    { displayName: `INTERVIEW `, value: 'interview' },
  ];

  const Tabs = {
    details: 'details',
    hiringPipeline: 'hiringPipeline',
    documents: 'documents',
    interview: 'interview',
  };
  const [selectedButton, setSelectedButton] = useState('details');

  const setSelected = (tab) => {
    Object.keys(Tabs).forEach((key) => {
      if (Tabs[key] === tab) {
        setSelectedButton(Tabs[key]);
      }
    });
  };

  useEffect(() => {
    setSelected(selectedButton);
  }, [selectedButton]);

  return {
    tabs,
    setSelected,
    selectedButton,
    // setPreviousTab,
    // setNextTab,
  };
}

export { useCandidatesInfo };
