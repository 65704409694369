import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AnimatedSection } from 'components/AnimateSection';

import briefcase from '../../../assets/icons/brifecase-tick.svg';
import timer from '../../../assets/icons/clock-timer.svg';
import screening from '../../../assets/icons/screening.svg';
import triangle from '../../../assets/images/triangle.png';

const textArray = [
  {
    image: timer,
    heading: 'Time Attendance Management',
    description:
      'Say goodbye to manual time tracking and embrace automation that provides accurate and reliable records of when your employees clock in and out.',
  },
  {
    image: screening,
    heading: 'Easy Candidate Screening:',
    description:
      'Streamline candidate selection effortlessly through an intuitive and user-friendly interface. Quickly review candidate profiles, qualifications, and suitability for specific roles.',
  },
  {
    image: briefcase,
    heading: 'Asset Management',
    description:
      'Mo money, no problems. HRMS is a next-generation financial technology company in the process of reinventing banking. 30-day free trial.',
  },
];

const Wrapper = styled(Grid)`
  position: relative;
  gap: 52px;
  border-radius: 20px;
  background: #23273b;
  margin: 160px 0;
  padding: 55px 37px;
  background-image: url(${triangle});
  background-size: 99% 101%;
  background-repeat: no-repeat;
  font-family: Inter;

  @media (min-width: 1024px) {
    padding: 55px 80px;
  }

  .section-2 {
    &-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      margin: auto;
      align-content: stretch;

      @media (min-width: 804px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }

      .grid-item {
        display: flex;
        flex-direction: column;
        padding: 39px 21px 21px 20px;
        gap: 39px;
        border-radius: 8px;
        border: 1.5px solid #3b3f57;
        justify-items: center;
        background: linear-gradient(219deg, #292e46 0%, #262a3f 98.28%);
        height: 100%;

        .image {
          display: flex;
          width: 60px;
          height: 60px;
          padding: 14px;
          justify-content: center;
          align-items: center;
          border-radius: 1000px;
          border: 1px solid #3b3f57;
          background: #333853;
        }

        &-text {
          gap: 11px;
          height: fit-content;

          .heading {
            color: #fff;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 116.667% */
            letter-spacing: -0.48px;
          }

          .description {
            color: #a9aec1;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 166.667% */
            max-width: 278px;
          }
        }
      }
    }

    &-text {
      gap: 16px;
      text-align: center;
      max-width: 736px;
      margin: auto;

      .heading {
        color: #3e7eee;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 33.5px; /* 209.375% */
        letter-spacing: 1.6px;
        text-transform: uppercase;
      }
    }

    &-sub-text {
      gap: 20px;

      .sub-heading {
        color: #fff;
        font-size: 46px;
        font-style: normal;
        font-weight: 700;
        line-height: 43.5px; /* 94.565% */
        letter-spacing: -0.92px;
      }

      .description {
        color: #a9aec1;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
      }
    }
  }

  @media screen and (max-width: 968px) {
    padding: 55px 32px 55px 37px;
    background-size: cover;

    .section-2 {
      &-text {
        .heading {
          font-size: 14px;
        }
      }

      &-sub-text {
        .sub-heading {
          font-size: 36px;
        }

        .description {
          font-size: 14px;
        }
      }
    }

    .section-2-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const Section2 = () => {
  return (
    <div className="container">
      <Wrapper>
        <AnimatedSection>
          <Grid className="section-2-text">
            <Text className="heading">Features at a Glances</Text>
            <Grid className="section-2-sub-text">
              <Text className="sub-heading">Manage Complex Work Easily</Text>
              <Text className="description">
                Simplify, automate, and streamline intricate HR tasks effortlessly, enabling your
                team to focus on what truly matters—nurturing your talent and advancing your
                organization.
              </Text>
            </Grid>
          </Grid>
        </AnimatedSection>

        <Grid className="section-2-grid">
          {textArray.map(({ heading, description, image }) => (
            <AnimatedSection key={heading}>
              <GridItem className="grid-item">
                <Box className="image">
                  <img src={image} />
                </Box>
                <Grid className="grid-item-text">
                  <Text className="heading">{heading}</Text>
                  <Text className="description">{description}</Text>
                </Grid>
              </GridItem>
            </AnimatedSection>
          ))}
        </Grid>
      </Wrapper>
    </div>
  );
};
