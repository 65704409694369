import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Flex, Heading, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ASSET_REPORT } from 'query';
import { formatDate } from 'utils';

import {
  CustomButton,
  EmptyState,
  ModalBackButton,
  NameWithImage,
  TableComponent,
} from 'components';

import arrowDown from '../../../../assets/images/arrowdown.svg';

import { AssetReportcolumn } from './data';
import { exportCsv, getCsv, modifyAssetCsv } from './helpers';

export function Assets() {
  const { data: assetReportData, loading } = useQuery(ASSET_REPORT, {
    variables: {
      filter: {},
    },
  });

  const navigate = useNavigate();
  const allData = assetReportData?.fetchAssets?.data;
  const [selectAll, setSelectAll] = React.useState(false);

  const newAssetReportDataTable = allData?.map((elem) => {
    return {
      col1: elem?.name || 'N/A',
      col2: elem?.category || 'N/A',
      col3: elem?.serialNumber || 'N/A',
      col4: elem?.notes || 'N/A',
      col5: elem?.cost || 'N/A',
      col6: <NameWithImage data={elem?.assignedTo} /> || 'N/A',
      col7: formatDate(elem?.dateAssigned) || 'N/A',
    };
  });

  return (
    <Wrapper overflowX={'auto'}>
      <Flex
        border={'solid 1px var(--grey-150)'}
        alignItems={'center'}
        borderRadius={'4px'}
        gap={'24px'}
        p={'24px'}
        backgroundColor={'var(--white)'}>
        <ModalBackButton
          onClick={() => navigate('/admin/reports')}
          data-testid="modal-back-button"
        />
        <Heading className="header-text">Asset Reports </Heading>
      </Flex>
      {assetReportData?.fetchAssets?.data.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Flex justifyContent="flex-end" marginBottom="24px" marginTop="24px">
            <CustomButton
              srcRight={arrowDown}
              name={'Export'}
              mW={'122px'}
              h={'48px'}
              onClick={() => {
                exportCsv(
                  getCsv(modifyAssetCsv, assetReportData?.fetchAssets?.data),
                  'assetReport'
                );
              }}
            />
          </Flex>
          <Stack overflowX={'auto'}>
            <TableComponent
              noAction
              noCheckbox
              loading={loading}
              columns={AssetReportcolumn}
              data={newAssetReportDataTable}
              ids={newAssetReportDataTable?.map((e) => e._id)}
              setAllChecked={setSelectAll}
              allChecked={selectAll}
              noSort
              empty={assetReportData?.fetchAssets?.data === 0}
            />
          </Stack>
        </>
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  padding: 24px;
  .th-parent,
  td {
    padding: 16px;
  }
`;
