import React from 'react';
import { useFormState } from 'react-hook-form';
import { Box, Grid } from '@chakra-ui/react';
import { useReviewCycleForm } from 'context/reviewCycleForm';

import { CustomInput } from 'components';

import { inputError } from '../../../../../../utils';

import { InputLabel } from './InputLabel';
import { SectionTitle } from './SectionTitle';

export function ScheduleStack() {
  const { register, control } = useReviewCycleForm();
  const formState = useFormState({ control });

  return (
    <Box>
      <SectionTitle>Schedule</SectionTitle>
      <Grid templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }} gap="24px">
        <Box>
          <InputLabel>Start Date*</InputLabel>
          <CustomInput
            {...register('schedule.startDate')}
            type="date"
            placeholder="Enter a title for your review"
            style={{ height: '48px' }}
            withBorder
          />
          {inputError(formState.errors, 'schedule.startDate')}
        </Box>
        <Box>
          <InputLabel>End Date*</InputLabel>
          <CustomInput
            {...register('schedule.endDate')}
            type="date"
            placeholder="Enter a title for your review"
            style={{ height: '48px' }}
            withBorder
          />
          {inputError(formState.errors, 'schedule.endDate')}
        </Box>
      </Grid>
    </Box>
  );
}
