import React from 'react';
import { useQuery } from '@apollo/client';
import { strListToOptionList } from 'utils';

import { FETCH_ALL_DEPARTMENTS } from '../query/admin/departments';

const OptionsContext = React.createContext();

const spreadActionReducer = (state, action) => ({ ...state, ...action });

function OptionsProvider(props) {
  const [options, spread] = React.useReducer(spreadActionReducer, {}, () => optionObj);
  const { data: departmentsData } = useQuery(FETCH_ALL_DEPARTMENTS);

  React.useEffect(() => {
    if (departmentsData) {
      const departmentList = departmentsData.fetchCompanyDepartments.data;
      const departmentOptions = departmentList.map(({ _id, name }) => ({
        label: name,
        value: _id,
      }));
      spread({ department: departmentOptions });
    }
  }, [departmentsData]);

  // TODO - REMOVE LATER
  React.useEffect(() => {}, [options]);

  function getOption(name) {
    return options[name] || [];
  }

  return (
    <OptionsContext.Provider value={{ options, getOption }}>
      {props.children}
    </OptionsContext.Provider>
  );
}

function useOptions() {
  const context = React.useContext(OptionsContext);
  if (!context) throw Error('useOptions must be wrapped in OptionsProvider');
  return context;
}

const optionObj = {
  type: strListToOptionList(['Full-Time', 'Part-Time', 'Internship', 'Contract']),
  location: strListToOptionList(['On-site', 'Remote', 'Hybrid']),
  experience: strListToOptionList(['0-1 years', '2-4 years', '5+ years']),
};

export { OptionsProvider, useOptions };
