import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, GridItem, SimpleGrid, Text } from '@chakra-ui/react';

import { BaseDropdown, BaseInput } from '../../../../components';

const colSpan = { base: 2, md: 1 };
import { inputError } from 'utils';

import { accountType, paymentType } from '../../../../data/dropDownOptions';

export const BankDetails = ({ register, control, errors }) => {
  return (
    <>
      <Box borderBottom="1px solid #F2F2F2" pb="0.8rem" mt="4rem" className="bank__details">
        <Text fontSize="2.4rem" color="#807F7F" mt="3.4rem">
          Bank account information
        </Text>
      </Box>
      <SimpleGrid columns={2} marginTop="4rem" gap="6">
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            rules={{ required: true }}
            name="bankAccount.paymentType"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Payment Method"
                border="1px solid #E0E0E0"
                options={paymentType}
                isRequired
              />
            )}
          />
          {inputError(errors, 'bankAccount.paymentType')}
        </GridItem>
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('bankAccount.bankName')}
            placeholder="Bank Name"
            fontSize="1.6rem"
            label="Bank Name"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="bankAccount.accountType"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Account Type"
                border="1px solid #E0E0E0"
                options={accountType}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('bankAccount.accountNo')}
            placeholder="Account Number"
            fontSize="1.6rem"
            label="Account Number"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('bankAccount.accountName')}
            fontSize="1.6rem"
            label="Account Name"
            placeholder="Account Holder's Name"
          />
        </GridItem>
      </SimpleGrid>
    </>
  );
};
