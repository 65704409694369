import { useLazyQuery } from '@apollo/client';
import { LINE_MANAGER_SINGLE_LEAVE_REQUEST } from 'query';

export const useSingleLeaveApproval = () => {
  const [getSingleLeaveRequest, { data, loading, refetch }] = useLazyQuery(
    LINE_MANAGER_SINGLE_LEAVE_REQUEST
  );

  const onGetSingleLeaveRequest = (id) => {
    getSingleLeaveRequest({
      variables: {
        id,
      },
    });
  };

  return {
    loading,
    refetch,
    onGetSingleLeaveRequest,
    leaveRequest: data?.lineManagerSingleLeaveRequest?.data,
  };
};
