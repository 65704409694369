import React from 'react';
import Dropzone from 'react-dropzone';
import { Box, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import cloud from 'assets/icons/upload-cloud.svg';

import { SpinnerComponent } from 'components/common';

export const DropFile = ({ setFiles, accept, maxFiles, loading }) => {
  const handleOnDrop = (acceptedFiles) => {
    setFiles((prev) => {
      const myFiles = new Set(prev?.map((file) => file.name));
      const processedFiles = acceptedFiles?.filter((file) => !myFiles?.has(file.name));
      return [...prev, ...processedFiles];
    });
  };

  return (
    <>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <UploadWrapper data-testid={'employee-upload'}>
          <Dropzone
            onDrop={(acceptedFiles) => handleOnDrop(acceptedFiles)}
            accept={accept ? accept : {}}
            maxFiles={maxFiles || 5}
            data-testid={'ondrop'}
            className={'dropZone'}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <Box {...getRootProps()} className="drop__zone">
                  <input {...getInputProps()} data-testid={'drop-zone'} />
                  <div className="drop__zone--content">
                    <img src={cloud} alt="" />
                    <Grid>
                      <Text color="#000000" fontSize={'13px'}>
                        Select a file or drag and drop here
                      </Text>
                      <Text
                        textAlign={'center'}
                        style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.4)' }}>
                        File size no more than 10MB
                      </Text>
                    </Grid>
                  </div>
                  <button type="button">ADD FILE</button>
                </Box>
              </section>
            )}
          </Dropzone>
        </UploadWrapper>
      )}
    </>
  );
};

const UploadWrapper = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.32);
  margin: 2.4rem 0 1.6rem;
  border-radius: 0.6rem;
  cursor: pointer;
  padding: 2.4rem 0;
  .drop__zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      @media (min-width: 600px) {
        display: flex;
        flex-direction: row;
      }
    }

    button {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
      color: #333758;
      background: #f8f9fb;
      border: 1px solid #3e7eee;
      border-radius: 4px;
      padding: 8px 16px;
    }
  }
`;
