import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const EmployeeViewCard = ({ children, label, type, height }) => {
  return (
    <Wrapper>
      <Text className={`label-${type || 'default'}`}>{label}</Text>
      <Box className={`child_contents-${type || 'default'}`} type={type} height={height}>
        {children}
      </Box>
    </Wrapper>
  );
};
export const ContactCard = ({ children, label, type, height }) => {
  return (
    <Wrapper>
      <Text className={`label-${type || 'default'}`}>{label}</Text>
      <Box className={'contact-card'} type={type} height={height}>
        {children}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .contact-card {
    display: grid;
    grid-gap: 2.4rem;
    .address {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2.4rem;
    }
    .input-root {
      display: grid;
      grid-gap: 1.6rem;
    }
    .input-root div {
      display: grid;
    }
  }
  .child_contents {
    &-default {
      background: #fafcff;
      border-radius: 4px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2.4rem;
    }
    &-green {
      padding: 2.2rem 2rem;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      background: rgba(54, 202, 139, 0.1);
      border: 1px solid #36ca8b;
    }
    &-blue {
      display: grid;
      grid-template-columns: repeat(auto-fill, 24rem);
      grid-gap: 2.4rem;
      padding: 2rem 2rem;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      background: rgba(62, 126, 238, 0.1);
      border: 1px solid #3e7eee;
      .upcoming-event {
        border-left: 1px solid red;
      }
    }
  }
  .label {
    &-default {
      color: #364469;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 1.2rem;
    }
    &-green {
      color: #36ca8b;
    }
    &-blue {
      margin-bottom: 1rem;
      color: var(--blue);
    }
  }
`;

export const InputRoot = styled.div`
  display: grid;
  grid-gap: 0.8rem;
}
`;
