import React from 'react';
import { Box, Stack } from '@chakra-ui/react';

import { LFooter, LHeader, LNavBar } from 'components';

import lbg from '../assets/images/lbg.png';

export const LandingPageLayout = ({ children }) => {
  return (
    <Box backgroundColor={'#EDEEF4'}>
      <Stack
        backgroundImage={lbg}
        position={'relative'}
        backgroundPosition={'right'}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'cover'}
        overflow={'hidden'}
        gap={'56px'}>
        <LNavBar />
        <LHeader />
      </Stack>

      {children}
      <LFooter />
    </Box>
  );
};
