import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { useHandleCreateJob, useJobDetailsForm } from 'context';

import { MultiSelectCreatableDropdown } from 'components';

import { useEditField } from '../hooks';

import { FieldWrapper } from './FieldWrapper';

import { css } from '@emotion/css';

export function Skills() {
  const { watch, setValue } = useJobDetailsForm();
  const skills = watch('skills');
  const { control, reset, handleSubmit } = useForm({ defaultValues: { skills } });
  const { onEditJob } = useHandleCreateJob();

  function onSave(data) {
    setValue('skills', data.skills);
    onEditJob();
  }

  const editOptions = useEditField({
    reset,
    onSave: handleSubmit(onSave),
    defaults: { skills },
  });

  const { isEditing } = editOptions;

  return (
    <FieldWrapper label="Skills" editable editOptions={editOptions}>
      {!isEditing && (
        <Flex flexWrap="wrap">
          <DisplaySkills skills={skills} />
        </Flex>
      )}
      {isEditing && (
        <>
          <Box h="8px"></Box>
          <Controller
            control={control}
            name="skills"
            render={({ field }) => (
              <MultiSelectCreatableDropdown
                placeholder="Select skill sets"
                w="100%"
                h="4.2rem"
                options={skills}
                {...field}
              />
            )}
          />
        </>
      )}
    </FieldWrapper>
  );
}

function DisplaySkills({ skills }) {
  return skills.map((skill) => (
    <Grid key={skill.value} h="2.8rem" px="8px" placeContent="center" className={skillStyle}>
      {skill.value}
    </Grid>
  ));
}

const skillStyle = css`
  margin-right: 8px;
  margin-bottom: 8px;
  background: #3e7eee1a;
  white-space: nowrap;
  font-size: 1.4rem;
  border-radius: 8px;
`;
