import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import closeIcon from '../../assets/icons/close.svg';

import { Title } from './RightModal';

const Overlay = styled(Flex)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(35, 39, 59, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  align-items: center;
  padding: 0 2.4rem;

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  @media (min-width: 768px) {
    padding: 0;
  }

  .children {
    max-height: 85vh;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .modal_wrapper {
    height: auto;
    display: grid;
    padding: 1.6rem;
    grid-gap: 1.6rem;
    border-radius: 1.6rem;
    width: 100%;

    @media (min-width: 768px) {
      width: 43rem;
    }

    &-wide {
      height: auto;
      display: grid;
      padding: 3.4rem 2.4rem;
      grid-gap: 1.6rem;
      border-radius: 1.6rem;
      width: 100%;
      @media (min-width: 768px) {
        width: 70rem;
      }
    }
  }
`;

const Modal = (props) => {
  return (
    <>
      {props.showModal && (
        <Overlay justifyContent={'center'}>
          <Box
            width={props.width || 'auto'}
            background="#fff"
            style={props.style}
            className={`modal_wrapper ${props.wide ? 'modal_wrapper-wide' : ''}`}>
            <Flex justifyContent="space-between">
              <Title>{props.title}</Title>
              <Flex
                p="0.8rem"
                cursor={'pointer'}
                onClick={props.closeModal}
                data-testid="close-modal-btn">
                <img src={closeIcon} alt="" />
              </Flex>
            </Flex>
            <Box className="children">{props.children}</Box>
          </Box>
        </Overlay>
      )}
    </>
  );
};

const SimpleModal = (props) => {
  return (
    <>
      {props.showModal && (
        <Overlay
          justifyContent={'center'}
          overflowY="auto"
          className={`${props.showModal ? 'show' : ''}`}
          onClick={(e) => (e.target === e.currentTarget ? props.setShowModal(false) : () => {})}>
          <Box
            width={props.width || 'auto'}
            background="#fff"
            borderRadius={props.bR || '0.4rem'}
            padding={props.padding || '0'}
            className={`children`}>
            {props.children}
          </Box>
        </Overlay>
      )}
    </>
  );
};

export { Modal, SimpleModal };
