import { useEffect, useState } from 'react';

export function AnnouncementLayoutHook() {
  const [selectedButton, setSelectedButton] = useState('announcement');

  let tabs = [
    { displayName: `Announcement`, value: 'announcement' },
    { displayName: `Newsletter`, value: 'news' },
    { displayName: `Internal Memo`, value: 'memo' },
  ];

  const Tabs = {
    announcement: 'announcement',
    newsletter: 'news',
    internalMemo: 'memo',
  };

  const setSelected = (tab) => {
    Object.keys(Tabs).forEach((key) => {
      if (Tabs[key] === tab) {
        setSelectedButton(Tabs[key]);
      }
    });
  };

  useEffect(() => {
    setSelected(selectedButton);
  }, [selectedButton]);

  return {
    tabs,
    setSelected,
    selectedButton,
  };
}
