import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { HighIcon, LowIcon, MediumIcon, ReapeatOn, RecEventLabel, TaskCategory } from './styles';

const categories = new Map();
categories.set('high', HighIcon);
categories.set('medium', MediumIcon);
categories.set('low', LowIcon);

const TaskCategories = ({ handleTaskCat, taskCat }) => {
  return (
    <Flex gap="0.8rem" data-testid="task-categories">
      {Array.from(categories.keys()).map((cat) => {
        const isActive = taskCat === cat ? 'active' : '';
        return (
          <TaskCategory
            data-testid={`task-categories-${cat}`}
            key={cat}
            type="button"
            isSelected={cat}
            active={taskCat}
            className={`${taskCat} ${isActive}`}
            onClick={() => handleTaskCat(cat)}>
            <Box>{categories.get(cat)}</Box>
            {cat} {'priority'}
          </TaskCategory>
        );
      })}
    </Flex>
  );
};

export const ToggleSwitch = styled(Checkbox)`
  position: relative;
  width: 3.6rem;
  min-width: 3.6rem;
  height: 2rem;
  border-radius: 1rem;
  background: var(--grey-600);
  appearance: none;
  cursor: pointer;
  transition: background-color 100ms linear;

  &:before {
    content: '';
    position: absolute;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 50%;
    background: #fff;
    left: 2px;
    top: 2px;
    transition: left 100ms ease;
  }

  &:checked {
    background: var(--green);
  }

  &:checked:before {
    left: 17px;
  }
`;

function Checkbox(props) {
  return (
    <Flex alignItems={'center'} gap="1rem">
      <input data-testid="toggle-btn" type="checkbox" {...props} />
      <label className="toggle-label" htmlFor={props.id}>
        {props.label}
      </label>
    </Flex>
  );
}

const days = [
  { label: 'Mon', value: 'mon' },
  { label: 'Tue', value: 'tue' },
  { label: 'Wed', value: 'wed' },
  { label: 'Thu', value: 'thu' },
  { label: 'Fri', value: 'fri' },
];
const periods = [
  { label: 'Never', value: 'never' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
];

const RecurringDays = ({ isRecurring, recurringDays, CheckHandler }) => {
  return (
    <>
      {isRecurring && (
        <Box data-testid="daily-recurring">
          <RecEventLabel>{'Reapeat On'}</RecEventLabel>
          <Flex gap="0.8rem" justifyContent={'center'} mt="1rem">
            {days.map((day) => {
              const isActive = recurringDays.includes(day.value) ? 'active' : 'inactive';
              return (
                <ReapeatOn className={`${isActive}`} key={day.value}>
                  <label htmlFor={day.label}>{day.label}</label>
                  <input
                    data-testid={day.value}
                    type="checkbox"
                    id={day.label}
                    value={day.value}
                    onChange={(e) => CheckHandler(e)}
                  />
                </ReapeatOn>
              );
            })}
          </Flex>
        </Box>
      )}
    </>
  );
};

const RecurringPeriod = ({ isRecurring, selectedPeriod, handleSelectedPeriod }) => {
  return (
    <>
      {isRecurring && (
        <Box data-testid="repeat-recurring">
          <RecEventLabel>{'Reapeat Every'}</RecEventLabel>
          <Flex gap="0.8rem" justifyContent={'center'} mt="1rem">
            {periods.map((period) => {
              const isActive = selectedPeriod === period?.value ? 'active' : '';
              return (
                <ReapeatOn
                  data-testid={`selected-period-${period?.label}`}
                  className={`${isActive}`}
                  onClick={() => {
                    handleSelectedPeriod(period?.value);
                  }}
                  key={period?.value}>
                  {period?.label}
                </ReapeatOn>
              );
            })}
          </Flex>
        </Box>
      )}
    </>
  );
};

export { RecurringDays, RecurringPeriod, TaskCategories };
