import { setItem } from 'utils';

import { postRequest } from './httpRequest';

const url = process.env.REACT_APP_HRM_V3_API_BASE_URL;

const signIn = async (body) => {
  const { data } = await postRequest(`${url}/auth/signin`, body);
  setItem('token', data.data.token);
  setItem('hrm_user', data.data.user);
  return data;
};

export { signIn };
