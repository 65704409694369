import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Grid, Link } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import EyeCloseIcon from 'assets/icons/hide-password.svg';
import EyeOpenIcon from 'assets/icons/show-password.svg';
import { useToastify } from 'hooks/useToastify';
import * as yup from 'yup';

import { BaseButton, BaseInput } from '../../../components';
import { applicantSignIn } from '../../../query';

import { Container, ErrorMessage, InputFields } from './styles';

const SignIn = () => {
  const navigate = useNavigate();
  const [showIcon, setShowIcon] = useState(false);
  const { errorToast, successToast } = useToastify();
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    email: '',
    password: '',
  };

  const mainState = process.env.NODE_ENV === 'test' ? initialState : initialState;

  const signin = yup.object().shape({
    email: yup.string().required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: mainState,
    resolver: yupResolver(signin),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await applicantSignIn(data);
      successToast('Login successful');
      navigate(`/applicant/dashboard`);
    } catch (error) {
      errorToast(error.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <Container data-testid="signin-box">
      <h1>Login</h1>
      <InputFields onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <BaseInput
            data-testid={'signin-email'}
            {...register('email', { required: true })}
            name="email"
            label={'Email Address'}
            placeholder={'yourmail@email.com'}
          />
          {errors?.email?.message && <ErrorMessage>{errors?.email?.message} </ErrorMessage>}
        </Grid>
        <Grid>
          <BaseInput
            {...register('password', { required: true })}
            name="password"
            label={'Password'}
            type={showIcon ? 'text' : 'password'}
            rightIcon={showIcon ? EyeOpenIcon : EyeCloseIcon}
            IconClick={() => setShowIcon(!showIcon)}
            placeholder={'Enter password'}
          />
          {errors?.password?.message && <ErrorMessage>{errors?.password?.message} </ErrorMessage>}
        </Grid>
        <Link className="forgot-btn text" onClick={() => navigate('/applicant/forgot-password')}>
          Forgot your password?
        </Link>
        <BaseButton type={'submit'} w={'100%'} isLoading={isLoading}>
          Login
        </BaseButton>
        <p className="text">
          Don&apos;t have an account?{' '}
          <Link className="link" onClick={() => navigate('/applicant/signup')}>
            Sign up
          </Link>
        </p>
      </InputFields>
    </Container>
  );
};

export { SignIn };
