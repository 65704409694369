export const INPUT_TYPES = {
  TextField: 'Text Field',
  TextArea: 'TextArea',
  RadioButton: 'Radio Button',
  Checkbox: 'Checkbox',
  DatePicker: 'Date Picker',
  Dropdown: 'Dropdown',
  Attachment: 'Attachment',
  Hyperlink: 'Hyperlink',
  Section: 'Section',
};

export const NON_EDITABLE_FIELD_NAMES = ['First name', 'Last name', 'Email address'];
// export const SECTION_TYPE = 'Section';
