import React from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Heading, Text } from '@chakra-ui/react';
import { getItem, getYears } from 'utils';

import { EventPopUp, SpinnerComponent } from 'components';

import aniversary from '../../../../assets/images/aniversary_confetti.png';
import cake from '../../../../assets/images/birthday-cake.png';
import calendar from '../../../../assets/images/calendar.png';
import confetti from '../../../../assets/images/confetti.png';
import { FETCH_EMPLOYEE_DETAILS, FETCH_EMPLOYEE_EVENT } from '../query';

import { Notifications, ProfileCard, QuickLinks, UpcomingEvents } from './components';
import { getGreeting, joinNames } from './helpers';
import { DashboardWrapper } from './styles';

const isTesting = process.env.NODE_ENV === 'test';

export function Dashboard() {
  const userDetails = getItem('hrm_user');
  const name = userDetails.firstName.trim();
  let years = getYears(userDetails.employmentInfo.hireDate);

  const [showBirthdayModal, setShowBirthdayModal] = React.useState(false);
  const [showAniversaryModal, setShowAniversaryModal] = React.useState(years ? true : false);

  let id = userDetails?._id;
  if (isTesting) {
    id = 'mock_id';
  }

  const { data, loading } = useQuery(FETCH_EMPLOYEE_DETAILS, {
    variables: {
      filters: {
        _id: id,
      },
    },
  });

  const { data: upcomingEvents } = useQuery(FETCH_EMPLOYEE_EVENT, {
    variables: {
      filters: {},
    },
  });

  return (
    <DashboardWrapper>
      {loading ? (
        <Grid h={'70vh'} w={'77vw'} placeItems={'center'}>
          <SpinnerComponent />
        </Grid>
      ) : (
        <>
          <EventPopUp
            showModal={showBirthdayModal}
            setShowModal={setShowBirthdayModal}
            confetti={confetti}
            popUpPicture={cake}
            employeeDate={userDetails.dob}
            heading={`Hey ${name}, Happy Birthday!🥳❤️`}
            text={`Wishing you a day filled with joy, laughter, and wonderful moments. Your hard work and
            dedication contribute so much to our team, and we're grateful to have you with this
            year bring you success, good health, and all the happiness you deserve.`}
          />
          <EventPopUp
            showModal={showAniversaryModal}
            setShowModal={setShowAniversaryModal}
            confetti={aniversary}
            popUpPicture={calendar}
            employeeDate={userDetails.employmentInfo.hireDate}
            heading={`Happy Anniversary ${name}!🥳`}
            text={`Happy Work Anniversary! 🎉 Today marks ${
              years > 1 ? years + ' years' : years + ' year'
            } since you joined our team, and we couldn't be more thrilled to commemorate this significant milestone with you and we're excited to see what the future holds as we continue this journey together.`}
          />
          <div className="dashboard__heading">
            <Heading fontSize={'2.4rem'} data-testid={'overviewHeading'}>
              Good <span>{getGreeting()}</span>,{' '}
              {joinNames({
                firstName: data?.fetchUsersFiltered?.data[0]?.firstName,
                lastName: data?.fetchUsersFiltered?.data[0]?.lastName,
              })}
            </Heading>
            <Text fontSize={'1.6rem'} p={'1.6rem 0'}>
              Here’s what’s going on at {userDetails?.companyId?.companyName}.
            </Text>
          </div>
          <div className="dashboard__profile">
            <ProfileCard userData={data?.fetchUsersFiltered?.data[0]} />
          </div>
          <div className="dashboard__notification">
            <Notifications />
          </div>

          <div className="sidebar">
            <QuickLinks />
            <UpcomingEvents upcomingEvents={upcomingEvents?.fetchEmployeeEvents?.data} />
          </div>
        </>
      )}
    </DashboardWrapper>
  );
}
