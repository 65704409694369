import { gql } from '@apollo/client';

export const CREATE_TRAINING = gql`
  mutation createTraining($data: CreateTrainingInput!) {
    createTraining(data: $data) {
      status
      message
      data {
        _id
        name
        description
        details
        schedule {
          startDate
          endDate
        }
        repeatProgram {
          isRepeated
          repeatIn
          repeatType
        }
        status
        isShared
      }
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation createTeam($data: TeamInput!) {
    createTeam(data: $data) {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;
