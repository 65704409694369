import React from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { useHandleCreateJob, useJobDetailsForm } from 'context';

import { useEditField } from '../hooks';

import { FieldWrapper } from './FieldWrapper';

import { css } from '@emotion/css';

function JobTitle() {
  const { watch, setValue } = useJobDetailsForm();
  const jobTitle = watch('title');
  const { register, handleSubmit, reset } = useForm({ defaultValues: { title: jobTitle } });
  const { onEditJob } = useHandleCreateJob();

  function onSave(data) {
    setValue('title', data.title);
    onEditJob();
  }

  const editOptions = useEditField({
    onSave: handleSubmit(onSave),
    reset,
    defaults: { title: jobTitle },
  });

  const { isEditing } = editOptions;

  return (
    <FieldWrapper label="Job Title" editable editOptions={editOptions}>
      {!isEditing && <Box className={jobInputStyles}>{jobTitle}</Box>}
      {!!isEditing && (
        <input {...register('title')} className={jobInputStyles} placeholder="Enter job title" />
      )}
    </FieldWrapper>
  );
}

const jobInputStyles = css`
  width: 100%;
  min-height: 2.4rem;
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 500;
  letter-spacing: -0.02em;
  padding: 0;

  &:focus,
  &:focus-visible,
  &:focus-within {
    border: none;
    outline: none;
    stroke: none;
  }
`;

export { JobTitle };
