import { gql } from '@apollo/client';

export const EXIT_EMPLOYEE = gql`
  mutation exitEmployee($data: ExitEmployeeInput!) {
    exitEmployee(data: $data) {
      status
      message
      data {
        employeeId {
          _id
        }
        type
        reason
        content
        issuedBy {
          _id
        }
        createdAt
      }
    }
  }
`;

export const FETCH_EXITED_EMPLOYEES = gql`
  query fetchExitedEmployees($filter: ExitFilter, $page: Int) {
    fetchExitedEmployees(filter: $filter, page: $page) {
      status
      message
      totalCount
      hasNextPage
      data {
        employeeId {
          _id
          imageUrl
          firstName
          lastName
        }
        type
        content
        createdAt
        department
        designation
      }
    }
  }
`;

export const DELETE_EMPLOYEE = gql`
  mutation deleteUser($id: ObjectID!) {
    deleteUser(id: $id) {
      status
      message
      data {
        _id
      }
    }
  }
`;
