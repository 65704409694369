import React from 'react';
import { Box, Grid } from '@chakra-ui/react';

import { ConfigurePanel } from './components/ConfigurePanel';
import { HiringPipeline } from './components/HiringPipeline';

function HiringPipelineView(props) {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '6fr 3fr',
        lg: 'minmax(auto ,76rem) minmax(auto, 33rem)',
      }}
      height={{ md: 'calc(100vh - 17rem)' }}
      pb="2.4rem"
      {...props.gridProps}>
      <Box
        className="left-panel scroll-bar"
        px={{ base: '2.4rem', lg: '3.2rem' }}
        borderLeft="1px solid var(--grey-150)"
        overflowY="auto">
        <HiringPipeline />
      </Box>
      <Box className="right-panel">
        <ConfigurePanel />
      </Box>
    </Grid>
  );
}

export default HiringPipelineView;
