const { successToast, errorToast } = useToastify();
import { useMutation, useQuery } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';

import {
  DELETE_APPROVAL_FLOW,
  FETCH_ALL_POLICIES,
  FETCH_APPROVAL_FLOW,
} from '../../../../query/admin/leave-management/approval-flow';

export function LeaveHook(props) {
  const [deleteApprovalFlow] = useMutation(DELETE_APPROVAL_FLOW, {
    refetchQueries: [
      {
        query: FETCH_APPROVAL_FLOW,
        variables: {
          filter: {},
        },
      },
    ],
  });

  const { data: allPolicies } = useQuery(FETCH_ALL_POLICIES);

  async function action({ action, id }) {
    if (action === 'edit-schedule') {
      props.toggleEditModal(id);
      return;
    }
    if (action === 'assign-schedule') {
      props.assignSchedule(id);
      return;
    }
    if (action === 'delete-schedule') {
      props.deleteSchedule(id);
      return;
    }
    if (action === 'mark-as-default') {
      props.markDefault(id);
      return;
    }
    if (action === 'edit-flow') {
      props?.setApprovalId(id);
      props?.toggleEditModal();
      return;
    }
    if (action === 'assign-flow') {
      props?.setLeavePolicies(allPolicies);
      props?.setApprovalId(id);
      props?.setModalType({ showModal: true, contentType: 'assignApproval' });
      return;
    }
    if (action === 'delete-flow') {
      try {
        const res = await deleteApprovalFlow({
          variables: {
            _id: id,
          },
        });
        successToast(res.data.deleteApprovalFlow.message);
      } catch (error) {
        errorToast(error.message);
      }
    }
    return;
  }

  return {
    action,
  };
}
