import React from 'react';
import { useMutation } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { usePermissionsForm } from 'context';
import { useToastify } from 'hooks/useToastify';
import { ADD_ROLE, EDIT_ROLE } from 'query';

import { CustomButton, ModalBackButton } from 'components';

import { Table } from './Table';

export const PermissionsTable = (props) => {
  const [loading, setLoading] = React.useState(false);
  const { register, control, watch, handleSubmit, reset } = usePermissionsForm();
  const { successToast, errorToast } = useToastify();

  const { setShow, isEditing, refetch, setShowEdit } = props;

  const [addNewRole] = useMutation(ADD_ROLE);
  const [editRole] = useMutation(EDIT_ROLE);

  const onSubmit = async (data) => {
    setLoading(true);
    const permissions = data?.permissions?.map(function (item) {
      delete item.id;
      if (item.moduleName === 'Admin Management') {
        item.moduleName = 'Administrator';
      } else if (item.moduleName === 'Exit Management') {
        item.moduleName = 'Exit';
      } else if (item.moduleName === 'Leave Management') {
        item.moduleName = 'Leave';
      }
      return {
        moduleName: item.moduleName,
        privileges: item.privileges,
      };
    });

    const newData = {
      rolename: data.rolename,
      permissions: permissions,
    };

    const editingData = { ...newData, _id: data._id };

    try {
      const result = isEditing
        ? await editRole({ variables: { data: editingData } })
        : await addNewRole({ variables: { data: newData } });
      if (!isEditing) {
        const message = result?.data?.addNewRole?.message;
        successToast(message);
      } else if (isEditing) {
        const message = result?.data?.editRole?.message;
        successToast(message);
      }
      setLoading(false);
    } catch (error) {
      errorToast('Error Occured');
    } finally {
      setLoading(false);
      refetch();
      setShow(false);
      setShowEdit(false);
      reset();
    }
  };

  const goBack = () => {
    setShow(false);
  };

  return (
    <form data-testid="permissions-form">
      <div className="permissions-header">
        <Flex alignItems={'center'} gap="3.2rem">
          <ModalBackButton onClick={() => goBack()} data-testid="modal-back-btn" />
          <h1>
            <input readOnly type="text" {...register('rolename')} />
          </h1>
        </Flex>
        <CustomButton
          loading={loading}
          variant="primary"
          name="Save"
          h="4.8rem"
          mW="13.5rem"
          type={'button'}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
      <div className="roles-table-wrapper permissions">
        <Table register={register} control={control} watch={watch} />
      </div>
    </form>
  );
};
