import { Controller } from 'react-hook-form';

import { useJobDetailsForm } from '../../../../context';
import { MultiSelectCreatableDropdown } from '../../../shared';
import { JobInputLabel } from '../JobInputLabel';

const skillsRequiredOptions = [
  { label: 'HTML', value: 'HTML' },
  { label: 'Figma', value: 'Figma' },
  { label: 'Mobile app design', value: 'Mobile app design' },
  { label: 'User interface design', value: 'User interface design' },
  { label: 'User experience design', value: 'User experience design' },
];

function SkillsetInput() {
  const { control } = useJobDetailsForm();
  return (
    <>
      <JobInputLabel>Skill set</JobInputLabel>
      <Controller
        control={control}
        name="skills"
        render={({ field }) => (
          <MultiSelectCreatableDropdown
            placeholder="Select skill sets"
            w="100%"
            h="4.2rem"
            options={skillsRequiredOptions}
            {...field}
          />
        )}
      />
    </>
  );
}

export { SkillsetInput };
