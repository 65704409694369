import React from 'react';
import styled from '@emotion/styled';

import { OnboardingTabView } from './tab-views/OnboardingTabViews';
import { DoneOnboarding } from './DoneOnboarding';

export const EmployeeOnboarding = () => {
  const [doneOnboarding, setDoneOnboarding] = React.useState(false);

  return (
    <OnboardRoot>
      <div className="onboard-content">
        {doneOnboarding ? <DoneOnboarding /> : <OnboardingTabView setDone={setDoneOnboarding} />}
      </div>
    </OnboardRoot>
  );
};

const OnboardRoot = styled.div`
  width: 100%;
  ${'' /* height: calc(100vh - 6rem); */}
  border: 1xp solid red;
  padding: 1.4rem 2.4rem 0 2.4rem;
  position: relative;

  .onboard-content {
    background: var(--white);
    border: 1px solid var(--grey-150);
    border-radius: 4px;
    height: inherit;
    position: relative;
    bottom: 0;
    padding: 3.2rem 1.6rem;
  }
`;
