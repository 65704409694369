import { IoIosStar, IoIosStarOutline } from 'react-icons/io';
import styled from '@emotion/styled';

const RatingRoot = styled.div`
  position: relative;
  width: 100px;
  height: 16px;

  & > * {
    display: flex;
    gap: 2px;
    position: absolute;
    overflow-y: hidden;
    display: grid;
    grid: 1fr / auto-flow max-content;
  }

  .grey-stars {
    color: #dee2e8;
  }

  .gold-stars {
    width: calc(16px * var(--gold-stars-w));
    color: #f9900f;
  }
`;

function StarRating({ rating }) {
  return (
    <RatingRoot style={{ '--gold-stars-w': rating }}>
      <div className="grey-stars">
        <IoIosStarOutline />
        <IoIosStarOutline />
        <IoIosStarOutline />
        <IoIosStarOutline />
        <IoIosStarOutline />
      </div>
      <div className="gold-stars">
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
      </div>
    </RatingRoot>
  );
}

export { StarRating };
