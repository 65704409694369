import React from 'react';
import { Avatar, Flex, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { conditional } from 'utils';

import fuelStationIcon from '../../../../assets/icons/fuel-station.svg';
import locationIcon from '../../../../assets/icons/location.svg';

export const PositionAppliedCard = (props) => {
  const jobDetails = props?.jobDetails;

  const jobStages = ['screening', 'assessment', 'interview', 'hired'];

  function checkJobStatus() {
    const currentStageRank = jobDetails?.stage?.title;
    const previousStages = [];

    const currentIndex = jobStages.indexOf(currentStageRank);

    if (currentIndex !== -1) {
      for (let i = 0; i <= currentIndex; i++) {
        const previousStage = jobStages[i];
        previousStages.push(previousStage);
      }
    } else {
      const newlyApplied = 'Newly Applied';
      previousStages.push(newlyApplied);
    }

    return previousStages;
  }

  function checkStage(stage, currentIndex, lastIndex) {
    if (stage === 'screening') {
      return 'rgba(250, 74, 74, ' + (currentIndex === lastIndex ? '1' : '0.5') + ')';
    } else if (stage === 'assessment') {
      return 'rgba(253, 142, 37, ' + (currentIndex === lastIndex ? '1' : '0.5') + ')';
    } else if (stage === 'interview') {
      return 'rgba(151, 71, 255, ' + (currentIndex === lastIndex ? '1' : '0.5') + ')';
    } else if (stage === 'hired') {
      return 'rgba(61, 116, 113, ' + (currentIndex === lastIndex ? '1' : '0.5') + ')';
    } else if (stage === 'Newly Applied') {
      return 'rgba(0, 187, 158, ' + (currentIndex === lastIndex ? '1' : '0.5') + ')';
    } else {
      return 'rgba(204, 204, 204, ' + (currentIndex === lastIndex ? '1' : '0.5') + ')';
    }
  }

  const previousJobStages = checkJobStatus();

  return (
    <Wrapper>
      <main className="mainP">
        <Flex alignItems={'center'} gap={'20px 22px'} wrap={'wrap'}>
          <Flex alignItems={'center'} gap={'8px 8px'} wrap>
            <figure>
              <Avatar bgColor={'#3e7eee'} color={'#fff'} name={jobDetails?.jobId?.title} />
            </figure>
            <div>
              <h3 className="title">{jobDetails?.jobId?.title}</h3>
              <div className="subtitle">
                <Flex gap={'0px 6px'} alignItems={'center'}>
                  <Image src={locationIcon} />
                  <p>{jobDetails?.jobId?.country}</p>
                </Flex>
                <Flex gap={'0px 6px'} alignItems={'center'}>
                  <Image src={fuelStationIcon} />
                  <p>
                    {conditional(!jobDetails?.jobId?.location, 'Remote')}{' '}
                    {conditional(jobDetails?.jobId?.location, jobDetails?.jobId?.location)}
                  </p>
                </Flex>
              </div>
            </div>
          </Flex>
          <div className="border"></div>
          <div>
            <h3 className="title">Date Applied</h3>
            <div className="subtitle">
              <Flex gap={'0px 6px'} alignItems={'center'}>
                <p>{jobDetails?.createdAt?.slice(0, 10)?.split('-')?.join('/')}</p>
              </Flex>
            </div>
          </div>
        </Flex>
      </main>
      <Flex gap={'10px 10px'} wrap={'wrap'}>
        {previousJobStages.map((stage, index) => {
          const lastIndex = previousJobStages.length - 1;
          return (
            <div
              key={stage}
              style={{
                color: 'var(--white)',
                textTransform: 'capitalize',
                padding: '0px 19.5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                height: '40px',
                borderRadius: '6px',
                background: checkStage(stage, index, lastIndex),
              }}>
              {stage}
            </div>
          );
        })}
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 11px 29px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 0px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ecf0f5;
  background: var(--white);
  .title {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  .subtitle {
    display: flex;
    gap: 12px;
    color: #6a7585;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
  }
  .mainP {
    display: flex;
    align-items: center;
  }
  .border {
    width: 2px;
    height: 24px;
    background: #e9e9e9;
  }

  @media only screen and (max-width: 664px) {
    padding: 19px;
  }
`;
