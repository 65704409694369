import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { BaseInput, ModalBackButton, SpinnerComponent } from 'components';

import { useToastify } from '../../../../../../hooks/useToastify';
import {
  EDIT_APPROVAL_FLOW,
  FETCH_APPROVAL_FLOW,
} from '../../../../../../query/admin/leave-management';
import {
  modifyApprovalSteps,
  modifyNotifyUsers,
  transformApprovalStepsData,
  transformDataNotifyUsersData,
} from '../../helpers';
import { CustomButton } from '../Buttons';
import { ToggleSwitch } from '../ToggleButton';

import { ApprovalStepsForm } from './ApprovalStepsForm';
import { NotifyUponApprovalForm } from './NotifyUponApprovalForm';

export function EditApprovalFlow({ closeModal, approvalFlowId }) {
  const [automaticApproval, setAutomaticApproval] = useState(false);
  const { successToast, errorToast } = useToastify();
  function toggleApprovalSteps() {
    setAutomaticApproval((prevState) => !prevState);
  }
  const [getData, { data: approvalFlow, loading: loadingFetch }] = useLazyQuery(
    FETCH_APPROVAL_FLOW,
    {
      variables: {
        filter: {
          _id: approvalFlowId,
        },
      },
    }
  );
  const [editApprovalFlow, { loading }] = useMutation(EDIT_APPROVAL_FLOW, {
    refetchQueries: [
      {
        query: FETCH_APPROVAL_FLOW,
        variables: {
          filter: {
            _id: approvalFlowId,
          },
        },
      },
    ],
  });

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    getData();
    const formObj = approvalFlow?.fetchApprovalFlow?.data[0];
    if (formObj !== null && formObj !== undefined) {
      reset({
        name: formObj.name,
        steps: transformApprovalStepsData(formObj.steps),
        notifyUsers: transformDataNotifyUsersData(formObj.notifyUsers),
      });
    }
  }, [approvalFlow]);

  async function submitForm(data) {
    let steps = [];
    let notifyUsers = [];

    if (!automaticApproval) {
      steps = modifyApprovalSteps(data.steps);
      notifyUsers = modifyNotifyUsers(data.notifyUsers);
    }

    try {
      const res = await editApprovalFlow({
        variables: {
          data: {
            _id: approvalFlowId,
            steps,
            notifyUsers,
          },
        },
      });
      if (res.data.editApprovalFlow.status === 200) {
        successToast(res.data.editApprovalFlow.message);
      }

      if (!loading) {
        closeModal();
      }
    } catch (error) {
      errorToast('error');
    }
  }

  if (loadingFetch)
    return (
      <Stack display={'grid'} placeItems={'center'} h={'100vh'}>
        <SpinnerComponent />
      </Stack>
    );

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Stack gap={'4.8rem'} margin={'2.4rem'}>
        <Flex gap={'2.4rem'}>
          <ModalBackButton onClick={closeModal} />
          <Text
            fontSize={'2.4rem'}
            color={'var(--black)'}
            fontWeight={'600'}
            data-testid={'modalTitle'}>
            Edit Approval Flow
          </Text>
        </Flex>

        <Stack
          border={'solid 1px var(--grey-250)'}
          p={'2.4rem'}
          borderRadius={'0.4rem'}
          gap={'2.4rem'}>
          <Text fontWeight={'600'} color={'var(--black)'}>
            Overall Settings
          </Text>

          <Stack gap={'0.4rem'} maxW={'65.3rem'}>
            <BaseInput
              {...register('name', { required: true })}
              label="Approval Flow Name"
              placeholder={'Eg: Manager Flow'}
              isRequired
            />
          </Stack>

          <Flex>
            <ToggleSwitch onClick={toggleApprovalSteps} />
            <Text fontSize={'1.2rem'}>Automatic Approval</Text>
          </Flex>
        </Stack>

        {!automaticApproval ? (
          <>
            <ApprovalStepsForm register={register} control={control} />

            <NotifyUponApprovalForm register={register} control={control} />
          </>
        ) : null}

        <Flex justifyContent={'flex-end'} gap={'0.8rem'}>
          <CustomButton
            variant={'light'}
            name={'Cancel'}
            mW={'9.4rem'}
            h={'4rem'}
            type={'button'}
            onClick={closeModal}
          />
          <CustomButton
            variant={'primary'}
            name={'Save'}
            mW={'9.4rem'}
            h={'4rem'}
            type={'submit'}
            testId={'saveEditChangesBtn'}
            loading={loading}
          />
        </Flex>
      </Stack>
    </form>
  );
}
