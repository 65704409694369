import React from 'react';
import { Box } from '@chakra-ui/react';

import ApplicantsFormView from '../../add-jobs/ApplicantsForm';
import HiringPipelineView from '../../add-jobs/HiringPipeline';
import { ScoreCardView } from '../../add-jobs/ScoreCards';

import { Tabs } from './components';

const applicantFormProps = {
  gridProps: { maxH: { md: 'calc(100vh - 38.5rem)' } },
  leftPanelProps: {
    border: '1px solid var(--grey-150)',
  },
};

const tabs = {
  'Applicant form': <ApplicantsFormView {...applicantFormProps} />,
  'Hiring Pipeline': <HiringPipelineView {...applicantFormProps} />,
  'Score Card': <ScoreCardView style={{ maxHeight: 'calc(100vh - 22.5rem)', overflowY: 'auto' }} />,
};
const tabNames = Object.keys(tabs);

function Settings() {
  const [active, setActive] = React.useState(() => tabNames[0]);

  // props
  const styleProps = {
    bg: '#fff',
    pt: '4px',
    mt: '0.8rem',
  };

  return (
    <Box {...styleProps}>
      <Tabs active={active} onSelect={(tab) => setActive(tab)} tabs={tabNames} />
      {tabs[active]}
    </Box>
  );
}

export default Settings;
