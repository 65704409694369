import React from 'react';
import { useQuery } from '@apollo/client';
import { STAGING } from 'query/admin/candidates';

import { SpinnerComponent } from '../../../common';
import { HiringTableWrapper, TableWrapperBox } from '../styles';

import { JobApplicant, TableHeader } from './hiringHelpers';
import { HiringPipelineTable, HiringWrapper } from './HiringTable';

const HiringPipeline = ({ pipelineData }) => {
  const { data, loading } = useQuery(STAGING);
  const stagesCols = data?.fetchStages?.data.map((stages, index) => {
    return (
      {
        Header: stages.title,
        accessor: 'col' + (index + 2),
      } || {}
    );
  });

  const tableCols = [
    {
      Header: 'Jobs',
      accessor: 'col1',
    },
    ...stagesCols,
  ];

  // const loading = false;

  if (loading) return <SpinnerComponent h="60vh" />;

  const newTableData = pipelineData?.map((hire) => {
    return {
      col1: JobApplicant(hire),
      col2: hire.rank_1 || null,
      col3: hire.rank_2 || null,
      col4: hire.rank_3 || null,
      col5: hire.rank_4 || null,
      col6: hire.rank_5 || null,
    };
  });

  return (
    <HiringTableWrapper>
      <TableHeader />
      <TableWrapperBox>
        <HiringWrapper>
          <HiringPipelineTable data={newTableData} columns={tableCols}></HiringPipelineTable>
        </HiringWrapper>
      </TableWrapperBox>
    </HiringTableWrapper>
  );
};

export { HiringPipeline };
