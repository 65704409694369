import * as yup from 'yup';

export const jobApplicationSchema = yup.object().shape({
  fields: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Fields/Sections require a name'),
      children: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Fields/Sections require a name'),
        })
      ),
    })
  ),
});
