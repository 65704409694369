import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatDate } from 'utils';

import { Tabs } from 'components';

import ManagerReview from './ManagerReview/ManagerReview';
import PeerReview from './PeerReview/PeerReview';
import { SelfAppraisal } from './SelfAppraisal/SelfAppraisal';
import { useRevieweeReport } from './RevieweeReportHooks';

export default function RevieweeReport({ data }) {
  const { selectedButton, setSelectedButton, tabs } = useRevieweeReport();

  const reviewDetails = data[0]?.reportData[0]?.report[0]?.review_doc;
  const reviewScore = data[0]?.scoreData[0]?.reviewScore;
  const results = data[0]?.reportData;
  const name = data[0].reportData[0]?.report[0]?.reviewee?.firstName;

  const self = results.find(
    (result) => result.report[0]?.reviewers?.reviewType === 'Self Evaluation'
  );
  const manager = results.find(
    (result) => result.report[0]?.reviewers?.reviewType === 'Manager Reviews'
  );
  const peer = results.find((result) => result.report[0]?.reviewers?.reviewType === 'Peer Review');

  function getAccessText(seenByReviewee = false, seenByAll = false) {
    let text = "Review is visible to admin, employee's manager";
    if (seenByReviewee && !seenByAll) {
      text = text + ', reviewee';
    } else if (seenByAll) {
      text = text + ', reviewee, and all other participants';
    }

    return text;
  }

  const responseAccess = getAccessText(reviewDetails.isSeenByReviewee, reviewDetails.isSeenByAll);

  return (
    <Stack>
      <h2 style={{ fontWeight: '400', fontSize: '14px', marginBottom: '8px' }}>Review Details</h2>
      <Flex alignItems="center" justifyContent="space-between">
        <DetailsWrapper>
          <HStack spacing={'80px'} color={'#83889D'} fontSize={'12px'} marginBottom="24px">
            <Details>
              <p>Review Name</p>
              <p>Frequency</p>
              <p>Response Access</p>
              <p>Review Period</p>
            </Details>
            <Details>
              <p>{reviewDetails.title}</p>
              <p>{reviewDetails.frequency}</p>
              <p>{responseAccess}.</p>
              <p>
                {formatDate(reviewDetails.reviewPeriod.startDate)} -{' '}
                {formatDate(reviewDetails.reviewPeriod.endDate)}
              </p>
            </Details>
          </HStack>
          <div style={{ width: '637px', borderBottom: '2px solid #f2f2f2' }}></div>
        </DetailsWrapper>
        <Box>
          <p style={{ fontSize: '48px', fontWeight: '600', color: '#2F80ED', opacity: '0.8' }}>
            {reviewScore}%
          </p>
        </Box>
      </Flex>
      <Tabs
        style={{ marginTop: '56px' }}
        withLine={true}
        withBorder={true}
        tabs={tabs}
        selected={selectedButton}
        setSelected={setSelectedButton}
      />
      <Box>
        {selectedButton === 'self appraisal' ? <SelfAppraisal self={self} name={name} /> : null}
        {selectedButton === 'manager reviews' ? <ManagerReview manager={manager} /> : null}
        {selectedButton === 'peer reviews' ? <PeerReview peer={peer} /> : null}
      </Box>
    </Stack>
  );
}
const Details = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const DetailsWrapper = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 24px;
`;
