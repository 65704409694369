import React from 'react';
import { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { Button, InputBox } from 'components';

import {
  addbutton,
  close,
  deletebutton,
  duplicate,
  linear,
  mchoice,
  radio,
  rating,
} from '../images';

import { OptionCard } from './OptionCard';
import { addButton, buttonStyle, Card, Option, OptionCards, saveButton } from './styles';

const ConfigureCard = ({ question, id, handleChange, handleOptionActions, handleFieldActions }) => {
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = React.useState('');

  const [option, setOption] = useState({ choice: '', value: 1 });

  const handleAddOptions = (e) => {
    const { name, value } = e.target;
    if (name === 'value') {
      setOption({ ...option, [name]: parseInt(value) });
      return;
    }
    setOption({ ...option, [name]: value });
  };

  const onAddOptions = () => {
    handleOptionActions('add', option, id);
    setOption({ choice: '', value: 1 });
  };

  const openAddMode = () => {
    setOption({ choice: '', value: 1 });
    setAddMode(!addMode);
    setEditMode('');
  };
  const openEditMode = (id) => {
    setAddMode(false);
    setEditMode(id);
  };

  return (
    <Card>
      <Flex gap={'30px'} justifyContent={'space-between'} marginBottom={'10px'}>
        <Flex gap={'8px'}>
          {question?.questionType === 'linearScale' && <img className={'image'} src={linear} />}
          {question?.questionType === 'radio' && <img className={'image'} src={radio} />}
          {question?.questionType === 'rating' && <img className={'image'} src={rating} />}
          {question?.questionType === 'multipleChoice' && <img className={'image'} src={mchoice} />}

          <Box className="box">
            <InputBox
              dataTestId={'addQuestionField'}
              placeholder={'Rate team member understanding SOLID principle and how to apply them?'}
              id={'question'}
              value={question?.question || ''}
              handleChange={(e) => handleChange(e, id)}
            />
          </Box>
        </Flex>
        <Flex gap={'16px'}>
          <Button
            dataTestId={'duplicate'}
            img={duplicate}
            title={'Duplicate'}
            imgStyle={{ ...buttonStyle, color: '#23273B' }}
            onClick={() => handleFieldActions(id, 'duplicate')}
          />
          <Button
            dataTestId={'deleteButton'}
            img={deletebutton}
            title={'Delete'}
            imgStyle={{ ...buttonStyle, color: '#FA4A4A' }}
            onClick={() => handleFieldActions(id, 'delete')}
          />
        </Flex>
      </Flex>

      <Flex gap={'16px'} flexDirection={'column'}>
        {question?.questionType !== 'linearScale' && question?.questionType !== 'rating' ? (
          <Button
            dataTestId={'openAddOption'}
            style={addButton}
            img={addbutton}
            title={'Add Option'}
            onClick={() => openAddMode()}
          />
        ) : null}

        <OptionCards>
          {question?.options?.map((opt, index) => (
            <OptionCard
              key={index}
              id={index}
              questionId={id}
              option={opt}
              question={question}
              editMode={editMode}
              setValue={setOption}
              value={option}
              setEditMode={openEditMode}
              handleOptionActions={handleOptionActions}
              handleAddOptions={handleAddOptions}
            />
          ))}
          {addMode && (
            <AddMode
              option={option}
              setAddMode={setAddMode}
              addMode={addMode}
              handleAddOptions={handleAddOptions}
              onAddOptions={onAddOptions}
            />
          )}
        </OptionCards>
      </Flex>
    </Card>
  );
};

const AddMode = ({ handleAddOptions, option, setAddMode, addMode, onAddOptions }) => {
  const isDisabled = !(option?.choice && option?.value);

  return (
    <Flex gap={'16px'} alignItems={'center'}>
      <Option>
        <input
          className="option-text"
          data-testid={'addOptionField'}
          placeholder={'E.g Unsatisfactory'}
          name={'choice'}
          value={option?.choice}
          onChange={handleAddOptions}
          autoFocus
        />
        <Button dataTestId={'cancelOption'} img={close} onClick={() => setAddMode(!addMode)} />
      </Option>

      <Flex
        alignItems={'center'}
        gap={'8px'}
        fontSize={'14px'}
        color={'#3E7EEE;'}
        fontWeight={'600'}>
        <Text>Add Score:</Text>
        <Box fontSize={'14px'}>
          <input
            className="add-score"
            data-testid={'addOptionField'}
            placeholder={'E.g 1'}
            name={'value'}
            pattern="[0-9]"
            type={'number'}
            value={option?.value}
            onChange={handleAddOptions}
          />
        </Box>
      </Flex>

      <>
        <Button
          dataTestId={'addOption'}
          title={'Add'}
          isDisabled={isDisabled}
          style={{ ...saveButton, background: '#3E7EEE' }}
          onClick={onAddOptions}
        />
      </>
    </Flex>
  );
};
export { AddMode, ConfigureCard };
