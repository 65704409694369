import { useEffect, useState } from 'react';

export function ProfileLayoutHook() {
  const [selectedButton, setSelectedButton] = useState('personal');

  let tabs = [
    { displayName: `Personal`, value: 'personal' },
    { displayName: `Job & Pay`, value: 'job' },
    { displayName: `Documents`, value: 'documents' },
  ];

  const Tabs = {
    requests: 'personal',
    policies: 'job',
    settings: 'documents',
  };

  const setSelected = (tab) => {
    Object.keys(Tabs).forEach((key) => {
      if (Tabs[key] === tab) {
        setSelectedButton(Tabs[key]);
      }
    });
  };

  useEffect(() => {
    setSelected(selectedButton);
  }, [selectedButton]);

  return {
    tabs,
    setSelected,
    selectedButton,
  };
}
