export const customDateFormat = (date, day) => {
  Date.prototype.getDayOfWeek = function () {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][
      this.getDay()
    ];
  };

  let hours;
  let minutes;
  let dayString;

  if (day) {
    date = new Date(date);

    hours = date.getHours();
    minutes = date.getMinutes();
    dayString = date.getDayOfWeek();
  } else {
    hours = date.getHours();
    minutes = date.getMinutes();
    dayString = date.getDayOfWeek();
  }

  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? '0' + minutes : minutes;

  let timeString = hours + ':' + minutes + ' ' + amPm;
  if (day) timeString = dayString + ' ' + timeString;

  return timeString;
};

export const getClock = (data) => {
  if (data == null) {
    return 'clockin';
  } else if (data && data.length === 0) {
    return 'clockin';
  } else if (data[0].createdAt.split('T')[0] !== new Date().toISOString().split('T')[0]) {
    return 'clockin';
  } else if (data[0].in !== 'N/A' && data[0].out !== 'N/A') {
    return 'clocked';
  } else {
    return 'clockout';
  }
};
