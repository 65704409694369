export const TableColls = [
  {
    Header: 'Employee Name',
    accessor: 'col1',
  },
  {
    Header: 'Reviewers Name',
    accessor: 'col2',
  },
];

export const createReviewsTable = [
  {
    _id: 'adgfshagdhfjagsw',
    employeeName: 'Floyd Miles',
    reviewersName: 'Floyd Miless',
  },
  {
    _id: 'adgfshagdhdfjagsw',
    employeeName: 'Floyd Miles',
    reviewersName: 'Floyd Miless',
  },
  {
    _id: 'adgfshagdhffjagsw',
    employeeName: 'Floyd Miles',
    reviewersName: 'Floyd Miless',
  },
];
