import React from 'react';
import { useFormState } from 'react-hook-form';
import { Box, Grid } from '@chakra-ui/react';
import { useReviewCycleForm } from 'context/reviewCycleForm';

import { CustomInput } from 'components';

import { inputError } from '../../../../../../utils';

import { InputLabel } from './InputLabel';
import { SectionTitle } from './SectionTitle';

export function ReviewPeriodStack() {
  const { register, control } = useReviewCycleForm();
  const formState = useFormState({ control });

  return (
    <Box>
      <SectionTitle>Review Period</SectionTitle>
      <Grid templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }} gap="24px">
        <Box>
          <InputLabel>Period Start Date*</InputLabel>
          <CustomInput
            {...register('reviewPeriod.startDate')}
            type="date"
            placeholder="Enter a title for your review"
            style={{ height: '48px' }}
            withBorder
          />
          {inputError(formState.errors, 'reviewPeriod.startDate')}
        </Box>
        <Box>
          <InputLabel>Period End Date*</InputLabel>
          <CustomInput
            {...register('reviewPeriod.endDate')}
            type="date"
            placeholder="Enter a title for your review"
            style={{ height: '48px' }}
            withBorder
          />
          {inputError(formState.errors, 'reviewPeriod.endDate')}
        </Box>
      </Grid>
    </Box>
  );
}
