import React from 'react';
import styled from '@emotion/styled';

import { BaseButton, Calendar, CustomInput } from '../../../../../../components';

import { Card } from './Card';

const dateFieldName = 'startDate';
const toDateFieldName = 'endDate';
const isTesting = process.env.NODE_ENV === 'test';

function BlackoutPeriodForm(props) {
  const { editing, onSave, onCancel } = props;
  const [date, setDate] = React.useState(() =>
    isTesting ? new Date(2023, 0, 5) : editing?.[dateFieldName] || null
  );
  const [toDate, setToDate] = React.useState(() =>
    isTesting ? new Date(2023, 0, 30) : editing?.[toDateFieldName] || null
  );
  const [name, setName] = React.useState(() => editing?.name || '');
  return (
    <BlackoutPeriodFormRoot>
      <Card>
        <h1>{editing ? 'Edit' : 'Add'} Blackout Period</h1>

        <div className="content">
          <div className="name-group">
            <label htmlFor="period-name" className="work-schedule__label">
              Blackout Period Name<span className="red">*</span>
            </label>

            <CustomInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="period-name"
              style={{ height: '4.8rem', fontSize: '1.6rem' }}
              placeholder="Blackout period name"
              withBorder
            />
          </div>

          <div className="calendar-group">
            <label htmlFor="holiday-name" className="date__label">
              Select date<span className="red">*</span>
            </label>
            <div className="calendar-wrapper">
              <Calendar
                date={date}
                setDate={setDate}
                isRange
                toDate={toDate}
                setToDate={setToDate}
              />
            </div>
          </div>
        </div>

        <div className="card__ctas">
          <BaseButton variant="outline" onClick={onCancel}>
            Cancel
          </BaseButton>
          <BaseButton
            data-testid="save-blackout-period-btn"
            variant="dark"
            disabled={!date || !toDate || !name}
            onClick={() =>
              onSave({ name, [dateFieldName]: date, [toDateFieldName]: toDate }, editing?.id)
            }>
            Save
          </BaseButton>
        </div>
      </Card>
    </BlackoutPeriodFormRoot>
  );
}

const BlackoutPeriodFormRoot = styled.div`
  padding: 2.4rem;

  h1 {
    --size: 2.4rem;
    font-size: var(--size);
    line-height: var(--size);
    font-weight: 600;
  }

  .content {
    margin-top: 2.4rem;
    display: grid;
    grid: auto-flow max-content / 1fr;
    gap: 3.2rem;

    @media (min-width: 768px) {
      grid: 1fr / 1fr 1fr;
      gap: 2.4rem;

      .name-group {
        grid-area: 1/2/2/3;
      }
    }
  }

  .date__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: var(--grey-500);
  }

  .calendar-wrapper {
    width: 100%;
    max-width: 34rem;
  }

  .card__ctas {
    display: grid;
    grid: 1fr / auto-flow max-content;
    gap: 0.8rem;
    justify-content: flex-end;
  }
`;

export { BlackoutPeriodForm };
