import { Box, Flex, Image } from '@chakra-ui/react';

import ColoredStar from './image/ColoredStar.svg';
import uncoloredStar from './image/uncoloredStar.svg';

export function StarRating(props) {
  if (props.rating >= 2) {
    return (
      <Box padding={'4px'} border={'1px solid #83889D'} borderRadius={'20px'} width={'50px'}>
        <Flex gap={'0px 8px'}>
          <Image src={ColoredStar} />
          {props?.rating}
        </Flex>
      </Box>
    );
  } else {
    return (
      <Box padding={'4px'} border={'1px solid #83889D'} borderRadius={'20px'} width={'50px'}>
        <Flex gap={'0px 8px'}>
          <Image src={uncoloredStar} />
          {props?.rating}
        </Flex>
      </Box>
    );
  }
}
