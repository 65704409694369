import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, Flex, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { DELETE_REVIEW_TEMPLATE } from 'query';

import { DeletePromptModal, EmptyState, SpinnerComponent, TableComponent } from 'components';

import delIcon from '../../images/del.svg';
import iconEdit from '../../images/editIcon.svg';

import { Columns } from './TableCols';

const isTesting = process.env.NODE_ENV === 'test';

export function TemplateTable({ data, loading, refetch }) {
  const { successToast } = useToastify();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState('');
  const [tData, setTData] = useState([]);

  const keys = {
    Title: 'name',
    Description: 'description',
    'Date Created': 'createdAt',
  };
  const fetchedData = data?.fetchAllTemplates?.data;

  useEffect(() => {
    setTData(fetchedData);
  }, [fetchedData]);

  const [deleteReviewTemplate, { loading: deleting }] = useMutation(DELETE_REVIEW_TEMPLATE, {});

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];
    const sortedData = tData?.map((e) => {
      return {
        ...e,
        name: `${e?.name}`,
        description: e?.description || '',
        createdAt: e?.createdAt || '',
      };
    });
    if (key) {
      sortedData?.sort((a, b) => {
        return desc ? b[key]?.localeCompare(a[key]) : a[key]?.localeCompare(b[key]);
      });
      setTData(sortedData);
    }
  };

  const information = tData?.map(({ createdAt, description, name, _id }) => {
    return {
      col1: name,
      col2: description?.substring(0, 30)?.concat('....'),
      col3: new Date(createdAt)?.toString()?.substring(4, 15),
      col4: ActionsField(iconEdit, delIcon, _id),
    };
  });

  function deleteTemplete(id) {
    setShow(false);
    deleteReviewTemplate({ variables: { templateId: id } });
    successToast('Template deleted sucessfully');
    refetch();
  }

  function gotoEdit(id) {
    navigate(`edit-template/${id}`);
  }

  function ActionsField(iconEdit, delIcon, id) {
    return (
      <Flex gap={'6rem'} cursor="pointer">
        <button className="buttonStyle" onClick={() => gotoEdit(id)} data-testid="editIcon">
          <Image src={iconEdit} />
        </button>
        <button className="buttonStyle" onClick={() => setShow(true)} data-testid="deleteIcon">
          <Image src={delIcon} />
        </button>
        {show && (
          <DeletePromptModal
            show={show}
            cancel={() => setShow(false)}
            deleteItem={() => deleteTemplete(id)}
            loading={deleting}
          />
        )}
      </Flex>
    );
  }

  if (loading && !isTesting)
    return (
      <Box height={'40vh'}>
        <SpinnerComponent />
      </Box>
    );

  return (
    <Wrapper>
      {tData?.length ? (
        <TableComponent
          noCheckbox
          noAction
          key={sortBy}
          exclude={'Action'}
          data={information}
          columns={Columns}
          handleMultiSortBy={handleMultiSortBy}
        />
      ) : (
        <EmptyState message={'No Review Templates Found'} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  thead th {
    background: #f3f4f6;
  }
  thead th:first-of-type,
  tbody td:first-of-type {
    padding-left: 2.4rem;
  }

  .buttonStyle:hover {
    opacity: 0.6;
  }
`;
