import React from 'react';
import { Flex, Grid, Image, Text } from '@chakra-ui/react';

import { BaseButton, SimpleModal } from 'components';

import completed from '../../../../../assets/icons/completed.svg';

const ScoreCard = ({ setShowModal, showModal, scores, closeReview, id, loading }) => {
  const score = scores?.filter((item) => item?.reviewer === id);
  const finalScore = score[score.length - 1] || {};

  return (
    <SimpleModal setShowModal={setShowModal} showModal={showModal}>
      <Grid gap={'24px'}>
        <Grid gap={'20px'} justifyItems={'center'}>
          <Image src={completed} />
          <Grid gap={'12px'}>
            <Text className="score-text-1">Employee Review Complete</Text>
            <Grid className="score-cont" gap={'6px'}>
              <Text className="score-text-2">TOTAL REVIEW SCORE</Text>
              <Text className="score-text-3">{finalScore?.percentageScore || 0}%</Text>
            </Grid>
          </Grid>
        </Grid>
        <Flex gap={'12px'} justifyContent={'flex-end'} className="form-buttons">
          <BaseButton
            variant={'outline'}
            type={'reset'}
            isLoading={loading}
            disabled={loading}
            onClick={() => setShowModal(false)}
            data-testid={'cancel-btn'}>
            Cancel
          </BaseButton>
          <BaseButton
            variant={'primary'}
            type={'submit'}
            isLoading={loading}
            disabled={loading}
            onClick={() => closeReview(false)}
            data-testid={'submit-btn'}>
            Complete Review
          </BaseButton>
        </Flex>
      </Grid>
    </SimpleModal>
  );
};

export { ScoreCard };
