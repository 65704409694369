import { useState } from 'react';

export function useAdminPerformance() {
  let tabs = [
    { displayName: `OVERVIEW`, value: 'overview' },
    { displayName: `REVIEWS`, value: 'reviews' },
    { displayName: `GOALS`, value: 'goals' },
    { displayName: `TEMPLATES`, value: 'templates' },
  ];

  const [selectedButton, setSelectedButton] = useState('overview');
  return {
    selectedButton,
    setSelectedButton,
    tabs,
  };
}
