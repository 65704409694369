import { BiCheck } from 'react-icons/bi';
import cx from 'classnames';

import { BreadcrumbsItemRoot, ItemIndicator, ItemName } from './Breadcrumbs.styles';

function BreadcrumbsItem({ children, number, active, hideIndicator }) {
  const showNumber = number >= active;
  const isDone = number < active;
  const isActive = active + 1 === number + 1;
  const stateClasses = cx({ done: isDone, active: isActive, 'hide-indicator': hideIndicator });
  return (
    <BreadcrumbsItemRoot className={'breadcrumbs__item ' + stateClasses} title={children}>
      {hideIndicator ? null : (
        <ItemIndicator className={stateClasses}>
          {showNumber ? number + 1 : null}
          {isDone ? <BiCheck size={16} /> : null}
        </ItemIndicator>
      )}
      <ItemName className={stateClasses}>{children}</ItemName>
    </BreadcrumbsItemRoot>
  );
}

export default BreadcrumbsItem;
