import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { useToastify } from '../../../../hooks/useToastify';
import Clipboard from '../../../../utils/Clipboard';

export function LinkContainer() {
  const { successToast } = useToastify();
  const jobLink = location.href;

  function copy(text) {
    Clipboard.copy(text);
    successToast('Copied to clipboard');
  }

  return (
    <Box>
      <Text fontSize="1.6rem" fontWeight="500">
        Link to This Job
      </Text>
      <Flex
        mt="1.2rem"
        p="1.6rem"
        border="1px solid #6E7191"
        borderRadius="3px"
        alignItems="center"
        data-testid={'link-container'}
        onClick={() => copy(jobLink)}
        _hover={{ textDecoration: 'underline', textUnderlineThickness: '1px' }}
        cursor="pointer">
        <Text className="line-clamp-1">{jobLink}</Text>
      </Flex>
    </Box>
  );
}
