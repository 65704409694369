import { Flex, Grid, GridItem } from '@chakra-ui/react';
import cx from 'classnames';
import { EditButton } from 'pages/admin/jobs/add-jobs/ApplicantsForm/components/EditButton';

import { BaseButton, JobInputLabel } from 'components';

import { css } from '@emotion/css';

function FieldWrapper({ className, label, editable, editOptions = {}, ...props }) {
  const { onOpenEdit, isEditing } = editOptions;
  return (
    <GridItem
      position="relative"
      px="2.4rem"
      py="1.6rem"
      border="1px solid transparent"
      borderBottomColor="var(--grey-150)"
      _focusWithin={{ border: '1px solid var(--primary)' }}
      className={cx(wrapperRootStyles, { isEditing }, className)}
      {...props}>
      <JobInputLabel>
        <Flex justifyContent={{ base: 'space-between' }} alignItems={{ base: 'center' }}>
          {label}
          {editable && isEditing && <EditControls {...editOptions} />}
        </Flex>
      </JobInputLabel>
      {editable && !isEditing && <EditButton data-testid={`edit-${label}`} onClick={onOpenEdit} />}
      {props.children}
    </GridItem>
  );
}

function EditControls({ onCloseEdit, onSaveChanges }) {
  return (
    <Grid templateColumns="repeat(2, max-content)" gap="4px" h="12px" alignContent="center">
      <GridItem>
        <BaseButton variant="text" sm onClick={onCloseEdit}>
          Cancel
        </BaseButton>
      </GridItem>
      <GridItem>
        <BaseButton variant="dark" sm onClick={onSaveChanges}>
          Save
        </BaseButton>
      </GridItem>
    </Grid>
  );
}

const wrapperRootStyles = css`
  .icon {
    right: 2rem;
    top: 1.6rem;
  }

  &:not(.isEditing):hover {
    > .icon {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export { FieldWrapper };
