import { postRequest, putRequest } from 'query/admin/auth/httpRequest';

const url = process.env.REACT_APP_HRM_V3_API_BASE_URL;

export const resetPassword = async (body) => {
  const { data } = await postRequest(`${url}/auth/forgotpassword`, body);
  return data;
};

export const setNewPassword = async (body, token) => {
  const { data } = await putRequest(`${url}/auth/resetpassword?token=${token}`, body);
  return data;
};
