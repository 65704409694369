import { BiEnvelope, BiPhone } from 'react-icons/bi';
import { Avatar, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Status } from 'pages/admin/employee/tableConfig';

export function EmployeeDetails({ employee }) {
  function formatDate(date) {
    const dateLabel = new Date(date).toLocaleDateString('en-us', {
      day: `2-digit`,
      month: 'short',
      year: 'numeric',
    });
    return dateLabel;
  }
  return (
    <Root
      className="employeeInfo"
      bgColor={'#F2F5F9'}
      borderTop={'solid 1px #E2E7EF'}
      borderBottom={'solid 1px #E2E7EF'}>
      <Avatar
        name={employee?.firstName + ' ' + employee?.lastName}
        size={'2xl'}
        src={employee?.imageUrl}
      />
      <Stack gap={'16px'}>
        <Stack gap={'8px'}>
          <Text fontWeight={500}>{`${employee?.firstName} ${employee?.lastName}`}</Text>
          <Flex gap={'12px'} alignItems={'center'}>
            <Status statusChecked={employee?.status} />
            <Text fontSize={'12px'} color={'var(--grey-500)'}>
              {employee?.designation?.name || 'N/A'}
            </Text>
          </Flex>
        </Stack>

        <Grid className="employeeInfo__item">
          <Flex className="info__details">
            <Text fontSize={'12px'} color={'var(--grey-500)'}>
              Department
            </Text>
            <Text>{employee?.department?.name || 'N/A'}</Text>
          </Flex>

          <Flex className="info__details">
            <Text fontSize={'12px'} color={'var(--grey-500)'}>
              Email
            </Text>

            <Flex alignItems={'center'} gap={'8px'}>
              <BiEnvelope />
              <Text>{employee?.email}</Text>
            </Flex>
          </Flex>

          <Flex className="info__details">
            <Text fontSize={'12px'} color={'var(--grey-500)'} gap={'16px'}>
              Date Hired
            </Text>
            <Text>{formatDate(employee?.employmentInfo?.hireDate)}</Text>
          </Flex>

          <Flex className="info__details">
            <Text fontSize={'12px'} color={'var(--grey-500)'} gap={'16px'}>
              Phone Number
            </Text>
            <Flex alignItems={'center'} gap={'8px'}>
              <BiPhone />
              <Text>{employee?.phoneNumber}</Text>
            </Flex>
          </Flex>
        </Grid>
      </Stack>
    </Root>
  );
}

const Root = styled(Flex)`
  padding: 10px 20px;
  gap: 24px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }

  .employeeInfo__item {
    gap: 12px;
    .info__details {
      gap: 8px;
      font-size: 14px;
    }
  }

  @media screen and (min-width: 450px) {
    height: max-content;
    padding: 20px;
    flex-direction: row;
    gap: 45px;
  }

  @media screen and (min-width: 650px) {
    padding: 20px 40px;
    .employeeInfo__item {
      grid-template-columns: 1fr 1fr;
    }

    .info__details {
      flex-direction: row;
    }
  }

  @media screen and (min-width: 700px) {
    &__item {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
