import React from 'react';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import styled from '@emotion/styled';

import dots from '../../assets/icons/dots.svg';

const EventsActionContainer = styled.div`
  .chakra-menu__menu-button {
    padding: 1rem;

    & + div {
      z-index: 210;
    }
  }

  .chakra-menu__menu-list {
    padding: 1rem;
    border-radius: 0.4rem;

    .chakra-menu__menuitem {
      padding: 1rem 1.5rem;
      border-radius: 0.4rem;
    }
  }

  .Force {
    color: var(--error);
  }
`;
const EventsActionContainerTable = styled.td`
  .chakra-menu__menu-button {
    padding-right: 1rem;
    height: 3rem;
    width: 3rem;
    padding: 0 10px;

    & + div {
      z-index: 210;
    }
  }

  .chakra-menu__menu-list {
    padding: 1rem;
    border-radius: 0.4rem;

    .chakra-menu__menuitem {
      padding: 1rem 1.5rem;
      border-radius: 0.4rem;
    }
  }

  .Force {
    color: red;
  }
`;

const EventsPopupAction = (props) => {
  const { dropdownActions } = props;

  const formatText = (action) => {
    return action.replace(/\s+/g, '-').toLowerCase();
  };
  const actions = {
    email: props.email,
    id: props.id,
    name: props.name,
    type: props.type,
    url: props.url,
    status: props.status,
  };

  return (
    <>
      <Menu className="menu">
        {!props.largeKebab ? (
          <MenuButton>
            <svg
              width="3"
              height="12"
              viewBox="0 0 3 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.33333 0C0.6 0 0 0.6 0 1.33333C0 2.06667 0.6 2.66667 1.33333 2.66667C2.06667 2.66667 2.66667 2.06667 2.66667 1.33333C2.66667 0.6 2.06667 0 1.33333 0ZM1.33333 9.33333C0.6 9.33333 0 9.93333 0 10.6667C0 11.4 0.6 12 1.33333 12C2.06667 12 2.66667 11.4 2.66667 10.6667C2.66667 9.93333 2.06667 9.33333 1.33333 9.33333ZM1.33333 4.66667C0.6 4.66667 0 5.26667 0 6C0 6.73333 0.6 7.33333 1.33333 7.33333C2.06667 7.33333 2.66667 6.73333 2.66667 6C2.66667 5.26667 2.06667 4.66667 1.33333 4.66667Z"
                fill="#0D0F11"
              />
            </svg>
          </MenuButton>
        ) : (
          <MenuButton
            bgColor={'#F3F4F6'}
            borderRadius="0.4rem"
            height="4.8rem"
            w="4.8rem"
            justifyContent={'center'}>
            <img src={dots} alt="i" />
          </MenuButton>
        )}
        {actions.email !== 'super admin' ? (
          <MenuList>
            {Object.entries(dropdownActions).map(([key, action]) => {
              const actionText = formatText(action);
              return (
                <MenuItem
                  className={action}
                  data-testid={`${action}`}
                  key={key}
                  onClick={() => props.actionOnData({ ...actions, action: actionText })}>
                  {action}
                </MenuItem>
              );
            })}
          </MenuList>
        ) : null}
      </Menu>
    </>
  );
};

export { EventsActionContainer, EventsActionContainerTable, EventsPopupAction };
