import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, HStack, Text } from '@chakra-ui/react';

import plus from '../../../../../assets/icons/plusBlue.svg';
import { AssignEmployeesModal, SpinnerComponent } from '../../../../../components';
import { useToastify } from '../../../../../hooks/useToastify';
import {
  ASSIGN_WORK_SCHEDULE,
  DELETE_WORK_SCHEDULE,
  EDIT_WORK_SCHEDULE,
  FETCH_WORK_SCHEDULE,
} from '../../../../../query';

import { CustomButton } from './Buttons';
import { Container } from './Container';
import SettingsTable from './SettingsTable';

const workScheduleDropdownActions = [
  'Edit Schedule',
  'Assign Schedule',
  'Delete Schedule',
  'Mark as default',
];

export function WorkSchedules({ setModalType, setEditing }) {
  const [page] = React.useState(() => 1);
  const [assign, setAssign] = React.useState(null);
  const { data, loading, refetch } = useQuery(FETCH_WORK_SCHEDULE, {
    variables: { filter: { page } },
  });

  React.useEffect(() => {
    refetch();
  }, []);

  const { successToast } = useToastify();
  const scheduleData =
    data?.fetchWorkSchedule?.data?.map((s) => {
      return {
        ...s,
        id: s._id,
        value: 0,
      };
    }) || [];
  scheduleData.sort((a, b) => b.isDefault - a.isDefault);

  const [deleteSchedule] = useMutation(DELETE_WORK_SCHEDULE);
  const [updateSchedule] = useMutation(EDIT_WORK_SCHEDULE);
  const [assignSchedule, { loading: assigning }] = useMutation(ASSIGN_WORK_SCHEDULE);

  async function onMarkAsDefault(id) {
    const res = await updateSchedule({
      variables: { data: { _id: id, isDefault: true } },
      refetchQueries: [{ query: FETCH_WORK_SCHEDULE, variables: { filter: { page: 1 } } }],
      awaitRefetchQueries: true,
    });
    successToast(res?.data?.editWorkSchedule?.message);
  }

  async function onDeleteWorkSchedule(id) {
    const res = await deleteSchedule({
      variables: { _id: id },
      refetchQueries: [{ query: FETCH_WORK_SCHEDULE, variables: { filter: { page: 1 } } }],
      awaitRefetchQueries: true,
    });
    successToast(res?.data?.deleteWorkSchedule?.message);
  }

  async function handleAssign(employees) {
    await assignSchedule({
      variables: { data: { _id: assign, employeeId: employees } },
      refetchQueries: [FETCH_WORK_SCHEDULE],
      awaitRefetchQueries: true,
    });
    setAssign(null);
  }

  async function onAssignWorkSchedule(id) {
    setAssign(id);
  }

  return (
    <Container>
      <HStack justify={'space-between'}>
        <Text color={'var(--black)'} fontWeight={'bold'}>
          Work Schedules (Stat Holidays, Weekends, Blackout)
        </Text>

        <CustomButton
          variant={'tertiary'}
          name={'Create Work Schedule'}
          mW={'19.2rem'}
          src={plus}
          h={'4rem'}
          fS={'1.4rem'}
          fW={'400'}
          onClick={() => setModalType({ showModal: true, contentType: 'createWorkSchedule' })}
          testId={'createWorkScheduleBtn'}
        />
      </HStack>
      <HStack fontSize={'1.4rem'} color={'#83889D'} padding={'0 2.4rem'}>
        <Text w={'50%'}>Name</Text>
        <Text w={'50%'}>Enrolled Employees</Text>
      </HStack>
      {loading ? (
        <SpinnerComponent h="24rem" />
      ) : (
        <Box
          border={'solid 1px #ebebeb'}
          borderRadius={'0.4rem'}
          paddingBottom={'2.4rem'}
          overflow={'scroll'}>
          <SettingsTable
            toggleEditModal={(id) => setEditing(id)}
            deleteSchedule={(id) => onDeleteWorkSchedule(id)}
            markDefault={(id) => onMarkAsDefault(id)}
            assignSchedule={(id) => onAssignWorkSchedule(id)}
            data={scheduleData}
            dropdownActions={workScheduleDropdownActions}
          />
        </Box>
      )}
      <AssignEmployeesModal
        assigned={getAssigned(scheduleData, assign)}
        title="Assign Employees"
        key={assign}
        isModal={true}
        showModal={!!assign}
        setShowModal={setAssign}
        onClick={handleAssign}
        assignLoading={assigning}
        assignLeavePolicyLoading={assigning}
      />
    </Container>
  );
}

function getAssigned(schedules, id) {
  return schedules
    ?.find((s) => s._id === id)
    ?.assignedUsers?.map((e) => ({ ...e, isSelected: true }));
}
