import React from 'react';
import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { EmployeeDetails } from 'pages/employee/line-manager/leave-approvals/components/EmployeeDetails';
import { formatDate, getItem } from 'utils';

import { BaseButton } from 'components';

import { SingleEmployeeTimesheetTable } from './SingleEmployeeTimesheet';

export function TimesheetReport({ tData, page, setPage, pageCount, loading, employee, date }) {
  const [isLoading, setIsLoading] = React.useState(false);

  function exportCsv() {
    const token = getItem('token');
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_HRM_V3_API_BASE_URL}/export/timesheet?userId=${employee?._id}&start=${date?.start}&end=${date?.end}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${employee?.lastName}.csv`);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
        setIsLoading(false);
      });
  }
  return (
    <Root>
      <Flex
        justifyContent={'space-between'}
        className="timesheet__head"
        bgColor={'#F2F5F9'}
        alignItems={'center'}>
        <Stack>
          <Heading>TIMESHEET REPORT</Heading>
          <Text>{formatDate(new Date().toISOString())}</Text>
        </Stack>

        <BaseButton loading={isLoading} onClick={exportCsv}>
          Download
        </BaseButton>
      </Flex>

      <EmployeeDetails employee={employee} />
      <Stack p={'27px 34px'}>
        <SingleEmployeeTimesheetTable
          page={page}
          setPage={setPage}
          tData={tData}
          pageCount={pageCount}
          loading={loading}
        />
      </Stack>

      <Flex justifyContent={'center'} p={'11px'} bgColor={'#F2F5F9'}>
        <Text fontSize={'14px'} color={'var(--grey-500)'}>
          © Property of Enyata Ghana • www.enyata.com
        </Text>
      </Flex>
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  .timesheet__head {
    padding: 10px 20px;
  }

  @media screen and (min-width: 450px) {
    .timesheet__head {
      padding: 20px;
    }
  }
  @media screen and (min-width: 650px) {
    width: 600px;
    .timesheet__head {
      padding: 20px 40px;
    }
  }

  @media screen and (min-width: 850px) {
    width: 750px;
  }
  @media screen and (min-width: 1050px) {
    width: 816px;
  }
`;
