import { Flex, Text } from '@chakra-ui/react';

const truncateName = (rolename) => {
  const nameArray = rolename.trim().split(' ');
  return nameArray.length === 1
    ? nameArray[0]?.split('')[0]
    : nameArray[0]?.split('')[0] + nameArray[1].split('')[0];
};

export const RoleNameFormat = ({ rolename }) => {
  const truncName = truncateName(rolename);
  return (
    <Flex className="role-name" alignItems={'center'} gap="0.4rem">
      <TruncatedName truncName={truncName} />
      <Text color={'brand.white'} textTransform={'capitalize'}>
        {rolename.replace(/_/g, ' ')}
      </Text>
    </Flex>
  );
};

const TruncatedName = ({ truncName }) => {
  return (
    <Flex
      className="truncated-name"
      width="3.2rem"
      height="3.2rem"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      bgColor={'#F3F4F6'}>
      <Text color="#3E7EEE" fontSize={'1.6rem'} fontWeight="700">
        {truncName.toUpperCase()}
      </Text>
    </Flex>
  );
};

export const rolesCols = [
  {
    Header: 'Role Titles',
    accessor: 'col1',
  },
  {
    Header: 'No of persons',
    accessor: 'col2',
  },
  {
    Header: 'Status',
    accessor: 'col3',
  },
  {
    Header: 'Date Created',
    accessor: 'col4',
  },
];
export const permissionsCols = ['Menu', 'Create', 'Read', 'Update', 'Delete', 'All'];

export const permissionsData = {
  rolename: '',
  permissions: [
    {
      moduleName: 'Admin Management',
      privileges: [],
    },
    {
      moduleName: 'Employees',
      privileges: [],
    },
    {
      moduleName: 'Jobs',
      privileges: [],
    },
    {
      moduleName: 'Departments',
      privileges: [],
    },
    {
      moduleName: 'Leave Management',
      privileges: [],
    },
    {
      moduleName: 'Exit Management',
      privileges: [],
    },
    {
      moduleName: 'Templates',
      privileges: [],
    },
  ],
};
