import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Heading, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import jwt_decode from 'jwt-decode';

import { SpinnerComponent } from 'components';

import { Tabs } from '../../../../components/shared/tabs';
import { ProfileCard } from '../overview/components/';
import { FETCH_EMPLOYEE_DETAILS, FETCH_EMPLOYEE_DOCUMENTS } from '../query';

import { Documents } from './components/Documents';
import JobnPay from './components/JobnPay';
import Personal from './components/Personal';
import { formatIncomingDate } from './helpers';
import { ProfileLayoutHook } from './profileHook';

const isTesting = process.env.NODE_ENV === 'test';
export const ProfileView = () => {
  const { tabs, setSelected, selectedButton } = ProfileLayoutHook();
  const token = localStorage.getItem('token');
  const user = jwt_decode(token);

  let id = user.id;
  if (isTesting) {
    id = 'mock_id';
  }

  const { data, loading } = useQuery(FETCH_EMPLOYEE_DETAILS, {
    variables: {
      filters: {
        _id: id,
      },
    },
  });

  const [profileDetails, setProfileDetails] = useState({});
  const { data: employeeDocs, loading: loadingDocuments } = useQuery(FETCH_EMPLOYEE_DOCUMENTS);

  useEffect(() => {
    let profileData = data?.fetchUsersFiltered?.data[0];
    if (profileData !== null && profileData !== undefined) {
      setProfileDetails({
        'First Name': profileData?.firstName || 'N/A',
        'Last Name': profileData?.lastName || 'N/A',
        'Phone Number': profileData?.phoneNumber || 'N/A',
        'Email Address': profileData?.email || 'N/A',
        Gender: profileData?.gender || 'N/A',
        'Marital Status': profileData?.maritalStatus || 'N/A',
        'Date of Birth': formatIncomingDate(profileData?.dob) || 'N/A',
        'Work Format': profileData?.workingFormat || 'N/A',
        Location: profileData?.location || 'N/A',

        'Blood Group': profileData?.bloodGroup || 'N/A',
        'Blood Type': profileData?.bloodType || 'N/A',
        'Notable Ailment': profileData?.notableAilment || 'N/A',
        Disability: profileData?.isDisabled || 'N/A',
        'If yes (disability) state it': profileData?.disabilityType || 'N/A',

        'Street Address': profileData?.address[0]?.address || 'N/A',
        City: profileData?.address[0]?.district || 'N/A',
        'Province/State': profileData?.address[0]?.state || 'N/A',
        Country: profileData?.address[0]?.country || 'N/A',

        emergencyContact: {
          fullName: profileData.emergencyContact?.fullName || 'N/A',
          phoneNumber: profileData.emergencyContact?.phoneNumber || 'N/A',
          relationship: profileData.emergencyContact?.relationship || 'N/A',
        },
        Name: profileData?.emergencyContact?.fullName || 'N/A',
        Phone: profileData?.emergencyContact?.phoneNumber || 'N/A',
        Relationship: profileData?.emergencyContact?.relationship || 'N/A',

        'Bank Name': profileData?.bankAccount?.bankName || 'N/A',
        'Payment Type': profileData?.bankAccount?.paymentType || 'N/A',
        'Account Name': profileData?.bankAccount?.accountName || 'N/A',
        'Account Number': profileData?.bankAccount?.accountNo || 'N/A',
        'Account Type': profileData?.bankAccount?.accountType || 'N/A',
        'Pension AccountNo': profileData?.bankAccount?.pensionAccountNo || 'N/A',
        'Pension Fund Administrator': profileData?.bankAccount?.pensionFundAdministrator || 'N/A',

        'ID Number': profileData?.staffId || 'N/A',
        'Date  Hired': formatIncomingDate(profileData?.employmentInfo?.hireDate) || 'N/A',
        'Employment Type': profileData?.employmentType || 'N/A',
        Department: profileData?.department?.name || 'N/A',
        Designation: profileData?.designation?.name || 'N/A',
        Team: profileData?.team?.name || 'N/A',
        'Grade Level': profileData?.gradeLevel || 'N/A',
        'Line Manager/HOD': profileData?.lineManager || 'N/A',
        'Compensation Type': profileData?.employmentInfo?.compensationTypes || 'N/A',
        'Pay Rate': profileData?.employmentInfo?.monthlyPayRate || 'N/A',
        'Employee Benfit': profileData?.employmentInfo?.employmentBenefits || 'N/A',

        'Salary/Rate': profileData?.employmentInfo?.monthlyPayRate || 'N/A',
        'Effective Date': formatIncomingDate(profileData?.employmentInfo?.hireDate) || 'N/A',
        'Hours Per Week': profileData?.employmentInfo?.hoursPerWeek || 'N/A',
      });
    }
  }, [data]);

  return (
    <PageRoot data-testid="profile-layout">
      <Stack
        backgroundColor={'var(--white)'}
        p={'0 2.4rem'}
        marginTop={'2.4rem'}
        borderRadius={'0.4rem'}>
        <Header border={'solid 2px red'}>
          <Heading marginBottom={'5.6rem'}>My Profile</Heading>
          <Tabs tabs={tabs} setSelected={setSelected} selected={selectedButton} />
        </Header>

        {loading ? (
          <Grid h={'70vh'} placeItems={'center'}>
            <SpinnerComponent />
          </Grid>
        ) : (
          <ContentWrapper>
            <Stack>
              <ProfileCard userData={data?.fetchUsersFiltered?.data[0]} />
            </Stack>
            <Content>
              {selectedButton === 'personal' && (
                <Personal
                  userId={id}
                  profileDetails={profileDetails}
                  profileData={data?.fetchUsersFiltered?.data[0]}
                />
              )}
              {selectedButton === 'job' && (
                <JobnPay
                  profileDetails={profileDetails}
                  profileData={data?.fetchUsersFiltered?.data[0]}
                  userId={id}
                />
              )}
              {selectedButton === 'documents' && (
                <Documents
                  docs={employeeDocs?.employeeFetchDocuments?.data}
                  loadingDocuments={loadingDocuments}
                />
              )}
            </Content>
          </ContentWrapper>
        )}
      </Stack>
    </PageRoot>
  );
};

const PageRoot = styled.div``;

const Header = styled.div`
  border-bottom: 1px solid var(--grey-150);
  position: sticky;
  top: 6rem;
  z-index: 2;
  padding-top: 2.4rem;
  background-color: var(--white);
  .main-heading {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 600;
    color: #23273b;
    margin-bottom: 2.4rem;
    text-transform: uppercase;
  }

  .tab-btn-toggle {
    :hover {
      border-bottom: 1px solid #3e7eee;
    }
  }
`;
const Content = styled.div`
  thead tr th {
    padding-left: 1.6rem;
    width: 30%;
  }
  tbody tr td {
    padding-left: 1.6rem;
  }
  td:nth-of-type(4) {
    padding-left: 30px;
  }
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  padding: 54px 0;
  gap: 1.6rem;

  @media screen and (min-width: 900px) {
    grid-template-columns: 275px 1fr;
  }
`;
