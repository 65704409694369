import * as yup from 'yup';

const stringFields = ['title', 'salary', 'deadline'];
const objectFields = ['department', 'type', 'experience'];
const objectOrStringFields = ['country'];

export const JobDetailsSchema = yup.object().shape({
  // Validate String Fields
  ...stringFields.reduce((obj, cur) => {
    return { ...obj, [cur]: yup.string().required() };
  }, {}),

  // Validate Object Fields
  ...objectFields.reduce((obj, cur) => {
    return { ...obj, [cur]: yup.object().required() };
  }, {}),

  // Validate String/Object Fields
  ...objectOrStringFields.reduce((obj, cur) => {
    return {
      ...obj,
      [cur]: yup.lazy((val) => {
        if (typeof val === 'string') return yup.string().required();
        return yup.object().required();
      }),
    };
  }, {}),
});
