import React from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@chakra-ui/react';
import { FETCH_SINGLE_EMPLOYEE_DETAILS } from 'query';
import { getItem } from 'utils';

import { SpinnerComponent } from 'components';

import { EmployeeWelcome } from '..';

import { Dashboard } from './Dashboard';

export const EmployeeOverview = () => {
  const [showWelcome, setShowWelcome] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const user = getItem('hrm_user');
  const { data, loading, refetch } = useQuery(FETCH_SINGLE_EMPLOYEE_DETAILS, {
    variables: {
      filters: {
        _id: user?._id,
      },
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    refetch();
    data?.fetchUsersFiltered?.data[0]?.address.length > 0
      ? setShowWelcome(false)
      : setShowWelcome(true);
    if (data?.fetchUsersFiltered?.data[0]) setLoaded(true);
  }, [data]);

  if (!loaded || loading)
    return (
      <Grid bg="white" mt="48px" h={'70vh'} w={'77vw'} placeItems={'center'}>
        <SpinnerComponent />
      </Grid>
    );

  return (
    <>
      {data?.fetchUsersFiltered?.data?.length !== 0 && (
        <div>
          {showWelcome && !loading && <EmployeeWelcome />}
          {!showWelcome && !loading && <Dashboard />}
        </div>
      )}
    </>
  );
};
