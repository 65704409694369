import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FullModal, SimpleModal } from 'components';

import { FETCH_APP_SETTINGS } from '../../../../query/admin/leave-management/leaveSettings-page';

import { ApprovalFlow } from './components/ApprovalFlow';
import { ModalWrapper } from './components/Container';
import { AssignLeaveApprovalFlow } from './components/Modals/AssignLeaveApprovalFlow';
import { CreateLeaveApprovalFlow } from './components/Modals/CreateLeaveApprovalFlow';
import { CreateWorkSchedule } from './components/Modals/CreateWorkSchedule';
import { EditApprovalFlow } from './components/Modals/EditApprovalFlow';
import { OverAllSettings } from './components/OverAllSettings';
import { WorkSchedules } from './components/WorkSchedules';
import { WorkSchedule as EditWorkSchedule } from './WorkSchedule/WorkSchedule';

const initialState = { showModal: false, contentType: '' };

function simpleModalreducer(state, action) {
  return { ...state, ...action };
}

const isTesting = process.env.NODE_ENV === 'test';

export function LeaveSettingsPage() {
  const [editingWorkSchedule, setEditingWorkScheduleModal] = useState(() => null);
  const [leavePolicies, setLeavePolicies] = useState();
  const [approvalFlowId, setApprovalFlowId] = useState();
  const [leaveVisibility, setLeaveVisibility] = useState(false);
  const [showEditApproval, setShowEditApproval] = useState(false);

  const [fetchLeaveData, { data }] = useLazyQuery(FETCH_APP_SETTINGS);

  function toggleVisibility() {
    setLeaveVisibility((prev) => !prev);
  }
  function toggleEditModal() {
    setShowEditApproval((prevState) => !prevState);
  }

  function toggleEditWorkSchedule() {
    setEditingWorkScheduleModal((prevState) => !prevState);
    setModalType({ showModal: false, contentType: '' });
  }

  function setApprovalId(id) {
    setApprovalFlowId(id);
  }

  const [{ showModal, contentType }, setModalType] = React.useReducer(
    simpleModalreducer,
    () => initialState
  );

  if (!isTesting) {
    React.useEffect(() => {
      fetchLeaveData();
      const leaveSettings = data?.fetchAppSettings?.data[0]?.leaveSettings[0];

      if (leaveSettings !== null && leaveSettings !== undefined) {
        setLeaveVisibility(leaveSettings.leaveVisibility);
      }
    }, [data]);
  }

  // if (loading & !isTesting)
  //   return (
  //     <Stack display={'grid'} placeItems={'center'} h={'50vh'}>
  //       <SpinnerComponent />
  //     </Stack>
  //   );

  return (
    <>
      <OverAllSettings
        setVisibility={setLeaveVisibility}
        leaveVisibility={leaveVisibility}
        toggleVisibility={toggleVisibility}
      />
      {leaveVisibility ? (
        <>
          <WorkSchedules setModalType={setModalType} setEditing={setEditingWorkScheduleModal} />
          <ApprovalFlow
            setModalType={setModalType}
            toggleEditModal={toggleEditModal}
            setApprovalId={setApprovalId}
            setLeavePolicies={setLeavePolicies}
          />
        </>
      ) : null}
      <EditWorkSchedule editing={editingWorkSchedule} setEditing={toggleEditWorkSchedule} />

      <ModalWrapper>
        <SimpleModal
          showModal={showModal}
          setShowModal={() => setModalType({ showModal: false, contentType: '' })}>
          {contentType === 'createApproval' && (
            <CreateLeaveApprovalFlow
              closeModal={() => setModalType({ showModal: false, contentType: '' })}
              approvalFlowId={approvalFlowId}
              openEditModal={toggleEditModal}
              setApprovalFlowId={setApprovalFlowId}
            />
          )}
          {contentType === 'assignApproval' && (
            <AssignLeaveApprovalFlow
              closeModal={() => setModalType({ showModal: false, contentType: '' })}
              leavePolicies={leavePolicies}
              approvalFlowId={approvalFlowId}
            />
          )}
          {contentType === 'createWorkSchedule' && (
            <CreateWorkSchedule
              closeModal={() => setModalType({ showModal: false, contentType: '' })}
              setEditing={setEditingWorkScheduleModal}
            />
          )}
        </SimpleModal>

        <FullModal showModal={showEditApproval} noHeader padding={'0px'}>
          <EditApprovalFlow closeModal={toggleEditModal} approvalFlowId={approvalFlowId} />
        </FullModal>
      </ModalWrapper>
    </>
  );
}
