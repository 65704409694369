import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Chart, registerables } from 'chart.js';
import { FETCH_DEPARTMENT_REPORT } from 'query';

import { EmptyState } from 'components';
import { CustomButton, ModalBackButton, SpinnerComponent } from 'components';

import arrowDown from '../../../../assets/images/arrowdown.svg';

import { exportCsv, getCsv, getDeptFreq, getLabelColors, modifyDeptCsv } from './helpers';

Chart.register(...(registerables ?? []));

export function DepartmentProfile() {
  const { data: deptData, loading } = useQuery(FETCH_DEPARTMENT_REPORT);
  const navigate = useNavigate();

  const canvasRef = React.createRef();
  const ctxRef = React.createRef();
  const chartRef = React.createRef();
  const chartData = React.useMemo(() => {
    const data = [];
    const labels = [];
    const colors = [];
    Object.entries(getDeptFreq(deptData?.retrieveDepartmentReport?.data)).forEach(
      ([label, value]) => {
        data.push(value);
        labels.push(label);
        colors.push(getLabelColors(deptData?.retrieveDepartmentReport?.data)[label]);
      }
    );
    return { data, labels, colors };
  }, [deptData]);

  function mountChart() {
    const canvasEl = canvasRef.current;
    if (!ctxRef.current) ctxRef.current = canvasEl.getContext('2d');
    chartRef.current?.destroy();
    chartRef.current = new Chart(ctxRef.current, {
      type: 'pie',
      options: {
        plugins: {
          legend: { display: true },
        },
      },
      data: {
        labels: chartData.labels,
        datasets: [
          {
            data: chartData.data,
            backgroundColor: chartData.colors,
            borderWidth: 0,
          },
        ],
        hoverOffset: 4,
      },
    });
  }

  React.useEffect(() => {
    const canvasEl = canvasRef.current;
    if (!canvasEl) return;
    try {
      mountChart();
    } catch {
      mountChart();
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, [deptData]);
  return (
    <Wrapper>
      <Flex
        border={'solid 1px var(--grey-150)'}
        alignItems={'center'}
        borderRadius={'4px'}
        gap={'24px'}
        p={'24px'}
        backgroundColor={'var(--white)'}>
        <ModalBackButton
          onClick={() => navigate('/admin/reports')}
          data-testid="modal-back-button"
        />
        <Heading className="header-text">Department Profile </Heading>
      </Flex>
      {deptData?.retrieveDepartmentReport?.data.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Flex justifyContent="flex-end" marginBottom="24px" marginTop="24px">
            <CustomButton
              srcRight={arrowDown}
              name={'Export'}
              mW={'122px'}
              h={'48px'}
              onClick={() => {
                exportCsv(
                  getCsv(modifyDeptCsv, getDeptFreq(deptData?.retrieveDepartmentReport?.data)),
                  'departmentProfile'
                );
              }}
            />
          </Flex>
          {loading ? (
            <Grid placeItems={'center'} h={'500px'}>
              <SpinnerComponent />
            </Grid>
          ) : (
            <Stack
              backgroundColor={'var(--white)'}
              border={'solid 1px var(--grey-150)'}
              gap={'24px'}
              borderRadius={'4px'}
              p={'24px'}>
              <Text>
                This report shows the distribution of employees across department in your company
              </Text>

              <Flex flexDir={'column'} gap={'36px'} justifyContent={'center'}>
                <Grid placeItems={'center'}>
                  <Box minW={'440px'}>
                    <canvas id="deptProfile-chart" ref={canvasRef}></canvas>
                  </Box>
                </Grid>
              </Flex>
            </Stack>
          )}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 24px;
`;
