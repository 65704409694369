import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { DELETE_TEAM, FETCH_ALL_TEAMS } from 'query';
import { getGraphQLResponse, handleGraphQLResponse } from 'utils';

import { CustomButton, DeletePromptModal, TableComponent } from 'components';

import { useToastify } from '../../../../../hooks/useToastify';
import { DepartmentWrapper } from '../Departments/styles';
import { Modal } from '../RolesPermissions/Modal';

import { AddTeams } from './AddTeams';
import { EditTeams } from './EditTeams';
import { columns } from './tableConfig';

export const Teams = () => {
  const [teamsData, setData] = React.useState([]);
  const [teamName, setTeamName] = React.useState('');
  const [id, setId] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const initialState = { showModal: false, contentType: '' };
  const { successToast, errorToast } = useToastify();

  function simpleModalreducer(state, action) {
    return { ...state, ...action };
  }

  const [{ showModal, contentType }, setModalType] = React.useReducer(
    simpleModalreducer,
    () => initialState
  );

  const { data, loading, refetch } = useQuery(FETCH_ALL_TEAMS);
  React.useEffect(() => {
    const fetchedData = data?.fetchTeams?.data;
    setData(fetchedData);
  }, [data]);

  const [deleteTeam, { loading: deleteLoading }] = useMutation(DELETE_TEAM, {
    refetchQueries: [
      {
        query: FETCH_ALL_TEAMS,
      },
    ],
    onCompleted: onMutationCompleted,
    onError: onMutationError,
  });

  const onDeleteTeam = async () => {
    await deleteTeam({
      variables: {
        filter: {
          _id: id,
        },
      },
    });
  };

  function onMutationCompleted(res) {
    handleGraphQLResponse(res);
    const responseObject = getGraphQLResponse(res);
    successToast(responseObject.message);
    setShowDelete(false);
  }
  function onMutationError(error) {
    errorToast(error?.message);
  }

  const tableData = teamsData?.map(({ name, members, createdAt }) => {
    return {
      col1: name || null,
      col2: members,
      col3: format(parseISO(createdAt), 'MMMM dd, yyyy') || null,
    };
  });

  const performAction = (data) => {
    switch (data.action) {
      case 'edit':
        setId(data?.id);
        setTeamName(data?.email);
        setModalType({ showModal: true, contentType: 'editTeams' });
        break;
      case 'delete':
        setId(data?.id);
        setShowDelete(true);
        break;

      default:
        break;
    }
  };

  const closeModal = () => {
    setModalType({ showModal: false, contentType: '' });
  };

  return (
    <>
      <DepartmentWrapper data-testid="departments">
        <div className="header-content">
          <h1>Teams</h1>
          <CustomButton
            onClick={() => setModalType({ showModal: true, contentType: 'addTeams' })}
            name="Add team"
            variant={'primary'}
            mW="16rem"
            h="4.4rem"
            testId="add-department-btn"
          />
        </div>
        <Modal show={showModal} setShow={closeModal} data-testid="AddTeam">
          {contentType === 'addTeams' && <AddTeams closeModal={closeModal} refetch={refetch} />}
          {contentType === 'editTeams' && (
            <EditTeams closeModal={closeModal} refetch={refetch} name={teamName} id={id} />
          )}
        </Modal>

        <div className="departments">
          <TableComponent
            noCheckbox
            noSort
            data={tableData}
            columns={columns}
            ids={teamsData?.map((e) => e._id)}
            emails={teamsData?.map((e) => e.name)}
            dropdownActions={{ edit: 'Edit', delete: 'Delete' }}
            actionOnData={performAction}
            empty={teamsData?.length === 0}
            loading={loading}
          />
        </div>
      </DepartmentWrapper>

      <DeletePromptModal
        show={showDelete}
        cancel={() => setShowDelete(false)}
        loading={deleteLoading}
        deleteItem={onDeleteTeam}
      />
    </>
  );
};
