import React from 'react';
import styled from '@emotion/styled';

export const JobsBoard = (props) => {
  return (
    <Wrapper>
      <header className="header">
        <h1 className="applied">{props?.title}</h1>
        <p className="welcome">{props?.subtitle}</p>
      </header>

      {props.children && <main className="main">{props.children}</main>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .applied {
    color: var(--black);
    font-size: 24px;
    font-weight: 800;
    line-height: normal;
  }
  .welcome {
    color: var(--grey-500);
    font-size: 16px;
    line-height: normal;
  }
  .main {
    background: var(--bg);
    min-height: 20vh;
    padding: 38px;
    display: flex;
    flex-direction: column;
    gap: 12px 0px;
  }

  @media only screen and (max-width: 664px) {
    .main {
      padding: 29px 12px;
    }
  }
`;
