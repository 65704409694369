import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { UnreadChatsProvider } from 'context/unreadChats';

import { theme } from './styles/theme';
import { MenuContextProvider } from './context';
import { client } from './query';
import { AppRoutes } from './routes';

const queryClient = new QueryClient();

function App() {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <MenuContextProvider>
            <UnreadChatsProvider>
              <DndProvider backend={HTML5Backend}>
                <AppRoutes />
              </DndProvider>
            </UnreadChatsProvider>
          </MenuContextProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default App;
