import { gql } from '@apollo/client';

export const CREATE_LEAVE = gql`
  mutation createLeaveRequest($data: LeaveRequestInput!) {
    createLeaveRequest(data: $data) {
      status
      message
      data {
        _id
        employeeId {
          _id
        }
        leaveTypeId {
          _id
        }
        leavePolicyId {
          _id
        }
        description
        startDate
        endDate
        leaveStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const FETCH_EMPLOYEE_LEAVE = gql`
  query fetchUserLeaveRequests {
    fetchUserLeaveRequests {
      status
      message
      data {
        _id
        description
        leaveTypeId {
          _id
          name
        }
        durationType
        leaveStatus
        startDate
        endDate
        count
      }
    }
  }
`;

export const FETCH_LEAVE_HISTORY = gql`
  query fetchUserLeaveTypes {
    fetchUserLeaveTypes {
      status
      message
      data {
        entitled
        leaveTypeId {
          name
        }
        nextLeaveDate {
          startDate
          endDate
        }
        totalBalance
        spent
        requested
        year
        durationType
      }
    }
  }
`;

export const DELETE_LEAVE = gql`
  mutation deleteLeaveRequest($leaveId: ObjectID!) {
    deleteLeaveRequest(leaveId: $leaveId) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const EMPLOYEE_LEAVE_SUMMARY = gql`
  query fetchLeaveSummary($filters: LeaveSummaryFilter, $leaveTypeId: ObjectID!) {
    fetchLeaveSummary(filters: $filters, leaveTypeId: $leaveTypeId) {
      status
      message
      data {
        balanceBefore
        count
        requests {
          _id
          employeeId {
            _id
            firstName
            lastName
            imageUrl
          }
        }
      }
    }
  }
`;
