/* eslint-disable no-unused-vars */
import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { getItem, logout } from '../utils';

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_HRM_V3_API_BASE_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const wsLink = new WebSocketLink(
  new SubscriptionClient(
    `${process.env.REACT_APP_HRM_V3_API_BASE_URL}/graphql`.replace(/http|https/, 'ws'),
    {
      connectionParams: {
        authToken: getItem('token') ? `Bearer ${getItem('token')}` : '',
      },
    }
  )
);

// const wsLink = new GraphQLWsLink(
//   createClient({
//     url: `${process.env.REACT_APP_HRM_V3_API_BASE_URL}/graphql`.replace(/http|https/, 'ws'),
//     connectionParams: {
//       authToken: getItem('token') ? `Bearer ${getItem('token')}` : '',
//     },
//   })
// );

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      // const oldHeaders = operation.getContext().headers;
      switch (err.extensions?.code) {
        // Apollo Server sets code to UNAUTHENTICATED

        // when an AuthenticationError is thrown in a resolver

        case 'UNAUTHENTICATED':
          // Modify the operation context with a new token

          // operation.setContext({
          //   headers: {
          //     ...oldHeaders,

          //     authorization: getNewToken(),
          //   },
          // });

          // Retry the request, returning the new observable

          return forward(operation);
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink

  // instead of the onError link. This just logs the error.

  if (networkError) {
    if (networkError.statusCode === 401) {
      logout();
      location.pathname = '/login';
    }
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(errorLink).concat(httpLink)
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});
