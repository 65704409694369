import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { ADD_EMPLOYEE, FETCH_ALL_EMPLOYEES } from 'query';

function useSubmitForm(props) {
  const { currencyCode, kincountryCode, countryCode, closeModal } = props;
  const [loading, setLoading] = React.useState(false);

  const [addEmployee] = useMutation(ADD_EMPLOYEE);

  const { refetch } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      page: 1,
      filters: { isDefaultAdmin: false },
    },
  });

  const { successToast, errorToast } = useToastify();

  const onSubmit = async (data) => {
    const date = data.dob ? new Date(data.dob) : '';
    const dob = date ? date.toISOString() : '';
    const hire_date = data.employmentInfo.hireDate
      ? new Date(data.employmentInfo.hireDate).toISOString()
      : '';
    const new_phone_number = data.phoneNumber ? `${countryCode.dialCode}${data.phoneNumber}` : '';
    const monthly_pay_rate = data.employmentInfo.monthlyPayRate
      ? `${currencyCode.currency}${data.employmentInfo.monthlyPayRate}`
      : '';
    const kin_phone_number = data?.emergencyContact?.phoneNumber
      ? `${kincountryCode.dialCode}${data.emergencyContact.phoneNumber}`
      : '';

    const addressToSend =
      data.address !== []
        ? data?.address
            .filter((add) => add.address !== '')
            .map(({ country, state, ...rest }) => ({
              country: country?.value,
              state: state?.value,
              ...rest,
            }))
        : [];

    const newData = {
      firstName: data.firstName,
      lastName: data.lastName,
      location: data.location,
      gender: data.gender.value || '',
      maritalStatus: data.maritalStatus.value || '',
      workLocation: data.workLocation.value || '',
      dob: dob,
      email: data.email,
      staffId: data.staffId,
      phoneNumber: new_phone_number || '',
      department: data.department.value || '',
      bloodGroup: data.bloodGroup.value || '',
      bloodType: data.bloodType.value || '',
      designation: data.designation.value || '',
      team: data.team || null,
      employmentInfo: {
        compensationTypes: data.employmentInfo.compensationTypes.value || '',
        employeeBenefits: [],
        employmentType: data.employmentInfo.employmentType.value || '',
        hireDate: hire_date,
        workEmail: data.employmentInfo.workEmail,
        monthlyPayRate: monthly_pay_rate || '',
      },
      bankAccount: {
        accountType: data.bankAccount.accountType.value || '',
        paymentType: data.bankAccount.paymentType.value || '',
        bankName: data.bankAccount.bankName || '',
        accountNo: data.bankAccount.accountNo || '',
        pensionAccountNo: data.bankAccount.pensionAccountNo || '',
        pensionFundAdministrator: data.bankAccount.pensionFundAdministrator || '',
        accountName: data.bankAccount.accountName || '',
      },
      emergencyContact: {
        fullName: data?.emergencyContact?.fullName || '',
        relationship: data?.emergencyContact?.relationship || '',
        occupation: data?.emergencyContact?.occupation || '',
        email: data?.emergencyContact?.email || '',
        country: data?.emergencyContact?.country.value || '',
        state: data?.emergencyContact?.state.value || '',
        city: data?.emergencyContact?.city.value || '',
        phoneNumber: kin_phone_number || '',
        address: data?.emergencyContact?.address || '',
      },
      address: addressToSend || [],
    };
    const dataToSend =
      data.isDisabled.value !== ''
        ? { ...newData, isDisabled: data.isDisabled.value }
        : { ...newData };

    setLoading(true);

    try {
      const res = await addEmployee({
        variables: { data: dataToSend },
        refetchQueries: [
          {
            query: FETCH_ALL_EMPLOYEES,
            variables: {
              page: 1,
              filters: {},
            },
          },
        ],
      });
      if (res?.data?.addNewEmployee?.status === 201) {
        const message = res?.data?.addNewEmployee?.message;
        successToast(message);
      } else if (res?.data?.addNewEmployee?.status === 409) {
        const message = res?.data?.addNewEmployee?.message;
        errorToast(message);
      }
    } catch (error) {
      errorToast(error?.message || 'Error occured');
    } finally {
      setLoading(false);
      refetch();
      closeModal();
    }
  };

  return {
    onSubmit,
    loading,
  };
}

export { useSubmitForm };
