import { gql } from '@apollo/client';

export const UPDATE_APPLICANT_PROFILE = gql`
  mutation updateApplicantProfile($data: EditApplicant) {
    updateApplicantProfile(data: $data) {
      status
      message
      data {
        _id
        fullName
        email
        imageUrl
        dob
        address
        alternateEmail
      }
    }
  }
`;

export const FETCH_APPLICANT = gql`
  query fetchApplicant {
    fetchApplicant {
      status
      message
      data {
        _id
        fullName
        email
        imageUrl
        dob
        address
        alternateEmail
      }
    }
  }
`;

export const FETCH_APPLICANT_DETAILS = gql`
  query fetchApplicantApplications($filter: ApplicantFilter) {
    fetchApplicantApplications(filter: $filter) {
      status
      message
      data {
        jobId {
          _id
          title
          country
          type
          location
          assessmentId {
            _id
            deadline
            submitted
          }
        }
        stage {
          title
          rank
        }
        status
        createdAt
      }
    }
  }
`;
