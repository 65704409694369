import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

const PaginationRoot = styled.div`
  --control-hover-color: #0d0f111a;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: white;

  font-size: 12px;
  line-height: 16px;

  .display {
    margin-top: 2px;
  }

  .controls {
    display: grid;
    grid: 1fr / auto-flow max-content;
    gap: 4px;
  }

  .page-controls {
    display: grid;
    grid: 1fr / auto-flow max-content;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }

  .page-controls > * {
    box-sizing: border-box;
    height: 24px;
    width: 32px;
  }

  .page-control {
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 6px 12px 4px;
    font-size: 12px;
    line-height: 1;
    cursor: pointer;

    &.active {
      background: var(--control-hover-color);
      cursor: not-allowed;
    }
  }

  button {
    background: white;
    border-radius: 4px;
    padding: 4px 8px;
    border: none;
    cursor: pointer;
  }

  button:hover,
  .page-control:hover {
    background: var(--control-hover-color);
  }

  button:disabled {
    opacity: 0.5;
    background: transparent;
  }
`;

function Pagination(props) {
  const { page, setPage, totalPages } = props;

  const pages = React.useMemo(() => {
    const first = 1;
    const last = totalPages !== 1 ? [totalPages] : [];
    const middle = generateMiddle({ page, totalPages });
    const { afterDots, beforeDots } = generateDots({ middle, totalPages });

    return [first, ...beforeDots, ...middle, ...afterDots, ...last];
  }, [page, totalPages]);

  const handleNextPageClick = () => setPage(page + 1);
  const handlePrevPageClick = () => setPage(page - 1);
  const disableNext = page === totalPages;
  const disablePrev = page === 1;

  return (
    <PaginationRoot>
      <div className="display">
        Showing page {page} of {totalPages}
      </div>

      <div className="controls">
        <button disabled={disablePrev} onClick={handlePrevPageClick}>
          Prev
        </button>
        <div className="page-controls">
          {pages.map((p, i) => (
            <Flex key={i}>
              {p === 'dots' && <Dots key={i} />}
              {p !== 'dots' && (
                <button key={i} onClick={() => setPage(p)} className={controlClass({ page, p })}>
                  {p}
                </button>
              )}
            </Flex>
          ))}
        </div>
        <button disabled={disableNext} onClick={handleNextPageClick}>
          Next
        </button>
      </div>
    </PaginationRoot>
  );
}

function controlClass({ page, p }) {
  return `page-control ${page === p ? 'active' : ''}`;
}

function generateMiddle({ page, totalPages }) {
  let middle = [];

  for (let i = page - 2; i <= page; i++) {
    if (i >= 2 && i !== totalPages) {
      middle.push(i);
    }
  }

  for (let i = page + 1; i < page + 3; i++) {
    if (i < totalPages) {
      middle.push(i);
    }
  }

  return middle;
}

function generateDots({ middle, totalPages }) {
  const middleLen = middle.length;
  let beforeDots = [],
    afterDots = [];
  if (middle[0] > 2) {
    beforeDots = ['dots'];
  }

  if (middle[middleLen - 1] < totalPages - 1) {
    afterDots = ['dots'];
  }

  return { beforeDots, afterDots };
}

function Dots() {
  return (
    <div style={{ display: 'grid', placeContent: 'center' }}>
      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM9 0C7.9 0 7 0.9 7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0Z"
          fill="#677684"
        />
      </svg>
    </div>
  );
}

export { Pagination };
