import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { useUnreadChatsContext } from 'context/unreadChats';

import { useToastify } from '../../hooks/useToastify';
import { FETCH_EXISTING_CONVO, SUBSCRIBE_TO_MESSAGES } from '../../query';
import { getItem } from '../../utils';

import { getChatName } from './components/ChatDetail';
// import { getFrom, getLatestMessage } from './helpers';

export function GeneralMessageSubscription() {
  // const [convoList, setConvoList] = React.useState(() => []);
  // const loading = React.useRef(true);
  const userDetails = getItem('hrm_user');
  const location = useLocation();
  const { infoToast } = useToastify();
  const { data } = useSubscription(SUBSCRIBE_TO_MESSAGES, {
    variables: {
      receipientId: userDetails?._id,
    },
  });
  const [fetchConversations] = useLazyQuery(FETCH_EXISTING_CONVO, {
    fetchPolicy: 'cache-and-network',
  });
  const { setChats } = useUnreadChatsContext();

  async function getConversationList() {
    try {
      // if (first) loading.current = true;
      const res = await fetchConversations();
      let unread = 0;

      // setConvoList((prev) => {
      //   const convoIDs = first ? prev.map((c) => c._id) : [];
      //   const newList = res.data?.fetchExistingConversations?.data
      //     .filter((c) => !convoIDs.includes(c._id))
      //     .map((c) => ({
      //       ...c,
      //       from: getFrom(c?.participants, userDetails?._id),
      //       latest_message: getLatestMessage(c.messages),
      //     }));
      //   return [...(newList || []), ...(first ? prev : [])];
      // });
      // convoList.forEach((chat) => (unread += chat.unread));
      res?.data?.fetchExistingConversations?.data.forEach((chat) => (unread += chat.unread));
      setChats(unread);
      unread = 0;
    } finally {
      // if (first) loading.current = false;
    }
  }

  function requestNotification() {
    Notification.requestPermission();
  }

  function notify(message) {
    if ('Notification' in window && Notification.permission === 'granted') {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      new Notification('New message', { body: message });
    }
  }

  React.useEffect(() => {
    getConversationList();
    // return () => setConvoList([]);
  }, []);

  React.useEffect(() => {
    const reqFunction = () => {
      if ('Notification' in window && Notification.permission !== 'granted') {
        requestNotification();
      }
    };
    document.addEventListener('click', reqFunction);
    return () => document.removeEventListener('click', reqFunction);
  }, []);

  React.useEffect(() => {
    if (
      data?.newMessage &&
      data.newMessage.author?._id !== userDetails?._id &&
      !location.pathname.includes('/inbox')
    ) {
      const message = `New message from ${getChatName(data?.newMessage?.author)}`;
      infoToast(message);
      notify(message);
      getConversationList();
    }
    // return () => {
    //   setConvoList([]);
    // };
  }, [data]);

  return <Box></Box>;
}
