import React from 'react';
import styled from '@emotion/styled';

import close from '../../../../assets/icons/close.svg';
import { Button } from '../button';
import { InputBox } from '../inputbox';

const Container = styled.div`
  box-sizing: border-box;
  width: inherit;
  background: #ffffff;
  border-radius: 4px;
  padding: 0 7px;
  margin: 0 0 24px;
  z-index: 200;

  .editnote-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    padding: 24px;

    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 12px;
      color: #242424;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

const EditNote = (props) => {
  return (
    props.isOpen && (
      <Container style={props.style}>
        <div className="editnote-head">
          <h2>{props.heading}</h2>
          <div className="buttons">
            <Button dataTestId="ondelete" img={close} onClick={() => props.onClose()} />
          </div>
        </div>

        <Fields>
          <InputBox
            dataTestId={'title'}
            id={'title'}
            handleChange={props.handleChange}
            label={'Title'}
            placeholder="Add a title or subject"
            value={props.value?.title}
          />
          <InputBox
            id={'body'}
            dataTestId={'body'}
            handleChange={props.handleChange}
            label={'Body of Message'}
            placeholder="Build a Startup Website Design about Covid-19 with Responsive"
            value={props.value?.body}
          />
          <div className="buttons">
            <Button
              dataTestId="onclose"
              style={{
                padding: '12px 40px',
                background: '#FFFFFF',
                border: '1px solid #F2F2F2',
                borderRadius: '4px',
              }}
              title="Cancel"
              onClick={() => props.onClose()}
            />
            <Button
              dataTestId="onsave"
              style={{
                padding: '12px 40px',
                background: '#3E7EEE',
                color: '#F8F7FF',
                borderRadius: '4px',
              }}
              title="Add Note"
              isDisabled={props.isDisabled}
              onClick={() => props.onSave()}
              isLoading={props.isLoading}
            />
          </div>
        </Fields>
      </Container>
    )
  );
};

export { EditNote };
