import React from 'react';
import { useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
// import DP from 'assets/images/dp2.png';
import { UserImage } from 'pages/admin/employee/tableConfig';
import { FETCH_SINGLE_APPLICANT } from 'query/admin/candidates';

import {
  CustomButton,
  // InputBox,
  SimpleModal,
  SpinnerComponent,
  StageIndicator,
} from 'components';
import { Tabs } from 'components/shared/tabs';

import { CandidateDocuments } from './Tabs/CandidateDocuments';
import { CandidateHiringPipeLine } from './Tabs/CandidateHiringPipeline';
import { CandidateInterview } from './Tabs/CandidateInterview';
import { Details } from './Tabs/Details';
import { useCandidatesInfo } from './hook';

export const CandidateInfo = ({ openModal, userId, stages, setOpenModal }) => {
  const { tabs, setSelected, selectedButton } = useCandidatesInfo();

  const { data, loading, refetch } = useQuery(FETCH_SINGLE_APPLICANT, {
    variables: {
      filter: {
        applicationId: userId,
      },
    },
  });

  const user = data?.fetchSingleApplication?.data;

  React.useEffect(() => {
    refetch();
  }, [data]);
  // if (loading) return <SpinnerComponent />;

  function toggleModal() {
    setOpenModal(!openModal);
  }
  return (
    <>
      <SimpleModal showModal={openModal} setShowModal={toggleModal} width={'772px'}>
        <Wrapper>
          {loading ? (
            <Grid h={'650px'} w={'inherit'} placeItems={'center'}>
              <SpinnerComponent />
            </Grid>
          ) : (
            <div>
              <Header>
                <Profile>
                  <div className="profile-left">
                    {UserImage({
                      imageUrl: user?.imageUrl,
                      firstName: user?.firstName,
                      lastName: user?.lastName,
                    })}
                  </div>
                  <div className="profile-right">
                    <p className="candidate-name">
                      {user?.firstName} {user?.lastName}
                    </p>
                    <Flex className="candidate-role">
                      <p className="candidate-team">{user?.jobId?.department?.name}</p>
                      <p>•</p>
                      <p className="candidate-applied-role">{user?.jobId?.title}</p>
                    </Flex>
                    <StageIndicator
                      items={{
                        value: user?.stage?.rank || 1,
                        label: user?.stage?.title || 'New Applied',
                      }}
                      rank={user?.stage?.rank || 1}
                      stages={stages}
                    />
                  </div>
                </Profile>
                <div className="header-buttons">
                  <CustomButton
                    variant={'black'}
                    name={'Send Email'}
                    mW={'12.1rem'}
                    h={'4.3rem'}
                    onClick={() => (window.location = `mailto:${user?.email}`)}
                  />
                </div>
              </Header>
              <Main>
                <TabHeader>
                  <Tabs tabs={tabs} setSelected={setSelected} selected={selectedButton} />
                </TabHeader>
                <Content>
                  {selectedButton === 'details' && <Details user={user} />}
                  {selectedButton === 'hiringPipeline' && (
                    <CandidateHiringPipeLine user={user} stages={stages?.fetchStages?.data} />
                  )}
                  {selectedButton === 'documents' && <CandidateDocuments user={user} />}
                  {selectedButton === 'interview' && <CandidateInterview user={user} />}
                </Content>
              </Main>
              <div className="close-btn">
                <CustomButton
                  name={'Close'}
                  variant={'black'}
                  mW={'12.1rem'}
                  h={'4.3rem'}
                  onClick={() => setOpenModal(false)}
                />
              </div>
            </div>
          )}
        </Wrapper>
      </SimpleModal>
    </>
  );
};

const Header = styled.div`
  background: #fafcff;
  padding: 40px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-buttons {
    display: flex;
    gap: 8px;
  }
`;

const Profile = styled.div`
  display: flex;
  gap: 8px;

  .profile-left {
    border-radius: 50%;
    width: 64px;
    aspect-ratio: 1;

    img {
      width: 64px;
      aspect-ratio: 1;
    }
  }

  .profile-right {
    display: flex;
    flex-direction: column;

    .candidate-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #23273b;
    }

    .candidate-role {
      gap: 4px;
    }

    .candidate-role p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #83889d;
    }
  }
`;

const Main = styled.div`
  padding: 2.4rem;
`;
const Content = styled.div`
  width: 100%;
  height: auto;
  padding-top: 2.4rem;
`;

const Wrapper = styled.div`
  min-height: 872px;

  .close-btn {
    display: flex;
    justify-content: flex-end;
    padding: 2.4rem;
  }
`;

const TabHeader = styled.div`
  border-bottom: 1px solid var(--grey-150);
  position: sticky;
  z-index: 2;
  .main-heading {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 600;
    color: #23273b;
    margin-bottom: 2.4rem;
    text-transform: uppercase;
  }

  .tab-btn-toggle {
    :hover {
      border-bottom: 1px solid #3e7eee;
    }
  }
`;
