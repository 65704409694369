import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const TopPanel = styled(Grid)`
  width: 100%;
  height: auto;
  border: 1px solid #edf2f7;
  border-radius: 4px;
  padding: 2.4rem;
  grid: 1fr / max-content 1fr;
  grid-gap: 2.4rem;
  background: #23273b;
  p {
    color: #fff;
  }
  label {
    color: #fafcff;
  }

  .profile__info {
    grid: 1fr 1fr / repeat(auto-fill, minmax(18rem, auto));
    grid-gap: 1.6rem;
    p {
      font-weight: 500;
    }
    @media (min-width: 1200px) {
      grid: 1fr 1fr / repeat(4, 1fr);
    }
  }
`;
export const LowerPanel = styled(Grid)`
  width: 100%;
  height: auto;
  padding: 2.4rem;
  grid: 1fr / repeat(auto-fill, minmax(36rem, auto));
  grid-gap: 3.2rem;
  background: #fafcff;
  margin-top: 2.4rem;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  .left__column {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
  }
  .right__column {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
  }

  .employee__info {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    // grid: 1fr / 1fr 1fr;
    max-width: 104rem;
    margin: 2.4rem auto 0;
  }
`;

export const activeStatus = {
  color: '#36CA8B',
  background: 'rgba(54, 202, 139, 0.1)',
  border: '1px solid #36CA8B',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  borderRadius: '0.4rem',
  fontSize: '1.2rem',
};
export const notActiveStatus = {
  color: '#FF5252',
  background: 'rgba(255, 82, 82, 0.1)',
  border: '1px solid #FF5252',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  borderRadius: '0.4rem',
  fontSize: '1.2rem',
};
export const pendingStatus = {
  color: 'var(--grey-700)',
  background: 'var(--grey-100)',
  border: '1px solid var(--grey-700)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  borderRadius: '0.4rem',
  fontSize: '1.2rem',
};
export const unverifiedStatus = {
  color: '#F0A800',
  background: 'rgba(240, 168, 0, 0.1)',
  border: '1px solid #F0A800',
  width: 'max-content',
  padding: '0.6rem 1rem',
  borderRadius: '0.4rem',
  fontSize: '1.2rem',
};

export const MainWrapper = styled(Box)`
  padding: 2rem 2.4rem;
  height: calc(100vh);
`;
export const SearchContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-bottom: 2.4rem;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;
export const Stroke = styled(Box)`
  border: 1px solid #bcbfca;
  height: 2rem;
`;

export const activeStatus2 = {
  ...activeStatus,
  padding: '0.2rem 0.6rem',
};
export const notActiveStatus2 = {
  ...notActiveStatus,
  padding: '0.2rem 0.6rem',
};
export const unverifiedStatus2 = {
  ...unverifiedStatus,
  padding: '0.2rem 0.6rem',
};

export const StatusType = styled(Flex)`
  ${({ status }) =>
    status === 'active' || status === 'approved' || status === 'accepted' || status === 'spent'
      ? activeStatus
      : null}
  ${({ status }) =>
    status === 'suspended' || status === 'rejected' || status === 'exited' ? notActiveStatus : null}
${({ status }) => (status === 'Unverified' || status === 'awaitingHR' ? unverifiedStatus : null)};
  ${({ status }) => (status === 'pending' ? pendingStatus : null)};
`;

export const StatusType2 = styled(Flex)`
  ${({ status }) => (status === 'active' ? activeStatus2 : null)}
  ${({ status }) => (status === 'suspended' || status === 'exited' ? notActiveStatus2 : null)}
  ${({ status }) => (status === 'Unverified' ? unverifiedStatus2 : null)}
`;

export const MainHeading = styled(Text)`
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 600;
  color: #23273b;
  margin-bottom: 0.6rem;
`;
export const SubHeading = styled(Text)`
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 400;
  color: #999fa3;
`;
export const ESubHeading = styled(Text)`
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: #23273b;
`;
export const HeaderContentWrapper = styled(Flex)`
  margin-bottom: 1.6rem;
  justify-content: space-between;
  align-items: center;
`;
export const OnbTypeWrapper = styled(Flex)`
  width: 16rem;
  height: 16rem;
  margin-bottom: 2.4rem;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.4rem;
  align-items: center;
  border: 1px solid #f2f2f2;
  :hover {
    border: 1px solid rgba(62, 126, 238, 0.3);
  }
`;
