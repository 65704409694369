import { Box } from '@chakra-ui/react';

function JobInputLabel(props) {
  return (
    <Box fontSize="1.4rem" lineHeight="1.6rem" fontWeight="600" mb="8px">
      {props.children}
    </Box>
  );
}

export { JobInputLabel };
