import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { getItem } from '../utils';

function EmployeePrivateRoute({ authRoute = '/employee/login', children }) {
  const token = getItem('token');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function redirect() {
    navigate(authRoute);
  }

  React.useEffect(() => {
    try {
      const decoded = jwtDecode(token);
      // check if admin is accessing employee route and send them back to admin
      if (decoded.isDefaultAdmin) {
        navigate('/admin');
      }
    } catch (error) {
      redirect();
    }
  }, [pathname, token]);
  return <>{children}</>;
}

export default EmployeePrivateRoute;
