import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  span {
    text-transform: capitalize;
    color: #3e7eee;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: -0.117188px;

    /* Black - HRMs */

    color: #333758;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: -0.117188px;

    /* Black - HRMs */

    color: #333758;
    margin-top: 8px;
  }
`;
const Greeter = (props) => {
  return (
    <Container>
      {props?.user && (
        <div>
          <h1>
            Welcome <span>{props.user?.fullName?.split(' ')[0]}!</span>
          </h1>
          <p>Here the details for your Assessment.</p>
        </div>
      )}
    </Container>
  );
};

export { Greeter };
