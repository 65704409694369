import { gql } from '@apollo/client';

export const FETCH_SINGLE_LEAVE_REQUEST = gql`
  query fetchSingleRequest($leaveId: ObjectID!) {
    fetchSingleRequest(leaveId: $leaveId) {
      status
      message
      data {
        _id
        description
        leaveStatus
        startDate
        endDate
        balance
        count
        durationType
        employeeId {
          _id
          firstName
          lastName
        }
        leaveTypeId {
          name
        }
        reasonForDenial
        approvalFlow {
          user {
            _id
            firstName
            lastName
          }
          activity
          level
          date
          status
        }
        overlappingRequests {
          _id
          description
          createdAt
          endDate
          startDate
          leaveStatus
          leavePolicyId {
            _id
          }
          leaveTypeId {
            _id
            name
          }
          employeeId {
            firstName
            lastName
            imageUrl
          }
        }
      }
    }
  }
`;

export const FETCH_ALL_LEAVE_REQUEST = gql`
  query fetchLeaveRequests($filter: LeaveRequestFilters, $page: Int) {
    fetchLeaveRequests(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        leaveStatus
        startDate
        endDate
        count
        description
        durationType
        employeeId {
          _id
          firstName
          lastName
          imageUrl
        }
        leaveTypeId {
          _id
          name
        }
      }
    }
  }
`;

export const EDIT_LEAVE = gql`
  mutation editLeaveRequest($filters: LeaveRequestFilters, $data: EditLeaveRequestInput!) {
    editLeaveRequest(filters: $filters, data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const DELETE_LEAVE_REQUEST = gql`
  mutation deleteLeaveRequest($leaveId: ObjectID!) {
    deleteLeaveRequest(leaveId: $leaveId) {
      status
      message
    }
  }
`;

export const UPDATE_LEAVE = gql`
  mutation leaveRequestApproval($filters: LeaveRequestFilters, $data: LeaveRequestApprovalInput!) {
    leaveRequestApproval(filters: $filters, data: $data) {
      status
      message
      data {
        _id
        reasonForDenial
        employeeId {
          _id
        }
        leaveTypeId {
          _id
        }
        leavePolicyId {
          _id
        }
        description
        approvalFlow {
          user {
            _id
            firstName
            lastName
            middleName
          }
          date
          activity
          level
        }
        startDate
        endDate
        leaveStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const FORCE_UPDATE = gql`
  mutation forceApproveOrDenyLeaveRequest(
    $filters: LeaveRequestFilters
    $data: LeaveRequestApprovalInput!
  ) {
    forceApproveOrDenyLeaveRequest(filters: $filters, data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;
