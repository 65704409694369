import { INPUT_TYPES } from './constants';

export const createInput = (options = {}) => ({
  type: INPUT_TYPES.TextField,
  name: '',
  required: false,
  multiple: false,
  ...options,
});

export const getDefaultApplicantsForm = () => ({
  fields: [
    createInput({ name: 'First name', required: true }),
    createInput({ name: 'Last name', required: true }),
    createInput({ name: 'Email address', required: true }),
    createInput({ name: 'Phone number', required: true }),
    createInput({ name: 'Gender', options: ['Male', 'Female'], type: INPUT_TYPES.RadioButton }),
    createInput({ name: 'Date of Birth', type: INPUT_TYPES.DatePicker }),
    createInput({ name: 'Resume', type: INPUT_TYPES.Attachment }),
    createInput({
      name: 'Supporting Documents (Cover Letter, Certificate)',
      type: INPUT_TYPES.Attachment,
      multiple: true,
    }),
    createInput({ name: 'School', required: true }),
    createInput({
      name: 'Highest Qualifications',
      type: INPUT_TYPES.Dropdown,
      options: [
        'Junior high certificate',
        'Senior high certificate',
        'Diploma',
        'Bachelor degree',
        'Masters degree',
        'Doctoral degree',
      ],
    }),
    createInput({ name: 'Earliest Start Date?', required: true, type: INPUT_TYPES.DatePicker }),
    createInput({ name: 'Desired Pay', required: true }),
    createInput({ name: 'Portfolio/Website/Github', required: false }),
    createInput({ name: 'LinkedIn URL', required: false, type: INPUT_TYPES.Hyperlink }),
    createInput({
      type: 'Section',
      name: 'Contact Address',
      children: [
        createInput({ name: 'Country', required: true }),
        createInput({ name: 'Zipcode', required: true }),
        createInput({ name: 'State', required: true }),
        createInput({ name: 'City', required: true }),
        createInput({ name: 'Street Address', required: true, type: INPUT_TYPES.TextArea }),
      ],
    }),
    createInput({
      type: 'Section',
      name: 'Reference',
      children: [
        createInput({ name: 'First name', required: false }),
        createInput({ name: 'Last name', required: false }),
        createInput({ name: 'Email address', required: false }),
        createInput({ name: 'Phone number', required: false }),
        createInput({ name: 'City', required: false }),
        createInput({ name: 'State', required: false }),
        createInput({ name: 'Address', required: false, type: INPUT_TYPES.TextArea }),
      ],
    }),
  ],
});

export const getTestDefaultApplicantsForm = () => ({
  fields: [
    createInput({ name: 'First name', required: true }),
    createInput({ name: 'Last name', required: true }),
    createInput({ name: 'Email address', required: true }),
    createInput({ name: 'Phone number', required: true }),
    createInput({
      name: 'Personal Features',
      options: ['Blue Eye', 'Red Hair'],
      type: INPUT_TYPES.Checkbox,
    }),
    createInput({
      name: 'Gender',
      options: ['Male', 'Female'],
      type: INPUT_TYPES.RadioButton,
      isEditing: true,
    }),
    createInput({
      name: 'Guardian Gender',
      options: ['Male', 'Female'],
      type: INPUT_TYPES.RadioButton,
    }),
    createInput({ name: 'Date of Birth', type: INPUT_TYPES.DatePicker }),
    createInput({ name: 'Resume', type: INPUT_TYPES.Attachment, isEditing: true }),
    createInput({
      name: 'Supporting Documents (Cover Letter, Certificate)',
      type: INPUT_TYPES.Attachment,
      multiple: true,
    }),
    createInput({ name: 'School', required: true }),
    createInput({ name: 'Highest Qualifications', type: INPUT_TYPES.Dropdown }),
    createInput({ name: 'Earliest Start Date?', required: true, type: INPUT_TYPES.DatePicker }),
    createInput({ name: 'Desired Pay', required: true }),
    createInput({ name: 'Portfolio/Website/Github', required: true }),
    createInput({ name: 'LinkedIn URL', required: true, type: INPUT_TYPES.Hyperlink }),
    createInput({
      type: 'Section',
      name: 'Contact Address',
      children: [
        createInput({ name: 'Country', required: true }),
        createInput({ name: 'Zipcode', required: true }),
        createInput({ name: 'State', required: true }),
        createInput({ name: 'City', required: true }),
        createInput({ name: 'Street Address', required: true, type: INPUT_TYPES.TextArea }),
      ],
    }),
    createInput({
      type: 'Section',
      name: 'Reference (Optional)',
      children: [
        createInput({ name: 'First name', required: true }),
        createInput({ name: 'Last name', required: true }),
        createInput({ name: 'Email address', required: true }),
        createInput({ name: 'Phone number', required: true }),
        createInput({ name: 'City', required: true }),
        createInput({ name: 'State', required: true }),
        createInput({ name: 'Address', required: true, type: INPUT_TYPES.TextArea }),
      ],
    }),
  ],
});
