import React, { useReducer, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Heading, Stack } from '@chakra-ui/react';
import { FETCH_ANNOUNCEMENT } from 'query';

import { EmptyState, Pagination, SimpleModal, SpinnerComponent } from 'components';
import { Tabs } from 'components/shared/tabs';

import { AnnouncementContentFilter } from './components/AnnouncementContentFilter';
import { AnnouncementLayoutHook } from './components/AnnouncementLayoutHook';
import { AnnouncementType } from './components/AnnouncementType';
import { CreateAnnouncement } from './components/Modals/CreateAnnouncement';
import { SingleAnnouncement } from './components/SingleAnnouncement';
import { modifyFilters } from './helpers';
import { AnnouncementsTypeWrapper, AnnouncementsWrapper, Content, Header } from './styles';

const initialState = { showModal: false, contentType: '' };
function simpleModalreducer(state, action) {
  return { ...state, ...action };
}
export function Annoucements() {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    name: '',
    from: '',
    to: '',
    status: 'published',
  });
  const { tabs, setSelected, selectedButton } = AnnouncementLayoutHook();
  const [{ showModal, contentType }, setModalType] = useReducer(
    simpleModalreducer,
    () => initialState
  );

  const { data, loading } = useQuery(FETCH_ANNOUNCEMENT, {
    variables: {
      filter: {
        type: selectedButton,
        ...modifyFilters(filter),
      },
      page,
    },
  });

  return (
    <>
      <AnnouncementsWrapper>
        <Heading>NEWS FEED</Heading>

        <AnnouncementsTypeWrapper>
          <AnnouncementType
            title={'Annoucement'}
            openModal={() => setModalType({ showModal: true, contentType: 'createAnnouncement' })}
            description={
              'Do you have a message you want to broadcast. You can reach all or Specific Employee through this medium.'
            }
          />
          <AnnouncementType
            openModal={() => setModalType({ showModal: true, contentType: 'createNewsletter' })}
            title={'Newsletter'}
            description={'Write and publish Newsletter, Articles and Editorials'}
          />
          <AnnouncementType
            openModal={() => setModalType({ showModal: true, contentType: 'createInternalMemo' })}
            title={'Internal Memo'}
            description={'Write an internal memo.'}
          />
        </AnnouncementsTypeWrapper>

        <Stack>
          <Header>
            <Tabs tabs={tabs} setSelected={setSelected} selected={selectedButton} />
          </Header>

          <>
            <AnnouncementContentFilter setFilter={setFilter} filter={filter} />
            {loading ? (
              <SpinnerComponent />
            ) : (
              <Stack gap={'8px'}>
                <Content>
                  {data?.fetchAnnouncement?.data.length === 0 && <EmptyState />}
                  {data?.fetchAnnouncement?.data?.map((el) => (
                    <SingleAnnouncement
                      key={el._id}
                      id={el._id}
                      content={el.content}
                      title={el.title}
                      status={el.status}
                      issuedBy={el.issuedBy}
                      date={el.createdAt}
                      type={selectedButton}
                      filter={filter}
                      page={page}
                    />
                  ))}
                </Content>
                <Pagination
                  totalPages={data?.fetchAnnouncement?.pageCount}
                  page={page}
                  setPage={setPage}
                />
              </Stack>
            )}
          </>
        </Stack>
      </AnnouncementsWrapper>

      <SimpleModal
        showModal={showModal}
        setShowModal={() => setModalType({ showModal: false, contentType: '' })}>
        {contentType === 'createAnnouncement' && (
          <CreateAnnouncement
            label={'Announcement'}
            type={'announcement'}
            filter={filter}
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
          />
        )}
        {contentType === 'createNewsletter' && (
          <CreateAnnouncement
            label={'Newsletter'}
            type={'news'}
            filter={filter}
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
          />
        )}
        {contentType === 'createInternalMemo' && (
          <CreateAnnouncement
            label={'Internal Memo'}
            type={'memo'}
            filter={filter}
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
          />
        )}
      </SimpleModal>
    </>
  );
}
