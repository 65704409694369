import styled from '@emotion/styled';

export const ScheduleTableWrapper = styled.div`
  display: grid;
  grid: auto-flow max-content / 1fr;
  width: 100%;
  overflow: auto;
  margin-top: 1rem;

  table {
    width: 100%;
    min-width: 72rem;
    font-size: 1.4rem;
    border: 1px solid transparent;
  }

  .body-table {
    border: 1px solid var(--grey-150);
    border-radius: 4px;
    height: 25rem;
    overflow-y: auto;
    overflow-x: hidden;

    th,
    td {
      padding: 0.4rem 2.4rem;

      &:first-of-type,
      &:nth-of-type(2) {
        width: calc(30rem + 1px);
      }
    }
  }

  tbody {
    //border: 1px solid var(--grey-150);
    //max-height: 25.6rem;
    //display: block;

    tr {
      height: 6.4rem;
      border-block: 1px solid var(--grey-150);

      &:first-of-type {
        border-top-color: transparent;
      }
    }
  }

  tr {
    min-width: 80rem;
  }

  th {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--grey-700);
    text-align: left;
  }

  th,
  td {
    padding: 0.4rem 2.4rem;

    &:first-of-type,
    &:nth-of-type(2) {
      width: 30rem;
    }

    &:last-of-type {
      width: 2.4rem;
    }
  }

  td:first-of-type {
    font-weight: 500;
  }
`;
