import React from 'react';
import { Avatar, Flex, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseInput, CustomButton } from 'components';

import calendar from '../../../../../assets/icons/Calendar copy.svg';
import closeIcon from '../../../../../assets/icons/close.svg';

export function TimesheetPopup({
  closeModal,
  openModal,
  empDate,
  setEmpDate,
  employee,
  loading,
  handleSubmit,
  page,
}) {
  const onHandleSubmit = () => {
    handleSubmit({
      variables: {
        filters: {
          searchString: null,
          date: empDate,
          userId: employee?._id,
        },
        page,
      },
    });
    openModal();
  };
  return (
    <Root>
      <Flex justifyContent={'space-between'}>
        <Text fontSize={'var(fs-text-lg)'}>Time Sheet Report</Text>

        <button onClick={closeModal}>
          <img src={closeIcon} alt="" />
        </button>
      </Flex>

      <form action="">
        <Flex gap={'15px'}>
          <BaseInput
            label={'Start'}
            data-testid={'date-from'}
            type={'date'}
            rightIcon={calendar}
            maxWidth={'160px'}
            value={empDate.start}
            onChange={(e) =>
              setEmpDate((prev) => {
                return { ...prev, start: e.target.value };
              })
            }
          />
          <BaseInput
            label={'End'}
            data-testid={'date-from'}
            type={'date'}
            rightIcon={calendar}
            maxWidth={'160px'}
            value={empDate.end}
            onChange={(e) =>
              setEmpDate((prev) => {
                return { ...prev, end: e.target.value };
              })
            }
          />
        </Flex>

        <Stack>
          <Text color={'var(--grey-500)'} fontWeight={500}>
            Employee Name
          </Text>
          <Flex alignItems={'center'} gap={'16px'}>
            <Avatar
              size={'lg'}
              src={employee?.imageUrl}
              name={employee?.firstName + ' ' + employee?.lastName}
            />
            <Text>
              {employee?.firstName} {employee?.lastName}
            </Text>
          </Flex>
        </Stack>

        <Flex justifyContent={'flex-end'} gap={'16px'}>
          <CustomButton
            h={'43px'}
            name={'Cancel'}
            variant={'secondary'}
            onClick={closeModal}
            mW={'86px'}
          />
          <CustomButton
            name={'View'}
            mW={'98'}
            h={'43px'}
            type={'submit'}
            onClick={onHandleSubmit}
            loading={loading}
          />
        </Flex>
      </form>
    </Root>
  );
}

const Root = styled(Stack)`
  width: 100%;
  padding: 24px;
  gap: 48px;

  form {
    display: grid;
    gap: 32px;
  }
`;
