export const columns = [
  {
    Header: 'Name',
    accessor: 'col1',
  },
  {
    Header: 'Type',
    accessor: 'col2',
  },
  {
    Header: 'Description',
    accessor: 'col3',
  },
  {
    Header: 'Request Date(s)',
    accessor: 'col4',
  },
  {
    Header: 'Count As',
    accessor: 'col5',
  },
  {
    Header: 'Status',
    accessor: 'col6',
  },
];
export const overlapColumns = [
  {
    Header: 'Name',
    accessor: 'col1',
  },
  {
    Header: 'Type',
    accessor: 'col2',
  },
  {
    Header: 'Request Date(s)',
    accessor: 'col3',
  },
  {
    Header: 'Status',
    accessor: 'col4',
  },
];
