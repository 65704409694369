import React from 'react';
import ReactDOM from 'react-dom';
import {
  MdCode,
  MdFormatAlignCenter,
  MdFormatAlignJustify,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatUnderlined,
  MdLooksOne,
  MdLooksTwo,
} from 'react-icons/md';
import styled from '@emotion/styled';

import { css, cx } from '@emotion/css';

const icons = {
  format_bold: <MdFormatBold />,
  format_italic: <MdFormatItalic />,
  format_underlined: <MdFormatUnderlined />,
  code: <MdCode />,
  looks_one: <MdLooksOne />,
  looks_two: <MdLooksTwo />,
  format_list_numbered: <MdFormatListNumbered />,
  format_list_bulleted: <MdFormatListBulleted />,
  format_quote: <MdFormatQuote />,
  format_align_left: <MdFormatAlignLeft />,
  format_align_center: <MdFormatAlignCenter />,
  format_align_right: <MdFormatAlignRight />,
  format_align_justify: <MdFormatAlignJustify />,
};

function getIcon(name) {
  return icons[name] || name;
}

export const Button = React.forwardRef(({ className, active, reversed, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        cursor: pointer;
        color: ${reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#bbb'};
      `
    )}
  />
));

export const EditorValue = React.forwardRef(({ className, value, ...props }, ref) => {
  const textLines = value.document.nodes
    .map((node) => node.text)
    .toArray()
    .join('\n');
  return (
    <div
      ref={ref}
      {...props}
      className={cx(
        className,
        css`
          margin: 30px -20px 0;
        `
      )}>
      <div
        className={css`
          font-size: 14px;
          padding: 5px 20px;
          color: #404040;
          border-top: 2px solid #eeeeee;
          background: #f8f8f8;
        `}>
        Slate&apos;s value as text
      </div>
      <div
        className={css`
          color: #404040;
          font: 12px monospace;
          white-space: pre-wrap;
          padding: 10px 20px;

          div {
            margin: 0 0 0.5em;
          }
        `}>
        {textLines}
      </div>
    </div>
  );
});

export const Icon = React.forwardRef(({ ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={cx(
      css`
        font-size: 18px;
        vertical-align: text-bottom;
      `
    )}>
    {getIcon(props.children)}
  </span>
));

export const Instruction = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        white-space: pre-wrap;
        margin: 0 -20px 10px;
        padding: 10px 20px;
        font-size: 14px;
        background: #f8f8e8;
      `
    )}
  />
));

const MenuRoot = styled.div`
  overflow-x: auto;
  width: 100%;

  & > * {
    display: inline-block;
  }
`;
export const Menu = React.forwardRef(({ ...props }, ref) => <MenuRoot {...props} ref={ref} />);

export const Portal = ({ children }) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        position: relative;
        height: 5.2rem;
        max-width: calc(100vw - 9.6rem);
        display: grid;
        grid: 1fr / auto-flow auto;
        justify-content: flex-start;
        gap: 1.6rem;
        align-items: center;
        padding-inline: 2.4rem;
        border-radius: 8px 8px 0 0;
        background: #f3f4f6;
        overflow-x: auto;
      `
    )}
  />
));

Toolbar.displayName = 'Toolbar';
EditorValue.displayName = 'EditorValue';
Button.displayName = 'Button';
Icon.displayName = 'Icon';
Menu.displayName = 'Menu';
Instruction.displayName = 'Instruction';
