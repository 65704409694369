import React from 'react';
import { Stack } from '@chakra-ui/react';

export function SimpleModal({ children }) {
  return (
    <Stack
      //w={'75rem'}
      padding={'2.4rem'}
      gap={'2.4rem'}
      borderRadius={'0.4rem'}
      border={'solid 1px var(--grey-250)'}>
      <Stack>{children}</Stack>
    </Stack>
  );
}
