import { Box, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

const SummaryAndSend = ({ state }) => {
  const Wrapper = styled.div`
    margin: 0 auto;
    padding: 16px 24px 24px 24px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    flex: none;
    order: 2;
    flex-grow: 0;
    max-width: 785px;
    width: 100%;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.117188px;
      color: #23273b;
    }
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0012em;
      color: #1a1a1a;
      margin-top: 32px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.0012em;
      color: #83889d;
    }

    hr {
      max-width: 673px;
      height: 1px;
      background: rgba(65, 97, 128, 0.151934);
      margin-top: 24px;
    }

    .grid-templates {
      grid-template-columns: 100px 1fr;
      column-gap: 80px;
    }
  `;

  return (
    <Wrapper>
      <h1>Summary and send</h1>
      <h2>Goals Details</h2>
      <Box border={'1px solid #F2F2F2'} borderRadius={'4px'} p={'24px 32px'} mt={'8px'}>
        {state?.title && (
          <Grid className={'grid-templates'}>
            <Box mb={'24px'}>
              <p>Title</p>
            </Box>
            <p>{state?.title}</p>
          </Grid>
        )}
        {state?.description && (
          <Grid className={'grid-templates'}>
            <Box mb={'24px'}>
              <p>Description</p>
            </Box>
            <p>{state?.description}</p>
          </Grid>
        )}
        <Grid className={'grid-templates'}>
          <Box mb={'24px'}>
            <p>Frequency</p>
          </Box>
          <p>{state?.frequency}</p>
        </Grid>
        {state?.milestones && (
          <Grid className={'grid-templates'}>
            <Box>
              <p>Metric</p>
            </Box>
            <p>Milestones</p>
          </Grid>
        )}

        <hr />
      </Box>
      <h2>Schedule</h2>
      <Box border={'1px solid #F2F2F2'} borderRadius={'4px'} p={'24px 32px'} mt={'8px'}>
        {state?.schedule?.startDate && (
          <Grid className={'grid-templates'}>
            <Box mb={'24px'}>
              <p>Start Date</p>
            </Box>
            <p>{state?.schedule?.startDate}</p>
          </Grid>
        )}
        {state?.schedule?.dueDate && (
          <Grid className={'grid-templates'}>
            <Box>
              <p>Due Date</p>
            </Box>
            <p>{state?.schedule?.dueDate}</p>
          </Grid>
        )}
        <hr />
      </Box>
      {state?.employees?.length > 0 && <h2>Participants</h2>}
      {state?.employees?.length > 0 && (
        <Box border={'1px solid #F2F2F2'} borderRadius={'4px'} p={'24px 32px'} mt={'8px'}>
          <Grid className={'grid-templates'}>
            <Box>
              <p>Employees Selected</p>
            </Box>
            <p>{state?.employees?.length}</p>
          </Grid>

          <hr />
        </Box>
      )}
    </Wrapper>
  );
};

export { SummaryAndSend };
