export function modifyLabel(data) {
  return {
    label: data.rolename,
    value: data._id,
  };
}
export function extractData(data) {
  let arr = [];
  if (!data) return;
  for (let policy of data) {
    if (!arr.includes(policy)) {
      arr.push(modifyLabel(policy));
    }
  }
  return arr;
}

export function modifyApprovalSteps(data) {
  let arr = [];
  for (let level of data) {
    if (level.value !== undefined && level.value !== null) {
      arr.push({ level: level.value });
    }
  }
  return arr;
}

export function modifyNotifyUsers(data) {
  let arr = [];
  for (let level of data) {
    if (level.value !== undefined && level.value !== null) {
      arr.push(level.value);
    }
  }
  return arr;
}

export function transformApprovalStepsData(data) {
  let arr = [];
  for (let level of data) {
    arr.push({ label: level?.level?.rolename, value: level?.level?._id });
  }
  return arr;
}

export function transformDataNotifyUsersData(data) {
  let arr = [];
  for (let user of data) {
    arr.push({
      label: `${user.firstName} ${user.lastName}`,
      value: user._id,
    });
  }
  return arr;
}

function modifyPolicyLabel(data) {
  return {
    id: data._id,
    label: `${data.name}`,
    value: `${data.name}`,
  };
}

export function extractPolicies(data) {
  let arr = [];
  for (let policy of data) {
    if (!arr.includes(policy)) {
      arr.push(modifyPolicyLabel(policy));
    }
  }
  return arr;
}

export function getDateOptions() {
  const start = 2022;
  const yearsOptions = [];

  let date = start;
  const end = new Date().getFullYear() + 50;
  while (date < end) {
    const strDate = date.toString();
    yearsOptions.push({ label: strDate, value: strDate });
    date += 1;
  }

  return yearsOptions;
}
