import React from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';

import { getMonth } from './helpers';

export function RangeDisplay(props) {
  const { date, toDate, active } = props;
  return (
    <RangeDisplayRoot>
      <div className={cx('container', { active: active === 0 })}>
        {date ? (
          <span>
            {getMonth(date.getMonth(), 3)} {date.getDate()}, {date.getFullYear()}
          </span>
        ) : null}
      </div>
      <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="12" height="1" fill="#23273B" />
      </svg>

      <div className={cx('container', { active: active === 1 })}>
        {toDate ? (
          <span>
            {getMonth(toDate.getMonth(), 3)} {toDate.getDate()}, {toDate.getFullYear()}
          </span>
        ) : null}
      </div>
    </RangeDisplayRoot>
  );
}

const RangeDisplayRoot = styled.div`
  display: grid;
  grid: 1fr / 1fr max-content 1fr;
  align-items: center;
  gap: 8px;
  margin-block: 0.4rem;

  .container {
    height: 4.8rem;
    padding: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #f2f2f2;
    font-weight: 500;
    text-align: center;
    //cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    // &.active {
    //   border: 2px solid var(--blue);
    // }
  }
`;
