import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_COMPLETED_TRAINING } from 'query';

import { TrainingTable } from './component';
const headers = {
  'Training Program': 'training',
  'Start Date': 'startDate',
  'Due Date': 'dueDate',
};

const CompletedTraining = ({ action }) => {
  const { data, loading } = useQuery(FETCH_COMPLETED_TRAINING);
  const completedT = data?.fetchCompletedTraining?.data;

  return (
    <div>
      <p className="text-16-400">Completed Training</p>
      <div className="table-container" style={{ '--num-cols': 3, marginTop: '8px' }}>
        <TrainingTable
          completed
          loading={loading}
          headers={headers}
          data={completedT?.filter((d) => d?.trainingId !== null)}
          actionToPerform={action}
          msg={'No Active Training Currently'}
        />
      </div>
    </div>
  );
};

export { CompletedTraining };
