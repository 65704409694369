import { useFormState } from 'react-hook-form';
import { Box, Grid, Text } from '@chakra-ui/react';

import { useReviewCycleForm } from '../../../../../../context/reviewCycleForm';
import { inputError } from '../../../../../../utils';

import { ParticipantType } from './ParticipantType';
import { SectionTitle } from './SectionTitle';

export function ParticipantsTypeStack() {
  const { control } = useReviewCycleForm();
  const formState = useFormState({ control });

  return (
    <Box>
      <SectionTitle mb="8px">
        Choose the types of participants you want to have for this cycle
      </SectionTitle>
      <Text color="brand.grey.text" fontSize="1.2rem">
        Choose maximum of three from the review type
      </Text>
      {inputError(formState.errors, 'reviewType')}
      <Grid autoFlow="rows" gap="16px" mt="16px">
        {types.map((type, index) => (
          <ParticipantType key={type.name} index={index} {...type} />
        ))}
      </Grid>
    </Box>
  );
}

const types = [
  { name: 'Self Evaluation', description: 'Employee completes a self-evaluation of themselves' },
  {
    name: "Manager's Review",
    description: 'Reviewers complete a performance evaluation for their direct reports.',
  },
  {
    name: 'Peer Review',
    description: 'Employee completes an evaluation of their peers, teammates and colleagues',
  },
];
