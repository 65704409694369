import React from 'react';
import { useQuery } from '@apollo/client';
import { Flex, Image, Stack, Text } from '@chakra-ui/react';

import closeIcon from '../../../../../assets/icons/close-grey.svg';
import dotIcon from '../../../../../assets/icons/dot.svg';
import undoIcon from '../../../../../assets/icons/undo.svg';
import { FETCH_NOTIFICATIONS } from '../../query';
import { archiveMessage, unArchiveMessage } from '../helpers';
import { ActionRequired, NewMessage } from '../styles';

export function SingleNotificationMessage(props) {
  const { data } = useQuery(FETCH_NOTIFICATIONS, {
    variables: {
      filter: {
        _id: props.id,
      },
    },
  });

  function openMessage() {
    const message = data?.fetchNotifications?.data[0];
    props.setNote(message);
    props.updateNotification({
      variables: {
        notificationId: [props.id],
        data: {
          readAt: props.isRead || new Date(),
        },
      },
    });
    props.openModal();
  }

  return (
    <Stack padding={'2.4rem 0 1.2rem 0'} borderBottom={'solid 1px var(--grey-150)'}>
      <Stack>
        <Flex justifyContent={'space-between'} gap={'4.2rem'}>
          <Stack gap={'8px'}>
            <Flex paddingLeft={'24px'} gap={'8px'}>
              {props.isRead === null ? <NewMessage>New</NewMessage> : null}
              {props.type === 'action_required' && props.isRead === null ? (
                <ActionRequired>Action Required</ActionRequired>
              ) : null}
            </Flex>
            <Flex gap={'16px'}>
              <img src={dotIcon} alt="" width={'8px'} height={'8px'} />

              <Flex gap={'8px'}>
                {props.category && (
                  <Text
                    fontSize={'14px'}
                    fontWeight={'600'}
                    color={'var(--deep_blue)'}
                    textTransform={'uppercase'}>
                    {props.category}:
                  </Text>
                )}
                <Text
                  data-testid={'notificationMessage'}
                  noOfLines={2}
                  fontSize={'1.4rem'}
                  color={'var(--grey-500)'}
                  onClick={openMessage}
                  cursor={'pointer'}>
                  {props.message}
                </Text>
              </Flex>
            </Flex>
          </Stack>

          {props.status === 'recent' ? (
            <Image
              src={closeIcon}
              data-testid={'archiveSingleMessage'}
              onClick={() => archiveMessage([props.id], props.updateNotification)}
              alt=""
              width={'10px'}
              title={'archive'}
              cursor={'pointer'}
            />
          ) : (
            <Image
              src={undoIcon}
              data-testid={'archiveSingleMessage'}
              onClick={() => unArchiveMessage([props.id], props.updateNotification)}
              alt=""
              width={'10px'}
              title={'unarchive'}
              cursor={'pointer'}
            />
          )}
        </Flex>
      </Stack>
    </Stack>
  );
}
