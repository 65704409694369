import React from 'react';
import { useWatch } from 'react-hook-form';
import { CgSpinner } from 'react-icons/cg';
import { IoMdCloseCircle } from 'react-icons/io';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import classNames from 'classnames';

import { useJobDetailsForm } from '../../../../context';
import { UPLOAD_FILE } from '../../../../query';
import { TinyImageIcon } from '../../../shared/Svgs';

const InputLabel = styled.label`
  display: grid;
  grid: 1fr / repeat(2, max-content);
  align-items: center;
  gap: 8px;
  padding: 1.7rem 2.4rem 2.5rem;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 4px 4px 0 0;
  color: var(--grey-text);
  font-size: 1.4rem;
  cursor: pointer;

  &.has-image {
    grid: 1fr / 1fr;
    padding: 1rem 0;
    pointer-events: none;
    position: relative;
    padding-top: 40%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left center;
    }

    .close-btn {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      width: max-content;
      border-radius: 50%;
      padding: 0;
      color: white;
      background-color: #7f7f7f;
      pointer-events: auto;
      transition: background-color ease 300ms;
      z-index: 10;

      &:hover {
        background-color: #e74c3c;
      }
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &:active {
    background: rgba(0, 0, 0, 0.04);
  }

  input {
    display: none;
  }

  img {
    width: 100%;
  }

  .icon.loading {
    animation: spin 500ms linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function JobAddHeaderImage() {
  const { control, setValue } = useJobDetailsForm();
  const file = useWatch({ control, name: 'headerImage' });

  const [uploadImage, { loading }] = useMutation(UPLOAD_FILE);

  function setFile(val) {
    setValue('headerImage', val);
  }

  function onChange(event) {
    const uploadedFile = event.target.files[0];
    // setFile(URL.createObjectURL(uploadedFile));
    uploadImage({ variables: { file: uploadedFile } }).then((res) =>
      setFile(res.data?.uploadFile?.data)
    );
  }

  function clearFile(event) {
    event.stopPropagation();
    event.preventDefault();
    setFile(null);
  }

  return (
    <InputLabel htmlFor="header-image" className={classNames({ 'has-image': file })}>
      {!file && (
        <>
          {loading ? (
            <div className="icon loading">
              <CgSpinner size={20} />
            </div>
          ) : null}
          {!loading ? TinyImageIcon : null}
          Add Header Image
          <input
            type="file"
            name="header-image"
            id="header-image"
            onChange={onChange}
            accept=".png, .jpeg, .jpg, .svg"
          />
        </>
      )}
      {file && (
        <>
          <div className="close-btn" onClick={clearFile} title="remove file">
            <IoMdCloseCircle size={28} />
          </div>
          <img src={file} alt="" />
        </>
      )}
    </InputLabel>
  );
}

export { JobAddHeaderImage };
