import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { EDIT_QUERY, FETCH_QUERIES } from 'query/admin/query';
import { getItem } from 'utils';

import { CustomButton, EmptyState, NameWithImage, SpinnerComponent } from 'components';

import closeIcon from '../../../../../assets/icons/close.svg';
import { useToastify } from '../../../../../hooks/useToastify';
import { isIssuer } from '../../helpers';

export function ViewQueryDetails({ singleQuery, closeModal, page }) {
  const { data, loading } = useQuery(FETCH_QUERIES, {
    variables: {
      filter: {
        _id: singleQuery,
      },
    },
  });

  const issuerInfo = getItem('hrm_user');

  const { successToast, errorToast } = useToastify();

  const [editQueryReject, { loading: loadingReject }] = useMutation(EDIT_QUERY, {
    refetchQueries: [
      {
        query: FETCH_QUERIES,
        variables: {
          filter: {
            keyword: '',
          },
          page,
        },
      },
    ],
  });
  const [editQueryAccept, { loading: loadingAccept }] = useMutation(EDIT_QUERY, {
    refetchQueries: [
      {
        query: FETCH_QUERIES,
        variables: {
          filter: {
            keyword: '',
          },
          page,
        },
      },
    ],
  });

  async function editSingleQueryReject(id, status) {
    if (!isIssuer(data?.fetchQueries?.data[0]?.issuer, issuerInfo)) {
      errorToast("You don't have permission to perform this.");
      return;
    }
    const res = await editQueryReject({
      variables: {
        filters: {
          _id: id,
        },
        data: {
          status: status,
        },
      },
    });

    if (res.data.editQuery.status === 200) {
      closeModal();
      successToast(res.data.editQuery.message);
    }
  }

  async function editSingleQueryAccept(id, status) {
    if (!isIssuer(data?.fetchQueries?.data[0]?.issuer, issuerInfo)) {
      errorToast("You don't have permission to perform this action.");
      return;
    }
    const res = await editQueryAccept({
      variables: {
        filters: {
          _id: id,
        },
        data: {
          status: status,
        },
      },
    });

    if (res.data.editQuery.status === 200) {
      closeModal();
      successToast(res.data.editQuery.message);
    }
  }
  return (
    <>
      {loading ? (
        <QueryDetailsWrapper>
          <SpinnerComponent />
        </QueryDetailsWrapper>
      ) : (
        <QueryDetailsWrapper>
          <Stack gap={'24px'} width={'100%'}>
            <Stack
              p={'16px 24px'}
              border={'solid 1px var(--grey-100)'}
              borderRadius={'4px'}
              gap={'32px'}>
              <Flex justifyContent={'space-between'} alignContent={'center'}>
                <Heading>Query Details</Heading>
                <Image
                  src={closeIcon}
                  onClick={closeModal}
                  cursor={'pointer'}
                  data-testid={'closeModal'}
                />
              </Flex>

              <Stack
                p={'16px 24px'}
                border={'solid 1px var(--grey-100)'}
                borderRadius={'4px'}
                gap={'24px'}
                color={'var(--grey-500)'}
                fontSize={'12px'}>
                <Flex justifyContent={'space-between'}>
                  <Text width={'30%'}>Subject</Text>
                  <Text width={'70%'}>{data?.fetchQueries?.data[0]?.subject}</Text>
                </Flex>

                <Flex justifyContent={'space-between'}>
                  <Text width={'30%'}>Content</Text>
                  <Text width={'70%'}>{data?.fetchQueries?.data[0]?.content}</Text>
                </Flex>

                <Flex justifyContent={'space-between'}>
                  <Text width={'30%'}>Employee:</Text>
                  <Box width={'70%'}>
                    <NameWithImage data={data?.fetchQueries?.data[0]?.recepient} />
                  </Box>
                </Flex>

                <Flex justifyContent={'space-between'}>
                  <Text width={'30%'}>Issued By:</Text>
                  <Box width={'70%'}>
                    <NameWithImage data={data?.fetchQueries?.data[0]?.issuer} />
                  </Box>
                </Flex>
              </Stack>

              <Stack>
                <Text fontSize={'12px'} fontWeight={'600'}>
                  Response
                </Text>

                <Box
                  minH={'170px'}
                  p={'16px'}
                  backgroundColor={'#FAFCFF'}
                  border={'solid 1px var(--grey-100)'}
                  borderRadius={'4px'}>
                  {data?.fetchQueries?.data[0]?.response === null ? (
                    <EmptyState message={'no response yet'} />
                  ) : (
                    <Text>{data?.fetchQueries?.data[0]?.response}</Text>
                  )}
                </Box>
              </Stack>
            </Stack>

            <Flex justifyContent={'flex-end'} gap={'8px'}>
              <CustomButton
                name={'Reject'}
                type={'button'}
                variant={'red--outline'}
                color={'red'}
                mW={'94px'}
                h={'43px'}
                loading={loadingReject}
                onClick={() => editSingleQueryReject(data?.fetchQueries?.data[0]?._id, 'rejected')}
                disabled={
                  data?.fetchQueries?.data[0]?.response === null ||
                  data?.fetchQueries?.data[0]?.status === 'rejected' ||
                  data?.fetchQueries?.data[0]?.status === 'accepted'
                }
              />
              <CustomButton
                name={'Accept'}
                type={'button'}
                bgColor={'var(--green)'}
                mW={'80px'}
                h={'43px'}
                loading={loadingAccept}
                onClick={() => editSingleQueryAccept(data?.fetchQueries?.data[0]?._id, 'accepted')}
                disabled={
                  data?.fetchQueries?.data[0]?.response === null ||
                  data?.fetchQueries?.data[0]?.status === 'accepted' ||
                  data?.fetchQueries?.data[0]?.status === 'rejected'
                }
              />
            </Flex>
          </Stack>
        </QueryDetailsWrapper>
      )}
    </>
  );
}

const QueryDetailsWrapper = styled.div`
  padding: 24px;
  display: grid;
  place-items: center;

  @media screen and (min-width: 400px) {
    width: 350px;
    min-height: 350px;
  }
  @media screen and (min-width: 450px) {
    width: 400px;
    min-height: 400px;
  }

  @media screen and (min-width: 600px) {
    width: 550px;
    min-height: 550px;
  }
  @media screen and (min-width: 700px) {
    width: 560px;
    min-height: 560px;
  }
  @media screen and (min-width: 800px) {
    width: 700px;
    min-height: 500px;
  }
  @media screen and (min-width: 900px) {
    width: 750px;
    min-height: 500px;
  }
`;
