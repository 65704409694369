import React from 'react';
import { useMutation } from '@apollo/client';
import { Box, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, CustomInput, SimpleModal } from '../../../../components';
import { useToastify } from '../../../../hooks/useToastify';
import { EDIT_CATEGORY } from '../../../../query';
import { InputLabel } from '../../performance/reviews/CreateReview/components/InputLabel';

export function EditCategory(props) {
  const [newCategory, setNewCategory] = React.useState(() => props.editing);
  const { successToast, errorToast } = useToastify();

  const [editCategory, { loading }] = useMutation(EDIT_CATEGORY);
  async function handleSave() {
    try {
      await editCategory({
        variables: { category: props.editing, newCategory: newCategory },
      });
      await props.refetch();
      successToast('Successfully edited category');
      props.setEditing(null);
    } catch (e) {
      errorToast(e.message);
    }
  }

  return (
    <SimpleModal showModal={props.editing} setShowModal={() => {}}>
      <Box
        m={'24px'}
        mb={'0'}
        border={'1px solid var(--grey-150)'}
        p={'24px'}
        w={'701px'}
        maxWidth={'90vw'}>
        <Header>
          <h1>Edit Category</h1>
        </Header>

        <InputLabel>
          Category Name <span className="req">*</span>
        </InputLabel>
        <CustomInput
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          withBorder
        />
        <Grid
          mt={'24px'}
          justifyContent={'flex-end'}
          gap={'12px'}
          templateColumns={'repeat(2, max-content)'}>
          <BaseButton onClick={() => props.setEditing(null)} variant={'outline'}>
            Cancel
          </BaseButton>
          <BaseButton onClick={handleSave} variant={'dark'} isLoading={loading}>
            Save
          </BaseButton>
        </Grid>
      </Box>
    </SimpleModal>
  );
}

const Header = styled.div`
  margin-bottom: 24px;
  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: -0.03em;
  }
`;
