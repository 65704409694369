import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { useToastify } from 'hooks/useToastify';
import { DELETE_DEPARTMENT, GET_DEPARTMENT } from 'query';
import { createActionHandler } from 'utils';

import { CustomButton, DeletePromptModal, Pagination, TableComponent } from 'components';

import { Modal } from '../RolesPermissions/Modal';

import { AddDepartment } from './AddDepartment';
import { EditDepartment } from './EditDepartment';
import { DepartmentWrapper } from './styles';
import { columns } from './tableConfig';

const actions = ['Edit', 'Delete'];

export const Departments = () => {
  const [showAdd, setShowAdd] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [departmentData, setData] = React.useState([]);
  const [deptID, setID] = React.useState('');
  const [page, setPage] = React.useState(1);

  const { successToast, errorToast } = useToastify();

  const { data, loading, refetch } = useQuery(GET_DEPARTMENT, {
    variables: {
      page: page,
    },
  });

  const totalPages = data?.fetchCompanyDepartments?.pageCount || 1;
  React.useEffect(() => {
    const fetchedData = data?.fetchCompanyDepartments?.data;
    setData(fetchedData);
  }, [data]);

  const tableData = departmentData?.map(({ name, createdBy, createdAt, designation }) => {
    const { firstName, lastName } = createdBy;
    return {
      col1: name || null,
      col2: designation.length || 0,
      col3: `${firstName} ${lastName}` || null,
      col4: format(parseISO(createdAt), 'MMMM dd, yyyy') || null,
    };
  });

  const [deleteDepartment, { loading: deleting }] = useMutation(DELETE_DEPARTMENT);
  const editAction = ({ id }) => {
    setID(id);
    setIsEditing(true);
  };
  const deleteAction = async ({ id }) => {
    setShowDelete(true);
    setID(id);
  };
  const deleteResource = async () => {
    try {
      const res = await deleteDepartment({ variables: { _id: deptID } });
      if ([200, 201].includes(res?.data?.deleteDepartment?.status)) {
        const msg = res?.data?.deleteDepartment?.message;
        successToast(msg);
      }
    } catch (error) {
      const errMsg = error?.response?.data?.message || error.message;
      errorToast(errMsg);
    } finally {
      refetch();
      setShowDelete(false);
      setID('');
    }
  };

  const closeModal = () => {
    setShowAdd(false);
    setIsEditing(false);
  };

  const actionOnData = createActionHandler({ editAction, deleteAction });

  return (
    <DepartmentWrapper data-testid="departments">
      <div className="header-content">
        <h1>Departments</h1>
        <CustomButton
          onClick={() => setShowAdd(true)}
          name="Add department"
          variant={'primary'}
          mW="16rem"
          h="4.4rem"
          testId="add-department-btn"
        />
      </div>
      <Modal show={showAdd} setShow={closeModal} data-testid="AddDepartment">
        <AddDepartment setShow={setShowAdd} refetch={refetch} />
      </Modal>
      <Modal show={isEditing} setShow={closeModal}>
        <EditDepartment setShow={setIsEditing} refetch={refetch} deptID={deptID} />
      </Modal>

      <DeletePromptModal
        show={showDelete}
        deleteItem={deleteResource}
        cancel={() => setShowDelete(false)}
        loading={deleting}
      />

      <div className="departments">
        <TableComponent
          noCheckbox
          noSort
          data={tableData}
          columns={columns}
          dropdownActions={actions}
          ids={departmentData?.map((e) => e._id)}
          actionOnData={actionOnData}
          empty={departmentData?.length === 0}
          loading={loading}
        />
      </div>
      {+totalPages && <Pagination page={page} setPage={setPage} totalPages={totalPages} />}
    </DepartmentWrapper>
  );
};
