import { BreadcrumbsRoot } from './Breadcrumbs.styles';
import BreadcrumbsItem from './BreadcrumbsItem';

function JobsBreadcrumbs({ active, setActive, list, color, hideIndicator }) {
  return (
    <BreadcrumbsRoot len={list.length} style={color ? { '--active-color': color } : {}}>
      {list.map((item, index) => (
        <BreadcrumbsItem
          key={item}
          number={index}
          active={active}
          clicked={() => setActive(index)}
          hideIndicator={hideIndicator}>
          {item}
        </BreadcrumbsItem>
      ))}
    </BreadcrumbsRoot>
  );
}

export { JobsBreadcrumbs };
