import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { CREATE_ANNOUNCEMENT, FETCH_ALL_DEPARTMENTS, FETCH_ANNOUNCEMENT } from 'query';

import {
  BaseDropdown,
  BaseInput,
  CustomButton,
  CustomCheckbox,
  NameWithImage,
  SpinnerComponent,
} from 'components';

import RichTextEditor from '../../../../../components/admin/jobs/job-details/RichTextEditor';
import { useToastify } from '../../../../../hooks/useToastify';
import { getItem } from '../../../../../utils/helpers/localStorage';
import { getDepartments, modifyData, modifyFilters } from '../../helpers';
import { CreateAnnouncementWrapper, RichTextEditorWrapper } from '../../styles';

// const isTesting = process.env.NODE_ENV === 'test';

export function CreateAnnouncement({ type, filter, closeModal, label }) {
  const [initialValue, setInitialValue] = useState([
    { type: 'paragraph', children: [{ text: '' }] },
  ]);
  const [formData, setFormData] = useState({
    title: '',
    status: '',
    share: '',
    department: [],
    isImportant: false,
  });

  const [createAnnouncementPublished, { loading: loadingPublished }] = useMutation(
    CREATE_ANNOUNCEMENT,
    {
      refetchQueries: [
        {
          query: FETCH_ANNOUNCEMENT,
          variables: {
            filter: {
              type: type,
              ...modifyFilters(filter),
            },

            page: 1,
          },
        },
      ],
    }
  );
  const [createAnnouncementDraft, { loading: loadingDraft }] = useMutation(CREATE_ANNOUNCEMENT, {
    refetchQueries: [
      {
        query: FETCH_ANNOUNCEMENT,
        variables: {
          filter: {
            type: type,
            ...modifyFilters(filter),
          },

          page: 1,
        },
      },
    ],
  });

  function onChange(value) {
    setInitialValue(value);
  }
  const user = getItem('hrm_user');
  const { data, loading } = useQuery(FETCH_ALL_DEPARTMENTS);

  const { successToast } = useToastify();

  async function onSubmitFormDraft(status) {
    await setFormData((prev) => {
      return { ...prev, status };
    });

    const res = await createAnnouncementDraft({
      variables: {
        data: {
          type: type,
          title: formData.title,
          content: JSON.stringify(initialValue),
          isImportant: formData.isImportant,
          sendTo: formData.share,
          departmentIds: getDepartments(formData.share, formData.department),
          status,
        },
      },
    });

    if (res?.data?.createAnnouncement?.status === 201) {
      closeModal();
      successToast(res?.data?.createAnnouncement?.message);
    }
  }

  async function onSubmitFormPublished(status) {
    await setFormData((prev) => {
      return { ...prev, status };
    });

    const res = await createAnnouncementPublished({
      variables: {
        data: {
          type: type,
          title: formData.title,
          content: JSON.stringify(initialValue),
          isImportant: false,
          sendTo: formData.share,
          departmentIds: getDepartments(formData.share, formData.department),
          status,
        },
      },
    });

    if (res?.data?.createAnnouncement?.status === 201) {
      closeModal();
      successToast(res?.data?.createAnnouncement?.message);
    }
  }

  let departmentElements = null;

  if (formData.share === 'department') {
    departmentElements = modifyData(data?.fetchCompanyDepartments?.data).map((el) => {
      return (
        <div key={el.value}>
          <CustomCheckbox
            label={el.label}
            value={formData.department}
            onChange={(e) => {
              if (e.target.checked) {
                setFormData((prev) => {
                  return { ...prev, department: [...prev.department, el.value] };
                });
              } else {
                setFormData((prev) => {
                  return {
                    ...prev,
                    department: prev.department.filter((item) => item !== el.value),
                  };
                });
              }
            }}
          />
        </div>
      );
    });
  } else if (formData.share === 'everyone') {
    departmentElements = null;
  }

  return (
    <CreateAnnouncementWrapper>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <form>
          <Stack gap={'24px'}>
            <Stack
              p={'16px 24px'}
              border={'solid 1px var(--grey-150)'}
              borderRadius={'4px'}
              gap={'32px'}>
              <Heading>{label}</Heading>
              <Stack gap={'24px'}>
                <BaseInput
                  data-testid={'announcement-title'}
                  value={formData.title}
                  onChange={(e) =>
                    setFormData((prev) => {
                      return { ...prev, title: e.target.value };
                    })
                  }
                  label={'Subject'}
                  labelSize={'12px'}
                  fontW={'600'}
                  padding={'8px'}
                  placeholder={'Subject'}
                  isRequired
                />
                <CustomCheckbox
                  label={'Mark as important'}
                  value={formData.isImportant}
                  onChange={() =>
                    setFormData((prev) => {
                      return { ...prev, isImportant: !prev.isImportant };
                    })
                  }
                />
              </Stack>

              <Stack>
                <Text fontSize={'12px'} fontWeight={'600'}>
                  Content
                </Text>
                <RichTextEditorWrapper>
                  <RichTextEditor
                    initialValue={initialValue}
                    onChange={onChange}
                    placeholder={'Enter Content'}
                  />
                </RichTextEditorWrapper>
              </Stack>

              <Stack gap={'32px'}>
                <BaseDropdown
                  placeholder={'share with'}
                  w={'223px'}
                  onChange={(e) =>
                    setFormData((prev) => {
                      return { ...prev, share: e.value };
                    })
                  }
                  options={[
                    { label: 'Share to Everyone', value: 'everyone' },
                    { label: 'Share to Departments', value: 'department' },
                  ]}
                />

                {formData.share === 'department' && (
                  <Stack gap={'16px'}>{departmentElements}</Stack>
                )}
              </Stack>
              <Flex gap={'32px'} fontWeight={'600'} alignItems={'center'}>
                <Text fontSize={'12px'} color={'var(--grey-500)'}>
                  Issued by:{' '}
                </Text>
                <NameWithImage data={user} />
              </Flex>
            </Stack>

            <Flex justifyContent={'flex-end'} gap={'8px'}>
              <CustomButton
                name={'Save as Draft'}
                variant={'black--outline'}
                mW={'138px'}
                h={'43px'}
                type={'button'}
                loading={loadingDraft}
                onClick={() => onSubmitFormDraft('draft')}
              />
              <CustomButton
                h={'43px'}
                name={'Cancel'}
                variant={'red--outline'}
                onClick={closeModal}
                mW={'86px'}
              />
              <CustomButton
                name={'Publish'}
                variant={'black'}
                mW={'98'}
                h={'43px'}
                type={'button'}
                loading={loadingPublished}
                onClick={() => onSubmitFormPublished('published')}
              />
            </Flex>
          </Stack>
        </form>
      )}
    </CreateAnnouncementWrapper>
  );
}
