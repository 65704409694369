import React from 'react';
import { Flex, Grid } from '@chakra-ui/react';

import { Dropdown, EmptyState, Pagination, SpinnerComponent } from 'components';
import { NoPermission } from 'components/common/PermissionError';

import { ApplicantCard } from './ApplicantCard';
import { useApplicationView } from './hooks';
import { Container, Jobs, StatusBar, StatusStyle, TabView } from './styles';

const ApplicationView = () => {
  const {
    applications,
    length,
    loading,
    sortingList,
    statusList,
    statusField,
    sortedField,
    hasNextPage,
    totalPages,
    setPage,
    page,
    setSortedField,
    setStatusField,
    errorMessage,
  } = useApplicationView();

  return (
    <Container>
      <TabView>
        <StatusBar>
          <h1>
            {length} {statusField === 'all' ? '' : statusField} Jobs
          </h1>
          <Flex alignItems={'center'} gap={'4px'}>
            <span>Sort by: </span>
            <Dropdown
              list={sortingList}
              value={sortedField}
              style={StatusStyle}
              onChange={(e) => setSortedField(e.target.value)}
            />
          </Flex>
          <Flex alignItems={'center'} gap={'4px'}>
            <span>Status: </span>
            <Dropdown
              list={statusList}
              value={statusField}
              style={StatusStyle}
              onChange={(e) => setStatusField(e.target.value)}
            />
          </Flex>
        </StatusBar>
        {loading ? (
          <Grid minH="20rem" placeContent="center" mt="20rem">
            <SpinnerComponent data-testid="loading" />
          </Grid>
        ) : (
          <Jobs>
            {applications?.length && (
              <div className="cards">
                <ApplicantCard jobs={applications} path={'/admin/candidates'} />
              </div>
            )}
            {applications?.length == 0 && !loading && !errorMessage && (
              <EmptyState message={'No Application Found'} />
            )}
            {errorMessage && !loading && <NoPermission message={errorMessage} />}
            {hasNextPage && <Pagination page={page} setPage={setPage} totalPages={totalPages} />}
          </Jobs>
        )}
      </TabView>
    </Container>
  );
};

export { ApplicationView };
