import React from 'react';
import { IoClose } from 'react-icons/io5';
import { TbChevronLeft } from 'react-icons/tb';
import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';

const IconWrapper = styled.div`
  margin-right: 0.8rem;

  &:empty {
    margin-right: 0;
  }
  ${(props) => props.isClose && `margin-right: 0rem;`}
`;

function ModalButton({ icon, children = '', ...props }) {
  return (
    <Button
      background="brand.bg"
      h="4rem"
      p="0.8rem 1.6rem"
      color="brand.black"
      fontSize="1.8rem"
      line-height="2.4rem"
      borderRadius="2rem"
      border="1px solid #F2F2F2"
      {...props}>
      <IconWrapper>{icon}</IconWrapper>
      {children}
    </Button>
  );
}
function ModalButtonClose({ icon, children = '', ...props }) {
  return (
    <Button
      background="brand.bg"
      h="4rem"
      p="0.8rem 1.6rem"
      color="brand.black"
      fontSize="1.8rem"
      line-height="2.4rem"
      borderRadius="2rem"
      border="1px solid #F2F2F2"
      {...props}>
      {children}
      <IconWrapper isClose={true}>{icon}</IconWrapper>
    </Button>
  );
}

const ModalBackButton = (props) => (
  <ModalButton icon={<TbChevronLeft size={24} />} {...props}>
    Back
  </ModalButton>
);
const ModalCloseButton = (props) => (
  <ModalButtonClose icon={<IoClose size={24} />} {...props}>
    Close
  </ModalButtonClose>
);

export { ModalBackButton, ModalButton, ModalCloseButton };
