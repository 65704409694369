import * as yup from 'yup';

export const options = [
  { label: 'Days', value: 'days' },
  { label: 'Monthly', value: 'months' },
];

export const trainingSchema = yup.object().shape({
  name: yup.string().required('Provide a name'),
  description: yup.string().required('Provide a description'),
  schedule: yup.object().shape({
    startDate: yup.string().required('Start Date is required'),
    endDate: yup.string().required('End Date is required'),
  }),
});

export const recordSchema = yup.object().shape({
  completionDate: yup.string().nullable().required('Provide a Completion Date'),
  results: yup.string().nullable().required('Provide a result'),
  cost: yup.string().nullable().required('Provide a cost'),
  note: yup.string().nullable().required('Provide a note'),
});

export const toTrack = [
  {
    label: 'Expiration Date',
    value: 'expiration_date',
  },
  {
    label: 'Instructor',
    value: 'instructor',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Credits',
    value: 'credits',
  },
  {
    label: 'Cost',
    value: 'cost',
  },
  {
    label: 'Results (i.e. Pass/Fail)',
    value: 'result',
  },
  {
    label: 'Certificate',
    value: 'certificate',
  },
];
