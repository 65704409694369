import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Grid, Heading, Stack, Text, Textarea } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { CustomButton, SpinnerComponent } from 'components';

import { useToastify } from '../../../../../../hooks/useToastify';
import { EDIT_QUERY, FETCH_QUERIES, FETCH_USER_QUERIES } from '../../../query';
import { formatDate } from '../../helpers';

const isTesting = process.env.NODE_ENV === 'test';

export function ViewQuery(props) {
  const [showTextarea, setTextarea] = useState(false);
  const [response, setResponse] = useState('');

  const { loading, data } = useQuery(FETCH_QUERIES, {
    variables: {
      filter: {
        _id: props.singleQuery,
      },
      page: props.page,
    },
  });

  const [editQuery, { loading: responseLoading }] = useMutation(EDIT_QUERY, {
    refetchQueries: [
      {
        query: FETCH_USER_QUERIES,
        variables: {
          filter: {
            keyword: '',
          },
          page: 1,
        },
      },
    ],
  });

  const { successToast } = useToastify();

  async function handleSubmit() {
    if (!showTextarea) {
      setTextarea(true);
      return;
    }

    if (response === '') return;
    const res = await editQuery({
      variables: {
        filters: {
          _id: props.singleQuery,
        },
        data: {
          status: 'active',
          response: response,
        },
      },
    });

    if (res?.data?.editQuery?.status === 200) {
      props.closeModal();
      successToast(res.data?.editQuery?.message);
    }
  }

  return (
    <>
      {loading ? (
        <Grid minW={'350px'} minH={'350px'} placeItems={'center'}>
          <SpinnerComponent />
        </Grid>
      ) : (
        <ViewQueryWrapper>
          <Stack
            p={'1.6rem 24px'}
            gap={'1.6rem'}
            border={'solid 1px var(--grey-150)'}
            borderRadius={'0.4rem'}>
            <Heading>Query</Heading>

            <Stack>
              <Text color={'var(--grey-500)'}>
                {' '}
                Subject: {data?.fetchQueries?.data[0]?.subject}
              </Text>
              <Text color={'var(--grey-500)'}>{data?.fetchQueries?.data[0]?.content}</Text>
            </Stack>

            {showTextarea && (
              <Stack>
                <Text fontWeight={'500'}>Response</Text>
                <Textarea
                  data-testid={'response-input'}
                  placeholder={'Response'}
                  name={response}
                  onChange={(e) => setResponse(e.target.value)}
                  fontSize={'1.6rem'}
                  backgroundColor={'#FAFCFF'}
                  h={'19.1rem'}
                />
              </Stack>
            )}

            {data?.fetchQueries?.data[0]?.response && (
              <>
                <Text fontWeight={'500'}>Response</Text>
                <Stack
                  background={'#FAFCFF'}
                  h={'19.1rem'}
                  p={'16px'}
                  border={'solid 1px var(--grey-150)'}
                  borderRadius={'4px'}>
                  <Text>{data?.fetchQueries?.data[0]?.response}</Text>
                </Stack>
              </>
            )}

            <Box>
              <Text>
                <strong>Date:</strong> {formatDate(data?.fetchQueries?.data[0]?.createdAt)}
              </Text>
              <Text textTransform={'capitalize'}>
                <strong>Time:</strong>{' '}
                {new Date(data?.fetchQueries?.data[0]?.createdAt).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              </Text>
            </Box>
          </Stack>

          <Flex justifyContent={'flex-end'} gap={'1rem'}>
            <CustomButton
              name={'Cancel'}
              fS={'1.4rem'}
              h={'4.3rem'}
              type={'button'}
              onClick={props?.closeModal}
              mW={'10.5rem'}
              variant={'black--outline'}
            />
            {(!data?.fetchQueries?.data[0]?.response || isTesting) && (
              <CustomButton
                name={'Response'}
                h={'4.3rem'}
                mW={'10.5rem'}
                variant={'black'}
                fS={'1.4rem'}
                loading={responseLoading}
                onClick={handleSubmit}
              />
            )}
          </Flex>
        </ViewQueryWrapper>
      )}
    </>
  );
}

const ViewQueryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  @media screen and (min-width: 450px) {
    min-width: 375px;
  }
  @media screen and (min-width: 550px) {
    min-width: 475px;
  }
  @media screen and (min-width: 650px) {
    min-width: 575px;
  }
  @media screen and (min-width: 750px) {
    min-width: 625px;
  }
  @media screen and (min-width: 850px) {
    min-width: 700px;
  }
`;
