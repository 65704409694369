import React from 'react';
import { useLocation } from 'react-router-dom';

function LoginHook() {
  let location = useLocation();
  const [routeType, setRouteType] = React.useState('');

  React.useEffect(() => {
    location?.pathname === '/employee/login' ? setRouteType('employee') : setRouteType('admin');
  }, [location]);

  return {
    routeType,
  };
}

export { LoginHook };
