import { useState } from 'react';
export function useAdminTraining() {
  let tabs = [
    { displayName: `SHARED TRAINING`, value: 'shared training' },
    { displayName: `INDIVIDUAL TRAINING`, value: 'individual training' },
  ];

  const [selectedButton, setSelectedButton] = useState('shared training');

  return {
    selectedButton,
    setSelectedButton,
    tabs,
  };
}
