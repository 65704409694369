import { RiEdit2Fill } from 'react-icons/ri';
import styled from '@emotion/styled';

function EditButton({ className = '', ...props }) {
  return (
    <EditIcon className={'icon ' + className} title="Edit input" {...props}>
      <RiEdit2Fill />
    </EditIcon>
  );
}

/* ---- STYLES ---- */
const EditIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  border-radius: 2px;
  color: var(--primary);
  background-color: inherit;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: background-color 300ms ease, opacity 200ms ease;

  &:hover {
    background: var(--grey-150);
  }
`;

export { EditButton };
