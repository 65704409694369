import { gql } from '@apollo/client';

export const GET_EMPLOYEE_ID = gql`
  query getEmployeeID {
    getEmployeeID {
      status
      message
      data
    }
  }
`;
