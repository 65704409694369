import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Flex, Grid, Heading, Image, Stack } from '@chakra-ui/react';

import { BaseDropdown, BaseInput, CustomButton } from 'components';

import close from '../../../../../../assets/icons/close.svg';
import calendar from '../../../../../../assets/icons/dateCalendar.svg';
import { genderData, maritalStatus, workingFormat } from '../../../../../../data/dropDownOptions';
import { useToastify } from '../../../../../../hooks/useToastify';
import { EDIT_EMPLOYEE_DETAILS, FETCH_EMPLOYEE_DETAILS } from '../../../query';
import { formatIncomingDate } from '../../helpers';

function EditBasicInfo({ closeModal, basicInfo, userId }) {
  const [editEmployeeDetails, { loading: saveLoading }] = useMutation(EDIT_EMPLOYEE_DETAILS, {
    refetchQueries: [
      {
        query: FETCH_EMPLOYEE_DETAILS,
        variables: {
          filters: {
            _id: userId,
          },
        },
      },
    ],
  });

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      ...basicInfo,
      dob: formatIncomingDate(basicInfo?.dob),
    },
  });

  const { successToast, errorToast } = useToastify();

  async function onSubmitForm(data) {
    try {
      const res = await editEmployeeDetails({
        variables: {
          data: {
            phoneNumber: data.phoneNumber,
            workingFormat: data.workingFormat.value,
            maritalStatus: data.maritalStatus.value,
            gender: data.gender.value,
          },
        },
      });
      if (res.data.editEmployeeDetails.status === 200) {
        closeModal();
        successToast(res.data.editEmployeeDetails.message);
      }
    } catch (error) {
      errorToast('Error updating basic information');
    }
  }

  return (
    <Stack p={'24px'}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        borderBottom={'solid 1px var(--grey-300)'}
        marginBottom={'4rem'}>
        <Heading fontSize={'2.4rem'} p={'0.8rem 0'} color={'var(--deep_blue)'}>
          Edit Basic Information
        </Heading>
        <Image data-testid={'closeModal'} src={close} cursor={'pointer'} onClick={closeModal} />
      </Flex>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid gridTemplateColumns={'1fr 1fr'} gap={'1.6rem'} width={'660px'}>
          <BaseInput
            {...register('firstName')}
            disabled
            label={'First Name'}
            placeholder={'Faith'}
            isRequired
          />
          <BaseInput
            {...register('lastName')}
            disabled
            label={'Last Name'}
            placeholder={'Oni'}
            isRequired
          />
          <Stack>
            <BaseInput
              {...register('phoneNumber')}
              label={'Phone Number'}
              placeholder={'phone Number'}
            />
          </Stack>
          <BaseInput
            {...register('email')}
            label={'Email Address'}
            disabled
            placeholder={'email'}
            type={'email'}
          />
          <Controller
            defaultValue=""
            control={control}
            name={`gender`}
            render={({ field }) => (
              <BaseDropdown
                label={'Gender'}
                {...field}
                placeholder={'Choose Gender'}
                options={genderData}
                w={'100%'}
              />
            )}
          />
          <BaseInput {...register('location')} label={'Location'} placeholder={'Ghana'} disabled />

          <Controller
            defaultValue=""
            control={control}
            name={`workingFormat`}
            render={({ field }) => (
              <BaseDropdown
                label={'Working Format'}
                {...field}
                placeholder={'Choose working format'}
                options={workingFormat}
                w={'100%'}
              />
            )}
          />
          <Controller
            defaultValue=""
            control={control}
            name={`maritalStatus`}
            render={({ field }) => (
              <BaseDropdown
                label={'Marital Status'}
                {...field}
                placeholder={'Choose Marital Status'}
                options={maritalStatus}
                w={'100%'}
              />
            )}
          />

          <BaseInput
            {...register('dob')}
            label={'Date of Birth'}
            disabled
            placeholder={'Date of Birth'}
            type={'date'}
            rightIcon={calendar}
          />
        </Grid>

        <Flex justifyContent={'center'} marginTop={'4.8rem'}>
          <CustomButton
            name={'Save Changes'}
            h={'4.8rem'}
            mW={'20.3rem'}
            type={'submit'}
            loading={saveLoading}
          />
        </Flex>
      </form>
    </Stack>
  );
}

export default EditBasicInfo;
