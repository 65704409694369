import React from 'react';
import styled from '@emotion/styled';

import { CheckboxWrapper, CustomCheckbox } from 'components';
import { RadioLabel } from 'components/shared/Radio';

export const AnswerType = ({ type, options, answers }) => {
  return (
    <>
      {type === 'multipleChoice' ? (
        <MultipleChoice options={options} answers={answers} showLabel={true} />
      ) : null}
      {type === 'radio' ? (
        <div style={{ display: 'grid', gap: '0.8rem' }}>
          <CustomRadio options={options} showLabel={true} answers={answers} />
        </div>
      ) : null}
    </>
  );
};

const MultipleChoice = React.forwardRef(({ options = [], answers, ...rest }, ref) => {
  return (
    <Wrap>
      {options.map((option, i) => (
        <CheckboxWrapper key={i}>
          <CustomCheckbox
            readOnly={true}
            onChange={() => {}}
            checked={answers?.includes(option?.value)}
            ref={ref}
            id={`${option?.label}|${option?.value}`}
            value={`${option?.value}`}
            label={option?.label}
            {...rest}
            variant={'blue-checkbox'}
          />
        </CheckboxWrapper>
      ))}
    </Wrap>
  );
});

const CustomRadio = React.forwardRef(({ options = [], showLabel, answers, ...rest }, ref) => {
  return (
    <>
      {options.map((option) => (
        <RadioLabel key={option?.label} htmlFor={`${option?.label}|${option?.value}`}>
          <input
            onChange={() => {}}
            readOnly={true}
            checked={answers?.includes(option?.value)}
            ref={ref}
            type="radio"
            name={option?.label}
            value={option?.value}
            id={`${option?.label}|${option?.value}`}
            className="radio-input"
            {...rest}
          />
          {showLabel ? <span>{option?.label}</span> : null}
        </RadioLabel>
      ))}
    </>
  );
});

MultipleChoice.displayName = 'MultipleChoice';
CustomRadio.displayName = 'CustomRadio';

const Wrap = styled.div`
  display: grid;
  gap: 1.6rem;
`;
