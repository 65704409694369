import styled from '@emotion/styled';

export const Container = styled.div`
  background: #fafcff;

  > .header {
    position: sticky;
    top: 6rem;
    z-index: 40;
    background: #fafcff;
    padding: 0 24px;
  }
`;
export const TabView = styled.div`
  position: relative;
  padding: 0 24px 24px;
`;
export const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: sticky;
  top: 6rem;
  z-index: 40;
  background: #fafcff;
  margin: 24px;

  @media only screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    padding: 10px 0;
    margin: 0;
  }
`;

export const StatusBar = styled.div`
  display: flex;
  gap: 56px;
  align-items: center;
  margin: 32px 0px;
  text-transform: capitalize;

  span {
    font-weight: 600;
    font-size: 12px;
    color: #83889d;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height */

    color: #000000;
  }

  .sort,
  .status {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const Jobs = styled.div`
  .cards {
    display: flex;
    gap: 24px 13px;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 450px) {
    .cards {
      display: flex;
      justify-content: center;
    }
  }
`;

export const Style = {
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: ' 16px',
  padding: '16px 8px',
};
export const LineStyle = { height: '2px', width: '100%' };

export const ButtonStyle = {
  width: '136px',
  padding: '12px 16px',
  fontWeight: 400,
  fontSize: '14px',
  marginBottom: '2px',
  color: '#fff',
  borderRadius: '3px',
};

export const LinkStyle = {
  fontWeight: 500,
  fontSize: '10px',
  color: '#666E7D',
  flexDirection: 'row-reverse',
};

export const ImgStyle = {
  flexDirection: 'row-reverse',
};

export const StatusStyle = { fontWeight: 600, fontSize: '12px' };
