import React from 'react';

import { EmptyState } from 'components';

import ReviewResponses from '../ReviewResponses';
import ReviewResponseWrapper from '../ReviewResponseWrapper';

export default function ManagerReview({ manager }) {
  const responses = manager?.report[0]?.reviewers?.responses;

  return (
    <ReviewResponseWrapper>
      {responses ? (
        <>
          {responses?.map((sec, i) => (
            <ReviewResponses section={sec} key={i} />
          ))}
        </>
      ) : (
        <EmptyState />
      )}
    </ReviewResponseWrapper>
  );
}
