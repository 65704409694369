import { useMutation } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';

import { CREATE_TASK, FETCH_ALL_TASKS, UPDATE_TASK } from './query';

export function TaskHook({ editing, state, closeModal, id, recurringDays }) {
  const [createTask, { loading }] = useMutation(CREATE_TASK);
  const [updateTask, { loading: updating }] = useMutation(UPDATE_TASK);

  const { successToast, errorToast } = useToastify();

  const refetchQueries = [
    {
      query: FETCH_ALL_TASKS,
      variables: {
        filter: {
          filterBy: 'day',
        },
      },
    },
    {
      query: FETCH_ALL_TASKS,
      variables: {
        filter: {
          filterBy: 'week',
        },
      },
    },
  ];
  const addTask = async (data) => {
    const ready = {
      ...data,
      ...state,
      daysToRepeat: recurringDays,
      date: new Date(data?.date).toISOString(),
      repeatInterval: state.repeatInterval === '' ? 'never' : state.repeatInterval,
      priority: state.priority === '' ? 'low' : state.priority,
    };

    try {
      const res = await createTask({
        variables: { data: ready },
        awaitRefetchQueries: true,
        refetchQueries: refetchQueries,
      });
      const status = res?.data.createTask?.status;
      const message = res?.data.createTask?.message;
      [201].includes(status) ? successToast(message) : errorToast(message);
    } catch (error) {
      return error;
    } finally {
      closeModal();
    }
  };
  const editTask = async (data) => {
    delete data['_id'];
    delete data['createdBy'];

    const ready = {
      ...data,
      daysToRepeat: recurringDays,
      date: new Date(data?.date).toISOString(),
    };
    try {
      const res = await updateTask({
        variables: { id: id, data: ready },
        awaitRefetchQueries: true,
        refetchQueries: refetchQueries,
      });

      const status = res?.data.updateTaskByID?.status;
      const message = res?.data.updateTaskByID?.message;
      [200].includes(status) ? successToast(message) : errorToast(message);
    } catch (error) {
      return error;
    } finally {
      closeModal();
    }
  };

  function saveTask(data) {
    editing ? editTask(data) : addTask(data);
  }

  return {
    saveTask,
    loading,
    updating,
    updateTask,
    refetchQueries,
  };
}
