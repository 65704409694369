import { gql } from '@apollo/client';

const GET_ROLES = gql`
  query Query($filters: Rolefilters, $page: Int) {
    fetchRolesFiltered(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      totalPages: totalCount
      data {
        _id
        rolename
        userCount
        status
        createdAt
        updatedAt
        permissions {
          moduleName
          privileges
        }
      }
    }
  }
`;
const GET_ROLES_FOR_DROPDOWN = gql`
  query Query($filters: Rolefilters, $page: Int) {
    fetchRolesFiltered(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      data {
        _id
        rolename
      }
    }
  }
`;

const ADD_ROLE = gql`
  mutation Mutation($data: createRoleinput!) {
    addNewRole(data: $data) {
      status
      message
      data {
        _id
        rolename
        status
        permissions {
          moduleName
          privileges
        }
      }
    }
  }
`;

const EDIT_ROLE = gql`
  mutation Mutation($data: editRoleSchema!) {
    editRole(data: $data) {
      status
      message
      data {
        _id
        rolename
        status
        permissions {
          moduleName
          privileges
        }
      }
    }
  }
`;

const DELETE_ROLE = gql`
  mutation Mutation($_id: ObjectID!) {
    deleteRole(_id: $_id) {
      status
      message
      data {
        rolename
      }
    }
  }
`;

export { ADD_ROLE, DELETE_ROLE, EDIT_ROLE, GET_ROLES, GET_ROLES_FOR_DROPDOWN };
