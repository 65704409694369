export const monthsFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getMonth = (index, length = null) => {
  const month = monthsFull[index];
  return length && length > 0 ? month.slice(0, length) : month;
};

export const daysFull = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday,',
];

export const getDay = (index, length) => {
  const day = daysFull[index];
  return length && length > 0 ? day.slice(0, length) : day;
};

export const getEdges = (month, year) => {
  const start = new Date(year, month, 1);
  const end = new Date(year, month + 1, 0);
  // days start at 0 = Sunday
  const startAt = [7, 1, 2, 3, 4, 5, 6];
  return [start.getDate(), end.getDate(), startAt[start.getDay()]];
};

export const getDaysArray = (start, end) => {
  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }
  return arr;
};

export const isValidDate = function (d) {
  return !isNaN(new Date(d).getTime());
};

export const isSameDate = (date1, date2) => {
  if (!isValidDate(date1) || !isValidDate(date2)) return false;
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
