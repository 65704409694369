import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import BulkIcon from '../../../assets/icons/B-onboarding.svg';
import SingleIcon from '../../../assets/icons/S-onboarding.svg';

import { OnbTypeWrapper } from './styles';

export const OnboardingType = ({ onboardBulk, onboardSingle }) => {
  return (
    <Flex p={'2.4rem 0'} justifyContent="space-between" data-testid="onb-type">
      <Box>
        <OnbTypeWrapper onClick={() => onboardSingle()} data-testid="single-onb">
          <img src={SingleIcon} alt="" />
        </OnbTypeWrapper>
        <Text color="#000000" fontWeight={'500'} fontSize={'1.6rem'}>
          {'Single Onboarding'}
        </Text>
      </Box>
      <Box>
        <OnbTypeWrapper onClick={() => onboardBulk()} data-testid="bulk-onb">
          <img src={BulkIcon} alt="" />
        </OnbTypeWrapper>
        <Text color="#000000" fontWeight={'500'} fontSize={'1.6rem'}>
          {'Bulk Onboarding'}
        </Text>
      </Box>
    </Flex>
  );
};
