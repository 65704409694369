import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import plus from '../../../assets/icons/plus copy.svg';
import { BaseButton } from '../../../components';
import { Tabs } from '../../../components/shared/tabs';

import { TableView } from './table-view/TableView';
import { CreateTraining } from './create-training';
import { useAdminTraining } from './TrainingView.hooks';

export const AdminTraining = () => {
  const { selectedButton, setSelectedButton, tabs } = useAdminTraining();

  const [openModal, setOpenModal] = React.useState(false);

  const decodedButton = decodeURI(selectedButton);

  return (
    <PageRoot>
      <Header>
        <Flex justifyContent="space-between" alignItems="center" mb="56px" minHeight="5.6rem">
          <h1>{decodeURI(selectedButton)}</h1>
          {decodedButton === 'shared training' && (
            <BaseButton h="5.6rem" smp onClick={() => setOpenModal(true)}>
              <img src={plus} alt="" />
              Create Training Program
            </BaseButton>
          )}
        </Flex>
        <Tabs tabs={tabs} setSelected={setSelectedButton} selected={decodedButton} />
      </Header>
      <Content className="scroll-bar">
        {decodedButton === 'shared training' ? <TableView selectedButton={decodedButton} /> : null}
        {decodedButton === 'individual training' ? (
          <TableView selectedButton={decodedButton} />
        ) : null}
      </Content>

      {openModal ? <CreateTraining openModal={openModal} setOpenModal={setOpenModal} /> : null}
    </PageRoot>
  );
};

const PageRoot = styled.div`
  height: calc(100% - 108px);
  padding: 0 24px;
`;

const Header = styled.div`
  border-bottom: 1px solid var(--grey-150);
  position: sticky;
  top: 0;
  background: var(--bg);
  z-index: 10;
  padding-top: 2.4rem;

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .tab-btn-toggle {
    :hover {
      border-bottom: 1px solid #3e7eee;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 144px);
  overflow-x: auto;
  padding-block: 24px;
`;
