import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import countriesData from '../../data/countries.json';

import { BaseDropdown } from './BaseDropdown';

function DropdownIndicator() {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '0.8rem' }}>
      <path d="M0.25 0.5L4 4.25L7.75 0.5H0.25Z" fill="#333758" />
    </svg>
  );
}

export const PhoneInput = (props) => {
  const [countries, setCountries] = React.useState([]);

  React.useEffect(() => {
    const countryData = countriesData.map((c) => {
      return {
        value: c.dialCode,
        label: c.name,
        flag: c.flag,
      };
    });
    setCountries(countryData);
  }, []);

  const allCountryOptions = countries?.map((c) => ({
    label: (
      <Flex>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          w={'2rem'}
          height={'2rem'}
          borderRadius={'50%'}
          mr={'1rem'}
          overflow={'hidden'}>
          <img src={c.flag} alt={'i'} width="100%" height="100%" style={{ borderRadius: '80%' }} />
        </Flex>
        <Text fontSize={'1.4rem'}>{c.value}</Text>
      </Flex>
    ),
    value: c.label,
    dialCode: c.value,
  }));
  const options = [...allCountryOptions] || [];

  return (
    <>
      <div>
        <Text fontSize="1.4rem" as="label" mb="1.2rem" color={'#333758'}>
          {props.label}
        </Text>
        <Flex>
          <BaseDropdown
            data-testid="phone-input"
            options={options}
            value={props.countryCode}
            border="1px solid #e0e0e0"
            onChange={(e) => props.getCountryCode(e)}
            w={'11rem'}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
          />
        </Flex>
      </div>
    </>
  );
};

PhoneInput.displayName = 'PhoneInput';
PhoneInput.propTypes = {
  countryCode: PropTypes.object,
  phoneValue: PropTypes.string,
  getCountryCode: PropTypes.func,
  getPhoneNumber: PropTypes.func,
};
