import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, Grid, Text } from '@chakra-ui/react';
import * as yup from 'yup';

import { BaseButton, SpinnerComponent } from 'components';

import { INPUT_TYPES } from '../../admin/jobs/add-jobs/ApplicantsForm/helpers';

import { ApplicationForm } from './components/ApplicationForm';
import { LinkContainer } from './components/LinkContainer';
import { BackButton, Socials } from './components';
import { useJobDetails } from './hooks';
import { useScrollToTop } from './JobDescription';

const JobApply = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const params = useParams();

  const jobId = params.id;
  const { isLoading, data: jobDetails } = useJobDetails(jobId);

  const schema = React.useMemo(() => generateSchema(), [jobDetails]);

  useEffect(() => {}, [params.id]);

  if (isLoading) {
    return (
      <Grid minH="20rem" placeContent="center" mt="20rem">
        <SpinnerComponent data-testid="loading" />
      </Grid>
    );
  }
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Grid
      key={jobDetails ? jobDetails?.updatedAt : null}
      gridTemplateColumns={{ base: '100%', md: 'auto 35%', lg: 'auto 444px' }}
      alignItems="flex-start"
      gap="2.4rem"
      maxWidth="128rem"
      mx="auto">
      <Box p="3.2rem" border="1px solid #E8E8E8" backgroundColor="#fff">
        <BackButton onClick={handleBack} />
        <Box mt="3.2rem" mb="2.4rem">
          {!jobDetails && !isLoading ? (
            <Text fontSize="1.4rem" textAlign={'center'}>
              We couldn&apos;t retreive any data for this job id.
              <br />
              Check the id and try again!!
            </Text>
          ) : null}
          {jobDetails?.title && (
            <Text fontSize="2.4rem" fontWeight="bold">
              {jobDetails?.title}
            </Text>
          )}
          {jobDetails?.city && jobDetails?.country && (
            <Text fontSize="1.2rem">
              {jobDetails?.city}, {jobDetails?.country}
            </Text>
          )}
        </Box>

        <Divider orientation="horizontal" mb="4rem" />

        <ApplicationForm formFields={jobDetails.formFields} schema={schema} />
      </Box>

      <Grid templateColumns="100%" gap="2.4rem" w="100%">
        <Box p="3.2rem" border="1px solid #E8E8E8" backgroundColor="#fff">
          <BaseButton
            h="5.6rem"
            w="100%"
            color="#fff"
            backgroundColor="brand.blue"
            _hover={{ backgroundColor: 'hsla(218, 84%, 65%, 1)' }}
            _active={{ backgroundColor: 'hsla(218, 84%, 62%, 1)' }}
            fontSize="1.6rem"
            fontWeight="700"
            variant="outline-blue"
            onClick={handleBack}>
            View Job Description
          </BaseButton>
          <Divider orientation="horizontal" marginBlock="1.6rem 2.4rem" />
          <LinkContainer />
          <Socials link="#" />
        </Box>
      </Grid>
    </Grid>
  );
};

function generateSchema() {
  return yup.object().shape({
    fields: yup.array().of(yup.lazy(lazyValidateFields)),
  });
}

function lazyValidateFields(value) {
  if (value.type === INPUT_TYPES.Section)
    return yup.object().shape({
      children: yup.array().of(yup.lazy(lazyValidateFields)),
    });
  const stringTypeFields = [
    INPUT_TYPES.TextField,
    INPUT_TYPES.TextArea,
    INPUT_TYPES.RadioButton,
    INPUT_TYPES.Hyperlink,
    INPUT_TYPES.DatePicker,
  ];
  const rootValidation = {
    string: yup.string(),
    email: yup.string().email(`${value.name} must be a valid email`),
    array: yup.array(),
    object: yup.object(),
  };
  const isStringType = stringTypeFields.includes(value.type);
  const isArrayType = Array.isArray(value.value);
  const isObjectType = !isArrayType && typeof value.value === 'object';
  const isEmailType = value.name?.toLowerCase().includes('email');
  const type = isEmailType
    ? 'email'
    : isStringType
    ? 'string'
    : isArrayType
    ? 'array'
    : isObjectType
    ? 'object'
    : null;
  if (type === null) return yup.mixed();
  let validation = rootValidation[type];
  if (value.required && validation) validation = validation.required(`${value.name} is required`);
  else if (value.type === INPUT_TYPES.RadioButton) validation = validation.nullable();

  return yup.object().shape({
    value: validation,
  });
}

export { JobApply };
