export const columns = [
  {
    Header: 'Query Title',
    accessor: 'queryTitle',
  },
  {
    Header: 'Issued By',
    accessor: 'issuedBy',
  },
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const data = [
  {
    _id: 1,
    subject: 'Query One',
    issuer: {
      firstName: 'Gawsusu',
      lastName: 'Razak',
    },
    status: 'active',
    createdAt: '2022-12-09',
  },
  {
    _id: 1,
    subject: 'Query One',
    issuer: { firstName: 'Kwabena', lastName: 'Agyapong' },
    status: 'pending',
    createdAt: '2022-12-09',
  },
];
