import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import { EventsActionContainer, EventsPopupAction } from 'components';

import { LeaveHook } from '../hook';

function SettingsTable(props) {
  const {
    setModalType,
    toggleEditModal,
    setApprovalId,
    setLeavePolicies,
    markDefault,
    deleteSchedule,
    assignSchedule,
  } = props;

  const { action } = LeaveHook({
    setModalType,
    toggleEditModal,
    setApprovalId,
    setLeavePolicies,
    markDefault,
    deleteSchedule,
    assignSchedule,
  });

  return (
    <>
      {props.data.map((el) => (
        <HStack
          justify={'flex-start'}
          key={el?.name}
          borderBottom="solid 1px #ebebeb"
          margin={'0 2.4rem'}
          padding={'1.6rem 0'}
          minWidth={'45rem'}>
          <Box w={'54%'} display={'flex'} gap={'2rem'}>
            <Text fontWeight={'500'} fontSize={'1.4rem'} color={'#23273B'}>
              {el.name}
            </Text>

            {el.isDefault ? (
              <Text
                fontSize={'1rem'}
                bgColor={'rgba(62, 126, 238, 0.1)'}
                color={'#3E7EEE'}
                p={'0.4rem 0.8rem'}
                borderRadius={'1rem'}>
                default
              </Text>
            ) : (
              ''
            )}
          </Box>

          <HStack w={'46%'} justify={'space-between'}>
            <Text fontSize={'1.4rem'}>{el.leavePolicies ?? el.enrolledEmployee}</Text>
            <Box>
              <EventsActionContainer>
                <EventsPopupAction
                  dropdownActions={props.dropdownActions}
                  actionOnData={action}
                  id={el._id}></EventsPopupAction>
              </EventsActionContainer>
            </Box>
          </HStack>
        </HStack>
      ))}
    </>
  );
}

export default SettingsTable;
