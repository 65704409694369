export const profileInfo = {
  basicInfoData: [
    'First Name',
    'Last Name',
    'Phone Number',
    'Email Address',
    'Gender',
    'Marital Status',
    'Date of Birth',
    'Work Format',
    'Location',
  ],
  medicalInfoData: [
    'Blood Group',
    'Blood Type',
    'Notable Ailment',
    'Disability',
    'If yes (disability) state it',
  ],
  addressInfo: ['Street Address', 'City', 'Province/State', 'Country'],
  emergencyContact: ['Name', 'Phone', 'Relationship'],
  employmentInfo: [
    'ID Number',
    'Date  Hired',
    'Employment Type',
    'Department',
    'Designation',
    'Team',
    'Grade Level',
    'Compensation Type',
    'Employee Benfit',
    'Pay Rate',
  ],

  bankAccountInfo: [
    'Bank Name',
    'Payment Type',
    'Pension AccountNo',
    'Pension Fund Administrator',
    'Account Name',
    'Account Number',
    'Account Type',
  ],
  currentCompensationInfo: ['Compensation Type', 'Salary/Rate', 'Effective Date', 'Hours Per Week'],
};

export const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Created By',
    accessor: 'createdBy',
  },
  {
    Header: 'Date Modified',
    accessor: 'date',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
