import { gql } from '@apollo/client';

export const CREATE_LEAVE_POLICY = gql`
  mutation addLeavePolicy($data: LeavePolicyInput!) {
    addLeavePolicy(data: $data) {
      status
      message
      data {
        _id
        name
        leaveDuration {
          duration
          durationType
        }
      }
    }
  }
`;

export const FETCH_LEAVE_POLICY = gql`
  query retrieveLeavePolicy {
    retrieveLeavePolicy {
      status
      message
      data {
        policies {
          leaveTypeId {
            _id
            name
          }
          _id
          name
          isDefault
          leaveDuration {
            duration
            durationType
          }
        }
        employeeCount
      }
    }
  }
`;

export const UPDATE_LEAVE_POLICY = gql`
  mutation updateLeavePolicy($filters: LeavePolicyFilters!, $data: LeavePolicyUpdate!) {
    updateLeavePolicy(filters: $filters, data: $data) {
      status
      message
      data {
        _id
        name
        isDefault
        leaveDuration {
          duration
          durationType
        }
      }
    }
  }
`;

export const ASSIGN_LEAVE_POLICY = gql`
  mutation assignPolicyToEmployee($employeeIds: [ObjectID!], $data: AssignPolicyData!) {
    assignPolicyToEmployee(employeeIds: $employeeIds, data: $data) {
      status
      message
      data {
        firstName
        lastName
        policyId {
          leavePolicyId
          leaveTypeId
        }
      }
    }
  }
`;

export const DELETE_LEAVE_POLICY = gql`
  mutation removeLeavePolicy($policyId: ObjectID!) {
    removeLeavePolicy(policyId: $policyId) {
      status
      message
    }
  }
`;
