import React from 'react';
import { IoClose } from 'react-icons/io5';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { fileIcon } from './icons';

export const FilesPreview = ({ files, addclose, removeFile }) => {
  return (
    <Wrap data-testid="files-preview">
      {files?.map((file) => {
        return (
          <Flex key={file?.name} gap="1.6rem" alignItems={'center'}>
            <img src={fileIcon} alt="" className="" />
            <p className="filename">{file?.name}</p>
            {addclose ? (
              <IoClose
                data-testid="add-close"
                className="close"
                size="18"
                color={'var(--error)'}
                onClick={() => removeFile(file?.name)}
              />
            ) : null}
          </Flex>
        );
      })}
    </Wrap>
  );
};
export const UrlFilesPreview = ({ files, addclose, removeFile }) => {
  return (
    <Wrap data-testid="url-files-preview">
      {files?.map((file) => {
        return (
          <Flex key={file} gap="1.6rem" alignItems={'center'} mb="0.8rem">
            <img src={fileIcon} alt="" className="" />
            <p className="filename">{file}</p>
            {addclose ? (
              <IoClose
                data-testid="add-close"
                className="close"
                size="18"
                color={'var(--error)'}
                onClick={() => removeFile(file)}
              />
            ) : null}
          </Flex>
        );
      })}
    </Wrap>
  );
};

const Wrap = styled.div`
  margin-top: 1.6rem;

  .close {
    cursor: pointer;
  }

  .filename {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    color: #333758;
    white-space: wrap;
  }
`;
