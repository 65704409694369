import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastify } from 'hooks/useToastify';
import { changeApplicantPassword } from 'query';
import { conditional, getItem } from 'utils';
import * as yup from 'yup';

import { BaseButton, BaseInput, CustomButton } from 'components';

import EyeCloseIcon from '../../../../../assets/icons/hide-password.svg';
import EyeOpenIcon from '../../../../../assets/icons/show-password.svg';
import { ErrorMessage } from '../../../auth/styles';

export const ApplicantChangePassword = () => {
  const [edit, setEdit] = React.useState(false);
  const [showIcon, setShowIcon] = React.useState(false);
  const [showConPass, setShowConPass] = React.useState(false);
  const [showNewPass, setShowNewPass] = React.useState(false);
  const navigate = useNavigate();

  const user = getItem('hrm_user');

  const submit = async (data) => {
    const newData = {
      existingPassword: data?.existingPassword,
      password: data?.password,
      confirmPassword: data?.confirmPassword,
      id: user._id,
      email: user.email,
    };
    try {
      setIsLoading(true);
      await changeApplicantPassword({ ...newData });
      successToast('Password Changed successful');
      navigate('/applicant/login');
    } catch (error) {
      errorToast(error.message);
    }
  };

  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const confirmPassword = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .matches(
        passwordRules,
        'Must be at least 8 characters and contain a lowercase, uppercase, number and special case character'
      ),

    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], 'Passwords do not match'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    resolver: yupResolver(confirmPassword),
  });
  const { successToast, errorToast } = useToastify();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <form
      style={{ backgroundColor: 'white', position: 'relative' }}
      onSubmit={handleSubmit(submit)}>
      <div>
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          paddingBottom={'20px'}
          flexWrap={'wrap'}
          gap={'10px 0px'}>
          <Flex direction={'column'} gap={'4px'}>
            <Text color={'#101828'} fontSize={'24px'} fontWeight={'700'}>
              Edit your Job board Password
            </Text>
            <p>Update your current password by entering your previous password to continue</p>
          </Flex>
          {conditional(
            !edit,
            <CustomButton
              data-testid="edit password"
              name="Edit Password"
              type="submit"
              variant="primary"
              h="40px"
              onClick={() => setEdit(!edit)}
              mW="128px"></CustomButton>
          )}
        </Flex>

        <hr />
      </div>
      <div>
        <Flex alignItems={'center'} flexWrap={'wrap'} gap={'10px 32px'} padding={'20px 0'}>
          <p style={{ maxWidth: '280px', width: '100%' }}>Current password</p>
          <Box maxW={'512px'} width={'100%'}>
            <BaseInput
              data-testid="current-password"
              type={showIcon ? 'text' : 'password'}
              disabled={!edit}
              name={'password'}
              rightIcon={showIcon ? EyeOpenIcon : EyeCloseIcon}
              IconClick={() => setShowIcon(!showIcon)}
              placeholder={'Enter your current password'}
              {...register('existingPassword')}
            />
          </Box>
        </Flex>

        <hr />
      </div>
      <div>
        <Flex alignItems={'center'} flexWrap={'wrap'} gap={'10px 32px'} padding={'20px 0'}>
          <p style={{ maxWidth: '280px', width: '100%' }}>New password</p>
          <Box maxW={'512px'} width={'100%'}>
            <BaseInput
              disabled={!edit}
              data-testid="new-password"
              type={showNewPass ? 'text' : 'password'}
              rightIcon={showNewPass ? EyeOpenIcon : EyeCloseIcon}
              IconClick={() => setShowNewPass(!showNewPass)}
              placeholder={'Enter your new password'}
              {...register('password')}
            />
            {errors?.password?.message && <ErrorMessage>{errors?.password?.message}</ErrorMessage>}
          </Box>
        </Flex>

        <hr />
      </div>
      <div>
        <Flex alignItems={'center'} flexWrap={'wrap'} gap={'10px 32px'} padding={'20px 0'}>
          <p style={{ maxWidth: '280px', width: '100%' }}>Confirm new password</p>
          <Box maxW={'512px'} width={'100%'}>
            <BaseInput
              disabled={!edit}
              data-testid="confirm-password"
              type={showConPass ? 'text' : 'password'}
              rightIcon={showConPass ? EyeOpenIcon : EyeCloseIcon}
              IconClick={() => setShowConPass(!showConPass)}
              placeholder={'Confirm new password'}
              {...register('confirmPassword')}
            />
            {errors?.confirmPassword?.message && (
              <ErrorMessage>{errors?.confirmPassword?.message}</ErrorMessage>
            )}
          </Box>
        </Flex>

        <hr />
      </div>
      {conditional(
        edit,
        <Flex gap={'0px 12px'} paddingTop={'20px'} justifyContent={'end'}>
          <BaseButton
            data-testid="cancel"
            variant="outline"
            h="40px"
            onClick={() => setEdit(!edit)}
            mW="128px"
            style={{ background: '#000', color: '#344054' }}>
            Cancel
          </BaseButton>
          <BaseButton
            data-testid="update password"
            type="submit"
            variant="primary"
            h="40px"
            mW="128px"
            isLoading={isLoading}>
            Update Password
          </BaseButton>
        </Flex>
      )}
    </form>
  );
};
