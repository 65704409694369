import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
export const TableCol = [
  {
    Header: 'Reviewee',
    accessor: 'col1',
  },
  {
    Header: 'Reviewer',
    accessor: 'col2',
  },
  {
    Header: 'Score',
    accessor: 'col3',
  },
  {
    Header: 'Status',
    accessor: 'col4',
  },
  {
    Header: 'Reviews recieved',
    accessor: 'col5',
  },
  {
    Header: 'Deadline',
    accessor: 'col6',
  },
  {
    Header: 'Action',
    accessor: 'col7',
  },
];

export function ReviewRating(review, totalReviewers) {
  const reviewWidth = `${33.666 * Number(review)}px`;
  return (
    <Flex justifyContent="space-between" maxWidth="153px" alignItems={'center'}>
      <div>
        <Wrapper>
          <Box
            bg="#3E7EEE"
            w={reviewWidth}
            h="8px"
            borderRadius="20px"
            position="relative"
            marginRight="5px"></Box>
        </Wrapper>
      </div>
      <div style={{ marginTop: '10px' }}>
        {Number(review) || 0}/{totalReviewers}
      </div>
    </Flex>
  );
}

const Wrapper = styled.div`
  width: 104px;
  height: 8px;
  background: #fafcff;
  border: 1px solid v(--grey-150);
  border-radius: 20px;
  position: absolute;
`;

export const ReviewsReportTable = [
  {
    _id: 'adgfshagdhfjagsw',
    reviewee: 'Floyd Miles',
    reviewer: 'Floyd Miless',
    score: '80%',
    status: 'Not Started',
    reviewsrecieved: ReviewRating(0),
    deadline: '01 March, 2019',
  },
  {
    _id: 'adgfsddhagdhfjagsw',
    reviewee: 'Floyd Miles',
    reviewer: 'Floyd Miless',
    score: '80%',
    status: 'completed',
    reviewsrecieved: ReviewRating(1),
    deadline: '01 March, 2019',
  },
  {
    _id: 'adgfsdedhagdhfjagsw',
    reviewee: 'Floyd Miles',
    reviewer: 'Floyd Miless',
    score: '80%',
    status: 'in progress',
    reviewsrecieved: ReviewRating(2),
    deadline: '01 March, 2019',
  },
  {
    _id: 'adgfsddfdhaegdhfjagsw',
    reviewee: 'Floyd Miles',
    reviewer: 'Floyd Miless',
    score: '80%',
    status: 'in progress',
    reviewsrecieved: ReviewRating(3),
    deadline: '01 March, 2019',
  },
];

export const reviewData = [
  {
    _id: 1,
    reviewee: 'John Smith',
    reviewer: ['Alice Johnson', 'Bob Thompson', 'Charlie Brown'],
    score: 90.0,
    status: 'not started',
    reviews_received: 2,
    deadline: '2023-04-30',
  },
  {
    _id: 2,
    reviewee: 'Jane Doe',
    reviewer: ['Elena Rodriguez', 'Frank Williams', 'George Lee'],
    score: 0.0,
    status: 'not started',
    reviews_received: 1,
    deadline: '2023-05-31',
  },
  {
    _id: 3,
    reviewee: 'Sam Johnson',
    reviewer: ['Karen Brown', 'Larry Davis', 'Mike Nguyen'],
    score: 80.0,
    status: 'in progress',
    reviews_received: 1,
    deadline: '2023-04-15',
  },
  {
    _id: 4,
    reviewee: 'Maggie Lee',
    reviewer: ['Nancy Smith', 'Oliver Davis', 'Peter Johnson'],
    score: 60.0,
    status: 'not started',
    reviews_received: 0,
    deadline: '2023-05-15',
  },
  {
    _id: 5,
    reviewee: 'David Chen',
    reviewer: ['Qing Wang', 'Rachel Jones', 'Steve Kim'],
    score: 75.0,
    status: 'not started',
    reviews_received: 1,
    deadline: '2023-04-25',
  },
  {
    _id: 6,
    reviewee: 'Linda Park',
    reviewer: ['Tom Lee', 'Uma Patel', 'Victor Chen'],
    score: 0.0,
    status: 'not started',
    reviews_received: 1,
    deadline: '2023-06-15',
  },
  {
    _id: 7,
    reviewee: 'Hannah Lee',
    reviewer: ['William Brown', 'Xavier Nguyen', 'Yolanda Hernandez'],
    score: 85.0,
    status: 'in progress',
    reviews_received: 2,
    deadline: '2023-04-30',
  },
  {
    _id: 8,
    reviewee: 'Victor Kim',
    reviewer: ['Zoe Wang', 'Aaron Davis', 'Brenda Smith'],
    score: 70.0,
    status: 'not started',
    reviews_received: 0,
    deadline: '2023-05-31',
  },
  {
    _id: 9,
    reviewee: 'Emily Chen',
    reviewer: ['Carl Jones', 'Diana Lee', 'Ethan Kim'],
    score: 0.0,
    status: 'not started',
    reviews_received: 0,
    deadline: '2023-06-15',
  },
  {
    _id: 10,
    reviewee: 'Frank Williams',
    reviewer: ['Grace Park', 'Henry Davis', 'Isabel Nguyen'],
    score: 95.0,
    status: 'completed',
    reviews_received: 2,
    deadline: '2023-05-31',
  },
];
