import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Grid, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import ErroCircle from '../../../../../assets/icons/Error-circle.png';
import { SpinnerComponent } from '../../../../../components';
import { useToastify } from '../../../../../hooks/useToastify';
import { VERIFY_LINE_MANAGER } from '../../../../../query/admin/line-manager/line-manager';

import { VerifiedLineManager } from './VerifiedLineManager';

const Container = styled(Grid)`
  max-width: 1240px;
  margin: 0 auto;
  height: 99vh;
  padding: 5rem 0;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.117188px;
    color: #333758;
    text-align: left;
  }

  .images {
    gap: 0.62rem;
    width: 12.5rem;
    height: fit-content;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-items: center;
    width: 600px;
    background: #fff;
    padding: 32px 24px;
    box-shadow: 0px 10px 32px 0px rgba(0, 0, 0, 0.08);
    gap: 24px;

    .line {
      width: 100%;
    }

    .image {
      padding: 20px 0 12px;
    }

    &-1 {
      color: #343859;
      font-size: 24px;
      font-weight: 700;
    }

    &-2 {
      color: var(--darker-grey, #666);
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 2rem 0;

    .text {
      width: 100%;
    }
  }
`;
const VerifyLineManager = () => {
  const getToken = useParams();
  const [verifyLineManager, { loading }] = useMutation(VERIFY_LINE_MANAGER);
  const { errorToast } = useToastify();
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    onVerify(token);
  }, []);

  const { token } = getToken;

  const onVerify = async (token) => {
    try {
      const res = await verifyLineManager({
        variables: {
          token,
        },
      });
      const { data } = res;
      if (data.verifyLineManager.status === 200) {
        setSuccess(true);
      } else errorToast(data.verifyLineManager.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  if (loading) {
    return <SpinnerComponent h="80vh" />;
  }
  return (
    <Container data-testid="verify-manager">
      {!success ? (
        <Grid className="text">
          <h1>Verify Line Manager</h1>
          <Image src={ErroCircle} />
          <Text fontSize={'24px'}>Verification failed. Your token probably expired.</Text>
        </Grid>
      ) : (
        <VerifiedLineManager />
      )}
    </Container>
  );
};
export { VerifyLineManager };
