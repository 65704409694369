export function transformJobFormDetails({ jobDetails, scoreCards, applicantsForm }) {
  applicantsForm?.fields.forEach((field) => {
    formPruneFields.forEach((fieldName) => {
      delete field[fieldName];
    });
    if (field.children) {
      field.children.forEach((field) => {
        formPruneFields.forEach((fieldName) => {
          delete field[fieldName];
        });
      });
    }
  });

  const copy = { ...jobDetails, formFields: applicantsForm.fields, scoreCards: [...scoreCards] };

  // JSON transformation
  JSONFields.forEach((field) => {
    const value = copy[field];
    const shouldTransform = !isString(value) && !isUndefined(value);
    if (shouldTransform) copy[field] = JSON.stringify(value);
  });

  // React Select value transformation
  optionFields.forEach((field) => {
    const option = copy[field];
    const shouldTransform = !isString(option) && !isUndefined(option);
    if (shouldTransform) copy[field] = getOptionValue(option);
  });

  // Date transforms
  dateFields.forEach((field) => {
    const value = copy[field];
    const date = isString(value) ? new Date(value) : value;
    copy[field] = date.toISOString().substring(0, 10);
  });

  pruneFields.forEach((field) => {
    delete copy[field];
  });

  // Skills transform
  copy.skills = copy.skills ? copy.skills.map((skill) => skill.value) : [];

  copy.scoreCards = copy.scoreCards.map(({ type: { value: type }, hint, stage, title }) => {
    return { type, hint, stage, title };
  });

  return copy;
}

export function transformAPIDetails(apiDetails) {
  const applicantsForm = apiDetails.formFields;
  const scoreCards = apiDetails?.scoreCards?.map((card) => {
    return { ...card, type: { label: card.type, value: card.type } };
  });

  delete apiDetails.formFields;
  delete apiDetails.scoreCards;

  const jobDetails = apiDetails;

  // JSON transformation
  JSONFields.forEach((field) => {
    const value = jobDetails[field];
    const shouldTransform = isString(value) && !isUndefined(value);
    if (shouldTransform)
      jobDetails[field] = value.startsWith('[')
        ? JSON.parse(value)
        : [{ type: 'paragraph', children: [{ text: value }] }];
  });

  // React Select value transformation
  optionFieldsFromAPI.forEach((field) => {
    const option = jobDetails[field];
    const shouldTransform = isString(option) && !isUndefined(option);
    if (shouldTransform) jobDetails[field] = { label: option, value: option };
  });

  // Date transforms
  dateFieldsFromAPI.forEach((field) => {
    const value = jobDetails[field];
    const shouldTransform = value && isString(value);
    if (shouldTransform) jobDetails[field] = new Date(value);
  });

  // Skills transform
  jobDetails.skills = jobDetails.skills
    ? jobDetails.skills.map((skill) => ({ label: skill, value: skill }))
    : [];

  // Assessment transform
  jobDetails.assessment = jobDetails.assessmentId;
  jobDetails.assessmentId = jobDetails.assessmentId ? jobDetails.assessmentId?._id : null;

  const dep = jobDetails.department;
  jobDetails.department = dep?.name && { label: dep.name, value: dep._id };

  return { applicantsForm, scoreCards, jobDetails };
}

const JSONFields = ['description'];
const optionFields = [
  'department',
  'type',
  'experience',
  'location',
  'country',
  'state',
  'city',
  'assessmentId',
];
const optionFieldsFromAPI = ['type', 'experience', 'location'];
const dateFields = ['deadline'];
const dateFieldsFromAPI = ['deadline', 'createdAt'];
const pruneFields = [
  'createdAt',
  'hiringManager',
  '_id',
  'applicants',
  'createdBy',
  'notes',
  'timeline',
  'assessment-switch',
  'assessment',
];

const formPruneFields = ['_id', 'isEditing', 'id'];

function isString(val) {
  return typeof val === 'string' || val instanceof String;
}

function isUndefined(val) {
  return typeof val === 'undefined' || val === undefined;
}

function getOptionValue(val, field = 'value') {
  if (val?.[field]) return val[field];
  return val;
}
