import React from 'react';
import { useNavigate } from 'react-router';
import { Button, Flex } from '@chakra-ui/react';

import { Logo } from 'components/shared/Svgs';

export const LNavBar = () => {
  const navigate = useNavigate();
  function onSubmit() {
    const emailSubject = encodeURIComponent('Inquiry about Your HRMS System');
    const emailBody = encodeURIComponent(
      `Hello, \n\nI am reaching out to express my interest in your HRMS (Human Resource Management System) after learning about it online.\n\nI am currently exploring options for an HRMS system to streamline our human resources processes and enhance overall efficiency within our organization.\n\nThank you in advance for your time and assistance. I look forward to hearing from you soon.`
    );

    const mailtoLink = `mailto:partnerships@enyata.com?subject=${emailSubject}&body=${emailBody}`;

    window.location.href = mailtoLink;
  }
  return (
    <Flex
      justifyContent={'space-between'}
      gap={'148px'}
      paddingTop={'16px'}
      alignItems={'center'}
      className="container">
      {Logo}
      <Flex gap={'8px'}>
        <Button
          onClick={onSubmit}
          p={'20px 27.5px'}
          borderRadius={'50px'}
          border={'solid 1px #568EF0'}
          bgColor={'#3E7EEE'}
          fontSize={'14px'}
          fontWeight={'600px'}
          lineHeight={'24px'}
          _hover={{ backgroundColor: '#3E7EEE' }}
          color={'#fff'}>
          Get Started
        </Button>
        <Button
          onClick={() => navigate('/login')}
          p={'20px 27.5px'}
          borderRadius={'50px'}
          border={'solid 1px #568EF0'}
          bgColor={'#3E7EEE'}
          fontSize={'14px'}
          fontWeight={'600px'}
          lineHeight={'24px'}
          _hover={{ backgroundColor: '#3E7EEE' }}
          color={'#fff'}>
          Login
        </Button>
      </Flex>
    </Flex>
  );
};
