import React from 'react';
import { useWatch } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton } from '../../../../../../components';
import { useReviewCycleForm } from '../../../../../../context/reviewCycleForm';
import { useSubmitReview } from '../../hooks/useSubmitReview';

function Summary(props) {
  const { control, handleSubmit } = useReviewCycleForm();
  const formData = useWatch({ control });
  // const formState = useFormState({ control });

  const responseAccess = getAccessText(formData.isSeenByReviewee, formData.isSeenByAll);
  const myData = {
    'Review Name': formData.title,
    Frequency: formData.frequency?.label,
    'Response Access': responseAccess,
    'Review Period': `${formData.reviewPeriod?.startDate} - ${formData.reviewPeriod?.endDate}`,
    Template: formData.templateId?.label,
  };

  const mySchedule = {
    'Review Date': formData.schedule?.startDate,
    'Due Date': formData.schedule?.endDate,
  };

  const participants = {
    'Employees Selected': formData.reviewCycle ? formData.reviewCycle.length : 0,
    'Self Evaluation': isTypeSelected('Self Evaluation'),
    'Manager’s Review': isTypeSelected("Manager's Review"),
    'Peer Review': isTypeSelected('Peer Review'),
  };

  function isTypeSelected(typeName) {
    return formData?.reviewType?.map((t) => t.name).includes(typeName) ? 'Yes' : 'No';
  }

  const { onCreate: onSubmit, loading } = useSubmitReview({ setShowModal: props.setShowModal });

  return (
    <SummaryRoot>
      <h1>Summary and send</h1>
      <p className="sub-heading">Review details</p>
      <div className="content">
        {Object.entries(myData).map(([key, value]) => {
          return (
            <div className="sub-content" key={key}>
              <p className="content-name">{key}</p>
              <p className="content-details">{value}</p>
            </div>
          );
        })}
        <hr />
      </div>

      <div>
        <p className="sub-heading">Schedule</p>
        <div className="content">
          {Object.entries(mySchedule).map(([key, value]) => {
            return (
              <div className="sub-content" key={key}>
                <p className="content-name">{key}</p>
                <p className="content-details">{value}</p>
              </div>
            );
          })}
          <hr />
        </div>
        <div>
          <p className="sub-heading">Partcipants</p>
          <div className="content">
            {Object.entries(participants).map(([key, value]) => {
              return (
                <div className="sub-content" key={key}>
                  <p className="content-name">{key}</p>
                  <p className="content-details">{value}</p>
                </div>
              );
            })}
            <hr />
          </div>
        </div>
      </div>
      <Flex justifyContent="space-between" mt="24px">
        <BaseButton variant="outline" onClick={() => props.setShowEndModal(false)}>
          Back
        </BaseButton>
        <BaseButton variant="dark" onClick={handleSubmit(onSubmit)} isLoading={loading}>
          Complete
        </BaseButton>
      </Flex>
    </SummaryRoot>
  );
}

export function SummaryItem({ myData }) {
  return (
    <div>
      <p>Review details</p>
      <div className="content">
        {Object.entries(myData).map(([key, value]) => {
          return (
            <div key={key}>
              <p>{key}</p>
              <p>{value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function getAccessText(seenByReviewee = false, seenByAll = false) {
  let text = "Review is visible to admin, employee's manager";
  if (seenByReviewee && !seenByAll) {
    text = text + ', reviewee';
  } else if (seenByAll) {
    text = text + ', reviewee, and all other participants';
  }

  return text;
}

const SummaryRoot = styled.div`
  width: calc(100% - 24px);
  //max-width: 785px;
  margin: auto;
  padding: 40px 24px;
  background-color: white;
  border: 1px solid #f2f2f2;
  //   height:100vh;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #23273b;
  }

  hr {
    height: 1px;
    border-color: rgba(65, 97, 128, 0.151934);

    margin: 0 24px 24px 24px;
  }

  .sub-heading {
    margin-top: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-bottom: 8px;
  }

  .content {
    border: 1px solid #f2f2f2;
  }

  .sub-content {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    justify-items: end;
    display: flex;
    gap: 80px;
    // grid-template-columns: auto auto;
    color: #83889d;
    // align-items: center;
    text-align: start;

    .content-name {
      width: 152px;
      //   margin-top:24px;
      padding: 24px;
      // width: 30%;
    }

    .content-details {
      text-align: left;
      padding: 24px;

      width: 70%;
      //   margin-top:24px;
    }
  }
`;

export default Summary;
