import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

export const HeadingText = ({ headingText, textAlign, mt, mb }) => {
  return (
    <>
      <Heading
        fontFamily="Karla"
        fontSize="16px"
        fontWeight="700"
        lineHeight="19px"
        letterSpacing="-0.117188px"
        fontStyle="normal"
        color="#23273B"
        // marginBottom="10px"
        textAlign={textAlign}
        marginTop={mt}
        marginBottom={mb}>
        {headingText}
      </Heading>
    </>
  );
};

export const TextText = ({ subText, textAlign, mt, mb }) => {
  return (
    <>
      <Text
        fontFamily="Karla"
        fontSize="15px"
        fontWeight="400"
        lineHeight="20px"
        letterSpacing="-0.117188px"
        fontStyle="normal"
        color="#85879B"
        opacity="0.4"
        mixBlendMode="normal"
        textAlign={textAlign}
        marginTop={mt}
        marginBottom={mb}>
        {subText}
      </Text>
    </>
  );
};
