import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Flex, Link } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { useToastify } from 'hooks/useToastify';
import { EDIT_GOAL } from 'query';

import { BaseInput } from 'components';

import PersonInitials from './Initials';
function convertDateTime(dateTime) {
  if (dateTime) {
    const newDate = new Date(dateTime);
    const formattedDate = format(newDate, 'dd-MM-yyyy . hh:mm a');
    return <>{formattedDate}</>;
  }
}
function Timelines(props) {
  const { successToast, errorToast } = useToastify();
  const _id = props?.data?._id;

  const comment = props?.data?.comment;

  const [message, setMessage] = useState('');

  const [editGoal] = useMutation(EDIT_GOAL, {});

  async function editIndividualGoal(_id, message) {
    if (message !== '') {
      try {
        const response = await editGoal({
          variables: {
            filters: {
              _id: _id,
            },
            data: {
              comment: [
                ...comment,
                {
                  message: message,
                },
              ],
            },
          },
        });
        response?.data?.editGoal?.status === 200
          ? successToast('Comment added sucessfully')
          : errorToast(response?.data?.editGoal?.message);
      } catch (error) {
        errorToast(error);
      } finally {
        setMessage('');
        props.refetch();
      }
    }
  }

  const Timeline = [props.data]?.map(
    ({ creator, comment, createdAt } = { creator: '', comment: [], createdAt: null }, index) => {
      const convertedDateTime = convertDateTime(createdAt);
      const comments = comment?.map((comment, index) => {
        return (
          <Box key={index}>
            <Box>
              <Wrapper>
                <Flex>
                  <PersonInitials creator={creator} />
                  <nav>
                    <div>
                      <h1>
                        {creator?.firstName} {creator?.lastName}
                      </h1>
                    </div>
                    <div>
                      <p>{convertedDateTime}</p>
                    </div>
                  </nav>
                </Flex>
                <div style={{ marginLeft: '45px', marginBottom: '24px' }}>
                  <p>{comment?.message}</p>
                </div>
                <hr />
              </Wrapper>
            </Box>
          </Box>
        );
      });
      return <Box key={index}>{comments}</Box>;
    }
  );

  return (
    <Box>
      <Flex justifyContent={'space-between'} mb={'8px'}>
        <h1
          style={{
            color: '#23273B',
            fontWeight: '400',
            fontSize: '16px',
            letterSpacing: '-0.0012em',
          }}
          data-testid="timeline-heading">
          Timeline
        </h1>
        <Link
          data-testid={'handleComment'}
          onClick={() => editIndividualGoal(_id, message)}
          style={{ color: '#3E7EEE', cursor: 'pointer', textDecoration: 'none' }}>
          Add Comment
        </Link>
      </Flex>
      <Flex
        pb={'24px'}
        pl={'32px'}
        pr={'32px'}
        border={'1px solid #F2F2F2'}
        borderRadius={'border-radius: 4px'}>
        <div
          style={{
            borderLeft: '1px solid #3E7EEE',
            minHeight: '100%',
            marginRight: '24px',
            marginTop: '24px',
          }}>
          .
        </div>
        <div style={{ width: '100%' }}>
          {Timeline}
          <Box marginTop={'40px'}>
            <BaseInput
              value={message}
              onChange={({ target }) => setMessage(target.value)}
              type="textarea"
              placeholder="Write message"
              data-testid="message-input"
            />
            <Btn data-testid={'handleAddComment'} onClick={() => editIndividualGoal(_id, message)}>
              Submit
            </Btn>
          </Box>
        </div>
      </Flex>
    </Box>
  );
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #23273b;
  }
  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #83889d;
  }
`;
const Btn = styled.button`
  background: #23273b;
  border-radius: 4px;
  padding: 12px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-top: 16px;
  float: right;
`;
export { Timelines };
