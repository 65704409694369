import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { SpinnerComponent, TimelineCard } from '../../../../../components';
import { FETCH_NOTES } from '../../../../../query';

const Container = styled.div`
  display: grid;
  gap: 24px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  .timeline-width {
    max-width: 528px;
  }
  .notes-width {
    max-width: 582px;
  }

  @media only screen and (max-width: 500px) {
    . timeline-width,
    .notes-width {
      max-width: 100%;
    }
  }
`;

const TimeLineNotes = (props) => {
  const [notesData, setNotesData] = useState();
  const { id } = useParams();

  // const filterDates = (list) => {
  //   if (list) {
  //     // this gives an object with data as keys
  //     const groups = list?.reduce((groups, item) => {
  //       const date = item.date?.split('T')[0];
  //       if (!groups[date]) {
  //         groups[date] = [];
  //       }
  //       groups[date].push(item);
  //       return groups;
  //     }, {});

  //     // Edit: to add it in the array format instead
  //     const groupArrays = Object.keys(groups)?.map((date) => {
  //       return {
  //         date,
  //         items: groups[date],
  //       };
  //     });

  //     setData(groupArrays);
  //   }
  // };

  const {
    data: notes,
    loading,
    refetch: refetchNotes,
  } = useQuery(FETCH_NOTES, {
    variables: {
      jobId: id,
    },
  });

  const fetchnotes = notes?.fetchNotes.data;

  useEffect(() => {
    setNotesData(fetchnotes);
  }, [fetchnotes]);

  if (loading || props.loading) {
    return (
      <Grid minH="20rem" placeContent="center" mt="20rem">
        <SpinnerComponent data-testid="loading" />
      </Grid>
    );
  }

  return (
    <Container>
      <div className="timeline-width">
        <TimelineCard
          data={props.timelineData}
          title={'Timeline'}
          withNotes={false}
          loading={props.loading}
        />
      </div>
      <div className="notes-width">
        <TimelineCard
          refetchNotes={refetchNotes}
          data={notesData}
          title={'Notes'}
          withNotes={true}
          loading={loading}
        />
      </div>
    </Container>
  );
};

export { TimeLineNotes };
