import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';

import { Button } from 'components';

import { addsection, linear, mchoice, radio, rating } from '../images';

const Box = styled.div`
  position: sticky;
  top: 0;
  width: 324px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #f2f2f2;
  height: fit-content;

  h3 {
    border-radius: 8px 8px 0px 0px;
    background: #f3f4f6;
    padding: 16px 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242424;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Fields = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #f2f2f2;
  border-radius: 8px 8px 0px 0px;

  img {
    opacity: 0.7;
  }
`;

const buttonStyle = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  color: ' #666E7D',
  borderTop: '1px solid #F2F2F2',
  borderBottom: '1px solid #F2F2F2',
  borderRadius: '4px',
  padding: '14px 0',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '16px 0',
};

const inputStyle = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '21px',
  color: ' #666E7D',
  border: '1px solid #F2F2F2',
  borderRadius: '4px',
  padding: '14px 32px',
  width: '100%',
};

const ConfigureBox = ({
  setSectionData,
  sectionData,
  setOpenEdit,
  openEdit,
  selected,
  validateQuestions,
}) => {
  const { errorToast } = useToastify();

  const { questions, ...rest } = sectionData;

  const buttonActions = (action) => {
    if (action === 'addSection') {
      if (selected || openEdit) {
        errorToast('Please save section');
      } else {
        setOpenEdit(true);
        setSectionData({ title: '', questions: [] });
      }
    } else if (openEdit || selected) {
      if (validateQuestions) {
        if (action === 'multipleChoice') {
          setData('multipleChoice');
        }
        if (action === 'radio') {
          setData('radio');
        }
        if (action === 'linearScale') {
          setData('linearScale');
        }
        if (action === 'rating') {
          setData('rating');
        }
      } else {
        errorToast('Please fill question field');
      }
    } else {
      errorToast('Please select a section to edit!');
    }
  };

  const setData = (questionType) => {
    if (questionType === 'rating' || questionType === 'linearScale') {
      setSectionData({
        ...rest,
        questions: [
          ...questions,
          {
            questionType: questionType,
            options: [
              { choice: '1', value: 1 },
              { choice: '2', value: 2 },
              { choice: '3', value: 3 },
              { choice: '4', value: 4 },
              { choice: '5', value: 5 },
            ],
          },
        ],
      });
    } else {
      setSectionData({
        ...rest,
        questions: [
          ...questions,
          {
            question: '',
            questionType: questionType,
            options: [],
          },
        ],
      });
    }
  };

  return (
    <Box>
      <h3>Configure</h3>
      <Flex flexDirection={'column'}>
        <Button
          dataTestId={'addSection'}
          title={'Add Section'}
          img={addsection}
          style={buttonStyle}
          onClick={() => buttonActions('addSection')}
        />
      </Flex>
      <Fields>
        <Text color={'#242424'} fontWeight={600} fontSize={'14px'} marginBottom={'24px'}>
          Select Field
        </Text>
        <Flex flexDirection={'column'} gap={'24px'} className="buttons">
          <Button
            dataTestId={'multipleChoice'}
            img={mchoice}
            title={'Multiple Choice'}
            style={inputStyle}
            onClick={() => buttonActions('multipleChoice')}
          />
          <Button
            dataTestId={'radio'}
            img={radio}
            title={'Radio Button'}
            style={inputStyle}
            onClick={() => buttonActions('radio')}
          />
          <Button
            dataTestId={'linearScale'}
            img={linear}
            title={'Linear Scale'}
            style={inputStyle}
            onClick={() => buttonActions('linearScale')}
          />
          <Button
            dataTestId={'rating'}
            img={rating}
            title={'Rating'}
            style={inputStyle}
            onClick={() => buttonActions('rating')}
          />
        </Flex>
      </Fields>
    </Box>
  );
};

export { ConfigureBox };
