import { Grid } from '@chakra-ui/react';

import { Assessment } from './Assessment';
import { HeaderImage } from './HeaderImage';
import { JobDescription } from './JobDescription';
import { JobTitle } from './JobTitle';
import { Skills } from './Skills';

function ConfigureJobDetails() {
  return (
    <Grid templateColumns="100%" border="1px solid var(--grey-150)" bg="white">
      <HeaderImage />
      <JobTitle />
      <JobDescription />
      <Skills />
      <Assessment />
    </Grid>
  );
}

export { ConfigureJobDetails };
