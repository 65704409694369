import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { Button } from 'components';

import { close, rating } from '../images';

import { AddMode } from './ConfigureCard';
import { Option } from './styles';

const OptionCard = ({
  questionId,
  id,
  option,
  handleOptionActions,
  handleAddOptions,
  question,
  setEditMode,
  editMode,
  setValue,
  value,
}) => {
  const onEditOptions = () => {
    handleOptionActions('edit', value, questionId, id);
    setEditMode('');
  };

  React.useEffect(() => {
    if (editMode === id) setValue(option);
  }, [editMode]);

  return question?.questionType === 'rating' ? (
    <Option>
      <Flex className={'stars'}>
        {[...Array(option?.value)]?.map((star, e) => (
          <div key={e} className={'star'}>
            <img src={rating} />
          </div>
        ))}
      </Flex>
    </Option>
  ) : editMode === id ? (
    <Box w={'100%'}>
      <AddMode
        option={value}
        setAddMode={setEditMode}
        addMode={editMode}
        handleAddOptions={handleAddOptions}
        onAddOptions={onEditOptions}
      />
    </Box>
  ) : (
    <Option onClick={question?.questionType !== 'linearScale' ? () => setEditMode(id) : null}>
      <Flex gap={'24px'} alignItems={'center'}>
        <Text className={'option-text'}>{option?.choice}</Text>
        <Box as={'span'} padding={'4px 8px'} bg={'#f2f2f2'} fontSize={'10px'} borderRadius={'4px'}>
          {option?.value}
        </Box>
      </Flex>
      {question?.questionType !== 'linearScale' && (
        <Button
          dataTestId={'deleteOption'}
          img={close}
          onClick={() => handleOptionActions('delete', option, questionId)}
        />
      )}
    </Option>
  );
};

export { OptionCard };
