import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useEmployeeApparaisalForm } from 'context/EmployeeReviewForm';
import { useToastify } from 'hooks/useToastify';
import { formatDayOfTheMonth } from 'pages/admin/leave-management/Requests/helper';
import { EDIT_PERFORMANCE_RESPONSE, SUBMIT_A_REVIEW } from 'query';
import { getItem } from 'utils';

import { BaseInput, CustomButton, EmptyState, ModalBackButton, SpinnerComponent } from 'components';

import { BaseLayout } from '../Layout';

import { ReviewQuestions } from './component';
const isTesting = process.env.NODE_ENV === 'test';

export const ReviewQuestionSectionPage = ({
  fetchedData,
  loading,
  defaultValues,
  isEditing,
  refetch,
}) => {
  const [comment, setComment] = React.useState('');
  const navigate = useNavigate();
  const location = isTesting
    ? {
        state: {
          reviewType: 'Peer',
          userId: '390-12nfbdjskfsdfueir8',
        },
      }
    : useLocation();
  const user = getItem('hrm_user');
  const { successToast, errorToast } = useToastify();

  const { register, handleSubmit, control, reset } = useEmployeeApparaisalForm();

  React.useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { fields } = useFieldArray({ control, name: 'questions' });

  const reviewType = location.state.reviewType;
  const theOneToBeReviewd = location.state.userId;

  const reviewee = fetchedData?.reviewee;
  const reviewTemplateQuestions = fetchedData?.reviewTemplate;
  const templateId = fetchedData?.reviewTemplate?.templateId?.questions;
  // const submittedRes = fetchedData?.responses;

  function getTotal() {
    return (
      templateId?.map((q) => q?.questions?.length).reduce((total, cur) => total + cur, 0) || 'NA'
    );
  }

  const summary = {
    Section: templateId?.length || 'N/A',
    Question: getTotal(),
    deadline: formatDayOfTheMonth(reviewTemplateQuestions?.schedule?.endDate) || 'N/A',
  };

  const [submitReview, { loading: sending }] = useMutation(SUBMIT_A_REVIEW);
  const [editReview, { loading: editing }] = useMutation(EDIT_PERFORMANCE_RESPONSE);

  const onSubmit = async (data) => {
    const responses = data?.questions?.map((section, index) => {
      return {
        section: {
          title: templateId[index]?.title,
          questions: section?.questions?.map(({ question, options, questionType }) => {
            return {
              question: question,
              questionType: questionType,
              options: options,
            };
          }),
        },
      };
    });

    const toSend = {
      reviewId: fetchedData?.reviewTemplate?._id,
      employeeId: theOneToBeReviewd,
      reviewer: {
        reviewType: reviewType,
        reviewer: user?._id,
        responses: responses,
        comments: comment,
      },
    };

    try {
      const res = !isEditing
        ? await submitReview({ variables: { data: toSend } })
        : await editReview({
            variables: {
              data: toSend,
              filters: { reviewerId: user?._id },
            },
          });

      if (!isEditing) {
        const message = res?.data?.addResponse?.message;
        res?.data?.addResponse?.status === 201 ? successToast(message) : null;
      } else if (isEditing) {
        const message = res?.data?.updateResponse?.message;
        res?.data?.updateResponse?.status === 200 ? successToast(message) : null;
      }
    } catch (error) {
      errorToast('Error Occured');
    } finally {
      refetch();
      navigate('/employee/appraisal');
    }
  };

  const goTo = () => {
    reviewType?.includes('Peer')
      ? navigate('/employee/appraisal-review')
      : navigate(`/employee/appraisal`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid={'appraisal-review-questions'}>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <BaseLayout data-testid="single-review">
          <>
            <Flex alignItems={'center'} gap="2.4rem">
              <ModalBackButton type="button" data-testid="modal-back-btn" onClick={goTo} />
              <h1 className="heading">{`Review ${reviewee?.firstName} ${reviewee?.lastName}`}</h1>
            </Flex>
            <BtnWrap>
              {/* <CustomButton
                variant="black--outline"
                name={'Save & Close'}
                mW="195px"
                h="56px"
                type="button"
                type={'submit'}
              /> */}
              <CustomButton
                variant="grey__button"
                name={'Submit'}
                mW="195px"
                h="56px"
                type={'submit'}
                loading={sending || editing}
                bgColor={'#999fa3'}
              />
            </BtnWrap>
          </>
          <ContentWrap>
            <p className="title">
              <span>Assessment Title:</span> {reviewTemplateQuestions?.title}
            </p>
            <div className="assessment__details">
              <div className="assessment__details--header">
                <p>Section</p>
                <p>Question</p>
                <p>Deadline</p>
              </div>
              <div className="assessment__details--row">
                <p>{summary.Section}</p>
                <p>{summary.Question}</p>
                <p>{summary.deadline}</p>
              </div>
            </div>

            {reviewTemplateQuestions?.templateId === null ? (
              <EmptyState message={'No Review QuestionS Found.'} />
            ) : (
              <>
                {fields?.map((field, index) => {
                  return (
                    <ReviewQuestions
                      key={field?.id}
                      index={index}
                      sectionQst={field}
                      register={register}
                      control={control}
                      // responses={submittedRes}
                    />
                  );
                })}
              </>
            )}

            <div className="comment__section">
              <div className="heading">
                <p className="title">Comment</p>
              </div>
              <BaseInput
                type="textarea"
                placeholder="Comment"
                rows="5"
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          </ContentWrap>
        </BaseLayout>
      )}
    </form>
  );
};

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  .black--outline {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
  }
`;
const ContentWrap = styled.div`
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0012em;
    color: #83889d;

    span {
      color: #272932;
    }
  }
  .assessment__details {
    background: #fcfcfd;
    border: 1px solid #f0f2f5;
    border-radius: 2px;
    margin-top: 3.4rem;
    overflow-x: auto;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #4d4d4d;
    }

    &--header {
      display: grid;
      grid: 1fr / repeat(3, minmax(150px, auto));
      border-bottom: 1px solid #dee2e8;
      padding: 3.2rem 4.6rem 1.6rem;
      min-width: 500px;
    }
    &--row {
      min-width: 500px;
      display: grid;
      grid: 1fr / repeat(3, minmax(155px, auto));
      padding: 2rem 4.6rem 1.6rem;
    }
  }

  .comment__section {
    .heading {
      margin-bottom: 1.6rem;
    }
  }
`;
