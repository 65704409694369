import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const InputFor = ({ title, subtitle, marginBottom }) => {
  return (
    <Box marginBottom={marginBottom}>
      <Text
        fontWeight="700"
        fontSize="1.6rem"
        lineHeight="1.8rem"
        color="#23273B"
        letterSpacing="-0.02rem">
        {title}
      </Text>
      <Text
        mt="1rem"
        fontWeight="400"
        fontSize="1.5rem"
        lineHeight="2rem"
        color="#85879B"
        letterSpacing="-0.02rem">
        {subtitle}
      </Text>
    </Box>
  );
};

const FieldTitle = () => {
  return (
    <Box>
      <InputFor
        title="Business Name"
        subtitle="Enter the name of your Business or Organization"
        marginBottom="6.7rem"
      />
      <InputFor
        title="Upload Company Logo"
        subtitle="Upload your Company Logo"
        marginBottom="10rem"
      />
      <InputFor
        title="Business Details"
        subtitle="Provide your Company Details."
        marginBottom="18rem"
      />
      <InputFor
        title="EmployeeID Prefix"
        subtitle="Provide your Company's Prefix"
        marginBottom="6.7rem"
      />

      <InputFor title="Address" subtitle="Add your Organization's Address(es)" />
    </Box>
  );
};

export { FieldTitle };
