import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { getItem } from '../utils';

function PrivateRoute({ authRoute = '/admin/login', children }) {
  const token = React.useMemo(() => getItem('token'), []);
  const navigate = useNavigate();

  function redirect() {
    navigate(authRoute);
  }

  React.useEffect(() => {
    try {
      const decoded = jwtDecode(token);
      // check if employee is accessing admin route and send them back to employee
      if (!decoded.isDefaultAdmin) {
        navigate('/employee');
      }
    } catch (error) {
      redirect();
    }
  }, []);
  return <>{children}</>;
}

export default PrivateRoute;
