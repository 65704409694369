import { profileInfo } from './data';

export function filterLabels(label) {
  let filter;
  switch (label) {
    case 'Personal Information':
      filter = profileInfo.basicInfoData;
      break;
    case 'Medical Information':
      filter = profileInfo.medicalInfoData;
      break;
    case 'Address':
      filter = profileInfo.addressInfo;
      break;
    case 'Emergency Contact':
      filter = profileInfo.emergencyContact;
      break;
    case 'Bank Account Information':
      filter = profileInfo.bankAccountInfo;
      break;
    case 'Employment Information':
      filter = profileInfo.employmentInfo;
      break;
    case 'Current Compensation':
      filter = profileInfo.currentCompensationInfo;
      break;

    default:
      filter = [];
      break;
  }
  return filter;
}

export function formatIncomingDate(dateVal) {
  return dateVal?.substr(0, 10);
}
