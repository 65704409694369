import React from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { Box, Flex, Grid, Input, Text } from '@chakra-ui/react';

import { CheckboxWrapper, CustomCheckbox } from '../../../../../../components';
import { useReviewCycleForm } from '../../../../../../context/reviewCycleForm';
import { inputError } from '../../../../../../utils';

export function ParticipantType({ index, ...props }) {
  const { control, setValue } = useReviewCycleForm();
  const formState = useFormState({ control });
  const reviewType = useWatch({ control, name: 'reviewType' }) || [];
  const checked = !!reviewType.find((type) => type.name === props.name);
  const score = reviewType.find((type) => type.name === props.name)?.score || '';

  function handleCheck() {
    if (checked) {
      setValue(
        'reviewType',
        reviewType.filter((type) => type.name !== props.name)
      );
    } else {
      if (reviewType.length < 3) setValue('reviewType', [...reviewType, props]);
    }
  }

  function onScoreChange(value) {
    setValue(
      'reviewType',
      reviewType.map((type) => (type.name === props.name ? { ...type, score: value } : type))
    );
  }

  return (
    <Grid templateColumns="auto 202px" gap="24px">
      <Grid
        templateColumns="max-content auto"
        p="16px"
        columnGap="16px"
        backgroundColor="brand.bg"
        border="1px solid transparent"
        borderColor="brand.grey.150"
        borderRadius="3px">
        <CheckboxWrapper>
          <CustomCheckbox checked={checked} onChange={handleCheck} variant="green-checkbox" />
        </CheckboxWrapper>

        <Box>
          <Text fontSize="1.4rem" fontWeight="600">
            {props.name}
          </Text>
          <Text fontSize="1.2rem" fontWeight="600" color="brand.grey.text">
            {props.description}
          </Text>
        </Box>
      </Grid>

      {checked ? (
        <Flex justifyContent="center" flexDirection="column">
          <Grid
            templateColumns="100px  max-content"
            gap="8px"
            h="auto"
            alignItems="center"
            visibility={checked ? 'visible' : 'hidden'}>
            <Input
              value={score}
              onChange={(e) => onScoreChange(e.target.value)}
              type="number"
              minH="56px"
              backgroundColor="brand.bg"
              color="brand.primary"
              fontSize="2rem"
              textAlign="center"
              _focusVisible={{
                borderColor: 'brand.primary',
                boxShadow: '0 0 0 1px var(--primary)',
              }}
            />
            <Text fontSize="1.2rem">Allocate score(%)</Text>
          </Grid>
          {inputError(formState.errors, `reviewType.${index}.score`)}
        </Flex>
      ) : null}
    </Grid>
  );
}
