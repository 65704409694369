import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';
import { FETCH_ALL_LEAVE_REQUEST, FETCH_SINGLE_LEAVE_REQUEST, FORCE_UPDATE } from 'query';
import { formatDate } from 'utils';

import { BaseInput, CustomButton, SpinnerComponent } from 'components';

import { DenyRoot } from './DenyPrompt';

export const ForceDenyPrompt = ({ cancel, leaveId, refetch }) => {
  const [reason, setReason] = React.useState('');
  const [forceUpdateStatus, { loading }] = useMutation(FORCE_UPDATE);

  const { errorToast, successToast } = useToastify();

  const { data, loading: fetching } = useQuery(FETCH_SINGLE_LEAVE_REQUEST, {
    variables: { leaveId: leaveId },
  });

  const leaveData = data?.fetchSingleRequest?.data;

  async function forceDenyLeave() {
    const date = new Date().toISOString();
    const toSend = {
      date: date,
      status: 'rejected',
      reasonForDenial: reason,
    };
    if (reason !== '') {
      try {
        const response = await forceUpdateStatus({
          variables: {
            data: toSend,
            filters: {
              _id: leaveId,
            },
          },
          refetchQueries: [
            {
              query: FETCH_ALL_LEAVE_REQUEST,
              variables: {
                filter: {},
                page: 1,
              },
            },
          ],
        });
        const res = response.data.forceApproveOrDenyLeaveRequest;
        const { status, message } = res;
        status === 200 ? successToast(message) : errorToast('Error occured');
      } catch (error) {
        return error;
      } finally {
        refetch();
        cancel({ showModal: false });
      }
    } else {
      errorToast('Provide a reason');
    }
  }

  const startDate = formatDate(leaveData?.startDate);
  const endDate = formatDate(leaveData?.endDate);
  return (
    <DenyRoot className="" data-testid="force-deny-prompt">
      {fetching ? (
        <SpinnerComponent />
      ) : (
        <>
          <h2>Are you sure?</h2>
          <div className="subtexts">
            <p className="qst">
              You are about to deny
              <span>
                {` ${leaveData?.employeeId?.firstName} ${leaveData?.employeeId?.lastName} `}
              </span>
              leave request:
            </p>
            <p className="req--date">{`${startDate || ''} - ${endDate || ''}`}</p>
          </div>
          <h4 className="stress--qst">
            This will override any approval flow steps and immediately deny the request.
          </h4>

          <BaseInput
            onChange={(e) => setReason(e.target.value)}
            type="textarea"
            fontSize={'1.6rem'}
            color={'var(--grey-600'}
            placeholder={'Provide a reason for denying the leave request'}
            data-testid="reason"
          />
        </>
      )}

      <Flex justifyContent={'flex-end'} gap="0.8rem" mt="6.4rem">
        <CustomButton
          onClick={() => cancel({ showModal: false })}
          name="Cancel"
          variant="black--outline"
          mW="9.5rem"
          h="4.3rem"
        />
        <CustomButton
          onClick={forceDenyLeave}
          name="Force Deny Request"
          variant="black"
          mW="14.5rem"
          h="4.3rem"
          loading={loading}
        />
      </Flex>
    </DenyRoot>
  );
};
