import React from 'react';
import { Flex, FormControl } from '@chakra-ui/react';
import styled from '@emotion/styled';
import CloseIcon from 'assets/icons/close-grey.svg';

import { CustomButton, CustomCheckbox, CustomRadio, SimpleModal } from 'components';

export const ShareFolderModal = ({
  openShareModal,
  setOpenShareModal,
  handleSubmit,
  handleCheckBoxChange,
  checked,
  departmentData,
  id,
  name,
  checkedData,
  optionType,
  register,
  loading,
}) => {
  return (
    <SimpleModal showModal={openShareModal} width={'748px'}>
      <Wrapper>
        <p className="header">Share With</p>
        <Flex>
          <CustomRadio
            options={[
              { label: 'Everyone', value: 'everyone' },
              { label: 'Departments', value: 'departments' },
            ]}
            showLabel={true}
            {...register('sharedRight', { required: true })}
          />
        </Flex>
        {optionType === 'Departments' && (
          <RenderOptions
            handleCheckBoxChange={handleCheckBoxChange}
            checked={checked}
            data={departmentData}
            id={id}
            name={name}
            checkedData={checkedData}
          />
        )}
        <Flex justify={'flex-end'} alignItems={'center'} gap={'8px'}>
          <CustomButton
            variant={'black--outline'}
            name={'Cancel'}
            mW={'94px'}
            h={'40px'}
            onClick={() => setOpenShareModal(false)}
          />
          <CustomButton
            variant={'black'}
            name={'Save'}
            mW={'94px'}
            h={'40px'}
            onClick={handleSubmit}
            loading={loading}
          />
        </Flex>
      </Wrapper>
    </SimpleModal>
  );
};

export const RenderOptions = ({ handleCheckBoxChange, checked, data, id, name, checkedData }) => {
  return (
    <RenderOptionWrapper>
      {checkedData.length > 0 && (
        <Flex className="view-options" flexWrap={'wrap'} gap={'8px'} key={id}>
          {checkedData.map((e, index) => {
            return (
              <Flex key={e.id} className="dept" gap={'12px'}>
                <p className="dept-name">{e.name}</p>
                <img
                  src={CloseIcon}
                  alt="close icon"
                  onClick={() =>
                    handleCheckBoxChange(checkedData[index]?.id, checkedData[index]?.name)
                  }
                  data-testid={'uncheck-selected'}
                />
              </Flex>
            );
          })}
        </Flex>
      )}
      <FormControl mt={'24px'}>
        {data?.map((item, index) => {
          return (
            <CustomCheckbox
              key={item?._id}
              id={item?._id}
              label={item?.name}
              testid={'department-names'}
              onChange={() => handleCheckBoxChange(id[index], name[index])}
              checked={checked[index]}
              variant={'green-checkbox'}
            />
          );
        })}
      </FormControl>
    </RenderOptionWrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;

  .header {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.117188px;
    color: #23273b;
    margin-bottom: 24px;
  }
`;

const RenderOptionWrapper = styled.div`
  .view-options {
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    padding: 12px;
    margin-top: 24px;

    .dept {
      background: #fafcff;
      border: 1px solid #f2f2f2;
      border-radius: 20px;
      padding: 8px;

      .dept-name {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.117188px;
        color: #23273b;
      }
    }
  }
`;
