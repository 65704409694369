import axios from 'axios';

const postRequest = (url, body) => {
  return axios.post(url, body);
};
const putRequest = (url, body) => {
  return axios.put(url, body);
};
const getRequest = (url) => {
  return axios.get(url);
};
export { getRequest, postRequest, putRequest };
