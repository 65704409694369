import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Box, Flex, GridItem, Image, Input, SimpleGrid, Text } from '@chakra-ui/react';
import { useCountriesStatesCities } from 'hooks';

import { BaseDropdown } from 'components';

const ExtraFields = (props) => {
  const { fields } = props;
  return (
    <Box mt="4rem">
      <Text
        lineHeight="1.3rem"
        fontSize="1.4rem"
        color="#23273B"
        letterSpacing="-0.02rem"
        mb="1.2rem">
        Other Locations
      </Text>
      {fields.map((item, index) => (
        <SingleExtraField key={item.id} item={item} index={index} {...props} />
      ))}
    </Box>
  );
};

function SingleExtraField({ item, index, register, fieldName, remove, trashIcon, control }) {
  const watchCountry = useWatch({ control, name: `locations.${index}.country` });
  const watchState = useWatch({ control, name: `locations.${index}.state` });
  const { states, cities, countries } = useCountriesStatesCities({
    country: watchCountry,
    state: watchState,
  });

  return (
    <Box key={item.id} mb="3rem">
      <Flex alignItems="center" justifyContent="flex-end" mb="1.2rem">
        <button
          onClick={() => {
            remove(index);
          }}>
          <Flex alignItems="center">
            <Image src={trashIcon} height="1.5rem" />
            <Text ml="1rem" color="#FA4A4A" fontSize="1.4rem" textDecoration="underline">
              Delete
            </Text>
          </Flex>
        </button>
      </Flex>

      <SimpleGrid columns={2} gap="1.6rem">
        <GridItem colSpan={2}>
          <Input
            {...register(`${fieldName}.${index}.address`)}
            placeholder="Enter Address"
            type="text"
            fontSize="1.4rem"
            height="4.8rem"
            backgroundColor="#F7F9FB"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Controller
            defaultValue=""
            control={control}
            name={`${fieldName}.${index}.country`}
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Select Country"
                border="1px solid #E0E0E0"
                placeholder="Select a country"
                options={countries}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Controller
            defaultValue=""
            control={control}
            name={`${fieldName}.${index}.state`}
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Select State"
                border="1px solid #E0E0E0"
                placeholder="Select a state"
                options={states}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Controller
            defaultValue=""
            control={control}
            name={`${fieldName}.${index}.city`}
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Select City"
                border="1px solid #E0E0E0"
                placeholder="Select a city"
                options={cities}
              />
            )}
          />
        </GridItem>
      </SimpleGrid>
    </Box>
  );
}

export { ExtraFields };
