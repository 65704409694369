import React from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_SINGLE_TRAINING } from 'query';

import { ModalBackButton, SpinnerComponent } from 'components';

const SingleTrainingView = ({ setShowFull, id }) => {
  const { data, loading, refetch } = useQuery(FETCH_SINGLE_TRAINING, {
    variables: {
      filter: {
        trainingId: id,
      },
    },
  });

  React.useEffect(() => {
    refetch();
  }, []);

  const single = data?.fetchSingleTraining?.data;
  return (
    <Card data-testid="single-training-view">
      <Grid maxW={'740px'} gap="4.8rem">
        <div className="header">
          <ModalBackButton data-testid="modal-back-btn" onClick={setShowFull} />
          <p>{single?.name}</p>
        </div>
        <div className="content">
          {loading ? (
            <SpinnerComponent />
          ) : (
            <TrainingCard data={single} heading={'Training Program Details'} />
          )}
        </div>
      </Grid>
    </Card>
  );
};

const TrainingCard = ({ heading, data }) => {
  return (
    <>
      <Card>
        <p className="title">{heading}</p>
        <div className="card__item">
          <div className="card__item-row">
            <p>{'Due Date'}</p>
            <p>{data?.schedule?.endDate}</p>
          </div>
          <div className="card__item-row">
            <p>{'Description'}</p>
            <p>{data?.description}</p>
          </div>
          <div className="card__item-row">
            <p>{'Attached Files:'}</p>
            <span>
              <p>{data?.attachedFile || 'Not Available'}</p>
              <p>{data?.userAttachedFile || 'Not Available'}</p>
            </span>
          </div>
        </div>
      </Card>

      <Card>
        <p className="title">{'Completion Details'}</p>
        <div className="card__item">
          <div className="card__item-row">
            <p>{'Completion Date'}</p>
            <p>{data?.completionDate || 'Not Available'}</p>
          </div>
          <div className="card__item-row">
            <p>{'Cost'}</p>
            <p>{data?.cost || 'Not Available'}</p>
          </div>
          <div className="card__item-row">
            <p>{'Result'}</p>
            <p>{data?.results || 'Not Available'}</p>
          </div>
          <div className="card__item-row">
            <p>{'Attached Files:'}</p>
            <span>
              <p>{data?.attachedFile || 'Not Available'}</p>
              <p>{data?.userAttachedFile || 'Not Available'}</p>
            </span>
          </div>
          <div className="card__item-row">
            <p>{'Notes:'}</p>
            <p>{data?.note || 'Not Available'}</p>
          </div>
        </div>
      </Card>
    </>
  );
};

const Card = styled.div`
  .header {
    p {
      text-transform: capitalize;
    }
  }
  .card__item {
    display: grid;
    gap: 1.2rem;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    padding: 2.4rem 3.2rem;
    margin: 0.8rem 0 2.4rem 0;

    p {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 2.4rem;
      letter-spacing: -0.0012em;
      color: #83889d;
    }
    &-row {
      display: grid;
      grid-template-columns: 17rem auto;
      gap: 4px;
      text-transform: capitalize;

      :last-child {
        border-bottom: 1px solid rgba(65, 97, 128, 0.151934);
        padding-bottom: 1.6rem;
      }
    }
  }
`;

export { SingleTrainingView };
