import React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Box, Text } from '@chakra-ui/react';
import { formatDate } from 'utils';

import { useEmployeeEditForm } from '../../../context/employeeDetailsForm';

import { ContactCard, EmployeeViewCard, InputRoot } from './EmployeeViewCard';

import './styles.css';

const EditableInput = (props) => {
  const { value, label, status } = props;
  const { control } = useEmployeeEditForm();
  const hire_date = useWatch({ control, name: 'employmentInfo.hireDate' });

  const displayValue = useWatch({ control, name: value }) || ' Not Available';

  return (
    <div className="editable-input">
      <label htmlFor={label} className="label-item">
        {label}
      </label>

      <Box>
        <Text className={label === 'Status' ? `status__${status}` : 'text'}>
          {value === 'employmentInfo.hireDate' ? formatDate(hire_date) : displayValue}
        </Text>
      </Box>
    </div>
  );
};

export const ProfileInfo = () => {
  const { getValues, control } = useEmployeeEditForm();
  const dob = useWatch({ control, name: 'dob' });
  const employee = {
    'Employee Name': 'name',
    'Work Email': 'employmentInfo.workEmail',
    'Phone no.': 'phoneNumber',
    Status: 'status',
    Gender: 'gender',
    'Marital Status': 'maritalStatus',
    'Date of Birth': 'dob',
    Address: 'location',
  };
  const statusVal = getValues('status');

  return (
    <>
      {Object.entries(employee).map(([key, value]) => {
        return (
          <div key={key}>
            {value === 'dob' ? (
              <Box>
                <label className="label-item">Date of Birth</label>
                <Text className="text">{formatDate(dob)}</Text>
              </Box>
            ) : (
              <EditableInput status={statusVal} key={key} label={key} value={value} />
            )}
          </div>
        );
      })}
    </>
  );
};

export const EmploymentInfo = () => {
  const employeeBenefits = [
    {
      name: 'Pension',
    },
  ];

  const employmentInfo = {
    'ID Number': 'staffId',
    'Date Hired': 'employmentInfo.hireDate',
    'Employment Type': 'employmentInfo.employmentType',
    Department: 'department.name',
    Designation: 'designation.name',
    Team: 'team.name',
    'Grade Level': 'gradeLevel',
    'Compensation Type': 'employmentInfo.compensationTypes',
    'Pay Rate': 'employmentInfo.monthlyPayRate',
  };

  return (
    <EmployeeViewCard label="Employment Information" className="child_contents-contact">
      <>
        {Object.entries(employmentInfo).map(([key, value], index) => {
          const mapIt = key === 'Employee Benefits';
          return <EditableInput mapItem={mapIt} key={index} label={key} value={value} />;
        })}
        <div>
          <label className="label-item">Employee Benefits</label>
          {employeeBenefits?.map((emp, index) => {
            return (
              <Box key={index}>
                <Text className="text">{emp.name}</Text>
              </Box>
            );
          })}
        </div>
      </>
    </EmployeeViewCard>
  );
};
export const ContactInformation = () => {
  const { control } = useEmployeeEditForm();
  const { fields } = useFieldArray({
    control,
    name: 'address',
  });

  return (
    <ContactCard label={'Contact Information'}>
      <InputRoot className="contact">
        <label className="label-item">{'Email Address'}</label>
        <Text className={'text'}>{useWatch({ control, name: 'email' })}</Text>
      </InputRoot>

      <div className="address">
        {fields.map((field, index) => {
          return (
            <InputRoot className="input-root" key={field.id}>
              <div>
                <label htmlFor={index} className="label-item">
                  {`Address ${index + 1}`}
                </label>
                <Text className={'text'}>
                  {useWatch({ control, name: `address.${index}.address` })}
                </Text>
              </div>
              <div>
                <label htmlFor={index} className="label-item">
                  {'Country'}
                </label>
                <Text className={'text'}>
                  {useWatch({ control, name: `address.${index}.country` })}
                </Text>
              </div>
              <div>
                <label htmlFor={index} className="label-item">
                  {'State'}
                </label>

                <Text className={'text'}>
                  {useWatch({ control, name: `address.${index}.state` })}
                </Text>
              </div>
            </InputRoot>
          );
        })}
      </div>
    </ContactCard>
  );
};

export const KinInformation = () => {
  const kinData = {
    Name: 'emergencyContact.fullName',
    Relationship: 'emergencyContact.relationship',
    'Phone number': 'emergencyContact.phoneNumber',
    Occupation: 'emergencyContact.occupation',
    address: 'emergencyContact.address',
  };

  return (
    <EmployeeViewCard label={'Next of Kin Information'}>
      {Object.entries(kinData).map(([key, value]) => {
        return <EditableInput key={key} label={key} value={value} />;
      })}
    </EmployeeViewCard>
  );
};

export const PensionInformation = () => {
  const pensionData = {
    'Pension Number': 'bankAccount.pensionAccountNo',
    'Administrative Body': 'bankAccount.pensionFundAdministrator',
  };

  return (
    <EmployeeViewCard label={'Pension Account Information'}>
      {Object.entries(pensionData).map(([key, value]) => {
        return <EditableInput key={key} label={key} value={value} />;
      })}
    </EmployeeViewCard>
  );
};

export const BankInformation = () => {
  const bankDetails = {
    'Payment Information': 'bankAccount.paymentType',
    'Bank Name': 'bankAccount.bankName',
    'Account Type': 'bankAccount.accountType',
    'Bank Account Number': 'bankAccount.accountNo',
    'Account Holder Name': 'bankAccount.accountName',
  };

  return (
    <EmployeeViewCard label={'Bank Account Information'}>
      {Object.entries(bankDetails).map(([key, value]) => {
        return <EditableInput key={key} label={key} value={value} />;
      })}
    </EmployeeViewCard>
  );
};

export const MedicalInformation = () => {
  const medicalInfo = {
    'Blood Group': 'bloodGroup',
    Genotype: 'bloodType',
    'Notable Ailment': 'notableAilment',
    Disability: 'isDisabled',
    'If yes (disability) state it': 'disabilityType',
  };

  return (
    <EmployeeViewCard label={'Medical Information'}>
      {Object.entries(medicalInfo).map(([key, value]) => {
        return <EditableInput key={key} label={key} value={value} />;
      })}
    </EmployeeViewCard>
  );
};

export const UpcomingEmployeeEvent = () => {
  const { control } = useEmployeeEditForm();

  const leaveDate = useWatch({
    control,
    name: 'nextLeaveDate',
  });

  return (
    <EmployeeViewCard label={'Upcoming Event'} type={'blue'}>
      <Box borderLeft={'2px solid #FF8500'} pl={'0.8rem'}>
        <label className="label-item">Birthday Countdown</label>
        <Text className="text">
          {`${useWatch({
            control,
            name: 'birthdayCountdown',
          })} days Left ` || 'Not Available'}
        </Text>
      </Box>
      <Box borderLeft={'2px solid #5DB3F0'} pl={'0.8rem'}>
        <label className="label-item">Work Anniversary Countdown</label>
        <Text className="text">
          {`${useWatch({
            control,
            name: 'workAnniversary',
          })} days Left ` || 'Not Available'}
        </Text>
      </Box>
      <Box borderLeft={'2px solid #00BB9E'} pl={'0.8rem'}>
        <label className="label-item">Next Leave</label>
        {leaveDate ? (
          <Text className="text">{`${leaveDate} days Left ` || 'Not Available'}</Text>
        ) : null}
      </Box>
    </EmployeeViewCard>
  );
};
