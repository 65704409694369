import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Progress } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';
import { getItem } from 'utils';

import { CustomButton, EmptyState, SpinnerComponent } from 'components';
import { Status } from 'components/tables/TableConfig';

export const formatStartEndDate = ({ startDate, endDate }) => {
  const sDate = format(parseISO(startDate), 'dd LLL');
  const eDate = format(parseISO(endDate), 'dd MMM, yyyy');
  const dateRange = `${sDate} - ${eDate}`;
  return dateRange;
};

export const ActiveAppraisal = ({ review, loading }) => {
  const user = getItem('hrm_user');
  const navigate = useNavigate();

  const goTo = ({ type, id }) => {
    type?.includes('Peer')
      ? navigate('/employee/appraisal-review', { state: { reviewType: type } })
      : navigate(`/employee/appraisal-review/${id}`, {
          state: { reviewType: type, userId: user?._id, reviewId: id },
        });
  };
  return (
    <Root className="active__appraisal">
      <p className="custom-text">Active Appraisal</p>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <Box overflowX={'auto'} pb="2.4rem" mt="1.6rem">
          <Box minW="1080px">
            {review?.length === 0 ? (
              <EmptyState message={'You have No Pending Review'} h="10vh" />
            ) : (
              <>
                {review
                  ?.filter((item) => item.type !== "Manager's Review")
                  ?.map((el, i) => {
                    const checkIfPeer = el?.type?.includes('Peer');
                    const checkIfManager = el?.type?.includes("Manager's");
                    return (
                      <div key={i} className="active__appraisal-row">
                        <p>{el.title}</p>
                        <p>{el.type}</p>
                        {el?.status !== null ? (
                          <Status
                            status={
                              el.status === 'in_progress' ? el.status?.replace(/_/, ' ') : el.status
                            }
                          />
                        ) : (
                          <Status status={'not started'} />
                        )}
                        <Flex>
                          {checkIfPeer ? (
                            <ProgressBar status={el?.reviewStats?.completed}>
                              <Progress
                                className="progress"
                                value={el?.reviewStats?.completed}
                                max={el?.reviewStats?.total}></Progress>
                              <label className="progress-label">{`${el?.reviewStats?.completed}/${el?.reviewStats?.total}`}</label>
                            </ProgressBar>
                          ) : null}
                        </Flex>
                        <p className="date">
                          {formatStartEndDate({
                            startDate: el.scheduleDate.startDate,
                            endDate: el.scheduleDate.endDate,
                          })}
                        </p>
                        <Flex justifyContent={'center'}>
                          <CustomButton
                            name={el.status !== null ? 'Edit' : 'Start'}
                            size="small"
                            h="35px"
                            variant="primary"
                            mW="75px"
                            onClick={() => goTo({ type: el?.type, id: el?._id })}
                            disabled={checkIfManager}
                          />
                        </Flex>
                      </div>
                    );
                  })}
              </>
            )}
          </Box>
        </Box>
      )}
    </Root>
  );
};

const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  .css-1jrtelv {
    ${({ status }) => `
    background-color: ${status === 0 ? 'var(--primary-light)' : '#3e7eee'};
  `}
  }
`;

const Root = styled.div`
  margin: 2.4rem 0;
  .active__appraisal {
    &-row {
      display: grid;
      grid-template-columns: 300px repeat(5, 1fr);
      border-bottom: 1px solid #f2f2f2;
      padding: 1.3rem 1.6rem;
      align-items: center;
      gap: 2rem;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #23273b;
        white-space: nowrap;
        text-transform: capitalize;
      }

      .primary {
        font-size: 1.4rem;
        padding: 0 2.4rem;
      }
      .progress-label {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #23273b;
      }

      .progress {
        width: 104px;
        height: 8px;
        background: #fafcff;
        border: 1px solid #f2f2f2;
        border-radius: 20px;

        // .css-1jrtelv {
        //   background-color: #3e7eee;
        // }
      }

      .date {
        white-space: nowrap;
      }
    }
  }
`;
