import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: inherit;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  @media only screen and (max-width: 700px) {
  }
`;
const SpinnerLoader = (props) => {
  return (
    <Container style={props.style}>
      <div className="loading-spinner"></div>
    </Container>
  );
};

export { SpinnerLoader };
