import React from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';

import { BaseButton, CustomInput } from '../../../../../../components';
import { useWorkScheduleForm } from '../../../../../../context/workScheduleForm';

import { Card } from './Card';

const selectedKeyName = 'weekend';
const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

function OverallSettings(props) {
  const { onSave, onCancel, saving } = props;
  const { register, watch, setValue } = useWorkScheduleForm();
  const selected = watch(selectedKeyName) || [];

  function toggleDay(day) {
    if (!isSelected(day)) setValue(selectedKeyName, [...selected, day]);
    else
      setValue(
        selectedKeyName,
        selected.filter((d) => d !== day && d !== day.toLowerCase())
      );
  }

  function isSelected(day) {
    return selected?.includes(day) || selected?.includes(day.toLowerCase());
  }

  return (
    <Wrapper>
      <Card className="card">
        <h2>Overall Settings</h2>

        <label htmlFor="work-schedule" className="work-schedule__label">
          Work Schedule Name<span className="red">*</span>
        </label>
        <CustomInput
          {...register('name')}
          id="work-schedule"
          style={{ height: '4.8rem', fontSize: '1.6rem' }}
          placeholder="Work schedule name"
          withBorder
        />

        <div className="work-schedule__label">Weekend Settings</div>
        <div className="days">
          {days.map((day) => (
            <div
              key={day}
              data-testid={`${day}-btn`}
              data-selected={isSelected(day)}
              className={cx('day', { active: isSelected(day) })}
              onClick={() => toggleDay(day)}>
              {day}
            </div>
          ))}
        </div>

        <div className="card__ctas">
          <BaseButton variant="outline" onClick={onCancel}>
            Cancel
          </BaseButton>
          <BaseButton variant="dark" onClick={onSave} isLoading={saving}>
            Save
          </BaseButton>
        </div>
      </Card>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .input-wrapper {
    max-width: 65rem;
    margin-bottom: 1.6rem;
  }

  .days {
    display: grid;
    grid: auto-flow max-content / repeat(auto-fill, minmax(max-content, 5rem));
    gap: 1rem 1.2rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 3.2rem;

    .day {
      display: grid;
      place-content: center;
      height: 2.8rem;
      padding: 8px 16px;
      border: 1px solid var(--black);
      border-radius: 3rem;
      background: var(--grey-100);
      font-size: 1.2rem;
      cursor: pointer;
      transition: background-color 200ms ease;

      &:hover {
        background-color: var(--grey-300);
      }

      &.active {
        background-color: var(--primary);
        color: white;
        border-color: var(--primary);
      }
    }
  }
`;

export { OverallSettings };
