import { Grid, Heading } from '@chakra-ui/react';
import { APPLICANTS_FORM_BREAKPOINT } from 'utils';

import { EditableInputFieldList } from './EditableInputFieldList';

function ApplicantsForm() {
  return (
    <>
      <Heading fontSize="2rem" lineHeight="2.3rem" mt="2.4rem" mb="3.2rem">
        Customize your application form
      </Heading>
      <Grid
        templateColumns={{ base: '1fr', [APPLICANTS_FORM_BREAKPOINT]: '1fr 1fr' }}
        autoRows="max-content"
        gap="2.4rem">
        <EditableInputFieldList name="fields" />
      </Grid>
    </>
  );
}

export { ApplicantsForm };
