import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AssignEmployeesModal, SimpleModal, SpinnerComponent } from '../../../../../components';
import { useToastify } from '../../../../../hooks/useToastify';
import { EDIT_REVIEW, RETRIEVE_BULK_EMPLOYEES } from '../../../../../query';

export function EditReviewees(props) {
  const { data: employeeData, loading } = useQuery(RETRIEVE_BULK_EMPLOYEES, {
    variables: {
      employeeIds: props.reviewCycle?.reviewCycle?.map((c) => c.employee?._id) || [],
    },
  });

  const assigned = employeeData?.retrieveBulkEmployees?.data;

  return (
    <Wrapper>
      <SimpleModal showModal={!!props.reviewCycle} setShowModal={props.setReviewCycle}>
        <Box
          mt="24px"
          mx="24px"
          p="24px"
          w="826px"
          maxW="90vw"
          border="1px solid var(--grey-150)"
          boxSizing="border-box">
          {loading ? (
            <SpinnerComponent h="60vh" />
          ) : (
            <EditRevieweesForm
              reviewCycle={props.reviewCycle}
              setReviewCycle={props.setReviewCycle}
              refetch={props.refetch}
              assigned={assigned}
            />
          )}
        </Box>
      </SimpleModal>
    </Wrapper>
  );
}

function EditRevieweesForm(props) {
  const [editReview, { loading }] = useMutation(EDIT_REVIEW);
  const { successToast, errorToast } = useToastify();

  function onAssign(employeeIds) {
    const existingIds = props.reviewCycle.reviewCycle.map((c) => c.employee);
    const fromPrevList = props.reviewCycle.reviewCycle.filter((c) =>
      employeeIds.includes(c.employee)
    );
    const newlyAdded = employeeIds
      .filter((id) => !existingIds.includes(id))
      .map((id) => ({ employee: id, reviewers: [] }));
    const updatedList = [...fromPrevList, ...newlyAdded];
    editReview({
      variables: {
        filters: { _id: props.reviewCycle.id },
        data: {
          reviewCycle: updatedList,
        },
      },
    })
      .then((res) => {
        if (res.data?.updateReview?.status === 200) {
          props?.refetch();
          successToast('Review updated successfully');
          props.setReviewCycle(null);
        } else {
          errorToast(res.data?.addReview?.message);
        }
      })
      .catch((err) => errorToast(err.message));
  }

  return (
    <AssignEmployeesModal
      assigned={props.assigned}
      isModal={true}
      showModal={true}
      setShowModal={props.setReviewCycle}
      onClick={onAssign}
      assignLeavePolicyLoading={loading}
    />
  );
}

const Wrapper = styled.div`
  .header-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.05em;
  }
`;
