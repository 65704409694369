import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { ApplicantStatus } from 'pages/admin/candidates/components/CandidateStatus';
import { PIPELINE_COLORS } from 'utils';

const StageIndicator = (props) => {
  return (
    <Box>
      <Box>{props.isStatus && <ApplicantStatus data={props} />}</Box>

      <Flex gap={'0px 4px'} mt={'10px'}>
        {props?.stages?.fetchStages?.data?.map((item, index) => (
          <Flex key={item?._id}>
            <Box
              width={'21px'}
              height={'16px'}
              borderRadius={'2px'}
              textAlign={'center'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              color={item.rank <= props.rank ? '#fff' : '#83889D'}
              backgroundColor={item.rank <= props.rank ? PIPELINE_COLORS[props.rank] : '#F2F2F2'}>
              {index + 1}
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export { StageIndicator };
