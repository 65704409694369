import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FETCH_SINGLE_EMPLOYEE_DETAILS } from 'query';
import { createActionHandler } from 'utils';

import { FullModal, ModalBackButton, RightModal, SpinnerComponent } from '../../../components';
import { EmployeeEditFormProvider } from '../../../context/employeeDetailsForm';

import { EditEmployeeProfile } from './EditProfile';
import { EmployeeEditHook } from './transformers';
import { EmployeeViewForm } from './ViewForm';

const dropDownActions = {
  'Edit Profile': 'edit',
  Query: 'query',
  Exit: 'exit',
};

import { employeeDefault } from 'tests/pages/employee/mock';

const isTesting = process.env.NODE_ENV === 'test';

const SingleEmployeeView = (props) => {
  const { showViewModal, setShowViewModal, empId, refetch: refetchAllEmployees } = props;

  const navigate = useNavigate();
  const [editing, setEditing] = React.useState(false);

  const { data, loading, refetch } = useQuery(FETCH_SINGLE_EMPLOYEE_DETAILS, {
    variables: { filters: { _id: empId } },
  });

  const viewData = data?.fetchUsersFiltered?.data[0] || {};
  const editData = !isTesting ? { ...viewData } : employeeDefault[0];

  const employeeData = {
    ...viewData,
    name: `${viewData?.firstName} ${viewData?.lastName}`,
    status: viewData?.status === 'pending' ? 'unverified' : viewData?.status,
  };

  const { employeeEditFormData } = EmployeeEditHook({ editData });

  const editAction = () => {
    setEditing(true);
  };

  const queryEmployee = () => {
    navigate('/admin/query');
  };

  const exitEmployee = () => {
    navigate('/admin/exit-management');
  };

  const closeEditModal = () => {
    setEditing(false);
  };

  const toggleFullModal = () => {
    refetchAllEmployees();
    setShowViewModal({ showViewModal: false });
  };

  const actionOnData = createActionHandler({ editAction, queryEmployee, exitEmployee });

  return (
    <>
      <FullModal
        dropDownActions={dropDownActions}
        actionOnData={actionOnData}
        showModal={showViewModal}
        setShowModal={toggleFullModal}
        title={employeeData && !loading && employeeData?.name}
        addedOn={employeeData?.employmentInfo?.hireDate}>
        {loading ? <SpinnerComponent h="60vh" /> : <EmployeeViewForm employeeData={employeeData} />}
      </FullModal>
      <RightModal
        button={<ModalBackButton data-testid={'back-btn'} onClick={closeEditModal} />}
        showModal={editing}
        closeModal={closeEditModal}
        title={'Edit Profile'}
        width={'76.4rem'}>
        <EmployeeEditFormProvider employeeData={employeeEditFormData}>
          <EditEmployeeProfile refetch={refetch} closeEditModal={closeEditModal} />
        </EmployeeEditFormProvider>
      </RightModal>
    </>
  );
};

export { SingleEmployeeView };
