import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import * as yup from 'yup';

import { BaseButton, BaseForm, SpinnerComponent } from '../../../../components';
import { useToastify } from '../../../../hooks/useToastify';
import { FETCH_APPLICANT, UPDATE_APPLICANT_PROFILE } from '../../../../query';

import { profileInfoFormData } from './account-data';

const ProfileDetails = () => {
  const { successToast, errorToast } = useToastify();
  const [updateProfile, { loading }] = useMutation(UPDATE_APPLICANT_PROFILE);
  const [edit, setEdit] = React.useState(false);

  const profileSchema = yup.object().shape({
    fullName: yup.string().required('Name is required'),
    dob: yup.string().required('Date is required'),
    alternateEmail: yup
      .string()
      .required('Email is required')
      .email('Email is invalid! Try format abcd@email.com'),
    address: yup.string().required('Address is required'),
  });

  const {
    data,
    loading: profileLoading,
    refetch,
  } = useQuery(FETCH_APPLICANT, {
    variables: {},
  });

  const profile = useMemo(() => data?.fetchApplicant?.data, [data]);

  const onSubmit = async (data) => {
    let image;
    delete data.email;
    delete data._id;
    const { imageUrl, ...rest } = data;

    if (typeof imageUrl === 'string') {
      delete data.imageUrl;
    } else {
      image = imageUrl;
    }

    try {
      const res = await updateProfile({
        variables: {
          data: { image, ...rest },
        },
      });
      if (res?.data?.updateApplicantProfile?.status === '200') {
        successToast('Profile Updated sucessfully');
        refetch();
      } else errorToast(res?.data?.updateApplicantProfile?.message);
    } catch (error) {
      errorToast(error.response?.data?.message);
    }
  };

  return profileLoading ? (
    <SpinnerComponent />
  ) : (
    <BaseForm
      formName={'Personal info'}
      formDesc={'Update your photo and personal details here.'}
      formData={profileInfoFormData}
      schema={profileSchema}
      mainState={profile}
      leftLabel
      edit={edit}
      setEdit={setEdit}
      className={'settings-form'}
      onSubmit={onSubmit}>
      {edit ? (
        <Flex gap={'12px'} justifyContent={'flex-end'} className="form-buttons">
          <BaseButton
            variant={'outline'}
            type={'reset'}
            disabled={loading}
            onClick={() => setEdit(!edit)}
            data-testid={'cancel-btn'}>
            Cancel
          </BaseButton>
          <BaseButton
            variant={'primary'}
            type={'submit'}
            isLoading={loading}
            disabled={loading}
            data-testid={'submit-btn'}>
            Save
          </BaseButton>
        </Flex>
      ) : null}
    </BaseForm>
  );
};

export { ProfileDetails };
