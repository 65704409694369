import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Flex, Text } from '@chakra-ui/react';
import { FETCH_ASSESSMENT_BY_TITLE } from 'query';

import { EmptyState, FullModal, ModalBackButton, SpinnerComponent } from 'components';

import { AssessmentQuestions } from './component/QuestionsView';
import { PreviewRoot } from './component/style';

const isTesting = process.env.NODE_ENV === 'test';

const PreviewPage = () => {
  let navigate = useNavigate();
  let { title } = useParams();

  const { data, loading } = useQuery(FETCH_ASSESSMENT_BY_TITLE, {
    variables: { title: title },
  });

  const pData = data?.fetchByTitle?.data[0];

  const summary = {
    section: pData?.sections?.length,
    duration: pData?.duration,
    deadline: pData?.deadline,
  };

  function goBack() {
    navigate('/admin/assessments');
  }
  return (
    <PreviewRoot data-testid="preview-page">
      {loading && !isTesting ? (
        <SpinnerComponent />
      ) : (
        <>
          {data?.fetchByTitle?.data?.length === 0 ? (
            <EmptyState message={'Save Assessment before preview'} />
          ) : (
            <FullModal showModal={true} noHeader mt="8rem">
              <Flex gap="2.4rem" className="modal--header">
                <ModalBackButton onClick={() => goBack()} data-testid="modal-back-btn" />
                <Text className="assessment-title" textTransform={'capitalize'}>
                  {pData?.title}
                </Text>
              </Flex>

              <div className="assessment__content">
                <div className="assessment__summary">
                  <div className="assessment__summary--header">
                    <p>Section</p>
                    <p>Duration</p>
                    <p>Deadline</p>
                  </div>
                  <div className="assessment__summary--row">
                    <p>{summary.section}</p>
                    <p>{summary.duration}</p>
                    <p>{summary.deadline}</p>
                  </div>
                </div>

                {pData?.sections?.map((sec, j) => {
                  return (
                    <div key={sec?.name} className="assessment__section">
                      <div className="top">
                        <p className="title">
                          <span>Section: </span>
                          {sec?.name}
                        </p>
                        <p className="title">
                          <span>Instructions: </span>
                          {sec?.instructions}
                        </p>

                        <AssessmentQuestions
                          questions={sec?.questions}
                          index={j}
                          optIsObj={false}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </FullModal>
          )}
        </>
      )}
    </PreviewRoot>
  );
};

export { PreviewPage };
