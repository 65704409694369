import React from 'react';
import { Flex, Image, Stack, Text } from '@chakra-ui/react';
import { joinNames } from 'pages/employee/dashboard/overview/helpers';

import clockIcon from '../../../../../assets/icons/clock.svg';

import { formatDate } from './helpers';
import { DraftLabel } from './styles';

export function SingleAnnouncement(props) {
  return (
    <Flex
      data-testId={props?.announcement?.title}
      onClick={() => {
        props?.handleViewAnnouncement(props.announcement._id);
        props?.openModal();
      }}
      _hover={{ border: 'solid 1px var(--primary)' }}
      cursor={'pointer'}
      justifyContent={'space-between'}
      backgroundColor={'var(--white)'}
      alignItems={'center'}
      border={'solid 1px var(--grey-150)'}
      borderRadius={'4px'}
      p={'16px 24px'}>
      <Stack>
        <Flex alignItems={'center'} gap={'8px'}>
          <Text fontSize={'16px'} fontWeight={'600'}>
            {props?.announcement?.title}
          </Text>
        </Flex>
        <Flex color={'var(--grey-500)'} gap={'20px'} fontSize={'12px'}>
          <Flex gap={'8px'}>
            <Image src={clockIcon} />
            <Text>{formatDate(props?.announcement?.createdAt)}</Text>
          </Flex>
          <Text>{joinNames(props?.announcement?.issuedBy)}</Text>
        </Flex>
      </Stack>

      <DraftLabel>{props?.announcement?.type}</DraftLabel>
    </Flex>
  );
}
