import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { BROADCAST_MESSAGE } from 'query';

import { BaseInput, CustomButton } from 'components';
import { TaskCategories } from 'components/admin/dashboard/task/taskComponents';

const Wrapper = styled(Box)`
  display: grid;
  grid-gap: 1.6rem;
  padding: 0 2.4rem 3.2rem 2.4rem;
`;
const testState = {
  message: '',
  subject: '',
};

const SendMessage = (props) => {
  const { userIds, closeModal } = props;
  const initialState = {
    message: '',
    subject: '',
  };
  const mainState = process.env.NODE_ENV === 'test' ? testState : initialState;
  const [state, setState] = useState(mainState);
  const [taskCategory, setTaskCat] = useState('');
  const { successToast, errorToast } = useToastify();
  const [sendBulkMessage, { loading }] = useMutation(BROADCAST_MESSAGE);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTaskCat = (cat) => {
    setTaskCat(cat);
  };
  const sendRequest = async () => {
    const tosend = { userIds: userIds, message: `${state.subject}: ${state.message}` };
    try {
      const res = await sendBulkMessage({ variables: { data: tosend } });
      res?.data?.broadcastMessage?.status === 200
        ? successToast('Message sent successfully')
        : errorToast(res?.data?.broadcastMessage?.message);
      setState(initialState);
      setTaskCat('');
    } catch (error) {
      errorToast(error.response?.data?.message);
    } finally {
      closeModal();
    }
  };
  return (
    <>
      <Wrapper data-testid="send-message">
        <BaseInput
          data-testid={'title-input'}
          placeholder={'Add Title'}
          name={'subject'}
          value={state.subject}
          onChange={(e) => handleChange(e)}
        />
        <TaskCategories handleTaskCat={handleTaskCat} taskCat={taskCategory} />
        <BaseInput
          label="Body of Message"
          data-testid="description"
          type="textarea"
          color={'rgba(0,0,0,0.5)'}
          rows={'3'}
          name={'message'}
          value={state.message}
          onChange={(e) => handleChange(e)}
        />
        <Flex gap="0.8rem" justifyContent={'flex-end'}>
          <CustomButton
            h={'4.8rem'}
            mW={'13.2rem'}
            variant={'secondary'}
            name={'Cancel'}
            handleButtonClick={closeModal}
          />
          <CustomButton
            variant={'primary'}
            h={'4.8rem'}
            mW={'13.2rem'}
            name={'Send'}
            handleButtonClick={sendRequest}
            loading={loading}
          />
        </Flex>
      </Wrapper>
    </>
  );
};

export { SendMessage };
