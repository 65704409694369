import React from 'react';
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const MenuDropDown = (props) => {
  return (
    <MenuWrapper>
      <Menu className={'menu'}>
        <MenuButton
          variant={props.variant}
          className={`${props.variant || 'btn-default'}`}
          as={Button}>
          <Flex align="center" justify="center">
            {props.children}
          </Flex>
        </MenuButton>
        <MenuList className={`${props.variant}__menulist` || 'menulist'}>
          {Object.entries(props.dropDownActions).map(([key, value]) => (
            <MenuItem
              data-testid={value}
              key={value}
              className={`${props.variant}__menuItem` || 'menuItem'}
              onClick={() => props.actionOnData({ action: value })}>
              {key}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  .btn-default {
    background: transparent;
  }
  .update-employee-dropdown {
    width: 122px;
    height: 40px;
    border: 1px solid #3e7eee;
    border-radius: 4px;
    color: var(--blue);
    display: flex;
    align-items: center;
    padding: 0 0.8rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
    &__menulist {
      padding: 1.4rem 0 !important;
      box-shadow: 4px 4px 15px rgba(36, 36, 36, 0.2);
      border-radius: 4px;
    }
    &__menuItem {
      height: 4rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -0.117188px;
      color: #23273b;
      border-radius: 0;
      margin-bottom: 0.8rem;
    }
  }
`;
