import { Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseInput, CustomButton } from 'components/shared';

export function LetHelpYouSetup() {
  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('email');
    const message = formData.get('message');

    const mailtoLink = `mailto:djokotoabeeku619@gmail.com?subject=Hello&body=${encodeURIComponent(
      `Email: ${email}%0A%0AMessage: ${message}`
    )}`;

    window.location.href = mailtoLink;
  }
  return (
    <Root>
      <Stack alignItems={'center'}>
        <Heading fontSize={{ base: '36px', md: '46px' }}>
          Let’s <span className="ascent">Help</span> Set You Up
        </Heading>
        <Text textAlign={'center'} color={'#475467'}>
          {
            "Kindly provide your information below and we'll help create your account for a smooth onboarding experience on the HRMS System."
          }
        </Text>
      </Stack>

      <form onSubmit={onSubmit}>
        <BaseInput label="Email" id="email" />

        <BaseInput type="textarea" label="Message" id="message" />

        <CustomButton name={'Send message'} type="submit"></CustomButton>
      </form>
    </Root>
  );
}

const Root = styled(Stack)`
  gap: 32px;
  padding: 20px;
  max-width: 600px;
  @media (min-width: 768px) {
    padding: 40px;
  }

  .ascent {
    color: var(--primary);
  }

  form {
    display: grid;
    gap: 24px;
  }
`;
