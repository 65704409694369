import React from 'react';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import edit from 'assets/icons/edit-blue.svg';

import { CustomButton } from 'components';

export const EditCancel = ({ status, reviewId, userId, type }) => {
  const navigate = useNavigate();

  const handleNav = () => {
    navigate(`/employee/appraisal-review/${userId}`, {
      state: { reviewId: reviewId, userId: userId, reviewType: type },
    });
  };

  return (
    <>
      {status === 'completed' ? (
        <EditCancelWrap>
          <div className="icon__left" onClick={() => handleNav()}>
            <img src={edit} alt="i" />
            <p>Edit</p>
          </div>
          <div className="icon__right">
            <Flex
              alignItems={'center'}
              justifyContent="center"
              bgColor={'rgba(250, 74, 74, 0.4)'}
              w="24px"
              h="24px"
              borderRadius={'4px'}>
              <IoClose size="14" color="var(--error)" />
            </Flex>
            <p className="red">Cancel</p>
          </div>
        </EditCancelWrap>
      ) : (
        <CustomButton
          name={'Start'}
          size="small"
          h="35px"
          variant="primary"
          mW="113px"
          onClick={() => handleNav()}
        />
      )}
    </>
  );
};

const EditCancelWrap = styled(Flex)`
  align-items: center;
  gap: 4.8rem;
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: var(--primary);
  }

  .red {
    color: var(--error);
  }

  .icon__right,
  .icon__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
`;
