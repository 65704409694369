import { gql } from '@apollo/client';

export const FETCH_QUERIES = gql`
  query fetchQueries($filter: QueryFilters, $page: Int) {
    fetchQueries(filter: $filter, page: $page) {
      status
      message
      pageCount
      data {
        _id
        subject
        content
        createdAt
        response
        issuer {
          _id
          firstName
          lastName
          imageUrl
        }
        recepient {
          firstName
          lastName
          imageUrl
        }
        status
      }
    }
  }
`;

export const CREATE_QUERY = gql`
  mutation createQuery($data: QueryInput) {
    createQuery(data: $data) {
      status
      message
      data {
        _id
        subject
        content
        issuer
        recepient
        status
      }
    }
  }
`;

export const EDIT_QUERY = gql`
  mutation editQuery($filters: QueryFilters!, $data: QueryInput!) {
    editQuery(filters: $filters, data: $data) {
      status
      message
      data {
        _id
        subject
        content
        issuer
        recepient
        response
        status
      }
    }
  }
`;
