import React from 'react';
import styled from '@emotion/styled';

import { EmptyState, ViewDocument } from 'components';

import { HeadingText, RenderDocuments } from '../CandidateInfoComponents';

export const CandidateDocuments = ({ user }) => {
  const [documentUrl, setDocumentUrl] = React.useState('');
  const [documentName, setDocumentName] = React.useState('');

  let newArr = [];
  user?.applicationData?.map((data) => {
    if (data.type === 'Attachment') {
      return (newArr = [
        ...newArr,
        ...(JSON.parse(data.value)?.map?.((data) => {
          return { name: data.name, url: data.url?.data?.data?.url || data.url };
        }) ?? []),
      ]);
    }
  });

  const documentArray = newArr.flatMap((e) => e);

  function closeDocModal() {
    setDocumentUrl('');
  }
  return (
    <>
      <CandidateDocumentsRoot>
        {documentUrl === '' && (
          <div>
            <HeadingText headingText={'Documents'} />
            <hr />
            {documentArray.length === 0 ? (
              <EmptyState message={'No Documents found'} />
            ) : (
              <RenderDocuments
                docs={documentArray}
                setDocumentUrl={setDocumentUrl}
                setDocumentName={setDocumentName}
              />
            )}
          </div>
        )}
        {documentUrl !== '' && (
          <ViewDocument
            closeModal={closeDocModal}
            documentName={documentName}
            documentUrl={documentUrl}
          />
        )}
      </CandidateDocumentsRoot>
    </>
  );
};

const CandidateDocumentsRoot = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 16px;

  hr {
    margin: 12px 0;
  }
`;
