import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Grid, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import {
  FETCH_SINGLE_EMPLOYEE_DETAILS,
  FETCH_SINGLE_TEMPLATE,
  SUBMIT_LINE_MANAGER_APPRAISAL,
} from 'query';
import { getItem } from 'utils';

import { FullModal, SpinnerComponent } from 'components';

import { EmployeeDetails } from '../leave-approvals/components/EmployeeDetails';

import { Rating, ScoreCard, Summary } from './components';

const Container = styled(Box)`
  .full-modal-wrapper {
    top: 60px;
  }
  .children {
    display: grid;
    padding: 24px;
    justify-content: center;
    text-align: center;
  }

  .score-cont {
    justify-content: center;
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #eaedf2;
    background: #fafcff;
    text-align: center;
  }

  .score-text-1 {
    color: var(--Black---HRMs, #23273b);
    font-family: Karla;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  .score-text-2 {
    color: #999fa3;
    font-family: Karla;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
  }

  .score-text-3 {
    color: #242424;
    font-family: Karla;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 100% */
  }

  .score-cont {
  }

  .outline {
    border-radius: 8px;
    border: 1px solid #d0d5dd !important;
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .option,
  .checkbox {
    color: var(--Black---HRMs, #23273b);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    padding: 0;
  }

  .checkbox-cont {
    gap: 6px;
  }

  .modal__content {
    display: flex;
    justify-content: center;
  }

  .review-box {
    width: 81rem;
    border-radius: 8px;
    border: 1px solid #4e7ce6;
    height: fit-content;
  }

  .employeeInfo {
    border-radius: 8px;
    border: none;
    background: #fafcff;
  }

  .sections {
    margin: 40px;
    padding: 16px 42px 16px 16px;
    border-radius: 8px;
    border: 1px solid var(--Stroke, #f2f2f2);
  }

  .heading {
    color: var(--Black---HRMs, #333758);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    padding: 0 0 16px;
    border-bottom: 1px solid var(--Stroke, #f2f2f2);
    text-transform: 'capitalize';
  }

  @media (max-width: 350px) {
    .content {
      grid-template-columns: max-content;
      gap: 20px;
      justify-content: center;
      overflow: auto;

      .username {
        margin: 0;
      }
    }
  }
  @media (max-width: 786px) {
    .modal__content {
      overflow: auto;
    }
    .full-modal-wrapper,
    .right-modal-overlay {
      padding: 10px;
    }
    .sections {
      padding: 10px;
      margin: 10px;
    }
    .questions {
      overflow: auto;
    }
    .section {
      overflow: auto;
    }

    .question {
      width: 500px;
    }
    .review-box {
      overflow: auto;
    }
  }
`;

const Section = styled(Grid)`
  border: 1px solid #f2f2f2;
  border-radius: 6px;

  .sub-heading {
    color: var(--Black---HRMs, #333758);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
    border-radius: 6px;
    background: #fafcff;
    padding: 14px 20px;
  }
`;

const AddPerformanceReview = ({ showModal, setShowModal, data: reviewData, refetch }) => {
  const [openScoreCard, setScoreCard] = React.useState(false);
  const [score, setScore] = React.useState([]);
  const { _id } = getItem('hrm_user');

  const { successToast, errorToast } = useToastify();
  const { data: fetchTemplate, loading: templateLoading } = useQuery(FETCH_SINGLE_TEMPLATE, {
    variables: {
      templateId: reviewData?.templateId?._id,
    },
  });

  const templateData = React.useMemo(() => {
    return fetchTemplate?.fetchSingleTemplate?.data || [];
  }, [fetchTemplate]);

  const [questionsData, setQuestions] = React.useState(templateData ? templateData : {});

  const { data } = useQuery(FETCH_SINGLE_EMPLOYEE_DETAILS, {
    variables: { filters: { _id: reviewData?.employee?._id } },
  });
  const employeeData = React.useMemo(() => {
    return data?.fetchUsersFiltered?.data[0];
  }, [data]);

  const sections = React.useMemo(() => {
    return questionsData?.questions || [];
  }, [questionsData]);

  const [submitAppraisal, { reviewLoading }] = useMutation(SUBMIT_LINE_MANAGER_APPRAISAL);

  const submitData = {
    employeeId: reviewData?.employee?._id,
    reviewId: reviewData?._id,
    reviewer: {
      reviewType: `Manager's Review`,
      reviewer: _id,
      responses: [{ section: [...sections] }],
    },
  };

  const onSubmit = async (data) => {
    try {
      const res = await submitAppraisal({
        variables: {
          data: data,
        },
      });
      if (res?.data?.addResponse?.status === 201) {
        setScore(res?.data?.addResponse?.data.reviewers);
        setScoreCard(!openScoreCard);
        successToast('Review submitted sucessfully');
        refetch();
      } else errorToast(res?.data?.addResponse?.message);
    } catch (error) {
      errorToast('something went wrong try again');
    }
  };

  const handleChange = (sectionId, questionId, optionId) => {
    const newArray = sections?.map((question, secId) => {
      delete question.instruction;
      if (sectionId === secId) {
        const newQuestions = question.questions.map((ques, quesId) => {
          if (questionId === quesId) {
            const opts = ques.options.map((option, optId) => {
              if (optId === optionId) {
                return {
                  ...option,
                  isAnswer: true,
                };
              } else {
                return {
                  ...option,
                  isAnswer: false,
                };
              }
            });
            return { ...ques, options: opts };
          } else return ques;
        });
        return { ...question, questions: newQuestions };
      } else return question;
    });
    const questions = { ...questionsData, questions: newArray };
    setQuestions(questions);
  };

  React.useEffect(() => {
    setQuestions(templateData);
  }, [templateData]);

  return (
    <Container>
      <FullModal
        title={'Performance Review'}
        dontShowActions
        showModal={showModal}
        setShowModal={setShowModal}>
        {templateLoading ? (
          <SpinnerComponent />
        ) : (
          <Grid className="review-box">
            <EmployeeDetails employee={employeeData} />
            <Grid className="sections" gap={'24px'}>
              <Heading className="heading">{questionsData?.name}</Heading>
              {sections?.map((question, sectionId) => (
                <Section className="section" key={question?.title}>
                  <Heading className="sub-heading">{question?.title}</Heading>
                  <Grid className="questions">
                    {question?.questions?.length
                      ? question?.questions?.map((ques, i) => (
                          <Rating
                            key={ques?.question}
                            index={i}
                            question={ques?.question}
                            sectionId={sectionId}
                            questionType={ques?.questionType}
                            options={ques?.options}
                            handleChange={handleChange}
                            status={reviewData?.lineManagerCompleted}
                          />
                        ))
                      : ''}
                  </Grid>
                </Section>
              ))}
              <Summary
                onSubmit={() => onSubmit(submitData)}
                loading={reviewLoading}
                isCompleted={reviewData.lineManagerCompleted}
                evauations={reviewData.reviewType}
              />
            </Grid>
          </Grid>
        )}
      </FullModal>
      <ScoreCard
        scores={score}
        id={employeeData?._id}
        setShowModal={setScoreCard}
        showModal={openScoreCard}
        closeReview={setShowModal}
      />
    </Container>
  );
};

export { AddPerformanceReview };
