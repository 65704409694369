import { useMutation } from '@apollo/client';

import { useToastify } from '../../../../../hooks/useToastify';
import { CREATE_REVIEW, EDIT_REVIEW, RETRIEVE_REVIEWS } from '../../../../../query';

export function useSubmitReview(options) {
  const { successToast, errorToast } = useToastify();

  const [createReview, { loading }] = useMutation(CREATE_REVIEW);
  const [updateReview, { loading: updating }] = useMutation(EDIT_REVIEW);

  function onCreate(values) {
    const transformed = JSON.parse(JSON.stringify(values));
    transformed.reviewCycle = values.reviewCycle?.map((empObj) => ({
      ...empObj,
      reviewers: empObj?.reviewers?.map((r) => r._id),
    }));
    transformed.reviewType = values.reviewType
      ?.filter((t) => t.score)
      .map((t) => ({ ...t, score: Number(t.score) }));
    transformed.frequency = values.frequency?.value;
    transformed.templateId = values.templateId?.value;
    delete transformed.team;

    createReview({
      variables: { data: transformed },
      refetchQueries: [{ query: RETRIEVE_REVIEWS, variables: { page: 1, filters: {} } }],
      awaitRefetchQueries: true,
    })
      .then((res) => {
        if (res.data?.addReview?.status === 201) {
          successToast('Review created successfully');
          options.setShowModal(false);
        } else {
          errorToast(res.data?.addReview?.message);
        }
      })
      .catch((err) => errorToast(err.message));
  }

  function onEditDetails(values) {
    const transformed = JSON.parse(JSON.stringify(values));
    transformed.reviewType = values.reviewType
      ?.filter((t) => t.score)
      .map((t) => ({ ...t, score: Number(t.score) }));
    transformed.frequency = values.frequency?.value;
    transformed.reviewCycle = transformed?.reviewCycle?.map((cycle) => {
      return {
        employee: cycle?.employee?._id,
        reviewers: cycle?.reviewers?.map((r) => r?._id),
      };
    });
    const _id = transformed._id;
    delete transformed._id;
    delete transformed.status;
    delete transformed.templateId;
    delete transformed.createdAt;

    updateReview({ variables: { filters: { _id }, data: transformed } })
      .then((res) => {
        if (res.data?.updateReview?.status === 200) {
          options?.onAfterSubmit();
          successToast('Review updated successfully');
          options?.setShowModal(false);
        } else {
          errorToast(res.data?.addReview?.message);
        }
      })
      .catch((err) => errorToast(err.message));
  }

  return { onCreate, loading, onEditDetails, updating };
}
