import React from 'react';
import { Flex, Grid } from '@chakra-ui/react';

const Rating = ({ sectionId, index, question, options, handleChange, status }) => {
  const length = options?.length;

  const number = index + 1;
  return (
    <Grid
      className={'question'}
      gap={'16px'}
      borderBottom={'1px solid #F2F2F2'}
      padding={'20px 0'}
      margin={'0 20px'}>
      <p>
        {'Q' + number} {question}
      </p>
      <Flex gap={'24px'}>
        {options?.map((option, optionId) => (
          <Flex className="option" key={option.choice} gap={'6px'}>
            <input
              className="radio"
              type="radio"
              id={option.choice}
              name={question}
              value={option.value}
              onChange={() => handleChange(sectionId, index, optionId)}
              disabled={status}
            />
            <label htmlFor={option.choice}>
              {option.value} out of {length}
            </label>
          </Flex>
        ))}
      </Flex>
    </Grid>
  );
};

export { Rating };
