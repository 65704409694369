import React from 'react';
import { useTable } from 'react-table';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

function HiringPipelineTable(props) {
  const data = props.data || [];

  const columns = props.columns || [];
  const tableInstance = useTable({
    columns,
    data,
  });

  const cusStyle = {
    textAlign: 'left',
    width: '12rem',
  };

  const bodyTextStyle = {
    textAlign: 'left',
    width: '12rem',
    fontSize: '1.2rem',
    linHeight: '1.4rem',
    fontWeight: '700',
    color: '#23273B',
    letterSpacing: '-0.05rem',
    background: '#ffffff',
    opacity: '1',
  };

  const pipelineColors = ['#FA4A4A', '#00BB9E', '#FF9F2D', '#9747FF', '#3D7471', '#A8C690'];

  const tableRowStyle = (i) => ({
    background: pipelineColors[i],
  });
  const tableRowStyles = (cellVal, rowCells, cellColumnHeader, j, i) => ({
    ...(cellVal === null ? null : tableRowStyle(i - 1)),
    // ...(rowCells[i + 1]?.value !== null && i + 1 <= rowCells.length - 1
    //   ? { opacity: 0.5 }
    //   : { opacity: 1 }),
    ...(cellColumnHeader === 'Jobs' ? bodyTextStyle : ''),
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <table {...getTableProps()} className="tableWrap">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  style={{ ...(column.render('Header') === 'Jobs' ? cusStyle : '') }}
                  key={i}
                  {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="tableBody">
          {rows.map((row, j) => {
            prepareRow(row);
            return (
              <tr key={j} {...row.getRowProps()} style={{ color: '#fff' }} className="tableRow">
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      className="tableCell"
                      key={i}
                      {...cell.getCellProps()}
                      style={{
                        ...tableRowStyles(cell.value, row.cells, cell.column.Header, j, i),
                      }}>
                      {cell.render('Cell')}{' '}
                      {[
                        ...(cell.column.Header !== 'Jobs' && cell.value !== null
                          ? 'Candidates'
                          : ''),
                      ]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const HiringWrapper = styled(Box)`
  table {
    background: #ffffff;
    width: 100%;
    text-align: center;
    @media (max-width: 768px) {
      width: 64rem;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0.8rem;
  }
  th {
    color: #999fa3;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 600;
    padding: 0 0 1.6rem 0;
  }
  td {
    width: 6rem;
    height: 3rem;
    background: #fafcff;
    border-radius: 0.2rem;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.2rem;
  }
`;

export { HiringPipelineTable, HiringWrapper };
