import React from 'react';
import styled from '@emotion/styled';

export function TimeLabel({ variant, time }) {
  return <Root className={variant}>{time}</Root>;
}

const Root = styled.div`
  width: 92px;
  text-align: center;
  padding: 11px 21px;
  border-radius: 6px;
  &.in {
    background-color: rgba(62, 126, 238, 0.1);
    color: var(--primary);
  }
  &.out {
    background-color: rgba(250, 74, 74, 0.1);
    color: var(--error);
  }
`;
