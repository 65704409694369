import React from 'react';
import { CgAsterisk } from 'react-icons/cg';
import { Box, Flex, Text } from '@chakra-ui/react';

import { currenciesData } from '../../data/country-currency';

import { BaseDropdown } from './BaseDropdown';

function DropdownIndicator() {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '0.8rem' }}>
      <path d="M0.25 0.5L4 4.25L7.75 0.5H0.25Z" fill="#333758" />
    </svg>
  );
}

export const CurrencyInput = (props) => {
  const currencies = currenciesData.map((c) => {
    return {
      value: c.symbol,
      label: c.cc,
      flag: c.flag,
      name: c.name,
    };
  });

  const allCurrencyOptions = currencies?.map((c) => ({
    label: (
      <Flex>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          w={'2rem'}
          height={'2rem'}
          borderRadius={'50%'}
          mr={'1rem'}
          overflow={'hidden'}>
          <img src={c.flag} alt={'i'} width="100%" height="100%" style={{ borderRadius: '80%' }} />
        </Flex>
        <Text fontSize={'1.4rem'}>{c.label}</Text>
      </Flex>
    ),
    value: c.label,
    currency: c.value,
  }));
  const options = [...allCurrencyOptions] || [];

  return (
    <>
      <div>
        <Text fontSize="1.4rem" as="label" mb="1.2rem" color={'#333758'}>
          {props.label}
          {props.isRequired && (
            <Box as="span" position="absolute" top="0">
              <CgAsterisk color="var(--error)" size="10" />
            </Box>
          )}
        </Text>
        <Flex gap={'0.8rem'} mt="0.2rem">
          <BaseDropdown
            data-testid="phone-input"
            options={options}
            value={props.currencyCode}
            border="1px solid #e0e0e0"
            onChange={(e) => props.getCurrencyCode(e)}
            w={'11rem'}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
          />
        </Flex>
      </div>
    </>
  );
};
