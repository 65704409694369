import { gql } from '@apollo/client';

export const FETCH_PIPELINE = gql`
  query Query {
    fetchStages {
      status
      message
      data {
        id: _id
        title
        colorCode
        rank
      }
    }
  }
`;
