import { gql } from '@apollo/client';

export const FETCH_WORK_SCHEDULE = gql`
  query fetchWorkSchedule($filter: WorkScheduleFilter!) {
    fetchWorkSchedule(filter: $filter) {
      status
      message
      data {
        _id
        name
        isDefault
        weekend
        enrolledEmployee
        assignedUsers {
          _id
          firstName
          lastName
          status
          imageUrl
        }
        blackoutPeriods {
          name
          startDate
          endDate
        }
        holidays {
          id: _id
          name
          date
          type
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const EDIT_WORK_SCHEDULE = gql`
  mutation editWorkSchedule($data: EditWorkScheduleInputData!) {
    editWorkSchedule(data: $data) {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;

export const CREATE_WORK_SCHEDULE = gql`
  mutation createWorkSchedule($data: WorkScheduleInputData!) {
    createWorkSchedule(data: $data) {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;

export const DELETE_WORK_SCHEDULE = gql`
  mutation deleteWorkSchedule($_id: ObjectID!) {
    deleteWorkSchedule(_id: $_id) {
      status
      message
      data {
        _id
        name
      }
    }
  }
`;

export const ASSIGN_WORK_SCHEDULE = gql`
  mutation assignWorkSchedule($data: AssignWorkScheduleInputData!) {
    assignWorkSchedule(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const FILTER_WORK_SCHEDULE = gql`
  query filterWorkSchedule($filter: WorkScheduleFilter!) {
    filterWorkSchedule(filter: $filter) {
      status
      message
      data {
        _id
        name
        isDefault
        weekend
        enrolledEmployee
        blackoutPeriods {
          name
          startDate
          endDate
        }
        holidays {
          id: _id
          name
          date
          type
        }
        createdAt
        updatedAt
      }
    }
  }
`;
