import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_LEAVE_TYPE } from 'query';

function useTypesandPoliciesView() {
  let [leaveType, setLeaveType] = useState([]);
  const [showModal, setShowModal] = useState('');

  const isTesting = process.env.NODE_ENV === 'test';

  const dummyData = [
    {
      description: 'Sick days to be used',
      name: 'vacation',
      paidTimeSetting: 'paid',
      _id: '637f5e3ec4189bfab704ce5d',
    },
    {
      description: 'Leaves related to employee health',
      name: 'health',
      paidTimeSetting: 'paid',
      _id: '637f609ac4189bfab704ceb6',
    },
    {
      description: 'Sick day for the sick',
      name: 'vacation leave',
      paidTimeSetting: 'paid',
      _id: '63863cd017e1393c207ea0af',
    },
    {
      description: "When you've worked enough and need some rest",
      name: 'rest',
      paidTimeSetting: 'paid',
      _id: '63864d9d17e1393c207ea28d',
    },
  ];

  const {
    data: leaveTypeData,
    loading,
    refetch: refetchLeaveType,
  } = useQuery(FETCH_LEAVE_TYPE, {});

  const fetchLeaveType = leaveTypeData?.retrieveLeaveTypes?.data;

  useEffect(() => {
    setLeaveType(isTesting ? dummyData : fetchLeaveType);
  }, [fetchLeaveType]);

  return {
    loading,
    leaveType,
    isTesting,
    refetchLeaveType,
    showModal,
    setShowModal,
  };
}

export { useTypesandPoliciesView };
