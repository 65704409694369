import {
  AnnouncementIcon,
  ApprovalIcon,
  AssessmentIcon,
  CandidatesIcon,
  DashboardIcon,
  DocumentIcon,
  EmployeeIcon,
  ExitIcon,
  GoalsIcon,
  InboxIcon,
  JobsIcon,
  LeaveMangementIcon,
  LineManagerIcon,
  LineManagerReviewIcon,
  PerformanceIcon,
  QueryIcon,
  ReportIcon,
  SettingsIcon,
  TimeClockIcon,
  // StructureIcon,
  TrainingIcon,
} from '../components/shared/Svgs';

export const SidebarMainData = [
  {
    icon: InboxIcon,
    name: 'Chat',
    path: '/admin/inbox',
  },
  {
    icon: DashboardIcon,
    name: 'Dashboard',
    path: '/admin',
    exact: true,
  },
  {
    icon: AnnouncementIcon,
    name: 'News Feed',
    path: '/admin/announcements',
  },
  // {
  //   icon: CalendaIcon,
  //   name: 'Calendar & Todos',
  //   path: '/admin/calendar',
  // },
];

export const SidebarRecruitmentData = [
  {
    icon: AssessmentIcon,
    name: 'Assessments',
    path: '/admin/assessments',
  },
  {
    icon: CandidatesIcon,
    name: 'Candidates',
    path: '/admin/candidates',
  },
  {
    icon: JobsIcon,
    name: 'Jobs',
    path: '/admin/jobs',
  },
];

export const SidebarEmploymentData = [
  {
    icon: EmployeeIcon,
    name: 'Employee',
    path: '/admin/employee',
  },
  {
    icon: ExitIcon,
    name: 'Exit Management',
    path: '/admin/exit-Management',
  },
  {
    icon: LeaveMangementIcon,
    name: 'Leave Management',
    path: '/admin/leave-management',
  },
  {
    icon: LineManagerIcon,
    name: 'Line Manager',
    path: '/admin/line-manager',
  },
  {
    icon: PerformanceIcon,
    name: 'Performance',
    path: '/admin/performance',
  },
  {
    icon: QueryIcon,
    name: 'Query',
    path: '/admin/query',
  },
  {
    icon: TimeClockIcon,
    name: 'Timesheets',
    path: '/admin/timesheets',
  },
  {
    icon: TrainingIcon,
    name: 'Training',
    path: '/admin/training',
  },

  // {
  //   icon: PerformanceIcon,
  //   name: 'Appraisal',
  //   path: '/admin/appraisal',
  // },
];
export const SidebarOrganizationData = [
  {
    icon: GoalsIcon,
    name: 'Asset Management',
    path: '/admin/asset',
  },
  {
    icon: DocumentIcon,
    name: 'Documents',
    path: '/admin/documents',
  },
  {
    icon: ReportIcon,
    name: 'Reports',
    path: '/admin/reports',
  },
  // {
  //   icon: StructureIcon,
  //   name: 'Structure',
  //   path: '/admin/structure',
  // },
  {
    icon: SettingsIcon,
    name: 'Settings',
    path: '/admin/settings',
  },
];

// employee sidebar menu data

export const EmployeePersonal = [
  {
    icon: DashboardIcon,
    name: 'Overview',
    path: '/employee',
    exact: true,
  },
  {
    icon: InboxIcon,
    name: 'Chat',
    path: '/employee/inbox',
  },
  {
    icon: AnnouncementIcon,
    name: 'News Feed',
    path: '/employee/announcements',
  },
  {
    icon: TimeClockIcon,
    name: 'Timesheets',
    path: '/employee/timesheets',
  },
];

export const EmployeeEmployment = [
  {
    icon: EmployeeIcon,
    name: 'Profile',
    path: '/employee/profile',
    exact: true,
  },
  {
    icon: TrainingIcon,
    name: 'Training',
    path: '/employee/training',
  },
  {
    icon: QueryIcon,
    name: 'Query',
    path: '/employee/query',
  },
  {
    icon: LeaveMangementIcon,
    name: 'Leave',
    path: '/employee/leave',
  },
  {
    icon: PerformanceIcon,
    name: 'Appraisal',
    path: '/employee/appraisal',
  },
];
export const LineManager = [
  {
    icon: LineManagerReviewIcon,
    name: 'Performance Review',
    path: '/employee/performance-review',
    exact: true,
  },
  {
    icon: ApprovalIcon,
    name: 'Leave Approval',
    path: '/employee/leave-approval',
  },
];
