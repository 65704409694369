import React from 'react';
import { useTable } from 'react-table';
import styled from '@emotion/styled';

import { EmptyState, SpinnerComponent } from '../common';
import { CustomCheckbox, TableCheckboxWrapper } from '../shared/Checkbox';
import { EventsActionContainerTable, EventsPopupAction } from '../shared/DropDownAction';

import { MainTableWrapper } from './styles';

const IconSpan = styled.span`
  svg {
    :hover {
      transform: scale(1.2);
    }
  }
`;

const TableComponent = (props) => {
  const data = React.useMemo(() => {
    return props.data || [];
  }, [props.data]);

  const columns = props.columns || [];
  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (props.loading || props.error || props.searchLoading) {
    return <SpinnerComponent h="60vh" />;
  }
  return (
    <>
      {props.empty ? (
        <EmptyState message={props.message} h={props.height} />
      ) : (
        <MainTableWrapper
          minH={props.minH || 'auto'}
          minW={props.minW || '900px'}
          maxW={props.maxW || '100%'}
          data-testid="table-component">
          <table {...getTableProps()} className="tableWrap">
            <thead className="tableHead">
              {headerGroups?.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {!props.noCheckbox && (
                    <TableCheckboxWrapper>
                      <CustomCheckbox
                        testid={'handle-checkAll'}
                        variant={'green-checkbox'}
                        checked={props.allChecked}
                        handleChecked={(e) => props.setAllChecked(e.target.checked)}
                      />
                    </TableCheckboxWrapper>
                  )}
                  {headerGroup?.headers?.map((column, j) => (
                    <th
                      className="th-parent"
                      key={j}
                      {...column.getHeaderProps()}
                      style={props.headerStyle}>
                      {column.render('Header')}
                      {column.Header !== props.exclude &&
                      (!props.noSort || column.Header === props.include || column.sortable) ? (
                        <IconSpan className="sort-icon">
                          <svg
                            data-testid={`sort-asc-${column.Header}`}
                            onClick={() => props.handleMultiSortBy(column.Header)}
                            className={''}
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1.52881 4.48633L4.99981 1.00033L8.47081 4.48633"
                              stroke="#999FA3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <svg
                            data-testid={`sort-desc-${column.Header}`}
                            onClick={() => props.handleMultiSortBy(column.Header, { desc: true })}
                            className={'svg-bot'}
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1.52881 1.48633L4.99981 4.97233L8.47081 1.48633"
                              stroke="#999FA3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconSpan>
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                  {!props.noAction ? <th></th> : null}
                </tr>
              ))}
              <tr></tr>
            </thead>
            <tbody {...getTableBodyProps()} className="tableBody">
              {rows?.map((row, j) => {
                prepareRow(row);
                return (
                  <tr key={row.id} {...row.getRowProps()} className="tableRow">
                    {!props.noCheckbox && (
                      <TableCheckboxWrapper>
                        <CustomCheckbox
                          testid="checkbox-table"
                          variant={'green-checkbox'}
                          handleChecked={() =>
                            props.handleChecked(
                              props.emails ? props.emails[j] : props.ids[j],
                              props.ids[j]
                            )
                          }
                          checked={props.checkedBool[j]}
                        />
                      </TableCheckboxWrapper>
                    )}

                    {row?.cells?.map((cell, i) => {
                      return (
                        <td className="tableCell" key={i} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                          {''}
                        </td>
                      );
                    })}
                    {!props.noAction && (
                      <EventsActionContainerTable>
                        <EventsPopupAction
                          dropdownActions={props.dropdownActions}
                          actionOnData={props.actionOnData}
                          email={props.emails ? props.emails[j] : null}
                          id={props.ids[j]}
                          status={props?.empStatus ? props.empStatus[j] : null}
                        />
                      </EventsActionContainerTable>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </MainTableWrapper>
      )}
    </>
  );
};

export { TableComponent };
