import React from 'react';
import { useMutation } from '@apollo/client';

import { useToastify } from '../hooks/useToastify';
import { CREATE_JOB, EDIT_JOB, FETCH_JOBS, FETCH_SINGLE_JOB } from '../query/admin/jobs';
import { JOB_STATUS, transformJobFormDetails } from '../utils';

import { useApplicantForm } from './applicantsForm';
import { useJobDetailsForm } from './jobDetailsForm';
import { useScoreCardContext } from './ScoreCardDetails';

const HandleCreateJob = React.createContext();

const spreadActionReducer = (state, action) => ({ ...state, ...action });

function HandleCreateJobProvider(props) {
  const { onSuccessfulPublish, onSuccessfulPublishDraft, onSuccessfulEdit } = props;
  const [state, dispatch] = React.useReducer(spreadActionReducer, {});
  const [status, setStatus] = React.useState(null);

  const { handleSubmit: handleJD, setValue } = useJobDetailsForm();
  const { handleSubmit: handleAF } = useApplicantForm();
  const { list: scoreCards } = useScoreCardContext();

  const { successToast, errorToast } = useToastify();
  const [createJob, { loading: createJobLoading }] = useMutation(CREATE_JOB);
  const [editJob, { loading: editJobLoading }] = useMutation(EDIT_JOB);

  async function grabDataWithStatus(status = JOB_STATUS.Draft) {
    let jobDetails, applicantsForm;
    const onSubmitJobDetails = (data) => (jobDetails = data);
    const onSubmitApplicantsForm = (data) => (applicantsForm = data);
    handleJD && (await handleJD(onSubmitJobDetails)());
    handleAF && (await handleAF(onSubmitApplicantsForm)());
    if (!jobDetails) return null;
    if (!applicantsForm) return null;
    jobDetails.status = status;
    return transformJobFormDetails({ jobDetails, applicantsForm, scoreCards });
  }

  async function onPublish(asDraft = false) {
    try {
      const ready = await grabDataWithStatus(asDraft ? JOB_STATUS.Draft : JOB_STATUS.Active);
      if (!ready) return; // don't proceed is data not ready
      if (!asDraft) setStatus(JOB_STATUS.Active);
      const res = await createJob({
        variables: { data: ready },
        refetchQueries: [{ query: FETCH_JOBS }],
      });
      const { data } = res;

      if (data && data.createJob.status === 201) {
        if (!asDraft) onSuccessfulPublish && onSuccessfulPublish();
        else onSuccessfulPublishDraft && onSuccessfulPublishDraft();
        successToast(data.createJob.message);
        setValue('id', data.createJob.data._id);
      } else errorToast(data.createJob.message);
    } catch (err) {
      errorToast(err.message);
    }
  }

  async function onSaveAsDraft() {
    setStatus(JOB_STATUS.Draft);
    return onPublish(true);
  }

  async function onEditJob() {
    try {
      let jobDetails;
      const onSubmitJobDetails = (data) => (jobDetails = data);
      handleJD && (await handleJD(onSubmitJobDetails)());
      if (!jobDetails) return null;
      const ready = await grabDataWithStatus(jobDetails.status);
      if (!ready) return null;
      const res = await editJob({
        variables: { data: ready, filter: { _id: jobDetails._id } },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: FETCH_SINGLE_JOB, variables: { filters: { _id: jobDetails._id } } },
        ],
      });
      const { data } = res;
      if (data && data.updateJob.status === 200) {
        onSuccessfulEdit && onSuccessfulEdit();
        successToast(data.updateJob.message);
      } else {
        errorToast(data.updateJob.message);
      }
    } catch (err) {
      errorToast(err.message);
    }
  }

  return (
    <HandleCreateJob.Provider
      value={{
        state,
        dispatch,
        onPublish,
        onSaveAsDraft,
        onEditJob,
        editJobLoading,
        createJobLoading: createJobLoading && status !== JOB_STATUS.Draft,
        saveAsDraftLoading: createJobLoading && status === JOB_STATUS.Draft,
      }}>
      {props.children}
    </HandleCreateJob.Provider>
  );
}

function useHandleCreateJob() {
  const context = React.useContext(HandleCreateJob);
  if (!context) throw Error('useHandleCreateJob must be wrapped in HandleCreateJobProvider');
  return context;
}

export { HandleCreateJobProvider, useHandleCreateJob };
