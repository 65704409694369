import * as React from 'react';

import { JobInputLabel } from 'components';

import { INPUT_TYPES } from '../helpers';

import { FieldAppearance } from './FieldAppearance';

export function InputField(props) {
  const { field, fieldName } = props;
  const name = field.name || '';
  const isDropdown = field.type === INPUT_TYPES.Dropdown;
  const placeholder = isDropdown ? `Select ${name.toLowerCase()}` : `Enter ${name.toLowerCase()}`;
  return (
    <>
      <JobInputLabel>
        {name}
        {field.required && <span style={{ color: 'red' }}>*</span>}
      </JobInputLabel>
      <FieldAppearance attrs={{ placeholder }} fieldName={fieldName} {...field} />
    </>
  );
}
