import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { SingleEmployeeTimesheetTable } from 'pages/admin/timesheet/components/Modals/SingleEmployeeTimesheet';
import { CLOCK_IN, CLOCK_OUT, FETCH_TIMESHEETS } from 'query';
import { getGraphQLResponse, getItem, handleGraphQLResponse } from 'utils';
import { isTesting } from 'utils';

import { BaseInput, CustomButton, SimpleModal, SpinnerComponent } from 'components';

import calendar from '../../../../assets/icons/Calendar copy.svg';
import clockIcon from '../../../../assets/icons/timer.svg';
import { ProfileCard } from '../overview/components';
import { getGreeting, joinNames } from '../overview/helpers';
import { FETCH_EMPLOYEE_DETAILS } from '../query';

import { ClockInOut } from './ClockInout';
import { getClock } from './helpers';

export function EmployeeTimesheet() {
  const initialState = { showModal: false, contentType: '' };

  function simpleModalreducer(state, action) {
    return { ...state, ...action };
  }

  const [{ showModal, contentType }, setModalType] = React.useReducer(
    simpleModalreducer,
    () => initialState
  );
  const { successToast, errorToast } = useToastify();
  const [page, setPage] = React.useState(1);
  const [date, setDate] = React.useState({
    start: '',
    end: '',
  });

  const userDetails = getItem('hrm_user');
  let id = userDetails?._id;
  if (isTesting) {
    id = 'mock_id';
  }

  const { data, loading } = useQuery(FETCH_EMPLOYEE_DETAILS, {
    variables: {
      filters: {
        _id: id,
      },
    },
  });

  const fetchTimesheetVariables = React.useMemo(
    () => ({
      filters: {
        searchString: null,
        date,
        userId: id,
      },
      page,
    }),
    [date, page]
  );
  const { data: timesheetsData, loading: loadingTimesheets } = useQuery(FETCH_TIMESHEETS, {
    variables: fetchTimesheetVariables,
  });

  const [onClockin, { loading: loadingClockin }] = useMutation(CLOCK_IN, {
    refetchQueries: [
      {
        query: FETCH_TIMESHEETS,
        variables: fetchTimesheetVariables,
      },
    ],
    onCompleted: onMutationCompleted,
    onError: onMutationError,
  });
  const [onClockout, { loading: loadingClockout }] = useMutation(CLOCK_OUT, {
    refetchQueries: [
      {
        query: FETCH_TIMESHEETS,
        variables: fetchTimesheetVariables,
      },
    ],
    onCompleted: onMutationCompleted,
    onError: onMutationError,
  });

  const onClockinEmployee = async () => {
    await onClockin();
  };
  const onClockoutEmployee = async () => {
    await onClockout();
  };

  function onMutationCompleted(res) {
    handleGraphQLResponse(res);
    const responseObject = getGraphQLResponse(res);
    successToast(responseObject.message);
    setModalType({ showModal: false, contentType: '' });
  }
  function onMutationError(error) {
    errorToast(error?.message);
  }

  return (
    <>
      <Root>
        <div className="timesheet__heading">
          <Stack>
            <Heading fontSize={'2.4rem'} data-testid={'overviewHeading'}>
              Good <span>{getGreeting()}</span>,{' '}
              {joinNames({
                firstName: data?.fetchUsersFiltered?.data[0]?.firstName,
                lastName: data?.fetchUsersFiltered?.data[0]?.lastName,
              })}
            </Heading>
            <Text fontSize={'1.6rem'} p={'1.6rem 0'}>
              Here’s what’s going on at {userDetails?.companyId?.companyName}.
            </Text>
          </Stack>
          <>
            {loadingTimesheets ? null : (
              <>
                {getClock(timesheetsData?.fetchTimesheets?.data) === 'clockin' && (
                  <CustomButton
                    name={'Clock In'}
                    src={clockIcon}
                    mW={'170px'}
                    h={'46px'}
                    onClick={() => setModalType({ showModal: true, contentType: 'clockin' })}
                  />
                )}

                {getClock(timesheetsData?.fetchTimesheets?.data) === 'clockout' && (
                  <CustomButton
                    name={'Clock Out'}
                    variant={'red'}
                    src={clockIcon}
                    mW={'170px'}
                    h={'46px'}
                    onClick={() => setModalType({ showModal: true, contentType: 'clockout' })}
                  />
                )}
              </>
            )}
          </>
        </div>

        <div className="dashboard__profile">
          {loading ? (
            <Grid h={'100%'} placeItems={'center'}>
              <SpinnerComponent />
            </Grid>
          ) : (
            <ProfileCard userData={data?.fetchUsersFiltered?.data[0]} />
          )}
        </div>
        <div className="employee__timesheet--main">
          <Stack gap={'24px'}>
            <Flex
              alignItems={'center'}
              justifyContent={'space-between'}
              className="filter"
              gap={'24px'}>
              <Text fontSize={'20px'} fontWeight={500}>
                Your Timesheets
              </Text>
              <Flex gap={'16px'} overflow={'auto'}>
                <Flex gap={'8px'} alignItems={'center'} className="date__filter">
                  <Text>Start</Text>
                  <BaseInput
                    data-testid={'date-from'}
                    type={'date'}
                    rightIcon={calendar}
                    maxWidth={'160px'}
                    value={date.start}
                    onChange={(e) =>
                      setDate((prev) => {
                        return { ...prev, start: e.target.value };
                      })
                    }
                  />
                </Flex>

                <Flex gap={'8px'} alignItems={'center'} className="date__filter">
                  <Text>End</Text>
                  <BaseInput
                    type={'date'}
                    data-testid={'date-to'}
                    rightIcon={calendar}
                    maxWidth={'160px'}
                    value={date.end}
                    onChange={(e) =>
                      setDate((prev) => {
                        return { ...prev, end: e.target.value };
                      })
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
            <Stack overflow={'hidden'}>
              <SingleEmployeeTimesheetTable
                tData={timesheetsData?.fetchTimesheets?.data}
                pageCount={timesheetsData?.fetchTimesheets?.pageCount}
                page={page}
                setPage={setPage}
                loading={loadingTimesheets}
              />
            </Stack>
          </Stack>
        </div>
      </Root>

      <SimpleModal
        showModal={showModal}
        setShowModal={() => setModalType({ showModal: false, contentType: '' })}>
        {contentType === 'clockin' && (
          <ClockInOut
            loading={loadingClockin}
            handleSubmit={onClockinEmployee}
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
            type={'clockin'}
          />
        )}
        {contentType === 'clockout' && (
          <ClockInOut
            handleSubmit={onClockoutEmployee}
            loading={loadingClockout}
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
            type={'clockout'}
          />
        )}
      </SimpleModal>
    </>
  );
}

const Root = styled(Stack)`
  width: 100%;
  padding: 32px;
  gap: 32px;

  @media screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-columns: repeat(3, 1fr);

    .timesheet__heading {
      grid-column: span 3;
      display: flex;
      justify-content: space-between;
    }

    .dashboard__profile {
      grid-column: span 1;
    }

    .employee__timesheet--main {
      grid-column: span 2;
    }
  }
  @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);

    .timesheet__heading {
      grid-column: span 4;
    }

    .dashboard__profile {
      grid-column: span 1;
    }

    .employee__timesheet--main {
      grid-column: span 3;
    }
  }
`;
