import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Grid, Image, Text } from '@chakra-ui/react';

import { BaseButton } from 'components';

import checkMail from '../../../../../assets/icons/check-mail.svg';
import logo from '../../../../../assets/images/enyataLogo.svg';
import nameLogo from '../../../../../assets/images/nameLogo.svg';

const VerifiedLineManager = () => {
  const navigate = useNavigate();

  return (
    <>
      <Flex className="images">
        <Image src={logo} />
        <Image src={nameLogo} />
      </Flex>
      <Grid className="text">
        <Text className="text-1">Thank you for accepting our invitation</Text>
        <hr className="line" />
        <Image src={checkMail} className="image" />
        <Text className="text-2">
          You are now verified as a Line Manager <br /> Login to continue using HRMS
        </Text>
        <BaseButton onClick={() => navigate('/employee/login')}>Login</BaseButton>
      </Grid>
    </>
  );
};

export { VerifiedLineManager };
