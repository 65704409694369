import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { CgAsterisk } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastify } from 'hooks/useToastify';
import { formatD } from 'pages/admin/leave-management/Requests/helper';
import {
  CREATE_LEAVE,
  EMPLOYEE_LEAVE_SUMMARY,
  FETCH_EMPLOYEE_LEAVE,
  FETCH_LEAVE_HISTORY,
  FETCH_LEAVE_TYPE,
} from 'query';
import * as yup from 'yup';

import { BaseDropdown, BaseInput, Calendar, CustomButton, ModalBackButton } from 'components';

import { OverlapRequest, Summary } from './component';

export const leaveSchema = yup.object().shape({
  description: yup.string().required('Provide a description'),
  leaveTypeId: yup.object().nullable(true).required('Select a Leave Type'),
});

export const LeaveRequestPage = () => {
  const [date, setDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const navigate = useNavigate();

  const { data, refetch } = useQuery(FETCH_LEAVE_TYPE, {});
  const [createLeaveRequest, { loading }] = useMutation(CREATE_LEAVE);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(leaveSchema) });

  const leaveType = useWatch({ control, name: 'leaveTypeId' });

  const { data: leaveSummary, loading: loadingSummary } = useQuery(EMPLOYEE_LEAVE_SUMMARY, {
    variables: {
      filters: {
        startDate: formatD(date),
        endDate: formatD(toDate),
      },
      leaveTypeId: leaveType?.value,
    },
  });

  const summaryData = leaveSummary?.fetchLeaveSummary?.data;

  const summary = {
    'Count As': summaryData?.count,
    'Balance Before': summaryData?.balanceBefore && `${summaryData?.balanceBefore} day(s)`,
  };

  const overlap = summaryData?.requests;

  const options = data?.retrieveLeaveTypes?.data?.map(({ _id, name }) => ({
    label: name,
    value: _id,
  }));

  React.useEffect(() => {
    const errorList = Object.entries(errors);
    if (errorList?.length) {
      errorList?.forEach(([, val]) => {
        const message = val?.message;
        errorToast(message);
      });
    }
    refetch();
  }, [data, errors]);

  const { successToast, errorToast } = useToastify();

  const onSubmit = async (data) => {
    const sDate = formatD(date);
    const eDate = formatD(toDate);
    const leaveTypeId = data?.leaveTypeId?.value;
    const toSend = { ...data, leaveTypeId: leaveTypeId, startDate: sDate, endDate: eDate };

    if (sDate === eDate) {
      errorToast('Choose leave date');
    } else {
      try {
        const response = await createLeaveRequest({
          variables: { data: toSend },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: FETCH_EMPLOYEE_LEAVE,
            },
            {
              query: FETCH_LEAVE_HISTORY,
            },
          ],
        });

        const message = response?.data?.createLeaveRequest?.message;
        successToast(message);
      } catch (error) {
        errorToast('Error occured');
      } finally {
        reset();
      }
    }
  };

  return (
    <Root data-testid="leave-request">
      <div className="content">
        <div className="header">
          <ModalBackButton
            onClick={() => navigate('/employee/leave')}
            data-testid="modal-back-btn"
          />
          <h1 className="heading">Leave Management</h1>
        </div>
        <div className="notification">
          <p>This request may result in a negative balance but you can still make request</p>
        </div>
        <div className="content-body">
          <form className="left" onSubmit={handleSubmit(onSubmit)}>
            <div className="calender">
              <p>Select a date</p>
              <Box position="relative">
                <Box as="span" position="absolute" top="-2rem" left="9.5rem">
                  <CgAsterisk color="var(--error)" size="10" />
                </Box>
              </Box>
              <Calendar
                isRange
                date={date}
                setDate={setDate}
                toDate={toDate}
                setToDate={setToDate}
              />
            </div>
            <div className="left-form-fields">
              <Controller
                name="leaveTypeId"
                control={control}
                render={({ field }) => (
                  <BaseDropdown
                    options={options}
                    label="Select Leave Type"
                    w="100%"
                    fontW="600"
                    labelSize="1.2rem"
                    {...field}
                  />
                )}
              />
              <BaseInput
                label="Add Description"
                isRequired
                type="textarea"
                rows="5"
                labelSize="1.2rem"
                fontW="600"
                placeholder="Vacation"
                {...register('description')}
              />
              <Flex gap="10px" height="100%" justifyContent={'flex-end'} alignItems="flex-end">
                <CustomButton
                  name="Cancel"
                  variant="black--outline"
                  h="43px"
                  mW="101px"
                  type="button"
                  onClick={() => navigate('/employee/leave')}
                />
                <CustomButton
                  loading={loading}
                  name="Request"
                  variant="black"
                  h="43px"
                  mW="101px"
                  type="submit"
                />
              </Flex>
            </div>
          </form>
          <div className="right">
            <Summary summary={summary} loading={loadingSummary}></Summary>
            <OverlapRequest overlap={overlap} loading={loadingSummary} />
          </div>
        </div>
      </div>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: calc(100vh - 8.4rem);
  padding: 24px;

  .content {
    background: var(--white);
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    height: calc(100vh - 8.4rem);
    padding: 0rem 2.4rem 3rem;
    overflow-y: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .header {
      display: flex;
      align-items: center;
      gap: 2.4rem;
      background: var(--white);
      position: sticky;
      top: 0;
      margin-bottom: 1.6rem;
      padding: 2.4rem 0 0.8rem 0;
      z-index: 100;
      .heading {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3.3rem;
        color: var(--black);
      }
    }
    .calender {
      width: 32rem;
      min-width: 50%;
      padding-bottom: 4rem;
    }

    .notification {
      width: 100%;
      border: 1px solid #f0a800;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 0.8rem;
      position: sticky;
      top: 7rem;
      background: #fff;
      z-index: 1000;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #bb7300;
        position: relative;
      }
    }

    .content-body {
      display: grid;
      grid-template-columns: auto;
      gap: 2.4rem;

      @media (min-width: 1200px) {
        grid-template-columns: auto 300px;
      }
      .left {
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        padding: 2.4rem;
        display: grid;
        grid-template-columns: max-content;
        grid-gap: 3.2rem;
        margin: 2.4rem 0 0 0;

        @media (min-width: 860px) {
          grid-template-columns: max-content auto;
        }
        @media (min-width: 1200px) {
          margin: 2.4rem;
        }

        .left-form-fields {
          display: grid;
          // flex-direction: column;
          grid-template-rows: repeat(2, max-content) auto;
          gap: 3.2rem;
        }
      }

      .right {
        padding-top: 2.4rem;
        display: grid;
        gap: 2.4rem;

        .custom-text {
          font-size: 24px;
          line-height: 28px;
          font-weight: 600;
          color: var(--black);
          letter-spacing: -0.02em;
        }
      }
    }
  }
`;
