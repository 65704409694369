import React from 'react';
import styled from '@emotion/styled';

export const LeaveItem = ({ data }) => {
  const color = ['#FF8500', '#4E23ED', '#5DB3F0', '#00BB9E'];

  const summary = {
    entitled: data?.entitled,
    spent: data?.spent,
    requested: data?.requested,
    totalBalance: data?.totalBalance,
  };

  return (
    <>
      <LeaveCard data-testid="leave-item">
        <div className="card">
          <p className="leave-name">{data?.leaveTypeId?.name}</p>
          <div className="leave__info">
            {Object.entries(summary)?.map(([key, value], i) => {
              return (
                <div className="leave__info--item" key={i}>
                  <p className="leave__info--item-name">{key}</p>
                  <p
                    className="leave__info--item-summary"
                    style={{
                      borderLeft: `3px solid ${color[i]}`,
                    }}>
                    <span>{value}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </LeaveCard>
    </>
  );
};

const LeaveCard = styled.div`
  .card {
    width: 223px;
    height: 248px;
    background: #fafcff;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    padding: 16px 24px;
    .leave-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0012em;
      color: #1a1a1a;
    }

    .leave__info {
      display: grid;
      grid-gap: 2.4rem;
      margin-top: 2rem;
      &--item {
        display: grid;
        grid: 1fr / repeat(2, 1fr);
        align-items: center;
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.12px;
          color: #333758;
        }

        &-name {
          opacity: 0.5;
          text-transform: capitalize;
        }

        &-summary {
          position: relative;
          // border-left: 3px solid #ff8500;
          border-radius: 0.8px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 4px;
          span {
            font-weight: 700;
            line-height: 17px;
          }
        }
      }
    }
  }
`;
