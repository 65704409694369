import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { Button, SectionBox } from 'components';

import { deletebutton, edit, sectionImage } from '../images';

import { buttonStyle, Container, Heading } from './styles';

const SectionView = ({
  section,
  handleSectionActions,
  setSectionData,
  sectionData,
  selected,
  validateSection,
  onSaveSection,
  id,
}) => {
  return selected === section?.title ? (
    <SectionBox
      id={id}
      setSectionData={setSectionData}
      sectionData={sectionData}
      handleSectionActions={handleSectionActions}
      isDisabled={validateSection}
      onSaveSection={onSaveSection}
    />
  ) : (
    <Container>
      <Heading>
        <Flex gap={'8px'} alignItems={'center'}>
          <img src={sectionImage} />
          <Text className="heading">{section?.title}</Text>
        </Flex>
        <Flex gap={'24px'} className="buttons">
          <Button
            dataTestId={'editButton'}
            img={edit}
            title={'Edit'}
            imgStyle={{ ...buttonStyle, color: '#3E7EEE' }}
            onClick={() => handleSectionActions('edit', section?.title)}
          />
          <Button
            dataTestId={'deleteButton'}
            img={deletebutton}
            title={'Delete'}
            imgStyle={{ ...buttonStyle, color: '#FA4A4A' }}
            onClick={() => handleSectionActions('delete', section?.title)}
          />
        </Flex>
      </Heading>
    </Container>
  );
};

export default SectionView;
