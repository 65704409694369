import styled from '@emotion/styled';

export const RolesPageContainer = styled.div`
  position: relative;

  .header {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: -0.02em;
    color: var(--black);
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
  }

  .roles-table-wrapper {
    overflow-x: auto;
    padding-bottom: 4rem;
    min-height: 60vh;

    thead tr {
      background: var(--grey-100);
    }
    thead th {
      border-bottom: 1px solid rgba(62, 126, 238, 0.2);
      border-top: 1px solid rgba(62, 126, 238, 0.2);
    }
    thead th:first-of-type {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      border-left: 1px solid rgba(62, 126, 238, 0.2);
    }
    thead th:last-child {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      border-right: 1px solid rgba(62, 126, 238, 0.2);
    }
    tbody th,
    tbody td {
      border-bottom: 1px solid rgba(62, 126, 238, 0.2);
    }
  }

  .roles-table {
    thead th:first-of-type,
    tbody td:first-of-type {
      padding-left: 1.6rem;
    }
  }

  .permissions {
    thead th:first-of-type {
      padding-left: 1.6rem;
    }

    table {
      width: 100%;
      text-align: left;
      border-collapse: separate;
      border-spacing: 0px;
    }

    thead tr {
      border-radius: 0.8rem;
      align-items: center;
      height: 4.8rem;
      font-weight: 700;
      letter-spacing: -0.05rem;
      line-height: 1.6rem;
    }

    thead th:first-of-type,
    tbody td:first-of-type {
      width: 20%;
      max-width: 22rem;
    }

    tbody tr td {
      padding-left: 1.6rem;
    }

    tbody td {
      align-items: center;
      height: 6rem;
      background: #fff;
      color: #23273b;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }

  .permissions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
    input {
      border: none;
      pointer-events: none;
    }

    input,
    h1 {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: var(--black);
    }
  }
`;
