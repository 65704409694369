import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useCountriesStatesCities } from 'hooks';

import { BaseDropdown, BaseInput, CustomButton } from 'components';

import { HeadingText } from '../onboard-components/Heading';

export const Contacts = ({ register, control, setNextTab }) => {
  const { setValue } = useForm();
  const watchCountry = useWatch({ control, name: `address.country` });
  const watchState = useWatch({ control, name: `address.state` });
  const watchCity = useWatch({ control, name: `address.district` });

  const {
    states: states,
    countries: countries,
    cities: cities,
  } = useCountriesStatesCities({
    country: watchCountry,
    state: watchState,
    city: watchCity,
    setValue,
    stateName: `address.state`,
  });
  return (
    <ContactsRoot>
      <Grid className="contact-grid-wrap">
        <GridItem w="218px">
          <HeadingText headingText="Contact Information" mb="10px" />
        </GridItem>
        <GridItem>
          <ContactsFormRoot>
            <Grid className="contact-form-grid-wrap">
              <GridItem>
                <BaseInput
                  {...register('phoneNumber')}
                  label="Phone Number"
                  placeholder="Enter Phone number"
                  fontSize="1.6rem"
                  disabled
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('email')}
                  label="Email Address"
                  placeholder="Enter Email Address"
                  fontSize="1.6rem"
                  disabled
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('employmentInfo.workEmail')}
                  label="Work Email Address"
                  placeholder="Enter work email"
                  fontSize="1.6rem"
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('address.address', { required: true })}
                  label="Address 1"
                  placeholder="Enter your address"
                  fontSize="1.6rem"
                  isRequired
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                  name="address.country"
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose a country"
                      options={countries}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Country"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                  name="address.state"
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose a state"
                      options={states}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="State"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem mb="2.4rem">
                <Controller
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                  name="address.district"
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose a city"
                      options={cities}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="City"
                      isRequired
                    />
                  )}
                />
              </GridItem>
            </Grid>
            <CustomButton
              name="Confirm & Proceed"
              variant="primary"
              mW="131px"
              onClick={setNextTab}
              type="button"
            />
          </ContactsFormRoot>
        </GridItem>
      </Grid>
    </ContactsRoot>
  );
};

const ContactsRoot = styled.div`
  padding: 40px 0 162px 16px;

  .inputType {
    border: none;
  }
  .css-1iyp8s0 {
    position: absolute;
    right: 0;
    margin-top: 48px;
    padding: 12px 48px;
  }

  .css-4302v8 {
    margin-top: 8px;
  }

  .css-b62m3t-container,
  .react-select--is-disabled {
    margin-top: 8px;
  }

  .css-18v3ng0-control {
    border: none;
  }

  .contact-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 1200px) {
      gap: 105px;
      grid-template-columns: 218px 500px;
    }
  }

  .contact-form-grid-wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
    @media (min-width: 1200px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
  }
`;

const ContactsFormRoot = styled.div`
  position: relative;
`;
