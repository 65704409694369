import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { FormControl, FormLabel, Grid, GridItem, SimpleGrid, Textarea } from '@chakra-ui/react';

import { BaseDropdown, BaseInput } from '../../../../components';

const colSpan = { base: 2, md: 1 };

export const ContactInformation = ({
  register,
  control,
  countries,
  contactStates,
  secondcontactCountries,
  secondcontactStates,
}) => {
  const { fields } = useFieldArray({
    control,
    name: 'address',
  });
  return (
    <SimpleGrid columns={2} marginTop="4rem" gap="6">
      {fields.map((field, index) => {
        return (
          <Grid key={field.id} gridGap={'1.6rem'}>
            <GridItem colSpan={colSpan}>
              <FormControl>
                <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
                  Address {index + 1}
                </FormLabel>
                <Textarea
                  {...register(`address.${index}.address`)}
                  fontSize="1.6rem"
                  height="103px"
                  backgroundColor="#F7F9FB"
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={colSpan}>
              <Controller
                defaultValue=""
                control={control}
                name={`address.${index}.country`}
                render={({ field }) => (
                  <BaseDropdown
                    {...field}
                    w="322"
                    labelSize="1.4rem"
                    label="Country"
                    options={index === 0 ? countries : secondcontactCountries}
                    border="1px solid #E0E0E0"
                  />
                )}
              />
            </GridItem>
            <GridItem colSpan={colSpan}>
              <Controller
                defaultValue=""
                control={control}
                name={`address.${index}.state`}
                render={({ field }) => (
                  <BaseDropdown
                    {...field}
                    w="322"
                    labelSize="1.4rem"
                    label="State"
                    border="1px solid #E0E0E0"
                    options={index === 0 ? contactStates : secondcontactStates}
                  />
                )}
              />
            </GridItem>
            <GridItem colSpan={colSpan}>
              <BaseInput
                {...register(`address.${index}.district`)}
                placeholder="L.G.A/District"
                fontSize="1.6rem"
                label="L.G.A/District"
              />
            </GridItem>
          </Grid>
        );
      })}
    </SimpleGrid>
  );
};
