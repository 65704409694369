import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { FETCH_ALL_EMPLOYEES } from 'query';
import { PROMOTE_LINE_MANAGER } from 'query/admin/line-manager/line-manager';

import { BaseButton, InputBox, Modal } from 'components';

const InputFields = styled(Grid)`
  max-width: 737px;
  border-radius: 4px;

  .label {
    opacity: 0.5;
  }

  .outline {
    border: 1px solid #f2f2f2;
  }

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    padding: 10px;

    .input-style {
      width: 100%;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const AddLineManager = ({ showModal, setShowModal, refetch }) => {
  const { successToast, errorToast } = useToastify();
  const [promoteToLineManager, { loading }] = useMutation(PROMOTE_LINE_MANAGER);

  const initialState = { id: '', email: '' };

  let [state, setState] = useState(initialState);

  const { data: allEmployees } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      filters: {
        isDefaultAdmin: false,
      },
    },
  });

  const employees = allEmployees?.fetchUsersFiltered?.data;

  const handleChange = (e) => {
    let { value } = e.target;

    setState(value);
  };

  const onAdd = async () => {
    try {
      const res = await promoteToLineManager({
        variables: {
          id: state.id,
        },
      });
      const { data } = res;

      if (data && data.promoteToLineManager.status === 200) {
        successToast('Line manager created sucessfully');
        setState(initialState);
        setShowModal(false);
        refetch();
      } else errorToast(data.promoteToLineManager.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  return (
    <Modal
      title={'Invite Line Manager'}
      showModal={showModal}
      closeModal={() => setShowModal(!showModal)}>
      <Grid gap={'4.8rem'}>
        <InputFields>
          <Flex
            className="input-style"
            flexDirection={'column'}
            gap={'24px'}
            paddingBottom={'16px'}>
            <InputBox
              label={'Employee Name'}
              placeholder={'Jane Doe'}
              required={true}
              type={'dropdown'}
              dataTestId={'id'}
              dropdown={employees}
              id={'name'}
              handleChange={(e) =>
                handleChange({
                  target: {
                    name: 'id',
                    value: e.value,
                  },
                })
              }
            />
            <InputBox
              label={'Email Address'}
              placeholder={'Eg: Vacation'}
              required={true}
              dataTestId={'email'}
              value={state.email}
              id={'email'}
              handleChange={handleChange}
            />
          </Flex>
        </InputFields>
        <Buttons>
          <BaseButton
            dataTestId={'cancel'}
            variant={'outline'}
            onClick={() => setShowModal(!showModal)}>
            Cancel
          </BaseButton>
          <BaseButton dataTestId={'save'} isDisabled={loading} onClick={onAdd} isLoading={loading}>
            Save
          </BaseButton>
        </Buttons>
      </Grid>
    </Modal>
  );
};

export { AddLineManager };
