import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AssignEmployeesModal, Search, SpinnerComponent } from 'components';
import { TableComponent } from 'components';

import { TableColls } from './CreatereviewsTable';
import { useReviewers } from './Reviewers.hooks';

export function Reviewers() {
  const {
    setPage,
    search,
    loading,
    tableIds,

    selectAll,
    setSearchString,
    setSelectAll,
    dropdownActions,
    handleSearchChange,
    actionOnData,
    readyTableData,

    addingReviewerFor,
    setAddingReviewerFor,
    handleChangeReviewer,
    currentReviewers,
  } = useReviewers();

  return (
    <Wrapper>
      <Flex my="24px">
        <Search
          placeholder={'Search Employee'}
          value={search}
          setPage={setPage}
          setSearchString={setSearchString}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </Flex>
      {!loading ? (
        <>
          <Box overflowX={'auto'}>
            <TableComponent
              noCheckbox
              columns={TableColls}
              data={readyTableData}
              ids={tableIds}
              dropdownActions={dropdownActions}
              actionOnData={actionOnData}
              setAllChecked={setSelectAll}
              allChecked={selectAll}
            />
          </Box>
        </>
      ) : (
        <SpinnerComponent h="20rem" />
      )}

      <AssignEmployeesModal
        assigned={currentReviewers}
        key={addingReviewerFor}
        title="Add/Change Reviewers"
        showModal={!!addingReviewerFor}
        isModal={true}
        setShowModal={setAddingReviewerFor}
        onClick={handleChangeReviewer}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: var(--white);
  margin: auto;

  thead th {
    background: #f3f4f6;
  }

  .Remove.from.cycle {
    color: var(--red);
  }

  .tableWrap {
    width: 720px;
    min-width: 760px;
  }

  thead th {
    background: #f3f4f6;
  }
`;
