import React from 'react';
import { Box, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import UploadIcon from 'assets/icons/upload.svg';
import { getExtFromLink } from 'pages/admin/documents/helper';
import { UserImage } from 'pages/admin/employee/tableConfig';

export const HeadingText = ({ headingText }) => {
  return (
    <>
      <Heading fontWeight={'700'} fontSize={'16px'} lineHeight={'24px'} color={'#364469'}>
        {headingText}
      </Heading>
    </>
  );
};

export const LabelText = ({ labelText }) => {
  return (
    <>
      <Text
        fontWeight={'400px'}
        fontSize={'14px'}
        lineHeight={'16px'}
        letterSpacing={'0.02em'}
        color={'#83889D'}>
        {labelText}
      </Text>
    </>
  );
};

export const PText = ({ pText }) => {
  return (
    <>
      <Text
        fontWeight={'600px'}
        fontSize={'14px'}
        lineHeight={'20px'}
        letterSpacing={'0.01em'}
        color={'#23273B'}>
        {pText}
      </Text>
    </>
  );
};

// export const SKillSet = ({ skills }) => {
//   return (
//     <>
//       <Flex gap={'8px'} width={'395px'} wrap={'wrap'}>
//         {skills.map((skill) => {
//           return (
//             <>
//               <Flex padding={'4px 8px'} background={'rgba(62, 126, 238, 0.1)'} borderRadius={'8px'}>
//                 <Text fontWeight={'500px'} fontSize={'12px'} lineHeight={'20px'}>
//                   {skill}
//                 </Text>
//               </Flex>
//             </>
//           );
//         })}
//       </Flex>
//     </>
//   );
// };

export const RenderDocuments = ({ docs, setDocumentUrl, setDocumentName }) => {
  function downloadFile(url, fileName) {
    fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  }
  return (
    <>
      {docs.map(({ url, name }) => {
        return (
          <Grid gridTemplateColumns={'200px 150px'} gap={'204px'} marginBottom={'32px'} key={url}>
            <GridItem>
              <Flex gap="16px" alignItems={'center'}>
                {getExtFromLink(url)}
                <Box className="document-info">
                  <Text
                    fontWeight={'600px'}
                    fontSize={'14px'}
                    lineHeight={'16px'}
                    color={'#253858'}>
                    {name}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex gap={'25px'} alignItems={'center'}>
                <Text
                  fontWeight={'500px'}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  textDecoration={'underline'}
                  color={'#666E7D'}
                  cursor={'pointer'}
                  data-testid={'btn-clicked-open-document'}
                  onClick={() => {
                    setDocumentUrl(url);
                    setDocumentName(name);
                  }}>
                  Open
                </Text>
                <img
                  src={UploadIcon}
                  alt="upload icon"
                  style={{ cursor: 'pointer' }}
                  onClick={() => downloadFile(url, name)}
                  data-testid={'btn-clicked-download-document'}
                />
              </Flex>
            </GridItem>
          </Grid>
        );
      })}
    </>
  );
};

export const RenderUsers = ({ users }) => {
  return (
    <>
      <Flex gap={'8px'} width={'344px'} wrap={'wrap'}>
        <Box key={users?._id}>
          {UserImage({
            imageUrl: users?.imageUrl,
            firstName: users?.firstName || 'N/',
            lastName: users?.lastName || 'A',
            name: users?.firstName || 'N/' + ' ' + users?.lastName || 'A',
          })}
        </Box>
      </Flex>
    </>
  );
};
