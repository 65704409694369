import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_ALL_USERS, FETCH_COMPANY_DETAILS, GET_DEPARTMENT, GET_ROLES } from 'query';
import { FETCH_PIPELINE_DATA } from 'query';
import { STAGING } from 'query/admin/candidates';
import { getItem } from 'utils';

function useHook() {
  const [user, setUser] = useState();
  const { data: companyDetails, loading: loading1 } = useQuery(FETCH_COMPANY_DETAILS);
  const { data: rolesDetails, loading: loading2 } = useQuery(GET_ROLES);
  const { data: stagesData } = useQuery(STAGING);
  const { data: pipelineData } = useQuery(FETCH_PIPELINE_DATA);
  const { data: adminDetails, loading: loading3 } = useQuery(FETCH_ALL_USERS, {
    variables: { filter: { isDefaultAdmin: true }, page: 1 },
  });
  const { data: departmentDetails, loading: loading4 } = useQuery(GET_DEPARTMENT);

  const compD = companyDetails?.fetchCompanyDetails?.data?.locations?.length > 0;
  const RolesD = rolesDetails?.fetchRolesFiltered?.data?.length > 1;
  const AdminD = adminDetails?.fetchAllAdmins?.data?.length > 1;
  const Department = departmentDetails?.fetchCompanyDepartments?.data?.length > 0;
  const stages = stagesData?.fetchStages?.data;
  const pipeline = pipelineData?.fetchPipelineData?.data;

  React.useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    const userDetails = getItem('hrm_user');
    setUser(userDetails);
  };

  return {
    user,
    compD,
    RolesD,
    AdminD,
    Department,
    loading1,
    loading2,
    loading3,
    loading4,
    stages,
    pipeline,
  };
}

export { useHook };
