import { RiEdit2Fill } from 'react-icons/ri';
import { Box, Text } from '@chakra-ui/react';

function PreviewDetail({ label, value, canEdit, onOpenEdit }) {
  return (
    <>
      <Text fontSize="1.2rem" lineHeight="1.2rem" color="#83889d" mb="8px">
        {label}
      </Text>
      <Box fontSize="1.4rem" lineHeight="1.4rem" fontWeight="600" minH="1.4rem">
        {value || 'N/A'}
      </Box>
      {canEdit && (
        <Box className="edit-control" fontSize="1.4rem" onClick={onOpenEdit}>
          <div className="icon">
            <RiEdit2Fill />
          </div>
          <span className="label">Edit {label.toLowerCase()}</span>
        </Box>
      )}
    </>
  );
}

export { PreviewDetail };
