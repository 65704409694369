import React from 'react';
import styled from '@emotion/styled';

import { ModalBackButton, ModalCloseButton } from 'components';

export const Modal = ({ show, setShow, children }) => {
  return (
    <>
      {show && (
        <Container className="small-modal" data-testid="modal">
          <div className="small-modal-content">
            <div className="small-modal-header">
              <ModalBackButton onClick={setShow} data-testid="modal-back" />
              <ModalCloseButton onClick={setShow} data-testid="modal-close" />
            </div>
            {children}
          </div>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  background: var(--white);
  height: 100%;
  min-height: 78vh;
  position: absolute;
  top: 0;
  rigth: 0;
  left: 0;
  z-index: 5;

  .small-modal-content {
    background: var(--white);
    max-width: 85%;
    height: 100%;
    margin: auto;
    padding: 2.4rem;
  }
  .small-modal-header {
    display: flex;
    justify-content: space-between;
  }
`;
