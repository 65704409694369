import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { DELETE_LEAVE_TYPE, FETCH_LEAVE_POLICY, UPDATE_LEAVE_TYPE } from 'query';

import {
  ActionDropdown,
  Button,
  DeletePromptModal,
  EditLeaveTypeAndPolicy,
  EmptyState,
  SpinnerComponent,
} from 'components';

import bluePlus from '../../../../assets/icons/blue-plus.svg';
import threedots from '../../../../assets/icons/three-dots.svg';

import { LeavePolicy } from './LeavePolicy';

const Card = styled.div`
  padding: 24px;
  border: 1px solid #f2f2f2;
  background: #ffffff;

  .flex {
    h1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #23273b;
      text-transform: capitalize;
    }
  }
`;

export const Table = styled.div`
  width: 100%;

  .head {
    font-weight: 400;
    font-size: 14px;
    color: #666e7d;
  }

  .center {
    text-align: center;
  }

  .body {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #23273b;
    border-bottom: 1px solid #f2f2f2;

    .default {
      background: rgba(62, 126, 238, 0.1);
      border-radius: 10px;
      font-weight: 400;
      font-size: 10px;
      color: #3e7eee;
      padding: 0px 8px;
    }
  }
`;

const LeaveCard = (props) => {
  const typeActions = [
    {
      label: 'Edit Type',
      value: 'editType',
    },
    { label: 'Delete Type', value: 'deleteType' },
  ];

  const policyActions = [
    {
      label: 'Edit',
      value: 'edit',
    },
    { label: 'Assign', value: 'assign' },
    { label: 'Mark as default', value: 'default' },

    {
      label: 'Delete ',
      value: 'delete',
    },
  ];

  const isTesting = process.env.NODE_ENV === 'test';
  const leaveData = [
    {
      policies: [
        {
          employeeCount: 3,
          isDefault: false,
          leaveDuration: {
            duration: 30,
            durationType: 'days',
          },
          leaveTypeId: [
            {
              _id: '637f5e3ec4189bfab704ce5d',
              name: 'Vacation',
            },
          ],
          name: 'casual leave',
          _id: '637f5e8cc4189bfab704ce61',
        },
      ],
    },
    {
      policies: [
        {
          employeeCount: 3,
          isDefault: false,
          leaveDuration: {
            duration: 30,
            durationType: 'days',
          },
          leaveTypeId: [
            {
              _id: '637f5e3ec4189bfab704cd5d',
              name: 'Health',
            },
          ],
          name: 'casual lleave',
          _id: '637f5e8cc4189bfab704ce61',
        },
        {
          employeeCount: 3,
          isDefault: true,
          leaveDuration: {
            duration: 30,
            durationType: 'days',
          },
          leaveTypeId: [
            {
              _id: '637f5e3ec4189bfab704cd5d',
              name: 'Health',
            },
          ],
          name: 'casual day off',
          _id: '637f5e8cc4189bfab704fe61',
        },
      ],
    },
  ];
  const [editType, setEditType] = useState(false);
  const [state, setState] = useState();
  const { successToast, errorToast } = useToastify();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const [updateLeaveType, { loading: LeaveTypeLoading }] = useMutation(UPDATE_LEAVE_TYPE);
  const [deleteLeaveType, { loading: deleting }] = useMutation(DELETE_LEAVE_TYPE);

  const {
    data: leavePolicyData,
    loading,
    refetch: refetchLeavePolicy,
  } = useQuery(FETCH_LEAVE_POLICY, {});

  const fetchLeavePolicy = leavePolicyData?.retrieveLeavePolicy?.data;
  const [leavePolicy, setLeavePolicy] = useState(isTesting ? leaveData : fetchLeavePolicy);

  const createLeavePolicy = () => {
    props.setShowModal(props.data?._id);
  };

  const handleActionClick = (status) => {
    if (status.value === 'editType') {
      openEditType();
    }

    if (status.value === 'deleteType') {
      setShowDeletePrompt(true);
    }
  };

  const openEditType = () => {
    setEditType(!editType);
  };

  const filterPolicy = (list) => {
    if (list) {
      const filterPolicy = list.map((el) => {
        return { employeeCount: el.employeeCount, ...el.policies };
      });

      const newState = filterPolicy?.map((policy) => {
        const leaveTypes = [policy]?.reduce((a, { leaveTypeId: leaveType, ...policyDetails }) => {
          const obj = a.get(leaveType?.name) || { ...leaveType[0], policies: [] };
          obj?.policies?.push({ ...policyDetails });
          obj?.policies?.sort((a, b) => b.isDefault - a.isDefault);
          return a.set(leaveType.name, obj);
        }, new Map());

        const res = [...leaveTypes.values()];

        return res[0];
      });

      setLeavePolicy(newState);
    }
  };

  const onUpdate = async () => {
    try {
      const { _id, ...rest } = state;
      const res = await updateLeaveType({
        variables: {
          data: rest,
          filters: {
            _id: _id,
          },
        },
      });
      const { data } = res;
      if (data && data.editLeaveType.status === 200) {
        successToast('Leave Type updated sucessfully');
        props.refetchLeaveType();
      } else errorToast(data.editLeaveType.message);
    } catch (err) {
      errorToast(err.message);
    }
    setEditType(false);
  };

  const onDelete = async () => {
    try {
      const { _id } = state;
      const res = await deleteLeaveType({
        variables: {
          leaveId: _id,
        },
      });
      const { data } = res;
      if (data && data.deleteLeaveType.status === 200) {
        successToast('Leave Type deleted sucessfully');
        props.refetchLeaveType();
      } else errorToast(data.deleteLeaveType.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  React.useLayoutEffect(() => {
    filterPolicy(isTesting ? leaveData : fetchLeavePolicy);
  }, [fetchLeavePolicy]);

  React.useLayoutEffect(() => {
    setState(props.data);
  }, [props.data]);

  const isDisabled = !(state?.name && state?.paidTimeSetting);
  return (
    <>
      <Card key={props.data._id}>
        <Flex justifyContent={'space-between'}>
          <Flex alignItems={'center'} gap={'8px'} className="flex">
            <h1>{props.data?.name}</h1>
          </Flex>
          <Flex gap={'16px'}>
            <Button
              dataTestId={'createLeavePolicy'}
              img={bluePlus}
              title={'Create Policy'}
              onClick={createLeavePolicy}
              style={{
                border: '1px solid #3E7EEE',
                borderRadius: '4px',
                padding: '12px 16px',
                color: '#3E7EEE',
                fontWeight: 400,
                fontSize: '14px',
              }}
            />
            <ActionDropdown
              btn={threedots}
              actions={typeActions}
              onClick={handleActionClick}
              style={{ transform: 'rotate(90deg)' }}
            />
          </Flex>
        </Flex>
        {editType && (
          <EditLeaveTypeAndPolicy
            isDisabled={isDisabled}
            leavePolicy={false}
            loading={LeaveTypeLoading}
            editMode={editType}
            title={'Edit Leave Type'}
            setState={setState}
            value={state}
            onClick={onUpdate}
            goBack={() => setEditType(!editType)}
          />
        )}
        <Table>
          <Grid className="head" templateColumns={'1fr 1fr 1fr'} padding={'24px'}>
            <h2>Name</h2>
            <h2 className="center">Enrolled employees</h2>
          </Grid>
          {loading && !isTesting ? (
            <Grid placeContent="center">
              <SpinnerComponent data-testid="loading" h="20rem" />
            </Grid>
          ) : null}
          <Grid border={'1px solid #f2f2f2'}>
            {leavePolicy?.map((type) =>
              props.data?._id === type?._id
                ? type?.policies?.map((policy) => (
                    <LeavePolicy
                      key={policy._id}
                      refetchLeavePolicy={refetchLeavePolicy}
                      policyActions={policyActions}
                      leavePolicy={policy}
                      leaveTypeId={type?._id}
                      setLeavePolicy={setLeavePolicy}
                      title={'Edit Leave Policy'}
                    />
                  ))
                : null
            )}
            {!leavePolicy?.filter((policy) => props.data?._id === policy?._id).length &&
            !loading ? (
              <EmptyState message={'No Leave Policy Found'} />
            ) : null}
          </Grid>
        </Table>
      </Card>
      {showDeletePrompt && (
        <DeletePromptModal
          show={showDeletePrompt}
          cancel={() => setShowDeletePrompt(false)}
          deleteItem={() => onDelete(props.data?._id)}
          loading={deleting}
        />
      )}
    </>
  );
};

export { LeaveCard };
