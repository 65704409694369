import React from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const AddNew = () => {
  const navigate = useNavigate();
  return (
    <AddRoot>
      <div
        className="add__new"
        data-testid="add-new-assessment"
        onClick={() =>
          navigate('/admin/assessments/add', { state: { title: '', isEditing: false } })
        }>
        <div className="add__new-item">
          <div className="add__new-icon">
            <BsPlusLg size="24" color={'var(--primary'} />
          </div>
        </div>
        <p className="addnew">Add New</p>
      </div>
    </AddRoot>
  );
};

const AddRoot = styled.div`
  .addnew {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: center;
  }
  .add__new {
    &-item {
      width: 180px;
      height: 180px;
      background: #ffffff;
      border: 1px solid #3e7eee;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 12px;
    }
    &-icon {
      border: 1px solid #3e7eee;
      border-radius: 8px;
      width: max-content;
      padding: 1.2rem;
    }
  }
`;

export { AddNew };
