import React from 'react';
import styled from '@emotion/styled';

import { useJobDetailsForm } from '../../../../context';
import { JobInputLabel } from '../JobInputLabel';

import RichTextEditor from './RichTextEditor';

const JobDescriptionInputRoot = styled.div`
  [role='textbox'] {
    padding: 1.6rem 2.4rem;
    border: 1px solid var(--grey-150);
    min-height: 14.4rem !important;

    &:focus-within,
    &:focus-visible,
    &:focus {
      box-shadow: 0 0 1px 1px #3e7eee;
    }
  }

  h1,
  h2 {
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.4;
    margin-bottom: 4px;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  ul,
  ol {
    padding-left: 1.6rem;
  }

  blockquote {
    padding-left: 2rem;
    padding-block: 8px;
    background: var(--grey-100);
    border-left: 4px solid var(--grey-300);
    color: var(--grey-500);
    margin-block: 4px;
    //font-style: italic;
  }
`;

function JobDescriptionInput() {
  const { setValue, watch } = useJobDetailsForm();
  const description = watch('description');
  const changeDescription = (data) => {
    setValue('description', data);
  };

  return (
    <JobDescriptionInputRoot>
      <JobInputLabel>Job Description</JobInputLabel>
      <RichTextEditor initialValue={description} onChange={changeDescription} />
    </JobDescriptionInputRoot>
  );
}

export { JobDescriptionInput };
