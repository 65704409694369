import { gql } from '@apollo/client';

export const FETCH_LINE_MANAGER_LEAVE_REQUESTS = gql`
  query fetchLineManagerLeaveRequests($keyword: String, $status: String, $page: Int!) {
    fetchLineManagerLeaveRequests(keyword: $keyword, status: $status, page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        countBalance
        startDate
        endDate
        leaveStatus
        employee {
          firstName
          lastName
          imageUrl
        }
        leaveType {
          name
        }
      }
    }
  }
`;

export const LINE_MANAGER_SINGLE_LEAVE_REQUEST = gql`
  query lineManagerSingleLeaveRequest($id: ObjectID!) {
    lineManagerSingleLeaveRequest(id: $id) {
      status
      message
      data {
        _id
        countBalance
        startDate
        endDate
        leaveStatus
        days
        description
        leaveTypeId {
          name
        }
        employeeId {
          firstName
          lastName
          imageUrl
          email
          phoneNumber
          status
          employmentInfo {
            hireDate
          }
          team {
            name
          }
          department {
            name
          }
          designation {
            name
          }
        }
      }
    }
  }
`;

export const FETCH_LINE_MANAGER_REVIEWS = gql`
  query fetchLineManagerReviewList($filters: AllReviewsFilter!, $page: Int) {
    fetchLineManagerReviewList(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        title
        status
        frequency
        schedule {
          startDate
          endDate
        }
        reviewPeriod {
          startDate
          endDate
        }
        reviewType {
          name
          score
        }
        isSeenByReviewee
        isSeenByAll
        lineManagerCompleted
        templateId {
          _id
          name
        }
        createdAt
        updatedAt
        employee {
          _id
          firstName
          lastName
          team {
            name
          }
        }
      }
    }
  }
`;
export const SUBMIT_LINE_MANAGER_APPRAISAL = gql`
  mutation addResponse($data: ResultInput) {
    addResponse(data: $data) {
      status
      message
      data {
        _id
        reviewers {
          reviewer
          score
          percentageScore
        }
      }
    }
  }
`;

export const LINE_MANAGE_UPDATE_LEAVE = gql`
  mutation lineManagerUpdateLeave($action: String!, $leaveId: ObjectID!, $reason: String) {
    lineManagerUpdateLeave(action: $action, leaveId: $leaveId, reason: $reason) {
      status
      message
      data {
        _id
      }
    }
  }
`;
