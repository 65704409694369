import React from 'react';
import { useDrop } from 'react-dnd';
import { useFieldArray } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';

import { BaseInput } from 'components';

import { ASSESSMENT_DND_TYPES } from './Constants';
import { sectionImage } from './icons';
import { InputFieldTemplate } from './InputFieldTemplate';
import { QuestionsPreview } from './QuestionsPreview';

const SectionArea = ({ index, removeSection, register, control }) => {
  const [files, setFiles] = React.useState([]);

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: `sections.${index}.questions`,
  });

  const onDrop = ({ type }) => {
    const oneQuestion = {
      id: uuidv4(),
      questionType: type,
      question: '',
      points: null,
      options: [],
      correctAnswers: [],
      isEditing: false,
      files: [],
      attachedFiles: [],
    };
    append(oneQuestion);
  };
  const { drop, collectedProps } = MyDropTarget({ onDrop });

  return (
    <>
      <Root key={index}>
        <SectionInputArea>
          <Flex className="left" gap={'8px'}>
            <img src={sectionImage} alt="" />
            <SectionInput
              type="text"
              placeholder={'Section Title'}
              {...register(`sections.${index}.name`)}
            />
          </Flex>
          <Flex className="right">
            <Button onClick={removeSection} style={{ background: 'transparent', color: '#999FA3' }}>
              Cancel
            </Button>
            <Button type="submit" style={{ background: '#23273b' }}>
              Save
            </Button>
          </Flex>
        </SectionInputArea>
        <BaseInput
          type="textarea"
          {...register(`sections.${index}.instructions`)}
          label="Instruction"
          isRequired
          fontW="600"
          rows={4}
        />
        <div style={{ height: 'auto', padding: '0.8rem' }}>
          {fields?.map((field, i) => {
            return (
              <>
                <QuestionsPreview
                  key={field?.id}
                  field={field}
                  index={i}
                  remove={remove}
                  update={update}
                  setFiles={setFiles}
                  files={files}
                />
              </>
            );
          })}
        </div>

        {fields?.map((field, i) => {
          return (
            <DropedField
              setFiles={setFiles}
              files={files}
              key={field?.id}
              field={field}
              update={update}
              remove={remove}
              index={i}
              sectionIndex={index}
            />
          );
        })}
        <DropArea ref={drop} style={collectedProps.isOver ? dropstyles : dropstyles}>
          <Flex alignItems={'center'} gap="1rem">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 15C11.866 15 15 11.866 15 8C15 4.134 11.866 1 8 1C4.134 1 1 4.134 1 8C1 11.866 4.134 15 8 15ZM7.5 5.5C7.5 5.36739 7.55268 5.24021 7.64645 5.14645C7.74021 5.05268 7.86739 5 8 5C8.13261 5 8.25979 5.05268 8.35355 5.14645C8.44732 5.24021 8.5 5.36739 8.5 5.5V7.5H10.5C10.6326 7.5 10.7598 7.55268 10.8536 7.64645C10.9473 7.74021 11 7.86739 11 8C11 8.13261 10.9473 8.25979 10.8536 8.35355C10.7598 8.44732 10.6326 8.5 10.5 8.5H8.5V10.5C8.5 10.6326 8.44732 10.7598 8.35355 10.8536C8.25979 10.9473 8.13261 11 8 11C7.86739 11 7.74021 10.9473 7.64645 10.8536C7.55268 10.7598 7.5 10.6326 7.5 10.5V8.5H5.5C5.36739 8.5 5.24021 8.44732 5.14645 8.35355C5.05268 8.25979 5 8.13261 5 8C5 7.86739 5.05268 7.74021 5.14645 7.64645C5.24021 7.55268 5.36739 7.5 5.5 7.5H7.5V5.5Z"
                fill="#3E7EEE"
              />
            </svg>
            <p>Drag & Drop a Field</p>
          </Flex>
        </DropArea>
      </Root>
    </>
  );
};

function DropedField({ field, update, remove, index, setFiles, files, sectionIndex }) {
  return (
    <>
      {!field?.isEditing && (
        <InputFieldTemplate
          key={field.id}
          setFiles={setFiles}
          files={files}
          update={update}
          questionIndex={index}
          sectionIndex={sectionIndex}
          type={field.questionType}
          removeField={() => remove(index)}
          field={field}
        />
      )}
    </>
  );
}

function MyDropTarget({ onDrop }) {
  const [collectedProps, drop] = useDrop(() => ({
    accept: ASSESSMENT_DND_TYPES.AssessmentInputOption,
    drop: (d) => onDrop(d),
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      };
    },
  }));

  return {
    collectedProps,
    drop,
  };
}

const dropstyles = {
  marginTop: '2.4rem',
  minHeight: '8vh',
  borderRadius: '4px',
  border: '2px dashed var(--grey-300)',
  placeContent: 'center',
  alignItems: 'center',
  gridTemplateColumns: 'repeat(2, max-content)',
  cursor: 'pointer',
  color: 'var(--primary)',
  display: 'flex',
  justifyContent: 'center',
};

const DropArea = styled.div`
  border: '2px dashed var(--grey-300)';
  &:hover {
    color: var(--primary);
    background: var(--primary-light);
    border: 2px dashed var(--primary);
  }
`;

const Root = styled.div`
  margin-top: 2.4rem;
  border: 1px solid #f2f2f2;
  border-radius: 8px 8px 0px 0px;
  padding-bottom: 1.6rem;
`;
const Button = styled.button`
  border: 1px solid #f2f2f2;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 4px;
`;

const SectionInputArea = styled.div`
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f4f6;
  border: 1px solid #f2f2f2;
  border-radius: 8px 8px 0px 0px;
  padding: 1.6rem;
`;
const SectionInput = styled.input`
  background: #fafcff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 8px;
  &:focus {
    outline: none;
  }
`;

export { SectionArea };
