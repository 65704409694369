import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { inputError } from 'utils';

import { BaseDropdown, BaseInput } from '../../../../components';
import { bloodGenotype, bloodGroup, twoAnswers } from '../../../../data/dropDownOptions';

const colSpan = { base: 2, md: 1 };

export const MedicalDetails = ({ register, control, errors }) => {
  return (
    <>
      <Box borderBottom="1px solid #F2F2F2" pb="0.8rem" className="header__box">
        <Text fontSize="2.4rem" color="#807F7F" mt="3.4rem">
          Medical Details
        </Text>
      </Box>
      <SimpleGrid columns={2} marginTop="4rem" gap="6">
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="bloodGroup"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Blood Group"
                border="1px solid #E0E0E0"
                placeholder="Choose..."
                options={bloodGroup}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="bloodType"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Genotype"
                border="1px solid #E0E0E0"
                placeholder="Choose..."
                options={bloodGenotype}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('notableAilment')}
            fontSize="1.6rem"
            placeholder={'State Ailment'}
            label="Notable Ailment"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="isDisabled"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Disability"
                border="1px solid #E0E0E0"
                placeholder="Choose..."
                options={twoAnswers}
                isRequired
              />
            )}
          />
          {inputError(errors, 'isDisabled')}
        </GridItem>
        <GridItem colSpan={2}>
          <BaseInput
            {...register('disabilityType')}
            fontSize="1.6rem"
            placeholder={'State Disablity'}
            label="If Yes (Disability) Mention it"
          />
        </GridItem>
      </SimpleGrid>
    </>
  );
};
