import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useCountriesStatesCities } from 'hooks';

import { BaseDropdown, BaseInput, CustomButton } from 'components';

import { HeadingText } from '../onboard-components/Heading';

export const Emergency = ({ register, control, setNextTab }) => {
  const { setValue } = useForm();
  const watchEmergencyCountry = useWatch({ control, name: `emergencyContact.country` });
  const watchEmergencyState = useWatch({ control, name: `emergencyContact.state` });
  const watchEmergencyCity = useWatch({ control, name: `emergencyContact.city` });

  const {
    states: emergencyStates,
    countries: emergencyCountries,
    cities: emergencyCities,
  } = useCountriesStatesCities({
    country: watchEmergencyCountry,
    state: watchEmergencyState,
    city: watchEmergencyCity,
    setValue,
    stateName: 'emergencyContact.state',
    cityName: 'emergencyContact.city',
  });

  return (
    <EmergencyRoot>
      <Grid className="emergency-grid-wrap">
        <GridItem>
          <Box>
            <HeadingText headingText="Next of Kin Contact" mb="10px" />
          </Box>
        </GridItem>
        <GridItem>
          <EmercencyFormRoot>
            <Grid className="emergency-form-grid-wrap">
              <GridItem>
                <BaseInput
                  {...register('emergencyContact.fullName')}
                  label="Full Name"
                  placeholder="Enter Contact's Full Name"
                  isRequired
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('emergencyContact.phoneNumber')}
                  h="4.8rem"
                  type="number"
                  fontSize="1.6rem"
                  backgroundColor="#F7F9FB"
                  label="Phone Number"
                  placeholder="Enter Contact's Phone"
                  isRequired
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('emergencyContact.relationship')}
                  label="Relationship"
                  placeholder="Enter Contact's Relationship"
                  isRequired
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('emergencyContact.address')}
                  label="Contact Address"
                  placeholder="Enter Contact's Address"
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  control={control}
                  name="emergencyContact.country"
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose Contact's Country"
                      options={emergencyCountries}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Country"
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  control={control}
                  name="emergencyContact.state"
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose Contact's State"
                      options={emergencyStates}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="State"
                    />
                  )}
                />
              </GridItem>
              <GridItem mb="2.4rem">
                <Controller
                  defaultValue=""
                  control={control}
                  name="emergencyContact.city"
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose Contact's City"
                      options={emergencyCities}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="City"
                    />
                  )}
                />
              </GridItem>
            </Grid>
            <CustomButton
              name="Confirm & Proceed"
              variant="primary"
              mW="131px"
              onClick={setNextTab}
              type="button"
            />
          </EmercencyFormRoot>
        </GridItem>
      </Grid>
    </EmergencyRoot>
  );
};

const EmergencyRoot = styled.div`
  padding: 40px 0 148px 16px;
  .emergency-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 1200px) {
      gap: 105px;
      grid-template-columns: 218px 500px;
    }
  }
`;

const EmercencyFormRoot = styled.div`
  position: relative;

  .emergency-form-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
    @media (min-width: 1200px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
  }

  .inputType {
    border: none;
  }
  .css-1iyp8s0 {
    position: absolute;
    right: 0;
    margin-top: 48px;
    padding: 12px 48px;
  }

  .css-4302v8 {
    margin-top: 8px;
  }

  .css-b62m3t-container {
    margin-top: 8px;
  }

  .css-18v3ng0-control {
    border: none;
  }
`;
