import React from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatDate } from 'pages/admin/announcements/helpers';

import { BaseInput, NameWithImage, Pagination, Search, TableComponent } from 'components';

import calendar from '../../../../assets/icons/Calendar copy.svg';

import { columns } from './data';
import { TimeLabel } from './TimeLabel';

export function TimesheetTable({
  page,
  setPage,
  setDate,
  date,
  setQuery,
  query,
  openModal,
  setSearchString,
  pageCount,
  setEmployee,
  loading,
  tData = [],
}) {
  const performAction = (data) => {
    switch (data.action) {
      case 'view':
        setEmployee(data.email);
        openModal();
        break;

      default:
        break;
    }
  };

  const tableData = tData?.map(({ employee, time, in: timeIn, out: timeOut, createdAt }) => {
    return {
      id: employee._id,
      employeeName: (
        <NameWithImage
          data={{
            firstName: employee?.firstName,
            lastName: employee?.lastName,
            imageUrl: employee?.imageUrl,
          }}
        />
      ),
      employeeId: employee?.staffId,
      date: formatDate(createdAt),
      time,
      in: <TimeLabel time={timeIn} variant={'in'} />,
      out: <TimeLabel time={timeOut} variant={'out'} />,
    };
  });

  return (
    <Root>
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        className="filter"
        overflow={'auto'}>
        <Search
          setSearchString={setSearchString}
          placeholder={'Search by name'}
          value={query}
          setPage={setPage}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Flex gap={'16px'}>
          <Flex gap={'8px'} alignItems={'center'} className="date__filter">
            <Text>Start</Text>
            <BaseInput
              data-testid={'date-from'}
              type={'date'}
              rightIcon={calendar}
              maxWidth={'160px'}
              value={date.start}
              onChange={(e) =>
                setDate((prev) => {
                  return { ...prev, start: e.target.value };
                })
              }
            />
          </Flex>

          <Flex gap={'8px'} alignItems={'center'} className="date__filter">
            <Text>End</Text>
            <BaseInput
              type={'date'}
              data-testid={'date-to'}
              rightIcon={calendar}
              maxWidth={'160px'}
              value={date.end}
              onChange={(e) =>
                setDate((prev) => {
                  return { ...prev, end: e.target.value };
                })
              }
            />
          </Flex>
        </Flex>
      </Flex>

      <Stack overflow={'auto'} gap={'16px'}>
        <>
          <Stack overflow={'auto'}>
            <TableComponent
              columns={columns}
              noSort
              include="Status"
              noCheckbox
              loading={loading}
              data={tableData}
              dropdownActions={{ view: 'View' }}
              actionOnData={performAction}
              empty={tData?.length === 0}
              emails={tData?.map((item) => item.employee)}
              ids={tData?.map((item) => item._id)}
            />
          </Stack>

          {tableData.length > 0 && (
            <Pagination page={page} setPage={setPage} totalPages={pageCount} />
          )}
        </>
      </Stack>
    </Root>
  );
}

const Root = styled(Stack)`
  gap: 24px;
`;
