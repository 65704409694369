import { gql } from '@apollo/client';

export const EDIT_EMPLOYEE = gql`
  mutation editUser($id: ObjectID!, $data: EditUserInput!) {
    editUser(id: $id, data: $data) {
      status
      message
      data {
        _id
        firstName
        status
      }
    }
  }
`;
