import React, { useEffect, useState } from 'react';
import { RiAsterisk } from 'react-icons/ri';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseDropdown, CustomInput, Search } from 'components/shared';

// import calendar from '../../../../assets/icons/Calendar.svg';
import hidePwdImg from '../../../../assets/icons/hide-password.svg';
import showPwdImg from '../../../../assets/icons/show-password.svg';

const Input = styled.div`
  &.with-label {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
    width: 100%;

    .input-wrapper {
      width: 100%;
    }
    .dropDown {
      width: 100%;
    }
  }

  .circle {
    width: 20px;
  }

  label {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    color: #333758;

    .required {
      color: #fa4a4a;
    }
    .instructions {
      color: #83889d;
    }
  }

  .input-wrapper {
    position: relative;

    .password-btn {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);

      width: 20px;
    }
  }

  .input,
  textarea {
    width: 100%;
    background: #f7f9fb;
    border-radius: 3px;
    padding: 12px 16px;
    outline: none;
    border: 1px solid #f2f2f2;

    ::placeholder {
      color: rgba(51, 55, 88, 0.3);
    }
  }

  textarea {
    min-height: 20rem;
  }
`;

const InputBox = (props) => {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [dropdown, setDropdown] = useState();

  const changeKeys = (dropdown) => {
    if (dropdown) {
      let newData = dropdown.map((key) => {
        if (Object.keys(key).includes('_id') && Object.keys(key).includes('name')) {
          return {
            label: key.name,
            value: key._id,
          };
        }
        if (
          Object.keys(key).includes('_id') &&
          !Object.keys(key).includes('name') &&
          !Object.keys(key).includes('firstName')
        ) {
          return {
            label: key._id,
            value: key?._id,
          };
        }
        if (Object.keys(key).includes('_id') && Object.keys(key).includes('firstName')) {
          return {
            label: key.firstName + ' ' + key.lastName,
            value: { id: key?._id, email: key?.email },
          };
        } else {
          return key;
        }
      });
      setDropdown(newData);
    }
  };

  useEffect(() => {
    changeKeys(props.dropdown);
  }, [props.dropdown]);

  return (
    <Input style={props.style} className={`${props.label ? 'with-label' : ''}`}>
      <label className="label" htmlFor={props.id} style={props.labelStyle}>
        {props.label}{' '}
        {props.instructions && <span className="instructions"> {props.instructions}</span>}
        <span className="required">
          {' '}
          {props.required && <RiAsterisk fontSize={'6px'} color="#fa4a4a" />}
        </span>
      </label>
      <Flex gap={'24px'}>
        {props.type === undefined && (
          <input
            className="input"
            style={props.inputStyle}
            data-testid={props.dataTestId}
            name={props.id}
            placeholder={props.placeholder}
            id={props.id}
            minLength="5"
            onChange={props.handleChange}
            value={props.value}
            required={props.required}
          />
        )}
        {props.type === 'email' && (
          <Flex flexDirection={'column'} width={'100%'}>
            <input
              className="input"
              style={props.inputStyle}
              data-testid={props.dataTestId}
              name={props.id}
              placeholder={props.placeholder}
              id={props.id}
              type={props.type}
              onChange={props.handleChange}
              value={props.value}
              required={props.required}
            />
            {props.error && (
              <Text fontSize={'10px'} color={'red'}>
                Email is invalid! Try format abcd@email.com
              </Text>
            )}
          </Flex>
        )}
        {props.type === 'number' && (
          <input
            className="input"
            style={props.inputStyle}
            data-testid={props.dataTestId}
            name={props.id}
            placeholder={props.placeholder}
            type={props.type}
            id={props.id}
            min="0"
            max="999"
            onChange={props.handleChange}
            value={props.value}
            required={props.required}
          />
        )}
        {props.type === 'password' && (
          <Flex flexDirection={'column'} width={'100%'}>
            <div className="input-wrapper">
              <input
                className="input"
                data-testid={props.dataTestId}
                style={props.inputStyle}
                name={props.id}
                placeholder="Enter Password"
                id={props.id}
                type={isRevealPwd ? 'text' : 'password'}
                onChange={props.handleChange}
                value={props.value}
                required={props.required}
              />
              <button
                className="password-btn"
                data-testid="tooglePassword"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}>
                <img src={isRevealPwd ? hidePwdImg : showPwdImg} alt="" />
              </button>
            </div>
            {props.error && (
              <Text fontSize={'10px'} color={'red'}>
                Password length should be at least 7 characters
              </Text>
            )}
          </Flex>
        )}
        {props.type === 'textarea' && (
          <textarea
            className="input"
            data-testid={props.dataTestId}
            style={props.inputStyle}
            name={props.id}
            placeholder={props.placeholder}
            id={props.id}
            onChange={props.handleChange}
            value={props.value}
            required={props.required}
          />
        )}
        {props.type === 'search' && (
          <Search
            style={{
              width: '100%',
            }}
            setSearchString={props.setSearchString}
            onChange={props.handleChange}
            placeholder={props.placeholder}
            value={props.value}
          />
        )}
        {props.type === 'dropdown' && (
          <BaseDropdown
            border={'1px solid #F2F2F2'}
            w={'100%'}
            name={props.id}
            dataTestId={props.dataTestId}
            style={props.dropdownStyle}
            options={dropdown}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            value={props.value}
          />
        )}
        {props.type === 'date' && (
          <CustomInput
            type={'date'}
            color={'rgba(0,0,0,0.5)'}
            name={props.id}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            // rightIcon={calendar}
            value={props.value}
            data-testid={props.dataTestId}
          />
        )}
      </Flex>
    </Input>
  );
};

export { InputBox };
