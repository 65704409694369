import React from 'react';
import styled from '@emotion/styled';

import { SpinnerComponent } from 'components';

export const Summary = ({ summary, noHeading, loading }) => {
  return (
    <Root data-testid="summary" className="leave-summary">
      {noHeading ? null : <h2 className="custom-text">Summary</h2>}

      {loading ? (
        <SpinnerComponent h="20vh" />
      ) : (
        <>
          {Object.entries(summary).map(([key, value]) => {
            return (
              <div key={key}>
                <p className="title">{key}</p>
                <p className="custom-text, subtitle">{value}</p>
              </div>
            );
          })}
        </>
      )}
    </Root>
  );
};

const Root = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: grid;
  grid-gap: 2.4rem;
  padding: 2.4rem;

  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #83889d;
  }

  .subtitle:first-letter {
    text-transform: capitalize;
  }
`;
