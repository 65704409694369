import { Box, Grid, Heading } from '@chakra-ui/react';

import {
  DepartmentInput,
  ExpectedSalary,
  ExperienceInput,
  JobAddHeaderImage,
  JobDeadline,
  JobDescriptionInput,
  JobLocationInput,
  JobTitleInput,
  JobTypeInput,
  LocationInputs,
  SkillsetInput,
  SpanDouble,
  SpanSingle,
} from '../../../../components';

import { JobAssessmentSwitch } from './JobAssessmentSwitch';

function AddJobDetails() {
  const spanSingle = { colSpan: { base: 1 } };
  return (
    <Box w="100%" maxW="74.6rem" px={{ base: '2.4rem', lg: '3.2rem' }} pb="4rem">
      <Heading fontSize="2rem" lineHeight="2.3rem" mt="2.4rem" mb="3.2rem">
        Add job details
      </Heading>
      <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} autoRows="max-content" gap="2.4rem">
        <SpanDouble md>
          <JobAddHeaderImage />
        </SpanDouble>
        <SpanDouble md>
          <JobTitleInput />
        </SpanDouble>
        <SpanDouble md>
          <JobDescriptionInput />
        </SpanDouble>
        <SpanSingle>
          <DepartmentInput />
        </SpanSingle>
        <SpanSingle>
          <JobTypeInput />
        </SpanSingle>
        <SpanSingle>
          <ExperienceInput />
        </SpanSingle>
        <SpanSingle>
          <SkillsetInput />
        </SpanSingle>
        <SpanSingle>
          <ExpectedSalary />
        </SpanSingle>
        <SpanSingle>
          <JobLocationInput />
        </SpanSingle>
        <SpanSingle>
          <JobDeadline />
        </SpanSingle>
        <LocationInputs spanSingle={spanSingle} />
        <SpanSingle>
          <JobAssessmentSwitch />
        </SpanSingle>
      </Grid>
    </Box>
  );
}

export default AddJobDetails;
