import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { EDIT_REVIEW_TEMPLATE, FETCH_SINGLE_TEMPLATE } from 'query';

function useEditTemplate() {
  const questions = [
    {
      title: 'section 1',
      instruction: 'Rate team member understanding SOLID principle and how to apply them?',
      questions: [
        {
          questionType: 'multipleChoice',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'radio',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'linearScale',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'rating',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],

          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'rating',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'radio',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
      ],
    },
    {
      title: 'section 2',
      instruction: 'Rate team member understanding SOLID principle and how to apply them?',
      questions: [
        {
          questionType: 'multipleChoice',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'radio',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'linearScale',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'rating',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],

          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'rating',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
        {
          questionType: 'radio',
          options: [
            { choice: '1', value: 1 },
            { choice: '2', value: 2 },
            { choice: '3', value: 3 },
            { choice: '4', value: 4 },
            { choice: '5', value: 5 },
          ],
          question: 'Rate team member understanding SOLID principle and how to apply them?',
        },
      ],
    },
  ];

  const { id } = useParams();
  const { successToast, errorToast } = useToastify();
  const isTesting = process.env.NODE_ENV === 'test';

  const navigate = useNavigate();
  const initialState = { title: '', instruction: '', questions: [] };
  const [selected, setSelected] = useState('');
  const [sectionData, setSectionData] = useState(initialState);

  const {
    data: fetchTemplate,
    refetch,
    loading: templateLoading,
  } = useQuery(FETCH_SINGLE_TEMPLATE, {
    variables: {
      templateId: id,
    },
  });
  const templateData = fetchTemplate?.fetchSingleTemplate?.data;

  const [state, setState] = useState(templateData ? templateData?.questions : []);

  const [openEdit, setOpenEdit] = useState(false);
  const [editReviewTemplate, { loading: editReviewLoading }] = useMutation(EDIT_REVIEW_TEMPLATE);

  const clearSection = () => {
    setOpenEdit(false);
    setSelected('');
    setSectionData(initialState);
  };

  const fetchSection = (value) => {
    setSelected(value);
    state?.map((section) => {
      if (section?.title?.toLowerCase() === value?.toLowerCase()) {
        setSectionData(section);
      }
    });
  };

  const handleSectionActions = (action, value, id) => {
    if (action === 'edit') {
      if (openEdit) {
        setOpenEdit(false);
      }
      if (selected === '') {
        fetchSection(value);
      } else {
        onEdit(selected, id);
        fetchSection(value);
      }
    }

    if (action === 'delete') {
      const newArray = state?.filter(
        (section) => section?.title?.toLowerCase() !== value?.toLowerCase()
      );
      setState(newArray);
      successToast('Section deleted sucessfully');
      clearSection();
    }

    if (action === 'cancel') {
      clearSection();
    }
  };

  const onSaveSection = (value) => {
    const foundDuplicates = state.some(
      (el) => el?.title?.toLowerCase() === value?.title?.toLowerCase()
    );
    if (foundDuplicates) {
      errorToast('Duplicate name');
    } else {
      successToast('Section saved successfully');
      setState((prev) => [...prev, { ...value }]);
      clearSection();
    }
  };

  const onEdit = (value, id) => {
    const foundDuplicates = state?.some(
      (el, index) => el?.title?.toLowerCase() === value?.title?.toLowerCase() && index !== id
    );

    if (foundDuplicates) {
      errorToast('Duplicate Name!');
    } else {
      const newArray = state?.map((section, index) => {
        if (index === id) {
          successToast(`${value?.title} edited successfully`);
          return sectionData;
        } else {
          return section;
        }
      });
      setState(newArray);
      clearSection();
    }
  };

  const onSaveTemplate = async () => {
    clearSection();
    try {
      const res = await editReviewTemplate({
        variables: {
          data: { questions: state, team: templateData.team },

          filters: {
            _id: id,
          },
        },
      });
      const { data } = res;
      if (data && data.editReviewTemplate.status === 200) {
        successToast('Template edited sucessfully');
        refetch();
      } else errorToast(data.editReviewTemplate.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  function goBack() {
    navigate(-1);
  }

  const validateQuestions = sectionData?.questions?.every(
    (question) => question?.question && question?.options?.length > 0
  );

  const validateSection = !(
    sectionData?.title &&
    sectionData?.instruction &&
    sectionData?.questions?.length > 0 &&
    validateQuestions
  );

  const validateTemplate = !(state?.length > 0 && !selected);

  useEffect(() => {
    setState(isTesting ? questions : templateData?.questions);
  }, [templateData?.questions]);

  return {
    goBack,
    handleSectionActions,
    state,
    setSectionData,
    sectionData,
    selected,
    setSelected,
    setState,
    onSaveSection,
    templateData,
    onSaveTemplate,
    isTesting,
    validateSection,
    validateTemplate,
    templateLoading,
    editReviewLoading,
    validateQuestions,
    onEdit,
    setOpenEdit,
    openEdit,
  };
}

export { useEditTemplate };
