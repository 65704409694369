import React from 'react';
import { useForm } from 'react-hook-form';
import { TiPlus } from 'react-icons/ti';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import EmptyStateIcon from 'assets/icons/empty-state-folders.svg';
import PrivateHardDrive from 'assets/icons/hard-drive-blue.svg';
import SharedHardDrive from 'assets/icons/hard-drive-orange.svg';
import { useToastify } from 'hooks/useToastify';
import { FETCH_ALL_DEPARTMENTS } from 'query';
import { DELETE_FOLDER, EDIT_FOLDER, SHARE_FOLDER } from 'query/admin/document/document';
import { formatDate } from 'utils';

import {
  BaseButton,
  BaseInput,
  CustomButton,
  DeletePromptModal,
  EventsActionContainer,
  EventsPopupAction,
  ModalBackButton,
  SimpleModal,
  TableComponent,
} from 'components';

import { UserImage } from '../employee/tableConfig';

import { DocumentTableColumns } from './documentTableData';
import { formatText } from './helper';
import { ShareFolderModal } from './ShareFolderModal';
import { DocumentName } from './ViewFolders';

export const PrivateDocument = ({
  folderType,
  header,
  tabType,
  addFolder,
  isClicked,
  setPath,
  setFolderId,
  // folderId,
  folderName,
  tab,
  refetch,
  privateRootRefetch,
  sharedRootRefetch,
}) => {
  const [editModal, setEditModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [rightClicked, setRightClicked] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [optionType, setOptionType] = React.useState('');
  const [singleObject, setSingleObject] = React.useState({});
  const [mousePoints, setMousePoints] = React.useState({ x: 0, y: 0 });
  const [selectedFolderName, setSelectedFolderName] = React.useState('');
  const [selectedName, setSelectedName] = React.useState(selectedFolderName);
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const { errorToast, successToast } = useToastify();
  const { watch, register, handleSubmit, reset } = useForm();

  const [editFolder, { loading }] = useMutation(EDIT_FOLDER, {});

  const [deleteFolder, { loading: deleteLoading }] = useMutation(DELETE_FOLDER, {});

  const [shareFolder, { loading: shareLoading }] = useMutation(SHARE_FOLDER, {});

  const { data: allDepartments } = useQuery(FETCH_ALL_DEPARTMENTS);

  function toggleEditModal() {
    setEditModal((prev) => !prev);
  }

  const onDeleteFolder = async () => {
    try {
      const response = await deleteFolder({ variables: { _id: id } });
      if (response?.data?.deleteFolder?.status === 200) {
        successToast('Folder deleted sucessfully');
        setShow(false);
        refetch();
        privateRootRefetch();
        sharedRootRefetch();
      } else errorToast(response?.data?.deleteFolder?.message);
    } catch (error) {
      const message = error?.response?.data?.message || 'Error Occured';
      errorToast(message);
    }
  };

  const actionOnData = ({ action, id, name }) => {
    switch (action) {
      case 'view':
        setPath('active');
        setFolderId(id);
        break;
      case 'edit':
        toggleEditModal();
        setSelectedFolderName(name);
        setSelectedName(name);
        setSelectedId(id);
        break;
      case 'delete':
        // onDeleteFolder(id);
        setShow(true);
        setId(id);
        break;
      case 'share':
        setOpenShareModal(true);
        setId(id);
        break;

      default:
        break;
    }
  };

  const dropdownActions = {
    view: 'View',
    share: 'Share',
    edit: 'Edit',
    deleteDocument: 'Delete',
  };

  function handleDoubleclick({ id }) {
    setPath('active');
    setFolderId(id);
  }

  function handleRightClick({ id, name, url, type }) {
    // e.preventDefault();
    setRightClicked(true);
    setSingleObject({ id, type, name, url });
    // setMousePoints({ x: event.pageX, y: event.pageY });
  }

  const newdocumentTableData = folderType?.map(({ _id, name, createdBy, createdAt }) => {
    return {
      col1:
        (
          <DocumentName
            name={name}
            tab={tab}
            id={_id}
            handleDoubleclick={handleDoubleclick}
            handleRightClick={handleRightClick}
            setMousePoints={setMousePoints}
          />
        ) || 'N/A',
      col2: UserImage({
        imageUrl: createdBy?.imageUrl,
        name: `${createdBy?.firstName + ' ' + createdBy?.lastName}`,
        firstName: createdBy?.firstName,
        lastName: createdBy?.lastName,
      }),
      col3: '--',
      col4: formatDate(createdAt) || 'N/A',
      col5: (
        <EventsActionContainer>
          <EventsPopupAction
            id={_id}
            name={name}
            actionOnData={actionOnData}
            dropdownActions={dropdownActions}></EventsPopupAction>
        </EventsActionContainer>
      ),
    };
  });

  const onEditFolder = async (e) => {
    e.preventDefault();
    const dataToSend = {
      _id: selectedId,
      name: selectedName,
    };
    try {
      const response = await editFolder({ variables: { data: dataToSend } });
      if (response?.data?.editFolder?.status === 200) {
        successToast('Folder name updated sucessfully');
        setSelectedName('');
        toggleEditModal();
        refetch();
        privateRootRefetch();
        sharedRootRefetch();
      } else errorToast(response?.data?.editFolder?.message);
    } catch (error) {
      const message = error?.response?.data?.message || 'Error Occured';
      errorToast(message);
    }
  };

  function handleSelectedName(val) {
    setSelectedName(val);
  }

  function handleCheckBoxChange(id, name) {
    setChecked((prev) =>
      checked.some((e) => e.id === id)
        ? prev.filter((cur) => cur?.id !== id)
        : [...prev, { id, name }]
    );
  }

  const checkedBool = allDepartments?.fetchCompanyDepartments?.data?.map((emp) => {
    return checked.some((e) => e?.id === emp?._id);
  });

  React.useEffect(() => {
    watch('sharedRight') === 'departments'
      ? setOptionType('Departments')
      : setOptionType('Everyone');
  });

  async function onShareFolder(data) {
    const selectedDepartments = checked.map((e) => {
      return e?.id;
    });
    const dataToSend =
      data?.sharedRight === 'departments'
        ? { _id: id, sharedRight: data?.sharedRight, sharedWith: selectedDepartments }
        : { _id: id, sharedRight: data?.sharedRight, sharedWith: [] };

    try {
      const response = await shareFolder({ variables: { data: dataToSend } });
      response?.data?.shareFolder?.status === 200
        ? successToast('Folder shared successfully')
        : errorToast(response?.data?.shareFolder?.message);
      reset();
      setOpenShareModal(false);
      refetch();
      privateRootRefetch();
      sharedRootRefetch();
    } catch (error) {
      errorToast('Error occurred');
    }
  }

  return (
    <>
      <Wrapper onClick={() => setRightClicked(false)}>
        {rightClicked && (
          <ContextMenu top={mousePoints.y} left={mousePoints.x}>
            {Object.entries(dropdownActions).map(([key, action]) => {
              const actionText = formatText(action);
              return (
                <div
                  key={key}
                  className={action}
                  data-testid={`${action}`}
                  onClick={() => actionOnData({ ...singleObject, action: actionText })}>
                  {action}
                </div>
              );
            })}
          </ContextMenu>
        )}
        <div className="tab-section-header">
          {isClicked ? (
            <div className="single-folder">
              <ModalBackButton
                onClick={() => {
                  setPath('');
                }}
              />
              <h1>{folderName}</h1>
            </div>
          ) : (
            <div className="header-title">
              {tabType === 'private' ? (
                <img src={PrivateHardDrive} className="drive-icon" />
              ) : (
                <img src={SharedHardDrive} className="drive-icon" />
              )}

              <h1>{header}</h1>
            </div>
          )}
          <div>
            <BaseButton variant="outline-blue" onClick={addFolder} data-testid="addFolderBtn">
              <TiPlus />
              Create Folder
            </BaseButton>
          </div>
        </div>
        <hr />
        {folderType?.length < 1 ? (
          <Box className="empty-state">
            <img src={EmptyStateIcon} alt="" />
            <h3 className="empty-state-header">No Folders and Files</h3>
            <p className="empty-state-sub-header">
              Keep your colleague up to date by uploading new company documents here.
            </p>
          </Box>
        ) : (
          <Box>
            <TableComponent
              minW={'750px'}
              columns={DocumentTableColumns}
              data={newdocumentTableData}
              noSort
              noCheckbox
              noAction
            />
          </Box>
        )}
      </Wrapper>
      <SimpleModal showModal={editModal} width={'749px'}>
        <FormWrapper>
          <h1>{`Edit ${selectedFolderName} folder name`}</h1>
          <form onSubmit={onEditFolder}>
            <Stack border={'1px solid #F2F2F2'} borderRadius="4px" mt={'24px'} padding="24px">
              <BaseInput
                label="Folder Name"
                placeholder="Folder name"
                isRequired
                value={selectedName}
                onChange={(e) => handleSelectedName(e.target.value)}
              />
            </Stack>
            <Flex mt={'24px'} justifyContent={'flex-end'} gap={'0.8rem'}>
              <CustomButton
                variant="black--outline"
                name={'Cancel'}
                mW={'9.4rem'}
                h={'4rem'}
                onClick={toggleEditModal}
              />
              <CustomButton
                variant="black"
                name={'Save'}
                mW={'9.4rem'}
                h={'4rem'}
                loading={loading}
                type="submit"
                testId={'saveBtn'}
              />
            </Flex>
          </form>
        </FormWrapper>
      </SimpleModal>
      <ShareFolderModal
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        checked={checkedBool}
        checkedData={checked}
        handleCheckBoxChange={handleCheckBoxChange}
        departmentData={allDepartments?.fetchCompanyDepartments?.data}
        id={allDepartments?.fetchCompanyDepartments?.data.map((e) => e?._id)}
        name={allDepartments?.fetchCompanyDepartments?.data.map((e) => e?.name)}
        optionType={optionType}
        register={register}
        handleSubmit={handleSubmit(onShareFolder)}
        loading={shareLoading}
      />
      <DeletePromptModal
        show={show}
        cancel={() => setShow(false)}
        deleteItem={() => {
          onDeleteFolder();
        }}
        loading={deleteLoading}
      />
    </>
  );
};

const Wrapper = styled.div`
  max-width: 100%;
  height: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 8px;
  background: var(--white);
  thead th {
    background: #f3f4f6;
  }

  .document-icon {
    width: 24px;
    aspect-ratio: 1;
  }

  .single-folder {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  hr {
    padding-bottom: 40px;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #23273b;
  }

  .header-title {
    display: flex;
    align-items: center;
    gap: 13px;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #23273b;
    }
  }

  .modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 1010px) {
      flex-direction: row;
    }
  }
  .drive-icon {
    width: 40px;
    height: 40px;
  }

  .tab-section-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 8px;
    -webkit-align-items: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 32px 24px;

    @media (min-width: 1200px) {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding: 32px 24px;
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 104px;
      aspect-ratio: 1;
    }
  }

  .empty-state-header {
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #23273b;
    margin-top: 24px;
  }
  .empty-state-sub-header {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #83889d;
    margin-top: 8px;
  }
`;

const FormWrapper = styled(Box)`
  padding: 24px;
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.117188px;
    color: #23273b;
  }

  form {
    padding: 0;
  }
`;

const ContextMenu = styled.div`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  width: 138px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}

  div {
    padding: 5px;
  }

  div:hover {
    background: #e2e8f0;
    border-radius: 4px;
    padding: 5px;
  }
`;
