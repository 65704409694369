import { gql } from '@apollo/client';

export const FETCH_EMPLOYEE_ANNOUNCEMENTS = gql`
  query fetchEmployeeAnnouncements($page: Int!) {
    fetchEmployeeAnnouncements(page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        type
        title
        content
        createdAt
        issuedBy {
          _id
          imageUrl
          firstName
          lastName
        }
        isImportant
        status
      }
    }
  }
`;
