export const LineManagerTableColumns = [
  {
    Header: 'Manager Name',
    accessor: 'col1',
    sortable: true,
  },
  {
    Header: 'Contact',
    accessor: 'col2',
  },
  {
    Header: 'Department',
    accessor: 'col3',
    sortable: true,
  },
  {
    Header: 'Date Hired',
    accessor: 'col4',
    sortable: true,
  },
  {
    Header: 'Status',
    accessor: 'col5',
    sortable: true,
  },
  {
    Header: '',
    accessor: 'col6',
  },
];
