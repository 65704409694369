import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Box, Flex, FormLabel, Heading, Image, Stack, Text, Textarea } from '@chakra-ui/react';

import { CustomButton } from 'components';

import closeIcon from '../../../../../../assets/icons/close.svg';
import { useToastify } from '../../../../../../hooks/useToastify';
import { SEND_BIRTHDAY_WISH } from '../../../query';
import { MessageWrapper } from '../../styles';

const isTesting = process.env.NODE_ENV === 'test';
export function SendBirthdayWish({ closeModal, name, userId }) {
  const [SendBirthdayWish, { loading }] = useMutation(SEND_BIRTHDAY_WISH);

  let defaultMesage = '';

  if (isTesting) {
    defaultMesage = 'Happy birthday bossu';
  }
  const { register, handleSubmit } = useForm({
    defaultValues: { response: defaultMesage },
  });

  const { successToast } = useToastify();

  async function onSubmit(data) {
    const res = await SendBirthdayWish({
      variables: {
        data: {
          message: data.response,
          userId: userId,
        },
      },
    });

    if (res.data.sendBirthdayWish.status === 200) {
      successToast(res.data.sendBirthdayWish.message);
      closeModal();
    }
  }
  return (
    <MessageWrapper>
      <Flex justifyContent={'space-between'}>
        <Heading fontSize={'2.4rem'}>Send Wishes</Heading>
        <Image src={closeIcon} onClick={closeModal} cursor={'pointer'} data-testid={'closeModal'} />
      </Flex>

      <Stack gap={'3.2rem'}>
        <Text fontWeight={'500'}>Special Wishes to {name}</Text>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box gap={'10px'}>
            <FormLabel
              htmlFor="response"
              color={'var(--grey-500)'}
              fontSize={'14px'}
              fontWeight={'500s'}>
              Message
            </FormLabel>
            <Textarea
              backgroundColor={'var(--grey-100)'}
              id="response"
              {...register('response')}
              fontSize={'14px'}
              placeholder={
                'Wishing you a beautiful day with happiness and good health forever! Happy Birthday!'
              }
              rows={'5'}
            />
          </Box>

          <Flex justifyContent={'flex-end'} marginTop={'3.2rem'}>
            <CustomButton
              name={'Send'}
              h={'4.8rem'}
              type={'submit'}
              mW={'11.8rem'}
              loading={loading}
            />
          </Flex>
        </form>
      </Stack>
    </MessageWrapper>
  );
}
