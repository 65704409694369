import React from 'react';

function useOnboardingHook() {
  const [onboardBulk, setOnboardBulk] = React.useState(false);
  const [onboardSingle, setOnboardSingle] = React.useState(false);
  const [countryCode, setCountry] = React.useState({});
  const [kincountryCode, setKinCountry] = React.useState({});

  const [phone, setPhone] = React.useState('');
  const [currencyCode, setCurrency] = React.useState({});

  const [formHeading, setFormHeading] = React.useState('Personal data');
  const [page, setPage] = React.useState(1);

  const handleOnboardBulk = () => {
    setOnboardBulk(true);
  };
  const handleOnboardSingle = () => {
    setOnboardSingle(true);
  };

  const getCountryCode = (e) => {
    setCountry(e);
  };
  const getKinCountryCode = (e) => {
    setKinCountry(e);
  };

  const getCurrencyCode = (e) => {
    setCurrency(e);
  };

  const getPhoneNumber = (e) => {
    const { value } = e.target;
    setPhone(value);
  };

  const previousBtnAction = () => {
    switch (page) {
      case 2:
        setFormHeading('Personal Information');
        setPage(1);
        break;
      case 3:
        setFormHeading('Employment Information');
        setPage(2);
        break;
      case 4:
        setFormHeading('Pension account information');
        setPage(3);
        break;
      default:
        setFormHeading('Personal Information');
        setPage(1);
    }
  };

  const nextBtnAction = () => {
    switch (page) {
      case 1:
        setFormHeading('Employment Information');
        setPage(2);
        break;
      case 2:
        setFormHeading('Pension account information');
        setPage(3);
        break;
      case 3:
        setFormHeading('Contact information');
        setPage(4);
        break;
      default:
        setFormHeading('Employment Information');
        setPage(2);
    }
  };

  return {
    nextBtnAction,
    onboardBulk,
    onboardSingle,
    countryCode,
    kincountryCode,
    phone,
    currencyCode,
    formHeading,
    page,
    previousBtnAction,
    handleOnboardBulk,
    handleOnboardSingle,
    getCountryCode,
    getKinCountryCode,
    getCurrencyCode,
    getPhoneNumber,
    setOnboardBulk,
    setOnboardSingle,
  };
}

export { useOnboardingHook };
