import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

// import { SpinnerLoader } from '../../../applicant';
const Line = styled.hr`
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  border: none;
  border-radius: 8px;
  // background-color: #f0a800;
  width: 100%;

  &.orange {
    background-color: #f0a800;
  }

  &.green {
    background-color: #36ca8b;
  }

  &.blue {
    background-color: #3e7eee;
  }
`;

const ColourCoder = (props) => {
  const [item, setItem] = useState('');

  const getFilteredColor = (list) => {
    const filtered = list?.filter((category) => {
      return category.department?.toLowerCase() === props.department?.toLowerCase();
    });

    const color = filtered?.map((color) => {
      return color.color;
    });

    setItem(color);
  };

  useEffect(() => {
    getFilteredColor(props.colorCoder);
  }, [props.department, props.colorCoder]);

  return item && <Line className={`${item}`} />;
};

export { ColourCoder };
