import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import empty from '../../assets/icons/emptyState.svg';

const EmptyState = ({ h, w, message }) => {
  return (
    <Flex minHeight={h || '40vh'} width={w || '100%'} justifyContent="center" alignItems={'center'}>
      <Box>
        <img src={empty} alt="" />
        <Text
          mt="1rem"
          textAlign={'center'}
          color="#999FA3"
          fontSize={'1.6rem'}
          lineHeight="1.6rem"
          fontWeight={'500'}>
          {message || 'No Data Found'}
        </Text>
      </Box>
    </Flex>
  );
};

export { EmptyState };
