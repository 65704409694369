import React from 'react';
import { CgAsterisk } from 'react-icons/cg';
import Select, { createFilter } from 'react-select';
import Async from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { Box, FormErrorMessage, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
const BaseDropdown = React.forwardRef(
  (
    {
      placeholder,
      style,
      label,
      dataTestId,
      options,
      value,
      onChange,
      border,
      name,
      w,
      h,
      fontSize,
      labelSize,
      color,
      isRequired,
      isDisabled,
      fontW,
      creatable,
      onCreateOption,
      async,
      loadOptions,
      defaultOptions,
      clearable,
      error,
      ...rest
    },
    ref
  ) => {
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: '#F7F9FB',
        width: w || '27rem',
        height: h || '4.8rem',
        border: border || 'none',
        padding: '0 0.8rem',
        fontSize: fontSize || '1.4rem',
        textTransform: 'capitalize',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }),
      option: (_styles, state) => ({
        backgroundColor: state.isSelected ? '#f4f6f8' : 'transparent',
        fontSize: '1.4rem',
        textTransform: 'capitalize',
      }),
    };

    return (
      <Box {...rest} data-testid={dataTestId} className="dropDown" style={style}>
        <Text
          color={color}
          fontSize={labelSize || '1.4rem'}
          fontWeight={fontW || '400'}
          as="label"
          mb="1.2rem"
          position="relative">
          {label}
          {isRequired && (
            <Box as="span" position="absolute" top="0" right="-1rem">
              <CgAsterisk color="var(--error)" size="10" />
            </Box>
          )}
        </Text>
        {creatable ? (
          <CreatableSelect
            {...(typeof value === 'object' ? { value } : { value: stringToOption(value, options) })}
            menuPosition="fixed"
            classNamePrefix="react-select"
            styles={colourStyles}
            aria-label="my custom select"
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            inputRef={ref}
            ref={ref}
            name={name}
            onCreateOption={onCreateOption || null}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: DropdownIndicator,
            }}
            isClearable={clearable}
            isDisabled={isDisabled}
          />
        ) : null}
        {async ? (
          <Async
            {...(typeof value === 'object' ? { value } : { value: stringToOption(value, options) })}
            menuPosition="fixed"
            classNamePrefix="react-select"
            styles={colourStyles}
            aria-label="my custom select"
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            inputRef={ref}
            ref={ref}
            name={name}
            loadOptions={loadOptions}
            defaultOptions={defaultOptions}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: DropdownIndicator,
            }}
            isClearable={clearable}
            isDisabled={isDisabled}
          />
        ) : null}
        {!creatable && !async ? (
          <Select
            {...(typeof value === 'object' ? { value } : { value: stringToOption(value, options) })}
            menuPosition="fixed"
            className="dropdown"
            classNamePrefix="react-select"
            styles={colourStyles}
            aria-label="my custom select"
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            inputRef={ref}
            ref={ref}
            name={name}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: DropdownIndicator,
            }}
            isClearable={clearable}
            isDisabled={isDisabled}
            filterOption={createFilter({
              ignoreCase: true,
              ignoreAccents: true,
              matchFrom: 'any',
              stringify: (option) =>
                `${option.label} ${option.value} ${option?.data?.dialCode} ${option?.data?.currrency}`,
              trim: true,
            })}
          />
        ) : null}
        {error && <FormErrorMessage> {error}</FormErrorMessage>}
      </Box>
    );
  }
);

const stringOrObject = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

BaseDropdown.displayName = 'BaseDropdown';
BaseDropdown.propTypes = {
  placeholder: PropTypes.string,
  label: stringOrObject,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: stringOrObject,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: stringOrObject,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    }),
  ]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  w: PropTypes.string,
  h: PropTypes.string,
  fontSize: PropTypes.string,
};

const MultiSelectCreatableDropdown = React.forwardRef(
  ({ placeholder, value, label, options, onChange, w, h, isMulti, fontSize, ...rest }, ref) => {
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: '#F7F9FB',
        width: w || '27rem',
        minHeight: h || '4.4rem',
        border: 'none',
        padding: '0 0.8rem 0 0.4rem',
        fontSize: fontSize || '1.4rem',
      }),
      option: (_styles, state) => ({
        backgroundColor: state.isSelected ? '#f4f6f8' : 'transparent',
      }),
    };

    return (
      <Box {...rest} className="dropDown">
        <Text
          fontSize="1.4rem"
          as="label"
          mb="1.2rem"
          lineHeight={'1.6rem'}
          textTransform={'capitalize'}>
          {label}
        </Text>
        <CreatableSelect
          isMulti={isMulti !== undefined ? rest.isMulti : true}
          onChange={onChange}
          placeholder={placeholder}
          inputRef={ref}
          menuPosition="fixed"
          options={options}
          value={value}
          styles={colourStyles}
          name={rest.name}
          classNamePrefix="react-select"
          {...rest}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator,
          }}
        />
      </Box>
    );
  }
);

MultiSelectCreatableDropdown.displayName = 'BaseDropdown';

function DropdownIndicator() {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '0.8rem' }}>
      <path d="M0.25 0.5L4 4.25L7.75 0.5H0.25Z" fill="#333758" />
    </svg>
  );
}

function stringToOption(value, options = []) {
  if (value) {
    const inOptions = options.find((option) => option.value === value);
    return inOptions ?? { label: value, value };
  } else {
    return value;
  }
}

export { BaseDropdown, MultiSelectCreatableDropdown };
