import { gql } from '@apollo/client';

export const UPDATE_APP_SETTINGS = gql`
  mutation updateAppSettings($data: SettingInputData!) {
    updateAppSettings(data: $data) {
      status
      message
      data {
        leaveSettings {
          leaveVisibility
          profileBalanceDisplayDate
        }
      }
    }
  }
`;

export const FETCH_APP_SETTINGS = gql`
  query fetchAppSettings {
    fetchAppSettings {
      status
      message
      data {
        leaveSettings {
          profileBalanceDisplayDate
          leaveVisibility
        }
      }
    }
  }
`;
