import axios from 'axios';

import { setItem } from '../../utils';

import { postRequest } from './httpRequest';

const url = process.env.REACT_APP_HRM_V3_API_BASE_URL;

const applicantSignUp = async (body) => {
  const { data } = await postRequest(`${url}/applicant/signup`, body);
  return data;
};

const applicantSignIn = async (body) => {
  const { data } = await postRequest(`${url}/applicant/signin`, body);
  setItem('token', data.data.token);
  setItem('hrm_user', data.data.user);
  return data;
};

const sendApplicantEmail = async (body) => {
  const { data } = await postRequest(`${url}/applicant/forgotpassword`, body);

  return data;
};

const checkApplicantToken = async (body) => {
  const { data } = await postRequest(`${url}/applicant/checktoken`, body);

  return data;
};

const resetApplicantPassword = async (token, body) => {
  const { data } = await axios.put(`${url}/applicant/resetpassword/?token=${token}`, body);

  return data;
};

const changeApplicantPassword = async (body) => {
  const { data } = await axios.put(`${url}/applicant/changepassword`, body);

  return data;
};

export {
  applicantSignIn,
  applicantSignUp,
  changeApplicantPassword,
  checkApplicantToken,
  resetApplicantPassword,
  sendApplicantEmail,
};
