import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { GoalDetailsProvider } from 'context/GoalsDetailsForm';
import { useToastify } from 'hooks/useToastify';

import { Button, FullModal, ModalBackButton, SpinnerComponent } from 'components';

import { FlatSteps } from '../../reviews/CreateReview/components';

import { GoalDetails } from './goal-details';
import { SelectParticipant } from './select-participants';
import { SummaryAndSend } from './summary-and-send';

export function GoalsComponent(props) {
  const { errorToast } = useToastify();
  const initialState = {
    title: '',
    description: '',
    type: '',
    milestones: [{ metricTitle: '', metricDescription: '' }],
    schedule: { startDate: '', dueDate: '' },
  };
  const [state, setState] = useState(initialState);

  const steps = [
    { name: 'Goal Details', component: GoalDetails },
    { name: 'Select Participants', component: SelectParticipant },
    { name: 'Summary', component: SummaryAndSend },
  ];
  const [activeStep, setActiveStep] = useState(() => 0);
  const ActiveComponent = steps[activeStep]['component'];

  const validateName = state?.title;
  const validateSchedule = state?.schedule?.startDate && state?.schedule?.dueDate;
  const validateGoals = state?.milestones?.every(
    (milestone) => milestone?.metricTitle && milestone?.metricDescription
  );

  const validateGoalDetails =
    validateName && state?.milestones?.length && validateGoals && validateSchedule;
  const validateSelectParticipants = state?.employees?.length > 0;

  const isDisabled = !(
    validateName &&
    validateGoalDetails &&
    validateSelectParticipants &&
    validateSchedule
  );

  const handleBackClick = () => {
    if (activeStep === 0) props.setShowModal(false);
    else setActiveStep(activeStep - 1);
  };

  const handleNextClick = () => {
    if (activeStep < steps.length - 1) {
      if (activeStep === 0 && !validateGoalDetails) {
        errorToast('Please fill out all required fields');
      } else if (activeStep === 1 && !validateSelectParticipants) {
        errorToast('Please select participants');
      } else setActiveStep(activeStep + 1);
    } else {
      props.onSave(state);
    }
  };

  useEffect(() => {
    setState(props.value || initialState);
  }, [props.value]);

  return (
    <Wrapper>
      <FullModal {...props} noHeader mt="8.3rem">
        <header className="header">
          <ModalBackButton onClick={() => props.setShowModal(false)} />
          <span className="header-text">{props.title}</span>
        </header>
        {props.pageLoading ? (
          <SpinnerComponent />
        ) : (
          <Flex flexDirection={'column'} gap={'24px'}>
            <Flex
              width={'100%'}
              position="sticky"
              zIndex="20"
              top="88px"
              bg="white"
              py="24px"
              justifyContent={'center'}>
              <FlatSteps w="816.5px" steps={steps} active={activeStep} setActive={setActiveStep} />
            </Flex>
            <Flex justifyContent={'center'} width={'100%'} className={'view-container'}>
              <GoalDetailsProvider initialValue={state}>
                <ActiveComponent setState={setState} state={state} validateGoals={validateGoals} />
              </GoalDetailsProvider>
            </Flex>
            <Flex justifyContent={'space-between'} padding={'24px 0'}>
              {activeStep < steps.length ? (
                <>
                  <Button
                    onClick={handleBackClick}
                    title={'Back'}
                    style={{
                      fontWeight: 600,
                      fontSize: '16px',
                      letterSpacing: '0.01em',
                      color: '#23273B',
                      width: '132px',
                      height: '48px',
                      border: ' 1px solid #23273B',
                      background: '#FFFFFF',
                      borderRadius: '4px',
                    }}
                    dataTestId="Back-btn"
                  />
                  <Button
                    isLoading={props.loading}
                    onClick={handleNextClick}
                    title={activeStep < steps.length - 1 ? 'Next' : 'Complete'}
                    isDisabled={activeStep < steps.length - 1 ? false : isDisabled}
                    style={{
                      fontWeight: 600,
                      fontSize: '16px',
                      letterSpacing: '0.01em',
                      color: '#FFFFFF',
                      width: '132px',
                      height: '48px',
                      background: ' #3E7EEE',
                      borderRadius: '4px',
                    }}
                    dataTestId="Next-btn"
                  />
                </>
              ) : null}
            </Flex>
          </Flex>
        )}
      </FullModal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .buttonStyle {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
  }
  .header {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
    display: grid;
    grid: 1fr / max-content max-content auto;
    gap: 2.4rem;
    align-items: center;
    background-color: var(--white);

    &-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.05em;
    }
  }

  .view-container {
    width: 100%;
    overflow-x: scroll;
  }
`;
