import React from 'react';
import { Controller, useFormState, useWatch } from 'react-hook-form';
import { GridItem } from '@chakra-ui/react';

import { useJobDetailsForm } from '../../../../context/jobDetailsForm';
import { useCountriesStatesCities } from '../../../../hooks';
import { inputError } from '../../../../utils';
import { BaseDropdown } from '../../../shared';
import { JobInputLabel } from '../JobInputLabel';

function LocationInputs({ spanSingle = {} }) {
  const { control, setValue } = useJobDetailsForm();
  const country = useWatch({ name: 'country', control });
  const state = useWatch({ name: 'state', control });
  const formState = useFormState({ control });

  const { countries, states, cities } = useCountriesStatesCities({
    country,
    state,
    setValue,
    stateName: 'state',
    cityName: 'city',
  });

  return (
    <>
      <GridItem {...spanSingle}>
        <JobInputLabel>Country</JobInputLabel>
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <BaseDropdown placeholder="Select country" w="100%" options={countries} {...field} />
          )}
        />
        {inputError(formState.errors, 'country')}
      </GridItem>
      <GridItem {...spanSingle}>
        <JobInputLabel>State</JobInputLabel>
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <BaseDropdown placeholder="Select state" w="100%" options={states} {...field} />
          )}
        />
        {inputError(formState.errors, 'state')}
      </GridItem>
      <GridItem {...spanSingle}>
        <JobInputLabel>City</JobInputLabel>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <BaseDropdown placeholder="Select city" w="100%" options={cities} {...field} />
          )}
        />
        {inputError(formState.errors, 'city')}
      </GridItem>
    </>
  );
}

export { LocationInputs };
