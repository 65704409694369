import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useEmployeeApparaisalForm } from 'context/EmployeeReviewForm';

import { CheckboxWrapper, CustomCheckbox } from 'components';

// import { TemplateTypes } from './TemplateTypes';

export const ReviewQuestions = ({ sectionQst, index }) => {
  const { register, control } = useEmployeeApparaisalForm();

  const { fields } = useFieldArray({ control, name: `questions.${index}.questions` });

  return (
    <ContentWrap>
      <div className="assessment" key={index}>
        <div className="assessment__instructions">
          <p className="title">
            <span>Instructions: </span>
            {sectionQst?.title}
          </p>
          <p>{sectionQst?.instruction}</p>
        </div>

        <>
          {fields?.map((field, i) => {
            const { fields: optionField } = useFieldArray({
              control,
              name: `questions.${index}.questions.${i}.options`,
            });
            return (
              <div key={field.id} className="assessment__instructions--row">
                <Flex alignItems={'center'} gap="1.6rem" mt={'2.4rem'}>
                  <p className="bullet">{i + 1}.</p>

                  <p className="question--item">{field?.question}</p>
                  <input
                    className="hidden-input"
                    value={field?.question}
                    {...register(`questions.${index}.questions.${i}.question`)}></input>
                </Flex>

                <div className="assessment__instructions--row-right">
                  {optionField?.map((fld, j) => {
                    return (
                      <CheckboxWrapper key={fld.id}>
                        <CustomCheckbox
                          {...register(`questions.${index}.questions.${i}.options.${j}.isAnswer`)}
                          id={`${fld?.choice}|${fld?.value}`}
                          label={fld?.choice}
                          variant={'blue-checkbox'}
                        />
                      </CheckboxWrapper>

                      // <TemplateTypes
                      //   index={i}
                      //   sectionIndex={index}
                      //   type={field?.questionType}
                      //   register={register}
                      //   options={field?.options}
                      // />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      </div>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  overflow-x: auto;
  padding-bottom: 2.4rem;
  margin-top: 3.2rem;

  .question--item {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #23273b;
    // margin-top: 2.4rem;
  }

  .assessment__instructions--row-right {
    display: grid;
    align-items: center;
    gap: 3.2rem;
    padding: 2.4rem 3.4rem;
    width: max-content;
    // border: 1px solid red;

    label {
      margin-right: 0;
    }
  }

  .assessment__instructions {
    min-width: 1100px;
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0012em;
      color: #83889d;
      margin-bottom: 2.4rem;

      span {
        color: #272932;
      }
    }
    &--row {
      display: grid;
      border-bottom: 1px solid #f2f2f2;
      padding: 0 1.6rem;

      .hidden-input {
        display: none;
      }

      .bullet {
        font-weight: 700;
        font-size: 1.4rem;
      }

      input[type='text'] {
        word-wrap: break-word;
        word-break: break-all;
        height: 80px;
      }
    }
  }

  .start-wrap {
    cursor: pointer;
  }
`;
