import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Flex, Grid, Heading, Image, Stack } from '@chakra-ui/react';

import { BaseInput, CustomButton } from 'components';

import close from '../../../../../../assets/icons/close.svg';
import { useToastify } from '../../../../../../hooks/useToastify';
import { EDIT_EMPLOYEE_DETAILS, FETCH_EMPLOYEE_DETAILS } from '../../../query';

function EditAddress({ closeModal, address, userId }) {
  const [editEmployeeDetails, { loading: saveLoading }] = useMutation(EDIT_EMPLOYEE_DETAILS, {
    refetchQueries: [
      {
        query: FETCH_EMPLOYEE_DETAILS,
        variables: {
          filters: {
            _id: userId,
          },
        },
      },
    ],
  });

  const { register, handleSubmit } = useForm({
    defaultValues: { ...address },
  });

  const { successToast, errorToast } = useToastify();

  async function onSubmitForm(data) {
    try {
      const res = await editEmployeeDetails({
        variables: {
          data: {
            address: data,
          },
        },
      });
      if (res.data.editEmployeeDetails.status === 200) {
        closeModal();
        successToast(res.data.editEmployeeDetails.message);
      }
    } catch (error) {
      errorToast(error);
    }
  }
  return (
    <Stack p={'2.4rem'}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        borderBottom={'solid 1px var(--grey-300)'}
        marginBottom={'4rem'}>
        <Heading fontSize={'2.4rem'} p={'0.8rem 0'} color={'var(--deep_blue)'}>
          Edit Address
        </Heading>
        <Image data-testid={'closeModal'} src={close} cursor={'pointer'} onClick={closeModal} />
      </Flex>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid gridTemplateColumns={'1fr 1fr'} gap={'1.6rem'} width={'660px'}>
          <BaseInput
            {...register('address')}
            label={'Street Address'}
            placeholder={'Street Address'}
          />
          <BaseInput {...register('district')} label={'City'} placeholder={'City'} />
          <BaseInput
            {...register('state')}
            label={'Provice / State'}
            placeholder={'State / Province'}
          />
          <BaseInput {...register('country')} label={'Country'} placeholder={'Nigeria'} />
        </Grid>

        <Flex justifyContent={'center'} marginTop={'4.8rem'}>
          <CustomButton
            name={'Save Changes'}
            h={'4.8rem'}
            mW={'20.3rem'}
            type={'submit'}
            loading={saveLoading}
          />
        </Flex>
      </form>
    </Stack>
  );
}

export default EditAddress;
