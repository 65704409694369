import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { durationDisplay, formatDateRange } from 'pages/admin/leave-management/Requests/helper';
import { FETCH_EMPLOYEE_LEAVE } from 'query';

import { TableComponent } from 'components';
import { Status } from 'components/tables/TableConfig';

import { columns } from './data';

export const LeaveRequestsTable = () => {
  let navigate = useNavigate();

  const { data, loading } = useQuery(FETCH_EMPLOYEE_LEAVE);
  const fetchedData = data?.fetchUserLeaveRequests?.data;

  const tableData = fetchedData?.map(
    ({ leaveStatus, leaveTypeId, description, startDate, endDate, count, durationType }) => {
      const duration = durationType === null ? '' : durationType;

      return {
        col1: leaveTypeId?.name || 'N/A',
        col2: description || 'N/A',
        col3: formatDateRange({ startDate, endDate }) || '',
        col4: durationDisplay({ count, duration }) || 'N/A',
        col5: Status({ status: leaveStatus }) || '',
      };
    }
  );
  const viewUser = ({ id }) => {
    navigate({ pathname: `/employee/leave-request/${id}` });
  };
  const dropdown = {
    view: 'View Details',
  };

  return (
    <Root data-testid="leave-request-table">
      <TableComponent
        noSort
        noCheckbox
        message={'No Leave History Found'}
        loading={loading}
        columns={columns}
        data={tableData}
        empty={tableData?.length === 0}
        dropdownActions={dropdown}
        actionOnData={viewUser}
        ids={fetchedData?.map((e) => e._id)}
      />
    </Root>
  );
};

const Root = styled.div`
  padding-bottom: 5rem;
  margin-top: 4.8rem;
  overflow-x: auto;

  thead tr {
    background: #f3f4f6;
  }

  thead th:first-of-type {
    padding-left: 1.6rem;
  }

  tbody td:first-of-type {
    padding-left: 1.6rem;
  }

  tbody td {
    text-transform: capitalize;
  }
`;
