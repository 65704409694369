import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { joinNames } from 'pages/employee/dashboard/overview/helpers';
import { DELETE_ANNOUNCEMENT, FETCH_ANNOUNCEMENT } from 'query';

import { SimpleModal } from 'components';

import clockIcon from '../../../../assets/icons/clock.svg';
import deleteIcon from '../../../../assets/icons/Delete copy.svg';
import editIcon from '../../../../assets/icons/Edit copy.svg';
import { useToastify } from '../../../../hooks/useToastify';
import { formatDate, modifyFilters } from '../helpers';
import { DraftLabel, PublishedLabel } from '../styles';

import { EditAnnouncement } from './Modals/EditAnnouncement';

export function SingleAnnouncement(props) {
  const [showModal, setShowModal] = useState(false);
  const [announcementId, setAnnouncementId] = useState('');
  function toggleModal() {
    setShowModal((prev) => !prev);
  }

  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT, {
    refetchQueries: [
      {
        query: FETCH_ANNOUNCEMENT,
        variables: {
          filter: {
            type: props.type,
            ...modifyFilters(props.filter),
          },

          page: 1,
        },
      },
    ],
  });

  const { successToast } = useToastify();

  async function handleDeleteAnnouncement(id) {
    const res = await deleteAnnouncement({
      variables: {
        announcementId: id,
      },
    });

    if (res.data.deleteAnnouncement.status === 200) {
      successToast(res.data.deleteAnnouncement.message);
    }
  }
  function editAnnouncement(id) {
    setAnnouncementId(id);
    toggleModal();
  }

  return (
    <>
      <Flex
        justifyContent={'space-between'}
        backgroundColor={'var(--white)'}
        alignItems={'center'}
        border={'solid 1px var(--grey-150)'}
        borderRadius={'4px'}
        p={'16px 24px'}>
        <Stack>
          <Flex alignItems={'center'} gap={'8px'}>
            <Text fontSize={'16px'} fontWeight={'600'}>
              {props.title}
            </Text>
            {props.status === 'published' && <PublishedLabel>{props.status}</PublishedLabel>}
            {props.status === 'draft' && <DraftLabel>{props.status}</DraftLabel>}
          </Flex>
          <Flex color={'var(--grey-500)'} gap={'20px'} fontSize={'12px'}>
            <Flex gap={'8px'}>
              <Image src={clockIcon} />
              <Text>{formatDate(props.date)}</Text>
            </Flex>
            <Text>{joinNames(props.issuedBy)}</Text>
          </Flex>
        </Stack>

        <Flex>
          <Button
            border={'none'}
            data-testid={`edit-announcement`}
            onClick={() => editAnnouncement(props.id)}
            backgroundColor={'inherit'}>
            <Image src={editIcon} />
          </Button>
          <Button border={'none'} backgroundColor={'inherit'} data-testid={`delete-announcement`}>
            <Image src={deleteIcon} onClick={() => handleDeleteAnnouncement(props.id)} />
          </Button>
        </Flex>
      </Flex>

      <SimpleModal showModal={showModal}>
        <EditAnnouncement
          closeModal={toggleModal}
          label={'Edit'}
          announcementId={announcementId}
          type={props.type}
          content={JSON.parse(props.content)}
          page={props.page}
          filter={props.filter}
          issuedBy={props.issuedBy}
        />
      </SimpleModal>
    </>
  );
}
