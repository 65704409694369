import React, { useState } from 'react';
import styled from '@emotion/styled';

import deleteicon from '../../../../assets/icons/Delete copy.svg';
import editicon from '../../../../assets/icons/Edit copy.svg';
import { Button } from '../button';
import { EditCard } from '../edit-cards';

const Container = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin: 24px 0;
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 24px 8px 16px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;

  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    color: #333758;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.117188px;

    /* Gray text */

    color: #83889d;
  }

  .flex {
    display: flex;
    gap: 30px;
  }
`;

const ScoreCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [itemId, setItemId] = useState('');

  const [state, setState] = useState();

  const handleChange = (e) => {
    const { name, value, label } = e.target;

    if (label) {
      setState((prevState) => ({
        ...prevState,
        [name]: { label, value },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onDelete = () => {
    setIsOpen(false);
  };

  const openEdit = (id) => {
    const newList = props.list?.filter((item) => {
      if (item.id === id) {
        setItemId(item.id);
        setIsOpen(true);
        return item.id;
      }
    });
    setState(...newList);
  };

  const onUpdate = () => {
    const newState = props.list.map((item) => {
      if (item.id === itemId) {
        return { ...item, ...state };
      }
      return item;
    });
    props.setList(newState);
    setIsOpen(false);
  };

  const isDisabled = !(state?.title && state?.hint && state?.type.label);
  return (
    <Container>
      {props.list &&
        props.list?.map((data) => (
          <div key={data.id}>
            {data.id === itemId && isOpen ? (
              <EditCard
                handleRemove={props}
                Heading={'Edit Score Card'}
                isOpen={isOpen}
                handleChange={handleChange}
                scoreCardType={props.scoreCardType}
                onDelete={onDelete}
                onSave={onUpdate}
                isDisabled={isDisabled}
                value={state}
              />
            ) : (
              <Card>
                <div>
                  <h1>{data.title}</h1>
                  <p>{data.hint}</p>
                </div>
                <div className="flex">
                  <Button dataTestId="edit" img={editicon} onClick={() => openEdit(data.id)} />
                  <Button
                    dataTestId="delete"
                    img={deleteicon}
                    onClick={() => props.handleRemove(data.id)}
                  />
                </div>
              </Card>
            )}
          </div>
        ))}
    </Container>
  );
};

export { ScoreCard };
