import styled from '@emotion/styled';

import { ConfigureControls } from './ConfigureControls';
import { ConfigureFormFields } from './ConfigureFormFields';

function ConfigurePanel() {
  return (
    <ConfigurePanelRoot>
      <header className="header">Configure</header>
      <ConfigureControls />
      <ConfigureFormFields />
    </ConfigurePanelRoot>
  );
}

export const ConfigurePanelRoot = styled.div`
  border: 1px solid var(--grey-150);
  border-radius: 8px 8px 0 0;

  .header {
    height: 5.6rem;
    display: flex;
    align-items: center;
    padding-inline: 2.4rem;
    border-radius: 8px 8px 0 0;
    background: var(--grey-150);
    font-weight: 600;
    top: 0;
  }

  @media (min-width: 768px) {
    position: sticky;
    top: 0;
  }
`;

export { ConfigurePanel };
