import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import {
  FETCH_APPLICANT_APPLICATION,
  FETCH_APPLICATION_FILTER_FIELDS,
  STAGING,
  UPDATE_APPLICATION_STAGE,
} from 'query/admin/candidates';

import {
  ActionDropdown,
  CustomButton,
  ModalBackButton,
  Pagination,
  Search,
  SimpleModal,
} from 'components';

import { CandidateTable } from './components/candidates-table/CandidateTable';
import Bookmark from './components/image/Bookmark.svg';
import Message from './components/image/Message.svg';
import ThreeDots from './components/image/three.svg';
import filter from './components/image/Vector.svg';
import RunFilter from './components/RunFilter';

const Container = styled.div`
  margin: 40px 24px;
  background: #fafcff;

  .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px;
  }
`;

const dropDownForTable = [
  {
    label: 'Move Next  Status',
    value: 'change',
    src: Bookmark,
  },
  {
    label: 'Email Candidate',
    value: 'message',
    src: Message,
  },
  // {
  //   label: <Text color={'#FA4A4A'}>Delete</Text>,
  //   value: 'delete',
  //   src: deleteIcon,
  // },
];

const isTesting = process.env.NODE_ENV === 'test';

export function ApplicantViewTable() {
  const [showModal, setShowModal] = useState(false);
  function toggleModal() {
    setShowModal((prev) => !prev);
  }

  const [tData, setTData] = useState([]);
  const [search, setSearch] = useState('');
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState([]);
  const { successToast, errorToast } = useToastify();
  const { data: stages } = useQuery(STAGING, {
    variables: {},
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [updateStatus] = useMutation(UPDATE_APPLICATION_STAGE, {
    refetchQueries: [
      {
        query: FETCH_APPLICANT_APPLICATION,
        variables: {
          filter: {
            jobId: id,
            name: search,
          },
          page,
        },
      },
    ],
  });

  let userId;

  if (isTesting) {
    userId = 'mockId';
  } else {
    userId = id;
  }
  function getRankId({ rank }) {
    if (rank === stages?.fetchStages?.data?.length) {
      return stages?.fetchStages?.data?.find((item) => item.rank === rank)._id;
    } else {
      return stages?.fetchStages?.data?.find((item) => item.rank === rank + 1)._id;
    }
  }
  function changeAllStatus(data) {
    data.forEach(async (element) => {
      try {
        const result = await updateStatus({
          variables: {
            data: {
              applicationId: element.id,
              stageId: getRankId(element.stage),
            },
          },
        });
        if (result.data.updateApplicationStage.status === 200) {
          successToast('Status updated successfully');
        }
      } catch (err) {
        errorToast(err.message);
      }
    });
  }
  const actionPerform = (action, data) => {
    let newdata = data.map((e) => {
      return e.email;
    });
    switch (action?.value) {
      case 'change':
        changeAllStatus(data);
        break;
      case 'message':
        window.location = `mailto:${newdata.join(',')}`;
        break;
      case 'delete':
        break;
    }
  };
  const {
    data,
    loading: fetchApplicantLoading,
    refetch: refetchApplication,
  } = useQuery(FETCH_APPLICANT_APPLICATION, {
    variables: {
      filter: {
        jobId: userId,
        name: searchString,
      },
      page,
    },
  });
  const title = data?.fetchJobApplications?.data?.title;
  const country = data?.fetchJobApplications?.data?.country;
  const design = data?.fetchJobApplications?.data?.department?.name;
  const type = data?.fetchJobApplications?.data?.type;
  const totalPages = data?.fetchJobApplications?.totalCount || 1;
  useEffect(() => {
    const newTableData = data?.fetchJobApplications?.data;
    setTData(newTableData);
  }, [data, searchString, page]);
  function goBack() {
    navigate('/admin/candidates');
  }
  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const { data: appFilter, refetch: refetchApplicationFilters } = useQuery(
    FETCH_APPLICATION_FILTER_FIELDS,
    {
      variables: {
        filter: {
          jobId: id,
        },
      },
    }
  );

  return (
    <Container>
      <Flex alignItems={'center'} mb={'32px'}>
        <ModalBackButton onClick={goBack} data-testid={'backButton'} />
        <Box ml={'32px'} mt={'24px'}>
          <Heading>{title}</Heading>
          <Flex gap={'12px'} color={'#83889D'}>
            <Text>{design}</Text>
            <Text>{country}</Text>
            <Text>{type}</Text>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent={'space-between'} mb={'35px'}>
        <Search
          placeholder={'Search by name'}
          value={search}
          setPage={setPage}
          setSearchString={setSearchString}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
        <Flex justifyContent={'space-between'} gap={'0px 40px'} alignItems={'center'}>
          <Flex alignItems={'center'} gap={'0px 16px'}>
            <Flex color={'#83889D'} alignItems={'center'} fontSize={'20px'}>
              <Heading color={'#23273B'} mr={'4px'}>
                {checked.length}
              </Heading>{' '}
              Selected
            </Flex>
            <Box borderRadius={'4px'} background={'#999FA3'}>
              <ActionDropdown
                actions={dropDownForTable}
                btn={ThreeDots}
                onClick={actionPerform}
                id={checked?.map((e) => e)}></ActionDropdown>
            </Box>
          </Flex>
          <div className="all-kebab">
            <CustomButton
              srcRight={filter}
              onClick={toggleModal}
              name={'Run ATS Filter'}
              h={'40px'}
            />
          </div>
        </Flex>
      </Flex>

      <SimpleModal showModal={showModal} setShowModal={toggleModal}>
        <RunFilter
          closeModal={toggleModal}
          filters={appFilter?.fetchApplicationFilterFields?.data?.filters}
          jobId={id}
          search={search}
          page={page}
          refetchFns={[refetchApplication, refetchApplicationFilters]}
        />
      </SimpleModal>
      <CandidateTable
        checked={checked}
        setChecked={setChecked}
        search={searchString}
        page={page}
        data={tData}
        loading={fetchApplicantLoading}
      />
      {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
    </Container>
  );
}
