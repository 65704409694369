import React from 'react';
import { Avatar, Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Logo } from 'components/shared/Svgs';
import { Tabs } from 'components/shared/tabs';

import logoutImage from '../../../assets/icons/red-logout.svg';
import { logout } from '../../../utils';

const Container = styled(Grid)`
  height: fit-content;
  color: #141414;

  .dropdown {
    &-menu {
      height: fit-content;
      justify-content: space-between;

      .avatar {
        border-radius: 50%;
      }
    }
    &-text {
      height: fit-content;

      .name {
        opacity: 0.8;
        font-weight: 600;
      }
    }
  }

  .menu-items {
    list-style-type: none;

    .menu-item {
      display: flex;
      gap: 16px;
      align-items: center;
      cursor: pointer;
      padding: 10px 16px;
      transition: all 0.2s ease;
      border-radius: 10px;
      color: var(--grey-700);
      font-weight: 500;

      &:hover {
        background-color: var(--primary);
        color: #fff;
        path {
          fill: #fff;
        }
      }
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid var(--grey-300);
    border-radius: 10px;

    .tab-btn-toggle {
      position: relative;
      margin: 0;
      border: 0;
      font-size: 12px;
      text-transform: uppercase;
      padding: 10px 16px;
      border-radius: 8px;
      transition: 0.15s ease-in;

      &.active {
        background-color: var(--primary);
        color: #fff;
      }
    }
  }

  .logo {
    fill: #141414 !important;
  }

  .sidebar {
    &-overlay {
      position: fixed;
      top: 0px;
      height: 100vh;
      width: 100vw;
      background: #000;
      opacity: 0.7;
      left: 0;
    }

    &-content {
      flex-direction: column;
      justify-content: space-between;
      padding: 35px 20px;
      background-color: #fff;
      position: fixed;
      top: 0px;
      height: 100vh;
      width: 90vw;
      right: 0;
    }

    &-text {
      font-size: 12px;
      font-weight: 800;
      color: var(--grey-500);
    }
  }

  .logout {
    cursor: pointer;
  }

  #nav-icon1 {
    z-index: 1000;
    width: 18px;
    height: 18px;
    position: relative;
    background-color: transparent;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon1 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #3e7eee;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon1 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon1 span:nth-child(2) {
    top: 9px;
  }

  #nav-icon1 span:nth-child(3) {
    top: 18px;
  }

  #nav-icon1.open span:nth-child(1) {
    top: 9px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -30px;
  }

  #nav-icon1.open span:nth-child(3) {
    top: 9px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  @media only screen and (max-width: 900px) {
  }
`;

const SideBar = (props) => {
  const [openSideBar, setOpenSideBar] = React.useState(false);

  const handleLogout = () => {
    logout();
    window.location.replace(`/applicant/login`);
  };

  const goToProfile = () => {
    return props.isDashboard
      ? window.location.replace(`/applicant/${props?.user?._id}`)
      : window.location.replace(`/applicant/dashboard`);
  };

  return (
    <Container className="sidebar">
      <div
        id={`nav-icon1`}
        className={`${openSideBar ? 'open' : ''}`}
        onClick={() => setOpenSideBar(!openSideBar)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {openSideBar ? (
        <>
          <Box className="sidebar-overlay"></Box>
          <Flex className="sidebar-content">
            <Grid gap={'48px'}>
              <Box>{Logo}</Box>
              <Grid gap={'24px'}>
                <ul className="menu-items">
                  {props.isDashboard ? (
                    <li className="menu-item" onClick={goToProfile}>
                      {User}
                      Edit Profile
                    </li>
                  ) : (
                    <li className="menu-item" onClick={goToProfile}>
                      {Dashboard}
                      Go to Dashboard
                    </li>
                  )}
                </ul>
                {props.isDashboard ? (
                  <Grid gap={'16px'}>
                    <Text className="sidebar-text">MY JOBS</Text>
                    <Tabs
                      withLine
                      tabs={props.tabs}
                      closeModal
                      setCloseModal={setOpenSideBar}
                      setSelected={props.setSelected}
                      selected={props.selected}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid gap={'24px'}>
              <hr />
              <Flex className="dropdown-menu">
                <Flex gap={'4px'}>
                  <Avatar
                    size={'lg'}
                    className="avatar"
                    name={props?.user?.fullName}
                    src={props?.user?.imageUrl}
                  />
                  <Grid className="dropdown-text">
                    <p className="name">{props?.user?.fullName}</p>
                    <p className="sidebar-text">{props?.user?.email}</p>
                  </Grid>
                </Flex>
                <Image className="logout" src={logoutImage} onClick={handleLogout} />
              </Flex>
            </Grid>
          </Flex>
        </>
      ) : null}
    </Container>
  );
};

const Dashboard = (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M13.3962 1.73145H16.2178C17.3864 1.73145 18.3333 2.68632 18.3333 3.86475V6.71022C18.3333 7.88864 17.3864 8.84352 16.2178 8.84352H13.3962C12.2276 8.84352 11.2808 7.88864 11.2808 6.71022V3.86475C11.2808 2.68632 12.2276 1.73145 13.3962 1.73145"
      fill="#3e7eee"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.78194 1.73145H6.60358C7.77214 1.73145 8.71902 2.68632 8.71902 3.86475V6.71022C8.71902 7.88864 7.77214 8.84352 6.60358 8.84352H3.78194C2.61338 8.84352 1.6665 7.88864 1.6665 6.71022V3.86475C1.6665 2.68632 2.61338 1.73145 3.78194 1.73145ZM3.78194 11.286H6.60358C7.77214 11.286 8.71902 12.2409 8.71902 13.4193V16.2648C8.71902 17.4424 7.77214 18.3981 6.60358 18.3981H3.78194C2.61338 18.3981 1.6665 17.4424 1.6665 16.2648V13.4193C1.6665 12.2409 2.61338 11.286 3.78194 11.286ZM16.2177 11.286H13.3961C12.2275 11.286 11.2807 12.2409 11.2807 13.4193V16.2648C11.2807 17.4424 12.2275 18.3981 13.3961 18.3981H16.2177C17.3863 18.3981 18.3332 17.4424 18.3332 16.2648V13.4193C18.3332 12.2409 17.3863 11.286 16.2177 11.286Z"
      fill="#3e7eee"
    />
  </svg>
);

const User = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99775 11.9463C5.763 11.9463 3 12.4563 3 14.4963C3 16.537 5.74575 17.065 8.99775 17.065C12.2325 17.065 14.9955 16.5558 14.9955 14.515C14.9955 12.4743 12.2505 11.9463 8.99775 11.9463Z"
      fill="#3e7eee"
    />
    <path
      opacity="0.4"
      d="M8.99781 10.0029C11.2013 10.0029 12.9668 8.23669 12.9668 6.03394C12.9668 3.83119 11.2013 2.06494 8.99781 2.06494C6.79506 2.06494 5.02881 3.83119 5.02881 6.03394C5.02881 8.23669 6.79506 10.0029 8.99781 10.0029Z"
      fill="#3e7eee"
    />
  </svg>
);

export { SideBar };
