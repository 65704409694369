import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useToastify } from '../hooks/useToastify';
import { reviewCycleFormTestDefaults } from '../pages/admin/performance/reviews/CreateReview/CreateReview.mock';
import { ReviewFormSchema } from '../schemas/review-cycle-form';

const reviewCycleFormContext = React.createContext();
const defaultValues = { isSeenByAll: false, isSeenByReviewee: false };

const isTesting = process.env.NODE_ENV === 'test';

export function ReviewCycleFormProvider(props) {
  const { initialValues } = props;
  const init = React.useMemo(() => {
    if (isTesting) return { defaultValues: reviewCycleFormTestDefaults };
    return initialValues || { defaultValues };
  }, []);
  const formMethods = useForm({ ...init, resolver: yupResolver(ReviewFormSchema) });
  const { formState } = formMethods;
  const { errorToast } = useToastify();

  function getErrors(errorObject) {
    const errorList = Object.entries(errorObject);
    if (errorList.length) {
      errorList.forEach(([, errorDetails]) => {
        const { message = '' } = errorDetails || {};
        if (message) errorToast(message);
        if (!message && errorDetails) {
          getErrors(errorDetails);
        }
      });
    }
  }

  React.useEffect(() => {
    getErrors(formState.errors);
  }, [formState.errors]);

  return (
    <reviewCycleFormContext.Provider value={formMethods}>
      {props.children}
    </reviewCycleFormContext.Provider>
  );
}

export function useReviewCycleForm() {
  const context = React.useContext(reviewCycleFormContext);
  if (!context) {
    throw new Error("'useReviewCycleForm' should be used inside ReviewCycleFormProvider");
  }
  return context;
}
