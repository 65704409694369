import React from 'react';
import { FormControl, FormLabel, GridItem, Input, SimpleGrid } from '@chakra-ui/react';
import { inputError } from 'utils';

const colSpan = { base: 2, md: 1 };

export const PensionDetails = ({ register, errors }) => {
  return (
    <SimpleGrid columns={2} marginTop="4rem" gap="6">
      <GridItem colSpan={colSpan}>
        <FormControl isRequired>
          <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
            Pension Number
          </FormLabel>
          <Input
            {...register('bankAccount.pensionAccountNo')}
            fontSize="1.6rem"
            height="4.8rem"
            backgroundColor="#F7F9FB"
          />
          {inputError(errors, 'bankAccount.pensionAccountNo')}
        </FormControl>
      </GridItem>
      <GridItem colSpan={colSpan}>
        <FormControl isRequired>
          <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
            Administrative Body
          </FormLabel>
          <Input
            {...register('bankAccount.pensionFundAdministrator')}
            fontSize="1.6rem"
            height="4.8rem"
            backgroundColor="#F7F9FB"
          />
          {inputError(errors, 'bankAccount.pensionFundAdministrator')}
        </FormControl>
      </GridItem>
    </SimpleGrid>
  );
};
