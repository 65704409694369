import React from 'react';
import { useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { AssessmentFormProvider, useAssessmentForm } from 'context/assessmentForm';
import { useToastify } from 'hooks/useToastify';
import {
  CREATE_ASSESSMENT,
  EDIT_ASSESSMENT,
  FETCH_ASSESSMENT_BY_TITLE,
  FETCH_ASSESSMENT_TITLES,
} from 'query';
import { assessmentSchemer } from 'schemas';
import { fetchTitle } from 'tests/pages/assessment/mock';

import { CustomButton, ModalBackButton } from 'components';

import { AssessmentContent } from './AssessmentContent';
import { PreviewQuestions } from './component';
import { formatQuestions, revertFormatQuestions } from './helpers';

const isTesting = process.env.NODE_ENV === 'test';

const AddAssessment = ({ defaultData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const assTitle = location?.state?.title;
  const isEditing = location?.state?.isEditing;

  const [showPreview, setShow] = React.useState(false);
  const [defaultD, setDefaultD] = React.useState({});

  const { control, reset, handleSubmit, register } = useAssessmentForm();

  const [getData, { data, refetch: refetchSingle }] = useLazyQuery(FETCH_ASSESSMENT_BY_TITLE, {
    variables: { title: assTitle },
  });

  React.useEffect(() => {
    assTitle !== '' ? getData() : null;

    const init = isTesting ? fetchTitle?.fetchByTitle?.data[0] : data?.fetchByTitle?.data[0];

    const sections = init?.sections?.map((sec) => {
      return {
        ...sec,
        questions: sec?.questions?.map((qst) => {
          return revertFormatQuestions(qst);
        }),
      };
    });
    const defaultDt = { ...init, sections: sections };
    setDefaultD(defaultDt);
  }, [data]);

  const { refetch } = useQuery(FETCH_ASSESSMENT_TITLES, { variables: { filter: { keyword: '' } } });

  const [createAssessment, { loading }] = useMutation(CREATE_ASSESSMENT);
  const [editAssessment, { loading: editing }] = useMutation(EDIT_ASSESSMENT);
  const { successToast, errorToast } = useToastify();

  async function onSubmit(data) {
    const sectionsFormat = data?.sections?.map((sec) => {
      return {
        ...sec,
        questions: sec?.questions
          ?.filter((elem) => elem?.question !== '')
          .map((qst) => {
            return formatQuestions(qst);
          }),
      };
    });
    const dataToSend = { ...data, sections: sectionsFormat, passMark: parseInt(data.passMark) };
    isEditing ? delete dataToSend['_id'] : null;

    if (data?.sections.length === 0) {
      errorToast('Add at least one Section');
    } else {
      try {
        const res = isEditing
          ? await editAssessment({
              variables: {
                filters: {
                  _id: data?._id,
                },
                data: dataToSend,
              },
            })
          : await createAssessment({
              variables: { data: dataToSend },
            });

        if (!isEditing) {
          const status = res?.data?.createAssessment?.status;
          const message = res?.data?.createAssessment?.message;
          status === 201 ? successToast(message) : errorToast(message);
          reset();
        } else if (isEditing) {
          const editStatus = res?.data?.editAssessment?.status;
          const editMsg = res?.data?.editAssessment?.message;
          editStatus === 200 ? successToast(editMsg) : errorToast(editMsg);
          await refetchSingle();
          navigate('/admin/assessments');
        }
      } catch (error) {
        return error;
      } finally {
        refetch();
      }
    }
  }

  const pData = useWatch({ control });
  const assessmentTitle = useWatch({ control, name: 'title' });

  return (
    <AssessmentRoot onSubmit={handleSubmit(onSubmit)}>
      <div className="main__content">
        <div className="header">
          <div className="header-left">
            <ModalBackButton
              data-testid="modal-back-btn"
              onClick={() => navigate('/admin/assessments')}
              type="button"
            />
            <p className="title">{assessmentTitle || 'Add new assessment'}</p>
          </div>
          <div className="header-right">
            <CustomButton
              onClick={() => setShow(true)}
              name="Preview"
              type={'button'}
              variant="tertiary"
              h="48px"
              disabled={pData?.sections?.length < 1}
            />
            <CustomButton
              loading={loading || editing}
              type={'submit'}
              name="Save"
              variant="primary"
              h="48px"
            />
          </div>
        </div>

        <div className="content">
          <AssessmentContent
            defaultData={assTitle === '' ? defaultData : defaultD}
            register={register}
            control={control}
            reset={reset}
          />
        </div>
      </div>

      <PreviewQuestions pData={pData} show={showPreview} setShow={setShow} />
    </AssessmentRoot>
  );
};

export const AddNewAssessment = () => {
  const defaultData = {
    title: '',
    duration: '',
    deadline: '',
    sections: [],
  };

  const initialData = isTesting ? fetchTitle?.fetchByTitle?.data[0] : defaultData;

  return (
    <>
      <AssessmentFormProvider
        initialValue={{ defaultValues: initialData, resolver: yupResolver(assessmentSchemer) }}>
        <AddAssessment defaultData={defaultData} />
      </AssessmentFormProvider>
    </>
  );
};

const AssessmentRoot = styled.form`
  padding: 2.4rem 2.4rem 0;
  height: calc(100vh - 6rem);
  overflow: hidden;
  .main__content {
    padding: 0 2.4rem 3.2rem;
    background: #ffffff;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #23273b;
    text-transform: capitalize;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    margin-bottom: 4rem;
    padding: 4.8rem 0 2.4rem;
    background: var(--white);
    z--index: 15;
    &-left {
      display: flex;
      align-items: center;
      gap: 2.4rem;
    }

    &-right {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 300px;
      gap: 1.6rem;
    }
  }

  .content {
    height: 100%;
    display: grid;
    grid-template-columns: 2.3fr 1fr;
    gap: 2.4rem;
    .drop__section {
    }
  }
`;
