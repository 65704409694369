import React from 'react';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { CREATE_REVIEW_TEMPLATE, FETCH_ALL_TEAMS } from 'query';

import { Button, InputBox, Modal, SpinnerComponent } from 'components';

const InputFields = styled.form`
  display: flex;
  gap: 32px;
  flex-direction: column;
  max-width: 737px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 24px;

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    padding: 10px;
  }
`;

const ModalStyle = {
  width: '734px',
  borderRadius: '4px',
};

const labelStyle = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '17px',
  letterSpacing: '-0.117188px',
  color: '#23273B',
};
const inputStyle = {
  border: '1px solid #F2F2F2',
};

const saveButton = {
  background: ' #23273B',
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: 600,
  color: '#ffffff',
  padding: '12px 24px',
};

const cancelButton = {
  border: '1px solid #23273b',
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: 600,
  padding: '12px 24px',
};
const dropdownStyle = { width: '100%' };

const CreateTemplate = ({ openModal, setOpenModal }) => {
  const [createReviewTemplate, { loading }] = useMutation(CREATE_REVIEW_TEMPLATE);
  const { successToast, errorToast } = useToastify();
  const { data: teamsData, loading: teamsLoading } = useQuery(FETCH_ALL_TEAMS, {});

  const fetchTeams = teamsData?.fetchTeams?.data;
  const [state, setState] = useState();

  const isTesting = process.env.NODE_ENV === 'test';

  let teams = [
    { label: `Team A`, value: 'teamA' },
    { label: `Team B`, value: 'teamB' },
    { label: `Team C`, value: 'teamC' },
    { label: `Team D`, value: 'teamD' },
  ];

  const handleChange = (e) => {
    let { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCreate = async () => {
    try {
      const res = await createReviewTemplate({
        variables: {
          data: state,
        },
      });
      const { data } = res;

      if (data && data.createReviewTemplate.status === 201) {
        successToast('Review Template created sucessfully');
      } else errorToast(data.createReviewTemplate.message);
    } catch (err) {
      errorToast(err.message);
    }
    setOpenModal(false);
  };

  const clearState = (openModal) => {
    setState();
    setOpenModal(openModal);
  };

  const isDisabled = !(state?.name && state?.team);

  return (
    <Modal
      title={'Create Review Template'}
      width={'749px'}
      style={ModalStyle}
      showModal={openModal}
      closeModal={() => setOpenModal(!openModal)}>
      {teamsLoading && !isTesting ? (
        <Grid>
          <SpinnerComponent />
        </Grid>
      ) : (
        <InputFields>
          <InputBox
            label={'Name'}
            placeholder={'Eg: Enyata 2022 product team Q3 Appraisal'}
            required={true}
            dataTestId={'name'}
            id={'name'}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            handleChange={handleChange}
          />
          <InputBox
            label={'Description'}
            type={'textarea'}
            id={'description'}
            dataTestId={'description'}
            placeholder={'Ontario Employee'}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            handleChange={handleChange}
          />

          <InputBox
            label={'Teams'}
            placeholder={'Select from options'}
            type={'dropdown'}
            dataTestId={'teams'}
            id={'team'}
            dropdown={isTesting ? teams : fetchTeams}
            labelStyle={labelStyle}
            handleChange={(e) =>
              handleChange({
                target: {
                  name: 'team',
                  value: e.value,
                },
              })
            }
            required={true}
            dropdownStyle={dropdownStyle}
          />
        </InputFields>
      )}
      <Flex justifyContent={'flex-end'} gap={'8px'} margin={'24px 0'}>
        <Button
          dataTestId={'cancel'}
          title={'Cancel'}
          style={cancelButton}
          onClick={() => clearState(!openModal)}
        />
        <Button
          dataTestId={'save'}
          title={'Save'}
          isDisabled={isDisabled}
          onClick={onCreate}
          style={saveButton}
          loading={loading}
        />
      </Flex>
    </Modal>
  );
};

export { CreateTemplate };
