import { gql } from '@apollo/client';

export const FETCH_ASSESSMENT = gql`
  query fetchAssessmentById($id: ObjectID!) {
    fetchAssessmentById(id: $id) {
      status
      message
      data {
        _id
        title
        duration
        deadline
        sections {
          name
          instructions
          questions {
            question
            questionType
            options
            correctAnswers
            points
          }
        }
      }
    }
  }
`;
export const FETCH_ASSESSMENT_RESPONSE = gql`
  query fetchResponse($applicantId: ObjectID) {
    fetchResponse(applicantId: $applicantId) {
      status
      message
      data {
        assessmentId
        score
      }
    }
  }
`;
export const SUBMIT_ASSESSMENT = gql`
  mutation createResponse($data: ResponseInput!) {
    createResponse(data: $data) {
      status
      message
      data {
        score
      }
    }
  }
`;
