import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import plusBlue from 'assets/icons/plusBlueBg.svg';
import plusIcon from 'assets/icons/plusIcon.svg';
import { useToastify } from 'hooks/useToastify';
import { questionSchemer } from 'schemas';

import { DropFile } from 'components';

import { FilesPreview } from './filesDisplay';
import { dustbin, icons } from './icons';
import { Button, OptionsInput, Root, SectionInput } from './style';

const isTesting = process.env.NODE_ENV === 'test';

const defaultVal = { question: 'What is a calback', points: 5, options: [{ value: 'one' }] };

export const InputFieldTemplate = (props) => {
  const { type, removeField, questionIndex, update, setFiles, files } = props;
  const [correctAnswers, setAnswers] = React.useState([]);
  const [canAddAnswer, setCanAdd] = React.useState(false);
  const [addFile, setAddFile] = React.useState(false);

  const defaultFields = isTesting ? defaultVal : {};
  const previewFiles = isTesting ? [{ name: 'Samplefile.png' }] : files;

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFields,
    resolver: yupResolver(questionSchemer),
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: `options`,
  });

  React.useEffect(() => {
    const errorList = Object.entries(errors);
    if (errorList.length) {
      errorList.forEach(([, value]) => {
        const message = value.message;
        errorToast(message);
      });
    } else if (errors?.options?.length) {
      errors?.options?.map((opt) => {
        const message = opt?.value.message;
        errorToast(message);
      });
    }
  }, [errors]);

  const setAsAnswer = (f) => {
    setAnswers((prev) =>
      correctAnswers.includes(f) ? prev.filter((cur) => cur !== f) : [...prev, f]
    );
  };

  const filterFiles = (filename) => {
    setFiles((prev) => prev?.filter((file) => file?.name !== filename));
  };

  const { errorToast } = useToastify();
  const watchOptions = watch(`options`);

  const handleUpdate = (data) => {
    const uniqueOptions = data?.options.filter(
      (value, index, self) => index === self.findIndex((t) => t.value === value.value)
    );

    const newData = {
      ...data,
      options: uniqueOptions,
      correctAnswers: correctAnswers,
      questionType: type,
      isEditing: true,
    };

    const toSend = files?.length > 0 ? { ...newData, files: files } : newData;

    const condition = type === 'radio' && correctAnswers.length > 1;

    if (watchOptions?.length < 1) {
      errorToast('Add Options');
    } else if (watchOptions?.some((opt) => opt?.value === '')) {
      errorToast('Option cannot be empty');
    } else if (correctAnswers?.length === 0) {
      errorToast('Choose correct answer(s');
    } else if (condition) {
      errorToast('Only one option allowed for radio type question');
    } else {
      update(questionIndex, toSend);
      setFiles([]);
    }
  };

  const toggleCanAdd = () => {
    if (watchOptions?.length === 0) {
      errorToast('Add options');
    } else if (watchOptions?.some((opt) => opt?.value === '')) {
      errorToast('Option Cannot be empty');
    } else {
      setCanAdd(!canAddAnswer);
    }
  };

  return (
    <Root data-testid="input-field-template">
      <div className="text_area">
        <img src={icons[type]} alt="" />
        <SectionInput type="text" {...register(`question`)} />
        <Flex className="add-score">
          <p>Add Score</p>
          <SectionInput {...register(`points`)} type="number" min={0} max={10} />
        </Flex>
      </div>

      <div style={{ width: '80%', margin: 'auto' }}>
        <div className="options__section">
          <div className="options__wrapper">
            {fields?.map((field, index) => {
              const ans = watch(`options.${index}.value`);
              return (
                <button
                  key={field.id}
                  data-testid={'set-as-answer'}
                  disabled={!canAddAnswer}
                  onClick={() => setAsAnswer(ans)}
                  className={
                    ('options__wrapper--item',
                    correctAnswers.includes(ans) ? 'added_as_answer' : 'options__wrapper--item')
                  }
                  type={'button'}>
                  <OptionsInput name={field} {...register(`options.${index}.value`)} />
                  <IoClose
                    data-testid="remove-option"
                    className="close"
                    color={'#999FA3'}
                    size="14"
                    onClick={() => remove(index)}
                  />
                </button>
              );
            })}
          </div>
          {!canAddAnswer && (
            <Button
              style={{ color: 'var(--primary)' }}
              data-testid="add-options-btn-1"
              onClick={() => {
                append({ value: '' });
              }}
              className="btn"
              type="button">
              <img src={plusBlue} alt="add" />
              Add Option
            </Button>
          )}
          {canAddAnswer && (
            <p onClick={toggleCanAdd} className="select--answer">
              Select answer(s)
            </p>
          )}
        </div>

        <Flex justifyContent={'space-between'} mt="1.6rem">
          <Button
            data-testid="add-file"
            onClick={() => setAddFile(!addFile)}
            className="btn"
            type="button">
            <img src={plusIcon} alt="add" />
            Add File(s) <span>(optional)</span>
          </Button>

          <div className="delete-icon">
            <img src={dustbin} alt="" data-testid="remove-question" onClick={removeField} />
          </div>
        </Flex>
        {addFile && (
          <>
            <DropFile setFiles={setFiles} size={'12MB'} />
            <FilesPreview
              files={previewFiles}
              addclose={true}
              removeFile={(filename) => filterFiles(filename)}
            />
          </>
        )}

        <div className="add__answer">
          <>
            {!canAddAnswer && (
              <Button
                data-testid="add-answer-btn"
                onClick={toggleCanAdd}
                className="btn"
                type="button">
                <img src={plusIcon} alt="add" />
                Add answer
              </Button>
            )}
            {canAddAnswer && (
              <Button
                data-testid="toggle-add-answer"
                style={{
                  border: '1px solid var(--green)',
                  padding: '1rem 2.4rem',
                  background: '#F7F9FB',
                }}
                onClick={() => setCanAdd(false)}
                className="btn"
                type="button">
                Done
              </Button>
            )}
          </>
          <Button
            data-testid="update-question"
            style={{
              border: '1px solid var(--green)',
              padding: '1rem 2.4rem',
              background: '#F7F9FB',
            }}
            onClick={handleSubmit(handleUpdate)}
            className="btn"
            type="button">
            Save
          </Button>
        </div>
      </div>
    </Root>
  );
};
