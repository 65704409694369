import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Tabs } from 'components/shared/tabs';

import { LeaveSettingsPage } from '../leave-management/leave-settings';
import { AdminManagement } from '../onboarding/setup-view/AdminManagement/AdminManagement';
import { CompanyDetails } from '../onboarding/setup-view/CompanyDetails/CompanyDetails';
import { Departments } from '../onboarding/setup-view/Departments/Departments';
import { RolesPermissions } from '../onboarding/setup-view/RolesPermissions/RolesPermissions';
import { Teams } from '../onboarding/setup-view/Teams/Teams';

const views = {
  details: <CompanyDetails />,
  roles: <RolesPermissions />,
  admins: <AdminManagement />,
  departments: <Departments />,
  leave: <LeaveSettingsPage />,
  teams: <Teams />,
};

export function Settings() {
  const [activeTab, setActiveTab] = React.useState('details');
  const tabs = [
    { displayName: 'COMPANY DETAILS', value: 'details' },
    { displayName: 'ROLES & PERMISSIONS', value: 'roles' },
    { displayName: 'ADMIN MANAGEMENT', value: 'admins' },
    { displayName: 'DEPARTMENTS', value: 'departments' },
    { displayName: 'LEAVE POLICY', value: 'leave' },
    { displayName: 'TEAMS', value: 'teams' },
  ];
  return (
    <SettingsWrapper>
      <Box
        m="24px"
        p="16px"
        pt="0"
        bg="white"
        w="calc(100% - 48px)"
        h="calc(100vh - 105px)"
        border="1px solid transparent"
        borderColor="brand.grey.150"
        overflowY="auto">
        <header>
          <h1>Settings</h1>
          <Box w="100%" overflowX="auto" className="scroll-bar">
            <Tabs selected={activeTab} setSelected={setActiveTab} tabs={tabs} />
          </Box>
        </header>
        {views[activeTab]}
      </Box>
    </SettingsWrapper>
  );
}

const SettingsWrapper = styled.div`
  h1 {
    margin-bottom: 24px;
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  header {
    padding-bottom: 20px;
    position: sticky;
    top: 0;
    padding-top: 16px;
    background: var(--white);
    z-index: 1;
  }
`;
