import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_COMPANY_DETAILS } from 'query';

function LocationOptions() {
  const [workLocationOptions, setLocation] = React.useState([]);

  const { data } = useQuery(FETCH_COMPANY_DETAILS);

  React.useEffect(() => {
    const locData = data?.fetchCompanyDetails?.data?.locations?.map(function (loc) {
      return {
        label: loc.address,
        value: loc._id,
      };
    });
    setLocation(locData);
  }, [data]);

  return {
    workLocationOptions,
  };
}

export { LocationOptions };
