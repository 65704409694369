import React from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';

export function YearGrid(props) {
  const { tempYear, setTempYear, year, setMode } = props;

  React.useLayoutEffect(() => {
    setTempYear(1970 + Math.floor((year - 1970) / 12) * 12);
  }, []);

  function handleYearClick(year) {
    setTempYear(year);
    setMode('month');
  }

  function isActive(y) {
    return y === year;
  }

  const yearArr = [];
  for (let i = tempYear; i < tempYear + 12; i++) {
    yearArr.push(i);
  }

  return (
    <YearGridRoot>
      {yearArr.map((year) => (
        <div
          className={cx('year', { active: isActive(year) })}
          key={year}
          onClick={() => handleYearClick(year)}>
          <div className="year__text">{year}</div>
        </div>
      ))}
    </YearGridRoot>
  );
}

const YearGridRoot = styled.div`
  display: grid;
  grid: 1fr / repeat(3, 1fr);
  gap: 8px;
  border: 1px solid #f2f2f2;
  border-radius: 0.8rem;
  padding: 1rem;
  //margin-bottom: 5.4rem;

  .year {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    width: 100%;
    border-radius: 0.4rem;
    padding-top: max(60%, 4rem);

    &:hover {
      background: #f2f2f2;
    }

    &.active {
      background: var(--blue);
      color: white;
    }

    &__text {
      position: absolute;
      inset: 50% 0 0 50%;
      translate: -50% -50%;
      width: max-content;
      height: max-content;
    }
  }
`;
