import styled from '@emotion/styled';

export const DashboardWrapper = styled.div`
  margin-top: 28px;
  background-color: white;
  min-height: 100vh;
  border-radius: 0.4rem;
  padding: 2.4rem;
  display: grid;
  column-gap: 16px;
  grid-template-columns: 1fr;
  row-gap: 32px;
  .dashboard__heading {
    margin-bottom: 20px;
  }

  .sidebar {
    display: flex;
    gap: 32px;
    flex-direction: column;
  }

  @media screen and (min-width: 450px) {
    margin: 48px 24px 24px 24px;
  }

  @media screen and (min-width: 700px) {
    grid-template-columns: repeat(3, 1fr);

    .dashboard__heading {
      grid-column: span 3;
    }

    .dashboard__profile {
      grid-column: span 1;
    }

    .dashboard__notification {
      grid-column: span 2;
    }

    .sidebar {
      grid-column: span 3;
      flex-direction: row;
      .upcomingEvents__container,
      .quickLinks__container {
        width: 50%;
      }
    }
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: 100px;

    .dashboard__profile {
      grid-column: span 3;
    }

    .dashboard__notification {
      grid-column: span 3;
    }

    .sidebar {
      flex-direction: column;
      .upcomingEvents__container,
      .quickLinks__container {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 900px) {
    grid-template-columns: 100px;

    .dashboard__profile {
      grid-column: span 3;
    }

    .dashboard__notification {
      grid-column: span 3;
    }

    .sidebar {
      flex-direction: row;
      .upcomingEvents__container,
      .quickLinks__container {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: 950px) {
    grid-template-columns: repeat(3, 1fr);

    .dashboard__heading {
      grid-column: span 3;
    }

    .dashboard__profile {
      grid-column: span 1;
    }

    .dashboard__notification {
      grid-column: span 2;
    }

    .sidebar {
      grid-column: span 3;
      flex-direction: row;
      .upcomingEvents__container,
      .quickLinks__container {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);

    .dashboard__heading {
      grid-column: span 4;
    }

    .dashboard__profile {
      grid-column: span 1;
    }

    .dashboard__notification {
      grid-column: span 3;
    }

    .sidebar {
      grid-column: span 4;
      flex-direction: row;
      .upcomingEvents__container,
      .quickLinks__container {
        width: 50%;
      }
    }
  }
  @media screen and (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);

    .dashboard__heading {
      grid-column: span 4;
    }

    .dashboard__profile {
      grid-column: span 1;
    }

    .dashboard__notification {
      grid-column: span 2;
    }

    .sidebar {
      grid-column: span 1;
      flex-direction: column;
      .upcomingEvents__container,
      .quickLinks__container {
        width: 100%;
      }
    }
  }
`;

export const ProfileCardWrapper = styled.div`
  color: var(--white);
  background-color: var(--blue);
  border-radius: 4px;
  padding: 32px 24px;
`;

export const ActionRequired = styled.div`
  background-color: var(--pending);
  padding: 0.4rem 0.8rem;
  max-width: 7.4rem;
  font-size: 0.8rem;
  color: var(--white);
  border-radius: 1.2rem;
`;
export const NewMessage = styled.div`
  background-color: var(--green);
  padding: 0.4rem 0.8rem;
  font-size: 0.8rem;
  color: var(--white);
  border-radius: 1.2rem;
`;

export const MessageWrapper = styled.div`
  width: 300px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: 500px) {
    width: 375px;
  }
  @media screen and (min-width: 600px) {
    width: 450px;
  }
  @media screen and (min-width: 700px) {
    width: 560px;
  }
`;

export const WrapperSimpleModal = styled.div`
  .notification-modal {
    width: 330px;
    padding: 24px;
    overflow-y: auto;
  }

  @media screen and (min-width: 500px) {
    .notification-modal {
      width: 400px;
    }
  }
  @media screen and (min-width: 700px) {
    .notification-modal {
      width: 450px;
    }
  }
  @media screen and (min-width: 850px) {
    .notification-modal {
      width: 500px;
    }
  }
  @media screen and (min-width: 1000px) {
    .notification-modal {
      width: 650px;
    }
  }
`;
