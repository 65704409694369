import React, { useReducer } from 'react';
import { Stack } from '@chakra-ui/react';

import { SimpleModal } from 'components';

import EditAddress from './Modals/EditAddress';
import EditBasicInfo from './Modals/EditBasicInfo';
import EditEmergencyContacts from './Modals/EditEmergencyContacts';
import { InfoContainer } from './InfoContainer';

const initialState = { showModal: false, contentType: '' };

function simpleModalreducer(state, action) {
  return { ...state, ...action };
}

function Personal({ profileDetails, profileData, userId }) {
  const [{ showModal, contentType }, setModalType] = useReducer(
    simpleModalreducer,
    () => initialState
  );

  return (
    <>
      <Stack gap={'2.4rem'}>
        <InfoContainer
          data={profileDetails}
          label={'Personal Information'}
          isEditable={true}
          openModal={() => setModalType({ showModal: true, contentType: 'editBasicInfo' })}
        />
        <InfoContainer data={profileDetails} label={'Medical Information'} isEditable={false} />
        <InfoContainer
          data={profileDetails}
          label={'Address'}
          isEditable={true}
          openModal={() => setModalType({ showModal: true, contentType: 'editAddress' })}
        />
        <InfoContainer
          data={profileDetails}
          label={'Emergency Contact'}
          isEditable={true}
          openModal={() => setModalType({ showModal: true, contentType: 'editEmergencyContacts' })}
        />
      </Stack>

      <SimpleModal showModal={showModal}>
        {contentType === 'editBasicInfo' && (
          <EditBasicInfo
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
            basicInfo={profileData}
            userId={userId}
          />
        )}

        {contentType === 'editAddress' && (
          <EditAddress
            address={profileData?.address[0]}
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
            userId={userId}
          />
        )}
        {contentType === 'editEmergencyContacts' && (
          <EditEmergencyContacts
            profileData={profileDetails}
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
            userId={userId}
          />
        )}
      </SimpleModal>
    </>
  );
}

export default Personal;
