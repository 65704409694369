import { extendTheme } from '@chakra-ui/react';

const breakpoints = {
  sm: '320px',
  md: '768px',
  slg: '980px',
  lg: '1200px',
  xl: '1441px',
};

const colors = {
  brand: {
    primary: '#3E7EEE',
    blue: '#3E7EEE',
    black: '#23273B',
    pending: '#F0A800',
    error: '#FA4A4A',
    green: '#36CA8B',
    deep_blue: '#23273B',
    bg: '#FAFCFF',
    grey: {
      text: '#83889D',
      100: '#F3F4F6',
      150: '#f2f2f2',
      300: '#D9D9D9',
      500: '#999FA3',
      700: '#666E7D',
    },
  },
};

const styles = {
  global: {
    html: {
      fontSize: '62.5%',
      boxSizing: 'border-box',
    },
    body: {
      fontSize: '1.6rem',
      fontFamily: "'Karla', sans-serif",
      color: '#23273B',
    },
    a: {
      color: '#23273B',
    },
    input: {
      fontSize: '1.4rem',
    },
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
};

const fonts = {
  heading: 'Karla, sans-serif',
  body: 'Karla, sans-serif',
};

const theme = extendTheme({
  breakpoints,
  colors,
  styles,
  fonts,
});

export { theme };
