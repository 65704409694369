import React from 'react';
import { Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AnimatedSection } from 'components/AnimateSection';

import skewedboard from '../../../assets/images/skewedboards.png';

export const LHeader = () => {
  const [isLargerThan1200] = useMediaQuery('(min-width: 768px)');

  const Container = styled.div`
    display: flex;
    padding: 36px 0;
    align-items: center;
    button {
      padding: 12.5px 18px;
      background: #3e7eee;
      border: 1px solid #568ef0;
      border-radius: 1000px;
      color: #fff;
    }

    .hero__image {
      width: 50%;
    }

    @media (max-width: 768px) {
      .heroTitle {
        line-height: 52px;
      }
      .heroSub {
        font-size: 16px;
        color: '#475467';
      }
      button {
        font-size: 14px;
      }
      .boxInput {
        padding: 8.5px 10px 8.5px 20px;
      }
    }
  `;
  return (
    <Container className="container">
      <Flex flexDir={'column'} gap={'32px'} maxWidth={'690px'} width={{ base: '100', md: '50%' }}>
        <Flex flexDir={'column'} gap={'24px'}>
          <AnimatedSection>
            <Text
              fontSize={{ base: '42px', md: '56px' }}
              fontWeight={700}
              letterSpacing={'-1.12px'}
              lineHeight={'normal'}
              maxWidth={'696px'}
              width={'100%'}
              className="heroTitle">
              Your Comprehensive <br />
              <span style={{ color: '#5380E0' }}>HR Management </span>Solution
            </Text>
          </AnimatedSection>
          <AnimatedSection>
            <Text fontSize={'18px'} lineHeight={'30px'} color={'#475467'} className="heroSub">
              Take your HR management to the next level with HRMS. Streamline your operations,
              enhance productivity, and drive organizational success.
            </Text>
          </AnimatedSection>
        </Flex>
      </Flex>
      {isLargerThan1200 ? (
        <Image
          className="hero__image"
          src={skewedboard}
          alt=""
          position={'relative'}
          right="-62px"
        />
      ) : null}
    </Container>
  );
};
