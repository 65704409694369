import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Heading, Stack } from '@chakra-ui/react';
import { FETCH_EMPLOYEE_ANNOUNCEMENTS } from 'query';

import { EmptyState, Pagination, SimpleModal, SpinnerComponent } from 'components';

import { getAnnouncement } from './components/helpers';
import { SingleAnnouncement } from './components/SingleAnnouncement';
import { ViewAnnouncement } from './components/ViewAnnouncement';

export function EmployeeAnnouncement() {
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);

  const [announcementId, setAnnouncementId] = useState();
  function toggleModal() {
    setShowModal((prev) => !prev);
  }

  function handleViewAnnouncement(id) {
    setAnnouncementId(id);
  }

  const { data, loading } = useQuery(FETCH_EMPLOYEE_ANNOUNCEMENTS, {
    variables: {
      page,
    },
  });

  return (
    <Stack p={'32px 24px'} gap={'16px'}>
      <Heading textTransform={'uppercase'}>News Feed</Heading>

      {loading ? (
        <Grid h={'70vh'} placeItems={'center'}>
          <SpinnerComponent />
        </Grid>
      ) : (
        <Stack gap={'8px'}>
          {data?.fetchEmployeeAnnouncements?.data?.length > 0 ? (
            <>
              {data?.fetchEmployeeAnnouncements?.data.map((announcement) => {
                return (
                  <SingleAnnouncement
                    key={announcement._id}
                    announcement={announcement}
                    openModal={toggleModal}
                    handleViewAnnouncement={handleViewAnnouncement}
                  />
                );
              })}

              <Pagination
                totalPages={data?.fetchEmployeeAnnouncements?.pageCount}
                page={page}
                setPage={setPage}
              />
            </>
          ) : (
            <EmptyState />
          )}
        </Stack>
      )}

      <SimpleModal showModal={showModal}>
        <ViewAnnouncement
          label={'Announcement'}
          cancelModal={toggleModal}
          announcement={getAnnouncement(data?.fetchEmployeeAnnouncements?.data, announcementId)}
        />
      </SimpleModal>
    </Stack>
  );
}
