import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeOptionsProvider } from 'context';
import { useToastify } from 'hooks/useToastify';
import { EDIT_EMPLOYEE_DETAILS, FETCH_SINGLE_EMPLOYEE_DETAILS, UPLOAD_PROFILE_PIC } from 'query';
import { EmployeeOnboardSchema } from 'schemas';
import { getItem } from 'utils';

import { ModalBackButton } from 'components';

import { Tabs } from '../../../../../components/shared/tabs';

import { Account } from './Tabs/Account';
import { Basics } from './Tabs/Basics';
import { Contacts } from './Tabs/Contacts';
import { Emergency } from './Tabs/Emergency';
import { Employment } from './Tabs/Employment';
import { useOnboardDetailsView } from './hook';

const formatIncomingDate = (dateVal) => {
  return dateVal?.substr(0, 10);
};

const isTesting = process.env.NODE_ENV === 'test';

export const OnboardingTabView = ({ setDone }) => {
  const userDetails = getItem('hrm_user');

  let userId;

  if (isTesting) {
    userId = 'mockId';
  } else {
    userId = userDetails._id;
  }
  const { data, refetch } = useQuery(FETCH_SINGLE_EMPLOYEE_DETAILS, {
    variables: {
      filters: {
        _id: userId,
      },
    },
  });
  const [employee, setEmployee] = React.useState({});

  const { tabs, setSelected, selectedButton, setPreviousTab, setNextTab } = useOnboardDetailsView();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: employee,
    resolver: yupResolver(EmployeeOnboardSchema),
  });
  const [imageFile, setImageFile] = React.useState([]);

  const { successToast, errorToast } = useToastify();

  const [editEmployee, { loading }] = useMutation(EDIT_EMPLOYEE_DETAILS);

  const [uploadProfilePic] = useMutation(UPLOAD_PROFILE_PIC, {
    refetchQueries: () => [
      {
        query: FETCH_SINGLE_EMPLOYEE_DETAILS,
      },
    ],
  });

  const onSubmit = async (data) => {
    const hire_date = data?.employmentInfo.hireDate
      ? new Date(data?.employmentInfo.hireDate).toISOString()
      : '';
    const dataToSend = {
      gender: data.gender.value || '',
      maritalStatus: data.maritalStatus.value || '',
      department: data.department.value,
      designation: data.designation.value,
      team: data.team.value || null,
      emergencyContact: {
        ...data?.emergencyContact,
        country: data?.emergencyContact?.country?.value,
        city: data?.emergencyContact?.city?.value,
        state: data?.emergencyContact.state.value,
      },
      bankAccount: {
        ...data?.bankAccount,
        accountType: data?.bankAccount?.accountType?.value || '',
        paymentType: data?.bankAccount?.paymentType?.value || '',
      },
      employmentInfo: {
        employmentType: data?.employmentInfo?.employmentType.value || '',
        hireDate: hire_date,
        workEmail: data?.employmentInfo.workEmail || '',
      },
      imageUrl: data?.imageUrl,
      notableAilment: data?.notableAilment,
      bloodGroup: data?.bloodGroup?.value,
      bloodType: data?.bloodType.value,
      address: {
        address: data?.address?.address,
        country: data?.address?.country.value,
        state: data?.address?.state.value,
        district: data?.address?.district.value,
      },
      isDisabled: data.isDisabled.value || null,
    };
    try {
      const response = await editEmployee({ variables: { data: dataToSend } });

      if (imageFile?.length) {
        await uploadProfilePic({
          variables: {
            file: imageFile[0],
          },
        });
      }
      successToast(response?.data?.editEmployeeDetails?.message);
      setDone(true);
    } catch (error) {
      const message = error?.response?.data?.message || 'Error Occured';
      errorToast(message);
    }
  };

  React.useEffect(() => {
    refetch();
    const userData = data?.fetchUsersFiltered?.data[0];

    const newData = {
      ...userData,
      dob: formatIncomingDate(userData?.dob),
      hireDate: formatIncomingDate(userData?.hireDate),
      bloodType: { label: userData?.bloodType || '', value: userData?.bloodType || '' },
      gender: { label: userData?.gender || '', value: userData?.gender || '' },
      maritalStatus: { label: userData?.maritalStatus || '', value: userData?.maritalStatus || '' },
      department: {
        label: userData?.department?.name || '',
        value: userData?.department?._id || '',
      },
      designation: {
        label: userData?.designation?.name || '',
        value: userData?.designation?._id || '',
      },
      team: { label: userData?.team?.name || '', value: userData?.team?._id || '' },
      employmentInfo: {
        ...userData?.employmentInfo,
        hireDate: formatIncomingDate(userData?.employmentInfo.hireDate),
        employmentType: {
          label: userData?.employmentInfo?.employmentType || '',
          value: userData?.employmentInfo?.employmentType || '',
        },
      },
      address: {
        address: userData?.address[0]?.address || '',
        country: {
          label: userData?.address[0]?.country || '',
          value: userData?.address[0]?.country || '',
        },
        district: {
          label: userData?.address[0]?.district || '',
          value: userData?.address[0]?.district || '',
        },
        state: {
          label: userData?.address[0]?.state || '',
          value: userData?.address[0]?.state || '',
        },
      },

      emergencyContact: {
        ...userData?.emergencyContact,
        country: {
          label: userData?.emergencyContact?.country,
          value: userData?.emergencyContact?.country,
        },
        city: {
          label: userData?.emergencyContact?.city,
          value: userData?.emergencyContact?.city,
        },
        state: {
          label: userData?.emergencyContact?.state,
          value: userData?.emergencyContact?.state,
        },
      },
      bankAccount: {
        ...userData?.bankAccount,
        paymentType: {
          label: userData?.bankAccount?.paymentType || '',
          value: userData?.bankAccount?.paymentType || '',
        },
        accountType: {
          label: userData?.bankAccount?.accountType || '',
          value: userData?.bankAccount?.accountType || '',
        },
      },
      isDisabled: {
        label: userData?.isDisabled || '',
        value: userData?.isDisabled || '',
      },
    };
    setEmployee(newData);
    reset(newData);
  }, [data]);

  React.useEffect(() => {
    const errorList = Object.entries(errors);
    if (errorList.length) {
      errorList.forEach(([, value]) => {
        const message = value.message;
        const innerList = Object.entries(value);
        if (!message && innerList.length) {
          innerList.forEach(([, { message }]) => {
            errorToast(message);
          });
        } else {
          errorToast(message);
        }
      });
    }
  }, [errors]);

  return (
    <>
      {!loading && (
        <Box data-testid="onb-tabs-view">
          <Header>
            <div className="header-title">
              {selectedButton === 'basics' ? (
                <p className="basics-header">{`Onboarding, ${employee?.firstName}`}</p>
              ) : (
                <Flex align="center">
                  <ModalBackButton onClick={setPreviousTab} />
                  <p className="user-full-name">
                    {employee.firstName} {employee.lastName}
                  </p>
                </Flex>
              )}
            </div>
            <Tabs tabs={tabs} setSelected={setSelected} selected={selectedButton} />
          </Header>
          <Content>
            {selectedButton === 'basics' && (
              <Basics
                register={register}
                control={control}
                setNextTab={setNextTab}
                imageFile={imageFile}
                setImageFile={setImageFile}
                logoUrl={employee?.imageUrl}
              />
            )}
            {selectedButton === 'employment' && (
              <EmployeeOptionsProvider>
                <Employment register={register} control={control} setNextTab={setNextTab} />
              </EmployeeOptionsProvider>
            )}
            {selectedButton === 'contacts' && (
              <Contacts register={register} control={control} setNextTab={setNextTab} />
            )}
            {selectedButton === 'emergency' && (
              <Emergency register={register} control={control} setNextTab={setNextTab} />
            )}
            {selectedButton === 'account' && (
              <Account
                register={register}
                control={control}
                loading={loading}
                handleSubmit={handleSubmit(onSubmit)}
              />
            )}
          </Content>
        </Box>
      )}
    </>
  );
};

const Header = styled.div`
  border-bottom: 1px solid var(--grey-150);
  width: 100%;
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 10;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  .header-title {
    margin-bottom: 56px;
    position: sticky;
    left: 0px;
  }

  .basics-header {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.0012em;
    color: #364469;
  }

  .user-full-name {
    margin-left: 24px;
    font-family: 'Karla';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.0012em;
    color: #364469;
  }
`;
const Content = styled.div`
  width: 100%;
  height: auto;
  padding-top: 2.4rem;
`;
