import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { GET_EMPLOYEE_ID } from 'query';
import { accountSchema, employmentSchema, kinSchema, personalSchema } from 'schemas';
import { createData } from 'tests/pages/employee/mock';

import { CustomButton, EmployeeFileUpload, ModalBackButton, RightModal } from '../../../components';
import { useCountriesStatesCities } from '../../../hooks';

import { OnboardingType } from './components';
import { useOnboardingHook } from './OnboardingHook';
import { FirstPage, FourthPage, SecondPage, ThirdPage } from './SingleOnboardingFormItem';
import { useSubmitForm } from './submitForm';

const isTesting = process.env.NODE_ENV === 'test';

const Onboarding = (props) => {
  const {
    onboardBulk,
    onboardSingle,
    countryCode,
    phone,
    currencyCode,
    formHeading,
    page,
    kincountryCode,
    getKinCountryCode,
    previousBtnAction,
    nextBtnAction,
    handleOnboardBulk,
    handleOnboardSingle,
    getCountryCode,
    getCurrencyCode,
    getPhoneNumber,
    setOnboardBulk,
    setOnboardSingle,
  } = useOnboardingHook();

  const { data: getEmployeeId } = useQuery(GET_EMPLOYEE_ID);

  const defaultValues = isTesting ? createData : { address: [{}, {}] };

  const schemaObj = {
    1: personalSchema,
    2: employmentSchema,
    3: accountSchema,
    4: kinSchema,
  };

  React.useEffect(() => {
    const staffId = getEmployeeId?.getEmployeeID?.data;
    if (staffId != null) {
      reset({ address: [{}, {}], staffId });
    }
  }, [getEmployeeId]);

  const {
    register: mainRegister,
    handleSubmit,
    reset: resetMain,
    control: mainControl,
    formState: { errors: mainErrors },
  } = useForm({ defaultValues: defaultValues });
  const {
    register,
    handleSubmit: submitFirstPage,
    control,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schemaObj[page]),
  });
  const closeModal = () => {
    props.setOnboardType(false);
    setOnboardBulk(false);
    setOnboardSingle(false);
  };

  const { onSubmit, loading } = useSubmitForm({
    currencyCode: isTesting ? { currency: 'Ghc' } : currencyCode,
    kincountryCode,
    countryCode,
    closeModal,
  });

  const submitForm = async (data) => {
    await onSubmit(data);
  };

  const handleOnboardBack = () => {
    setOnboardBulk(false);
    setOnboardSingle(false);
    reset();
  };

  const watchCountry = useWatch({ control: mainControl, name: `address.${0}.country` });
  const watchState = useWatch({ control: mainControl, name: `address.${0}.state` });
  const watchCountryContact = useWatch({ control: mainControl, name: `address.${1}.country` });
  const watchStateContact = useWatch({ control: mainControl, name: `address.${1}.state` });
  const watchKinCountry = useWatch({ control: mainControl, name: 'emergencyContact.country' });
  const watchKinState = useWatch({ control: mainControl, name: 'emergencyContact.state' });

  const { states: contactStates, countries: contactCountries } = useCountriesStatesCities({
    country: watchCountry,
    state: watchState,
    setValue,
    stateName: `address.${0}.state`,
  });

  const { states: secondcontactStates, countries: secondcontactCountries } =
    useCountriesStatesCities({
      country: watchCountryContact,
      state: watchStateContact,
      setValue,
      stateName: `address.${1}.state`,
    });

  const {
    states: kinStates,
    cities: kinCities,
    countries: allCountries,
  } = useCountriesStatesCities({
    country: watchKinCountry,
    state: watchKinState,
    setValue,
    stateName: 'emergencyContact.state',
    cityName: 'emergencyContact.city',
  });

  function submitPageOne(data) {
    resetMain((prev) => {
      return { ...prev, ...data };
    });
    nextBtnAction();
  }

  const colSpan = { base: 2, md: 1 };
  const showCreate = isTesting ? page === 1 : page === 4;

  return (
    <div data-testid="onboarding">
      <RightModal showModal={props.onboardType} title={'Add Employee'} closeModal={closeModal}>
        <OnboardingType onboardSingle={handleOnboardSingle} onboardBulk={handleOnboardBulk} />
      </RightModal>
      <RightModal
        showModal={onboardSingle}
        closeModal={closeModal}
        title={'Single Onboarding'}
        button={<ModalBackButton data-testid="onboard-back" onClick={handleOnboardBack} />}
        width={'76.5rem'}>
        <Box>
          <Box borderBottom="1px solid #F2F2F2" pb="0.8rem">
            <Text fontSize="2.4rem" color="#807F7F" mt="3.4rem">
              {formHeading}
            </Text>
          </Box>
          <div style={{ marginTop: '4rem' }}>
            {page === 1 && (
              <FirstPage
                register={register}
                phone={phone}
                countryCode={countryCode}
                getPhoneNumber={getPhoneNumber}
                getCountryCode={getCountryCode}
                colSpan={colSpan}
                control={control}
                errors={errors}
              />
            )}
            {page === 2 && (
              <SecondPage
                colSpan={colSpan}
                register={register}
                control={control}
                getCurrencyCode={getCurrencyCode}
                currencyCode={currencyCode}
                getValues={getValues}
                errors={errors}
              />
            )}
            {page === 3 && (
              <ThirdPage colSpan={colSpan} register={register} control={control} errors={errors} />
            )}
            {page === 4 && (
              <FourthPage
                control={mainControl}
                register={mainRegister}
                colSpan={colSpan}
                phone={phone}
                countries={contactCountries}
                contactStates={contactStates}
                secondcontactStates={secondcontactStates}
                secondcontactCountries={secondcontactCountries}
                allKinCountries={allCountries}
                kinStates={kinStates}
                citiesData={kinCities}
                countryCode={kincountryCode}
                getCountryCode={getKinCountryCode}
                errors={mainErrors}
              />
            )}
            <Flex justifyContent="center" mt="4.8rem" gap="8" mb={'4rem'}>
              {page > 1 && (
                <CustomButton
                  handleButtonClick={previousBtnAction}
                  h="4.8rem"
                  mW="13rem"
                  name="Previous"
                  variant="secondary"
                />
              )}
              {page < 4 && (
                <CustomButton
                  h="4.8rem"
                  mW="13rem"
                  type="button"
                  handleButtonClick={submitFirstPage(submitPageOne)}
                  name={'Next'}
                  variant={'primary'}
                />
              )}
              {showCreate && (
                <CustomButton
                  mW="21rem"
                  h="4.8rem"
                  type="button"
                  handleButtonClick={handleSubmit(submitForm)}
                  name="Create Account"
                  loading={loading}
                />
              )}
            </Flex>
          </div>
        </Box>
      </RightModal>
      <RightModal
        showModal={onboardBulk}
        closeModal={closeModal}
        title={'Bulk Onboarding'}
        button={<ModalBackButton data-testid="modal-back-btn" onClick={handleOnboardBack} />}
        width={'76.5rem'}>
        <EmployeeFileUpload closeModal={closeModal} />
      </RightModal>
    </div>
  );
};

export { Onboarding };
