import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_SINGLE_LEAVE_REQUEST } from 'query';

const initialState = {
  showModal: false,
  modalType: '',
};
function modalReducer(state, action) {
  return { ...state, ...action };
}

function EmployeeLeaveHook({ id }) {
  const [{ showModal, modalType }, setModalState] = React.useReducer(
    modalReducer,
    () => initialState
  );

  const { data, loading, refetch } = useQuery(FETCH_SINGLE_LEAVE_REQUEST, {
    variables: { leaveId: id },
  });

  const leaveRequestData = data?.fetchSingleRequest?.data;
  // const overlappingRequests = leaveRequestData?.overlappingRequests;
  const approvalFlow = leaveRequestData?.approvalFlow;
  const reason = leaveRequestData?.reasonForDenial;
  const summary = {
    'Leave Type': leaveRequestData?.leaveTypeId?.name,
    'Balance Before': `${leaveRequestData?.balance} ${leaveRequestData?.durationType || ''}`,
    'Count As': `${leaveRequestData?.count} ${leaveRequestData?.durationType || ''}`,
    Description: leaveRequestData?.description,
  };

  const date = new Date(leaveRequestData?.startDate) || new Date();
  const toDate = new Date(leaveRequestData?.endDate) || new Date();

  const editAction = () => {
    setModalState({ showModal: true, modalType: 'edit' });
  };
  const deleteAction = () => {
    setModalState({ showModal: true, modalType: 'delete' });
  };

  return {
    setModalState,
    editAction,
    refetch,
    deleteAction,
    showModal,
    modalType,
    loading,
    approvalFlow,
    reason,
    summary,
    date,
    toDate,
    leaveRequestData,
  };
}

export { EmployeeLeaveHook };
