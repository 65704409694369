import React from 'react';
import { Grid } from '@chakra-ui/react';

import { InputBox } from 'components';

import { SetMetrics } from './SetMetrics';
import { Container, Heading, InputFields, Section } from './styles';

const GoalDetails = ({ state, setState, validateGoals }) => {
  const typeDate = [
    { label: 'Shared', value: 'shared' },
    { label: 'Individual', value: 'individual' },
  ];

  const handleChange = (e, id) => {
    const { name, value } = e.target;

    if (name === 'metricTitle' || name === 'metricDescription') {
      const newArray = state?.milestones.map((milestone, index) => {
        if (index === id) {
          return { ...milestone, [name]: value };
        } else {
          return milestone;
        }
      });
      setState((prevState) => ({
        ...prevState,
        milestones: newArray,
      }));
    } else if (name === 'startDate' || name === 'dueDate') {
      setState((prevState) => ({
        ...prevState,
        schedule: { ...prevState?.schedule, [name]: value },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Container>
      <Heading>Goal Details</Heading>
      <InputFields>
        <InputBox
          id={'title'}
          placeholder={'Enter a title for your goals'}
          handleChange={handleChange}
          label={'Title'}
          required={true}
          value={state?.title}
          dataTestId="title-input"
        />
        <InputBox
          placeholder={'Enter a description for your goals'}
          id={'description'}
          handleChange={handleChange}
          label={'Description'}
          value={state?.description}
        />
        <Grid gridTemplateColumns={'1fr 1fr'} gap={'24px'}>
          <InputBox
            placeholder={'Shared'}
            id={'type'}
            handleChange={(e) =>
              handleChange({
                target: {
                  name: 'type',
                  value: e.value,
                },
              })
            }
            label={'Type'}
            type={'dropdown'}
            dropdown={typeDate}
            value={state?.type}
          />
        </Grid>
        <Section>
          <h2>Schedule</h2>
          <Grid gridTemplateColumns={'1fr 1fr'} gap={'24px'}>
            <InputBox
              id={'startDate'}
              placeholder={'Select a start date'}
              label={'Start Date'}
              type={'date'}
              required={true}
              handleChange={handleChange}
              value={state?.schedule?.startDate}
              dataTestId="startDate"
            />
            <InputBox
              id={'dueDate'}
              placeholder={'Select a start date'}
              label={'Due Date'}
              type={'date'}
              required={true}
              handleChange={handleChange}
              value={state?.schedule?.dueDate}
              dataTestId="dueDate-input"
            />
          </Grid>
        </Section>
        <SetMetrics
          handleChange={handleChange}
          state={state?.milestones}
          setState={setState}
          validateGoals={validateGoals}
        />
      </InputFields>
    </Container>
  );
};

export { GoalDetails };
