import React, { createRef, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import photoIcon from '../../../../../assets/icons/photo.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';

const dropzoneRef = createRef();

const PhotoUpload = ({ setImageFile, imageFile, logoUrl, employee }) => {
  const previewImage = useRef(null);

  const handleImageDrop = (acceptedFile) => {
    setImageFile(acceptedFile);
    const [file] = acceptedFile;
    if (file) {
      const reader = new FileReader();
      const { current } = previewImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImageFile([]);
  };

  const acceptedImageTypes = {
    'image/apng': [],
    'image/bmp': [],
    'image/jpeg': [],
    'image/pjpeg': [],
    'image/png': [],
    'image/svg+xml': [],
    'image/webp': [],
  };
  return (
    <Box>
      <Flex mt="4rem" justifyContent="space-between" alignItems="flex-start">
        <Dropzone
          ref={dropzoneRef}
          onDrop={(acceptedFile) => handleImageDrop(acceptedFile)}
          accept={acceptedImageTypes}
          className={'dropZone'}>
          {({ getRootProps, getInputProps }) => (
            <Flex
              {...getRootProps()}
              cursor="pointer"
              direction="column"
              alignItems="center"
              justifyContent="center"
              borderRadius="0.2rem"
              height="11rem"
              width="36.9rem"
              backgroundColor="#FCFCFD"
              border="1px dashed #DEE2E8">
              {imageFile[0] ? (
                <TextWrapper>
                  <Text className={'image-name'}>{imageFile[0].name}</Text>
                </TextWrapper>
              ) : (
                <Flex direction="column" alignItems="center">
                  <input {...getInputProps()} />
                  <Image textAlign="center" src={photoIcon} />
                  <Text fontWeight="500" mt="1.3rem" lineHeight="2.4rem" color="#C0C8D3">
                    Drag & drop or
                    <Text ml="0.8rem" textDecoration="underline" as="span" color="blue.500">
                      browse
                    </Text>
                  </Text>
                </Flex>
              )}
            </Flex>
          )}
        </Dropzone>
        <div>
          <button type="button" onClick={() => handleRemoveImage()}>
            <Flex alignItems="center" cursor="pointer">
              <Image src={trashIcon} height="1.5rem" />
              <Text ml="1rem" color="#FA4A4A" fontSize="1.4rem" textDecoration="underline">
                Delete
              </Text>
            </Flex>
          </button>
          <Flex h="83px" w="100px" justifyContent={'center'} alignItems="center" padding="0.4rem">
            {employee ? (
              <Image src={logoUrl || imageFile[0]} ref={previewImage} objectFit="cover" />
            ) : (
              <Image src={logoUrl || imageFile[0]} ref={previewImage} objectFit="cover" />
            )}
          </Flex>
        </div>
      </Flex>
    </Box>
  );
};

const TextWrapper = styled.div`
  .image-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
  }
`;

export { PhotoUpload };
