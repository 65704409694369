import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_ALL_EMPLOYEES } from 'query';
import { CREATE_QUERY, FETCH_QUERIES } from 'query/admin/query';
import { getItem } from 'utils';

import { BaseDropdown, BaseInput, CustomButton, NameWithImage } from 'components';

import { useToastify } from '../../../../../hooks/useToastify';
import { modifyUsersData } from '../../helpers';

const isTesting = process.env.NODE_ENV === 'test';
export function IssueQuery({ closeModal }) {
  let defaultData = '';
  const { successToast } = useToastify();
  const [createQuery, { loading }] = useMutation(CREATE_QUERY, {
    refetchQueries: [
      {
        query: FETCH_QUERIES,
        variables: {
          filter: {
            keyword: '',
          },
          page: 1,
        },
      },
    ],
  });
  const { data: allEmployees } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      filters: {
        isDefaultAdmin: false,
      },
    },
  });
  if (isTesting) {
    defaultData = {
      subject: 'Career Development',
      content: 'Which course would you like to take?',
      issuer: '637f4aa4976df8f85b7aca02',
      recepient: undefined,
    };
  }
  const { register, handleSubmit, control } = useForm({
    defaultValues: defaultData,
  });

  let issuerId;

  const issuer = getItem('hrm_user');
  if (isTesting) {
    issuerId = '637f4aa4976df8f85b7aca02';
  } else {
    issuerId = issuer._id;
  }

  async function onSubmitForm(data) {
    const res = await createQuery({
      variables: {
        data: {
          subject: data?.subject,
          content: data?.content,
          issuer: issuerId,
          recepient: data?.employee?.value,
        },
      },
    });

    if (res.data.createQuery.status === 201) {
      closeModal();
      successToast(res.data.createQuery.message);
    }
  }

  return (
    <IssueQueryWrapper>
      <form onSubmit={handleSubmit(onSubmitForm)} style={{ display: 'grid', gap: '24px' }}>
        <Stack
          p={'16px 24px'}
          border={'solid 1px var(--grey-100)'}
          borderRadius={'4px'}
          gap={'32px'}>
          <Heading>Query</Heading>

          <BaseInput
            {...register('subject', { required: true })}
            label={'Subject'}
            placeholder={'Enter Subject'}
            isRequired
          />
          <BaseInput
            {...register('content')}
            label={'Content'}
            placeholder={'Enter Query'}
            type={'textarea'}
          />

          <Controller
            defaultValue=""
            control={control}
            name={`employee`}
            render={({ field }) => (
              <BaseDropdown
                label={'Select Employee'}
                {...field}
                placeholder={'Select from options'}
                options={modifyUsersData(allEmployees?.fetchUsersFiltered?.data)}
                w={'100%'}
              />
            )}
          />

          <Flex alignItems={'center'} gap={'32px'}>
            <Text fontWeight={'600'} color={'var(--grey-500)'}>
              Issued by:{' '}
            </Text>
            <NameWithImage data={issuer} />
          </Flex>
        </Stack>
        <Flex justifyContent={'flex-end'} gap={'8px'}>
          <CustomButton
            name={'Cancel'}
            type={'button'}
            onClick={closeModal}
            variant={'black--outline'}
            mW={'94px'}
            h={'43px'}
          />
          <CustomButton
            name={'Save'}
            type={'submit'}
            variant={'black'}
            mW={'80px'}
            h={'43px'}
            loading={loading}
          />
        </Flex>
      </form>
    </IssueQueryWrapper>
  );
}

const IssueQueryWrapper = styled.div`
  padding: 24px;

  @media screen and (min-width: 400px) {
    width: 350px;
  }
  @media screen and (min-width: 450px) {
    width: 400px;
  }

  @media screen and (min-width: 600px) {
    width: 550px;
  }
  @media screen and (min-width: 700px) {
    width: 560px;
  }
  @media screen and (min-width: 800px) {
    width: 700px;
  }
  @media screen and (min-width: 900px) {
    width: 750px;
  }
`;
