/**
 * throw an error if request failed silently
 * ie. response object contains error status
 */
export function handleGraphQLResponse(result) {
  const responseObject = getGraphQLResponse(result);
  if (responseObject.status >= 400) throw new Error(responseObject.message);
}

/**
 * get unwrapped response object
 * @param {*} result - response object containing query name
 * @returns actual response object
 */
export function getGraphQLResponse(result) {
  return result ? Object.values(result)[0] : null;
}

export function conditional(condition, node) {
  return condition ? node : null;
}
