import styled from '@emotion/styled';

const Wrapper = styled.div`
  font-size: 16px;
  line-height: 24px;

  .main {
    position: relative;
    max-width: 910px;
    margin: auto;
    width: 100%;
    padding: 2.4rem;
    color: #23273b;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 768px) {
    margin-block: 3.6rem 10.4rem;

    .main {
      padding: 5.2rem;
    }
  }
`;

const AssessmentHeaderRoot = styled.header`
  display: grid;
  grid: auto-flow / 1fr;
  gap: 1.6rem;
  letter-spacing: -0.005em;
  align-items: center;

  .assessment-title {
    color: var(--Black---HRMs, #23273b);
    font-size: 32px;
    font-weight: 800;

    .label {
      color: #272932;
      font-weight: 700;
      margin-inline-end: 0.8rem;
    }

    .value {
      color: var(--Grey, #999fa3);
      font-size: 16px;
      font-weight: 400;
    }
  }

  .assessment-duration {
    border-radius: 6px;
    border: 1px solid #3a71d1;
    background: #3e7eee;
    color: #fff;
    padding: 9px 28px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }

  .assessment-duration .value {
    font-size: 24px;
    font-weight: 800;
    width: 134px;
  }

  @media (min-width: 768px) {
    grid: 1fr / repeat(2, max-content);
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    .assessment-duration .value {
      width: 100%;
    }
  }
`;

const SectionRoot = styled.section`
  display: grid;
  gap: 24px;
  border-radius: 12px;
  background: #fafcff;
  padding: 28px 47px 28px 23px;

  .section__header {
    padding: 16px 28px;
    border-radius: 8px;
    background: #f1f5fb;
    color: var(--Black---HRMs, #23273b);
    font-size: 24px;
    font-weight: 700;

    &:after {
      content: '';
      position: absolute;
      bottom: 1rem;
      height: 1px;
      width: 100%;
      background-color: #f0f2f5;
    }
  }

  h2 {
    color: #23273b;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
  }

  .question {
    border-bottom: 1px solid #f2f2f2;

    &__body:first-letter {
      text-transform: uppercase;
    }

    .answer {
      margin-block: 1.6rem;
    }
  }
`;

const OptionsInputRoot = styled.div`
  display: grid;
  grid: auto-flow / 1fr;
  gap: 8px;

  input[type='radio'],
  input[type='checkbox'] {
    --outer: 2rem;
    position: relative;
    display: inline-grid;
    place-content: center;
    width: var(--outer);
    height: var(--outer);
    appearance: none;
    border: 1px solid #d0d5dd;
    background: #f2f4f7;

    &:checked {
      --inner: 1.2rem;
      border-color: #3e7eee;

      &:after {
        content: '';
        position: relative;
        width: var(--inner);
        height: var(--inner);
        border: 1px solid #3e7eee;
        background: #eaf1ff;
      }
    }
  }

  input[type='radio'] {
    border-radius: 50%;

    &:checked {
      &:after {
        border-radius: 50%;
      }
    }
  }

  input[type='checkbox'] {
    border-radius: 3px;

    &:checked {
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='RoyalBlue' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2_2)'%3E%3Cpath d='M10.3035 0.264248C10.5718 -0.0513572 11.0451 -0.089735 11.3607 0.17853C11.6763 0.446795 11.7147 0.920119 11.4464 1.23572L5.07143 8.7357C4.79896 9.05625 4.31615 9.09007 4.00171 8.81055L0.626705 5.81054C0.317118 5.53536 0.289232 5.0613 0.56442 4.75171C0.83961 4.44213 1.31366 4.41424 1.62325 4.68942L4.42514 7.17999L10.3035 0.264248Z' fill='RoyalBlue'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2_2'%3E%3Crect width='12' height='9' fill='RoyalBlue'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        width: var(--outer);
        height: var(--outer);
        display: grid;
        place-content: center;
        border-radius: inherit;
      }
    }
  }

  .option {
    display: grid;
    grid: 1fr / 2rem auto;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
`;

const AssessmentControlsRoot = styled.section`
  --primary-blue: #3e7eee;
  display: grid;
  justify-content: flex-end;
  grid: auto-flow 4.3rem / 1fr;
  gap: 1rem;
  margin-top: 3rem;

  button[class*='btn-'] {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    place-content: center;
    padding-inline: 3.2rem;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    &:hover {
      background-color: rgba(62, 126, 238, 0.05);
    }

    &:disabled {
      //--primary-blue: #676767;
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    }
  }

  button.btn-empty {
    border-radius: 4px;
    border: 1.5px solid #3e7eee;
    background: rgba(62, 126, 238, 0.1);
    color: #3e7eee;
  }

  button.btn-filled {
    background: var(--primary-blue);
    color: #fff;

    &:hover {
      background-color: rgb(89, 142, 238);
    }
  }

  @media (min-width: 768px) {
    grid: 4.3rem / repeat(3, max-content);
  }
`;

export { AssessmentControlsRoot, AssessmentHeaderRoot, OptionsInputRoot, SectionRoot, Wrapper };
