import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex, Grid, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_ALL_TEAMS } from 'query';

import { EmptyState, SpinnerComponent } from 'components';

import teamicon from './images/teamicon.svg';
import { ReviewTemplates } from './review-templates';

const EmployeeContainer = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  width: 183px;
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transition: all 0.4s ease; /* zoom in */
    transform: translate3D(0, -1px, 0) scale(1.03);
  }
  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.0012em;
    color: #23273b;
    flex: none;
    order: 1;
    flex-grow: 0;
    text-transform: capitalize;
  }
  img {
    width: 48px;
  }
`;

const isTesting = process.env.NODE_ENV === 'test';

export function TemplatesView() {
  const [teams, setTeams] = useState([]);
  const [showTemplates, setShowTemplates] = useState(false);

  const { data: teamsData, loading } = useQuery(FETCH_ALL_TEAMS, {});

  const fetchTeams = teamsData?.fetchTeams?.data;

  function gotoTemplates(id) {
    setShowTemplates(id);
  }

  useEffect(() => {
    setTeams(fetchTeams);
  }, [fetchTeams]);

  if (loading && !isTesting) {
    return (
      <Grid minH="20rem" placeContent="center" mt="20rem">
        <SpinnerComponent data-testid="loading" />
      </Grid>
    );
  }

  const EmployeeData = teams?.map((item, index) => {
    return (
      <>
        <EmployeeContainer
          data-testid="employee"
          key={index}
          onClick={() => gotoTemplates(item?._id)}>
          <Flex
            justifyContent={'center'}
            borderRadius={'4px'}
            border={'1px solid #F2F2F2'}
            width={'64px'}
            height={'64px'}
            mb={'16px'}>
            <Image src={teamicon} alt={item._id} />
          </Flex>
          <h1>{item?.name}</h1>
        </EmployeeContainer>
        {showTemplates && (
          <ReviewTemplates
            team={item}
            showModal={showTemplates === item._id}
            setShowModal={setShowTemplates}
          />
        )}
      </>
    );
  });

  return (
    <Box>
      {teams?.length ? (
        <Flex gap="48px" flexWrap={'wrap'}>
          {EmployeeData}
        </Flex>
      ) : (
        <EmptyState message={'No Teams Found'} />
      )}
    </Box>
  );
}
