import React, { createContext, useContext, useState } from 'react';

const ScoreCardContext = createContext();

function ScoreCardProvider(props) {
  const [list, setList] = useState(props.initialValue);

  return (
    <ScoreCardContext.Provider value={{ list, setList }}>
      {props.children}
    </ScoreCardContext.Provider>
  );
}

function useScoreCardContext() {
  return useContext(ScoreCardContext);
}

export { ScoreCardContext, ScoreCardProvider, useScoreCardContext };
