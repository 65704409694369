import React from 'react';
import { useQuery } from '@apollo/client';
import { Text } from '@chakra-ui/react';

import { FETCH_DASHBOARD_SUMMARY } from '../../../../query';
import { SpinnerComponent } from '../../../common';
import { CardContainer, CardWrapper } from '../styles';

import { BirthdayCard } from './BirthdayCard';

export const DashboardCards = () => {
  const { data, loading } = useQuery(FETCH_DASHBOARD_SUMMARY);

  if (loading) return <SpinnerComponent h="9.2rem" />;

  let {
    designations,
    newEmployees: newHires,
    totalEmployees: employees,
    leaves,
    departments,
    events,
  } = data.fetchDashboardSummary.data;

  const totalBirthdays = events.birthdays.length;

  return (
    <CardWrapper data-testid="dashboard-card-wrapper">
      <CardContainer data-testid="dashboard-card-container">
        <Text>{employees ?? 0}</Text>
        <span>Total Employees</span>
      </CardContainer>
      <CardContainer data-testid="dashboard-card-container">
        <Text>{newHires ? newHires : 0}</Text>
        <span>New Employee{newHires?.length === 1 ? '' : 's'}</span>
      </CardContainer>
      <CardContainer data-testid="dashboard-card-container">
        <Text>{leaves ?? 0}</Text>
        <span>On-Leave</span>
      </CardContainer>
      <CardContainer data-testid="dashboard-card-container">
        <Text>{departments ?? 0}</Text>
        <span>Department{departments?.length === 1 ? '' : 's'}</span>
      </CardContainer>
      <CardContainer data-testid="dashboard-card-container">
        <Text>{designations ?? 0}</Text>
        <span>Designation{designations?.length === 1 ? '' : 's'}</span>
      </CardContainer>
      <div data-testid="dashboard-card-container">
        <BirthdayCard totalBirthdays={totalBirthdays} />
      </div>
    </CardWrapper>
  );
};
