import React from 'react';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatDate } from 'utils';

import { HeadingText, LabelText, PText } from '../CandidateInfoComponents';

export const Details = ({ user }) => {
  const BasicInfo = () => {
    return user?.applicationData?.map((data) => {
      return (
        data.type !== 'Section' &&
        data.type !== 'Attachment' && (
          <GridItem key={data.name}>
            <Flex direction={'column'} gap="8px">
              <LabelText labelText={data.name} />
              {data.type === 'Date Picker' ? (
                <PText pText={formatDate(JSON.parse(data.value)) || 'N/A'} />
              ) : (
                <PText pText={JSON.parse(data.value) || 'N/A'} />
              )}
            </Flex>
          </GridItem>
        )
      );
    });
  };

  return (
    <>
      <BasicInformationRoot>
        <HeadingText headingText={'Basic Information'} />
        <hr />
        <Grid className="basic-info-grid">
          <BasicInfo />
        </Grid>
      </BasicInformationRoot>
      {user?.applicationData?.map((data) => {
        return (
          data.type === 'Section' && (
            <OtherDetailsRoot key={data.name}>
              <HeadingText headingText={data.name} />
              <hr />
              <Grid className="professional-info-grid">
                {data?.children?.map((subdata) => {
                  return (
                    <GridItem key={subdata.name}>
                      <Flex direction={'column'} gap="8px">
                        <LabelText labelText={subdata.name} />
                        <PText pText={JSON.parse(subdata.value) || 'N/A'} />
                      </Flex>
                    </GridItem>
                  );
                })}
              </Grid>
            </OtherDetailsRoot>
          )
        );
      })}
    </>
  );
};

const BasicInformationRoot = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 16px;

  hr {
    margin: 12px 0;
  }

  .basic-info-grid {
    grid-template-columns: 218px 218px;
    grid-gap: 32px;
    @media (min-width: 1200px) {
      gap: 32px;
      grid-template-columns: 218px 218px;
    }
  }
`;
const OtherDetailsRoot = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;

  hr {
    margin: 12px 0;
  }

  .professional-info-grid {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    @media (min-width: 1200px) {
      gap: 32px;
      grid-template-columns: 218px 218px;
    }
  }
`;
