import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { FETCH_JOBS } from '../../../../query';

function useJobsView() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  let tabs = [
    { displayName: `All`, value: 'all' },
    { displayName: `Active`, value: 'active' },
    { displayName: `Closed`, value: 'closed' },
    {
      displayName: `Draft`,
      value: 'draft',
    },
  ];

  let sortingList = [
    { label: 'Title', value: 'title' },
    { label: 'Department', value: 'department' },
    { label: 'City', value: 'city' },
  ];

  let [selectedButton, setSelectedButton] = useState('all');
  const [sortedField, setSortedField] = useState('title');
  let [status, setStatus] = useState('');
  let [jobs, setJobs] = useState();
  let [length, setLength] = useState();
  const [errorMessage, setErrorMessage] = useState(null);

  const setSelected = (tab) => {
    setSelectedButton(tab);
    if (tab === 'all') {
      setStatus();
    } else {
      setStatus(tab);
    }
  };

  const {
    data: jobsData,
    loading,
    refetch: refetchJobs,
  } = useQuery(FETCH_JOBS, {
    variables: {
      filter: { ...(status ? { status: status } : {}) },
      page: page,
    },
    fetchPolicy: 'network-only',
    onError: (err) => {
      const errorResponse = err.graphQLErrors.find((item) => item.status === 403);
      setErrorMessage(errorResponse.message);
    },
  });

  const hasNextPage = useMemo(() => jobsData?.fetchJobs?.hasNextPage);
  function responseToNull() {
    if (errorMessage) return;
    const fetchjobs = jobsData?.fetchJobs?.data;
    const totalPages = Math.ceil(length / 10);

    return {
      fetchjobs,
      hasNextPage,
      totalPages,
    };
  }

  useEffect(() => {
    refetchJobs();
  }, [selectedButton]);

  const sortJobs = () => {
    if (errorMessage) return;
    if (sortedField) {
      const newState = responseToNull()
        ?.fetchjobs?.slice()
        .sort((a, b) => {
          if (sortedField !== 'department') {
            if (a[sortedField]?.toLowerCase() < b[sortedField]?.toLowerCase()) {
              return -1;
            }
            if (a[sortedField]?.toLowerCase() > b[sortedField]?.toLowerCase()) {
              return 1;
            }
          } else {
            if (a.department?.name.toLowerCase() < b.department?.name.toLowerCase()) {
              return -1;
            }
            if (a.department?.name.toLowerCase() > b.department?.name.toLowerCase()) {
              return 1;
            }
          }
          return 0;
        });
      setJobs(newState);
    }
  };

  useEffect(() => {
    responseToNull();
    sortJobs();
    if (!errorMessage) return setLength(jobsData?.fetchJobs.totalCount);
  }, [sortedField, selectedButton, errorMessage, jobsData]);

  function addJob() {
    navigate('/admin/jobs/add');
  }

  return {
    tabs,
    setSelected,
    selectedButton,
    jobs,
    length,
    sortingList,
    loading,
    setSortedField,
    addJob,
    totalPages: responseToNull.totalPages,
    page,
    setPage,
    refetchJobs,
    hasNextPage: responseToNull.hasNextPage,
    errorMessage,
  };
}

export { useJobsView };
