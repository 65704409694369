import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { transformAPIDetails } from 'utils';

import person from '../../../../assets/icons/person.svg';
import { useToastify } from '../../../../hooks/useToastify';
// import { Grid } from '@chakra-ui/react';
// import { SpinnerComponent } from '../../../../components';
import { EDIT_JOB, FETCH_JOBS, FETCH_SINGLE_JOB, FETCH_TIMELINE } from '../../../../query';

function usePublishedJob() {
  const { id } = useParams();
  const [timelineData, setTimelineData] = useState();

  let [selectedButton, setSelectedButton] = useState('jobDetails');
  let [jobDetails, setJobDetails] = useState();
  const { successToast, errorToast } = useToastify();
  const [updateJob] = useMutation(EDIT_JOB);

  let tabs = [
    { displayName: 'Job Details', value: 'jobDetails' },
    { displayName: 'Timeline & Notes', value: 'timeline' },
    { displayName: 'Settings', value: 'settings' },
  ];

  let options = [
    { label: 'Closed', value: 'closed' },
    { label: 'Active', value: 'active' },
    { label: 'Draft', value: 'draft' },
  ];

  const {
    data: singleJobData,
    refetch: refetchJobDetails,
    loading,
  } = useQuery(FETCH_SINGLE_JOB, {
    variables: {
      filters: {
        _id: id,
      },
    },
  });

  const {
    data: timeline,
    loading: loadingTimeline,
    refetch: refetchTimeLine,
  } = useQuery(FETCH_TIMELINE, {
    variables: {
      jobId: id,
    },
  });

  const fetchjob = singleJobData?.fetchSingleJob.data;
  const transformed = transformAPIDetails({ ...fetchjob } || {});

  const fetchtimeline = timeline?.fetchTimeline.data;

  useEffect(() => {
    setTimelineData(fetchtimeline);
  }, [fetchtimeline]);

  const onUpdate = async (status) => {
    try {
      const res = await updateJob({
        variables: {
          filter: {
            _id: id,
          },
          data: {
            status: status,
          },
        },
        refetchQueries: [
          {
            query: FETCH_JOBS,
            variables: {
              filters: {},
              page: 1,
            },
          },
        ],
      });

      const { data } = res;

      if (data && data.updateJob.status === 200) {
        successToast('Job was edited sucessfully');
      } else errorToast(data.updateJob.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const setSelected = (tab) => {
    setSelectedButton(tab);
  };

  useEffect(() => {
    setSelected(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    setJobDetails(fetchjob);
  }, [fetchjob]);

  return {
    tabs,
    setSelected,
    selectedButton,
    jobDetails,
    loading,
    loadingTimeline,
    timelineData,
    refetchTimeLine,
    person,
    options,
    transformed,
    onUpdate,
    refetchJobDetails,
  };
}

export { usePublishedJob };
