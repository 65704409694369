export const AssetReportcolumn = [
  {
    Header: 'Asset Name',
    accessor: 'col1',
  },

  {
    Header: 'Asset Category ',
    accessor: 'col2',
  },
  {
    Header: 'Serial Number ',
    accessor: 'col3',
  },
  {
    Header: 'Notes ',
    accessor: 'col4',
  },
  {
    Header: 'Cost ',
    accessor: 'col5',
  },
  {
    Header: 'Assigned To ',
    accessor: 'col6',
  },
  {
    Header: 'Date Assigned ',
    accessor: 'col7',
  },
];

export const ReportTableColumns = [
  {
    Header: ' Training Name',
    accessor: 'col1',
  },
  {
    Header: ' Employee Enrolled',
    accessor: 'col2',
  },
  {
    Header: 'Type',
    accessor: 'col3',
  },
  {
    Header: 'Due Date(s)',
    accessor: 'col4',
  },
  {
    Header: 'Completion Date',
    accessor: 'col5',
  },

  {
    Header: ' Cost',
    accessor: 'col6',
  },

  {
    Header: 'Status',
    accessor: 'col7',
  },
  {
    accessor: 'col8',
  },
];
