import React from 'react';
import { useMutation } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { FETCH_ALL_LEAVE_REQUEST, FORCE_UPDATE, UPDATE_LEAVE } from 'query';

import { CustomButton } from 'components';

const ApprovePrompt = ({ cancel, leaveId, force, refetch }) => {
  const [changeLeaveStatus, { loading: editLoading }] = useMutation(UPDATE_LEAVE);
  const [forceUpdateStatus, { loading }] = useMutation(FORCE_UPDATE);

  const { errorToast, successToast } = useToastify();

  const approveLeave = async () => {
    const date = new Date().toISOString();
    const toSend = {
      date: date,
      status: 'approved',
    };

    const variables = {
      data: toSend,
      filters: {
        _id: leaveId,
      },
    };

    try {
      const response = force
        ? await forceUpdateStatus({
            variables: variables,
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: FETCH_ALL_LEAVE_REQUEST,
                variables: {
                  filter: {},
                  page: 1,
                },
              },
            ],
          })
        : await changeLeaveStatus({
            variables: variables,
          });
      if (force) {
        const res = response.data.forceApproveOrDenyLeaveRequest;
        const { status, message } = res;
        status === 200 ? successToast(message) : errorToast('Error occured');
      } else {
        const res = response.data.leaveRequestApproval;
        const { status, message } = res;
        status === 403
          ? errorToast('Sorry, you currently do not have permission on this approval flow')
          : successToast(message);
      }
    } catch (error) {
      return error;
    } finally {
      refetch();
      cancel({ showModal: false });
    }
  };

  return (
    <ApproveRoot data-testid="approve-prompt">
      <h2>Are you sure?</h2>
      <p>
        {force
          ? 'This action will override any approval flow steps and immediately approve leave request'
          : 'This action will approve the leave for that flow'}
      </p>

      <Flex justifyContent={'flex-end'} gap="0.8rem" mt="6.4rem">
        <CustomButton
          onClick={() => cancel({ showModal: false })}
          name="Cancel"
          variant="black--outline"
          mW="9.5rem"
          h="4.3rem"
        />
        <CustomButton
          onClick={approveLeave}
          name={force ? 'Force Approve' : 'Approve'}
          variant="black"
          mW="14.5rem"
          h="4.3rem"
          loading={editLoading || loading}
        />
      </Flex>
    </ApproveRoot>
  );
};

const ApproveRoot = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 1.6rem 2.4rem;
  border: 1px solid var(--grey-150);
  border-radius: 0.4rem;

  h2 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.117188px;
    color: var(--black);
    margin-bottom: 1.6rem;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: var(--black);
  }
`;

export { ApprovePrompt };
