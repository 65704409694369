import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ToggleSwitch } from 'pages/admin/leave-management/leave-settings/components/ToggleButton';
import { FETCH_ALL_ADMINS } from 'query';

import { EmptyState, Pagination, Search, SpinnerComponent } from 'components';

import close from '../../../../assets/icons/thin_close.svg';
import { Button } from '../../jobs';

import { EmployeeCard } from './EmployeeCard';

const Column = styled.div`
  width: 50%;
  overflow: auto;
  height: calc(90vh - 310px);
`;

const EmployeeCards = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 90%;
  overflow: scroll;
`;

const Modal = styled.div`
  width: 930px;
  height: 80%;
  background: #ffffff;
  height: 90%;
  border-radius: 4px;
  padding: 24px;
  overflow-x: scroll;

  .grid {
    width: 100%;
    height: 100%;
    padding: 24px;
    grid-gap: 24px;
  }

  .row {
    background: #ffffff;
    z-index: 1;
    align-items: center;

    .column-head {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.117188px;
      color: #83889d;
    }
  }

  .heading {
    margin: 0 0 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    letter-spacing: -0.117188px;
    color: #23273b;

    img {
      width: 16px;
    }
  }

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  @media only screen and (max-width: 800px) {
    width: 95%;

    .grid {
      width: 930px;
    }
  }
`;

const Overlay = styled(Flex)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(35, 39, 59, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  align-items: center;
  padding: 0 2.4rem;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const AssignEmployeesModal = (props) => {
  const employee = [
    {
      _id: '637f5e3ec4189bfab704ce5d',
      firstName: 'David',
      lastName: 'Roe',
      imageUrl: close,
    },
    {
      _id: '637f5e3ec4189bfab704cd5d',
      firstName: 'Daid',
      lastName: 'Roe',
      imageUrl: null,
    },
    {
      _id: '637f5e3ec4189bfab794ce5d',
      firstName: 'vid',
      lastName: 'Roe',
      imageUrl: null,
    },
  ];

  const [search, setSearch] = useState('');
  const [searchString, setSearchString] = useState(null);
  const [page, setPage] = useState(1);

  const [employees, setEmployees] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState(props?.assigned || []);
  const length = props?.assigned?.length;
  const isTesting = process.env.NODE_ENV === 'test';

  const { data, loading } = useQuery(FETCH_ALL_ADMINS, {
    variables: {
      filter: {
        searchString,
      },
      page,
    },
  });
  const employeesData = data?.fetchAllAdmins?.data;
  const totalPages = data?.fetchAllAdmins?.pageCount;

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const handleAdd = (employee) => {
    if (!selectedEmployees?.some((em) => em?._id === employee?._id)) {
      setSelectedEmployees([...selectedEmployees, employee]);
    }
  };

  const handleRemove = (id) => {
    const newList = selectedEmployees.filter((item) => item._id !== id);
    setSelectedEmployees(newList);
    if (checked) setChecked(false);
  };

  const handleRemoveAll = () => {
    setSelectedEmployees([]);
    if (checked) setChecked(false);
  };

  useEffect(() => {
    setSelectedEmployees(props?.assigned || []);
    if (employeesData?.length === length) setChecked(true);
  }, [length]);

  useEffect(() => {
    setEmployees(isTesting ? employee : employeesData);
  }, [employeesData, searchString]);

  const selectedEmployeeIds = selectedEmployees?.map((emp) => emp._id);

  const handleToogleSwitch = (e) => {
    setChecked((prev) => !prev);
    const { checked: isChecked } = e.target;
    if (!isChecked) {
      return setSelectedEmployees([]);
    }
    let checkedCopy = Array(employeesData?.length);
    employeesData?.forEach((elem, i) => {
      checkedCopy[i] = { ...elem };
    });
    setSelectedEmployees(checkedCopy);
  };

  return (
    props.showModal && (
      <Overlay justifyContent={'center'}>
        <Modal>
          <Grid
            className="grid"
            templateRows={'max-content 1fr max-content'}
            border={'1px solid #F2F2F2'}
            flexDirection={'column'}>
            <Flex flexDirection={'column'}>
              <Flex className="heading" justifyContent={'space-between'}>
                <h1>{props?.title}</h1>
                <Button
                  dataTestId={'closeModal'}
                  img={close}
                  onClick={() => props?.setShowModal(false)}
                />
              </Flex>
              <Grid className="row" gridTemplateColumns={'1fr 1fr'}>
                <Search
                  type={'search'}
                  placeholder={'Search name, team'}
                  value={search}
                  setSearchString={setSearchString}
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
                <Flex justifyContent={'space-between'}>
                  <h2 className="column-head">Selected</h2>
                  <Button
                    dataTestId={'handleRemoveAll'}
                    title={'Remove all'}
                    style={{
                      color: '#3E7EEE',
                      fontWeight: 400,
                      fontSize: '16px',
                    }}
                    onClick={handleRemoveAll}
                  />
                </Flex>
              </Grid>
            </Flex>
            <Flex className="cards">
              <Column>
                {loading && !isTesting ? (
                  <Grid minH="20rem" placeContent="center" mt="20rem">
                    <SpinnerComponent data-testid="loading" />
                  </Grid>
                ) : (
                  <EmployeeCards>
                    {employees?.length ? (
                      employees?.map((employee) => (
                        <EmployeeCard
                          employee={employee}
                          key={employee?._id}
                          handleAdd={handleAdd}
                          selectedEmployeeIds={selectedEmployeeIds}
                        />
                      ))
                    ) : (
                      <EmptyState message={'No Employees Found'} />
                    )}
                  </EmployeeCards>
                )}

                {+totalPages && employees?.length >= 10 && (
                  <Pagination page={page} totalPages={totalPages} setPage={setPage} />
                )}
              </Column>
              <Column>
                <EmployeeCards>
                  <div className="container">
                    {selectedEmployees?.length ? (
                      selectedEmployees?.map((employee) => (
                        <EmployeeCard
                          employee={employee}
                          key={employee?._id}
                          handleRemove={handleRemove}
                          selected={true}
                        />
                      ))
                    ) : (
                      <EmptyState message={'No Employees Selected'} />
                    )}
                  </div>
                </EmployeeCards>
              </Column>
            </Flex>
            <ToggleSwitch
              label={'Select All'}
              name={'isSelectedAll'}
              onClick={handleToogleSwitch}
              checked={checked}
            />
            <Flex justifyContent={'flex-end'} alignItems={'flex-end'} className={'sticky-button'}>
              <Button
                dataTestId={'onAssign'}
                title={'Assign'}
                style={{
                  background: '#23273B',
                  borderRadius: '4px',
                  color: '#ffffff',
                  padding: '0 48px',
                  height: '48px',
                }}
                isLoading={props?.assignLoading}
                onClick={() => props?.onClick(selectedEmployeeIds, selectedEmployees)}
              />
            </Flex>
          </Grid>
        </Modal>
      </Overlay>
    )
  );
};

export { AssignEmployeesModal };
