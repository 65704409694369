import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { EDIT_GOAL } from 'query';

import { BaseButton, CheckboxWrapper, CustomCheckbox, ProgressBar } from 'components';

const Container = styled(Flex)`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 24px 32px;
  flex-direction: column;
  gap: 24px;

  .dueDate {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 12px;
    letter-spacing: -0.0012em;
    background: #3e7eee;
    color: #fff;
    width: 128px;
    height: 39px;
  }

  .progress {
    width: 94px;
    height: 32px;
    background: rgba(62, 126, 238, 0.1);
    border: 1px solid #3e7eee;
    font-weight: 600;
    color: #23273b;
  }

  .primary {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.4px;
    color: #3e7eee;
    background: #f7f9fb;
    border: 1px solid #f2f2f2;
  }
`;

const GoalsIndicator = ({ data, refetch, initialState }) => {
  const { successToast, errorToast } = useToastify();

  const [goal, setGoals] = useState({});
  const [isChecked, setIsChecked] = useState(initialState || []);

  const [completed, setCompleted] = useState(0);

  const [editGoal] = useMutation(EDIT_GOAL, {});
  const isGoalsLength = goal?.milestones?.length;
  const isCheckedLength = isChecked?.length;

  const handleChecked = (e) => {
    setIsChecked((prev) =>
      isChecked?.includes(e) ? prev?.filter((cur) => cur !== e) : [...prev, e]
    );
  };

  const updateMilestones = (goal) => {
    if (goal?.length) {
      const newArray = goal?.map((milestone) => {
        if (isChecked?.includes(milestone?.metricTitle)) {
          return { ...milestone, isCompleted: true };
        } else {
          return { ...milestone, isCompleted: false };
        }
      });
      setGoals((prev) => ({ ...prev, milestones: newArray }));
    }
  };

  const updateProgress = () => {
    const progress = (isCheckedLength / isGoalsLength) * 100;

    setCompleted(Math.ceil(progress) || 0);
    updateMilestones(goal?.milestones);
  };

  const status = goal?.status?.split('_')?.join(' ');

  const onUpdate = async (id, state) => {
    const { milestones } = state;
    try {
      const res = await editGoal({
        variables: {
          filters: {
            _id: id,
          },
          data: { milestones },
        },
      });
      const { data } = res;
      if (data && data.editGoal.status === 200) {
        successToast('Milestones updated sucessfully');
        refetch();
      } else errorToast(data.editGoal.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  useEffect(() => {
    setGoals(data);
  }, [data]);

  useMemo(() => {
    updateProgress();
  }, [isCheckedLength, isGoalsLength]);

  return (
    <Container>
      <Flex alignItems={'center'} justifyContent={'space-between'} textTransform={'capitalize'}>
        <Flex className="dueDate">
          <Text fontWeight={700}>Due</Text>: {goal?.schedule?.dueDate}
        </Flex>
        <Text className="dueDate progress">{status}</Text>
      </Flex>
      <Box>
        <ProgressBar bgcolor={'#3E7EEE'} completed={completed} />
      </Box>
      <Flex flexWrap={'wrap'} gap={'24px 80px'}>
        {goal?.milestones?.map((milestone, index) => (
          <CheckboxWrapper data-testid={'checkbox'} key={index}>
            <CustomCheckbox
              label={milestone?.metricTitle}
              variant={'green-checkbox'}
              checked={isChecked?.includes(milestone?.metricTitle)}
              handleChecked={() => handleChecked(milestone?.metricTitle)}
            />
          </CheckboxWrapper>
        ))}
      </Flex>
      <Box padding={'24px 0 0'} borderTop={'1px solid rgba(65, 97, 128, 0.151934)'}>
        <BaseButton onClick={() => onUpdate(goal?._id, goal)}>Update</BaseButton>
      </Box>
    </Container>
  );
};

export { GoalsIndicator };
