import { Route, Routes } from 'react-router-dom';
import { ApplicantAuth } from 'layouts/ApplicantAuth';

import { JobApply, JobList, PublicJobDescription } from '../pages';

export function JobRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<ApplicantAuth />}>
        <Route path="" element={<JobList />} />
        <Route path=":id" element={<PublicJobDescription />} />
        <Route path=":id/apply" element={<JobApply />} />
      </Route>
    </Routes>
  );
}
