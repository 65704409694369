import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Link } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastify } from 'hooks/useToastify';
import { setItem } from 'utils';
import * as yup from 'yup';

import { BaseButton, BaseInput } from '../../../components';
import { sendApplicantEmail } from '../../../query';

// import { removeItem, setItem } from '../../../utils';
import { Container, ErrorMessage, InputFields } from './styles';

const ForgotPassword = () => {
  const initialState = {
    email: '',
  };
  const navigate = useNavigate();

  const { errorToast, successToast } = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const mainState = process.env.NODE_ENV === 'test' ? initialState : initialState;

  const forgotPassword = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .matches(
        /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
        'Email is invalid! Try format abcd@email.com'
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: mainState,
    resolver: yupResolver(forgotPassword),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await sendApplicantEmail(data);
      successToast('Email sent successfully');
      setEmail(data.email);
      setItem('email', data.email);
    } catch (error) {
      errorToast(error.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <h1>Forgot your password</h1>
      {email ? (
        <p className="sent-email">
          We‘ve sent an email to {email}
          <br />
          Please check your inbox and follow the instructions to reset your password.
        </p>
      ) : (
        <Grid gap={'24px'} textAlign={'left'}>
          <InputFields onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <BaseInput
                data-testid="email"
                {...register('email', { required: true })}
                labelSize={'1.2rem'}
                fontSize={'1.4rem'}
                label={'Email Address'}
                type={'email'}
                name={'email'}
                placeholder={'Enter Email'}
              />
              {errors?.email?.message && <ErrorMessage> {errors?.email?.message}</ErrorMessage>}
            </Box>
            <BaseButton type={'submit'} isLoading={isLoading}>
              Send reset email
            </BaseButton>
          </InputFields>
          <p className="text">
            Take me back to{' '}
            <Link className="link" onClick={() => navigate('/applicant/login')}>
              Login
            </Link>
          </p>
        </Grid>
      )}
    </Container>
  );
};

export { ForgotPassword };
