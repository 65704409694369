import React from 'react';
import { Grid, Text } from '@chakra-ui/react';
import { ToggleSwitch } from 'pages/admin/leave-management/leave-settings/components/ToggleButton';

import { InputBox } from 'components';

const RecurringPeriod = ({ handleRecurringPerioid, setState, setRepeat, openRepeat, state }) => {
  const dropDownOptions = [
    {
      label: 'Days',
      value: 'days',
    },
    {
      label: 'Months',
      value: 'months',
    },
  ];

  const handleToogleSwitch = () => {
    setRepeat(!openRepeat);
    setState((prevState) => ({
      ...prevState,
      repeatProgram: {
        isRepeated: false,
        repeatIn: 0,
        repeatType: 'days',
      },
    }));
  };

  return (
    <Grid fontSize={'12px'} templateRows={'1fr'} gap={'17px'}>
      <Text className="heading">Does this program repeat?</Text>
      <ToggleSwitch
        label={'Does repeat'}
        onClick={handleToogleSwitch}
        onChange={handleRecurringPerioid}
        name={'isRepeated'}
        value={state?.isRepeated}
        checked={state?.isRepeated}
      />
      {openRepeat && (
        <Grid templateColumns={'1fr 1fr'}>
          <Grid templateColumns={'max-content 1fr 1fr'} gap={'16px'} alignItems={'center'}>
            <Text m={'12px 0 0'}>Repeat</Text>
            <InputBox
              label={' '}
              type={'number'}
              id={'repeatIn'}
              placeholder={'2'}
              value={state?.repeatIn}
              handleChange={handleRecurringPerioid}
            />
            <InputBox
              type={'dropdown'}
              id={'repeatType'}
              dropdown={dropDownOptions}
              dropdownStyle={{ width: '100%', zIndex: 2 }}
              placeholder={'Days'}
              value={state?.repeatType}
              handleChange={(e) =>
                handleRecurringPerioid({
                  target: {
                    name: 'repeatType',
                    value: e.value,
                  },
                })
              }
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { RecurringPeriod };
