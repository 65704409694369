import mail from '../../../../assets/icons/mail.svg';

export const profileInfoFormData = [
  {
    label: 'Your Photo',
    description: 'This will be displayed on your profile.',
    name: 'imageUrl',
    placeholder: 'Enter your email address',
    type: 'file',
  },
  {
    label: 'Your Name',
    name: 'fullName',
    placeholder: 'Enter your first name',
    type: 'text',
  },
  {
    label: 'Date of Birth',
    name: 'dob',
    type: 'date',
  },
  {
    label: 'Your Address',
    name: 'address',
    type: 'textarea',
    placeholder: '',
  },
  {
    label: 'Contact Email',
    name: 'email',
    placeholder: 'Enter your email address',
    icon: mail,
  },
  {
    label: 'Alternate Contact Email',
    name: 'alternateEmail',
    placeholder: 'Enter your email address',
    icon: mail,
  },
];
