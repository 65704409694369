import React, { createContext, useContext, useState } from 'react';

const UnreadChatContext = createContext(0);

function UnreadChatsProvider(props) {
  const [chats, setChats] = useState(props.initialValue);

  return (
    <UnreadChatContext.Provider value={{ chats, setChats }}>
      {props.children}
    </UnreadChatContext.Provider>
  );
}

function useUnreadChatsContext() {
  return useContext(UnreadChatContext);
}

export { UnreadChatsProvider, useUnreadChatsContext };
