import { useToast } from '@chakra-ui/react';

function useEaseToast() {
  const toast = useToast();

  function showSuccessToast({
    description,
    title = 'Success',
    isClosable = true,
    status = 'success',
  }) {
    toast({
      title,
      description,
      isClosable,
      status,
      position: 'top-right',
      duration: 3000,
    });
  }

  function showErrorToast({
    description = 'Could not process request. Try again later.',
    title = 'Failed',
    status = 'error',
    isClosable = true,
  }) {
    toast({
      title,
      description,
      status,
      isClosable,
      position: 'top-right',
      duration: 3000,
    });
  }

  function showClosableSuccessToast(description) {
    showSuccessToast({ description, isClosable: true });
  }

  return {
    toast,
    showSuccessToast,
    showErrorToast,
    showClosableSuccessToast,
  };
}

export { useEaseToast };
