import React from 'react';
import { Controller } from 'react-hook-form';
import { Text } from '@chakra-ui/react';
import { Grid, GridItem } from '@chakra-ui/react';
import { useOptions } from 'context';

import { BaseButton, BaseDropdown, CustomInput } from 'components';

function EditDetail(props) {
  const { label, type, formMethods } = props;
  const isDropdown = type === 'dropdown';
  const isText = type === 'text';
  const isDate = type === 'date';

  return (
    <>
      <Grid templateColumns="repeat(2, max-content)" justifyContent="space-between" mb="8px">
        <Text fontSize="1.2rem" lineHeight="1.2rem" color="#83889d" mb="8px">
          {label}
        </Text>
        <EditControls {...props} />
      </Grid>
      {isDropdown && <DropdownInput {...formMethods} {...props} />}
      {(isText || isDate) && <TextInput {...formMethods} {...props} />}
    </>
  );
}

function DropdownInput({ name, control }) {
  const { getOption } = useOptions();
  const options = React.useMemo(() => getOption(name), [name]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <BaseDropdown placeholder="Select job type" w="100%" {...field} options={options} />
      )}
    />
  );
}

function TextInput({ name, type, register, setValue, watch }) {
  const [date, setDate] = React.useState(
    type === 'date' ? watch(name)?.toISOString().substring(0, 10) : null
  );

  function onChange(e) {
    setDate(e.target.value);
    setValue(name, new Date(e.target.value));
  }

  if (type === 'date') {
    return (
      <CustomInput
        value={date}
        onChange={onChange}
        placeholder={`Enter ${name?.toLowerCase()}`}
        type="date"
      />
    );
  }

  return <CustomInput {...register(name)} placeholder={`Enter ${name?.toLowerCase()}`} />;
}

function EditControls({ onCloseEdit, onSaveChanges }) {
  return (
    <Grid templateColumns="repeat(2, max-content)" gap="4px" h="12px" alignContent="center">
      <GridItem>
        <BaseButton variant="text" sm onClick={onCloseEdit}>
          Cancel
        </BaseButton>
      </GridItem>
      <GridItem>
        <BaseButton variant="dark" sm onClick={onSaveChanges}>
          Save
        </BaseButton>
      </GridItem>
    </Grid>
  );
}

export { EditDetail };
