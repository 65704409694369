import styled from '@emotion/styled';

export const Container = styled.div`
  margin: 0px auto;
  padding: 48px 45px;
  position: relative;

  border-radius: 12px;
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);

  @media only screen and (max-width: 700px) {
    margin: 20px;
  }
`;

export const View = styled.div`
  header {
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.117188px;
      color: var(--backgroundColor);
    }
    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.117188px;
      color: var(--backgroundColor);
    }
    span {
      color: #3e7eee;
    }
  }

  .mainframe {
    position: relative;
    margin-top: 24px;
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      letter-spacing: -0.005em;
      /* Header Black */
      color: #272932;
    }

    span {
      font-weight: 500;
    }

    .instructions {
      border-radius: 12px 12px 0px 0px;
      border-top: 1px solid #eaeef3;
      border-right: 1px solid #eaeef3;
      border-left: 1px solid #eaeef3;
      padding: 37px 33px;
      background: var(--bg);
    }
  }

  .footer {
    padding: 35px 33px;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #eaeef3;
    background: #fafcff;
    display: flex;
    gap: 36px 0px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: 700px) {
    .mainframe {
      margin: 24px 0;

      .instructions {
        margin: 24px 0;
      }
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  background: #fcfcfd;
  border: 1px solid #f0f2f5;
  border-radius: 2px;
  margin: 0px 0 48px;

  tr {
    text-align: left;
  }

  th {
    padding: 32px 32px 17px;
  }

  td {
    padding: 20px 32px 32px;
  }

  @media only screen and (max-width: 700px) {
    display: flex;
    justify-content: space-around;
    margin: 0px 0 24px;

    tr {
      display: block;
      float: left;
    }
    th,
    td {
      padding: 15px;
      display: block;
    }
  }
`;
