import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { jobApplicationSchema } from 'schemas/job-application';

import { JobsBreadcrumbs } from '../../../../components';
import {
  ApplicantsFormProvider,
  HandleCreateJobProvider,
  JobDetailsFormProvider,
  OptionsProvider,
  ScoreCardProvider,
} from '../../../../context';

import { getDefaultApplicantsForm } from './ApplicantsForm/helpers';
import AddJobDetails from './AddJobDetails';
import ApplicantsFormView from './ApplicantsForm';
import HiringPipelineView from './HiringPipeline';
import { JobCreatedMessage } from './JobCreatedMessage';
import { ScoreCardView } from './ScoreCards';
import StatusBar from './StatusBar';

const defaultApplicantsFormValue = getDefaultApplicantsForm();

const tabList = ['Job Details', "Applicant's Form", 'Score Card', 'Hiring Pipeline'];
const pages = [
  <AddJobDetails key={0} />,
  <ApplicantsFormView key={1} />,
  <ScoreCardView key={2} />,
  <HiringPipelineView key={3} />,
  <JobCreatedMessage key={4} />,
];

function AddJobs() {
  const [activeTab, setActiveTab] = React.useState(0);
  const activePage = React.useMemo(() => pages[activeTab], [activeTab]);
  const navigate = useNavigate();

  const onSuccessfulPublish = () => {
    setActiveTab((val) => val + 1);
  };
  const onSuccessfulPublishDraft = () => {
    navigate('/admin/jobs');
  };

  const handleCreateProviderProps = {
    onSuccessfulPublishDraft,
    onSuccessfulPublish,
  };

  return (
    <JobDetailsFormProvider>
      <ApplicantsFormProvider
        initialValue={{
          defaultValues: defaultApplicantsFormValue,
          resolver: yupResolver(jobApplicationSchema),
        }}>
        <ScoreCardProvider initialValue={scoreCard}>
          <HandleCreateJobProvider {...handleCreateProviderProps}>
            <OptionsProvider>
              <Box p="2.4rem">
                <StatusBar
                  totalPages={tabList.length}
                  active={activeTab}
                  setActive={setActiveTab}
                />
                <Box
                  p="1.6rem"
                  bg="white"
                  mt="3.2rem"
                  border="1px solid #F2F2F2"
                  position="sticky"
                  top="6rem"
                  zIndex="50">
                  <JobsBreadcrumbs active={activeTab} setActive={setActiveTab} list={tabList} />
                </Box>

                <Box bg="white" border="1px solid #F2F2F2">
                  {activePage}
                </Box>
              </Box>
            </OptionsProvider>
          </HandleCreateJobProvider>
        </ScoreCardProvider>
      </ApplicantsFormProvider>
    </JobDetailsFormProvider>
  );
}

const scoreCard = [
  {
    id: 1,
    title: 'Interpersonal Skills',
    hint: 'How good are they at striking up new conversation? have they put you at ease?',
    stage: null,
    type: {
      label: 'Rating and comments',
      value: 'Rating and comments',
    },
  },
];

export { AddJobs };
