import React from 'react';
import styled from '@emotion/styled';

import { ModalBackButton } from 'components';

import { Tabs } from '../../../../components/shared/tabs';

import { AdminManagement } from './AdminManagement/AdminManagement';
import { CompanyDetails } from './CompanyDetails/CompanyDetails';
import { Departments } from './Departments/Departments';
import { RolesPermissions } from './RolesPermissions/RolesPermissions';
import { Teams } from './Teams/Teams';
import { useOnboardDetailsView } from './hooks';

export const OnboardingSetupView = ({ setShowDetails, selectedTab }) => {
  const { tabs, setSelected, selectedButton } = useOnboardDetailsView();

  return (
    <div data-testid="onb-tabs-view">
      <ModalBackButton onClick={() => setShowDetails(false)} />
      {selectedTab === 'companyDetails' && <CompanyDetails cancel={() => setShowDetails(false)} />}
      {selectedTab === 'adminManagement' && (
        <AdminManagement cancel={() => setShowDetails(false)} />
      )}
      {selectedTab === 'roles' && <RolesPermissions cancel={() => setShowDetails(false)} />}
      {selectedTab === 'department/teams' && (
        <>
          <Header>
            <Tabs tabs={tabs} setSelected={setSelected} selected={selectedButton} />
          </Header>
          <Content>
            {selectedButton === 'department' && (
              <Departments cancel={() => setShowDetails(false)} />
            )}
            {selectedButton === 'teams' && <Teams cancel={() => setShowDetails(false)} />}
          </Content>
        </>
      )}
    </div>
  );
};

const Header = styled.div`
  border-bottom: 1px solid var(--grey-150);
  width: 100%;
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 10;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
`;
const Content = styled.div`
  width: 100%;
  height: auto;
  padding-top: 2.4rem;
`;
