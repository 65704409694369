import React from 'react';
import styled from '@emotion/styled';
import { usePermissionsForm } from 'context';
import { useToastify } from 'hooks/useToastify';

import { BaseInput, CustomButton } from 'components';

import { Modal } from './Modal';
import { permissionsData } from './tableConfig';

export const CreateAndEditRoleForm = ({
  show,
  setShow,
  showPermissionTable,
  setEditingRole,
  isEditing,
}) => {
  const { register, reset, watch } = usePermissionsForm();

  const { errorToast } = useToastify();

  const openPermissions = () => {
    const watchVal = watch('rolename');
    watchVal !== '' ? showPermissionTable(true) : errorToast('Role name cannot be empty');
  };

  const closemodal = () => {
    setShow(false);
    setEditingRole(false);
    reset(permissionsData);
  };

  return (
    <Modal show={show} setShow={closemodal}>
      <Container data-testid="create-edit-form">
        <h1>{`${isEditing ? 'Edit' : 'Create'} Role`}</h1>
        <div className="form-wrapper">
          <BaseInput
            {...register('rolename')}
            label="Role name"
            name={'rolename'}
            placeholder="role name"
          />
          <CustomButton name="Next" variant="primary" h="4.8rem" onClick={openPermissions} />
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  max-width: 42rem;
  margin: auto;
  h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    color: var(--black);
    font-weight: 500;
  }
  .form-wrapper {
    margin-top: 4.8rem;
    display: grid;
    grid-gap: 1.6rem;
  }
`;
