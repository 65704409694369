const genderData = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Non Binary',
    value: 'Non Binary',
  },
  {
    label: 'Prefer not to say',
    value: 'other',
  },
];

const maritalStatus = [
  {
    label: 'Married',
    value: 'Married',
  },
  { label: 'Widowed', value: 'Widowed' },
  { label: 'Separated', value: 'Separated' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Single', value: 'Single' },
];

const bloodGroup = [
  { label: 'A+', value: 'A+' },
  { label: 'A-', value: 'A-' },
  { label: 'B+', value: 'B+' },
  { label: 'B-', value: 'B-' },
  { label: 'AB+', value: 'AB+' },
  { label: 'AB-', value: 'AB-' },
  { label: 'O+', value: 'O+' },
  { label: 'O-', value: 'O-' },
];

const bloodGenotype = [
  { label: 'AA', value: 'AA' },
  { label: 'AS', value: 'AS' },
  { label: 'AC', value: 'AC' },
  { label: 'SS', value: 'SS' },
  { label: 'SC', value: 'SC' },
  { label: 'OO', value: 'OO' },
];

const twoAnswers = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const range = [
  { value: '50 - 100', label: '50 - 100' },
  { value: '100 - 200', label: '100 - 200' },
  { value: '300 - 400', label: '300 - 400' },
  { value: '400 - 500', label: '400 - 500' },
  { value: '500 - 600', label: '500 - 600' },
  { value: '600 - 1000', label: '600 - 1000' },
];

const employmentType = [
  { value: 'full time', label: 'Full time' },
  { value: 'part time', label: 'Part time' },
  { value: 'contract', label: 'contract' },
];

const compensationOptions = [
  { value: 'salary', label: 'Salary' },
  { value: 'bonus', label: 'Bonus' },
];

const accountType = [
  { value: 'savings', label: 'Savings' },
  { value: 'investment', label: 'Investment' },
  { value: 'students', label: 'Students' },
];

const paymentType = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'bi weekly', label: 'Bi Weekly' },
];

const workingFormat = [
  { label: 'onsite', value: 'onsite' },
  { label: 'remote', value: 'remote' },
  { label: 'hybrid', value: 'hybrid' },
];

const industries = [
  'Agricultural Services & Products',
  'Agriculture',
  'Airlines',
  'Architectural Services',
  'Attorneys/Law Firms',
  'Auto Manufacturers/Dealers',
  'Banking',
  'Builders/General Contractors',
  'Business Services',
  'Cable & Satellite TV Production & Distribution',
  'Casinos / Gambling',
  'Chemical & Related Manufacturing',
  'Civil Servants/Public Officials',
  'Clergy & Religious Organizations',
  'Communications/Electronics',
  'Computer Software',
  'Doctors & Other Health Professionals',
  'Education',
  'Entertainment Industry',
  'Real Estate',
  'Food Products Manufacturing',
  'General Contractors',
  'Health Services/HMOs',
  'Hotels, Motels & Tourism',
  'Insurance',
  'Marine Transport',
  'Medical Supplies',
  'Oil & Gas',
  'Pharmaceutical Manufacturing',
  'Power Utilities',
  'Printing & Publishing',
  'Private Equity & Investment Firms',
  'Savings & Loans',
  'Textiles',
  'Transportation',
];

const industryArray = industries?.map((ind) => {
  return { label: ind, value: ind };
});

export {
  accountType,
  bloodGenotype,
  bloodGroup,
  compensationOptions,
  employmentType,
  genderData,
  industryArray,
  maritalStatus,
  paymentType,
  range,
  twoAnswers,
  workingFormat,
};
