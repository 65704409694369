import React from 'react';
import { Grid } from '@chakra-ui/react';

import { EmptyState, LeaveCard, SpinnerComponent } from 'components';

import { CreateLeavePolicy } from './create-leave-policy/CreatePolicy';
import { useTypesandPoliciesView } from './hooks';
import { Container, Empty, TabView } from './styles';

const TypesAndPolicies = () => {
  const { leaveType, showModal, isTesting, setShowModal, loading, refetchLeaveType } =
    useTypesandPoliciesView();

  if (loading && !isTesting) {
    return (
      <Grid minH="20rem" placeContent="center" mt="20rem">
        <SpinnerComponent data-testid="loading" />
      </Grid>
    );
  }
  return (
    <Container>
      {leaveType?.length !== 0 ? (
        <TabView>
          {leaveType?.map((data) => (
            <LeaveCard
              leaveType={leaveType}
              key={data?._id}
              data={data}
              setShowModal={setShowModal}
              refetchLeaveType={refetchLeaveType}
            />
          ))}
          <CreateLeavePolicy showModal={showModal} setShowModal={setShowModal} />
        </TabView>
      ) : (
        <Empty>
          <EmptyState message={'No Leave Type Found'} />
        </Empty>
      )}
    </Container>
  );
};
export { TypesAndPolicies };
