import React from 'react';
import jwt_decode from 'jwt-decode';

import { getItem } from '../utils';

function ApplicantPrivateRoute({ children }) {
  const token = getItem('token');
  const user = getItem('hrm_user');

  // Check is user is logged in
  if (!token || !user || !user?.isApplicant) {
    return window.location.replace(`/applicant/login`);
  }

  const decoded = jwt_decode(token);
  if (Date.now() >= decoded?.exp * 1000) {
    localStorage.removeItem('token');
    localStorage.removeItem('hrm_user');
    return window.location.replace(`/applicant/login`);
  }

  return <>{children}</>;
}

export { ApplicantPrivateRoute };
