import React from 'react';
import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';

const CButton = styled(Button)`
  line-height: 2.4rem;
  color: #fff;
  width: 100%;
  border-radius: 0.4rem;

  img {
    margin: 0 1rem;
  }
  &.primary {
    background: var(--primary);
    color: var(--white);
    &:disabled {
      color: var(--white);
      background: var(--primary);
    }
  }
  &.secondary {
    background: #fff;
    color: #23273b;
    border: 1px solid #f2f2f2;
  }
  &.tertiary {
    background: #fff;
    color: var(--primary);
    border: 1px solid var(--primary);
  }
  &.grey__button {
  }
  &.grey--button {
    background: #807f7f;
    color: #000;
    border: 1px solid #807f7f;
  }
  &.black {
    background: var(--black);
    color: var(--white);
  }
  &.black--outline {
    background: var(--white);
    color: var(--black);
    border: 1px solid var(--black);
  }
  &.red--outline {
    background: var(--white);
    color: var(--red);
    border: 1px solid var(--red);
  }
  &.red {
    color: var(--white);
    background: var(--red);
    border: 1px solid var(--red);
  }

  :focus {
    box-shadow: none !important;
  }

  .btn__loader {
    width: 1rem;
    height: 1rem;
    margin-left: 1.2rem;
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    &::after {
      content: ' ';
      display: block;
      width: 1rem;
      height: 1rem;
      box-sizing: content-box;
      backface-visibility: hidden;
      border-radius: 50%;
      border: 0.2rem solid currentColor;
      border-left-color: transparent;
      transform-origin: 50% 50%;
      transform-box: fill-box;
      animation: ld-spin 500ms infinite linear;
    }
  }
  @keyframes ld-spin {
    0% {
      animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CustomButton = ({
  name,
  h,
  variant,
  fW,
  bR,
  fS,
  mW,
  src,
  handleButtonClick,
  loading,
  disabled,
  bgColor,
  color,
  border,
  type,
  onClick,
  testId,
  srcRight,
}) => {
  return (
    <CButton
      disabled={disabled}
      data-testid={`btn-clicked-${variant}` || testId}
      variant={variant || 'primary'}
      height={h || '6rem'}
      maxW={mW}
      minW={'max-content'}
      color={color}
      border={border}
      bgColor={bgColor || `var(--primary)`}
      className={`${variant}`}
      fontWeight={fW || '600'}
      fontSize={fS || '1.6rem'}
      borderRadius={bR || '0.4rem'}
      type={type}
      onClick={onClick || handleButtonClick}>
      {src ? <img src={src} alt="i" /> : null}
      {name}
      {srcRight && <img src={srcRight} alt="i" />}
      {loading && <span className="btn__loader" />}
    </CButton>
  );
};

export { CustomButton };
