import React from 'react';
import { Flex, Heading, Stack } from '@chakra-ui/react';

import { getDateLabel, groupEvents } from '../helpers';

import { SingleEvent } from './SingleEvent';

export function GroupedUpcomingEvents({ upcomingEvents, setName, toggleModal, setUserId }) {
  return (
    <Flex flexDir={'column-reverse'} gap={'24px'}>
      {Object.entries(groupEvents(upcomingEvents)).map(([key, value]) => {
        return (
          <Stack key={key} gap={'12px'}>
            <Heading fontSize={'12px'} color={'#83889D'}>
              {getDateLabel(key)}
            </Heading>
            {value.map((event) => {
              return (
                <SingleEvent
                  setName={setName}
                  key={event._id}
                  event={event}
                  isReply={true}
                  isPerson={true}
                  isBirthday={event.dob ? true : false}
                  openModal={toggleModal}
                  setUserId={setUserId}
                />
              );
            })}
          </Stack>
        );
      })}
    </Flex>
  );
}
