import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  width: 842px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const Heading = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #23273b;
  margin: 0 0 16px 0;
`;

export const InputFields = styled(Flex)`
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  .with-label label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    color: #23273b;
  }

  .dropDown {
    width: 100%;
  }
`;

export const Section = styled.div`
  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.117188px;
    color: #23273b;
    margin: 0 0 16px 0;
  }

  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.117188px;

    /* Black - HRMs */

    color: #23273b;
  }
  .required {
    color: #fa4a4a;
  }
`;

export const Goals = styled(Flex)``;
export const Goal = styled(Grid)`
  .button {
    width: 18px;
  }
`;

export const addMilestone = {
  width: 'fit-content',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.117188px',
  textDecorationLine: 'underline',
  color: '#36CA8B',
};

export const deleteButton = {
  height: 'fit-content',
  margin: '0 0 12px',
};
