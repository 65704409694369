import { Grid, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AnimatedSection } from 'components/AnimateSection';

import candidateScreeningImg from '../../assets/images/candidate-screening.png';
import employeeManagementImg from '../../assets/images/employee-management.png';
import recordTimeImg from '../../assets/images/record-your-time.png';
import organiseImg from '../../assets/images/rejectApprove.png';

let data = [
  {
    id: 1,
    image: employeeManagementImg,
    heading: 'Employee Management',
    desc: 'Empowering HR professionals to optimize resource allocation, foster employee development, and maintain a cohesive work environment.',
  },
  {
    id: 2,
    image: organiseImg,
    heading: 'Organize Management',
    desc: 'Enhancing your organizational  efficiency and decision-making by providing a robust platform to handle essential administrative tasks seamlessly.',
  },
  {
    id: 3,
    image: candidateScreeningImg,
    heading: 'Candidate Screening',
    desc: 'From job posting to candidate monitoring and selection, this feature provides an intuitive interface for managing the entire recruitment lifecycle.',
  },
  {
    id: 4,
    image: recordTimeImg,
    heading: 'Record Your Time',
    desc: 'Easily track employee attendance and streamline payroll processes.Allow employee to clock-in and out when at post',
  },
];

export function IntegratedFeatures() {
  return (
    <Root className="container">
      <AnimatedSection>
        <Heading
          fontSize={{ base: '36px', md: '46px' }}
          maxW={'745px'}
          textAlign={{ base: 'left', md: 'center' }}>
          Unlock a World of Possibilities with Our{' '}
          <span className="ascent">Integrated Features</span>
        </Heading>
      </AnimatedSection>

      <AnimatedSection>
        <Grid gap={'26px'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4,1fr)' }}>
          {data.map((item) => {
            return (
              <FeatureCard
                key={item.id}
                image={item.image}
                heading={item.heading}
                desc={item.desc}
              />
            );
          })}
        </Grid>
      </AnimatedSection>
    </Root>
  );
}

function FeatureCard({ image, heading, desc }) {
  return (
    <Stack className="card">
      <img src={image} alt="" />
      <Stack className="text">
        <Heading>{heading}</Heading>
        <Text color={'#5D6C81'}>{desc}</Text>
      </Stack>
    </Stack>
  );
}

const Root = styled(Stack)`
  padding: 160px 0;
  gap: 46px;
  align-items: center;
  .ascent {
    color: var(--primary);
  }

  .card {
    max-width: 350px;
    overflow: hidden;
    border-radius: 25.333px;
    border: 1.9px solid #e3e6ee;
    background: #f7f8fa;
    box-shadow: 0px 0px 30.4px 0px rgba(0, 0, 0, 0.06);

    .text {
      padding: 40px 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10.133px;
    }
  }
`;
