import React from 'react';
import { Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { getItem } from 'utils';

import chat from '../../../../../assets/icons/Chat.svg';
import notification from '../../../../../assets/icons/eventNotification.svg';
import { formatDateShort, joinNames, setImage } from '../helpers';

export function SingleEvent({
  event,
  isPerson,
  isReply,
  isBirthday,
  openModal,
  setName,
  setUserId,
}) {
  function openMessage(event) {
    setUserId(event._id);
    setName(joinNames(event));
    openModal();
  }

  function getDuration(data) {
    return new Date().getFullYear() - data.split('-')[0] + 1;
  }

  const userDetails = getItem('hrm_user');

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} gap={'0.8rem'}>
      <Flex gap={'0.8rem'} alignItems={'center'}>
        {isPerson ? (
          <Image
            src={setImage(event?.firstName, event?.lastName, event?.imageUrl || '')}
            alt=""
            width={'32px'}
            height={'32px'}
            borderRadius={'50%'}
          />
        ) : (
          <img src={notification} alt="" width={'32px'} height={'32px'} />
        )}
        <Stack>
          {isBirthday ? (
            <>
              <Heading fontSize={'1.2rem'}>
                {joinNames({ firstName: event.firstName, lastName: event.lastName })} Birthday
              </Heading>
              <Text fontSize={'0.8rem'}>Birthday on {formatDateShort(event.dob)}</Text>
            </>
          ) : (
            <>
              <Heading fontSize={'1.2rem'}>
                {joinNames({ firstName: event.firstName, lastName: event.lastName })}’s work
                anniversary
              </Heading>
              <Text fontSize={'0.8rem'}>
                Celebrating
                {getDuration(event?.employmentInfo?.hireDate) > 1
                  ? ` ${getDuration(event?.employmentInfo?.hireDate)} years`
                  : ` ${getDuration(event?.employmentInfo?.hireDate)} year`}{' '}
                at {userDetails?.companyId?.companyName}.
              </Text>
            </>
          )}
        </Stack>
      </Flex>

      {isReply ? (
        <Image
          data-testid={'sendWish'}
          src={chat}
          onClick={() => openMessage(event)}
          cursor={'pointer'}
        />
      ) : null}
    </Flex>
  );
}
