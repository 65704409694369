import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_HRM_V3_API_BASE_URL,
});

const get = (...args) => axiosClient.get(...args).then((res) => res.data?.data || res.data);
const post = (...args) => axiosClient.post(...args).then((res) => res.data?.data || res.data);

export { axiosClient, get, post };
