import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';

import { Button, InputBox } from 'components';

import redbutton from '../../../../../../assets/icons/Delete-red.svg';
import greenplus from '../../images/greenplus.svg';

import { addMilestone, deleteButton, Goal, Goals, Section } from './styles';

const SetMetrics = ({ state, handleChange, setState, validateGoals }) => {
  const { errorToast } = useToastify();

  const onAdd = () => {
    if (validateGoals) {
      setState((prevState) => ({
        ...prevState,
        milestones: [...prevState.milestones, { metricTitle: '', metricDescription: '' }],
      }));
    } else {
      errorToast(`Please complete milestone`);
    }
  };

  const onDelete = (id) => {
    const newArray = state?.filter((milestone, index) => index !== id);
    setState((prevState) => ({
      ...prevState,
      milestones: newArray,
    }));
  };

  return (
    <Section>
      <h2>Set Metrics/Milestones</h2>
      <Flex flexDirection={'column'} gap={'17px'}>
        <h3>Below you can set the goal metric you’d like to use to measure</h3>
        <Goals flexDirection={'column'} gap={'17px'}>
          {state?.length > 0 &&
            state?.map((milestone, index) => (
              <Goal
                gridTemplateColumns={'1fr 1fr 20px'}
                gap={'24px'}
                key={index}
                alignItems={'flex-end'}>
                <InputBox
                  id={'metricTitle'}
                  label={'Title'}
                  required={true}
                  value={milestone?.metricTitle}
                  placeholder={'Launch Campaign'}
                  handleChange={(e) => handleChange(e, index)}
                  dataTestId="metricTitle-input"
                />
                <InputBox
                  id={'metricDescription'}
                  label={'Description'}
                  required={true}
                  placeholder={'Lorem ipsum Lorem ipsum Lorem ipsum Lorem '}
                  handleChange={(e) => handleChange(e, index)}
                  value={milestone?.metricDescription}
                  dataTestId="metricDescription-input"
                />
                {index !== 0 && (
                  <Button img={redbutton} onClick={() => onDelete(index)} style={deleteButton} />
                )}
              </Goal>
            ))}
          <Button
            img={greenplus}
            title={`Add a new milestone`}
            style={addMilestone}
            onClick={onAdd}
          />
        </Goals>
      </Flex>
    </Section>
  );
};

export { SetMetrics };
