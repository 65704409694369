import React from 'react';

/**
 * Debouncer for GraphQl query variables
 * @value: {string} - value to watch
 * @setter: {function} - function to call with the updated value of 'value'
 * @timeoutVar: {null} variable initialized with let (must be outside the component)
 * @timeout: {number} number of milliseconds to debounce for*/
function useDebounced(value, setter, timeoutVar, timeout = 500) {
  const valueRef = React.useRef('');
  React.useEffect(() => {
    if (valueRef.current !== value) {
      timeoutVar = setTimeout(() => {
        setter(value);
      }, timeout);
      valueRef.current = value;
    }
    return () => clearTimeout(timeoutVar);
  }, [value]);
}

export { useDebounced };
