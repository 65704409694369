import styled from '@emotion/styled';

export const ErrorMessage = styled.p`
  color: var(--error);
  font-size: 1.2rem;
  margin-top: 5px;

  &::before {
    display: inline;
    content: '⚠ ';
  }
`;

export const Container = styled.div`
  background-color: #fff;
  text-align: center;
  width: 390px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 42px 60px;
  margin: auto;
  box-sizing: border-box;

  &.signup {
    width: 684px;

    .form {
      display: grid;
      gap: 24px;

      grid-template-columns: 1fr 1fr;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.117188px;
    color: #333758;
    text-align: left;
  }

  .sent-email {
    word-break: break-word;
    margin-top: 6rem;
    font-size: 24px;
    font-weight: 400;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    color: rgba(51, 55, 88, 0.3);
  }

  .link {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    text-decoration-line: underline;
    color: #36ca8b;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 21px 0px;
    margin: auto;
    border: none;

    h1 {
      text-align: center;
    }
  }
`;

export const InputFields = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 48px 0 0;
  text-align: left;

  .forgot-btn {
    text-align: right;
  }

  .container {
    display: grid;

    label {
      font-size: 1.2rem;
    }

    input {
      font-size: 1.4rem;
    }
  }
  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export const ButtonStyle = {
  width: '270px',
  height: '42px',
  color: '#fff',
  borderRadius: '3px',
  margin: '32px 0 0',
};

export const LinkStyle = {
  margin: '16px 0 0',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '-0.117188px',
  textDecorationLine: 'underline',
  color: '#36CA8B',
};
