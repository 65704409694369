import { EmployeeAuth } from 'layouts';
import { Login } from 'pages/admin/auth/Login/Login.jsx';

// import { AuthSidebar } from 'components/auth/Login';

const UserLogin = () => {
  return (
    <>
      <Login />
    </>
  );
};

export const EmployeeLogin = () => {
  return (
    <EmployeeAuth>
      <UserLogin />
    </EmployeeAuth>
  );
};
// export const Reset = () => {
//   return (
//     <EmployeeAuth>
//       <AuthSidebar />
//       <UserLogin />
//     </EmployeeAuth>
//   );
// };
