import React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { VscClose } from 'react-icons/vsc';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AddOptionIcon } from 'components/shared/Svgs';

import ToggleSwitch from './ToggleSwitch';

function InputOptionsControl({
  control,
  setValue,
  register,
  buttonCopy,
  isRadio,
  isAttachment,
  fieldName,
}) {
  const { fields: options, append, remove } = useFieldArray({ control, name: 'options' });
  const lastInputRef = React.useRef();
  const isMultiple = useWatch({ control, name: 'multiple' });

  const handleAddOptionClick = () => {
    append('');
    setTimeout(() => {
      if (lastInputRef.current) {
        lastInputRef.current.querySelector('input')?.focus();
      }
    }, 0);
  };

  // function toggleSwitch() {
  //   setValue('multiple', !isMultiple);
  // }

  function onSwitchChange(e) {
    setValue('multiple', e.target.checked);
  }

  return (
    <Grid templateColumns="1fr" templateRows="4rem max-content">
      {!isAttachment && (
        <>
          <GridItem
            justifySelf="flex-start"
            display="grid"
            gridGap="8px"
            color="brand.primary"
            gridTemplateColumns="repeat(2, max-content)"
            alignItems="center"
            fontSize="1.4rem"
            fontWeight="600"
            cursor="pointer"
            data-testid={`add-${fieldName.toLowerCase()}-option`}
            onClick={handleAddOptionClick}>
            {AddOptionIcon} {buttonCopy}
          </GridItem>
          <GridItem display="flex" flexFlow="row wrap">
            {options.map((option, index) => (
              <InputOption
                fieldName={`options.${index}`}
                key={option.id}
                control={control}
                register={register}
                isRadio={isRadio}
                onRemove={() => remove(index)}
                ref={lastInputRef}
              />
            ))}
          </GridItem>
        </>
      )}
      {isAttachment && (
        <GridItem
          display="grid"
          gridTemplateColumns="max-content auto"
          gap="8px"
          pt="1.6rem"
          borderTop="1px solid var(--grey-150)">
          <ToggleSwitch
            checked={isMultiple}
            onChange={onSwitchChange}
            data-testid={`toggle-${fieldName.toLowerCase()}-multiple`}
            id={`toggle-${fieldName.toLowerCase()}-multiple`}
          />
          <Box
            as="label"
            htmlFor={`toggle-${fieldName.toLowerCase()}-multiple`}
            lineHeight="1"
            fontSize="1.4rem"
            cursor="pointer">
            Allow multiple files upload
          </Box>
        </GridItem>
      )}
    </Grid>
  );
}

const InputOption = React.forwardRef(function ({ fieldName, register, isRadio, onRemove }, ref) {
  function focusInput(e) {
    const input = e.target.querySelector('input');
    if (input) input.focus();
  }

  return (
    <InputOptionRoot tabIndex={0} onClick={focusInput} ref={ref}>
      {isRadio && <div className="circle"></div>}
      <input type="text" {...register(fieldName)} />
      <div className="remove-btn" tabIndex={0} onClick={onRemove}>
        <VscClose size={16} />
      </div>
    </InputOptionRoot>
  );
});

InputOption.displayName = 'InputOption';

const InputOptionRoot = styled.div`
  width: max-content;
  height: 3.2rem;
  display: grid;
  grid: 1fr / auto-flow max-content;
  align-items: center;
  gap: 8px;
  margin: 4px 8px 4px 0;
  padding: 4px 8px;
  border-radius: 2rem;
  border: 1px solid var(--grey-150);
  background: var(--bg);
  line-height: 1;
  font-size: 1.4rem;

  .circle {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--grey-300);
    background: #fff;
  }

  .text-value {
    pointer-events: none;
    user-select: none;

    &:empty {
      width: 10rem;
    }
  }

  input {
    width: 0;
    opacity: 0;
    background: transparent;
    //transition: width 200ms linear;

    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: none;
    }
  }

  input {
    width: 100%;
    max-width: 12rem;
    opacity: 1;
  }

  .remove-btn {
    cursor: pointer;
    border-radius: 50%;
    padding: 2px;

    &:hover {
      background: var(--grey-150);
    }
  }

  &:focus,
  &:focus-within {
    box-shadow: 0 0 0 2px var(--primary);

    .text-value {
      display: none;
    }
  }
`;

export { InputOptionsControl };
