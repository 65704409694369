import React from 'react';
import { Flex, Heading, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseDropdown, FullModal, Search, SimpleModal } from 'components';

import { LeaveApprovalTable } from './components/LeaveApprovalTable';
import { LeaveApprovalView } from './components/LeaveApprovalView';
import { useLeaveApprovals } from './hooks/useLeaveApprovals';
import { useSingleLeaveApproval } from './hooks/useSingleLeaveApproval';
import { RejectLeave } from './components';

export function LineManagerLeaveApproval() {
  const [leaveId, setLeaveId] = React.useState('');
  const {
    loading,
    page,
    setFilter,
    setSearchString,
    setPage,
    filter,
    leaveRequests,
    pageCount,
    refetch,
  } = useLeaveApprovals();

  const {
    loading: loadingSingleLeaveRequest,
    onGetSingleLeaveRequest,
    refetch: singleLeaveRequestRefetch,
    leaveRequest: singleLeaveRequest,
  } = useSingleLeaveApproval();

  const [showLeaveApproval, setShowLeaveApproval] = React.useState(false);
  const [showRejectLeave, setShowRejectLeave] = React.useState(false);

  function toggleModal() {
    setShowLeaveApproval((prev) => !prev);
  }
  function toggleRejectModal() {
    setShowRejectLeave((prev) => !prev);
  }

  return (
    <>
      <Root>
        <div className="leave__approval">
          <Flex
            className="leave__approval--top"
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Heading>All Leave Approvals</Heading>

            <Flex>
              <Search
                placeholder={'Search by name'}
                value={filter.query}
                setSearchString={setSearchString}
                setPage={setPage}
                onChange={(e) =>
                  setFilter((prev) => {
                    return { ...prev, query: e.target.value };
                  })
                }
              />
              <BaseDropdown
                w={'140px'}
                dataTestId={'filter-status'}
                placeholder={'status'}
                value={filter.status}
                onChange={(e) =>
                  setFilter((prev) => {
                    return {
                      ...prev,
                      status: e.value,
                    };
                  })
                }
                options={[
                  { label: 'Approvals', value: '' },
                  { label: 'Approved', value: 'approved' },
                  { label: 'Rejected', value: 'rejected' },
                  { label: 'Pending', value: 'pending' },
                  { label: 'Awaiting HR', value: 'awaitingHR' },
                ]}
              />
            </Flex>
          </Flex>
        </div>

        <LeaveApprovalTable
          page={page}
          tData={leaveRequests}
          pageCount={pageCount}
          setPage={setPage}
          toggleModal={toggleModal}
          loading={loading}
          onGetSingleLeaveRequest={onGetSingleLeaveRequest}
          toggleRejectModal={toggleRejectModal}
          setLeaveId={setLeaveId}
        />
      </Root>

      <FullModal showModal={showLeaveApproval} noHeader padding={'0px'}>
        <LeaveApprovalView
          closeModal={toggleModal}
          toggleRejectModal={toggleRejectModal}
          leaveRequest={singleLeaveRequest}
          loading={loadingSingleLeaveRequest}
          refetch={singleLeaveRequestRefetch}
          refetchAllLeaveRequest={refetch}
          setLeaveId={setLeaveId}
        />
      </FullModal>
      <SimpleModal showModal={showRejectLeave} setShowModal={setShowRejectLeave}>
        <RejectLeave
          closeModal={toggleRejectModal}
          leaveId={leaveId}
          refetch={singleLeaveRequestRefetch}
          refetchAllLeaveRequest={refetch}
        />
      </SimpleModal>
    </>
  );
}

const Root = styled(Stack)`
  padding: 32px;
  gap: 32px;

  .css-12xxamh-control{
    box-shadow: none !important;
  }

  .search-wrapper {
    width: 250px;
  }

  .leave__approval {
    width: 100%;
    overflow: auto;

    &--top {
      gap: 50px;
    5
  }
`;
