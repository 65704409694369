export const CandidateTableColumns = [
  {
    Header: 'Applicants',
    accessor: 'col1',
  },
  {
    Header: 'Ratings',
    accessor: 'col2',
  },
  {
    Header: 'Status',
    accessor: 'col3',
  },
  {
    Header: 'Applied',
    accessor: 'col4',
  },
  {
    Header: 'Hiring Manager',
    accessor: 'col5',
  },
];
