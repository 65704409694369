import React from 'react';
import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { filterLabels } from '../helpers';

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media screen and (max-width: 430px) {
    grid-template-columns: 1fr;
  }
`;

export function InfoContainer({ label, openModal, isEditable, data }) {
  return (
    <Stack
      padding={'2.4rem 1.6rem'}
      border={'solid 1px var(--grey-150)'}
      borderRadius={'0.4rem'}
      maxW={'60rem'}>
      <Flex justifyContent={'space-between'}>
        <Heading color={'#364469'} fontSize={'2rem'} marginBottom={'2.4rem'}>
          {label}
        </Heading>
        {isEditable && (
          <Text
            cursor={'pointer'}
            color={'var(--primary)'}
            onClick={openModal}
            data-testid={`${label.split(' ')[0]}-test`}>
            Edit
          </Text>
        )}
      </Flex>

      <InfoWrapper>
        {Object.entries(data).map(([key, value]) => {
          if (filterLabels(label).includes(key)) {
            return (
              <Stack key={key}>
                <Text fontSize={'1.4rem'} color={'var(--grey-500)'}>
                  {key === 'Blood Type' ? 'Genotype' : key}
                </Text>
                <Text color={'var(--deep_blue)'}>{value}</Text>
              </Stack>
            );
          } else return '';
        })}
      </InfoWrapper>
    </Stack>
  );
}
