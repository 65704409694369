/* eslint-disable indent */
import styled from '@emotion/styled';

export const BreadcrumbsRoot = styled.div`
  --active-color: var(--black);
  width: 100%;
  height: 48px;
  display: flex;

  .breadcrumbs__item {
    ${({ len }) => {
      let i = 1;
      let snippet = '';

      while (i < len) {
        snippet += `
          &:nth-of-type(${i}) {
            z-index: ${len - i};
          }
        `;
        i++;
      }

      return snippet;
    }}
`;

export const BreadcrumbsItemRoot = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 48px;
  flex-basis: 25%;
  //max-width: 23rem;
  display: grid;
  grid: 1fr / 24px 1fr;
  align-items: center;
  gap: 8px;
  padding-inline: 16px;
  border-radius: 4px;
  background: var(--grey-100);
  font-size: 14px;
  line-height: 1.2;
  //cursor: pointer;

  &.hide-indicator {
    grid: 1fr / 1fr;
    padding-inline: 20px;
  }

  &:after,
  &:before {
    --side: 36px;
    content: '';
    position: absolute;
    height: var(--side);
    width: var(--side);
    background: white;
    border-radius: 4px;
    transform: rotate(45deg);
    top: 5.5px;
    left: -22px;
  }

  &:after {
    background: inherit;
    left: auto;
    top: 6px;
    right: -16px;
  }

  &:first-of-type {
    &:before {
      display: none;
    }
  }

  &:last-of-type {
    //&:after {
    //  display: none;
    //}
    //
    //&.active {
    //  box-shadow: -2px 0 0 var(--black) inset;
    //}
  }

  & {
    margin-right: 10px;
  }
  &:not(:first-of-type) {
    padding-left: 32px;
  }

  &.active {
    color: var(--white);
    background: var(--grey-300);
    background: var(--active-color);
    //box-shadow: 0 -1px 0 var(--black) inset, 0 1px 0 var(--black) inset;
    // &:after {
    //   box-shadow: -1px 1px 0 var(--black) inset;
    // }
  }

  &.done {
    background: var(--active-color);
    opacity: 0.55;
  }
`;

export const ItemName = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 98%;
  overflow: hidden;
  color: var(--grey-text);
  text-overflow: ellipses;
  word-break: break-all;

  &.active {
    // color: var(--black);
    color: var(--white);
  }

  &.done {
    color: white;
  }
`;

export const ItemIndicator = styled.div`
  --border: var(--bg);
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  border: 1px solid var(--border);
  background: var(--bg);
  color: var(--grey-text);
  font-weight: 700;

  &.active {
    // --border: var(--black);
  }

  &.active,
  &.done {
    background: var(--active-color);
    color: var(--white);
  }
`;
