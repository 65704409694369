import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Flex, FormControl, GridItem, Input, SimpleGrid, Text } from '@chakra-ui/react';

import { BaseDropdown, BaseInput, PhoneInput } from '../../../../components';

const colSpan = { base: 2, md: 1 };

export const KinInformation = ({
  register,
  control,
  getCountryCode,
  countryCode,
  allKinCountries,
  kinStates,
  citiesData,
  isEdit,
}) => {
  return (
    <>
      <Box
        borderBottom="1px solid #F2F2F2"
        pb="0.8rem"
        mt="4rem"
        className="kin__details"
        data-testid="kin-info">
        <Text fontSize="2.4rem" color="#807F7F" mt="3.4rem">
          Next of Kin Information
        </Text>
      </Box>
      <SimpleGrid columns={2} marginTop="4rem" gap="6">
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('emergencyContact.fullName')}
            fontSize="1.6rem"
            label="Name"
            placeholder="Fullname"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('emergencyContact.relationship')}
            fontSize="1.6rem"
            label="Relationship"
            placeholder="Relationship"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          {isEdit ? (
            <BaseInput
              {...register('emergencyContact.phoneNumber')}
              fontSize="1.6rem"
              label="Phone Number"
              placeholder="Phone Number"
            />
          ) : (
            <FormControl>
              <Flex alignContent="center" gap="4">
                <PhoneInput
                  label="Phone Number"
                  getCountryCode={getCountryCode}
                  countryCode={countryCode}
                />
                <Input
                  {...register('emergencyContact.phoneNumber')}
                  h="4.8rem"
                  mt="2.5rem"
                  type="number"
                  fontSize="1.6rem"
                  backgroundColor="#F7F9FB"
                />
              </Flex>
            </FormControl>
          )}
        </GridItem>
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('emergencyContact.occupation')}
            fontSize="1.6rem"
            label="Occupation"
            placeholder="Occupation"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <BaseInput
            {...register('emergencyContact.address')}
            fontSize="1.6rem"
            label="Address"
            placeholder="Address"
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="emergencyContact.country"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                options={allKinCountries}
                labelSize="1.4rem"
                label="Country"
                placeholder="Choose from options"
                border="1px solid #E0E0E0"
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="emergencyContact.state"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                labelSize="1.4rem"
                label="State"
                options={kinStates}
                w="322"
                placeholder="Choose from options"
                border="1px solid #E0E0E0"
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Controller
            defaultValue=""
            control={control}
            name="emergencyContact.city"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                labelSize="1.4rem"
                label="City"
                options={citiesData}
                w="322"
                placeholder="Choose from options"
                border="1px solid #E0E0E0"
              />
            )}
          />
        </GridItem>
      </SimpleGrid>
    </>
  );
};
