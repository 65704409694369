import React from 'react';
import { useMutation } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { CREATE_TRAINING, FETCH_TRAINING } from 'query';

import { TrainingComponent } from 'components';
import { useTrainingComponent } from 'components/admin/training/hooks';

const CreateTraining = ({ setOpenModal, openModal }) => {
  const { successToast, errorToast } = useToastify();
  const [createTraining, { loading: createTrainingLoading }] = useMutation(CREATE_TRAINING);
  const { getDateDiffInSeconds } = useTrainingComponent();

  const onCreateTraining = async (state) => {
    const { attachedFile: fileData, ...rest } = state;

    if (!getDateDiffInSeconds(state?.schedule?.startDate, state?.schedule?.endDate)) {
      errorToast('Due date should be greater than the due date');
      return;
    }

    const variables = { data: rest };

    const file = fileData;

    try {
      const res = await createTraining({
        variables: file ? { ...variables, file } : { ...variables },
        refetchQueries: [
          {
            query: FETCH_TRAINING,
            variables: {
              filter: {
                isShared: true,
              },
              page: 1,
            },
            awaitRefetchQueries: true,
          },
        ],
      });
      const { data } = res;
      if (data && data.createTraining.status === 201) {
        successToast('Training created sucessfully');
        setInterval(setOpenModal(false), 2000);
      } else errorToast(data.createTraining.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  return (
    <TrainingComponent
      openModal={openModal}
      setOpenModal={setOpenModal}
      onCreateTraining={onCreateTraining}
      loading={createTrainingLoading}
      title={'Create a new training program'}
      buttonName={'Create'}
    />
  );
};

export { CreateTraining };
