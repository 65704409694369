import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import EyeCloseIcon from 'assets/icons/eyeClose.svg';
import EyeOpenIcon from 'assets/icons/eyeOpen.svg';
import { useToastify } from 'hooks/useToastify';
import { EmployeeAuth } from 'layouts';
import { setNewPassword } from 'query/employee/auth';
import * as yup from 'yup';

import { BaseInput, CustomButton } from 'components';
import { AuthSidebar } from 'components/auth/Login';

const formSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(7, 'Password length should be at least 7 characters'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
});

export const SetNewPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });
  const [showNewIcon, setShowNewIcon] = React.useState(false);
  const [showConfirmIcon, setShowConfirmIcon] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { errorToast, successToast } = useToastify();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await setNewPassword(data, token);
      successToast(response?.message);
      navigate('/employee/login');
    } catch (error) {
      errorToast(error?.response?.data?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const login = () => {
    navigate('/employee/login');
  };

  const toggleNewIcon = () => {
    setShowNewIcon(!showNewIcon);
  };
  const toggleConfirmIcon = () => {
    setShowConfirmIcon(!showConfirmIcon);
  };

  React.useEffect(() => {
    const errorList = Object.entries(errors);
    if (errorList?.length) {
      errorList.forEach(([, value]) => {
        const message = value?.message;
        errorToast(message);
      });
    }
  }, [errors]);
  return (
    <NewPasswordRoot>
      <AuthSidebar />
      <Box className="set-password-content">
        <h2>Enter New Password</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BaseInput
            data-testid="password"
            {...register('password', { required: true })}
            label="New Password"
            type={showNewIcon ? 'text' : 'password'}
            rightIcon={showNewIcon ? EyeOpenIcon : EyeCloseIcon}
            IconClick={toggleNewIcon}
            placeholder="Enter your new password"
          />
          <BaseInput
            data-testid="password"
            {...register('confirmPassword', { required: true })}
            label="Confirm New Password"
            type={showConfirmIcon ? 'text' : 'password'}
            rightIcon={showConfirmIcon ? EyeOpenIcon : EyeCloseIcon}
            IconClick={toggleConfirmIcon}
            placeholder="Confirm new password"
          />
          <Flex justifyContent="space-between" alignItems="center">
            <p onClick={login} type="button" className="back-to-login">
              Back to Login
            </p>
            <CustomButton name="Reset" mW="240px" type="submit" loading={loading} />
          </Flex>
        </form>
      </Box>
    </NewPasswordRoot>
  );
};

export const EmployeeSetNewPassword = () => {
  return (
    <EmployeeAuth>
      <SetNewPassword />
    </EmployeeAuth>
  );
};

const NewPasswordRoot = styled(Grid)`
  grid-template-columns: 1fr;
  overflow: hidden;
  place-content: center;
  height: 100vh;
  @media (min-width: 980px) {
    grid-template-columns: 1fr minmax(54rem, 1fr);
    height: 100vh;
  }
  .auth-sidebar {
    display: none;
    @media (min-width: 980px) {
      display: block;
    }
  }

  .set-password-content {
    width: 100%;
    max-width: 42rem;
    height: auto;
    margin: auto;
    padding: 0 1rem;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.117188px;
      color: #333758;
      text-align: center;
      margin-bottom: 4.6rem;
    }
  }

  .css-1lekzkb {
    margin-top: 48px;
  }

  .css-1a3iwly {
    margin-top: 24px;
  }
  .css-4302v8 {
    margin-top: 10px;
  }
  .back-to-login {
    cursor: pointer;
  }
`;
