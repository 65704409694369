import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import styled from '@emotion/styled';
import { formatDate } from 'utils';

export const TimeLine = ({ approvalFlow, reason }) => {
  return (
    <Timeline data-testid="employee-timeline">
      <h2>Approval Flow</h2>
      <ul className="stages">
        {approvalFlow?.map((fl, index) => {
          const pretext = fl.status === 'rejected' ? 'Denied by' : 'by';
          const name = `${fl.user.firstName || 'N'} ${fl.user.lastName || '/A'}`;
          return (
            <LI key={index} status={fl.status} className={`li--${fl.status}`}>
              {fl.status === 'approved' && (
                <BsCheck className="approved" color="var(--white)" size="22" />
              )}
              {fl.status === 'rejected' && (
                <IoClose className="close" color="var(--white)" size="18" />
              )}

              <Description className="time" status={fl.status}>
                {fl.activity}
              </Description>
              {fl.status === 'pending' ? (
                <Subtext status={fl.status}>{`Approval ${fl.status}`}</Subtext>
              ) : (
                <Subtext status={fl.status}>{`${pretext} ${name} on ${formatDate(
                  fl.date
                )}`}</Subtext>
              )}
              {reason && fl.status === 'rejected' ? (
                <p className="reason">{`"${reason}"`}</p>
              ) : null}
            </LI>
          );
        })}
      </ul>
    </Timeline>
  );
};

const LI = styled.li`
  border-left: 1px dashed var(--black);
  position: relative;
  padding-bottom: 2.4rem;
  padding-left: 4.4rem;
  &:last-child {
    border: 0px;
    padding-bottom: 0;
  }

  .approved {
    position: absolute;
    left: -11.5px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
  }
  .close {
    position: absolute;
    left: -9.5px;
    top: 2px;
    cursor: pointer;
    z-index: 2;
  }

  &:before {
    content: '';
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    position: absolute;
    left: -11.5px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
    background: var(--white);
    border: 1px solid var(--black);
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
  ${({ status }) => `
    color: ${status ? 'var(--grey-600)' : 'var(--black)'};
  `}
`;

const Subtext = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 400;
  ${({ status }) => `
   color: ${status ? 'var(--grey-600)' : 'var(--black)'};
 `}
`;

const Timeline = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 2.4rem;

  .stages {
    margin-left: 1rem;
  }

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: -0.02em;
    color: var(--black);
    margin-bottom: 2.4rem;
  }

  ul,
  li {
    list-style: none;
  }

  .reason {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--black);
    margin-top: 1.6rem;
  }

  .li--approved {
    &:before {
      border: none;
      background: var(--green);
    }
  }

  .li--rejected {
    &:before {
      border: none;
      background: var(--error);
    }
  }
`;
