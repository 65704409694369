import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const CustomButton = styled.button`
  box-sizing: content-box;
  height: 19px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1469f8;
  width: 10rem;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 0.4rem;
`;
export const ButtonTab = styled(CustomButton)`
  ${({ active }) =>
    `
    color: ${active ? '#3E7EEE' : 'var(--grey-150)'};
    background: ${active ? '#ffffff' : '#BCBFCA'};
  `}
`;

export const CBox = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TaskHeaderBox = styled(Box)`
  height: 6.4rem;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  border-color: 1px solid var(--grey-150);
  background-color: #fff;
  padding: 0 2.4rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
`;

export const HighIcon = (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="highSvg">
    <path
      d="M3.52519 1.13776L0.197457 4.46549C-0.0650052 4.72795 -0.0650052 5.15362 0.197457 5.41608C0.459919 5.67854 0.885591 5.67854 1.14805 5.41608L4.00016 2.56397L6.85225 5.41608C7.11471 5.67854 7.54038 5.67854 7.80284 5.41608C8.0653 5.15362 8.0653 4.72795 7.80284 4.46549L4.47512 1.13776C4.21331 0.875302 3.78765 0.875302 3.52519 1.13776ZM3.32368 5.13897L0.539197 7.78089C0.259755 8.04636 0.259755 8.47635 0.539197 8.74182C0.818639 9.00729 1.27196 9.00729 1.55174 8.74182L3.82995 6.58017L6.10817 8.74182C6.38762 9.00729 6.84125 9.00729 7.12071 8.74182C7.40016 8.47635 7.40016 8.04636 7.12071 7.78089L4.33622 5.13897C4.05678 4.8735 3.60346 4.8735 3.32368 5.13897Z"
      fill={''}
    />
  </svg>
);

export const MediumIcon = (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mediumSvg">
    <path
      d="M9.375 5.19092C9.6 5.19092 9.75 5.04092 9.75 4.81592C9.75 4.59092 9.6 4.44092 9.375 4.44092H2.625C2.4 4.44092 2.25 4.59092 2.25 4.81592C2.25 5.04092 2.4 5.19092 2.625 5.19092H9.375Z"
      fill={''}
    />
    <path
      d="M10.875 0.690918H1.125C0.9 0.690918 0.75 0.840918 0.75 1.06592C0.75 1.29092 0.9 1.44092 1.125 1.44092H10.875C11.1 1.44092 11.25 1.29092 11.25 1.06592C11.25 0.840918 11.1 0.690918 10.875 0.690918Z"
      fill={''}
    />
  </svg>
);

export const LowIcon = (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="lowSvg">
    <path
      d="M4.47481 8.74407L7.80254 5.41635C8.06501 5.15389 8.06501 4.72822 7.80254 4.46575C7.54008 4.20329 7.11441 4.20329 6.85195 4.46575L3.99984 7.31787L1.14775 4.46575C0.885291 4.20329 0.459619 4.20329 0.197157 4.46575C-0.0653048 4.72822 -0.0653048 5.15389 0.197157 5.41635L3.52488 8.74407C3.78669 9.00653 4.21235 9.00653 4.47481 8.74407ZM4.67632 4.74287L7.4608 2.10094C7.74024 1.83547 7.74024 1.40549 7.4608 1.14002C7.18136 0.874551 6.72804 0.874551 6.44826 1.14002L4.17005 3.30166L1.89183 1.14002C1.61238 0.874551 1.15875 0.874551 0.879292 1.14002C0.599837 1.40549 0.599837 1.83547 0.879292 2.10094L3.66378 4.74287C3.94322 5.00834 4.39654 5.00834 4.67632 4.74287Z"
      fill={''}
    />
  </svg>
);

const fillHighSvg = ({ active }) => `fill: ${active === 'high' ? '#FF0000' : '#23273B'}`;
const fillMediumSvg = ({ active }) => `fill: ${active === 'medium' ? '#F0A800' : '#23273B'}`;
const fillLowSvg = ({ active }) => `fill: ${active === 'low' ? '#3E7EEE' : '#23273B'}`;

export const TaskCategory = styled.button`
  border-radius: 0.4rem;
  align-items: center;
  padding: 0.8rem;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #23273b;
  background: #fafcff;
  display: flex;
  align-items: center;
  border: 1px solid var(--grey-150);
  text-transform: capitalize;
  .highSvg {
    margin-right: 0.4rem;
    ${fillHighSvg};
  }
  .mediumSvg {
    margin-right: 0.4rem;
    ${fillMediumSvg};
  }
  .lowSvg {
    margin-right: 0.4rem;
    ${fillLowSvg};
  }
  &.high.active {
    border: 1px solid #ff0000;
    color: #ff0000;
    background: rgba(255, 0, 0, 0.1);
  }
  &.medium.active {
    border: 1px solid #f0a800;
    color: #f0a800;
    background: rgba(240, 168, 0, 0.1);
  }
  &.low.active {
    border: 1px solid#3E7EEE;
    color: #3e7eee;
    background: rgba(62, 126, 238, 0.1);
  }
`;

export const Wrapper = styled.form`
  display: grid;
  grid-gap: 1.6rem;
  height: auto;
  background: #fff;
  border-radius: 0.4rem;
  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
  }
  .toggle-label {
    font-size: 1.4rem;
    font-weight: 600;
    color: #242424;
  }
`;

export const ReapeatOn = styled(Box)`
  background: #fafcff;
  border: 1px solid var(--grey-150);
  border-radius: 4px;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 600;
  color: #23273b;
  cursor: pointer;
  position: relative;
  &.active {
    color: #3e7eee;
    border: 1px solid #3e7eee;
  }
  input[type='checkbox'] {
    width: 100%;
    position: absolute;
    left: 0rem;
    cursor: pointer;
    opacity: 0;
  }
`;

export const RecEventLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #999fa3;
`;

export const TasksHeaderBox = styled(Box)`
  height: 6.4rem;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  border-color: 1px solid #f2f2f2;
  background-color: #fff;
  padding: 0 2.4rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;

  .completed {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #999fa3;
  }
`;
export const TaskButton = styled.button`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TaskButtonTab = styled(TaskButton)`
  ${({ active }) =>
    `
    background: ${active ? '#F2F2F2' : '#fff'};
  `};

  ${({ layoutType }) =>
    `
    border-top-right-radius: ${layoutType === 'table' ? 'inherit' : ''};
    border-bottom-right-radius: ${layoutType === 'table' ? 'inherit' : ''};
    border-top-left-radius: ${layoutType === 'grid' ? 'inherit' : ''};
    border-bottom-left-radius: ${layoutType === 'grid' ? 'inherit' : ''};
  `}
  .text {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #23273b;
  }
`;
