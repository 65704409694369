import { gql } from '@apollo/client';

export const CREATE_GOAL = gql`
  mutation addGoal($data: AddGoalInput!) {
    addGoal(data: $data) {
      status
      message
      data {
        _id
        title
        description
        type
        frequency
        schedule {
          startDate
          dueDate
        }
        milestones {
          metricTitle
          metricDescription
        }
      }
    }
  }
`;

export const RETRIEVE_SINGLE_GOAL = gql`
  query retrieveGoals($filters: GoalFilters) {
    retrieveGoals(filters: $filters) {
      status
      message
      data {
        _id
        title
        description
        type
        status
        creator {
          firstName
          lastName
          imageUrl
        }
        frequency
        schedule {
          startDate
          dueDate
        }
        milestones {
          metricTitle
          metricDescription
          isCompleted
        }
        comment {
          message
        }
        createdAt
      }
    }
  }
`;

export const RETRIEVE_GOALS = gql`
  query retrieveGoals($page: Int) {
    retrieveGoals(page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        title
        description
        type
        status
        creator {
          firstName
          lastName
          imageUrl
        }
        frequency
        schedule {
          startDate
          dueDate
        }
        milestones {
          metricTitle
          metricDescription
          isCompleted
        }
        comment {
          message
        }
      }
    }
  }
`;

export const EDIT_GOAL = gql`
  mutation editGoal($filters: GoalFilters!, $data: EditGoalInput!) {
    editGoal(filters: $filters, data: $data) {
      status
      message
      data {
        _id
        title
        description
        type
        frequency
        schedule {
          startDate
          dueDate
        }
        milestones {
          metricTitle
          metricDescription
          isCompleted
        }
      }
    }
  }
`;

export const DELETE_GOAL = gql`
  mutation removeGoal($goalId: ObjectID!) {
    removeGoal(goalId: $goalId) {
      status
      message
    }
  }
`;

export const SEARCH_GOAL = gql`
  query retrieveGoals($filters: GoalFilters, $page: Int) {
    retrieveGoals(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        title
        description
        type
        creator {
          firstName
          lastName
          imageUrl
        }
        frequency
        schedule {
          startDate
          dueDate
        }
        milestones {
          metricTitle
          metricDescription
        }
      }
    }
  }
`;
export const RETRIEVE_GOALS_STATUS = gql`
  query retrieveGoalStatus {
    retrieveGoalStatus {
      status
      message
      data {
        status
        count
      }
    }
  }
`;
