import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { BaseDropdown, BaseInput, Search } from 'components';

import calendar from '../../../../assets/icons/Calendar copy.svg';

export function AnnouncementContentFilter({ filter, setFilter }) {
  return (
    <Flex justifyContent={'space-between'} p={'24px 0'}>
      <Search
        placeholder={'Search name, year,'}
        value={filter.name}
        onChange={(e) =>
          setFilter((prev) => {
            return { ...prev, name: e.target.value };
          })
        }
      />
      <Flex gap={'16px'}>
        <Flex gap={'8px'} alignItems={'center'}>
          <Text>From</Text>
          <BaseInput
            data-testid={'date-from'}
            type={'date'}
            rightIcon={calendar}
            maxWidth={'160px'}
            value={filter.from}
            onChange={(e) =>
              setFilter((prev) => {
                return { ...prev, from: e.target.value };
              })
            }
          />
        </Flex>

        <Flex gap={'8px'} alignItems={'center'}>
          <Text>To</Text>
          <BaseInput
            type={'date'}
            data-testid={'date-to'}
            rightIcon={calendar}
            maxWidth={'160px'}
            value={filter.to}
            onChange={(e) =>
              setFilter((prev) => {
                return { ...prev, to: e.target.value };
              })
            }
          />
        </Flex>
        <BaseDropdown
          w={'140px'}
          dataTestId={'filter-status'}
          placeholder={'status'}
          value={filter.status}
          onChange={(e) =>
            setFilter((prev) => {
              return {
                ...prev,
                status: e.value,
              };
            })
          }
          options={[
            { label: 'draft', value: 'draft' },
            { label: 'published', value: 'published' },
          ]}
        />
      </Flex>
    </Flex>
  );
}
