import React from 'react';
import { Heading, Stack } from '@chakra-ui/react';

import { General } from './components';

export const ReportView = () => {
  return (
    <Stack gap={'35.5px'} p={'24px'}>
      <Heading fontSize={'24px'}>Report</Heading>
      <General />
    </Stack>
  );
};
