import React from 'react';
import { Img, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import styled from '@emotion/styled';

import threedots from '../../../../assets/icons/three-dots.svg';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionDropdown = (props) => {
  const handleActionClick = (status, id) => {
    props.onClick(status, id);
  };

  return props?.actions?.length ? (
    <Container onClick={(e) => e.stopPropagation()}>
      <Menu>
        <MenuButton className="button" padding={'10px'} style={props.style}>
          <img className="menu-image" src={props.btn ? props.btn : threedots} alt="" />
        </MenuButton>
        <MenuList padding={'16px'} maxH="30rem" overflow="auto" zIndex={1000}>
          {props?.actions?.map((action) => (
            <MenuItem
              data-testid={action?.value}
              color={action?.color}
              gap={'10px'}
              display={'flex'}
              padding={'12px'}
              key={action?.value}
              value={action?.value}
              onClick={() => {
                handleActionClick(action, props?.id);
              }}>
              {action?.src && <Img src={action.src} alt="" />}
              {action?.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Container>
  ) : null;
};

export { ActionDropdown };
