import DefaultIcon from 'assets/icons/default-file.png';
import DocsIcon from 'assets/icons/docx-icon.png';
import PdfIcon from 'assets/icons/pdf-icon.png';
import SheetsIcon from 'assets/icons/sheet-icon.png';

function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return '0 Bytes';
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function getExtFromLink(url) {
  let newArr = url.split('.');
  const extension = newArr[newArr.length - 1];
  const iconDict = {
    pdf: PdfIcon,
    docx: DocsIcon,
    xls: SheetsIcon,
    xlt: SheetsIcon,
    xlsm: SheetsIcon,
    xlsx: SheetsIcon,
  };
  const icon = iconDict[extension] ? iconDict[extension] : DefaultIcon;
  return <img src={icon} style={{ width: '24px', AspectRatio: 1 }} />;
}

const formatText = (action) => {
  return action.replace(/\s+/g, '-').toLowerCase();
};

export { formatFileSize, formatText, getExtFromLink };
