import React from 'react';
import styled from '@emotion/styled';

//Buttons
const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;

  &.stick {
    position: sticky;
    top: 6rem;
    z-index: 40;
    background-color: var(--bg);
  }

  .line {
    position: absolute;
    margin: auto;
    bottom: 0;
    background: #f2f2f2;
    border-radius: 10px;
  }

  @media only screen and (max-width: 500px) {
    overflow: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const Button = styled.button`
  box-sizing: content-box;
  height: 19px;
  line-height: 19px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  background: none;
  border-radius: 1px;

  ${({ withBorder }) =>
    `
       border-bottom: ${withBorder ? '2px solid transparent' : 'none'};
      color: ${withBorder ? '#83889d' : '#999FA3'};
  `}
  z-index: 1;

  @media only screen and (max-width: 500px) {
    border: none;
  }
`;

const ButtonToggle = styled(Button)`
  ${({ active, withLine, withBorder }) =>
    active &&
    `
     border-bottom: ${withBorder ? '2px solid #3E7EEE' : 'none'};
    color: ${withLine ? '#3E7EEE' : '#363740'};
    background-color: ${withBorder ? 'transparent' : '#fff'};
  `}
  :hover {
    ${({ withBorder }) =>
      `
       border-bottom: ${withBorder ? '2px solid #3E7EEE' : 'none'};
      color: ${withBorder ? '#3e7eee' : '#23273B'};
      background-color: ${withBorder ? 'transparent' : '#fff'};

    `}
  }
  @media only screen and (max-width: 500px) {
    border: none;

    :hover {
      border: none;
    }
  }
`;

const Tabs = (props) => {
  return (
    <ButtonGroup style={props.buttons} className={props.className}>
      {props.tabs?.map(({ displayName, value }) => (
        <ButtonToggle
          active={props.selected === value}
          style={props.style}
          onClick={() => props.setSelected(value)}
          key={value}
          withBorder={props.withBorder}
          withLine={props.withLine}>
          {displayName}
        </ButtonToggle>
      ))}
      <div className="line" style={props.lineStyle}></div>
    </ButtonGroup>
  );
};
12;
export { Tabs };
