import { CgSpinner } from 'react-icons/cg';
import styled from '@emotion/styled';
import cx from 'classnames';

function BaseButton(props) {
  const { variant, isLoading, icon, w, h, sm, smp, grey, ...rest } = props;
  const isPrimary = !variant || variant === 'primary';
  const isText = variant === 'text';
  const isOutline = variant === 'outline';
  const isOutlineBlue = variant === 'outline-blue';
  const isSmallPadding = smp;
  const isDark = variant === 'dark';
  return (
    <BaseButtonRoot
      {...rest}
      style={{ width: w ? w : 'auto', ...(h ? { height: h } : {}) }}
      className={cx({
        'outline-blue': isOutlineBlue,
        'sm-padding': isSmallPadding,
        text: isText,
        primary: isPrimary,
        outline: isOutline,
        dark: isDark,
        sm,
        grey,
      })}>
      {(isLoading || icon) && (
        <div className={cx('icon', { loading: isLoading })}>{isLoading && <CgSpinner />}</div>
      )}
      {props.children}
    </BaseButtonRoot>
  );
}

const BaseButtonRoot = styled.button`
  height: 4rem;
  display: grid;
  grid: 1fr / auto-flow max-content;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.1rem 3.2rem 1.2rem;
  border-radius: 4px;
  line-height: 1;
  font-weight: 400;
  cursor: pointer;

  &.sm {
    height: 3.2rem;
    padding: 0.9rem 1.6rem 1.2rem;
    font-size: 1.4rem;
  }

  &.sm-padding {
    padding-inline: 2.4rem;
  }

  &.primary {
    background: var(--blue);
    color: var(--white);

    &:hover {
      opacity: 0.85;
    }

    &:active {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.dark {
    background: var(--black);
    color: var(--white);

    &:hover {
      opacity: 0.85;
    }

    &:active {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.text {
    background: transparent;
    color: inherit;
    padding-inline: 1.6rem;
    margin-inline: 4px;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    &:active {
      background: rgba(0, 0, 0, 0.08);
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.outline {
    border: 1px solid var(--black);
    color: inherit;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    &:active {
      background: rgba(0, 0, 0, 0.08);
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.outline-blue {
    border: 1px solid var(--primary);
    color: var(--primary);

    &:hover {
      background: rgba(62, 126, 238, 0.1);
    }

    &:active {
      background: rgba(62, 126, 238, 0.2);
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.grey {
    color: var(--grey-500);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .icon.loading {
    animation: spin 500ms linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { BaseButton };
