import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Flex, Grid, Link } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import EyeCloseIcon from 'assets/icons/hide-password.svg';
import EyeOpenIcon from 'assets/icons/show-password.svg';
import { useToastify } from 'hooks/useToastify';
import * as yup from 'yup';

import { BaseButton, BaseInput } from '../../../components';
import { applicantSignUp } from '../../../query';

import { Container, ErrorMessage, InputFields } from './styles';

const SignUp = () => {
  const navigate = useNavigate();
  const { errorToast, successToast } = useToastify();
  const [showIcon, setShowIcon] = useState(false);
  const [showConPass, setShowConPass] = useState(false);

  const initialState = {
    fullName: '',
    email: '',
    password: '',
    conPassword: '',
  };
  const mainState = process.env.NODE_ENV === 'test' ? initialState : initialState;

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await applicantSignUp(data);
      successToast('Account created sucessfully');
      navigate(`/applicant/login`);
    } catch (error) {
      errorToast(error.response?.data?.message);
      setIsLoading(false);
    }
  };

  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const signin = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup
      .string()
      .required('Email is required')
      .matches(
        /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
        'Email is invalid! Try format abcd@email.com'
      ),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        passwordRules,
        'Must be at least 8 characters and contain a lowercase, uppercase, number and special case character'
      ),

    conPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], 'Passwords do not match'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: mainState,
    mode: 'onChange',
    resolver: yupResolver(signin),
  });

  return (
    <Container data-testid={'signup'} className={'signup'}>
      <h1>Create an account</h1>
      <InputFields onSubmit={handleSubmit(onSubmit)}>
        <Grid gap={'16px'}>
          <Grid gap={'24px'} templateColumns={'1fr 1fr'}>
            <div>
              <BaseInput
                data-testid={'fullName'}
                {...register('fullName', { required: true })}
                name="fullName"
                label={'Full Name'}
                placeholder={'Enter name'}
              />
              {errors?.fullName?.message && (
                <ErrorMessage> {errors?.fullName?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <BaseInput
                data-testid={'signin-email'}
                {...register('email', { required: true })}
                name="email"
                label={'Email Address'}
                placeholder={'yourmail@email.com'}
              />
              {errors?.email?.message && <ErrorMessage> {errors?.email?.message} </ErrorMessage>}
            </div>
          </Grid>
          <Grid gap={'24px'} templateColumns={'1fr 1fr'}>
            <div>
              <BaseInput
                data-testid={'password'}
                {...register('password', { required: true })}
                name="password"
                label={'Password'}
                placeholder={'Enter password'}
                type={showIcon ? 'text' : 'password'}
                rightIcon={showIcon ? EyeOpenIcon : EyeCloseIcon}
                IconClick={() => setShowIcon(!showIcon)}
              />

              {errors?.password?.message && (
                <ErrorMessage>{errors?.password?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <BaseInput
                {...register('conPassword', { required: true })}
                name="conPassword"
                label={'Confirm Password'}
                placeholder={'Enter password'}
                type={showConPass ? 'text' : 'password'}
                rightIcon={showConPass ? EyeOpenIcon : EyeCloseIcon}
                IconClick={() => setShowConPass(!showConPass)}
              />
              {errors?.conPassword?.message && (
                <ErrorMessage>{errors?.conPassword?.message} </ErrorMessage>
              )}
            </div>
          </Grid>
        </Grid>

        <Flex w={'100%'} justifyContent={'center'}>
          <BaseButton w={'270px'} data-testid={'signup-btn'} type={'submit'} isLoading={isLoading}>
            Create new account
          </BaseButton>
        </Flex>
      </InputFields>

      <Flex alignItems={'center'} gap={'16px'} flexDirection={'column'} mt={'32px'}>
        <p className="text">
          Already have an account? {''}
          <Link
            className="link"
            data-testid={'gotosignin-btn'}
            onClick={() => navigate('/applicant/login')}>
            Login
          </Link>
        </p>
      </Flex>
    </Container>
  );
};

export { SignUp };
