import React from 'react';

import { EmptyState } from 'components';

import ReviewResponses from '../ReviewResponses';
import ReviewResponseWrapper from '../ReviewResponseWrapper';

export const SelfAppraisal = ({ self, name }) => {
  const responses = self?.report[0]?.reviewers?.responses;
  const comments = self?.report[0]?.reviewers?.comments || 'N/A';

  return (
    <ReviewResponseWrapper>
      {responses ? (
        <>
          {responses?.map((sec, i) => (
            <ReviewResponses comments={comments} name={name} section={sec} key={i} />
          ))}
        </>
      ) : (
        <EmptyState />
      )}
    </ReviewResponseWrapper>
  );
};
