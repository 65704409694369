import { gql } from '@apollo/client';

export const BULK_UPLOAD = gql`
  mutation Mutation($file: Upload!) {
    uploadExistingUsers(file: $file) {
      status
      message
    }
  }
`;
