import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Box, Flex, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { FETCH_NOTIFICATIONS } from 'pages/employee/dashboard/query';
import { customDateFormat } from 'pages/employee/dashboard/timesheet/helpers';
import { MARK_ALL_NOTIFICATIONS_AS_READ } from 'query';
import { formatDate, getGraphQLResponse, handleGraphQLResponse } from 'utils';

import { EmptyState, SpinnerComponent } from 'components/common';

import charmTickIcon from '../../assets/icons/charm_tick-double.svg';
import closeIcon from '../../assets/icons/close-grey.svg';

import { CustomButton } from '.';

export function NotificationDropdown({ closeModal }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { successToast, errorToast } = useToastify();

  const { data, loading, refetch } = useQuery(FETCH_NOTIFICATIONS, {
    variables: {
      filter: {
        status: 'recent',
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  function onHandleClick() {
    navigate('/employee');
    closeModal();
  }

  const [markAsRead] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ, {
    onCompleted: onMutationCompleted,
    onError: onMutationError,
  });

  async function onMarkAsRead() {
    await markAsRead();
  }

  function onMutationCompleted(res) {
    handleGraphQLResponse(res);
    const responseObject = getGraphQLResponse(res);
    successToast(responseObject.message);
    refetch();
    closeModal();
  }

  function onMutationError(error) {
    errorToast(error?.message);
  }

  const allNotifications = data?.fetchNotifications?.data?.map((message) => (
    <SingleNotification
      key={message._id}
      id={message._id}
      status={message.status}
      type={message.type}
      isRead={message.readAt == null}
      message={message.data.message}
      category={message.data.category}
      date={message.createdAt}
      closeModal={closeModal}
      sender={message.data.sender}
      data={message}
    />
  ));

  return (
    <Root className="notification__content">
      <Stack p={'16px'} gap={'25px'}>
        <Flex justifyContent={'space-between'} align-items={'center'}>
          <Text fontSize={'20px'} fontWeight={500}>
            Notifications
          </Text>

          <button className="close-btn" onClick={closeModal}>
            <img src={closeIcon} />
          </button>
        </Flex>

        <Text cursor={'pointer'} fontWeight={500} borderBottom={'solid 2px'} w={'max-content'}>
          Recent
        </Text>

        <Stack overflow={'auto'}>
          {loading ? (
            <SpinnerComponent />
          ) : (
            <Stack maxH={'300px'} minH={'250px'}>
              {data?.fetchNotifications?.data.length > 0 ? allNotifications : <EmptyState />}
            </Stack>
          )}
        </Stack>
      </Stack>
      {pathname.startsWith('/admin') ? null : (
        <Flex justifyContent={'space-between'} alignItems={'center'} p={'16px'}>
          <Flex gap={'6px'} onClick={onMarkAsRead}>
            <img src={charmTickIcon} alt="" />
            <Text color={'var(--blue)'}>Mark all as read</Text>
          </Flex>
          {pathname !== '/employee' ? (
            <CustomButton
              onClick={onHandleClick}
              name={'View all notifictions'}
              mW={'146px'}
              h={'40px'}
            />
          ) : null}
        </Flex>
      )}
    </Root>
  );
}

function SingleNotification({ isRead, message, date, closeModal, sender, data }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function onHandleClick() {
    if (pathname.startsWith('/admin')) {
      if (data?.data?.category.match(/leave/i)) {
        navigate('/admin/leave-management');
        return;
      }
      if (data?.data?.category.match(/query/i)) {
        navigate('/admin/query');
        return;
      }
    }
    if (pathname.startsWith('/employee')) {
      if (data?.data?.category.match(/leave/i)) {
        navigate('/employee/leave');
        return;
      }
      if (data?.data?.category.match(/query/i)) {
        navigate('/employee/query');
        return;
      }
    }
    navigate('/employee');
    closeModal();
  }

  return (
    <Flex
      gap={'8px'}
      p={'16px'}
      borderRadius={'8px'}
      _hover={{ backgroundColor: 'var(--grey-100)' }}
      onClick={onHandleClick}>
      <Avatar name={`${sender?.firstName} ${sender?.lastName}`} src={sender?.imageUrl} />

      <Stack gap={'7px'} flexGrow={1}>
        <Flex justifyContent={'space-between'} gap={'16px'} alignItems={'center'}>
          <Stack>
            <Text fontWeight={700} fontSize={'14px'}>
              {message}
            </Text>
          </Stack>
          {isRead ? (
            <Box w={'12px'} h={'12px'} bgColor={'var(--green)'} borderRadius={'50%'}></Box>
          ) : null}
        </Flex>

        <Flex justifyContent={'space-between'} fontSize={'12px'} fontWeight={500}>
          <Text>{customDateFormat(date, true)}</Text>
          <Text>{formatDate(date)}</Text>
        </Flex>
      </Stack>
    </Flex>
  );
}

const Root = styled.div`
  width: 400px;
  .close-btn {
    border: solid 1px var(--grey-300);
    padding: 6px 8px;
    border-radius: 8px;
  }
`;
