import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const CheckboxWrapper = styled(Flex)`
  width: max-content;

  input {
    width: 1.4rem;
    height: 1.4rem;
    background: white;
    border: 1px solid #bcbfca;
    border-radius: 0.4rem;
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .inputItem__blue-checkbox {
    border: 1px solid rgba(62, 126, 238, 0.5);
  }

  .inputItem__green-checkbox[type='checkbox']:checked {
    border: 1px solid #36ca8b;
  }

  .inputItem__blue-checkbox[type='checkbox']:checked {
    border: 1px solid var(--blue);
  }

  input[type='checkbox']::before {
    content: '';
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
  }

  .inputItem__green-checkbox[type='checkbox']:checked::before {
    content: '';
    background-color: #36ca8b;
    border-radius: 0.1rem;
  }

  .inputItem__blue-checkbox[type='checkbox']:checked::before {
    content: '';
    background-color: var(--blue);
    border-radius: 0.1rem;
  }

  input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0.2rem;
    width: 0.8rem;
    height: 0.5rem;
    border-left: 1.5px solid white;
    border-bottom: 1.5px solid white;
    -webkit-transform: translateY(-1.5px) rotate(-45deg);
    transform: translateY(-1.5px) rotate(-45deg);
  }
`;
const TableCheckboxWrapper = styled.th`
  width: 4.2rem;
  padding: 0 1.4rem;

  input {
    width: 1.4rem;
    height: 1.4rem;
    background: white;
    border: 1px solid #bcbfca;
    border-radius: 0.4rem;
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .inputItem__green-checkbox[type='checkbox']:checked {
    border: 1px solid #36ca8b;
  }

  .inputItem__blue-checkbox[type='checkbox']:checked {
    border: 1px solid var(--blue);
  }

  input[type='checkbox']::before {
    content: '';
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
  }

  .inputItem__green-checkbox[type='checkbox']:checked::before {
    content: '';
    background-color: #36ca8b;
    border-radius: 0.1rem;
  }

  .inputItem__blue-checkbox[type='checkbox']:checked::before {
    content: '';
    background-color: var(--blue);
    border-radius: 0.1rem;
  }

  input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0.2rem;
    width: 0.8rem;
    height: 0.5rem;
    border-left: 1.5px solid white;
    border-bottom: 1.5px solid white;
    -webkit-transform: translateY(-1.5px) rotate(-45deg);
    transform: translateY(-1.5px) rotate(-45deg);
  }
`;

const CustomCheckbox = React.forwardRef(
  (
    { onChange, handleChecked, checked, variant, label, value, name, testid, id, textColor },
    ref
  ) => {
    return (
      <Flex className="checkbox-cont" alignItems={'center'}>
        <input
          id={id || name}
          data-testid={testid}
          type={'checkbox'}
          checked={checked}
          className={`inputItem__${variant || 'green-checkbox'}`}
          onChange={onChange || handleChecked}
          value={value}
          name={name}
          ref={ref}
        />
        {label && (
          <Text
            className="checkbox"
            as="label"
            htmlFor={id || name}
            fontWeight="400"
            fontSize={'1.2rem'}
            lineHeight="1.7rem"
            pl={'1.4rem'}
            color={textColor ? `${textColor}` : '#808080'}>
            {label}
          </Text>
        )}
      </Flex>
    );
  }
);

CustomCheckbox.displayName = 'CustomCheckbox';

export { CheckboxWrapper, CustomCheckbox, TableCheckboxWrapper };
