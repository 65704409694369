import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { UserImage } from 'pages/admin/employee/tableConfig';

import { BaseDropdown, BaseInput, CustomButton, SimpleModal } from 'components';

const ExitEmployeeModal = ({
  viewModal,
  control,
  options,
  register,
  adminInfo,
  user,
  loading,
  toggleModal,
  handleSubmit,
  onOpenDelete,
}) => {
  const name = `${user?.firstName} ${user?.lastName}`;
  return (
    <>
      <SimpleModal showModal={viewModal} width="749px">
        <form style={{ padding: '24px' }} onSubmit={handleSubmit}>
          <FormWrapper>
            <h1>Exit Employee</h1>
            <BaseInput label="Name of Employee" value={name} isRequired isDisabled />
            <Controller
              defaultValue=""
              name="type"
              control={control}
              render={({ field }) => (
                <BaseDropdown
                  {...field}
                  placeholder="Choose reason for leaving"
                  options={options}
                  w="100%"
                  fontSize="1.6rem"
                  label="Type of exit"
                  isRequired
                />
              )}
            />
            <BaseInput
              label="Reason for leaving"
              placeholder="Enter reason"
              {...register('reason')}
              isRequired
            />
            <BaseInput {...register('content')} type="textarea" label="Content" />
            <Flex gap="24px" alignItems={'center'}>
              <p className="issued-by">Issued by:</p>
              <Flex gap="16px" justifyContent={'center'} alignItems={'center'}>
                <UserImage
                  imageUrl={adminInfo?.imageUrl}
                  firstName={adminInfo?.firstName}
                  lastName={adminInfo?.lastName}
                  // name={`${adminInfo?.firstName} ${adminInfo?.lastName}`}
                />
                <p className="issuer">
                  {adminInfo?.firstName} {adminInfo?.lastName}
                </p>
              </Flex>
            </Flex>
          </FormWrapper>
          <Flex justifyContent={'flex-end'} gap={'0.8rem'} mt="24px">
            <CustomButton
              variant="black--outline"
              name={'Cancel'}
              mW={'9.4rem'}
              h={'4rem'}
              onClick={toggleModal}
              testId={'closeModal'}
              type="button"
            />
            <CustomButton
              variant="black"
              name={'Save'}
              mW={'9.4rem'}
              h={'4rem'}
              onClick={onOpenDelete}
              type="submit"
              testId={'nextBtn'}
              loading={loading}
            />
          </Flex>
        </form>
      </SimpleModal>
    </>
  );
};

const FormWrapper = styled(Box)`
  padding: 16px 24px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.117188px;
    color: #23273b;
  }

  .imageWrapper {
    img {
      border-radius: 50%;
      width: 32px;
    }
  }

  .issued-by {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.117188px;
    color: #83889d;
  }

  .issuer {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #83889d;
  }
`;

export { ExitEmployeeModal };
