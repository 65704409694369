import React from 'react';
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_ALL_EMPLOYEES } from '../../../query';

export function useAssetManagement() {
  let tabs = [
    { displayName: `LOG`, value: 'log' },
    { displayName: `CATEGORY`, value: 'category' },
  ];

  const [selectedButton, setSelectedButton] = useState('log');
  return {
    selectedButton,
    setSelectedButton,
    tabs,
  };
}

export function useEmployeeOptions() {
  const [fetchEmployees] = useLazyQuery(FETCH_ALL_EMPLOYEES);
  const filters = { isDefaultAdmin: false };
  const [options, setOptions] = React.useState([]);

  function loadOptions(searchString) {
    return fetchEmployees({
      variables: {
        page: 1,
        filters: searchString ? { ...filters, searchString: searchString } : { ...filters },
      },
    }).then((res) => {
      const employees = res.data.fetchUsersFiltered.data;
      return employeesToOptions(employees);
    });
  }

  function employeesToOptions(employees) {
    return employees.map((emp) => {
      const name = `${emp.firstName} ${emp.lastName}`;
      return { value: emp._id, label: name };
    });
  }

  React.useEffect(() => {
    fetchEmployees({
      variables: {
        page: 1,
        filters: { ...filters },
      },
    }).then((res) => {
      const employees = res.data.fetchUsersFiltered.data;
      setOptions(employeesToOptions(employees));
    });
  }, []);

  return { loadOptions, options };
}
