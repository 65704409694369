import { useFormState } from 'react-hook-form';

import { useJobDetailsForm } from '../../../../context';
import { inputError } from '../../../../utils';
import { CustomInput } from '../../../shared';
import { JobInputLabel } from '../JobInputLabel';

function ExpectedSalary() {
  const { register, control } = useJobDetailsForm();
  const path = 'salary';
  const formState = useFormState({ control });

  return (
    <>
      <JobInputLabel>Expected Salary</JobInputLabel>
      <CustomInput {...register(path)} placeholder="Ghc6,000" />
      {inputError(formState.errors, path)}
    </>
  );
}

export { ExpectedSalary };
