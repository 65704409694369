import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';

import { BaseDropdown, SpinnerComponent } from '../../../../../../components';
import { useReviewCycleForm } from '../../../../../../context/reviewCycleForm';
import { FETCH_ALL_REVIEW_TEMPLATES, FETCH_ALL_TEAMS } from '../../../../../../query';

import { InputLabel } from './InputLabel';
import { SectionTitle } from './SectionTitle';

export function SelectTemplate() {
  const [templateOptions, setTemplateOptions] = React.useState([]);

  const { control } = useReviewCycleForm();
  const team = useWatch({ control, name: 'team' });

  const { data: teamsData, loading } = useQuery(FETCH_ALL_TEAMS);
  const { data: templateData } = useQuery(FETCH_ALL_REVIEW_TEMPLATES, {
    variables: { filter: { team: team?.value }, page: 1 },
  });

  React.useEffect(() => {
    const optionList = templateData?.fetchAllTemplates?.data ?? [];
    const preTemplateOptions = optionList.map((template) => ({
      value: template._id,
      label: template.name,
    }));
    setTemplateOptions(preTemplateOptions);
  }, [team, templateData]);

  if (loading) return <SpinnerComponent h="20rem" />;

  const teamsOptions = teamsData?.fetchTeams?.data?.map((team) => ({
    value: team._id,
    label: team.name,
  }));

  return (
    <Box maxW="1106px">
      <Box p="24px" border="1px solid var(--grey-150)" maxW="752px" w="100%" mx="auto" my="48px">
        <SectionTitle mb="16px">Choose Title</SectionTitle>

        <Box w="100%" mb="16px">
          <InputLabel>Teams</InputLabel>
          <Controller
            control={control}
            name="team"
            render={({ field }) => (
              <BaseDropdown {...field} options={teamsOptions} placeholder="Product Team" w="100%" />
            )}></Controller>
        </Box>

        <Box w="100%">
          <InputLabel>Select a template to use</InputLabel>
          <Controller
            control={control}
            name="templateId"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                options={templateOptions}
                placeholder="Annual Marketing Team"
                w="100%"
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
