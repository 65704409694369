import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { RETRIEVE_GOALS } from 'query';
import { SEARCH_GOAL } from 'query';

import { Pagination, Search } from 'components';

import GoalsTable from './goalsTable/GoalsTable';

export function GoalsView() {
  const [searchString, setSearchString] = useState('');
  const [search, setSearch] = useState('');
  // const filters = {};
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);

  const {
    data: goalData,
    loading,
    refetch,
  } = useQuery(RETRIEVE_GOALS, {
    variables: {
      // filters: searchString ? { ...filter, keyword: searchString } : filter,
      page: page,
    },
  });

  const { data: searchGoals } = useQuery(SEARCH_GOAL, {
    variables: {
      filters: {
        searchString: searchString ? searchString : '',
      },
      page: page,
    },
  });

  useEffect(() => {
    const fetchedData = goalData?.retrieveGoals?.data;
    const searchGoalsData = searchGoals?.retrieveGoals?.data;
    searchString ? setAllData(searchGoalsData) : setAllData(fetchedData);
  }, [goalData, searchGoals]);

  function handleChange(event) {
    setSearch(event);
  }

  const totalPages = goalData?.retrieveGoals?.pageCount || 1;

  return (
    <Flex gap={'24px'} flexDirection={'column'} background={'#ffffff'} padding={'24px'} w={'100%'}>
      <Search
        placeholder={'Search name, year'}
        value={search}
        setSearchString={setSearchString}
        onChange={(e) => handleChange(e.target.value)}
        setPage={setPage}
      />

      <GoalsTable goalData={allData} loading={loading} refetch={refetch} />

      {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
    </Flex>
  );
}
