import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Calendar from 'assets/icons/dateCalendar.svg';
import { useEmployeeOptions } from 'context';
import { employmentType } from 'data/dropDownOptions';
import { FETCH_ALL_TEAMS } from 'query';

import { BaseDropdown, BaseInput, CustomButton } from 'components';

import { HeadingText } from '../onboard-components/Heading';

export const Employment = ({ register, control, setNextTab }) => {
  const watchDepartment = useWatch({ control, name: 'department' });
  const { getOption } = useEmployeeOptions({ department: watchDepartment });
  const { data } = useQuery(FETCH_ALL_TEAMS);

  const teamDropDown = data?.fetchTeams?.data.map((item) => {
    return {
      label: item.name,
      value: item._id,
    };
  });

  return (
    <EmploymentRoot>
      <Grid className="employment-grid-wrap">
        <GridItem w="218px">
          <HeadingText headingText="Employment Information" mb="10px" />
        </GridItem>
        <GridItem>
          <EmploymentFormRoot>
            <Grid className="employment-form-grid-wrap">
              <GridItem>
                <BaseInput
                  {...register('employmentInfo.hireDate', { required: true })}
                  fontSize="1.6rem"
                  type="date"
                  right={'1rem'}
                  rightIcon={Calendar}
                  border="none"
                  label="Hire Date"
                  isRequired
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="employmentInfo.employmentType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose Type"
                      options={employmentType}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Employment Type"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="department"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      options={getOption('department')}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Department"
                      placeholder="Choose Department"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="designation"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      options={getOption('designation')}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Designation"
                      placeholder="Choose Designation"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  defaultValue=""
                  name="team"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder="Choose Team"
                      options={teamDropDown}
                      w="100%"
                      fontSize="1.6rem"
                      color="#333758"
                      label="Team"
                      isRequired
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <BaseInput
                  {...register('gradeLevel')}
                  label="Grade Level/ Role"
                  fontSize="1.6rem"
                  disabled
                />
              </GridItem>
              <GridItem mb="2.4rem">
                <Controller
                  defaultValue=""
                  name="lineManager/hod"
                  control={control}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      options={employmentType}
                      w="100%"
                      fontSize="1.6rem"
                      placeholder=""
                      color="#333758"
                      label="Line Manager"
                      isDisabled
                    />
                  )}
                />
              </GridItem>
            </Grid>
            <CustomButton
              name="Next"
              variant="primary"
              mW="131px"
              onClick={setNextTab}
              type="button"
            />
          </EmploymentFormRoot>
        </GridItem>
      </Grid>
    </EmploymentRoot>
  );
};

const EmploymentRoot = styled.div`
  padding: 40px 0 155px 16px;
  .employment-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 1200px) {
      gap: 105px;
      grid-template-columns: 218px 500px;
    }
  }
`;

const EmploymentFormRoot = styled.div`
  position: relative;

  .employment-form-grid-wrap {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: 900px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
    @media (min-width: 1200px) {
      gap: 24px 16px;
      grid-template-columns: repeat(2, 242px);
    }
  }

  .inputType {
    border: none;
  }
  .css-1iyp8s0 {
    position: absolute;
    right: 0;
    margin-top: 48px;
    padding: 12px 48px;
  }

  .css-4302v8 {
    margin-top: 8px;
  }

  .css-b62m3t-container,
  .react-select--is-disabled {
    margin-top: 8px;
  }

  .css-18v3ng0-control {
    border: none;
  }
`;
