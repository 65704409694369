import React from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';

import { BaseDropdown } from '../../../../../../components';
import { useHandleCreateJob, useJobDetailsForm } from '../../../../../../context';
import { FETCH_ASSESSMENT_TITLES } from '../../../../../../query';
import { useEditField } from '../hooks';

import { FieldWrapper } from './FieldWrapper';

export function Assessment() {
  const { watch, setValue } = useJobDetailsForm();
  const assessment = watch('assessmentId');
  const assessmentDetails = watch('assessment');
  const defaultValues = {
    assessment: assessment ? { value: assessment, label: assessmentDetails.title } : null,
  };
  const fieldForm = useForm({
    defaultValues,
  });
  const { handleSubmit, reset } = fieldForm;
  const { onEditJob } = useHandleCreateJob();

  function onSave(data) {
    setValue('assessmentId', data.assessment);
    setValue('assessment', { _id: data.assessment, title: data.assessment?.label });
    onEditJob();
  }

  const editOptions = useEditField({
    onSave: handleSubmit(onSave),
    reset,
    defaults: defaultValues,
  });

  const { isEditing } = editOptions;

  return (
    <FieldWrapper label="Assessment" editable editOptions={editOptions}>
      {!isEditing && <Box>{assessmentDetails?.title ?? 'N/A'}</Box>}
      {!!isEditing && (
        <Box pt="1rem">
          <FormProvider {...fieldForm}>
            <AssessmentEditInput />
          </FormProvider>
        </Box>
      )}
    </FieldWrapper>
  );
}

function AssessmentEditInput() {
  const { control } = useFormContext();
  const [defaultOptions, setOptions] = React.useState([]);

  const [fetchAssessments] = useLazyQuery(FETCH_ASSESSMENT_TITLES);

  React.useEffect(() => {
    getAssessmentsAsOptions('').then((res) => setOptions(res));
  }, []);

  function loadOptions(searchString) {
    return getAssessmentsAsOptions(searchString);
  }

  async function getAssessmentsAsOptions(searchString) {
    return fetchAssessments({
      variables: {
        filter: { keyword: searchString },
        page: 1,
      },
    }).then((res) => {
      return res.data?.fetchTitles?.data?.map((assessment) => ({
        value: assessment._id.id,
        label: assessment._id.title,
      }));
    });
  }

  return (
    <Controller
      control={control}
      name="assessment"
      render={({ field }) => (
        <BaseDropdown
          {...field}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          placeholder="Choose assessment"
          w={'100%'}
          clearable
          async
        />
      )}
    />
  );
}
