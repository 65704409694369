import { gql } from '@apollo/client';

export const ADD_ASSET = gql`
  mutation createAsset($data: AssetInput!) {
    createAsset(data: $data) {
      status
      message
      data {
        _id
        name
        category
        serialNumber
        notes
        cost
        dateAssigned
      }
    }
  }
`;

export const FETCH_ALL_ASSETS = gql`
  query fetchAssets($filter: AssetFilters!, $page: Int) {
    fetchAssets(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      pageCount
      data {
        _id
        name
        category
        dateReturned
        serialNumber
        assignedTo {
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;

export const FETCH_ALL_CATEGORIES = gql`
  query fetchAllCategories($filter: AssetFilters!, $page: Int!) {
    fetchAllCategories(filter: $filter, page: $page) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const EDIT_ASSET = gql`
  mutation editAsset($filters: AssetFilters, $data: AssetInput) {
    editAsset(filters: $filters, data: $data) {
      status
      message
      data {
        name
        category
        serialNumber
        notes
        cost
        dateAssigned
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($category: String!) {
    deleteCategory(category: $category) {
      status
      message
      data
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation editCategory($category: String, $newCategory: String) {
    editCategory(category: $category, newCategory: $newCategory) {
      status
      message
      data
    }
  }
`;
