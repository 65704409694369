import React from 'react';
import styled from '@emotion/styled';

const Checkbox = React.forwardRef((props, ref) => {
  return <input type="checkbox" {...props} ref={ref} />;
});

Checkbox.displayName = 'Checkbox';

export const ToggleSwitch = styled(Checkbox)`
  position: relative;
  width: 2.8rem;
  min-width: 2.8rem;
  height: 1.6rem;
  border-radius: 1rem;
  background: var(--grey-300);
  appearance: none;
  cursor: pointer;
  transition: background-color 100ms linear;

  &:before {
    content: '';
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    background: #fff;
    left: 2px;
    top: 2px;
    transition: left 100ms ease;
  }

  &:checked {
    background: var(--green);
  }

  &:checked:before {
    left: 14px;
  }
`;

export default ToggleSwitch;
