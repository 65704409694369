import React from 'react';
import { Stack } from '@chakra-ui/react';

import { Pagination, TableComponent } from 'components';

import { TimeLabel } from '../TimeLabel';

import { columns } from './data';

export function SingleEmployeeTimesheetTable({ page, setPage, loading, tData = [], pageCount }) {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const tableData = tData?.map(({ _id, time, in: timeIn, out: timeOut, createdAt }) => {
    return {
      id: _id,
      day: formatDate(createdAt),
      time,
      in: <TimeLabel time={timeIn} variant={'in'} />,
      out: <TimeLabel time={timeOut} variant={'out'} />,
    };
  });

  return (
    <Stack overflow={'auto'} gap={'16px'}>
      <>
        <Stack overflow={'auto'}>
          <TableComponent
            columns={columns}
            noSort
            noAction
            include="Status"
            noCheckbox
            loading={loading}
            data={tableData}
            //handleMultiSortBy={handleMultiSortBy}
            empty={tData?.length === 0}
            ids={tData?.map((item) => item._id)}
          />
        </Stack>

        {tableData.length > 0 && (
          <Pagination page={page} setPage={setPage} totalPages={pageCount || 1} />
        )}
      </>
    </Stack>
  );
}
