import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

import { CBox, CText, NavigateBtn } from '../styles';

export const TableHeader = () => {
  let navigate = useNavigate();
  return (
    <CBox className="hiring-table-header">
      <Flex alignItems="center">
        <CText>Hiring Pipeline</CText>
      </Flex>
      <NavigateBtn onClick={() => navigate('jobs')}>View all Jobs</NavigateBtn>
    </CBox>
  );
};

export const JobApplicant = (hire) => {
  return (
    <Box data-testid="job-applicant">
      <Text mb="0.4rem">{hire._id}</Text>
      <Text color="#999FA3" fontSize="0.8rem" lineHeight="1rem" fontWeight="500">
        Total Applicant:{' '}
        <Text
          as="span"
          fontSize="0.8rem"
          lineHeight="1rem"
          fontWeight="700"
          color="#23273B"
          letterSpacing="-0.05rem"
          ml="0.4rem">
          {hire.applicantCount}
        </Text>
      </Text>
    </Box>
  );
};

// export const tableCols = [
//   {
//     Header: 'Jobs',
//     accessor: 'col1',
//   },
//   ...stagesCols,
//   // {
//   //   Header: 'New Applied',
//   //   accessor: 'col2',
//   // },
//   // {
//   //   Header: 'Screening',
//   //   accessor: 'col3',
//   // },
//   // {
//   //   Header: 'Assessment',
//   //   accessor: 'col4',
//   // },
//   // {
//   //   Header: 'Interview',
//   //   accessor: 'col5',
//   // },
//   // {
//   //   Header: 'Offer',
//   //   accessor: 'col6',
//   // },
//   // {
//   //   Header: 'Hired',
//   //   accessor: 'col7',
//   // },
// ];
