import { Outlet } from 'react-router-dom';
import { Box, Image } from '@chakra-ui/react';
import cornerPattern from 'assets/images/corner-pattern.svg';

import { JobHeader } from './JobHeader';

export function ApplicantAuth() {
  return (
    <Box>
      <JobHeader />
      <Box
        pos="relative"
        overflowY="auto"
        top="110px"
        px="24px"
        paddingBlock="32px 40px"
        minH="calc(100vh - 110px)">
        <Outlet />
        <Image
          src={cornerPattern}
          height="50%"
          maxHeight="44.8rem"
          position="absolute"
          bottom="0"
          right="0"
          zIndex="-1"
        />
      </Box>
    </Box>
  );
}
