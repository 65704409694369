import React from 'react';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { SpinnerComponent } from 'components';

export const LastAppraisalReport = ({ report, loading }) => {
  const review = {
    self: '28/30%',
    manager: '28/30%',
    peer: '28/30%',
  };
  return (
    <Root className="top__panel-right card">
      {loading ? (
        <SpinnerComponent />
      ) : (
        <>
          <Flex justifyContent={'space-between'}>
            <p className="custom-text">Last Appraisal Report</p>
            {report?.length === 0 ? null : (
              <p className="custom-text grey">June 08, 2021- June 08, 2022</p>
            )}
          </Flex>

          <Flex gap="3rem" className="stats-wrap">
            <Box>
              <Text className="small-text blue">Result</Text>
              <p className="percentage">86%</p>
            </Box>
            <Grid className="stats-report">
              {Object.entries(review).map(([key, value], i) => {
                return (
                  <GridItem key={i}>
                    <p className="small-text grey">{`${key}-review`}</p>
                    <p className="medium-text">{value}</p>
                  </GridItem>
                );
              })}
            </Grid>
          </Flex>
        </>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4.8rem;
  max-width: 662px;
  .percentage {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -0.12px;
    color: #3e7eee;
  }

  .medium-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.808889px;
    color: #333758;
    margin-top: 0.8rem;
  }

  .stats-report {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid #f2f2f2;
    padding: 1.6rem 2.4rem;
    gap: 1.6rem;
    @media (min-width: 520px) {
      grid-template-columns: repeat(2, minmax(auto, 166px)) auto;
    }
  }
`;
