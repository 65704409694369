import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { createActionHandler, formatDate } from 'utils';

import { GridView, TableComponent } from '../../../components';
import { useToastify } from '../../../hooks/useToastify';

import { tableCols } from './data';
import { SubmitForm } from './SubmitEditForm';
import { Contact, Status, UserImage } from './tableConfig';

const keys = {
  Name: 'name',
  Team: 'team_name',
  Status: 'status',
  'Date Hired': 'date_hired',
};

const BoxWrapper = styled(Box)`
  box-sizing: border-box;
  margin-top: 1rem;
  overflow-y: auto;
  min-height: 60vh;
  padding-bottom: 10rem;
  thead tr th {
    min-width: 100px;
  }
  thead tr th:first-of-type {
    min-width: 30px;
  }
  thead tr th:last-of-type {
    min-width: 30px;
  }
`;

const EmployeeTable = (props) => {
  const {
    tData,
    setTData,
    activeTab,
    setSelectAll,
    handleSelectAll,
    selectAll,
    checked,
    setChecked,
    setShowViewModal,
    loading,
    error,
    refetch,
    setShowModal,
  } = props;
  const [sortBy, setSortBy] = useState('');
  const { errorToast } = useToastify();

  const { SubmitDetails } = SubmitForm({});

  const newTableData = tData?.map(
    ({ firstName, lastName, email, status, employmentInfo, imageUrl, team, phoneNumber }) => {
      const name = `${firstName} ${lastName}`;
      const statusChecked = status === 'pending' ? 'Unverified' : status;
      return {
        col1: UserImage({ name, imageUrl, firstName, lastName }),
        col2: Contact({ email, phoneNumber }),
        col3: team?.name || 'N/A',
        col4: formatDate(employmentInfo.hireDate) || null,
        col5: Status({ statusChecked }),
      };
    }
  );

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];
    const sortedData = tData?.map((e) => {
      return {
        ...e,
        date_hired: e?.employmentInfo?.hireDate || '',
        name: `${e?.firstName} ${e?.lastName}`,
        team_name: e?.team?.name,
      };
    });
    if (key) {
      sortedData.sort((a, b) => {
        return desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
      });
      setTData(sortedData);
    }
  };

  const viewUser = ({ id }) => {
    setShowViewModal({ showViewModal: true, empId: id });
  };

  const exitAction = async ({ action, id }) => {
    const statusVal = action === 'exit-user' ? 'exited' : '';
    await SubmitDetails({ id: id, dataToSend: { status: statusVal } });
    refetch();
  };

  const statusChangeAction = async ({ action, id, status }) => {
    if (status === 'pending') {
      errorToast(`User hasn't logged in yet`);
      return;
    }
    const statusVal = action === 'deactivate' ? 'suspended' : 'active';
    await SubmitDetails({ id: id, dataToSend: { status: statusVal } });
    refetch();
  };

  const sendMessage = ({ id }) => {
    setChecked((prev) => (checked.includes(id) ? prev.filter((cur) => cur !== id) : [...prev, id]));
    setShowModal(true);
  };

  const actionOnData = createActionHandler({
    sendMessage,
    viewUser,
    statusChangeAction,
    exitAction,
  });

  const handleChecked = (email, id) => {
    setChecked((prev) => (checked.includes(id) ? prev.filter((cur) => cur !== id) : [...prev, id]));
  };
  const checkedBool = tData?.map((emp) => {
    return checked.includes(emp._id);
  });

  const dropdownActions = ['View', 'Send Message', 'Deactivate', 'Activate', 'Exit user'];
  return (
    <>
      <BoxWrapper data-testid="employee-table">
        {activeTab === 'tableLayout' && (
          <TableComponent
            loading={loading}
            error={error}
            key={sortBy}
            exclude={'Contact'}
            data={newTableData}
            columns={tableCols}
            actionOnData={actionOnData}
            handleSelectAll={handleSelectAll}
            handleChecked={handleChecked}
            checked={checked}
            checkedBool={checkedBool}
            emails={tData?.map((e) => e.email)}
            ids={tData?.map((e) => e._id)}
            empStatus={tData?.map((e) => e.status)}
            allChecked={selectAll}
            setAllChecked={setSelectAll}
            handleMultiSortBy={handleMultiSortBy}
            empty={tData?.length === 0}
            dropdownActions={dropdownActions}
          />
        )}
        {activeTab === 'gridLayout' && (
          <GridView
            data={tData}
            emails={tData?.map((e) => e.email)}
            ids={tData?.map((e) => e._id)}
            handleChecked={handleChecked}
            checked={checked}
            checkedBool={checkedBool}
            actionOnData={actionOnData}
            loading={loading}
            error={error}
            empty={tData?.length === 0}
          />
        )}
      </BoxWrapper>
    </>
  );
};

export { EmployeeTable };
