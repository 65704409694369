import React from 'react';
import { Text } from '@chakra-ui/react';

function inputError(errors, path) {
  const exists = getDeep(errors, path);
  if (exists) {
    const message = exists.message;
    return (
      <Text color="brand.error" mt="0.5rem" fontSize="1.4rem">
        {message}
      </Text>
    );
  }
}

function getDeep(inputObj, path) {
  const pathArr = Array.isArray(path) ? path : path?.split('.');

  function getNested(obj, readyPath) {
    if (!obj) return obj;
    if (readyPath?.length === 1) return obj?.[readyPath];
    const first = readyPath.shift();
    const key = Array.isArray(obj) ? Number(first) : first;
    return getNested(obj?.[key], readyPath);
  }

  return getNested(inputObj, pathArr);
}

export { getDeep, inputError };
