import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  select {
    background: transparent;
    outline: none;
  }
`;

const Dropdown = (props) => {
  // const handleDropdownClick = (list, value) => {
  //   props.onChange(list, value);
  // };
  return (
    <Container style={props.style} withStatusStyle={props.withStatusStyle}>
      <select data-testid="select" placeholder={props.placeholder} onChange={props.onChange}>
        {props.list?.map((li) => (
          <option data-testid="select-option" key={li.value || li._id} value={li.value || li._id}>
            {li.label || li.name}
          </option>
        ))}
      </select>
    </Container>
  );
};
export { Dropdown };
