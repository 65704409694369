import { useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { DOWNLOAD_LINE_MANAGER, FETCH_LINE_MANAGER } from 'query/admin/line-manager/line-manager';
import { getGraphQLResponse, handleGraphQLResponse } from 'utils';

import { ActionDropdown, BaseButton, Pagination, Search } from 'components';

import plus from '../../../assets/icons/plus copy.svg';

import Download from './components/image/Download.svg';
import { ManagerTable } from './components/manager-table/ManagerTable';
import { AddLineManager } from './components';

const dropDownForTable = [
  {
    label: 'CSV',
    value: 'csv',
  },
];

export function LineManagerView() {
  const [search, setSearch] = useState('');
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const { successToast, errorToast } = useToastify();

  const [getCsvData] = useLazyQuery(DOWNLOAD_LINE_MANAGER, {
    onCompleted: (res) => {
      handleGraphQLResponse(res);
      const responseObject = getGraphQLResponse(res);
      const csvData = responseObject.data;
      const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`;

      const link = document.createElement('a');
      link.setAttribute('href', csvContent);
      link.setAttribute('download', 'line-managers.csv');
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      successToast('Line Managers downloaded sucessfully');
    },
    onError: (error) => {
      errorToast(error?.message);
    },
  });

  const actionPerform = async (action) => {
    switch (action?.value) {
      case 'csv':
        await getCsvData();
        break;
    }
  };

  const {
    data,
    loading: fetchManagerLoading,
    refetch,
  } = useQuery(FETCH_LINE_MANAGER, {
    variables: {
      keyword: searchString,
      page,
    },
  });

  const totalPages = data?.fetchLineManagers?.pageCount || 1;
  const totalCount = data?.fetchLineManagers?.totalCount || 1;

  const tData = useMemo(() => {
    const newTableData = data?.fetchLineManagers?.data || [];
    return newTableData;
  }, [data, searchString, page]);

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  return (
    <Container>
      <Grid gap={'16px'}>
        <Flex flexWrap={'wrap'} gap={'30px'} justifyContent={'space-between'}>
          <Box className="text">
            <Text className="text-1">Line Manager</Text>
            <Text className="text-2">Total: {totalCount} people</Text>
          </Box>
          <BaseButton onClick={() => setShowModal(true)}>
            <Image src={plus} />
            Add Line Manager
          </BaseButton>
        </Flex>
        <Flex justifyContent={'space-between'} mb={'35px'} h={'4.7rem'}>
          <Search
            placeholder={'Search by name'}
            value={search}
            setPage={setPage}
            setSearchString={setSearchString}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <Flex alignItems={'center'} gap={'0px 16px'}>
            <ActionDropdown
              actions={dropDownForTable}
              btn={Download}
              onClick={actionPerform}></ActionDropdown>
          </Flex>
        </Flex>
      </Grid>
      <AddLineManager refetch={refetch} showModal={showModal} setShowModal={setShowModal} />
      <ManagerTable
        refetch={refetch}
        search={searchString}
        page={page}
        data={tData}
        loading={fetchManagerLoading}
      />
      {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
    </Container>
  );
}

const Container = styled.div`
  margin: 40px 24px;
  background: #fafcff;

  .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }

  .text {
    color: var(--black-hr-ms, #23273b);

    &-1 {
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &-2 {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .contact {
    gap: 8px;
  }

  .status {
    display: flex;
    justify-content: center;
    width: 78px;
    padding: 8px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px; /* 100% */

    &.active {
      border: 1px solid var(--green-status, #36ca8b);
      background: rgba(54, 202, 139, 0.1);
      color: #36ca8b;
    }

    &.deactivated {
      border: 1px solid var(--notification-red, #fa4a4a);
      background: rgba(255, 82, 82, 0.1);
      color: #fa4a4a;
    }

    &.unverified {
      border: 1px solid var(--pending, #f0a800);
      background: rgba(240, 168, 0, 0.1);
      color: #f0a800;
    }
  }

  .modal_wrapper {
    border-radius: 4px !important;
    padding: 24px !important;
  }

  @media only screen and (max-width: 900px) {
    margin: 20px;

    .search-wrapper {
      width: 100%;
    }
  }
`;
