import React from 'react';
import { Stack, Text } from '@chakra-ui/react';

import editProfileIcon from '../../../../../assets/icons/editProfile.svg';
import performanceIcon from '../../../../../assets/icons/performance.svg';
import requestIcon from '../../../../../assets/icons/request.svg';

import { SingleQuickLink } from './SingleQuickLink';

export function QuickLinks() {
  return (
    <div className="quickLinks__container">
      <Text fontWeight={'600'} marginBottom={'0.8rem'}>
        Quick Links
      </Text>
      <Stack>
        <SingleQuickLink link="/employee/profile" src={editProfileIcon} name={'Edit Profile'} />
        <SingleQuickLink
          link={'/employee/leave-request'}
          src={requestIcon}
          name={'Request Leave'}
        />
        <SingleQuickLink
          link="/employee/appraisal"
          src={performanceIcon}
          name={'View Performance'}
        />
      </Stack>
    </div>
  );
}
