import React from 'react';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';

import { BaseButton } from '../../../../../../components';
import { useHandleCreateJob } from '../../../../../../context';

function Tabs(props) {
  const { active, onSelect, tabs } = props;
  const { onEditJob, editJobLoading } = useHandleCreateJob();

  // props
  const tabContainerProps = { active, onSelect };
  const tabContainerStyleProps = {
    p: '4px',
    bg: 'var(--grey-100)',
    w: 'max-content',
    borderRadius: '4px',
  };
  const wrapperStyleProps = {
    alignItems: 'center',
    justifyContent: 'space-between',
    ml: '1.6rem',
    maxW: '107rem',
    my: '1.6rem',
    position: 'sticky',
    top: '12rem',
  };

  function onSaveChanges() {
    onEditJob();
  }

  return (
    <Flex {...wrapperStyleProps}>
      <Flex {...tabContainerStyleProps}>
        {tabs.map((tab) => (
          <Tab key={tab} tab={tab} {...tabContainerProps} />
        ))}
      </Flex>
      <EditControls noCancel onSaveChanges={onSaveChanges} saving={editJobLoading} />
    </Flex>
  );
}

function Tab(props) {
  const { tab, active, onSelect } = props;
  const isActive = tab === active;

  // actions
  const onTabClick = () => onSelect(tab);

  // props
  const styleProps = {
    p: '12px 16px',
    bg: isActive ? '#fff' : null,
    borderRadius: '4px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  };

  return (
    <Box {...styleProps} onClick={onTabClick}>
      {tab}
    </Box>
  );
}

function EditControls({ onCloseEdit, onSaveChanges, noCancel, saving }) {
  return (
    <Grid templateColumns="repeat(2, max-content)" gap="4px" h="12px" alignContent="center">
      <GridItem>
        {!noCancel && (
          <BaseButton variant="text" sm onClick={onCloseEdit}>
            Cancel
          </BaseButton>
        )}
      </GridItem>
      <GridItem>
        <BaseButton variant="dark" sm onClick={onSaveChanges} isLoading={saving}>
          Save
        </BaseButton>
      </GridItem>
    </Grid>
  );
}

export { Tabs };
