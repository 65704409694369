import React from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_ALL_ASSETS } from 'query';

import {
  EventsActionContainer,
  EventsPopupAction,
  Pagination,
  Search,
  TableComponent,
} from 'components';

import { ReassignAsset } from './ReassignAsset';

const dropAction = {
  edit: 'Reassign Asset',
};

export function Log() {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [searchString, setSearchString] = React.useState('');
  const [editing, setEditing] = React.useState();

  const filter = {};

  const { data, loading, refetch } = useQuery(FETCH_ALL_ASSETS, {
    variables: {
      page: page,
      filter: searchString ? { ...filter, keyword: searchString } : filter,
    },
  });

  const newData = data?.fetchAssets?.data;

  function actionOnData(payload) {
    switch (payload.action) {
      case 'reassign-asset':
        setEditing(newData.find((a) => a._id === payload.id));
        break;
    }
  }

  const tableData =
    newData?.map(({ _id, name, category, serialNumber, assignedTo = {} }) => {
      const { firstName = '', lastName = '' } = assignedTo || {};
      return {
        col1: name,
        col2: category,
        col3: serialNumber,
        col4: `${firstName} ${lastName}`,
        col5: (
          <EventsActionContainer>
            <EventsPopupAction
              id={_id}
              dropdownActions={dropAction}
              actionOnData={actionOnData}></EventsPopupAction>
          </EventsActionContainer>
        ),
      };
    }) ?? [];

  const totalPages = data?.fetchAssets?.pageCount || 1;

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  return (
    <>
      <RootWrap>
        <>
          <Search
            placeholder={'Search Asset'}
            value={search}
            setPage={setPage}
            setSearchString={setSearchString}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </>
        <Box
          mt="2.4rem"
          w={'100%'}
          overflowY={'hidden'}
          overflowX={'auto'}
          className={'scroll-bar'}>
          <TableComponent
            noCheckbox
            noAction
            noSort
            minH={'450px'}
            loading={loading}
            include={'Category'}
            columns={columns}
            data={tableData}
          />
        </Box>
      </RootWrap>
      <Box mt="1rem">
        {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
      </Box>
      {editing ? (
        <ReassignAsset editing={editing} setEditing={setEditing} refetch={refetch} />
      ) : null}
    </>
  );
}

const RootWrap = styled.div`
  background: var(--white);
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 2.4rem;
  tbody tr td:first-of-type {
    font-weight: 700;
  }
`;

const columns = [
  {
    Header: 'Asset Name',
    accessor: 'col1',
  },
  {
    Header: 'Category',
    accessor: 'col2',
  },
  {
    Header: 'Serial No',
    accessor: 'col3',
  },
  {
    Header: 'Employee Name',
    accessor: 'col4',
  },
  {
    Header: 'Action',
    accessor: 'col5',
  },
];
