import React from 'react';
import styled from '@emotion/styled';

import { BaseDropdown } from 'components/shared';

import { Button } from '../button';
import { InputBox } from '../inputbox';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #3e7eee;
  border-radius: 4px;
  padding: 0 7px;
  margin: 0 0 24px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 4px 4px 0px 0px;
    padding: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    color: #242424;
  }

  .buttons {
    display: flex;
    gap: 16px;
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  .react-select__placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.117188px;

    color: rgba(51, 55, 88, 0.3);
  }
`;

const Field = styled.div`
  max-width: 305px;
`;

const EditCard = (props) => {
  return (
    props.isOpen && (
      <Container>
        <div className="head">
          <h2>{props.Heading}</h2>
          <div className="buttons">
            <Button
              dataTestId="deleteButton"
              style={{
                color: '#999FA3',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '14px',
              }}
              title={'Delete'}
              onClick={() => props.onDelete()}
            />
            <Button
              dataTestId="saveButton"
              style={{
                padding: '8px 16px',
                background: '#23273B',
                color: '#fff',
                borderRadius: '4px',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '14px',
              }}
              title={'Save'}
              isDisabled={props.isDisabled}
              onClick={() => props.onSave()}
            />
          </div>
        </div>

        <Fields>
          <Field>
            <BaseDropdown
              name={'ratingType'}
              label={'Score Card Type'}
              style={{ width: '100%', paddingTop: ' 8px' }}
              placeholder="Rating with comments"
              options={props.scoreCardType}
              onChange={(e) =>
                props.handleChange({
                  target: {
                    name: 'type',
                    label: e.label,
                    value: e.value,
                  },
                })
              }
              value={props.value?.type.value}
            />
          </Field>
          <InputBox
            dataTestId="title"
            id={'title'}
            handleChange={props.handleChange}
            label={'Title'}
            placeholder="Title"
            value={props.value?.title}
          />
          <InputBox
            dataTestId="hint"
            id={'hint'}
            handleChange={props.handleChange}
            label={'Hint for Interviewer'}
            placeholder="Hint for interviewer..."
            value={props.value?.hint}
          />
        </Fields>
      </Container>
    )
  );
};

export { EditCard };
