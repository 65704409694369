import React, { useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import cx from 'classnames';
import { useUnreadChatsContext } from 'context/unreadChats';

import { EmptyState, Search } from 'components';

import { ActiveChat } from './components/ActiveChat';
import { EmployeeList } from './components/EmployeeList';
import { ChatList } from './components';

export function Inbox() {
  const [searchParams] = useSearchParams();
  const [search, setSearch] = React.useState('');
  const [searchString, setSearchString] = React.useState('');
  const [active, setActive] = React.useState(() => searchParams.get('id') || null);
  const [activeChat, setActiveChat] = React.useState(() => {});
  const [addingEmployee, setAddingEmployee] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);
  const { setChats } = useUnreadChatsContext();

  const chatList =
    !addingEmployee || searchString ? (
      <ChatList
        active={active}
        setActive={setActive}
        activeChat={activeChat}
        setActiveChat={setActiveChat}
        refetch={refetch}
        setRefetch={setRefetch}
        searchString={searchString}
      />
    ) : null;
  const employeeList =
    addingEmployee || searchString ? (
      <EmployeeList
        setActive={setActive}
        setActiveChat={setActiveChat}
        setAddingEmployee={setAddingEmployee}
        searchString={searchString}
      />
    ) : null;

  useEffect(() => {
    setChats(0);
  }, []);

  return (
    <Wrapper>
      <Grid className="chat-app" templateColumns={'max-content auto'}>
        <Grid
          position="relative"
          w={{ sm: '240px', md: 'min(295px, 30vw)' }}
          templateColumns="1fr"
          templateRows="max-content 1fr"
          display="block"
          className="chat-list"
          bg="var(--bg)"
          border="1px solid var(--grey-250)">
          <Search
            style={{ width: '100%', marginRight: '0', height: '48px', marginBottom: '8px' }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            setSearchString={setSearchString}
            setPage={() => {}}
          />
          <Box h={'calc(100% - 48px)'} overflowY={'auto'} className="scroll-bar">
            {chatList}
            {employeeList}
          </Box>
          {!searchString ? (
            <AddButton
              data-testid={'new-chat-button'}
              className={cx({ active: addingEmployee })}
              onClick={() => setAddingEmployee((p) => !p)}>
              <AiOutlinePlus size="24" />
            </AddButton>
          ) : null}
        </Grid>
        <Box className="active-chat">
          {active ? (
            <ActiveChat
              active={active}
              activeChat={activeChat}
              key={active}
              refetch={refetch}
              setRefetch={setRefetch}
            />
          ) : (
            <EmptyState message="No active chat" />
          )}
        </Box>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 24px;

  .chat-app {
    --height: calc(100vh - 110px);
    height: var(--height);
    grid-template-rows: var(--height);
    min-height: 300px;
  }
`;

const AddButton = styled.button`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  padding: 1.6rem;
  border-radius: 50%;
  background: var(--primary);
  color: white;
  transition: transform 300ms ease;

  &:hover {
    transform: scale(1.05);
  }

  &.active {
    transform: rotate(45deg);
    background-color: var(--grey-500);
  }
`;
