import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { FETCH_ALL_EMPLOYEES } from 'query';
import {
  DELETE_EMPLOYEE,
  EXIT_EMPLOYEE,
  FETCH_EXITED_EMPLOYEES,
} from 'query/admin/exit-management';
import { formatDate } from 'utils';
import { getItem } from 'utils';

import {
  CustomButton,
  DeletePromptModal,
  GridView,
  Pagination,
  Search,
  TableComponent,
} from 'components';

import { tableCols } from '../employee/data';
import { DownloadIcon, Layout } from '../employee/Icons';
import {
  ESubHeading,
  HeaderContentWrapper,
  MainHeading,
  MainWrapper,
  SearchContentWrapper,
} from '../employee/styles';
import { Contact, Status, UserImage } from '../employee/tableConfig';

import { ExitEmployeeModal } from './Modals/ExitUser';
import { ViewSingleEmployee } from './Modals/ViewSingleEmployee';

const keys = {
  Name: 'name',
  Team: 'team_name',
  Status: 'status',
  'Date Hired': 'date_hired',
};

const ExitManagement = () => {
  const [tableData, setTableData] = React.useState([]);
  const [sortBy, setSortBy] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('tableLayout');
  const [searchString, setSearchString] = React.useState('');
  const [viewModal, setViewModal] = React.useState(false);
  const [empModal, setEmpModal] = React.useState(false);
  const [oneUser, setOneUser] = React.useState('');
  const [user, setUser] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [empId, setEmpId] = React.useState(null);
  const filters = { isDefaultAdmin: false };
  const { register, control, handleSubmit, reset } = useForm();
  const { errorToast, successToast } = useToastify();
  const [handledData, setHandledData] = React.useState();

  const navigate = useNavigate();

  const getUser = () => {
    const userDetails = getItem('hrm_user');
    setUser(userDetails);
  };

  React.useEffect(() => {
    getUser();
  }, []);

  const [exitEmployee, { loading: exitEmployeeLoading }] = useMutation(EXIT_EMPLOYEE, {
    refetchQueries: () => [
      {
        query: FETCH_ALL_EMPLOYEES,
        variables: {
          page: page,
          filters: searchString ? { ...filters, searchString: searchString } : { ...filters },
        },
      },
      {
        query: FETCH_EXITED_EMPLOYEES,
        variables: {
          page: page,
          filter: searchString ? { name: searchString } : {},
        },
      },
    ],
  });

  const { data, loading } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      page: page,
      filters: searchString ? { ...filters, searchString: searchString } : { ...filters },
    },
  });

  const [deleteUser] = useMutation(DELETE_EMPLOYEE);

  React.useEffect(() => {
    const employeesData = data?.fetchUsersFiltered?.data;
    setTableData(employeesData);
  }, [data, searchString]);

  const fetchData = data?.fetchUsersFiltered;
  const totalCount = fetchData?.totalCount;
  const totalPages = fetchData?.pageCount || 1;

  const handleLayoutView = (val) => {
    setActiveTab(val);
  };

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];
    const sortedData = tableData?.map((e) => {
      return {
        ...e,
        date_hired: e?.employmentInfo?.hireDate || '',
        name: `${e.firstName} ${e.lastName}`,
        team_name: e?.team?.name || '',
      };
    });
    if (key) {
      sortedData.sort((a, b) => {
        return desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
      });
      setTableData(sortedData);
    }
  };

  const newTableData = tableData?.map(
    ({ firstName, lastName, email, status, employmentInfo, imageUrl, team, phoneNumber }) => {
      const name = `${firstName} ${lastName}`;
      const statusChecked = status === 'pending' ? 'Unverified' : status;
      return {
        col1: UserImage({ name, imageUrl, firstName, lastName }),
        col2: Contact({ email, phoneNumber }),
        col3: team?.name || 'Grit',
        col4: formatDate(employmentInfo?.hireDate) || null,
        col5: Status({ statusChecked }),
      };
    }
  );

  function toggleExitModal(id) {
    const user = tableData.find((d) => d._id === id);
    setViewModal(!viewModal);
    setOneUser(user);
  }

  function toggleEmpModal(id) {
    setEmpModal(!empModal);
    setEmpId(id);
  }

  const viewExitedEmployees = () => {
    navigate('/admin/exit-management/exited-employees/');
  };

  const options = [
    { label: 'Resignation', value: 'Resignation' },
    { label: 'Termination', value: 'Termination' },
    { label: 'End of Contract', value: 'end_of_contract' },
    { label: 'Unsuccessful Probation', value: 'unsuccessful_probation' },
    { label: 'Dismissal', value: 'dismissal' },
    { label: 'End of Service', value: 'end_of_service' },
    { label: 'Death', value: 'death' },
  ];

  const toggleDeleteModal = async (id) => {
    try {
      const res = await deleteUser({
        variables: {
          id: id,
        },
      });
      res?.data
        ? successToast(res?.data?.deleteUser?.message)
        : errorToast(res?.data?.deleteUser?.message);
    } catch (error) {
      errorToast('An error occurred');
    }
  };

  const actionOnData = ({ action, id }) => {
    switch (action) {
      case 'view':
        toggleEmpModal(id);
        break;
      case 'exit-user':
        toggleExitModal(id);
        break;
      case 'delete':
        toggleDeleteModal(id);
        break;
    }
  };

  const dropdownActions = ['View', 'Exit user', 'Delete'];

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  async function onSubmit(data) {
    try {
      const response = await exitEmployee({ variables: { data } });
      if (response?.data?.exitEmployee?.status === 201) {
        successToast('User exited sucessfully');
      } else errorToast(response?.data?.exitEmployee?.message);
    } catch (error) {
      const message = error?.response?.data?.message || error.message || 'Error Occured';
      errorToast(message);
    } finally {
      reset();
      toggleExitModal();
      setShow(false);
    }
  }

  const onSetSubmit = async (data) => {
    const dataToSend = {
      employeeId: oneUser?._id,
      type: data.type.value,
      reason: data.reason,
      content: data.content,
    };
    setHandledData(dataToSend);
    setShow(true);
  };

  //function onOpenDelete() {}

  return (
    <>
      <MainWrapper>
        <HeaderContentWrapper>
          <Box>
            <MainHeading>EMPLOYEES</MainHeading>
            <ESubHeading>Total: {totalCount} people</ESubHeading>
          </Box>
          <ButtonWrapper>
            <CustomButton
              name="View Exited Employees"
              type="button"
              onClick={viewExitedEmployees}
            />
          </ButtonWrapper>
        </HeaderContentWrapper>
        <SearchContentWrapper>
          <Flex>
            <Search
              placeholder={'Search Employee'}
              value={search}
              setPage={setPage}
              setSearchString={setSearchString}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </Flex>
          <Flex alignItems={'center'} gap="2.4rem">
            <DownloadIcon />
            <Layout
              activeTab={activeTab}
              tableViewTab={handleLayoutView}
              gridViewTab={handleLayoutView}
            />
          </Flex>
        </SearchContentWrapper>
        {activeTab === 'tableLayout' && (
          <TableComponent
            noCheckbox
            loading={loading}
            key={sortBy}
            exclude={'Contact'}
            data={newTableData}
            columns={tableCols}
            actionOnData={actionOnData}
            emails={tableData?.map((e) => e.email)}
            ids={tableData?.map((e) => e._id)}
            allChecked={selectAll}
            setAllChecked={setSelectAll}
            handleMultiSortBy={handleMultiSortBy}
            empty={tableData?.length === 0}
            dropdownActions={dropdownActions}
          />
        )}
        {activeTab === 'gridLayout' && (
          <GridView
            hideCheckbox
            dropdownactions={dropdownActions}
            data={tableData}
            emails={tableData?.map((e) => e.email)}
            ids={tableData?.map((e) => e._id)}
            actionOnData={actionOnData}
            loading={loading}
            empty={tableData?.length === 0}
          />
        )}
      </MainWrapper>
      <Box mt="1rem" position="fixed" right={'4rem'} left={'27.2rem'} bottom={'1.2rem'}>
        {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
      </Box>
      <ExitEmployeeModal
        register={register}
        control={control}
        adminInfo={user}
        user={oneUser}
        options={options}
        loading={exitEmployeeLoading}
        toggleModal={toggleExitModal}
        viewModal={viewModal}
        handleSubmit={handleSubmit(onSetSubmit)}
        //onOpenDelete={onOpenDelete}
      />
      <ViewSingleEmployee toggleModal={toggleEmpModal} empId={empId} empModal={empModal} />
      <DeletePromptModal
        show={show}
        deleteItem={() => {
          onSubmit(handledData);
        }}
        cancel={() => setShow(false)}
        loading={exitEmployeeLoading}
        isDefaultText
        text={'Are you sure you want to exit this user?'}
        buttonText={'Exit User'}
      />
    </>
  );
};

const ButtonWrapper = styled(Box)`
  .css-52dy6g {
    padding: 12px 16px;
  }
`;

export { ExitManagement };
