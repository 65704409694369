import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { jobApplicationSchema } from 'schemas/job-application';

import arrow from '../../../../assets/icons/arrow-left.svg';
import { Button, SpinnerComponent, StatusDropdown, Tabs } from '../../../../components';
import {
  ApplicantsFormProvider,
  HandleCreateJobProvider,
  JobDetailsFormProvider,
  OptionsProvider,
  ScoreCardProvider,
} from '../../../../context';
import { Container, LineStyle, Style, TabView } from '../jobs-view/styles';

import { createInitJobDetailsForm } from './JobDetails/helpers/form-state';
import { usePublishedJob } from './hooks';
import { JobDetails } from './JobDetails';
import Settings from './Settings';
import { BackButton, Header, ShareButton } from './styles';
import { TimeLineNotes } from './TimelineNotes';

const PublishedJob = () => {
  const {
    tabs,
    setSelected,
    selectedButton,
    options,
    jobDetails,
    loading,
    loadingTimeline,
    transformed,
    onUpdate,
    timelineData,
    refetchTimeLine,
    refetchJobDetails,
  } = usePublishedJob();
  const navigate = useNavigate();

  if (loading) {
    return (
      <Grid minH="20rem" placeContent="center" mt="20rem">
        <SpinnerComponent data-testid="loading" />
      </Grid>
    );
  }
  return (
    <Container>
      <div className="header">
        <Header>
          <div className="header-cont">
            <Button
              style={BackButton}
              title={'Back'}
              img={arrow}
              imgStyle={{ gap: '17px' }}
              onClick={() => navigate('/admin/jobs')}
            />
            <div className="greeter">
              <h1>{jobDetails?.department?.name}</h1>
              <h2>{jobDetails?.title}</h2>
              <p>
                {jobDetails?.city}
                <span className="dot"></span>
                {jobDetails?.type}
              </p>
            </div>
          </div>
          <div className="buttons">
            <Button style={ShareButton} title={'Share'} />
            <div className="dropdown">
              <StatusDropdown
                status={options}
                refetchJobDetails={refetchJobDetails}
                refetchTimeLine={refetchTimeLine}
                value={jobDetails?.status}
                onClick={onUpdate}
              />
            </div>
          </div>
        </Header>
        <Tabs
          tabs={tabs}
          setSelected={setSelected}
          selected={selectedButton}
          style={Style}
          buttons={{ width: '100%' }}
          lineStyle={LineStyle}
          activeStyle={'#3E7EEE'}
          withLine={true}
          withBorder={true}
          className="stick"
        />
      </div>
      <TabView>
        <WrapperProviders
          initJobDetails={createInitJobDetailsForm(transformed.jobDetails)}
          initApplicantsForm={{
            defaultValues: { fields: transformed.applicantsForm },
            resolver: yupResolver(jobApplicationSchema),
          }}
          initScoreCard={transformed.scoreCards}>
          {selectedButton === 'jobDetails' && <JobDetails />}
          {selectedButton === 'timeline' && (
            <TimeLineNotes timelineData={timelineData} loading={loadingTimeline} />
          )}
          {selectedButton === 'settings' && <Settings />}
        </WrapperProviders>
      </TabView>
    </Container>
  );
};

export function WrapperProviders(props) {
  const { initScoreCard, initApplicantsForm, initJobDetails } = props;
  return (
    <JobDetailsFormProvider initialValue={initJobDetails}>
      <ApplicantsFormProvider initialValue={initApplicantsForm}>
        <ScoreCardProvider initialValue={initScoreCard}>
          <HandleCreateJobProvider>
            <OptionsProvider>{props.children}</OptionsProvider>
          </HandleCreateJobProvider>
        </ScoreCardProvider>
      </ApplicantsFormProvider>
    </JobDetailsFormProvider>
  );
}

export { PublishedJob };
