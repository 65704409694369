import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 24px 0;
`;
export const TabView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Empty = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  box-sizing: border-box;
  flex-direction: column;
  padding: 48px;
`;
