import { Flex, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { CustomButton } from 'components';

import clockInImg from '../../../../assets/images/clockIn.png';
import clockOutImg from '../../../../assets/images/clockOut.png';

import { customDateFormat } from './helpers';

export const ClockInOut = ({ closeModal, handleSubmit, type, loading }) => {
  return (
    <Root>
      <Flex flexDir={'column'} alignItems={'center'} gap={'24px'}>
        <img src={type === 'clockin' ? clockInImg : clockOutImg} alt="" width={'86px'} />

        <Stack className={type}>
          <Text fontWeight={700}>{customDateFormat(new Date())} • GMT +0 </Text>
        </Stack>
        <Text fontSize={'24px'} fontWeight={700}>
          {type === 'clockin' ? 'Time to start your day!' : 'Time to Wrap Up!'}
        </Text>

        <Text fontSize={'14px'} textAlign={'center'}>
          {type === 'clockin'
            ? `Clock in to begin your productive workday. Your time is valuable, and logging your hours ensures accurate records and seamless collaboration. `
            : `Clock out to end your productive workday. Your hard work and dedication have been noted,
            and logging your hours ensures proper compensation and a well-deserved rest.`}
        </Text>
      </Flex>
      <Flex justifyContent={'center'} gap={'16px'}>
        <CustomButton
          h={'43px'}
          name={'Cancel'}
          variant={'secondary'}
          onClick={closeModal}
          mW={'120px'}
        />
        <CustomButton
          variant={type === 'clockin' ? 'primary' : 'red'}
          name={type === 'clockin' ? 'Clock In' : 'Clock Out'}
          mW={'120'}
          h={'43px'}
          type={'submit'}
          loading={loading}
          onClick={handleSubmit}
        />
      </Flex>
    </Root>
  );
};

const Root = styled(Stack)`
  gap: 24px;
  padding: 24px;
  width: 100%;
  justify-content: center;

  .clockin,
  .clockout {
    padding: 8px 80px;
    border-radius: 100px;
  }
  .clockout {
    border: 1px solid #f8efef;
    background: #fff9f9;
  }
  .clockin {
    border: 1px solid #e8ebef;
    background: #fafcff;
  }

  @media screen and (min-width: 700px) {
    width: 405px;
  }
`;
