import { gql } from '@apollo/client';

export const FETCH_LINE_MANAGER = gql`
  query fetchLineManagers($page: Int!, $keyword: String!) {
    fetchLineManagers(page: $page, keyword: $keyword) {
      status
      message
      hasNextPage
      pageCount
      totalCount
      data {
        _id
        firstName
        lastName
        email
        imageUrl
        phoneNumber
        lineManagerStatus
        employmentInfo {
          hireDate
        }
        department {
          name
        }
      }
    }
  }
`;

export const DEACTIVATE_LINE_MANAGER = gql`
  mutation deactivateLineManager($id: ObjectID!) {
    deactivateLineManager(id: $id) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const ACTIVATE_LINE_MANAGER = gql`
  mutation activateLineManager($id: ObjectID!) {
    activateLineManager(id: $id) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const RESEND_LINE_MANAGER = gql`
  mutation resendLineManagerEmail($id: ObjectID!) {
    resendLineManagerEmail(id: $id) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const REMOVE_LINE_MANAGER = gql`
  mutation removeLineManager($id: ObjectID!) {
    removeLineManager(id: $id) {
      status
      message
      data {
        _id
      }
    }
  }
`;
export const DOWNLOAD_LINE_MANAGER = gql`
  query downloadLineManagers {
    downloadLineManagers {
      status
      message
      data
    }
  }
`;

export const VERIFY_LINE_MANAGER = gql`
  mutation verifyLineManager($token: String!) {
    verifyLineManager(token: $token) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const PROMOTE_LINE_MANAGER = gql`
  mutation promoteToLineManager($id: ObjectID!) {
    promoteToLineManager(id: $id) {
      status
      message
      data {
        _id
      }
    }
  }
`;
