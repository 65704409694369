import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_EMPLOYEE_TRAINING } from 'query';

import { TrainingTable } from './component';
const headers = {
  'Training Program': 'training',
  'Start Date': 'startDate',
  'Due Date': 'dueDate',
};

const ActiveTraining = ({ action }) => {
  const { data, loading } = useQuery(FETCH_EMPLOYEE_TRAINING, {
    variables: {
      filter: {
        status: 'in_progress',
      },
    },
  });
  const activeTraining = data?.fetchActiveOrUpcomingTraining?.data;

  return (
    <div>
      <p className="text-16-400">Active Training</p>
      <div className="table-container" style={{ '--num-cols': 3, marginTop: '8px' }}>
        <TrainingTable
          loading={loading}
          headers={headers}
          data={activeTraining}
          actionToPerform={action}
          msg={'No Active Training Currently'}
        />
      </div>
    </div>
  );
};

export { ActiveTraining };
