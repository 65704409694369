import { useEffect, useState } from 'react';

function useOnboardDetailsView() {
  let tabs = [
    // { displayName: `COMPANY DETAILS`, value: 'companyDetails' },
    // { displayName: `ROLES & PERMISSION `, value: 'roles' },
    // { displayName: `ADMIN MANAGEMENT `, value: 'adminManagement' },
    { displayName: `DEPARTMENT `, value: 'department' },
    { displayName: `TEAMS `, value: 'teams' },
  ];

  const Tabs = {
    // companyDetails: 'companyDetails',
    // roles: 'roles',
    // adminManagement: 'adminManagement',
    department: 'department',
    teams: 'teams',
  };
  const [selectedButton, setSelectedButton] = useState('department');

  const setSelected = (tab) => {
    Object.keys(Tabs).forEach((key) => {
      if (Tabs[key] === tab) {
        setSelectedButton(Tabs[key]);
      }
    });
  };

  useEffect(() => {
    setSelected(selectedButton);
  }, [selectedButton]);

  return {
    tabs,
    setSelected,
    selectedButton,
  };
}

export { useOnboardDetailsView };
