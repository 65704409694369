import React from 'react';
import { Grid, Text } from '@chakra-ui/react';
import { APPLICANTS_FORM_BREAKPOINT } from 'utils';

import { SpanDouble } from 'components';

import { EditableInputFieldList } from './EditableInputFieldList';
import { EditButton } from './EditButton';
import { SectionEdit } from './SectionEdit';

import { css } from '@emotion/css';

const SectionField = (props) => {
  const { fieldName, field, isEditing, index, update } = props;
  const name = `${fieldName}.children`;

  function handleEditClick() {
    update(index, { ...field, isEditing: true });
  }

  return (
    <SpanDouble
      _focusWithin={{ border: isEditing ? '1px solid var(--primary)' : '' }}
      borderRadius="8px"
      mt="1.6rem">
      <SpanDouble
        bg="brand.grey.100"
        minH="6.4rem"
        display="grid"
        gridAutoColumns="max-content"
        justifyContent="space-between"
        alignItems="center"
        px="2.4rem"
        border="1px solid transparent"
        borderRadius="8px 8px 0px 0px"
        position="relative"
        className={headerStyles}>
        {!isEditing && (
          <>
            <Text fontWeight="bold">{field.name}</Text>
            <EditButton className={iconStyles} onClick={handleEditClick} />
          </>
        )}
        {isEditing && <SectionEdit {...props} />}
      </SpanDouble>
      <Grid
        templateColumns={{ base: '1fr', [APPLICANTS_FORM_BREAKPOINT]: '1fr 1fr' }}
        autoRows="max-content"
        gap="2.4rem"
        p="2.4rem"
        border="1px solid var(--grey-100)"
        borderRadius="0px 0px 8px 8px"
        minH="9.6rem">
        <EditableInputFieldList
          name={name}
          inSection
          isEditing={isEditing}
          parentUpdate={update}
          parentField={field}
          parentIndex={index}
        />
      </Grid>
    </SpanDouble>
  );
};

const iconStyles = css`
  top: 2rem !important;
  right: 2rem !important;
`;

const headerStyles = css`
  &:hover {
    > .icon {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export { SectionField };
