import React from 'react';
import { useForm } from 'react-hook-form';

const WorkScheduleFormContext = React.createContext({});
const defaultForm = { defaultValues: { weekends: [], backoutPeriods: [], holidays: [] } };
export function WorkScheduleFormProvider(props) {
  const { initialValue, other } = props;
  // const { date, startDate } = other;
  const init = React.useMemo(() => initialValue || defaultForm, []);
  const formMethods = useForm(init);

  React.useEffect(() => {
    formMethods.reset(initialValue.defaultValues || defaultForm.defaultValues);
  }, [initialValue]);

  return (
    <WorkScheduleFormContext.Provider value={{ ...formMethods, ...other }}>
      {props.children}
    </WorkScheduleFormContext.Provider>
  );
}

export function useWorkScheduleForm() {
  const context = React.useContext(WorkScheduleFormContext);
  if (!context) {
    throw new Error('"useWorkScheduleForm" can only be used within a "WorkScheduleFormProvider"');
  }
  return context;
}
