import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useUnreadChatsContext } from 'context/unreadChats';

import { useMenuContext } from '../../context';

const Item = ({ icon, text, chats }) => {
  return (
    <Flex align="center" alignItems="center">
      <Box>{icon}</Box>
      <Text ml="18px" lineHeight="19px" fontSize="16px" fontWeight="400" color="#fff">
        {text}
      </Text>
      <Box ml="80px">{text === 'Chat' && chats > 0 ? <RedNotice>{chats}</RedNotice> : ''}</Box>
    </Flex>
  );
};

const SidebarItem = ({ icon, name, path, exact }) => {
  const location = useLocation();
  const isActive = exact ? location.pathname === path : location.pathname.startsWith(path);
  const { setIsOpen } = useMenuContext();
  const { chats } = useUnreadChatsContext();

  function closeNav() {
    setTimeout(() => setIsOpen(false), 50);
  }

  return (
    <NavLink
      to={path}
      className={`sidebar__item ${isActive ? 'activeRoute' : ''}`}
      onClick={closeNav}>
      <Item icon={icon} text={name} path={path} chats={chats} />
    </NavLink>
  );
};

export default SidebarItem;

const RedNotice = styled.div`
  width: 22px;
  height: 20px;
  border-radius: 50px;
  background-color: white;
  color: black;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;
