import React from 'react';
import { Grid, Text } from '@chakra-ui/react';

import { InputBox } from 'components';

const SetSchedule = ({ handleSchedule, state }) => {
  return (
    <Grid gap={'16px'}>
      <Text className="heading">Schedule</Text>
      <Grid templateColumns={'1fr 1fr'} gap={'24px'}>
        <InputBox
          id={'startDate'}
          label={'Start Date'}
          required={true}
          type={'date'}
          handleChange={handleSchedule}
          value={state?.startDate}
          dataTestId={'startDate'}
        />
        <InputBox
          id={'endDate'}
          label={'Due Date'}
          required={true}
          type={'date'}
          handleChange={handleSchedule}
          value={state?.endDate}
          dataTestId={'endDate'}
        />
      </Grid>
    </Grid>
  );
};

export { SetSchedule };
