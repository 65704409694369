import { gql } from '@apollo/client';

export const FETCH_PERFORMANCE_DASHBOARD = gql`
  query fetchAppraisalDashboard {
    fetchAppraisalDashboard {
      status
      message
      data {
        lastReport {
          _id
          reviewType
          reviewDate {
            startDate
            endDate
          }
          score {
            actualScore
            expectedScore
          }
        }
        daysToNextAppraisal
        activeReviews {
          _id
          title
          type
          status
          scheduleDate {
            startDate
            endDate
          }
          reviewStats {
            total
            completed
          }
        }
      }
    }
  }
`;

export const FETCH_PAST_REVIEWS = gql`
  query fetchPastReviews {
    fetchPastReviews {
      status
      message
      data {
        _id
        title
        type
        score
        year
        scheduleDate {
          startDate
          endDate
        }
      }
    }
  }
`;

export const PEER_REVIEW = gql`
  query fetchPeerReviews {
    fetchPeerReviews {
      status
      message
      data {
        _id
        score
        status
        scheduleDate {
          startDate
          endDate
        }
        reviewee {
          _id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;

export const FETCH_REVIEW_QUESTIONS = gql`
  query fetchReviewQuestions($filter: ReviewQuestionsFilter!) {
    fetchReviewQuestions(filter: $filter) {
      status
      message
      data {
        responses {
          section {
            title
            questions {
              question
              questionType
              options {
                value
                choice
                isAnswer
              }
            }
          }
        }
        reviewTemplate {
          _id
          title
          reviewType {
            name
          }
          schedule {
            endDate
          }
          templateId {
            _id
            questionType
            questions {
              _id
              title
              instruction
              questions {
                _id
                questionType
                question
                options {
                  choice
                  value
                }
              }
            }
          }
        }
        reviewee {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export const SUBMIT_A_REVIEW = gql`
  mutation addResponse($data: ResultInput) {
    addResponse(data: $data) {
      status
      message
      data {
        reviewers {
          reviewer
          responses {
            section {
              title
              questions {
                question
                questionType
                options {
                  choice
                  value
                  isAnswer
                }
              }
            }
          }
          comments
        }
      }
    }
  }
`;

export const EDIT_PERFORMANCE_RESPONSE = gql`
  mutation updateResponse($filters: ReviewresponseFilters, $data: EditResultInput) {
    updateResponse(filters: $filters, data: $data) {
      status
      message
      data {
        createdAt
        updatedAt
      }
    }
  }
`;
