import React from 'react';

import { InputField } from './Input';

const rootStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(auto, 317px))',
  gap: '2.4rem',
};

const DefaultInputFields = ({ register }) => {
  return (
    <div style={rootStyle}>
      <InputField
        placeholder={'Assessment Title'}
        label={'Assessment Title'}
        {...register('title')}
      />
      <InputField placeholder={'HH:MM'} label={'Duration'} {...register('duration')} />
      <InputField
        type={'date'}
        label={'Deadline'}
        {...register('deadline')}
        className={'.hide-date-icon'}
      />
      <InputField
        type={'number'}
        placeholder={'Pass Mark'}
        label={'Pass Mark'}
        {...register('passMark')}
      />
    </div>
  );
};

export { DefaultInputFields };
