import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_ALL_REVIEW_TEMPLATES } from 'query';

import { FullModal, ModalBackButton, Pagination, Search, TableWrapper } from 'components';

import { TemplateTable } from './templatetable';

const Container = styled.div`
  display: flex;
  max-width: 100%;

  flex-direction: column;
  gap: 48px;
  padding: 24px 0;
  // margin: 24px;
  background: #ffffff;
  // border: 1px solid #f2f2f2;
  border-radius: 4px;

  .heading {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.0012em;
    color: #364469;
    text-transform: capitalize;
  }
`;

const ReviewTemplates = ({ showModal, setShowModal, team }) => {
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(1);
  const templateName = team?.name;
  const id = team?._id;

  const { data, loading, refetch } = useQuery(FETCH_ALL_REVIEW_TEMPLATES, {
    variables: {
      filter: {
        team: id,
        keyword: searchString,
      },
      page: page,
    },
  });

  const totalPages = data?.fetchAllTemplates?.pageCount || 1;

  function handleChange(event) {
    setSearchString(event);
  }

  function goBack() {
    setShowModal(false);
  }

  useEffect(() => {
    refetch();
  }, [showModal]);

  return (
    <FullModal showModal={showModal} setShowModal={setShowModal} noHeader mt="8.3rem">
      <Container>
        <Flex gap={'24px'} alignItems={'center'}>
          <ModalBackButton onClick={goBack} />
          <h1 className="heading">{`${templateName}` + ' ' + 'Templates'}</h1>
        </Flex>
        <Flex flexDirection={'column'} gap={'24px'} width={'100%'}>
          <Search
            placeholder={'Search name, year,'}
            value={searchString}
            setSearchString={setSearchString}
            onChange={(e) => handleChange(e.target.value)}
            setPage={setPage}
          />
          <TableWrapper>
            <TemplateTable refetch={refetch} data={data} loading={loading} />
          </TableWrapper>
        </Flex>
      </Container>
      {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
    </FullModal>
  );
};

export { ReviewTemplates };
