import React, { useState } from 'react';
import { Box, Flex, Grid, Heading, Image, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Pdf from '@mikecousins/react-pdf';

import { ModalBackButton, SpinnerComponent } from 'components';

const Button = styled.button`
  padding: 7px 14px;
  color: var(--deep_blue);
  border-radius: 4px;
  width: 100px;
  font-size: 14px;
  border: solid 1px var(--black);
`;

const DocumentView = styled.div`
  display: grid;
  place-items: center;
  padding: 32px 0;
  iframe {
    min-height: 650px;

    overflow: auto;
  }
`;

export function ViewDocument({ closeModal, documentName, documentUrl }) {
  const [page, setPage] = useState(1);
  const url = `https://view.officeapps.live.com/op/embed.aspx?src=${documentUrl}`;
  const docType = url.split('.').pop();
  const picTypes = ['jpg', 'gif', 'png', 'jpeg', 'JPG', 'GIF', 'PNG', 'JPEG'];

  return (
    <Stack minH={'100vh'} p={'24px'} backgroundColor={'#FAFCFF'}>
      <Flex alignItems={'center'} gap={'24px'}>
        <ModalBackButton onClick={closeModal} />
        <Heading textTransform={'capitalize'}>{documentName.split('.')[0]}</Heading>
      </Flex>

      {picTypes.includes(docType) && (
        <>
          <Stack p={'24px'}>
            <Image src={documentUrl} />
          </Stack>
        </>
      )}

      {docType === 'pdf' && (
        <Grid p={'30px'} justifyContent={'center'}>
          <Box>
            <Pdf file={documentUrl} page={page}>
              {({ pdfDocument, canvas }) => (
                <>
                  {!pdfDocument && (
                    <Grid h={'80vh'} placeItems={'center'}>
                      <SpinnerComponent />
                    </Grid>
                  )}
                  {Boolean(pdfDocument && pdfDocument.numPages) && (
                    <Flex
                      marginBottom={'30px'}
                      alignItems={'center'}
                      justifyContent={'space-between'}>
                      <Flex gap={'12px'}>
                        {page <= pdfDocument.numPages && page > 1 && (
                          <Button onClick={() => setPage(page - 1)}>Previous</Button>
                        )}
                        {page < pdfDocument.numPages && (
                          <Button onClick={() => setPage(page + 1)}>Next</Button>
                        )}
                      </Flex>

                      <Heading fontSize={'24px'}>{page}</Heading>
                    </Flex>
                  )}
                  {canvas}
                </>
              )}
            </Pdf>
          </Box>
        </Grid>
      )}
      {docType === 'doc' ||
        (docType === 'docx' && (
          <Stack minH={'100vh'} p={'24px'} backgroundColor={'#FAFCFF'}>
            <DocumentView>
              <iframe src={url} width="100%">
                This is an embedded{' '}
                <a target="_blank" href="http://office.com" rel="noreferrer">
                  Microsoft Office
                </a>{' '}
                document, powered by{' '}
                <a target="_blank" href="http://office.com/webapps" rel="noreferrer">
                  Office Online
                </a>
                .
              </iframe>
            </DocumentView>
          </Stack>
        ))}
    </Stack>
  );
}
