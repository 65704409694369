import { useEffect, useState } from 'react';
import { useToastify } from 'hooks/useToastify';
import moment from 'moment';
import validator from 'validator';

function useTrainingComponent(value) {
  const initialState = {
    name: '',
    description: '',
    attachedLink: '',
    attachedFile: '',

    details: [],
    schedule: {
      startDate: '',
      endDate: '',
    },
    repeatProgram: {
      isRepeated: false,
      repeatIn: 0,
      repeatType: 'days',
    },
    isShared: true,
    employeeId: [],
  };

  const { successToast } = useToastify();
  const [state, setState] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState('');
  const [showField, setShowField] = useState(false);
  const [openRepeat, setRepeat] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    name === 'attachedLink' && !validator.isURL(value)
      ? setErrorMessage('Is Not Valid URL')
      : setErrorMessage('');

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectEmployees = (employeeIds, isInitialState) => {
    setState((prevState) => ({
      ...prevState,
      employeeId: employeeIds,
    }));
    isInitialState ? null : successToast('Employees assigned sucessfully');
  };

  const handleRecurringPerioid = (e) => {
    const { name, value, checked } = e.target;

    setState((prevState) => ({
      ...prevState,
      repeatProgram: {
        ...prevState.repeatProgram,
        [name]: name === 'isRepeated' ? checked : name === 'repeatIn' ? Number(value) : value,
      },
    }));
  };

  const handleTrainingDetails = (isChecked) => {
    setState((prevState) => ({ ...prevState, details: isChecked }));
  };

  const handleSelectFiles = (file) => {
    setState((prevState) => ({
      ...prevState,
      attachedFile: file,
    }));
  };

  const handleSchedule = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      schedule: {
        ...prevState.schedule,
        [name]: value,
      },
    }));
  };

  const validateScheduleDate = state?.schedule?.startDate && state?.schedule?.endDate;

  // let {
  //   schedule: { startDate, endDate },
  // } = state;

  function getDateDiffInSeconds(startDateStr, endDateStr) {
    const dateFormat = 'DD-MM-YYYY';
    const startDate = moment(startDateStr, dateFormat);
    const endDate = moment(endDateStr, dateFormat);
    const diffInSeconds = endDate.diff(startDate, 'seconds');
    if (diffInSeconds > 0) return true;

    return false;
  }

  // const validatePastEndDate = getDateDiffInSeconds(startDate, endDate);

  const validateRecurringProgram = state?.repeatProgram?.isRepeated
    ? state?.repeatProgram?.repeatIn && state?.repeatProgram?.repeatType
    : true;

  const validateLink = showField ? state?.attachedLink : true;

  const validateName = state?.name;

  const isDisabled = !(
    validateRecurringProgram &&
    validateScheduleDate &&
    validateName &&
    validateLink &&
    !errorMessage
  );

  useEffect(() => {
    setState(value || initialState);
    setShowField(value?.attachedLink);
    setRepeat(value?.repeatProgram?.isRepeated);
  }, [value]);

  return {
    isDisabled,
    handleChange,
    handleSelectFiles,
    errorMessage,
    state,
    setShowField,
    showField,
    setState,
    handleTrainingDetails,
    handleSchedule,
    handleSelectEmployees,
    handleRecurringPerioid,
    setRepeat,
    openRepeat,
    getDateDiffInSeconds,
  };
}

export { useTrainingComponent };
