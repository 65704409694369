import React from 'react';
import { Image } from '@chakra-ui/react';

import LoadingIcon from '../../assets/icons/loading.svg';
import LoadingIconWhite from '../../assets/icons/loading-white.svg';

function LoadingImage({ white, ...props }) {
  return (
    <Image w="1.2rem" h="1.2rem" src={white ? LoadingIconWhite : LoadingIcon} alt="" {...props} />
  );
}

export { LoadingImage };
