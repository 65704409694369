export const adminManagementTableCols = [
  {
    Header: 'Name',
    accessor: 'col1',
  },
  {
    Header: 'Email Address',
    accessor: 'col2',
  },
  {
    Header: 'Role',
    accessor: 'col3',
  },
  {
    Header: 'Location',
    accessor: 'col4',
  },
  {
    Header: 'Status',
    accessor: 'col5',
  },
  {
    Header: 'Date created',
    accessor: 'col6',
  },
];
