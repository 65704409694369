import React from 'react';
import styled from '@emotion/styled';

import { CustomButton, FullModal } from 'components';

import { OnboardingSetupView } from './setup-view/onboardingSetupView';
import { useHook } from './hooks';

export const SetupChecklistPage = ({ skip }) => {
  const [showModal, setShowDetails] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(false);
  const { compD, AdminD, RolesD, Department } = useHook();

  const setupDone = compD && AdminD && RolesD && Department;

  return (
    <PageRoot data-testid="checklist-page">
      <div className="page-content">
        <h1 className="header">HR Setup Checklist</h1>
        <p className="sub-heading">
          Here is what we recommend you set up first to enjoy the platform to the fullest
        </p>
        <div className="checklist-wrapper">
          <div className="">
            <div className="main-title">To customize to your company needs</div>
            <div className="checklist--item">
              <div>
                <p className="title">Add your company’s details</p>
                <p className="duration">1 minute</p>
              </div>
              <SCButton
                completed={compD}
                onClick={() => {
                  setShowDetails(true);
                  setSelectedTab('companyDetails');
                }}>
                {compD ? 'Edit' : 'Start'}
              </SCButton>
            </div>
            <div className="checklist--item">
              <div>
                <p className="title">Set roles and permissions</p>
                <p className="duration">2 minute</p>
              </div>
              <SCButton
                completed={RolesD}
                onClick={() => {
                  setShowDetails(true);
                  setSelectedTab('roles');
                }}>
                {RolesD ? 'Edit' : 'Start'}
              </SCButton>
            </div>

            <div className="main-title">Because you don’t want to do everything yourself</div>
            <div className="checklist--item">
              <div>
                <p className="title">Add new admins for your company</p>
                <p className="duration">1 minute</p>
              </div>
              <SCButton
                completed={AdminD}
                onClick={() => {
                  setShowDetails(true);
                  setSelectedTab('adminManagement');
                }}>
                {AdminD ? 'Edit' : 'Start'}
              </SCButton>
            </div>
            <div className="checklist--item">
              <div>
                <p className="title">Add Departments/Teams</p>
                <p className="duration">1 minute</p>
              </div>
              <SCButton
                completed={Department}
                onClick={() => {
                  setShowDetails(true);
                  setSelectedTab('department/teams');
                }}>
                {Department ? 'Edit' : 'Start'}
              </SCButton>
            </div>
          </div>
        </div>
        <div className="btn-wrapper">
          {setupDone ? (
            <CustomButton onClick={skip} variant="primary" name="Continue" h="4.8rem" mW="40rem" />
          ) : (
            <ContinueButton onClick={() => setShowDetails(true)} className="continue_btn">
              Continue
            </ContinueButton>
          )}
        </div>
      </div>

      {showModal && (
        <FullModal noHeader showModal={showModal}>
          <OnboardingSetupView setShowDetails={setShowDetails} selectedTab={selectedTab} />
        </FullModal>
      )}
    </PageRoot>
  );
};

const completedStyle = {
  height: '4rem',
  border: '1px solid var(--green)',
  borderRadius: '4px',
  color: 'var(--green)',
  padding: '1.2rem 2.4rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const defaultStyle = {
  height: '4rem',
  border: '1px solid var(--primary)',
  borderRadius: '4px',
  color: 'var(--white)',
  padding: '0 1.2rem',
  backgroundColor: 'var(--primary)',
};

const SCButton = styled.button`
  ${(props) => (props.completed ? { ...completedStyle } : { ...defaultStyle })}
`;

const ContinueButton = styled.button`
  width: 40rem;
  height: 4.8rem;
  max-width: 40rem;
  border: none;
  padding: 1.2rem 2.4rem;
  background: var(--grey-500);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: var(--white);
`;

const PageRoot = styled.div`
  height: auto;

  .full-modal-wrapper {
    margin-top: 5.4rem;
  }

  .right-modal-overlay {
    border: none;
    padding-top: 2rem;
  }

  .page-content {
    padding: 4rem 1.2rem;
    width: 80%;
    margin: auto;
  }

  .header {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: -0.02em;
    color: var(--black);
    margin-bottom: 0.8rem;
  }

  .sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--grey-500);
  }

  .checklist-wrapper {
    max-width: 62rem;
    margin: 8rem auto 0;

    .main-title {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: var(--black);
      margin-top: 3.2rem;
    }
  }

  .checklist--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--grey-150);
    background-color: var(--white);
    width: 100%;
    box-shadow: 0px 4px 20px rgba(228, 228, 228, 0.25);
    border-radius: 4px;
    height: 8.3rem;
    padding: 0 2.4rem;
    margin-top: 0.8rem;

    .title {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: var(--black);
      margin-bottom: 0.8rem;
    }

    .duration {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: var(--black);
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3.2rem;
  }
`;
