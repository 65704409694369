import React from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { Box, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, SimpleModal, SpinnerComponent } from '../../../../components';

import { GoalsChart } from './GoalsChart';
import { useOverviewView } from './OverviewView.hooks';

export function OverviewView(props) {
  const { setSelected } = props;
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const { reviewsSummary, goalsSummary, goalsLoading } = useOverviewView();

  return (
    <Root>
      <Box className="card">
        <header className="header">
          <h3>Reviews</h3>
          <BaseButton variant="dark" smp h="50px" onClick={() => setShowCreateModal(true)}>
            <BsPlusLg size="14px" /> Create Review
          </BaseButton>
        </header>

        <div className="content">
          {goalsLoading ? (
            <SpinnerComponent h="30rem" />
          ) : (
            <div className="summary">
              {Object.entries(reviewsSummary).map(([key, value], index) => (
                <React.Fragment key={key}>
                  <div className="summary-item">
                    <div className="value">{value}</div>
                    <div className="label">{key}</div>
                  </div>
                  {index === 1 ? <Box className="line"></Box> : null}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>

        <Box className="a" onClick={() => setSelected('reviews')}>
          See All Reviews
        </Box>
      </Box>
      <Box className="card">
        <header className="header">
          <h3>Goals</h3>
          <BaseButton variant="dark" smp h="50px" onClick={() => props.setCreateGoal(true)}>
            <BsPlusLg size="14px" /> Create Goal
          </BaseButton>
        </header>

        <div className="content">
          {goalsLoading ? (
            <SpinnerComponent h="30rem" />
          ) : (
            <>
              <Box w="max-content" mx="auto">
                <GoalsChart summary={goalsSummary} />
              </Box>
              <Grid maxW="100%" width="212px" mx="auto" my="24px" autoRows="max-content" gap="20px">
                {Object.entries(goalsSummary).map(([label, value]) => (
                  <Grid
                    className="goal-summary-item"
                    key={label}
                    fontWeight="500"
                    templateColumns="max-content 80px"
                    justifyContent="space-between">
                    <Grid templateColumns="12px 100px" alignItems="center" gap="4px">
                      <Box
                        h="12px"
                        w="12px"
                        bg={labelColors[label]}
                        borderRadius="50%"
                        className="indicator"></Box>
                      <div className="label">{label}</div>
                    </Grid>
                    <div className="value">
                      {value} goal{value !== 1 ? 's' : ''}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
      </Box>

      <SimpleModal showModal={showCreateModal} setShowModal={setShowCreateModal}>
        <Box p="40px" maxW="480px" color="grey-text">
          <Text fontSize="2.4rem" fontWeight="600" letterSpacing="-0.01em">
            Create Review
          </Text>
          <Text>
            You might need to build a review templates first to complete this action. Continue if
            you already have a template.
          </Text>
          <Grid
            mt="64px"
            templateColumns="repeat(2, max-content)"
            gap="8px"
            justifyContent="flex-end">
            <BaseButton
              variant="outline"
              h="4.3rem"
              smp
              onClick={() => [setShowCreateModal(false), props.setOpenModal(true)]}>
              Build template
            </BaseButton>
            <BaseButton
              variant="dark"
              h="4.3rem"
              smp
              onClick={() => [setShowCreateModal(false), props.setShowCreateReview(true)]}>
              Create Review
            </BaseButton>
          </Grid>
        </Box>
      </SimpleModal>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid: auto-flow max-content / 1fr;
  gap: 24px;
  @media (min-width: 1024px) {
    grid: 1fr / 1fr 1fr;
    gap: 24px;
  }

  .card {
    display: flex;
    flex-flow: column;
    padding: 24px;
    border: 1px solid var(--grey-150);
    background: var(--white);

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;
    }

    h3 {
      font-size: 2.4rem;
      line-height: 3.3rem;
      font-weight: 600;
    }

    .a {
      width: max-content;
      display: block;
      margin-inline: auto;
      margin-top: auto;
      font-weight: 500;
      color: var(--primary);
      cursor: pointer;
      transition: transform 200ms ease;

      &:hover {
        transform: scale(1.02);
      }
    }
  }

  .summary {
    max-width: 340px;
    display: grid;
    grid: 1fr 1fr / 1fr max-content 1fr;
    place-content: center;
    row-gap: 56px;
    grid-auto-flow: column;
    padding-block: 16px 48px;
    margin-inline: auto;
  }

  .line {
    grid-row: span 2;
    width: 1px;
    border-right: 1px solid var(--grey-150);
  }

  .summary-item {
    display: grid;
    grid: auto-flow max-content / 1fr;
    justify-items: center;
    gap: 8px;
    font-size: 0.8rem;

    @media (min-width: 1200px) {
      font-size: 1rem;
    }

    .value {
      font-size: 4.8em;
      line-height: 1;
    }

    .label {
      font-size: 2.4em;
      color: var(--grey-600);
    }
  }
`;

export const labelColors = {
  'In progress': '#3E7EEE',
  Scheduled: '#F0A800',
  Completed: '#36CA8B',
  Overdue: '#FA4A4A',
};
