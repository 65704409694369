import { Box, Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Status } from 'pages/admin/employee/tableConfig';
import { formatDateRange } from 'pages/admin/leave-management/Requests/helper';

import { CustomButton, ModalBackButton, SpinnerComponent } from 'components';

import { useUpdateLeaveApproval } from '../hooks/useUpdateLeaveApproval';

import { EmployeeDetails } from './EmployeeDetails';

export function LeaveApprovalView({
  closeModal,
  toggleRejectModal,
  leaveRequest,
  loading,
  refetch,
  refetchAllLeaveRequest,
  setLeaveId,
}) {
  const { loading: loadingUpdateLeave, onUpdateLeave } = useUpdateLeaveApproval(
    refetch,
    refetchAllLeaveRequest
  );

  return (
    <Root>
      <ModalBackButton onClick={closeModal} w={'110px'} />

      <>
        {loading ? (
          <Grid h={'60vh'}>
            <SpinnerComponent />
          </Grid>
        ) : (
          <Stack gap={'43px'} className="leaveApprovalView--item">
            <Heading p={'8px 0'}>Leave Approval Review</Heading>

            <div className="leaveapprovalView--content">
              <EmployeeDetails employee={leaveRequest?.employeeId} />

              <div className="leave--request-info">
                <div className="item">
                  <Stack className="heading" p={'20px 0'} borderBottom={'solid 1px #F2F2F2'}>
                    <Text fontWeight={500}>Leave Request Info</Text>
                  </Stack>

                  <Stack
                    p={'12px 0'}
                    gap={'16px'}
                    fontSize={'14px'}
                    borderBottom={'solid 1px #F2F2F2'}>
                    <Stack className="content">
                      <Text fontSize={'12px'} color={'#999FA3'}>
                        Leave Date Provided
                      </Text>
                      <Flex alignItems={'center'} gap={'8px'}>
                        <Text fontWeight={500}>
                          {formatDateRange({
                            startDate: leaveRequest?.startDate,
                            endDate: leaveRequest?.endDate,
                          })}
                        </Text>
                        <Box
                          border={'solid 1px #E8EBEF'}
                          bgColor={'#FAFCFF'}
                          borderRadius={'100px'}
                          p={'5px 15px'}>
                          {leaveRequest?.days} days
                        </Box>
                      </Flex>
                    </Stack>
                    <Stack>
                      <Text fontSize={'12px'} color={'#999FA3'}>
                        Leave Type
                      </Text>
                      <Text fontWeight={500}>{leaveRequest?.leaveTypeId?.name}</Text>
                    </Stack>
                    <Stack>
                      <Text fontSize={'12px'} color={'#999FA3'}>
                        Description
                      </Text>
                      <Text fontWeight={500}>{leaveRequest?.description}</Text>
                    </Stack>
                  </Stack>

                  <Stack className="summary" gap={'16px'}>
                    <Text fontWeight={700}>Summary</Text>

                    <Grid className="summary--item">
                      <Flex gap={'36px'}>
                        <Stack>
                          <Text fontSize={'12px'} color={'var(--grey-500)'}>
                            Count As
                          </Text>
                          <Text fontSize={'14px'} fontWeight={500}>
                            {leaveRequest?.days} days
                          </Text>
                        </Stack>
                        <Stack>
                          <Text fontSize={'12px'} color={'var(--grey-500)'}>
                            Balance Before
                          </Text>
                          <Text fontSize={'14px'} fontWeight={500}>
                            {leaveRequest?.countBalance} days
                          </Text>
                        </Stack>
                      </Flex>

                      <Flex gap={'16px'} className="btns">
                        <>
                          {leaveRequest?.leaveStatus === 'pending' ? (
                            <>
                              <CustomButton
                                h={'43px'}
                                variant={'red'}
                                name={'Reject Request'}
                                onClick={() => {
                                  setLeaveId(leaveRequest?._id);
                                  toggleRejectModal();
                                }}
                                mW={'150px'}
                              />
                              <CustomButton
                                variant={'primary'}
                                name={'Approve Request'}
                                mW={'150px'}
                                h={'43px'}
                                type={'submit'}
                                loading={loadingUpdateLeave}
                                onClick={() => onUpdateLeave(leaveRequest?._id, 'approve')}
                              />
                            </>
                          ) : (
                            <Status statusChecked={leaveRequest?.leaveStatus} />
                          )}
                        </>
                      </Flex>
                    </Grid>
                  </Stack>
                </div>
              </div>
            </div>
          </Stack>
        )}
      </>
    </Root>
  );
}

const Root = styled.div`
  padding: 33px 25px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  display: grid;

  @media screen and (min-width: 800px) {
    grid-template-columns: 120px 1fr;
  }

  .leaveApprovalView--item {
    max-width: 810px;
  }

  .leaveapprovalView--content {
    border-radius: 8px;
    border: solid 1px #4e7ce6;
    overflow: hidden;

    .leave--request-info {
      padding: 20px 10px;
      @media screen and (min-width: 600px) {
        padding: 20px 40px;
      }
      .item {
        border: solid 1px #f2f2f2;
        border-radius: 8px;
        padding: 0 16px;
        padding-bottom: 16px;
      }

      .summary {
        margin-top: 20px;
        padding: 12px 24px;
        background-color: #f2f5f9;
        border-radius: 8px;
        &--item {
          grid-template-columns: 1fr;
          gap: 16px;
          .btns {
            flex-direction: column;
          }
          @media screen and (min-width: 600px) {
            grid-template-columns: 1fr 1fr;

            .btns {
              flex-direction: row;
              align-items: center;
            }
          }
        }
      }
    }
  }
`;
