import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { CustomButton } from 'components';
import { SimpleModal } from 'components';

const DeletePromptModal = ({
  show,
  cancel,
  deleteItem,
  loading,
  isDefaultText,
  text,
  buttonText,
}) => {
  return (
    <SimpleModal showModal={show} padding="2.4rem" width={'572px'}>
      <DeleteRoot data-testid="approve-prompt">
        <h2>Are you sure?</h2>
        {isDefaultText ? <p>{text}</p> : <p>This action will delete this resource</p>}

        <Flex justifyContent={'flex-end'} gap="0.8rem" mt="6.4rem">
          <CustomButton
            onClick={cancel}
            name="Cancel"
            variant="black--outline"
            mW="9.5rem"
            h="4.3rem"
          />
          <CustomButton
            variant="red"
            onClick={deleteItem}
            loading={loading}
            name={buttonText || 'Delete Item'}
            mW="9.5rem"
            h="4.3rem"
            bgColor={'var(--error)'}
          />
        </Flex>
      </DeleteRoot>
    </SimpleModal>
  );
};

const DeleteRoot = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 1.6rem 2.4rem;
  border: 1px solid var(--grey-150);
  border-radius: 0.4rem;

  h2 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.117188px;
    color: var(--black);
    margin-bottom: 1.6rem;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: var(--black);
  }
`;

export { DeletePromptModal };
