import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const activeStatus = {
  color: '#36CA8B',
  background: 'rgba(54, 202, 139, 0.1)',
  border: '1px solid #36CA8B',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  borderRadius: '20px',
};
export const deactivatedStatus = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: ' rgba(240, 168, 0, 0.1)',
  border: '1px solid #F0A800',
  borderRadius: '20px',
};
export const rejectedStatus = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(250, 74, 74, 0.1)',
  border: '1px solid #FA4A4A',
  borderRadius: '20px',
};
export const notStarted = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: ' rgba(153, 159, 163, 0.1)',
  border: ' 1px solid #999FA3',
  borderRadius: '20px',
};
export const inprogress = {
  color: '#F0A800',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: ' rgba(240, 168, 0, 0.1)',
  border: '1px solid #F0A800',
  borderRadius: ' 20px',
};
export const upcomingStatus = {
  color: '#23273B',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(153, 159, 163, 0.1)',
  border: '1px solid #999FA3',
  borderRadius: ' 20px',
};

export const StatusType = styled(Flex)`
  ${({ status }) =>
    status === 'active' ||
    status === 'approved' ||
    status === 'completed' ||
    status === 'accepted' ||
    status === 'spent'
      ? activeStatus
      : null}
  ${({ status }) =>
    status === 'deactivated' || status === 'pending' || status === 'in progress'
      ? deactivatedStatus
      : null}
  ${({ status }) =>
    status === 'pending' || status === 'awaitingHR' || status === 'in progress'
      ? deactivatedStatus
      : null}
  ${({ status }) => (status === 'rejected' || status === 'deactivated' ? rejectedStatus : null)}
  ${({ status }) => (status === 'scheduled' || status === 'not started' ? notStarted : null)}
`;

export const Status = ({ status }) => {
  return (
    <StatusType className="status" status={status}>
      <Text textTransform={'capitalize'}>{status}</Text>
    </StatusType>
  );
};

// export const DotStatusType = styled(Flex)`
//   display: flex;
//   align-items: center;
//   position: relative;
//   :before {
//     content: '';
//     position: absolute;
//     width: 12px;
//     height: 12px;
//     border-radius: 50%;
//     ${({ status }) => (status === 'active' ? 'background: #1aa551' : '')};
//     ${({ status }) => (status === 'deactivated' ? 'background: #FF5252' : '')};
//     ${({ status }) => (status === 'pending' ? 'background: #FF5252' : '')};
//     ${({ status }) => (status === 'active' ? 'border: 3px solid #EAFBF1' : '')};
//     ${({ status }) => (status === 'deactivated' ? 'border: 3px solid white' : '')};
//     ${({ status }) => (status === 'pending' ? 'border: 3px solid #ffe5e5' : '')};
//   }
//   .dot {
//     ${({ status }) => (status === 'active' ? activeStatus : null)}
//     ${({ status }) => (status === 'deactivated' ? deactivatedStatus : null)}
//     ${({ status }) => (status === 'pending' ? deactivatedStatus : null)}
//   }
//   p {
//     padding-left: 1.5rem;
//   }
// `;
