import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
`;

export const CardContainer = styled.div`
  width: 17.5rem;
  height: 9.2rem;
  padding: 24px 0px 0px 24px;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  box-shadow: 0px 4x 20px rgba(228, 228, 228, 0.25);

  p {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    line-height: 28px;
  }

  span {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #23273b;
    line-height: 16px;
    margin-top: 8px;
  }

  .month {
    color: #3e7eee;
  }

  .birthdays {
    color: #3e7eee;
  }
`;

export const EmployeesCardContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0px 16px 1.6rem;
  border: 1px solid #f2f2f2;
  border-radius: 4px;

  .card-item-container div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const EmployeesCardText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 0px 8px;

  .card-heading {
    color: #23273b;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
  }

  button {
    font-size: 12px;
    font-family: Karla;
    font-weight: 400;
    color: '#23273B';
  }
`;

export const EmployeeCardItem = styled.div`
  display: flex;
  height: 54px;
  width: 100%;
  background-color: #f5f7fb;
  border-radius: 4px;
  align-items: center;
  border: 1px solid #f2f2f2;
  justify-content: space-between;
  padding: 0px 16px;

  .card-item-text {
    .departments {
      color: #23273b;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      font-style: normal;
      text-transform: capitalize;
    }

    .members {
      color: #999fa3;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      letter-spacing: -0.5px;
      margin-top: 4px;

      span {
        color: #23273b;
      }
    }
  }

  .card-images-container {
    display: flex;

    img {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      //border: 2px solid white;
      box-shadow: 2px 2px 4px rgba(8, 12, 35, 0.2);
      background-color: white;
    }
  }

  .card-images-container img:not(:first-of-type) {
    margin-left: -5px;
  }
`;

export const Stroke = styled.div`
  border: 1px solid #f2f2f2;
  width: 306px;
  width: 100%;
  margin: 16px auto 16px;
`;

export const UpcomingCardContainer = styled.div`
  height: 340px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  padding: 0px 16px;

  .card-item-container div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const UpcomingCardText = styled.div`
  margin-top: 24px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-heading {
    color: #23273b;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
  }

  button {
    font-size: 12px;
    font-family: Karla;
    font-weight: 400;
    color: '#23273B';
  }
`;

export const UpcomingCardItemContainer = styled.div`
  position: relative;
`;

export const UpcomingCardItem = styled.div`
  display: flex;
  height: 56px;
  width: 100%;
  background-color: #f5f7fb;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;

  .card-details {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 5px;
  }

  .first-text {
    margin-top: 10px;
  }

  .card-dots {
    margin: 0 !important;
    height: 100%;
    display: grid;
    place-content: center;

    img {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .card-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.5px;
    color: #23273b;
  }

  .card-span {
    font-weight: 400;
    font-size: 8px;
    color: #23273b;
    margin-top: 3px;
  }

  .three-dots {
    cursor: pointer;
    height: 12px;
  }

  .ml {
    text-align: center;
  }
`;

export const Popup = styled.div`
  width: 202px;
  height: 116px;
  border-radius: 4px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 4px 4px 15px rgba(36, 36, 36, 0.2);
  position: absolute;
  z-index: 999999;
  top: 40px;
  right: 30px;

  .send {
    background-color: #f8f9fb;
  }

  .btn {
    display: block;
    font-family: inherit;
    height: 48px;
    width: 100%;
    text-align: left;
    padding: 12px 0px 12px 12px;
    border-radius: 4px;
    color: #333758;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
  }
`;

export const MessageCardContainer = styled.div`
  background-color: #ffffff;
  min-width: 446px;
  padding: 0 2.4rem;

  .close {
    cursor: pointer;
  }

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.0012em;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 1rem;
  }

  h3 {
    color: #23273b;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    font-family: Karla;
  }

  p {
    margin: 48px 0 2.2px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #23273b;
    text-transform: capitalize;
  }
`;

export const CText = styled(Text)`
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 700;
  color: var(--black);
`;
export const CBox = styled(Flex)`
  border-bottom: 1px solid #f2f2f2;
  height: 6rem;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 1.6rem);
  margin: 0 auto 2rem;
  background-color: #fff;
`;
export const TableWrapperBox = styled(Box)`
  background-color: #fff;
  overflow: scroll;
  @media (min-width: 768px) {
    overflow: hidden;
  }
`;
export const HiringTableWrapper = styled(Box)`
  border: 1px solid #f2f2f2;
  border-radius: 0.8rem;
  padding: 0 1.8rem 2rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
`;

export const NavigateBtn = styled(Text)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #23273b;
  cursor: pointer;

  &:hover {
    color: var(--blue);
  }
`;
