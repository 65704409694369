import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { AssignEmployeesModal, Button } from 'components';

const SelectEmployee = ({ handleSelectEmployees, state, assigned }) => {
  const [showModal, setShowModal] = useState(false);

  const assignedLength = assigned?.length;
  const selectedEmployeeIds = assigned?.map((emp) => emp._id);

  const stateLength = state?.length;

  const selectedEmployees = (employeeIds) => {
    handleSelectEmployees(employeeIds);
    setShowModal(false);
  };

  useEffect(() => {
    handleSelectEmployees(selectedEmployeeIds, true);
  }, [assignedLength]);

  return (
    <>
      <Flex gap={'16px'} alignItems={'center'}>
        {stateLength ? (
          <Text fontWeight={400} fontSize={'14px'}>
            <Box as="span" color={'#3E7EEE'}>
              {stateLength}
            </Box>{' '}
            employees selected for enrollment
          </Text>
        ) : (
          <Text fontWeight={400} fontSize={'14px'}>
            No employee selected for enrollment
          </Text>
        )}

        <Button
          title={'Add or Remove Employee'}
          style={{
            width: '215px',
            height: '48px',
            background: '#23273B',
            borderRadius: '4px',
            color: '#FFFFFF',
          }}
          onClick={() => setShowModal(true)}
        />
      </Flex>
      <AssignEmployeesModal
        title={'Assign Employees'}
        setShowModal={setShowModal}
        showModal={showModal}
        onClick={selectedEmployees}
        assigned={assigned}
      />
    </>
  );
};

export { SelectEmployee };
