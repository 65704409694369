import { useEffect, useState } from 'react';

export const useRevieweeReport = () => {
  const [selectedButton, setSelectedButton] = useState('self appraisal');

  let tabs = [
    { displayName: `SELF APPRAISAL`, value: 'self appraisal' },
    { displayName: `MANAGER REVIEWS`, value: 'manager reviews' },
    { displayName: `PEER REVIEWS`, value: 'peer reviews' },
  ];

  const Tabs = {
    selfAppraisal: 'self appraisal',
    managerReviews: 'manager reviews',
    peerReviews: 'peer reviews',
  };
  const setSelected = (tab) => {
    Object.keys(Tabs).forEach((key) => {
      if (Tabs[key] === tab) {
        setSelectedButton(Tabs[key]);
      }
    });
  };

  useEffect(() => {
    setSelected(selectedButton);
  }, [selectedButton]);

  return {
    tabs,
    setSelectedButton,
    selectedButton,
  };
};
