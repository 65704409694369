import React from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '@chakra-ui/react';

import { BaseButton } from 'components';
import { SectionsIcons } from 'components/shared/Svgs';

import { css } from '@emotion/css';

function SectionEdit({ field, update, remove, index }) {
  const { register, watch, handleSubmit } = useForm({ defaultValues: field });
  const name = watch('name');

  function onRemove() {
    remove(index);
  }

  function onSave(d) {
    update(index, { ...field, ...d, isEditing: false });
  }

  return (
    <header className={headerStyles}>
      <div className="input-label">
        <div className="input-label__icon">{SectionsIcons}</div>
        <div className="input-label__name">
          <Input
            h="3.2rem"
            fontSize="1.4rem"
            className="section-edit-input"
            placeholder="Enter section name"
            {...register('name')}
          />
        </div>
      </div>
      <div className="input__controls">
        <BaseButton variant="text" sm grey onClick={onRemove}>
          Delete
        </BaseButton>
        <BaseButton variant="dark" sm onClick={handleSubmit(onSave)} disabled={!name}>
          Save
        </BaseButton>
      </div>
    </header>
  );
}

const headerStyles = css`
  display: grid;
  grid: auto-flow 1fr / 1fr;
  justify-content: flex-end;
  padding-block: 16px;
  grid-column: span 2;
  gap: 8px;

  .input-label {
    width: 100%;
    display: grid;
    grid: 1fr / max-content 1fr;
    align-items: center;
    gap: 1.2rem;

    &__icon {
      transform: scale(1.25);
    }
  }

  .input-label input {
    width: 100%;
  }

  .input__controls {
    display: flex;
    justify-content: flex-end;
  }

  .section-edit-input {
    scroll-margin-bottom: 4rem;
  }

  @media (min-width: 960px) {
    padding: 0;
    grid: 1fr / max-content auto;
    justify-content: space-between;
  }
`;

export { SectionEdit };
