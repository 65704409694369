import React from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { Box, Grid } from '@chakra-ui/react';
import { useReviewCycleForm } from 'context/reviewCycleForm';

import { BaseDropdown, CustomInput } from 'components';

import { inputError } from '../../../../../../utils';

import { InputLabel } from './InputLabel';

export function TitleStack() {
  const { register, control } = useReviewCycleForm();
  const formState = useFormState({ control });

  const frequencyOptions = [
    { value: 'once', label: 'Once' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'semi_annually', label: 'Semi Annually' },
    { value: 'annually', label: 'Annually' },
  ];
  return (
    <Grid templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }} gap="24px">
      <Box>
        <InputLabel>Title*</InputLabel>
        <CustomInput
          {...register('title')}
          placeholder="Enter a title for your review"
          style={{ height: '48px' }}
          withBorder
        />
        {inputError(formState.errors, 'title')}
      </Box>
      <Box>
        <InputLabel>Frequency</InputLabel>
        <Controller
          name="frequency"
          control={control}
          render={({ field }) => (
            <BaseDropdown
              {...field}
              w="100%"
              placeholder="Select frequency"
              border="1px solid var(--grey-150)"
              options={frequencyOptions}
            />
          )}
        />
        {inputError(formState.errors, 'frequency')}
      </Box>
    </Grid>
  );
}
