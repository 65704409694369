import React, { useState } from 'react';
import { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { EDIT_TRAINING, INDIVIDUAL_SINGLE_TRAINING, SHARED_TRAINING } from 'query';

import { EmptyState, TrainingComponent } from 'components';

const EditTraining = ({ setOpenModal, openModal, id, refetch, individual }) => {
  const initialState = {
    name: '',
    description: '',
    attachedFile: '',
    attachedLink: '',
    details: [],
    schedule: {
      startDate: '',
      endDate: '',
    },
    repeatProgram: {
      isRepeated: false,
      repeatIn: 0,
      repeatType: '',
    },
    employeeId: [],
  };
  const { successToast, errorToast } = useToastify();

  const [state, setState] = useState(initialState);

  const [editTraining, { loading: editTrainingLoading }] = useMutation(EDIT_TRAINING);

  const {
    data: trainingDetails,
    loading,
    refetch: refetchSingleDetails,
  } = useQuery(individual ? INDIVIDUAL_SINGLE_TRAINING : SHARED_TRAINING, {
    variables: {
      filter: {
        _id: id,
      },
    },
  });

  const fetchedData = trainingDetails?.fetchTraining?.data[0];

  const onEditTraining = async (state) => {
    delete state?.employees;
    delete state?.dueDate;
    const { attachedFile: fileData, ...rest } = state;

    const file = fileData;
    const variables = { filters: { _id: id }, data: rest };

    try {
      const res = await editTraining({
        variables: file ? { ...variables, file } : { ...variables },
      });
      const { data } = res;
      if (data && data.editTraining.status === 200) {
        successToast('Training edited sucessfully');
        setInterval(setOpenModal(false), 2000);
        refetch();
        refetchSingleDetails();
      } else errorToast(data.editTraining.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  useEffect(() => {
    setState(fetchedData);
  }, [fetchedData]);

  return openModal && [state]?.length ? (
    <TrainingComponent
      openModal={openModal}
      setOpenModal={setOpenModal}
      onCreateTraining={onEditTraining}
      loading={editTrainingLoading}
      setState={setState}
      pageLoading={loading}
      value={state}
      individual={individual}
      title={'Edit training program'}
      buttonName={'Edit'}
    />
  ) : (
    <EmptyState />
  );
};

export { EditTraining };
