import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_LEAVE_TYPE } from 'query';

import { ModalBackButton, SpinnerComponent } from 'components';

import { Button } from '../../jobs/button';
import { InputBox } from '../../jobs/inputbox';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;

  @media only screen and (max-width: 900px) {
    padding: 10px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 24px;

  p {
    font-weight: 600;
    font-size: 24px;
    color: #23273b;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

const InputFields = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  max-width: 737px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 24px;

  .form-line {
    height: 1px;
    background: #f2f2f2;
    border: none;
  }

  .duration {
    width: 300px;
  }

  .durationType {
    width: 125px;
  }

  .durationDay {
    width: 200px;
  }

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    padding: 10px;

    .input-style {
      width: 100%;
    }

    .duration,
    .durationType,
    .durationDay {
      width: 100%;
    }
  }
`;

const saveButton = {
  background: '#3e7eee',
  borderRadius: '4px',
  fontSize: '16px',
  fontWeight: 600,
  color: '#ffffff',
  padding: '16px 40px',
};

const cancelButton = {
  border: '1px solid #23273b',
  borderRadius: '4px',
  fontSize: '16px',
  fontWeight: 600,
  padding: '16px 40px',
};

const backButton = {
  background: '#FAFCFF',
  border: '1px solid #F2F2F2',
  borderRadius: '20px',
  padding: '12px 16px',
  width: '110px',
};

const labelStyle = {
  fontSize: '12px',
  color: '#23273B',
  letterSpacing: '-0.0012em',
};

const inputStyle = { fontSize: '14px', border: '1px solid #F2F2F2', textTransform: 'capitalize' };

const EditLeaveTypeAndPolicy = (props) => {
  const isTesting = process.env.NODE_ENV === 'test';

  const durationData = [
    {
      label: 'Hours',
      value: 'hours',
    },
    {
      label: 'Days',
      value: 'days',
    },
    {
      label: 'Weeks',
      value: 'weeks',
    },
  ];

  const paidTimeSettings = [
    {
      label: 'Paid',
      value: 'paid',
    },
    {
      label: 'Not Paid',
      value: 'unpaid',
    },
  ];

  const dropdown = [
    {
      description: 'Leaves related to employee health',
      name: 'health',
      paidTimeSetting: 'paid',
      _id: '637f609ac4189bfab704ceb6',
    },
    {
      description: 'Sick days to be used',
      name: 'vacation',
      paidTimeSetting: 'paid',
      _id: '637f5e3ec4189bfab704ce5d',
    },
  ];

  let [leaveType, setLeaveType] = useState();

  const { data: leaveTypeData, loading } = useQuery(FETCH_LEAVE_TYPE, {});

  const fetchLeaveType = leaveTypeData?.retrieveLeaveTypes?.data;

  useEffect(() => {
    setLeaveType(fetchLeaveType);

    if (isTesting) {
      setLeaveType(dropdown);
    }
  }, [fetchLeaveType]);

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === 'duration') {
      value = Number(value);
      props.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      props.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Container>
      {props?.leavePolicy ? (
        <Card>
          {props?.editMode ? (
            <p>{props?.title}</p>
          ) : (
            <Flex gap={'24px'} alignItems={'center'} textTransform={'capitalize'}>
              <ModalBackButton
                data-testid={'backButton'}
                style={backButton}
                onClick={props?.goBack}
              />
              <p>{props?.title}</p>
            </Flex>
          )}
          {loading && !isTesting ? (
            <Grid minH="20rem" placeContent="center" mt="20rem">
              <SpinnerComponent data-testid="loading" />
            </Grid>
          ) : (
            <InputFields data-testid="form">
              <Flex
                className="input-style"
                flexDirection={'column'}
                gap={'24px'}
                paddingBottom={'16px'}>
                <InputBox
                  label={'Policy Name'}
                  placeholder={'Eg: Sick Leave'}
                  required={true}
                  dataTestId={'name'}
                  id={'name'}
                  handleChange={handleChange}
                  help={true}
                  value={props.value?.name}
                  labelStyle={labelStyle}
                  inputStyle={inputStyle}
                />
                <div className="duration">
                  <InputBox
                    label={'Leave Type'}
                    dataTestId={'leaveTypeId'}
                    placeholder={'Vacation'}
                    type={'dropdown'}
                    id={'leaveTypeId'}
                    dropdown={leaveType}
                    required={true}
                    value={props.value?.leaveTypeId}
                    handleChange={(e) =>
                      handleChange({
                        target: {
                          name: 'leaveTypeId',
                          value: e.value,
                        },
                      })
                    }
                    labelStyle={labelStyle}
                    inputStyle={inputStyle}
                    dropdownStyle={{
                      width: '100%',
                    }}
                  />
                </div>

                <Flex alignItems={'flex-end'} gap={'8px'} className={'duration'}>
                  <div className="durationDay">
                    <InputBox
                      dataTestId={'duration'}
                      label={'Leave Duration'}
                      placeholder={'20'}
                      id={'duration'}
                      required={true}
                      type={'number'}
                      handleChange={handleChange}
                      value={props.value?.duration}
                      labelStyle={labelStyle}
                      inputStyle={inputStyle}
                    />
                  </div>
                  <div className="durationType">
                    <InputBox
                      dataTestId={'durationType'}
                      placeholder={'Days'}
                      type={'dropdown'}
                      id={'durationType'}
                      value={props.value?.durationType}
                      dropdown={durationData}
                      handleChange={(e) =>
                        handleChange({
                          target: {
                            name: 'durationType',
                            value: e.value,
                          },
                        })
                      }
                      labelStyle={labelStyle}
                      inputStyle={inputStyle}
                      dropdownStyle={{
                        width: '100%',
                      }}
                    />
                  </div>
                </Flex>
              </Flex>
              <hr className="form-line" />
            </InputFields>
          )}
        </Card>
      ) : (
        <Card>
          {props.editMode ? (
            <p>{props?.title}</p>
          ) : (
            <Flex gap={'24px'} alignItems={'center'}>
              <ModalBackButton dataTestId={'backButton'} title={'Back'} onClick={props.goBack} />
              <p>{props?.title}</p>
            </Flex>
          )}
          <InputFields>
            <Flex
              className="input-style"
              flexDirection={'column'}
              gap={'24px'}
              paddingBottom={'16px'}>
              <InputBox
                label={'Type Name'}
                placeholder={'Eg: Vacation'}
                required={true}
                dataTestId={'name'}
                id={'name'}
                value={props.value?.name}
                handleChange={handleChange}
                help={true}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
              />

              <InputBox
                label={'Paid Time Setting'}
                placeholder={'Paid'}
                type={'dropdown'}
                help={true}
                dataTestId={'paidTimeSetting'}
                id={'paidTimeSetting'}
                dropdown={paidTimeSettings}
                required={true}
                value={props.value?.paidTimeSetting}
                handleChange={(e) =>
                  handleChange({
                    target: {
                      name: 'paidTimeSetting',
                      label: e.label,
                      value: e.value,
                    },
                  })
                }
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                dropdownStyle={{
                  width: '100%',
                }}
              />

              <InputBox
                label={'Description'}
                type={'textarea'}
                id={'description'}
                dataTestId={'description'}
                help={true}
                placeholder={'Eg: Sick days to be used when ill'}
                required={false}
                value={props.value?.description}
                handleChange={handleChange}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
              />
            </Flex>
            <hr className="form-line" />
          </InputFields>
        </Card>
      )}
      <Buttons>
        <Button
          dataTestId={'cancel'}
          title={'Cancel'}
          style={cancelButton}
          onClick={props.goBack}
        />
        <Button
          dataTestId={'save'}
          title={'Save'}
          isDisabled={props.isDisabled}
          onClick={() => props?.onClick(props?._id)}
          style={saveButton}
          isLoading={props.loading}
        />
      </Buttons>
    </Container>
  );
};

export { EditLeaveTypeAndPolicy };
