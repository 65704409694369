import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_SINGLE_LEAVE_REQUEST } from 'query';
import { createActionHandler } from 'utils';

import {
  Calendar,
  EventsActionContainer,
  EventsPopupAction,
  FullModal,
  ModalBackButton,
  SimpleModal,
  SpinnerComponent,
} from 'components';

import { ApprovePrompt, DenyPrompt, EditLeaveRequest, ForceDenyPrompt } from './component';
import { TimeLine } from './component';
import { RequestHook } from './hooks';
import { OverlapRequestsTable } from './OverlapRequests';

const dropdownAction = {
  edit: 'Edit',
  'force approve': 'Force Approve',
  'force deny': 'Force Deny',
};

const SingleRequest = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const { data, loading, refetch } = useQuery(FETCH_SINGLE_LEAVE_REQUEST, {
    variables: { leaveId: id },
  });

  const { showModal, modalType, setModalState, denyAction, editAction } = RequestHook();

  const leaveRequestData = data?.fetchSingleRequest?.data;
  const overlappingRequests = leaveRequestData?.overlappingRequests;
  const approvalFlow = leaveRequestData?.approvalFlow;
  const reason = leaveRequestData?.reasonForDenial;
  const summary = {
    'Leave Type': leaveRequestData?.leaveTypeId?.name,
    'Balance Before': `${leaveRequestData?.balance} ${leaveRequestData?.durationType || ''}`,
    'Count As': `${leaveRequestData?.count} ${leaveRequestData?.durationType || ''}`,
    Description: leaveRequestData?.description,
  };

  const date = new Date(leaveRequestData?.startDate) || new Date();
  const toDate = new Date(leaveRequestData?.endDate) || new Date();

  const approveAction = () => {
    setModalState({ showModal: true, modalType: 'force-approve' });
  };

  const actionOnData = createActionHandler({
    editAction,
    approveAction,
    denyAction,
  });

  return (
    <>
      <SingleRoot data-testid="single-leave">
        <FullModal showModal={true} noHeader mt="8rem">
          <div className="modal--header">
            <Flex gap="2.4rem">
              <ModalBackButton
                onClick={() => navigate('/admin/leave-management')}
                data-testid="modal-back-btn"
              />
              <Text textTransform={'capitalize'}>
                View Request - {!loading ? `${leaveRequestData?.leaveTypeId?.name || 'N/A'}` : ''}
              </Text>
            </Flex>
            <EventsActionContainer>
              <EventsPopupAction
                actionOnData={actionOnData}
                dropdownActions={dropdownAction}
                danger="Force Deny"
              />
            </EventsActionContainer>
          </div>
          <div className="modal--content">
            {loading ? (
              <SpinnerComponent h="70vh" />
            ) : (
              <>
                <div className="content--top">
                  <div className="calender--wrap">
                    <div className="calender">
                      <Calendar
                        isRange
                        date={date}
                        setDate={() => {}}
                        toDate={toDate}
                        setToDate={() => {}}
                      />
                    </div>
                    <Summary summary={summary} />
                  </div>
                  <TimeLine
                    approvalFlow={approvalFlow}
                    reason={reason}
                    approve={() => setModalState({ showModal: true, modalType: 'approve' })}
                    denyLeave={() => setModalState({ showModal: true, modalType: 'deny' })}
                  />
                </div>
                <OverlapRequestsTable data={overlappingRequests} />
              </>
            )}
          </div>
        </FullModal>
        <SimpleModal
          showModal={showModal}
          padding="2.4rem"
          width={modalType === 'edit' ? '' : '75rem'}>
          {(modalType === 'force-approve' || modalType === 'approve') && (
            <ApprovePrompt
              cancel={setModalState}
              leaveId={id}
              force={modalType === 'force-approve' ? true : false}
              refetch={refetch}
            />
          )}
          {modalType === 'deny' && (
            <DenyPrompt cancel={setModalState} leaveId={id} refetch={refetch} />
          )}
          {modalType === 'force_deny' && (
            <ForceDenyPrompt cancel={setModalState} leaveId={id} refetch={refetch} />
          )}
          {modalType === 'edit' && (
            <EditLeaveRequest
              cancel={setModalState}
              editData={leaveRequestData}
              leaveId={id}
              refetch={refetch}
            />
          )}
        </SimpleModal>
      </SingleRoot>
    </>
  );
};

const Summary = ({ summary }) => {
  return (
    <div className="leave-summary">
      {Object.entries(summary).map(([key, value]) => {
        return (
          <div key={key}>
            <p className="title">{key}</p>
            <p className="subtitle">{value}</p>
          </div>
        );
      })}
    </div>
  );
};

const SingleRoot = styled.div`
  background: var(--bg);
  height: 76.3rem;
  padding: 2.4rem;
  .right-modal-overlay {
    padding: 2.4rem;
  }
  .modal--header {
    position: sticky;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    padding-bottom: 1.2rem;
    width: 100%;
    p {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3.3rem;
      color: var(--black);
    }
  }

  .modal--content {
    display: grid;
    padding: 2rem 0;
    grid-gap: 2.4rem;
    width: 100%;

    .content--top {
      display: grid;
      grid: 1fr / 1fr;
      grid-gap: 2.4rem;
      @media (min-width: 1200px) {
        grid: 1fr / 1.75fr 1fr;
      }
    }
  }

  .calender--wrap {
    padding: 2.4rem;
    border: 1px solid var(--grey-150);
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4.8rem;
    @media (min-width: 900px) {
      align-items: center;
      flex-direction: row;
    }

    .calender {
      width: 32rem;
      min-width: 50%;
      padding-bottom: 4rem;
    }
  }

  .leave-summary {
    display: grid;
    grid-gap: 3.2rem;
    position: relative;
    padding-left: 4.8rem;

    &:before {
      content: '';
      border: 1px solid var(--grey-150);
      position: absolute;
      top: 0;
      bottom: 0;
    }

    .title {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.1rem;
      letter-spacing: -0.02em;
      color: var(--black);
      margin-bottom: 0.8rem;
    }

    .subtitle {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.1rem;
      letter-spacing: -0.02em;
      color: var(--grey-600);
    }
    .subtitle:first-letter {
      text-transform: capitalize;
    }
  }
`;

export { SingleRequest };
