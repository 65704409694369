import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { BaseDropdown } from 'components';

import plusIcon from '../../../../../../assets/icons/plusIcon.svg';
import { FETCH_ROLES } from '../../../../../../query/admin/leave-management';
import { extractData } from '../../helpers';

export function ApprovalStepsForm({ control }) {
  const { fields, remove, append } = useFieldArray({ control, name: 'steps' });
  const [roles, setRoles] = useState([]);
  const { data: allRoles } = useQuery(FETCH_ROLES, {
    variables: {
      filters: {},
      page: 1,
    },
  });

  useEffect(() => {
    setRoles(extractData(allRoles?.fetchRolesFiltered?.data));
  }, [allRoles]);

  return (
    <Stack>
      <Text marginBottom={'0.8rem'}>Approval Steps</Text>
      <Stack
        w={'73.7rem'}
        p={'3.2rem'}
        border={'solid 1px var(--grey-250)'}
        gap={'2.4rem'}
        borderRadius={'0.4rem'}>
        {fields.map((field, index) => {
          return (
            <div key={field.id}>
              <Text fontSize={'1.2rem'} fontWeight={'600'} marginBottom={'0.8rem'}>
                {`Step ${index + 1}`}
              </Text>
              <Flex alignItems={'center'} gap={'2.4rem'}>
                <Controller
                  defaultValue=""
                  control={control}
                  name={`steps.${index}`}
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      placeholder={'Manager'}
                      options={roles}
                      w={'33.2rem'}
                    />
                  )}
                />
                <Text
                  color={'red'}
                  textDecoration={'underline'}
                  cursor={'pointer'}
                  data-testid={'removeApprovalStepsBtn'}
                  onClick={() => {
                    remove(index);
                  }}>
                  Remove
                </Text>
              </Flex>
            </div>
          );
        })}
        <button
          data-testid={'addApprovalStepsBtn'}
          type="button"
          onClick={() => {
            append({ name: '' });
          }}>
          <Flex gap={'0.8rem'} cursor={'pointer'}>
            <img src={plusIcon} alt="" width={'15px'} height={'15px'} />
            <Text fontSize={'1.4rem'} color={'var(--green)'} textDecoration={'underline'}>
              Add Level
            </Text>
          </Flex>
        </button>
      </Stack>
    </Stack>
  );
}
