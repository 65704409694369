import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';

const CButton = styled(Button)`
  width: 100%;
  padding: 1.2rem 1.8rem;
  border: 1px solid #edf2f7;
  border-radius: 0.4rem;
  background-color: #fafcff;
  color: #425466;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  img {
    margin-right: 1rem;
  }
  &:hover {
    background-color: white;
  }
`;

export const SingleQuickLink = ({
  name,
  h,
  fW,
  bR,
  fS,
  mW,
  src,
  handleButtonClick,
  color,
  border,
  type,
  onClick,
  testId,
  link,
}) => {
  return (
    <Link to={link}>
      <CButton
        data-testid={testId}
        height={h || '6rem'}
        maxW={mW}
        minW={'max-content'}
        color={color}
        border={border}
        fontWeight={fW || '600'}
        fontSize={fS || '1.4rem'}
        borderRadius={bR || '0.4rem'}
        type={type}
        onClick={onClick || handleButtonClick}>
        {src ? <img src={src} alt="i" /> : null}
        {name}
      </CButton>
    </Link>
  );
};
