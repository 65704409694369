import { Flex } from '@chakra-ui/react';

import docs from '../../../assets/images/Document.svg';
import ellipseImg from '../../../assets/images/Ellipse.svg';
import folder from '../../../assets/images/Folder.svg';

export const DocumentTableColumns = [
  {
    Header: 'Name',
    accessor: 'col1',
  },
  {
    Header: 'Created by',
    accessor: 'col2',
  },
  {
    Header: 'Size',
    accessor: 'col3',
  },
  {
    Header: 'Date Modified',
    accessor: 'col4',
  },
  {
    Header: 'Action ',
    accessor: 'col5',
  },
];
export const SingleFolderColumns = [
  {
    Header: 'Name',
    accessor: 'col1',
  },
  {
    Header: 'Created by',
    accessor: 'col2',
  },
  {
    Header: 'Size',
    accessor: 'col3',
  },
  {
    Header: 'Date Modified',
    accessor: 'col4',
  },
  {
    Header: 'Action ',
    accessor: 'col5',
  },
];
const DocumentName = () => {
  return (
    <Flex gap="10px">
      <img src={docs} alt="documentImage" />
      <p>Offer Letter </p>
    </Flex>
  );
};
const DocumentImage = () => {
  return (
    <Flex gap="10px" alignItems="center">
      <img src={ellipseImg} alt="documentImage" />
      <p>Floyd Miles </p>
    </Flex>
  );
};
const FolderName = () => {
  return (
    <Flex gap="10px">
      <img src={folder} alt="documentImage" />
      <p>Offer Letter </p>
    </Flex>
  );
};

export const DocumentTableData = [
  {
    _id: 'jehkhadgfshagdhfjagsw',
    name: FolderName(),
    type: ' Folder ',
    created: DocumentImage(),
    dateModified: '01 March, 2019',
    action: '',
  },
  {
    _id: 'jehkhadgfsshagdhfjagsw',
    name: FolderName(),
    type: ' Folder ',
    created: DocumentImage(),
    dateModified: '01 March, 2019',
    action: '',
  },
  {
    _id: 'jehkhadgfrshagdhfjagsw',
    name: DocumentName(),
    type: ' View Only Document ',
    created: DocumentImage(),
    dateModified: '01 March, 2019',
    action: '',
  },
];
