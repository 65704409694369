import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const MainTableWrapper = styled(Box)`
  // min-width: 900px;
  table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0px;
  }
  thead {
    border-radius: 0.8rem;
  }
  thead tr {
    border-radius: 0.8rem;
    align-items: center;
    height: 4.8rem;
    font-weight: 700;
    letter-spacing: -0.05rem;
    line-height: 1.6rem;
    background: #f3f4f6;
  }
  thead th {
    position: relative;
  }
  .th-parent span {
    position: absolute;
    cursor: pointer;
    padding: 0.2em 0.8rem 0;
  }
  thead th {
    border-bottom: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
  }
  thead th:first-of-type {
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    border-left: 1px solid #f2f2f2;
  }
  thead th:last-child {
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    border-right: 1px solid #f2f2f2;
  }
  tbody th,
  tbody td {
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
    height: 6rem;
    background: #fff;
    color: #23273b;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  thead tr:last-child {
    height: 1.6rem;
    width: 100%;
    background-color: transparent;
  }

  th,
  td {
    max-width: 180px;
    //overflow-x: hidden;
    padding-inline: 12px;
    white-space: nowrap;
  }
  &:first-of-type {
    //padding-left: 16px;
  }

  .imageWrapper {
    width: 100%;
    max-width: max-content;
    align-items: center;
  }
  .imageWrapper img {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.6rem;
    border-radius: 50%;
  }
  .tableCell {
    position: relative;
    .suspense {
      position: absolute;
      overflow: hidden;
      width: 120px;
      height: 70%;
      &::before {
        content: '';
        position: absolute;
        height: 80%;
        width: 100px;
        top: 0;
        left: -32px;
        background: rgb(250, 252, 255);
        filter: blur(10px);
        animation: suspense 1s 1s ease infinite;
      }
    }
  }
  @keyframes suspense {
    from {
      left: -22px;
    }
    to {
      left: calc(100% + 22px);
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid: auto / repeat(auto-fill, 21rem);
  grid-gap: 1.8rem;
  overflow-x: auto;
  height: auto;
  padding-bottom: 8rem;
`;

export const GridCardItem = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #f2f2f2;
`;
