import { useEffect, useState } from 'react';
import { Flex, Grid, Image, Text } from '@chakra-ui/react';

import checkmark from './image/Checkboxbase.svg';
import arrow from './image/Path.svg';

export function ApplicantStatus(props) {
  const [status, setStatus] = useState(props?.data?.items?.label || 'Status');
  const [dropdown, setdropDown] = useState(false);
  useEffect(() => {
    setStatus(props?.data?.items?.label);
  });

  const stages = props.data.stages?.fetchStages?.data;
  const item = stages?.map((stage, index) => {
    return (
      <Flex key={stage?._id} alignItems={'center'} gap={'8px'} cursor={'pointer'}>
        <Grid>
          {stage.rank <= props.data.rank ? (
            <img src={checkmark} alt="" />
          ) : (
            <Text
              fontSize={'8px'}
              placeItems={'center'}
              color={'#FFFFFF'}
              background={'#999FA3'}
              borderRadius={'50%'}
              width={'14px'}
              height={'14px'}
              textAlign={'center'}>
              {index + 1}
            </Text>
          )}
        </Grid>
        <Text fontSize={'14px'} letterSpacing={'-0.117188px'}>
          {stage?.title}
        </Text>
      </Flex>
    );
  });

  return (
    <Flex onClick={() => setdropDown(!dropdown)} cursor={'pointer'}>
      <Flex gap={'0px 13px'} data-testid={'dropdown'}>
        <Text textTransform={'capitalize'}>{status}</Text>
        <button>
          <Image src={arrow} />
        </button>
      </Flex>
      {dropdown ? (
        <Flex
          flexDirection={'column'}
          gap={'16px 16px'}
          p={'16px'}
          background={'#FFFFFF'}
          boxShadow={'4px 4px 15px rgba(36, 36, 36, 0.2)'}
          borderRadius={'4px'}
          width={'140px'}
          position={'absolute'}
          zIndex={'2'}
          right={'-10px'}
          top={'30px'}>
          {item}
        </Flex>
      ) : null}
      {/* {item} */}
    </Flex>
  );
}

// return (
//   <div>
//     {stages?.map((stage) => (
//       <div key={stage._id}>
//         {stage?.title}{' '}
//         {stage.rank <= props.data.rank && (
//           <span role="img" aria-label="checkmark">
//             ✅
//           </span>
//         )}
//         {/* {res?.some((res) => res?.stage?.rank === stage.rank) ? (
//           <span role="img" aria-label="checkmark">
//             ✅
//           </span>
//         ) : null} */}
//       </div>
//     ))}
//   </div>
// );
