import React from 'react';

const initialState = {
  showModal: false,
  modalType: '',
  viewRequest: false,
  editData: {},
  leaveId: '',
  singleLeave: {},
};
function modalReducer(state, action) {
  return { ...state, ...action };
}

function RequestHook() {
  const [{ showModal, modalType, editData, leaveId }, setModalState] = React.useReducer(
    modalReducer,
    () => initialState
  );

  const approveAction = () => {
    setModalState({ showModal: true, modalType: 'approve' });
  };
  const denyAction = ({ action, id }) => {
    action === 'deny'
      ? setModalState({ showModal: true, modalType: 'deny', leaveId: id })
      : setModalState({ showModal: true, modalType: 'force_deny', leaveId: id });
  };
  const editAction = () => {
    setModalState({ showModal: true, modalType: 'edit' });
  };

  return {
    setModalState,
    approveAction,
    denyAction,
    editAction,
    showModal,
    modalType,
    editData,
    leaveId,
  };
}

export { RequestHook };
