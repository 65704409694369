export function formatDate(date) {
  const dateLabel = new Date(date).toLocaleDateString('en-uk', {
    day: `2-digit`,
    month: 'short',
    year: 'numeric',
  });
  return dateLabel;
}

export function getAnnouncement(arr, id) {
  if (arr === null || arr === undefined) return {};
  return arr.find((item) => item._id === id);
}
