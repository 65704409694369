import React from 'react';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { EmptyState, SimpleModal, SpinnerComponent } from 'components';

import { FETCH_NOTIFICATIONS, UPDATE_NOTIFICATION } from '../../query';
import { archiveMessage, getAllNotificationIds, switchTab, tabs } from '../helpers';
import { WrapperSimpleModal } from '../styles';

import { NotificationModal } from './Modals';
import { SingleNotificationMessage } from './SingleNotificationMessage';

export function Notifications() {
  const { data, loading, refetch, networkStatus } = useQuery(FETCH_NOTIFICATIONS, {
    variables: {
      filter: {
        status: 'recent',
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
    refetchQueries: [
      {
        query: FETCH_NOTIFICATIONS,
        variables: {
          filter: {
            status: 'recent',
          },
        },
      },
      {
        query: FETCH_NOTIFICATIONS,
        variables: {
          filter: {
            status: 'archived',
          },
        },
      },
    ],
  });

  const [showModal, setShowModal] = React.useState(false);
  function toggleShowModal() {
    setShowModal((prev) => !prev);
  }
  const [singleNotification, setSingleNotification] = React.useState({});

  const allMessages = data?.fetchNotifications?.data?.map((message) => (
    <SingleNotificationMessage
      key={message._id}
      id={message._id}
      status={message.status}
      type={message.type}
      isRead={message.readAt}
      message={message.data.message}
      category={message.data.category}
      note={singleNotification}
      setNote={setSingleNotification}
      openModal={toggleShowModal}
      updateNotification={updateNotification}
      refetch={refetch}
    />
  ));
  return (
    <>
      <Text fontWeight={'600'} marginBottom={'0.8rem'}>
        Notifications
      </Text>
      <Stack
        background-color={'red'}
        border={'solid 1px var(--grey-100)'}
        borderRadius={'0.4rem'}
        position={'relative'}
        overflow={'auto'}>
        <Flex
          borderBottom={'solid 1px var(--grey-150)'}
          p={'2.4rem 3.2rem'}
          justifyContent={'space-between'}
          width={'100%'}
          backgroundColor={'var(--white)'}
          position={'sticky'}
          top={'0'}>
          <Flex gap={'2.4rem'} textTransform={'capitalize'}>
            {tabs.map((el) => {
              return el.active ? (
                <Text
                  cursor={'pointer'}
                  onClick={() => switchTab(el.id, refetch)}
                  key={el.id}
                  data-testid={el.label}>
                  {el.label}
                </Text>
              ) : (
                <Text
                  data-testid={el.label}
                  cursor={'pointer'}
                  color={'var(--grey-500)'}
                  onClick={() => switchTab(el.id, refetch)}
                  key={el.id}>
                  {el.label}
                </Text>
              );
            })}
          </Flex>
          <Text
            data-testid={'archiveAll'}
            cursor={'pointer'}
            textDecoration={'underline'}
            color={'var(--blue)'}
            fontSize={'1.4rem'}
            onClick={() =>
              archiveMessage(
                getAllNotificationIds(data?.fetchNotifications?.data),
                updateNotification
              )
            }>
            Archive all
          </Text>
        </Flex>

        {loading || networkStatus === NetworkStatus.refetch ? (
          <SpinnerComponent />
        ) : (
          <Stack p={'2.4rem 3.2rem'} maxH={'560px'} minH={'560px'}>
            {data?.fetchNotifications?.data.length > 0 ? allMessages : <EmptyState />}
          </Stack>
        )}
      </Stack>
      <WrapperSimpleModal>
        <SimpleModal showModal={showModal}>
          <NotificationModal closeModal={toggleShowModal} note={singleNotification} />
        </SimpleModal>
      </WrapperSimpleModal>
    </>
  );
}
