import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { DELETE_CATEGORY, FETCH_ALL_CATEGORIES } from 'query';

import {
  EventsActionContainer,
  EventsPopupAction,
  Pagination,
  Search,
  TableComponent,
} from 'components';

import { useToastify } from '../../../../hooks/useToastify';

import { EditCategory } from './EditCategory';

const dropAction = {
  edit: 'Edit',
  delete: 'Delete',
};

export function Category() {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [editing, setEditing] = React.useState(null);
  const [searchString, setSearchString] = React.useState('');

  const filter = {};
  const { data, loading, refetch } = useQuery(FETCH_ALL_CATEGORIES, {
    variables: {
      page: page,
      filter: searchString ? { ...filter, keyword: searchString } : filter,
    },
  });

  const tData = data?.fetchAllCategories?.data;
  const { handleDeleteCategory } = useCategoryActions();

  function actionOnData(payload) {
    switch (payload.action) {
      case 'delete':
        handleDeleteCategory(payload.id);
        break;
      case 'edit':
        setEditing(payload.id);
        break;
    }
  }

  const tableData = tData?.map(({ _id }) => {
    return {
      col1: _id,
      col2: (
        <EventsActionContainer>
          <EventsPopupAction
            id={_id}
            dropdownActions={dropAction}
            actionOnData={actionOnData}></EventsPopupAction>
        </EventsActionContainer>
      ),
    };
  });

  const totalPages = data?.fetchAllCategories?.pageCount || 1;

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  return (
    <>
      <RootWrap>
        <>
          <Search
            placeholder={'Search Category'}
            value={search}
            setPage={setPage}
            setSearchString={setSearchString}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </>
        <Box mt="2.4rem">
          <TableComponent
            noCheckbox
            noAction
            noSort
            minH={'450px'}
            loading={loading}
            include={'Category'}
            columns={columns}
            data={tableData}
            empty={tData?.length === 0}
          />
        </Box>
      </RootWrap>
      <Box mt="1rem">
        {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
      </Box>
      {editing ? (
        <EditCategory editing={editing} setEditing={setEditing} refetch={refetch} />
      ) : null}
    </>
  );
}

const RootWrap = styled.div`
  background: var(--white);
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 2.4rem;

  tbody tr td:first-of-type {
    font-weight: 700;
  }

  thead tr th:nth-last-child(2) {
    text-align: right;
    padding-right: 4rem;
  }

  tbody tr td:last-child {
    display: flex;
    justify-content: flex-end;
    padding-right: 5.2rem;
  }
`;

const columns = [
  {
    Header: 'Asset Category',
    accessor: 'col1',
  },
  {
    Header: 'Action',
    accessor: 'col2',
  },
];

function useCategoryActions() {
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const { successToast } = useToastify();

  async function handleDeleteCategory(id) {
    await deleteCategory({ variables: { category: id } });
    successToast('Category deleted successfully');
  }

  return { handleDeleteCategory };
}
