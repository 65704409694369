import * as React from 'react';
import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseDropdown, CustomInput } from 'components';
import { FileInputIcon } from 'components/shared/Svgs';

import { INPUT_TYPES } from '../helpers';

export const FieldAppearance = (props) => {
  const { type, attrs, options, fieldName } = props;
  switch (type) {
    case INPUT_TYPES.Checkbox:
      return <CheckboxOptions options={options} fieldName={fieldName} />;
    case INPUT_TYPES.Attachment:
      return <FileInput />;
    case INPUT_TYPES.Dropdown:
      return <DropdownOptions options={options} attrs={attrs} />;
    case INPUT_TYPES.RadioButton:
      return <RadioOptions options={options} fieldName={fieldName} />;
    case INPUT_TYPES.DatePicker:
      return <CustomInput disabled={true} type="date" {...attrs} />;
    case INPUT_TYPES.Hyperlink:
      return <CustomInput disabled={true} type="url" {...attrs} />;
    case INPUT_TYPES.TextArea:
      return <CustomInput disabled={true} type="textarea" {...attrs} />;
    default:
      return <CustomInput disabled={true} {...attrs} />;
  }
};

const RadioLabel = styled.label`
  display: grid;
  grid: 1fr / auto-flow max-content;
  align-items: center;
  gap: 8px;
  margin-right: 3.2rem;
  margin-bottom: 4px;

  input[type='radio'],
  input[type='checkbox'] {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--grey-300);
    appearance: none;
    cursor: pointer;

    &:checked {
      border-color: var(--primary);
    }

    &:checked:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 8px;
      width: 8px;
      border-radius: inherit;
      background: var(--primary);
      transform: translate(-50%, -50%);
    }
  }

  &.checkbox {
    input[type='checkbox'] {
      border-radius: 3px;

      &:checked:before {
        border-radius: 1px;
        height: 9px;
        width: 9px;
        transform: translate(-51%, -51%);
      }
    }
  }
`;

function RadioOptions({ options = [], fieldName }) {
  return (
    <Flex py="8px" flexDirection={options.length > 2 ? 'column' : 'row'}>
      {options.map((option) => (
        <RadioLabel key={option}>
          <input type="radio" name={fieldName} className="radio-input" /> <span>{option}</span>
        </RadioLabel>
      ))}
    </Flex>
  );
}

function CheckboxOptions({ options = [], fieldName }) {
  return (
    <Flex py="8px" flexDirection={options.length > 2 ? 'column' : 'row'} flexWrap="wrap">
      {options.map((option) => (
        <RadioLabel className="checkbox" key={option}>
          <input type="checkbox" name={fieldName} className="radio-input" /> <span>{option}</span>
        </RadioLabel>
      ))}
    </Flex>
  );
}

function FileInput() {
  return (
    <Flex
      p="1.6rem"
      border={`1px dashed var(--grey-300)`}
      borderRadius="8px"
      alignItems="center"
      justifyContent="space-between"
      flexFlow="column nowrap"
      textAlign="center"
      cursor="pointer">
      <Flex alignItems="center" justifyContent="space-between" flexFlow="column nowrap">
        {FileInputIcon}
        <Stack direction="column" color="#000" ml="1rem" pr="1rem">
          <Text fontSize="1.3rem" lineHeight="1.6rem">
            Select a file or drag and drop here
          </Text>
          <Text fontSize="1.2rem" lineHeight="1.4rem" opacity="0.4">
            File size no more than 3MB
          </Text>
        </Stack>
      </Flex>

      <Button
        h="3.6rem"
        w="7.2rem"
        border="1px solid #3E7EEE"
        fontSize="1.2rem"
        backgroundColor="#F8F9FB"
        mt="1.6rem"
        _hover={{ boxShadow: 'none' }}>
        ADD FILE
      </Button>
    </Flex>
  );
}

function DropdownOptions({ options = [], attrs }) {
  const optionList = options.map((option) => ({ value: option, label: option }));
  return <BaseDropdown isDisabled options={optionList} {...attrs} h="4.2rem" w="100%" />;
}
