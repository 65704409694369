import styled from '@emotion/styled';

export const Container = styled.div`
  width: 700px;

  @media only screen and (max-width: 1100px) {
    width: 500px;
  }
`;

export const Heading = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 16px;
  background: #f3f4f6;
  border: 1px solid #f2f2f2;
  border-radius: 8px 8px 0px 0px;

  .heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.117188px;
    color: #333758;
    text-transform: capitalize;
  }

  .buttons {
    img {
      width: 18px;
    }
  }
`;
export const Fields = styled.div`
  overflow-x: auto;
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const ConfigureCards = styled.div`
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Card = styled.div`
  // overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border: 1px solid #3e7eee;
  border-radius: 4px;

  .image {
    width: 16px;
  }

  @media only screen and (min-width: 1100px) {
    .box {
      width: 506px;
    }
  }
  @media only screen and (max-width: 1100px) {
    .box {
      width: calc(506px / 2);
    }
  }
`;
export const OptionCards = styled.div`
  // overflow-x: scroll;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  input {
    outline: none;
    background: transparent;
  }

  .add-score {
    background: #fafcff;
    width: 50px;
    border: 1px solid #f2f2f2;
    padding: 8px 4px;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;
  padding: 8px;
  background: #fafcff;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  cursor: pointer;

  .star,
  .button {
    width: 12px;
  }

  .option-text {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.117188px;
    color: #23273b;
  }
`;

export const addButton = {
  width: 'fit-content',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '-0.117188px',
  color: '#3E7EEE',
};

export const inputStyle = {
  background: '#fafcff',
  fontWeight: 600,
  fontSize: '14px',
  color: ' #333758',
};

export const buttonStyle = {
  flexDirection: 'column',
  gap: '0px',
  fontWeight: 400,
  fontSize: '10px',
};

export const saveButton = {
  background: '#23273B',
  borderRadius: '4px',
  fontSize: '12px',
  fontWeight: 600,
  color: '#ffffff',
  height: '32px',
  padding: '0px 16px',
};

export const cancelButton = {
  fontSize: '12px',
  fontWeight: 600,
  color: '#999FA3',
};
