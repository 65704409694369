import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Box, Grid, Img } from '@chakra-ui/react';

import { BaseDropdown, CustomInput } from '../../../../components';
import { useAssessmentForm } from '../../../../context/assessmentForm';
import { useQuestionNumber } from '../../../../hooks';

import { OptionsInputRoot } from './styles';

const ApplicantQuestions = (props) => {
  const { control } = useAssessmentForm();
  const { sectionName, sectionIndex } = props;

  const { fields: questions, update } = useFieldArray({
    name: `${sectionName}.questions`,
    control,
  });

  return (
    <Grid gap={'36px'} p={'0 28px'}>
      {questions?.map((question, index) => {
        return (
          <Question
            key={question._id || question.question + index}
            question={question}
            index={index}
            sectionName={sectionName}
            sectionIndex={sectionIndex}
            update={update}
          />
        );
      })}
    </Grid>
  );
};

function Question(props) {
  const { question, index, update, sectionIndex, sectionName } = props;
  const { watch } = useAssessmentForm();
  const { questionNumber } = useQuestionNumber(watch, sectionIndex, index);
  const questionName = `${sectionName}.questions.${index}`;
  const imageUrl = question.image ? question.image.url : question.imageUrl;
  return (
    <article className="question">
      <h2 className="question__number">
        Q{questionNumber}. {question?.question}
      </h2>
      {imageUrl ? (
        <Box mt="1.6rem">
          <Img src={imageUrl} alt={`Question ${index} image`} />
        </Box>
      ) : null}
      <Answer question={question} questionName={questionName} update={update} index={index} />
    </article>
  );
}

function Answer({ question, questionName, update, index }) {
  const { watch } = useAssessmentForm();

  // questionType booleans
  const isShortText = (question.questionType || question.type) === 'short';
  const isLongText = (question.questionType || question.type) === 'long';
  const isMultiple = (question.questionType || question.type) === 'radio';
  const isCheckbox = (question.questionType || question.type) === 'multipleChoice';
  const isDropdown = (question.questionType || question.type) === 'dropdown';

  const inputName = `${questionName}.userAnswer`;
  const answer = watch(inputName) ?? '';
  const dropdownOptions = isDropdown ? question.options.map(optionToLabelValue) : [];
  const dropdownValue = { label: answer || 'Select answer...', value: answer };

  // props
  const textInputProps = {
    placeholder: 'Your answer',
    underline: true,
    // ...register(inputName)
  };
  const longTextInputProps = {
    ...textInputProps,
    height: 'auto',
    type: 'textarea',
    rows: 4,
    // ...register(inputName),
  };

  function handleDropdownChange(value) {
    const oldQuestion = watch(questionName);
    update(index, { ...oldQuestion, userAnswer: value.value });
  }

  return (
    <div className="answer">
      {isShortText && <CustomInput {...textInputProps} />}
      {isLongText && <CustomInput {...longTextInputProps} />}
      {isMultiple && <Options inputName={inputName} options={question.options} />}
      {isCheckbox && <Options inputName={inputName} options={question.options} checkbox />}
      {isDropdown && (
        <BaseDropdown
          h="4.8rem"
          placeholder="Select answer..."
          value={dropdownValue}
          onChange={handleDropdownChange}
          options={dropdownOptions}
        />
      )}
    </div>
  );
}

function Options({ inputName, options, checkbox }) {
  const { register } = useAssessmentForm();
  const type = checkbox ? 'checkbox' : 'radio';

  return (
    <OptionsInputRoot>
      {options.map((optionObj) => {
        const option = typeof optionObj === 'string' ? optionObj : optionObj.option;
        const id = `${inputName}.${option}`;
        return (
          <div className="option" key={id}>
            <input type={type} id={id} {...register(inputName)} value={option} />
            <label htmlFor={id}>{option}</label>
          </div>
        );
      })}
    </OptionsInputRoot>
  );
}

function optionToLabelValue({ option }) {
  return { label: option, value: option };
}

export { ApplicantQuestions };
