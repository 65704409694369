import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Image,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { industryArray, range } from 'data/dropDownOptions';

import { CustomButton } from 'components';
import { BaseDropdown } from 'components';

import plusIcon from '../../../../../assets/icons/plusIcon.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';

import { ExtraFields } from './ExtraFields';
import { PhotoUpload } from './PhotoUpload';

export const CompanyDetailsForm = ({
  register,
  control,
  states,
  cities,
  countries,
  imageFile,
  setImageFile,
  logoUrl,
  fields,
  cancel,
  disableBtn,
  loading,
  append,
  remove,
}) => {
  return (
    <>
      <FormControl>
        <FormLabel
          lineHeight="1.3rem"
          fontSize="1.4rem"
          color="#23273B"
          letterSpacing="-0.02rem"
          mb="1.2rem"
          fontWeight="400">
          Business Name
        </FormLabel>
        <Input
          {...register('companyName')}
          fontSize="1.4rem"
          height="4.8rem"
          backgroundColor="#F7F9FB"
          placeholder="Enter Business Name"
        />
      </FormControl>
      <PhotoUpload imageFile={imageFile} setImageFile={setImageFile} logoUrl={logoUrl} />
      <SimpleGrid columns={2} gap="1.6rem" mt="4rem">
        <GridItem colSpan={1} mb="1.4rem">
          <FormControl>
            <FormLabel
              lineHeight="1.3rem"
              fontSize="1.4rem"
              color="#23273B"
              letterSpacing="-0.02rem"
              mb="1.2rem"
              fontWeight="400">
              Company Email
            </FormLabel>
            <Input
              {...register('companyEmail')}
              type="email"
              fontSize="1.4rem"
              height="4.8rem"
              placeholder="Enter Company Email"
              backgroundColor="#F7F9FB"
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel
              lineHeight="1.3rem"
              letterSpacing="-0.02rem"
              fontSize="1.4rem"
              mb="1.2rem"
              fontWeight="400">
              Company Size
            </FormLabel>
            <Controller
              defaultValue=""
              control={control}
              name="companySize"
              render={({ field }) => (
                <BaseDropdown
                  {...field}
                  w="322"
                  labelSize="1.4rem"
                  border="1px solid #E0E0E0"
                  placeholder="Select a range"
                  options={range}
                />
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel
              lineHeight="1.3rem"
              letterSpacing="-0.02rem"
              fontSize="1.4rem"
              mb="1.2rem"
              fontWeight="400">
              Select Industry
            </FormLabel>
            <Controller
              defaultValue=""
              control={control}
              name="industry"
              render={({ field }) => (
                <BaseDropdown
                  {...field}
                  w="322"
                  labelSize="1.4rem"
                  border="1px solid #E0E0E0"
                  placeholder="Select industry"
                  options={industryArray}
                />
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <Controller
              defaultValue=""
              control={control}
              name="country"
              render={({ field }) => (
                <BaseDropdown
                  {...field}
                  w="322"
                  labelSize="1.4rem"
                  label="Select Country"
                  border="1px solid #E0E0E0"
                  placeholder="Select a country"
                  options={countries}
                />
              )}
            />
          </FormControl>
        </GridItem>
      </SimpleGrid>

      <Stack mt="4rem">
        <FormControl>
          <FormLabel
            lineHeight="1.3rem"
            fontSize="1.4rem"
            color="#23273B"
            letterSpacing="-0.02rem"
            mb="1.2rem"
            fontWeight="400">
            EmployeeID Prefix
          </FormLabel>
          <Input
            {...register('idPrefix')}
            fontSize="1.4rem"
            height="4.8rem"
            backgroundColor="#F7F9FB"
            placeholder="Enter EmployeeID Prefix"
          />
        </FormControl>
      </Stack>

      <SimpleGrid columns={2} gap="1.6rem" mt="5.5rem">
        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel
              lineHeight="1.3rem"
              fontSize="1.4rem"
              color="#23273B"
              letterSpacing="-0.02rem"
              mb="1.2rem"
              fontWeight="400">
              Head quarters address
            </FormLabel>
            <Input
              {...register('hqLocation.address')}
              type="text"
              fontSize="1.4rem"
              height="4.8rem"
              backgroundColor="#F7F9FB"
              placeholder="Enter Address"
            />
          </FormControl>
        </GridItem>
        {/* <GridItem colSpan={1}>
          <FormControl>
            <Controller
              defaultValue=""
              control={control}
              name="hqLocation.country"
              render={({ field }) => (
                <BaseDropdown
                  {...field}
                  w="322"
                  labelSize="1.4rem"
                  label="Select Country"
                  border="1px solid #E0E0E0"
                  placeholder="Select a country"
                  options={countries}
                />
              )}
            />
          </FormControl>
        </GridItem> */}
        <GridItem colSpan={1}>
          <Controller
            defaultValue=""
            control={control}
            name="hqLocation.state"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Select State"
                border="1px solid #E0E0E0"
                placeholder="Select a state"
                options={states}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Controller
            defaultValue=""
            control={control}
            name="hqLocation.city"
            render={({ field }) => (
              <BaseDropdown
                {...field}
                w="322"
                labelSize="1.4rem"
                label="Select City"
                border="1px solid #E0E0E0"
                placeholder="Select a city"
                options={cities}
              />
            )}
          />
        </GridItem>
      </SimpleGrid>
      <ExtraFields
        fields={fields}
        remove={remove}
        control={control}
        trashIcon={trashIcon}
        states={states}
        cities={cities}
        countries={countries}
        fieldName={'locations'}
        register={register}
      />

      <Box mt="3.3rem">
        <button
          type="button"
          onClick={() => {
            append(1);
          }}>
          <Flex alignItems="center">
            <Image src={plusIcon} height="1.5rem" />
            <Text ml="1rem" color="#36CA8B" fontSize="1.4rem" textDecoration="underline">
              Add Location
            </Text>
          </Flex>
        </button>
      </Box>

      <Flex justifyContent="flex-end" gap="2rem" mt="10rem">
        <CustomButton
          mW="13rem"
          h="4.8rem"
          onClick={cancel}
          variant="secondary"
          name="Cancel"
          type="button"
        />
        <CustomButton
          variant={'primary'}
          loading={loading}
          disabled={disableBtn}
          type="submit"
          h="4.8rem"
          mW="13rem"
          name="Save"
        />
      </Flex>
    </>
  );
};
