export function formatDate(date) {
  const dateLabel = new Date(date).toLocaleDateString('en-uk', {
    day: `2-digit`,
    month: 'short',
    year: 'numeric',
  });
  return dateLabel;
}

export function modifyData(data) {
  let arr = [];

  if (data !== undefined) {
    for (let item of data) {
      arr.push({ label: item?.name, value: item?.id });
    }
  }
  return arr;
}

export function modifyFilters(filter) {
  let obj = {};
  for (let key in filter) {
    if (filter[key] !== '') {
      obj[key] = filter[key];
    }
  }
  return obj;
}

export function getDepartments(share, data) {
  if (share === 'everyone') {
    return [];
  } else if (share === 'department') {
    return data;
  }
}
