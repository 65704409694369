import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { Status } from 'pages/admin/employee/tableConfig';
import { formatDateRange } from 'pages/admin/leave-management/Requests/helper';

import { ActionDropdown, NameWithImage, Pagination, TableComponent } from 'components';

import threedots from '../../../../../assets/icons/three-dots.svg';

import { columns } from './data';

export function LeaveApprovalTable({
  page,
  setPage,
  pageCount,
  toggleModal,
  loading,
  toggleRejectModal,
  onGetSingleLeaveRequest,
  tData = [],
  setLeaveId,
}) {
  const tableData = tData?.map(
    ({ _id, employee, startDate, endDate, leaveType, countBalance, leaveStatus }) => {
      return {
        id: _id,
        employeeName: (
          <NameWithImage
            data={{
              firstName: employee?.firstName,
              lastName: employee?.lastName,
              imageUrl: employee?.imageUrl,
            }}
          />
        ),
        leaveType: <Text textTransform={'capitalize'}>{leaveType?.name}</Text>,
        leaveDuration: formatDateRange({ startDate, endDate }),
        countBalance: countBalance || 'N/A',
        status: <Status statusChecked={leaveStatus} />,
        action: (
          <ActionDropdown
            actions={getActions(leaveStatus)}
            btn={threedots}
            onClick={performAction}
            id={_id}
          />
        ),
      };
    }
  );

  function performAction(action, id) {
    switch (action.value) {
      case 'view-request':
        onGetSingleLeaveRequest(id);
        toggleModal();
        break;

      case 'reject-request':
        setLeaveId(id);
        toggleRejectModal();
        break;

      default:
        break;
    }
  }

  function getActions(action) {
    const dropDownForTable =
      action === 'pending'
        ? [
            {
              label: 'View Request',
              value: 'view-request',
            },

            { label: 'Reject Request', value: 'reject-request' },
          ]
        : [
            {
              label: 'View Request',
              value: 'view-request',
            },
          ];

    return dropDownForTable;
  }

  return (
    <Stack overflow={'auto'} gap={'16px'}>
      <>
        <Stack overflow={'auto'}>
          <TableComponent
            columns={columns}
            noSort
            noAction
            noCheckbox
            loading={loading}
            data={tableData}
            actionOnData={performAction}
            empty={tData?.length === 0}
            ids={tData?.map((item) => item._id)}
          />
        </Stack>

        {tableData.length > 0 && (
          <Pagination page={page} setPage={setPage} totalPages={pageCount || 1} />
        )}
      </>
    </Stack>
  );
}
