import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, HStack, Text } from '@chakra-ui/react';

import { SpinnerComponent } from 'components';

import plus from '../../../../../assets/icons/plusBlue.svg';
import { FETCH_APPROVAL_FLOW } from '../../../../../query/admin/leave-management';

import { CustomButton } from './Buttons';
import { Container } from './Container';
import SettingsTable from './SettingsTable';
const assignApprovalFlowDropdownActions = ['Edit Flow', 'Assign Flow', 'Delete Flow'];

export function ApprovalFlow({ setModalType, toggleEditModal, setApprovalId, setLeavePolicies }) {
  const { loading, data } = useQuery(FETCH_APPROVAL_FLOW, {
    variables: {
      filter: {},
    },
  });

  return (
    <Container>
      <HStack justify={'space-between'}>
        <Text color={'#23273B'} fontWeight={'bold'}>
          Approval Flows
        </Text>

        <CustomButton
          variant={'tertiary'}
          name={'Create Approval Flow'}
          mW={'19.2rem'}
          src={plus}
          h={'4rem'}
          fS={'1.4rem'}
          fW={'400'}
          testId={'createApprovalFlowBtn'}
          onClick={() => setModalType({ showModal: true, contentType: 'createApproval' })}
        />
      </HStack>

      <HStack fontSize={'1.4rem'} color={'#83889D'} padding={'0 2.4rem'}>
        <Text w={'50%'}>Name</Text>
        <Text w={'50%'}>Leave Policies</Text>
      </HStack>

      <Box
        border={'solid 1px #ebebeb'}
        borderRadius={'0.4rem'}
        paddingBottom={'2.4rem'}
        overflow={'scroll'}>
        {loading ? (
          <SpinnerComponent />
        ) : (
          <SettingsTable
            data={data.fetchApprovalFlow.data}
            dropdownActions={assignApprovalFlowDropdownActions}
            setModalType={setModalType}
            toggleEditModal={toggleEditModal}
            setApprovalId={setApprovalId}
            setLeavePolicies={setLeavePolicies}
          />
        )}
      </Box>
    </Container>
  );
}
