import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@chakra-ui/react';
import { getItem } from 'utils';

import { EmptyState, SpinnerComponent } from '../../../components';
import { StartAssessment } from '../applicant-assessment';
import { JobsBoard } from '../components/jobBoard';
import { PositionAppliedCard } from '../components/positionAppliedCard';

import useApplicantDashboardView from './hooks';
import { Container, Tabs } from './styles';

const ApplicantDashboardView = () => {
  const location = useLocation();
  const { jobDetails, loading } = useApplicantDashboardView();

  const user = getItem('hrm_user');
  const stripped = location.hash.replace('#', '');

  if (loading) {
    return (
      <Grid h={'100vh'} alignItems={'center'}>
        <SpinnerComponent />
      </Grid>
    );
  }

  return (
    <Container>
      <Tabs>
        {stripped === 'assessments' &&
          jobDetails &&
          jobDetails?.map((job) => (
            <>
              {stripped === 'assessments' && !job.jobId.assessmentId ? (
                <EmptyState h="20rem" message="No current assessment available" />
              ) : (
                <JobsBoard
                  user={user}
                  title={`Assessments For Positions You've Applied To Will Show Here`}
                  subtitle={`Hello ${
                    user?.fullName?.split(' ')[0]
                  }. Welcome to your assessment board, complete your pending assessments`}>
                  {jobDetails &&
                    jobDetails?.map((job, index) => (
                      <StartAssessment key={index} jobDetails={job} />
                    ))}
                </JobsBoard>
              )}
            </>
          ))}
        {stripped === 'appliedJobs' && (
          <JobsBoard
            user={user}
            title={`Positions You've Applied To Show Up Here`}
            subtitle={`Hello ${
              user?.fullName?.split(' ')[0]
            }. Welcome to your job board workspace`}>
            {jobDetails &&
              jobDetails?.map((job, index) => <PositionAppliedCard key={index} jobDetails={job} />)}
          </JobsBoard>
        )}
      </Tabs>
    </Container>
  );
};

export { ApplicantDashboardView };
