import { gql } from '@apollo/client';

export const ASSET_REPORT = gql`
  query fetchAssets($filter: AssetFilters!, $page: Int) {
    fetchAssets(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      data {
        _id
        name
        dateAssigned
        cost
        notes
        category
        serialNumber
        assignedTo {
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;

export const FETCH_AGE_REPORT = gql`
  query retrieveAgeReport {
    retrieveAgeReport {
      status
      message
      data {
        report {
          id
          age
        }
        count
      }
    }
  }
`;

export const RETRIEVE_HEAD_COUNT = gql`
  query retrieveHeadCount {
    retrieveHeadCount {
      status
      message
      data {
        report {
          id
          name
          year
          month
        }
        count
      }
    }
  }
`;

export const FETCH_EXIT_HIRING_REPORT = gql`
  query retrieveExitAndHiringReport($filters: FilterInputData) {
    retrieveExitAndHiringReport(filters: $filters) {
      status
      message
      data {
        report {
          id
          status
          createdAt
          year
          month
        }
        count
      }
    }
  }
`;

export const FETCH_DEPARTMENT_REPORT = gql`
  query retrieveDepartmentReport {
    retrieveDepartmentReport {
      status
      message
      data {
        report {
          id
          department
        }
        count
      }
    }
  }
`;

export const INDIVIDUAL_TRAINING_REPORT = gql`
  query fetchTraining($filter: TrainingFilters!, $page: Int) {
    fetchTraining(filter: $filter, page: $page) {
      status
      message
      data {
        name
        cost
        results
        isShared
        dueDate
        status
        description
        attachedFile
        details
        employees {
          firstName
          lastName
          imageUrl
        }
        employee {
          firstName
          lastName
        }
        repeatProgram {
          isRepeated
        }
        schedule {
          startDate
          endDate
        }
      }
    }
  }
`;

export const SHARED_TRAINING_REPORT = gql`
  query fetchTraining($filter: TrainingFilters!, $page: Int) {
    fetchTraining(filter: $filter, page: $page) {
      status
      message
      data {
        name
        cost
        results
        isShared
        dueDate
        status
        description
        attachedFile
        details
        employees {
          firstName
          lastName
          imageUrl
        }
        employee {
          firstName
          lastName
        }
        repeatProgram {
          isRepeated
        }
        schedule {
          startDate
          endDate
        }
      }
    }
  }
`;
