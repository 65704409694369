import { useFormState } from 'react-hook-form';

import { useJobDetailsForm } from '../../../../context';
import { inputError } from '../../../../utils';
import { CustomInput } from '../../../shared';
import { JobInputLabel } from '../JobInputLabel';

function JobTitleInput() {
  const { register, control } = useJobDetailsForm();
  const path = 'title';
  const formState = useFormState({ control });
  return (
    <>
      <JobInputLabel>Job Title</JobInputLabel>
      <CustomInput {...register(path)} placeholder="UI / UX Designer" />
      {inputError(formState.errors, path)}
    </>
  );
}

export { JobTitleInput };
