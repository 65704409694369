import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_LINE_MANAGER_LEAVE_REQUESTS } from 'query';
import { getGraphQLResponse } from 'utils';

export const useLeaveApprovals = () => {
  const [searchString, setSearchString] = React.useState('');

  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState({
    query: '',
    status: '',
  });

  const { data, loading, refetch } = useQuery(FETCH_LINE_MANAGER_LEAVE_REQUESTS, {
    variables: {
      keyword: searchString,
      status: filter.status,
      page,
    },
  });

  const leaveRequestResponse = React.useMemo(() => getGraphQLResponse(data), [data]);

  return {
    page,
    filter,
    loading,
    setFilter,
    setPage,
    refetch,
    searchString,
    setSearchString,
    leaveRequests: leaveRequestResponse?.data,
    pageCount: leaveRequestResponse?.pageCount,
  };
};
