import { format, parseISO } from 'date-fns';

export const formatDateRange = ({ startDate, endDate }) => {
  const sDate = format(parseISO(startDate), 'LLL dd');
  const eDate = format(parseISO(endDate), 'dd MMMM, yyyy');
  const dateRange = `${sDate} - ${eDate}`;
  return dateRange;
};

export const durationDisplay = ({ count, duration }) => {
  if (count === 1 && duration === 'weeks') {
    const duration = '1 week';
    return duration;
  }
  const notFormated = count + ' ' + duration;
  return notFormated;
};

export const formatD = (value) => {
  if (value) {
    return format(parseISO(new Date(value).toISOString()), 'yyyy-MM-dd');
  }
  return value;
};
export const formatDayOfTheMonth = (value) => {
  if (value) {
    return format(parseISO(new Date(value).toISOString()), 'do MMMM, yyyy');
  }
  return value;
};
