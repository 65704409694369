import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ApplicantAuth } from 'layouts/ApplicantAuth';

import { NavBar } from '../components';
import {
  ApplicantAssessment,
  ApplicantAssessmentHome,
  ApplicantChangePassword,
  ApplicantDashboardView,
  ApplicantResetPassword,
  EditJobDetails,
  ForgotPassword,
  RoutingMessage,
  SignIn,
  SignUp,
  StartAssessment,
} from '../pages';
import { getItem } from '../utils';

import { ApplicantPrivateRoute } from './ApplicantPrivateRoute';

const ApplicantRoutes = () => {
  return (
    <Box>
      <Routes>
        <Route
          path="/*"
          element={
            <AuthRoute>
              <ApplicantAuth />
            </AuthRoute>
          }>
          <Route path="" element={<RoutingMessage />} />
          <Route path="login" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ApplicantResetPassword />} />
        </Route>
        <Route
          path="/*"
          element={
            <ApplicantPrivateRoute>
              <NavBar isDashboard />
            </ApplicantPrivateRoute>
          }>
          <Route path="change-password" element={<ApplicantChangePassword />} />
          <Route path="assessment" element={<StartAssessment />} />
          <Route path="dashboard" element={<ApplicantDashboardView />} />
          <Route path="assessment/:assessmentId/start" element={<ApplicantAssessment />} />
          <Route path="assessment/:assessmentId" element={<ApplicantAssessmentHome />} />
        </Route>

        <Route
          path="/:jobId"
          element={
            <ApplicantPrivateRoute>
              <NavBar />
              <EditJobDetails />
            </ApplicantPrivateRoute>
          }
        />
      </Routes>
    </Box>
  );
};

function AuthRoute({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const user = getItem('hrm_user');
    if (user?.isApplicant) {
      navigate(`/applicant/dashboard`);
    }
  }, []);

  return <>{children}</>;
}

export { ApplicantRoutes };
