import { useForm } from 'react-hook-form';
import { CgSpinner } from 'react-icons/cg';
import { IoMdCloseCircle } from 'react-icons/io';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { useHandleCreateJob, useJobDetailsForm } from 'context';
import { UPLOAD_FILE } from 'query';

import { TinyImageIcon } from 'components/shared/Svgs';

import { useEditField } from '../hooks';

import { FieldWrapper } from './FieldWrapper';

function HeaderImage() {
  const { watch, setValue } = useJobDetailsForm();
  const file = watch('headerImage');
  const {
    handleSubmit,
    reset,
    setValue: localSetValue,
    watch: localWatch,
  } = useForm({ defaultValues: { file } });
  const { onEditJob } = useHandleCreateJob();

  const localFile = localWatch('file');

  function onSave(data) {
    setValue('headerImage', data.file);
    onEditJob();
  }

  const editOptions = useEditField({
    onSave: handleSubmit(onSave),
    reset,
    defaults: { file },
  });

  const { isEditing } = editOptions;

  const [uploadImage, { loading }] = useMutation(UPLOAD_FILE);

  function setFile(val) {
    localSetValue('file', val);
  }

  function onChange(event) {
    const uploadedFile = event.target.files[0];
    // setFile(URL.createObjectURL(uploadedFile));
    if (uploadedFile) {
      uploadImage({ variables: { file: uploadedFile } }).then((res) =>
        setFile(res.data?.uploadFile?.data)
      );
    }
  }

  function clearFile(event) {
    event.stopPropagation();
    event.preventDefault();
    setFile(null);
  }

  const showFile = (!isEditing && file) || (isEditing && localFile);

  return (
    <FieldWrapper
      className="header-image"
      label="Header"
      editable
      editOptions={editOptions}
      mt="1rem">
      {/* <JobInputLabel>Header</JobInputLabel> */}
      <InputLabel
        htmlFor="header-image"
        className={classNames({ 'has-image': localFile, isEditing })}>
        {isEditing && !localFile ? (
          <>
            {loading ? (
              <div className="icon loading">
                <CgSpinner size={20} />
              </div>
            ) : null}
            {!loading ? TinyImageIcon : null}
            Add Header Image
            <input
              type="file"
              name="header-image"
              data-testid="header-image"
              id="header-image"
              onChange={onChange}
              accept=".png, .jpeg, .jpg, .svg"
            />
          </>
        ) : null}
        {showFile && (
          <>
            {isEditing ? (
              <div className="close-btn" onClick={clearFile} title="remove file">
                <IoMdCloseCircle size={28} />
              </div>
            ) : null}
            <img src={localFile || file} alt="" />
          </>
        )}
        {!isEditing && !file ? 'No Header Image' : null}
      </InputLabel>
    </FieldWrapper>
  );
}

const InputLabel = styled.label`
  display: grid;
  grid: 1fr / repeat(2, max-content);
  align-items: center;
  gap: 8px;
  margin-top: 1.4rem;
  padding: 4px 0 0;
  border-radius: 4px 4px 0 0;
  color: var(--grey-text);
  font-size: 1.4rem;
  cursor: pointer;

  &.has-image {
    grid: 1fr / 1fr;
    padding: 1rem 0;
    pointer-events: none;
    position: relative;
    padding-top: 40%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left center;
    }

    .close-btn {
      position: absolute;
      z-index: 10;
      top: 1.6rem;
      right: 1.6rem;
      width: max-content;
      border-radius: 50%;
      padding: 0;
      color: white;
      background-color: #7f7f7f;
      pointer-events: auto;
      transition: background-color ease 300ms;

      &:hover {
        background-color: #e74c3c;
      }
    }
  }

  &.isEditing:not(.has-image) {
    padding: 0.4rem 1rem;
  }

  &.isEditing:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &.isEditing:active {
    background: rgba(0, 0, 0, 0.04);
  }

  input {
    display: none;
  }

  img {
    width: 100%;
  }

  .icon.loading {
    animation: spin 500ms linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { HeaderImage };
