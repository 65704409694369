export const Columns = [
  {
    Header: 'Title',
    accessor: 'col1',
  },
  {
    Header: 'Creator',
    accessor: 'col2',
  },
  {
    Header: 'Type',
    accessor: 'col3',
  },
  {
    Header: 'Due Date',
    accessor: 'col4',
  },
  {
    Header: 'Status',
    accessor: 'col5',
  },
];

// export const MockData = [
//   {
//     id: '1',
//     title: 'Roll out HRMs Performance Module',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Per Person',
//     dueDate: '01 March, 2019',
//     status: 'upcoming',
//   },
//   {
//     id: '2',
//     title: 'Book 20 Demos',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Shared',
//     dueDate: '01 March, 2019',
//     status: 'upcoming',
//   },
//   {
//     id: '3',
//     title: 'Improve technical competencies',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Individual',
//     dueDate: '01 March, 2019',
//     status: 'in progress',
//   },
//   {
//     id: '4',
//     title: '01 March, 2019 - 01 March, 2019',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Individual',
//     dueDate: '01 March, 2019',
//     status: 'completed',
//   },
//   {
//     id: '5',
//     title: '01 March, 2019 - 01 March, 2019',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Individual',
//     dueDate: '01 March, 2019',
//     status: 'in progress',
//   },
//   {
//     id: '6',
//     title: '01 March, 2019 - 01 March, 2019',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Individual',
//     dueDate: '01 March, 2019',
//     status: 'completed',
//   },
//   {
//     id: '7',
//     title: '01 March, 2019 - 01 March, 2019',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Individual',
//     dueDate: '01 March, 2019',
//     status: 'in progress',
//   },
//   {
//     id: '8',
//     title: '01 March, 2019 - 01 March, 2019',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Individual',
//     dueDate: '01 March, 2019',
//     status: 'overdue',
//   },
//   {
//     id: '9',
//     title: '01 March, 2019 - 01 March, 2019',
//     creator: { firstName: 'Isaac', lastName: 'Anane', imageUrl: null },
//     type: 'Individual',
//     dueDate: '01 March, 2019',
//     status: 'overdue',
//   },
// ];
