import { gql } from '@apollo/client';

export const FETCH_ANNOUNCEMENT = gql`
  query fetchAnnouncement($filter: AnnouncementFilters, $page: Int) {
    fetchAnnouncement(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        type
        title
        content
        issuedBy {
          _id
          imageUrl
          firstName
          lastName
        }
        isImportant
        status
        createdAt
      }
    }
  }
`;
export const FETCH_SINGLE_ANNOUNCEMENT = gql`
  query fetchSingleAnnouncement($announcementId: ObjectID!) {
    fetchSingleAnnouncement(announcementId: $announcementId) {
      status
      message
      data {
        _id
        type
        title
        content
        issuedBy {
          _id
          imageUrl
        }
        isImportant
        status
      }
    }
  }
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation createAnnouncement($data: AnnouncementInput) {
    createAnnouncement(data: $data) {
      status
      message
      data {
        _id
        type
        title
        content
        issuedBy {
          _id
          imageUrl
        }
        isImportant
        status
      }
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($announcementId: ObjectID!) {
    deleteAnnouncement(announcementId: $announcementId) {
      status
      message
      data {
        _id
        type
        title
        content
        issuedBy {
          _id
          imageUrl
        }
        isImportant
      }
    }
  }
`;
export const EDIT_ANNOUNCEMENT = gql`
  mutation editAnnouncement($announcementId: ObjectID!, $data: EditAnnouncementInput) {
    editAnnouncement(announcementId: $announcementId, data: $data) {
      status
      message
      data {
        _id
        type
        title
        content
        issuedBy {
          _id
          imageUrl
        }
        isImportant
        status
      }
    }
  }
`;
