import React from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { BaseButton, BaseDropdown, CustomInput, ModalBackButton } from '../../../components';
import { useToastify } from '../../../hooks/useToastify';
import { ADD_ASSET, FETCH_ALL_ASSETS, FETCH_ALL_CATEGORIES } from '../../../query';
import { InputLabel } from '../performance/reviews/CreateReview/components/InputLabel';

import { useEmployeeOptions } from './AssetManagement.hooks';

const isTesting = process.env.NODE_ENV === 'test';

const defaultValues = isTesting
  ? {
      name: 'Macbook Pro 2015',
      category: { value: 'Laptop', label: 'Laptop' },
      serialNumber: '',
      notes: '',
      cost: '',
      assignedTo: '',
      dateAssigned: '',
    }
  : {};

const addFormSchema = yup.object().shape({
  name: yup.string().required(),
  category: yup.object().required(),
});
export function AddAsset() {
  const navigate = useNavigate();
  const methods = useForm({ defaultValues, resolver: yupResolver(addFormSchema) });
  const { successToast, errorToast } = useToastify();
  window.f = methods.formState;

  const [addAsset, { loading }] = useMutation(ADD_ASSET);

  function onSubmit(values) {
    const ready = Object.entries(values).reduce((accum, [key, value]) => {
      if (value) {
        if (['assignedTo', 'category'].includes(key)) accum[key] = value.value;
        else if (key === 'dateAssigned') accum[key] = new Date(value);
        else if (key === 'cost') accum[key] = parseInt(value);
        else accum[key] = value;
      }
      return accum;
    }, {});
    addAsset({
      variables: { data: ready },
      refetchQueries: [
        { query: FETCH_ALL_ASSETS, variables: { page: 1, filter: {} } },
        { query: FETCH_ALL_CATEGORIES, variables: { page: 1, filter: {} } },
      ],
      awaitRefetchQueries: true,
    })
      .then((res) => {
        if (res.data.createAsset.status === 201) {
          successToast('Asset added successfully');
          navigate('/admin/asset');
        } else {
          throw new Error(res.data.createAsset.message);
        }
      })
      .catch((err) => {
        errorToast(err.message);
      });
  }

  return (
    <PageRoot>
      <Header>
        <ModalBackButton onClick={() => navigate('/admin/asset')} />
        <h1>Add an Asset</h1>
      </Header>
      <Grid templateColumns={{ sm: '1fr', lg: '74% auto' }} gap={'24px'} my={'24px'}>
        <GridItem>
          <Card>
            <FormProvider {...methods}>
              <FormBody />
            </FormProvider>
          </Card>
          <Grid
            templateColumns={{ sm: '1fr', md: 'repeat(2, max-content)' }}
            gap={'12px'}
            justifyContent={'flex-end'}
            mt={'24px'}>
            <BaseButton onClick={() => navigate('/admin/asset')} h={'5.6rem'} variant="outline">
              Cancel
            </BaseButton>
            <BaseButton isLoading={loading} h={'5.6rem'} onClick={methods.handleSubmit(onSubmit)}>
              Submit
            </BaseButton>
          </Grid>
        </GridItem>

        <GridItem>
          <Card className="asset-tracking">
            <h3>Asset Tracking</h3>
            <p>
              Record a name, category, serial number or any additional note and assign it to an
              employee.
            </p>
          </Card>
        </GridItem>
      </Grid>
    </PageRoot>
  );
}

function FormBody() {
  const [categories, setCategories] = React.useState([]);
  const { register, control, setValue } = useFormContext();

  const filter = {};
  const { data } = useQuery(FETCH_ALL_CATEGORIES, {
    variables: {
      page: 1,
      filter,
    },
  });

  function onCreateOption(value) {
    setValue('category', { value: value, label: value });
    setCategories([...categories, { value: value, label: value }]);
  }

  React.useEffect(() => {
    setCategories(data?.fetchAllCategories?.data.map((c) => ({ value: c._id, label: c._id })));
  }, [data]);

  const { loadOptions, options } = useEmployeeOptions();

  return (
    <Grid templateColumns="1fr" gap="24px" maxW="332px">
      <Box>
        <InputLabel required>
          Asset Name<span className="req">*</span>
        </InputLabel>
        <CustomInput withBorder placeholder="Eg: Macbook Pro M2" {...register('name')} />
      </Box>

      <Box>
        <InputLabel required>
          Asset Category<span className="req">*</span>
        </InputLabel>
        <Controller
          control={control}
          name="category"
          render={({ field }) => (
            <BaseDropdown
              {...field}
              w="100%"
              creatable
              onCreateOption={onCreateOption}
              options={categories}
            />
          )}
        />
      </Box>

      <Box>
        <InputLabel required>Serial Number</InputLabel>
        <CustomInput withBorder placeholder="Eg: SDFGHJ8765FGH" {...register('serialNumber')} />
      </Box>

      <Box>
        <InputLabel required>Notes</InputLabel>
        <CustomInput withBorder placeholder="" {...register('notes')} />
      </Box>

      <Box>
        <InputLabel required>Cost</InputLabel>
        <CustomInput type="number" withBorder placeholder="#30000" {...register('cost')} />
      </Box>

      <Box>
        <InputLabel required>Assigned To</InputLabel>
        <Controller
          control={control}
          name="assignedTo"
          render={({ field }) => (
            <BaseDropdown
              {...field}
              w="100%"
              async
              defaultOptions={options}
              loadOptions={loadOptions}
            />
          )}
        />{' '}
      </Box>

      <Box>
        <InputLabel required>
          Date Assigned<span className="req">*</span>
        </InputLabel>
        <CustomInput withBorder placeholder="#30000" {...register('dateAssigned')} type={'date'} />
      </Box>
    </Grid>
  );
}

const PageRoot = styled.div`
  height: calc(100% - 108px);
  padding: 0 24px;
  margin-top: 24px;

  .req {
    color: var(--error);
  }
`;

const Header = styled.header`
  display: grid;
  grid: 1fr / auto-flow max-content;
  align-items: center;
  gap: 24px;
  padding: 24px;
  background: var(--white);
  border: 1px solid var(--grey-150);

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: -0.03em;
  }
`;

const Card = styled.section`
  border: 1px solid var(--grey-150);
  padding: 24px;
  background: var(--white);

  &.asset-tracking {
    position: sticky;
    top: 84px;
    h3 {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 8px;
    }

    p {
      font-size: 1.4rem;
    }
  }
`;
