import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Flex, Image } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';
import { DELETE_TRAINING, FETCH_TRAINING, INDIVIDUAL_TRAINING } from 'query';

import { Status } from 'components/tables/TableConfig';

const notStarted = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(240, 168, 0, 0.1)',
  border: '1px solid #F0A800',
  borderRadius: '20px',
  textTransform: 'capitalize',
};

const upcoming = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(153, 159, 163, 0.1)',
  border: '1px solid #999FA3',
  borderRadius: ' 20px',
  textTransform: 'capitalize',
};

const finished = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(54, 202, 139, 0.1)',
  border: '1px solid #36CA8B',
  borderRadius: ' 20px',
  textTransform: 'capitalize',
};

const keys = {
  Status: 'status',
};

function useTableView(selectedButton) {
  const dropdownActions = {
    edit: 'Edit Details',
    view: 'View Details',
    del: 'Delete',
  };

  const { successToast, errorToast } = useToastify();

  const [sortBy, setSortBy] = useState('');
  const [search, setSearch] = useState('');
  const [searchString, setSearchString] = useState('');
  const [tData, setTData] = useState([]);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [removeTraining, { loading: deleting }] = useMutation(DELETE_TRAINING);
  const [showViewTraining, setShowViewTraining] = useState(false);
  const [id, setId] = useState('');
  const [show, setShow] = useState(false);

  const filter = {
    isShared: selectedButton === 'shared training' ? true : false,
  };

  const {
    data: trainingDetails,
    loading,
    refetch,
  } = useQuery(selectedButton === 'shared training' ? FETCH_TRAINING : INDIVIDUAL_TRAINING, {
    variables: {
      filter: searchString ? { ...filter, keyword: searchString } : { ...filter },
      page: page,
    },
  });

  const fetchedData = trainingDetails?.fetchTraining?.data;
  const totalPages = trainingDetails?.fetchTraining?.pageCount || 1;

  useEffect(() => {
    setTData(fetchedData);
  }, [trainingDetails]);

  const InProgress = (trainingId) => {
    return (
      <div style={{ ...notStarted }}>
        <p>{trainingId?.status.substring(0, 2) + ' ' + trainingId?.status.substring(3)}</p>
      </div>
    );
  };

  const Upcoming = (trainingId) => {
    return (
      <div style={{ ...upcoming }}>
        <p>{trainingId?.status}</p>
      </div>
    );
  };

  const Finished = (trainingId) => {
    return (
      <div style={{ ...finished }}>
        <p>{trainingId?.status}</p>
      </div>
    );
  };

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];
    const sortedData = tData?.map((e) => {
      return {
        ...e,
        status: e?.status || '',
      };
    });
    if (key) {
      sortedData?.sort((a, b) => {
        return desc ? b[key]?.localeCompare(a[key]) : a[key]?.localeCompare(b[key]);
      });
      setTData(sortedData);
    }
  };

  const onEdit = (id) => {
    setId(id);
    setOpenModal(true);
  };

  const onView = (id) => {
    setId(id);
    setShowViewTraining(true);
  };

  const onDelete = async (id) => {
    try {
      const res = await removeTraining({
        variables: {
          trainingId: id,
        },
      });
      const { data } = res;
      if (data && data.removeTraining.status === 200) {
        successToast('Training deleted sucessfully');
        setShow(false);
        refetch();
      } else errorToast(data.removeTraining.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const actOnData = (payload) => {
    switch (payload?.action) {
      case 'edit-details':
        onEdit(payload?.id);
        break;
      case 'view-details':
        onView(payload?.id);
        break;
      case 'delete':
        setId(payload?.id);
        setShow(true);
        break;
    }
  };

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const information =
    selectedButton === 'shared training'
      ? tData?.map(({ name, repeatProgram, dueDate, status }) => {
          return {
            col1: <div style={{ color: '#83889D' }}>{name || '---'}</div>,
            col2: !repeatProgram?.repeatIn ? (
              '---'
            ) : (
              <>
                {repeatProgram?.repeatIn} {repeatProgram?.repeatType}
              </>
            ),
            col3: !dueDate ? '---' : <>{dueDate}</>,
            dueDate,
            col4:
              status === 'in_progress'
                ? InProgress({ status })
                : status === 'upcoming'
                ? Upcoming({ status })
                : status === 'finished'
                ? Finished({ status })
                : Status({ status }) || '---',
          };
        })
      : tData?.map(({ trainingId, cost, employee, status }) => {
          return {
            col1: <div style={{ color: '#83889D' }}>{trainingId?.name || '---'}</div>,
            col2:
              !employee?.firstName && !employee?.lastName ? (
                '---'
              ) : (
                <Flex alignItems={'center'} gap={'4px'}>
                  {employee?.imageUrl ? (
                    <Image src={employee?.imageUrl} width={'32px'} height={'32px'} />
                  ) : (
                    <Avatar
                      backgroundColor={'#3e7eee'}
                      name={employee?.firstName + ' ' + employee?.lastName}
                    />
                  )}
                  {employee?.firstName} {employee?.lastName}
                </Flex>
              ),
            col3: !cost ? '---' : <>{cost}</>,
            col4: !trainingId?.dueDate ? '---' : <>{trainingId?.dueDate}</>,
            col5:
              trainingId?.status === 'in_progress'
                ? InProgress(trainingId)
                : trainingId?.status === 'upcoming'
                ? Upcoming(trainingId)
                : trainingId?.status === 'completed'
                ? Finished(trainingId)
                : trainingId?.status === 'finished'
                ? Finished(trainingId)
                : '---' || Status({ status }),
          };
        });

  return {
    search,
    setPage,
    loading,
    setSearchString,
    handleSearchChange,
    tData,
    sortBy,
    deleting,
    onDelete,
    onEdit,
    information,
    handleMultiSortBy,
    totalPages,
    dropdownActions,
    page,
    openModal,
    setOpenModal,
    showViewTraining,
    id,
    refetch,
    setShowViewTraining,
    actOnData,
    show,
    setShow,
  };
}

export { useTableView };
