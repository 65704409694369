import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Heading, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_TIMESHEETS } from 'query';

import { SimpleModal } from '../../../components';

import { TimesheetPopup, TimesheetReport } from './components/Modals';
import { TimesheetTable } from './components';

export function Timesheet() {
  const [page, setPage] = React.useState(1);
  const [empPage, setEmpPage] = React.useState(1);
  const [query, setQuery] = React.useState('');
  const [searchString, setSearchString] = React.useState('');

  const [date, setDate] = React.useState({
    start: '',
    end: '',
  });
  const [employee, setEmployee] = React.useState({});
  const [empDate, setEmpDate] = React.useState({
    start: '',
    end: '',
  });
  const initialState = { showModal: false, contentType: '' };

  function simpleModalreducer(state, action) {
    return { ...state, ...action };
  }

  const [{ showModal, contentType }, setModalType] = React.useReducer(
    simpleModalreducer,
    () => initialState
  );

  const { data: timesheetsData, loading: loadingTimesheets } = useQuery(FETCH_TIMESHEETS, {
    variables: {
      filters: {
        searchString,
        date,
        userId: null,
      },
      page,
    },
  });

  const [getEmployeeData, { data: empTimesheetsData, loading: loadingEmpTimesheets }] =
    useLazyQuery(FETCH_TIMESHEETS);

  return (
    <>
      <Root>
        <Heading fontSize={'24px'}>TIMESHEET</Heading>

        <TimesheetTable
          query={query}
          setSearchString={setSearchString}
          page={page}
          date={date}
          setDate={setDate}
          setPage={setPage}
          setQuery={setQuery}
          setEmployee={setEmployee}
          setEmpDate={setEmpDate}
          loading={loadingTimesheets}
          tData={timesheetsData?.fetchTimesheets?.data}
          pageCount={timesheetsData?.fetchTimesheets?.pageCount}
          openModal={() => setModalType({ showModal: true, contentType: 'reportPopup' })}
        />
      </Root>
      <SimpleModal
        showModal={showModal}
        setShowModal={() => setModalType({ showModal: false, contentType: '' })}>
        {contentType === 'reportPopup' && (
          <TimesheetPopup
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
            openModal={() => setModalType({ showModal: true, contentType: 'timesheetreport' })}
            setEmpDate={setEmpDate}
            empDate={empDate}
            setDate={setDate}
            employee={employee}
            page={empPage}
            loading={loadingEmpTimesheets}
            handleSubmit={getEmployeeData}
          />
        )}
        {contentType === 'timesheetreport' && (
          <TimesheetReport
            closeModal={() => setModalType({ showModal: false, contentType: '' })}
            tData={empTimesheetsData?.fetchTimesheets?.data}
            pageCount={empTimesheetsData?.fetchTimesheets?.pageCount}
            page={empPage}
            employee={employee}
            date={date}
            setPage={setEmpPage}
            loading={loadingEmpTimesheets}
          />
        )}
      </SimpleModal>
    </>
  );
}

const Root = styled(Stack)`
  padding: 32px;
  gap: 32px;
`;
