import styled from '@emotion/styled';

export const AnnouncementWrapper = styled.div`
  background-color: var(--white);
  border: solid 1px var(--grey-150);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    border: solid 1px var(--blue);
  }
`;

export const AnnouncementsWrapper = styled.div`
  padding: 24px;
  color: var(--deep_blue);
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
`;

export const AnnouncementsTypeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  overflow-x: auto;
  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid var(--grey-150);
  position: sticky;
  z-index: 2;
  .main-heading {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 600;
    color: #23273b;
    margin-bottom: 2.4rem;
    text-transform: uppercase;
  }

  .tab-btn-toggle {
    :hover {
      border-bottom: 1px solid #3e7eee;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PublishedLabel = styled.div`
  background-color: #36ca8b1a;
  padding: 4px 8px;
  border: solid 1px #36ca8b;
  border-radius: 20px;
  font-size: 12px;
`;

export const DraftLabel = styled.div`
  background-color: var(--grey-100);
  padding: 4px;
  border: solid 1px var(--grey-700);
  border-radius: 20px;
  font-size: 12px;
  width: 70px;
  display: flex;
  justify-content: center;
`;

export const CreateAnnouncementWrapper = styled.div`
  padding: 24px;

  @media screen and (min-width: 400px) {
    width: 350px;s
  }
  @media screen and (min-width: 450px) {
    width: 400px;
  }

  @media screen and (min-width: 600px) {
    width: 550px;
  }
  @media screen and (min-width: 700px) {
    width: 560px;
  }
  @media screen and (min-width: 800px) {
    width: 700px;
  }
  @media screen and (min-width: 900px) {
    width: 750px;
  }
`;

export const RichTextEditorWrapper = styled.div`
  border-radius: 8px;
  min-height: 300px;
  background-color: #fafcff;
  border: solid 1px var(--grey-150);
  p {
    padding: 10px 30px;
  }
  ol,
  ul {
    padding: 10px 40px;
  }
`;
