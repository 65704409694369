import React from 'react';
import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';

import { EmptyState, SpinnerComponent } from 'components';

import { Kebab } from './Kebab';

const formatdate = (value) => {
  if (value) {
    return format(parseISO(new Date(value).toISOString()), 'LLL dd, yyyy');
  }
  return value;
};

export const TrainingTable = ({ headers, data, actionToPerform, loading, msg, completed }) => {
  const dropDown = {
    view: 'View Details',
    record: 'Record Completion',
    delete: 'Delete Training',
  };

  return (
    <BaseRoot>
      <div
        className="table__headers"
        style={{ display: 'grid', gridTemplateColumns: `repeat(var(--num-cols), 1fr) 50px` }}>
        {Object.entries(headers).map(([key]) => {
          return <p key={key}>{key}</p>;
        })}
      </div>
      <>
        {loading ? (
          <SpinnerComponent h="10vh" />
        ) : (
          <>
            {data?.length === 0 ? (
              <EmptyState message={msg || 'No Training Started'} h="20vh" />
            ) : (
              <>
                {data?.map((row) => {
                  return (
                    <div
                      key={row?._id}
                      className="table__row"
                      style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(var(--num-cols), 1fr) 50px`,
                      }}>
                      {completed ? (
                        <>
                          <p>{row?.trainingId?.name}</p>
                          <p>{formatdate(row?.trainingId?.schedule?.startDate)}</p>
                          <p>{formatdate(row?.trainingId?.schedule?.endDate)}</p>
                          <Kebab
                            dropDown={dropDown}
                            actionToPerform={actionToPerform}
                            id={row?.trainingId?._id}
                          />
                        </>
                      ) : (
                        <>
                          <p>{row?.name}</p>
                          <p>{formatdate(row?.schedule?.startDate)}</p>
                          <p>{formatdate(row?.schedule?.endDate)}</p>
                          <Kebab
                            dropDown={dropDown}
                            actionToPerform={actionToPerform}
                            id={row?._id}
                          />
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </>
    </BaseRoot>
  );
};

const BaseRoot = styled.div`
  width: 100%;

  .kebab {
    display: flex;
    align-items: center;
    justify-content: start;
    .kebab-btn {
      padding: 6px 12px;
    }

    .chakra-menu__menu-list {
      padding: 1.6rem 0 !important; /* override chakra default styling */
    }

    .chakra-menu__menu-list .chakra-menu__menuitem {
      padding: 0rem 1.6rem; /* override chakra default styling */
      border-radius: 0;
    }
  }

  .table__headers {
    padding: 8px;
    background: #f3f4f6;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.0012em;
    color: #83889d;
  }
  .table__row {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #23273b;
    padding: 2.4rem 0;
    border-bottom: 1px solid #f2f2f2;
    text-transform: capitalize;
  }
  .table__row:last-child {
    border-bottom: none;
  }
`;
