import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Spinner } from './Spinner';

const SpinnerComponent = ({ w, h }) => {
  return (
    <Flex w={w || '100%'} align="center" justify="center" h={h || '20vh'} data-testid="loading">
      <Spinner />
    </Flex>
  );
};

export { SpinnerComponent };
