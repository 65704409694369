import { gql } from '@apollo/client';

export const FETCH_TRAINING = gql`
  query fetchTraining($filter: TrainingFilters!, $page: Int) {
    fetchTraining(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        name
        description
        details
        attachedLink
        attachedFile
        status
        isShared
        schedule {
          startDate
          endDate
        }
        repeatProgram {
          isRepeated
          repeatIn
          repeatType
        }
        dueDate
      }
    }
  }
`;

export const CREATE_TRAINING = gql`
  mutation createTraining($data: CreateTrainingInput!, $file: Upload) {
    createTraining(data: $data, file: $file) {
      status
      message
      data {
        _id
        name
        description
        details
        schedule {
          startDate
          endDate
        }
        repeatProgram {
          isRepeated
          repeatIn
          repeatType
        }
        status
        isShared
      }
    }
  }
`;

export const EDIT_TRAINING = gql`
  mutation editTriaining($filters: TrainingFilters!, $data: UpdateTrainingInput!) {
    editTraining(filters: $filters, data: $data) {
      status
      message
      data {
        _id
        name
        description
        details
        schedule {
          startDate
          endDate
        }
        repeatProgram {
          isRepeated
          repeatIn
          repeatType
        }
        status
        isShared
      }
    }
  }
`;

export const DELETE_TRAINING = gql`
  mutation removeTraining($trainingId: ObjectID!) {
    removeTraining(trainingId: $trainingId) {
      status
      message
    }
  }
`;

export const INDIVIDUAL_TRAINING = gql`
  query fetchTraining($filter: TrainingFilters!, $page: Int) {
    fetchTraining(filter: $filter, page: $page) {
      status
      message
      data {
        _id
        cost
        note
        employee {
          firstName
          lastName
          imageUrl
        }
        trainingId {
          _id
          name
          dueDate
          status
        }
      }
    }
  }
`;

export const INDIVIDUAL_SINGLE_TRAINING = gql`
  query fetchTraining($filter: TrainingFilters!, $page: Int) {
    fetchTraining(filter: $filter, page: $page) {
      status
      message
      data {
        name
        dueDate
        description
        details
        attachedLink
        attachedFile
        status
        schedule {
          startDate
          endDate
        }
        repeatProgram {
          isRepeated
          repeatIn
          repeatType
        }
      }
    }
  }
`;

export const SHARED_TRAINING = gql`
  query fetchTraining($filter: TrainingFilters!, $page: Int) {
    fetchTraining(filter: $filter, page: $page) {
      status
      message
      data {
        name
        dueDate
        description
        details
        attachedLink
        attachedFile
        status
        schedule {
          startDate
          endDate
        }
        repeatProgram {
          isRepeated
          repeatIn
          repeatType
        }
        employees {
          _id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;
