import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

import { AnnouncementWrapper } from '../styles';

export function AnnouncementType({ title, description, openModal }) {
  return (
    <AnnouncementWrapper onClick={openModal} data-testid={`create-${title}`}>
      <Heading fontSize={'16px'}>{title}</Heading>
      <Text fontSize={'12px'} color={'var(--grey-500)'}>
        {description}
      </Text>
    </AnnouncementWrapper>
  );
}
