import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { EmployeeAppraisalFormProvider } from 'context/EmployeeReviewForm';
import { FETCH_REVIEW_QUESTIONS } from 'query';

import { ReviewQuestionSectionPage } from './ReviewQuestions';

const isTesting = process.env.NODE_ENV === 'test';

export const SingleReviewPage = () => {
  const location = isTesting
    ? {
        state: {
          userId: '301298rhfjkdvsh7ru',
          reviewId: '093834hjfkf672423s',
        },
      }
    : useLocation();

  const {
    data: sdata,
    loading,
    refetch,
  } = useQuery(FETCH_REVIEW_QUESTIONS, {
    variables: {
      filter: {
        reviewId: location?.state?.reviewId,
        userId: location?.state?.userId,
      },
    },
  });

  const fetchedData = sdata?.fetchReviewQuestions?.data;
  const templateId = fetchedData?.reviewTemplate?.templateId;
  const responses = fetchedData?.responses;

  function transform(responses) {
    if (responses !== null) {
      return {
        questions: responses?.map((res) => {
          return { questions: res?.section };
        }),
      };
    }
  }

  const defaultValues = responses !== null ? transform(responses) : templateId;

  return (
    <EmployeeAppraisalFormProvider
      initialValue={{ defaultValues: defaultValues }}
      data-testid="single-review">
      <ReviewQuestionSectionPage
        isEditing={responses !== null}
        loading={loading}
        fetchedData={fetchedData}
        defaultValues={defaultValues}
        refetch={refetch}
      />
      ;
    </EmployeeAppraisalFormProvider>
  );
};
