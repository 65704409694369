import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Grid, Input } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, SpanDouble } from 'components';

import { INPUT_TYPES } from '../helpers';

import { FieldAppearance } from './FieldAppearance';
import { InputOptionsControl } from './InputOptionsControl';
import { InputTypeIcon } from './InputTypeIcon';
import ToggleSwitch from './ToggleSwitch';

function InputFieldEdit(props) {
  const { field, fieldName, update, index, remove, inSection } = props;
  const { register, watch, setValue, control, handleSubmit } = useForm({ defaultValues: field });
  const required = watch('required');
  const name = watch('name');
  const options = watch('options');
  const optionTypes = [INPUT_TYPES.Checkbox, INPUT_TYPES.Dropdown, INPUT_TYPES.RadioButton];
  const hasOptions = optionTypes.includes(field.type);
  const isAttachment = field.type === INPUT_TYPES.Attachment;
  const isRadio = field.type === INPUT_TYPES.RadioButton;
  const isSelect = field.type === INPUT_TYPES.Checkbox || INPUT_TYPES.Dropdown;
  const addOptionButtonCopy = isRadio && !isSelect ? 'Add Option' : 'Add Choice';

  const optionsValid = options && options.length > 1 && options[0] && options[1];
  const isInvalid = !hasOptions ? !name : !optionsValid || !name;

  function onSwitchChange(e) {
    setValue('required', e.target.checked);
  }

  function toggleSwitch() {
    setValue('required', !required);
  }

  function onSave(data) {
    update(index, { ...data, isEditing: false });
  }

  function onRemove() {
    remove(index);
  }

  return (
    <InputFieldEditRoot tabIndex={0}>
      <header className="header">
        <div className="input-label">
          <div className="input-label__icon">
            <InputTypeIcon type={field.type} />
          </div>
          <div className="input-label__name">
            <Input
              h="3.2rem"
              fontSize="1.4rem"
              {...register('name')}
              placeholder="Enter field name"
            />
          </div>
        </div>
        <div className="input__controls">
          <Grid
            alignItems="center"
            templateColumns="repeat(2, max-content)"
            gap="8px"
            className="required-control">
            <ToggleSwitch checked={required} onChange={onSwitchChange} />
            <Box
              lineHeight="1"
              fontSize="1.4rem"
              cursor="pointer"
              onClick={toggleSwitch}
              mr={index === 0 && !inSection ? '8px' : null}>
              Field Required
            </Box>
          </Grid>
          <Flex>
            {index === 0 && !inSection ? null : (
              <BaseButton variant="text" sm grey onClick={onRemove}>
                Delete
              </BaseButton>
            )}
            <BaseButton variant="dark" sm onClick={handleSubmit(onSave)} disabled={isInvalid}>
              Save
            </BaseButton>
          </Flex>
        </div>
      </header>
      <div className="input__config">
        {!hasOptions && !isAttachment && (
          <Box maxW={field.type !== INPUT_TYPES.TextArea ? '32rem' : ''}>
            <FieldAppearance
              attrs={{ placeholder: `Enter ${name.toLowerCase()}` }}
              fieldName={fieldName}
              {...field}
            />
          </Box>
        )}
        {(hasOptions || isAttachment) && (
          <InputOptionsControl
            fieldName={name}
            control={control}
            setValue={setValue}
            register={register}
            buttonCopy={addOptionButtonCopy}
            isRadio={isRadio}
            isAttachment={isAttachment}
          />
        )}
      </div>
    </InputFieldEditRoot>
  );
}

/* ---- STYLES ----*/
const InputFieldEditRoot = styled(SpanDouble)`
  display: grid;
  grid: auto-flow max-content / 1fr;
  gap: 2.4rem;
  padding: 1.6rem 2.4rem;
  border-radius: 4px;
  border: 1px solid var(--grey-300);

  &:focus-within {
    border-color: var(--primary);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: static;
    padding: 0;
    background-color: transparent;
  }

  .input-label {
    flex-basis: 100%;
    display: grid;
    grid: 1fr / 1.6rem 1fr;
    align-items: center;
    gap: 8px;
  }

  .input-label {
    max-width: 15rem;
    @media (min-width: 880px) {
      max-width: 24rem;
    }
  }

  .input__controls {
    display: flex;
  }

  .required-control {
    margin-left: 8px;
  }

  @media (max-width: 1100px) {
    .header {
      flex-flow: column;
      align-items: flex-end;
    }

    .input-label,
    .input-label input {
      width: 100%;
      max-width: 100%;
    }

    .input__controls {
      margin-top: 8px;
    }

    .required-control {
      margin-left: 0;
      margin-right: 8px;
    }
  }
`;

export { InputFieldEdit };
