import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import forbidden from '../../assets/images/forbidden.png';

const NoPermission = ({ h, w, message }) => {
  return (
    <Flex minHeight={h || '40vh'} width={w || '100%'} justifyContent="center" alignItems={'center'}>
      <Wrapper>
        <img src={forbidden} alt="" width={'200px'} />
        <Text
          mt="1rem"
          textAlign={'center'}
          color="#999FA3"
          fontSize={'1.6rem'}
          lineHeight="1.6rem"
          fontWeight={'500'}>
          {message || 'No Data Found'}
        </Text>
      </Wrapper>
    </Flex>
  );
};

export { NoPermission };

const Wrapper = styled(Box)`
  img {
    margin: 0 auto;
  }
`;
