export const tableCols = [
  {
    Header: 'Name',
    accessor: 'col1',
  },
  {
    Header: 'Contact',
    accessor: 'col2',
  },
  {
    Header: 'Team',
    accessor: 'col3',
  },
  {
    Header: 'Date Hired',
    accessor: 'col4',
  },
  {
    Header: 'Status',
    accessor: 'col5',
  },
];
