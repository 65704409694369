export const Columns = [
  {
    Header: 'Title',
    accessor: 'col1',
  },
  {
    Header: 'Description',
    accessor: 'col2',
  },
  {
    Header: 'Date Created',
    accessor: 'col3',
  },
  {
    Header: 'Action',
    accessor: 'col4',
  },
];
