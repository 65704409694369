import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, BaseDropdown, CustomInput, SimpleModal } from '../../../../components';
import { useToastify } from '../../../../hooks/useToastify';
import { EDIT_ASSET, FETCH_ALL_ASSETS } from '../../../../query';
import { InputLabel } from '../../performance/reviews/CreateReview/components/InputLabel';
import { useEmployeeOptions } from '../AssetManagement.hooks';

export function ReassignAsset(props) {
  const { editing } = props;
  const methods = useForm({
    defaultValues: {
      dateReturned: editing?.dateReturned?.substr(0, 10),
      assignedTo: editing?.assignedTo
        ? `${editing?.assignedTo?.firstName} ${editing?.assignedTo?.lastName}`
        : '',
    },
  });
  const { register, control, handleSubmit, watch } = methods;
  const { loadOptions, options } = useEmployeeOptions();

  const data = watch();
  const valid = !!data.reassignedTo && !!data.dateReturned;

  const { onReassignAsset, loading } = useHandleReassignAsset({
    id: editing?._id,
    setShowModal: props.setEditing,
    refetch: props.refetch,
  });

  return (
    <SimpleModal showModal={!!props.editing} setShowModal={props.setEditing}>
      <Box m="24px 24px 0" p="24px" border="1px solid var(--grey-150)" w="701px" maxW="90vw">
        <Header>
          <h1>Reassign Asset</h1>
        </Header>

        <Grid mt="24px" gap="24px">
          <GridItem>
            <InputLabel>
              Assigned To
              <Text color="var(--error)" as="span">
                *
              </Text>
            </InputLabel>
            <CustomInput withBorder {...register('assignedTo')} disabled />
          </GridItem>

          <GridItem>
            <InputLabel>
              Date Returned
              <Text color="var(--error)" as="span">
                *
              </Text>
            </InputLabel>
            <CustomInput withBorder {...register('dateReturned')} type="date" />
          </GridItem>

          <GridItem>
            <InputLabel required>Assigned To</InputLabel>
            <Controller
              control={control}
              name="reassignedTo"
              render={({ field }) => (
                <BaseDropdown
                  {...field}
                  w="100%"
                  async
                  defaultOptions={options}
                  loadOptions={loadOptions}
                />
              )}
            />
          </GridItem>
        </Grid>
      </Box>
      <Grid
        templateColumns="repeat(2, max-content)"
        justifyContent="flex-end"
        gap="16px"
        p={'24px 24px 0'}>
        <BaseButton variant={'outline'} onClick={() => props.setEditing(null)}>
          Cancel
        </BaseButton>
        <BaseButton
          variant={'dark'}
          onClick={handleSubmit(onReassignAsset)}
          disabled={!valid}
          isLoading={loading}>
          Save
        </BaseButton>
      </Grid>
    </SimpleModal>
  );
}

const Header = styled.div`
  h1 {
    font-size: 2.4rem;
    font-weight: 600;
  }
`;

function useHandleReassignAsset({ id, setShowModal, refetch }) {
  const [updateAsset, { loading }] = useMutation(EDIT_ASSET);
  const { successToast } = useToastify();
  async function onReassignAsset(data) {
    const transformed = { ...data };
    delete transformed.assignedTo;
    transformed.assignedTo = transformed.reassignedTo.value;
    transformed.dateReturned = new Date(data.dateReturned);
    delete transformed.reassignedTo;

    const res = await updateAsset({
      variables: { filters: { _id: id }, data: transformed },
      refetchQueries: [{ query: FETCH_ALL_ASSETS, variables: { page: 1, filter: {} } }],
      awaitRefetchQueries: true,
    });
    if (res.data.editAsset.status === 200) {
      successToast('Asset updated successfully');
      setShowModal(null);
    }
    await refetch();
  }

  return { onReassignAsset, loading };
}
