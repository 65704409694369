import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
// import ArrowDown from 'assets/icons/arrowDownFilled.svg';
// import ChatIcon from 'assets/icons/chat-icon.svg';
// import PlusGreen from 'assets/icons/plus-green.svg';
// import DP from 'assets/images/dp2.png';
import { useToastify } from 'hooks/useToastify';
import {
  FETCH_APPLICANT_APPLICATION,
  FETCH_SINGLE_APPLICANT,
  UPDATE_APPLICATION_STAGE,
} from 'query/admin/candidates';
import { PIPELINE_COLORS } from 'utils';
import { formatDate } from 'utils';

import { CustomButton, JobsBreadcrumbs } from 'components';

import { HeadingText, LabelText, PText, RenderUsers } from '../CandidateInfoComponents';

export const CandidateHiringPipeLine = ({ user, stages }) => {
  const [activeTab, setActiveTab] = React.useState(user?.stage?.rank - 1);
  //commented code below is for when we want to add notes
  // const [openEditModal, setOpenEditModal] = React.useState(false);
  // const [notes, setNotes] = React.useState([]);
  // const [state, setState] = React.useState();
  // const [sortedOrder, setSortedOrder] = React.useState('Recent First');
  const { successToast, errorToast } = useToastify();
  const { id } = useParams();
  // const hiringManager = [
  //   { id: 1, firstName: 'Floyd', lastName: 'Miles', imageUrl: null },
  //   { id: 2, firstName: 'Floyd', lastName: 'Miles', imageUrl: null },
  // ];
  const [updateApplicationStage, { loading }] = useMutation(UPDATE_APPLICATION_STAGE, {
    refetchQueries: [
      {
        query: FETCH_SINGLE_APPLICANT,
        variables: {
          filter: {
            applicationId: user?._id,
          },
        },
      },
      {
        query: FETCH_APPLICANT_APPLICATION,
        variables: {
          filter: {
            jobId: id,
            name: '',
          },
          page: 1,
        },
      },
    ],
  });

  const breadCrumbList = stages.map((item) => {
    return item.title;
  });
  React.useEffect(() => {
    setActiveTab(user?.stage?.rank - 1);
  });

  async function onChangeStatus() {
    try {
      if (user?.stage?.rank === breadCrumbList.length) {
        errorToast('Applicant is at last stage of the Pipeline');
      } else {
        const newStatusId = stages?.find((item) => item.rank === user?.stage?.rank + 1)._id;
        const response = await updateApplicationStage({
          variables: {
            data: {
              applicationId: user?._id,
              stageId: newStatusId,
            },
          },
        });
        if (response.data.updateApplicationStage.status === 200) {
          successToast('Status updated successfully');
        }
      }
    } catch (error) {
      errorToast('Error occured');
    }
  }

  // below is the code for the notes section
  // const sortList = [
  //   {
  //     label: 'Recent First',
  //     value: 'recent',
  //   },
  //   {
  //     label: 'Oldest First',
  //     value: 'oldest',
  //   },
  // ];

  // function actionDropDown(action) {
  //   switch (action.value) {
  //     case 'recent':
  //       setSortedOrder('Recent First');
  //       notes.sort((a, b) => a.time < b.time);
  //       break;
  //     case 'oldest':
  //       setSortedOrder('Oldest First');
  //       notes.sort((a, b) => a.time > b.time);
  //       break;
  //   }
  // }

  // function toggleEditModal() {
  //   setOpenEditModal(!openEditModal);
  // }

  // function addToNote() {
  //   setNotes((prev) => [
  //     ...prev,
  //     {
  //       notes: state,
  //       time: new Date().toLocaleTimeString(),
  //       date: new Date().toLocaleDateString(),
  //     },
  //   ]);
  //   setOpenEditModal(false);
  // }
  // function handleChange(e) {
  //   setState(e.target.value);
  // }

  return (
    <>
      <CandidateHiringPipelineRoot>
        <div className="stage-of">
          <Text
            fontWeight={'600'}
            fontSize={'12px'}
            lineHeight={'17px'}
            letterSpacing={'-0.117188px'}
            color={'#83889D'}>
            Stage of:{'   '}
          </Text>
          <Flex className="candidate-role">
            <p className="candidate-team">{user?.jobId?.department?.name}</p>
            <p>•</p>
            <p className="candidate-applied-role">{user?.jobId?.title}</p>
          </Flex>
        </div>
        <JobsBreadcrumbs
          active={activeTab || user?.stage?.rank - 1 || 0}
          setActive={setActiveTab}
          list={breadCrumbList}
          hideIndicator
          color={PIPELINE_COLORS[user?.stage?.rank] || PIPELINE_COLORS[1]}
        />
        <HiringDetailRoot>
          <HeadingText headingText={'Details'} />
          <hr />
          <Grid className="hiring-details-grid">
            <GridItem>
              <Flex direction={'column'} gap="8px">
                <LabelText labelText={'Email Address'} />
                <PText pText={user?.email} />
              </Flex>
            </GridItem>
            <GridItem>
              <Flex direction={'column'} gap="8px">
                <LabelText labelText={'Hiring Manager'} />
                <RenderUsers users={user?.jobId?.createdBy} />
              </Flex>
            </GridItem>
            <GridItem>
              <Flex direction={'column'} gap="8px">
                <LabelText labelText={'Stage'} />
                <PText pText={user?.stage?.title || breadCrumbList[0]} />
              </Flex>
            </GridItem>
            <GridItem>
              <Flex direction={'column'} gap="8px">
                <LabelText labelText={'Date Applied'} />
                <PText pText={formatDate(user?.createdAt)} />
              </Flex>
            </GridItem>
            <GridItem>
              <CustomButton
                variant={'black--outline'}
                name={'Move Next Status'}
                h={'4rem'}
                mW={'16.4rem'}
                onClick={onChangeStatus}
                loading={loading}
              />
            </GridItem>
          </Grid>
        </HiringDetailRoot>
        {/* <HiringNotesRoot>
          <Flex className="notes-header" justify={'space-between'}>
            <HeadingText headingText={'Notes'} />
            <Flex className="sort-dropdown">
              <Text>{sortedOrder}</Text>
              <ActionDropdown actions={sortList} btn={ArrowDown} onClick={actionDropDown} />
            </Flex>
          </Flex>
          <hr />
          <Flex gap={'8px'} alignItems={'center'} cursor={'pointer'} onClick={toggleEditModal}>
            <img src={PlusGreen} alt="add icon" />
            <Text
              fontWeight={'400'}
              fontSize={'14px'}
              lineHeight={'16px'}
              letterSpacing={'-0.117188px'}
              textDecoration={'underline'}
              color={'#36CA8B'}>
              Add Note
            </Text>
          </Flex>
          <SimpleModal showModal={openEditModal} width={'500px'}>
            <AddNoteWrapper>
              <InputBox
                type={'textarea'}
                label={'Notes'}
                placeholder={'Write note'}
                handleChange={handleChange}
              />
              <Flex gap="8px" mt={'16px'}>
                <CustomButton name="Cancel" variant={'black--outline'} onClick={toggleEditModal} />
                <CustomButton name="Save" variant={'black'} onClick={addToNote} />
              </Flex>
            </AddNoteWrapper>
          </SimpleModal>
          {notes?.map((item, index) => {
            return (
              <Flex className="note-section" justify={'space-between'} key={index}>
                <Flex gap={'8px'}>
                  <img src={DP} alt="admin profile image" />
                  <Box>
                    <Flex gap={'4px'}>
                      <Text
                        fontWeight={'600'}
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        color={'#23273B'}>
                        Ozenua Akintola David
                      </Text>
                      <Text
                        fontWeight={'400'}
                        fontSize={'12px'}
                        lineHeight={'14px'}
                        color={'#83889D'}>
                        {item.date}
                      </Text>
                      <Text
                        fontWeight={'400'}
                        fontSize={'12px'}
                        lineHeight={'14px'}
                        color={'#83889D'}>
                        •
                      </Text>
                      <Text
                        fontWeight={'400'}
                        fontSize={'12px'}
                        lineHeight={'14px'}
                        color={'#83889D'}>
                        {item.time}
                      </Text>
                    </Flex>
                    <Text
                      fontWeight={'400'}
                      fontSize={'12px'}
                      lineHeight={'14px'}
                      color={'#83889D'}
                      mt={'8px'}>
                      {item.notes}
                    </Text>
                  </Box>
                </Flex>
                <img src={ChatIcon} alt="chat icon" className="chat-icon" />
              </Flex>
            );
          })}
        </HiringNotesRoot> */}
      </CandidateHiringPipelineRoot>
    </>
  );
};

const CandidateHiringPipelineRoot = styled.div`
  .stage-of {
    display: flex;
    gap: 8px;
    margin-bottom: 32px;
    .candidate-role {
      gap: 4px;
    }

    .candidate-role p {
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      color: #23273b;
    }
  }
`;

const HiringDetailRoot = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;

  hr {
    margin: 12px 0;
  }

  .hiring-details-grid {
    grid-template-columns: 1fr;
    grid-gap: 32px;

    .imageWrapper {
      img {
        border-radius: 50%;
        width: 24px;
        aspect-ratio: 1;
        margin-right: 8px;
      }
    }
    @media (min-width: 1200px) {
      gap: 32px;
      grid-template-columns: 218px 218px;
    }
  }
`;

// const HiringNotesRoot = styled.div`
//   border: 1px solid #f2f2f2;
//   border-radius: 8px;
//   padding: 16px;
//   margin-top: 32px;

//   hr {
//     margin: 12px 0;
//   }

//   .notes-header {
//     .sort-dropdown {
//     }
//   }

//   .note-section {
//     margin-top: 25px;

//     img {
//       width: 40px;
//       aspect-ratio: 1;
//     }
//     .chat-icon {
//       width: 20px;
//       aspect-ratio: 1;
//     }
//   }
// `;

// const AddNoteWrapper = styled.div`
//   padding: 16px;
// `;
