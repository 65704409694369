import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { Flex } from '@chakra-ui/react';
import plusBlue from 'assets/icons/plusBlueBg.svg';
import plusIcon from 'assets/icons/plusIcon.svg';
import { useToastify } from 'hooks/useToastify';

import { DropFile } from 'components';

import { FilesPreview, UrlFilesPreview } from './filesDisplay';
import { dustbin, icons } from './icons';
import { Button, OptionsInput, Root, SectionInput } from './style';

const EditInputFieldTemplate = (props) => {
  const { question, type, setEditing, questionIndex, update: updateQst, setFiles, files } = props;

  const [correctAnswers, setAnswers] = React.useState([]);
  const [canAddAnswer, setCanAdd] = React.useState(false);
  const [addFile, setAddFile] = React.useState(false);
  const { control, register, watch, handleSubmit, reset } = useForm({ defaultValues: question });
  const { fields, remove, append } = useFieldArray({
    control,
    name: `options`,
  });

  const setAsAnswer = (f) => {
    if (f?.length === 0) {
      errorToast('Correct answer cannot be empty');
    } else {
      setAnswers((prev) =>
        correctAnswers.includes(f) ? prev.filter((cur) => cur !== f) : [...prev, f]
      );
    }
  };

  const { errorToast } = useToastify();
  const watchOptions = watch(`options`);
  const watchQst = watch(`question`);
  const watchScore = watch(`points`);
  const condition = type === 'radio' && correctAnswers.length > 1;
  const noEmptyOptions = watchOptions?.some((opt) => opt?.value === '');

  const handleUpdate = (data) => {
    const uniqueOptions = data?.options.filter(
      (value, index, self) => index === self.findIndex((t) => t.value === value.value)
    );
    const dataToBeSent = {
      ...data,
      options: uniqueOptions,
      files: files,
      correctAnswers: correctAnswers,
      isEditing: true,
    };

    if (watchQst === '') {
      errorToast('Question is required');
    } else if (watchScore === '') {
      errorToast('Score is required');
    } else if (noEmptyOptions) {
      errorToast('Option Cannot be empty');
    } else if (correctAnswers?.length === 0) {
      errorToast('Choose Correct answer(s)');
    } else if (condition) {
      errorToast('Only one option allowed for radio type question');
    } else {
      updateQst(questionIndex, dataToBeSent);
      reset({});
      setEditing(false);
      setFiles([]);
    }
  };

  const filterFiles = (filename) => {
    setFiles((prev) => prev?.filter((file) => file?.name !== filename));
  };

  const toggleCanAdd = () => {
    if (watchOptions?.length === 0) {
      errorToast('Add options');
    } else if (noEmptyOptions) {
      errorToast('Option Cannot be empty');
    } else {
      setCanAdd(true);
    }
  };

  return (
    <Root>
      <div className="text_area">
        <img src={icons[type]} alt="" />
        <SectionInput type="text" {...register(`question`)} />
        <Flex className="add-score">
          <p>Add Score</p>
          <SectionInput {...register(`points`)} type="number" />
        </Flex>
      </div>

      <div style={{ width: '80%', margin: 'auto' }}>
        <div className="options__section">
          <div className="options__wrapper">
            {fields?.map((field, index) => {
              const ans = watch(`options.${index}.value`);
              return (
                <button
                  key={field.id}
                  data-testid="set-as-answer"
                  disabled={!canAddAnswer}
                  onClick={() => setAsAnswer(ans)}
                  className={
                    ('options__wrapper--item',
                    correctAnswers.includes(ans) ? 'added_as_answer' : 'options__wrapper--item')
                  }
                  type={'button'}>
                  <OptionsInput
                    readOnly={canAddAnswer}
                    name={field}
                    {...register(`options.${index}.value`)}
                  />
                  <IoClose
                    data-testid="remove-option"
                    className="close"
                    color={'#999FA3'}
                    size="14"
                    onClick={() => remove(index)}
                  />
                </button>
              );
            })}
          </div>
          {!canAddAnswer && (
            <Button
              style={{ color: 'var(--primary)' }}
              data-testid="add-options-btn"
              onClick={() => {
                append({ value: '' });
              }}
              className="btn"
              type="button">
              <img src={plusBlue} alt="add" />
              Add Option
            </Button>
          )}
          {canAddAnswer && <p className="select--answer">Select answer(s)</p>}
        </div>

        <Flex justifyContent={'space-between'} mt="1.6rem">
          <Button
            data-testid="add-file"
            onClick={() => setAddFile(!addFile)}
            className="btn"
            type="button">
            <img src={plusIcon} alt="add" />
            Add File(s) <span>(optional)</span>
          </Button>

          <div className="delete-icon">
            <img
              data-testid="remove-question"
              src={dustbin}
              alt=""
              onClick={() => setEditing(false)}
            />
          </div>
        </Flex>

        {addFile && <DropFile setFiles={setFiles} size={'12MB'} />}
        <div style={{ paddingLeft: '3.6rem' }}>
          <FilesPreview
            files={files}
            addclose={true}
            removeFile={(filename) => filterFiles(filename)}
          />
        </div>

        <UrlFilesPreview files={question?.attachedFiles} />

        <div className="add__answer">
          <>
            {!canAddAnswer ? (
              <Button
                data-testid="add-answer-btn"
                onClick={toggleCanAdd}
                className="btn"
                type="button">
                <img src={plusIcon} alt="add" />
                Add answer
              </Button>
            ) : (
              <Button
                data-testid="toggle-add-answer"
                style={{
                  border: '1px solid var(--green)',
                  padding: '1rem 2.4rem',
                  background: '#F7F9FB',
                }}
                onClick={() => setCanAdd(false)}
                className="btn"
                type="button">
                Done
              </Button>
            )}
          </>

          <Button
            data-testid="update-question"
            style={{
              border: '1px solid var(--green)',
              padding: '1rem 2.4rem',
              background: '#F7F9FB',
            }}
            onClick={handleSubmit(handleUpdate)}
            className="btn"
            type="button">
            Save
          </Button>
        </div>
      </div>
    </Root>
  );
};

export { EditInputFieldTemplate };
