import { Box } from '@chakra-ui/react';

function InputLabel(props) {
  return (
    <Box fontSize="1.2rem" lineHeight="1.6rem" fontWeight="400" mb="8px">
      {props.children}
    </Box>
  );
}

export { InputLabel };
