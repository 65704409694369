import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useDebounced } from 'hooks/useDebounced';

import FilterIcon from '../../assets/icons/Filter.svg';
import SearchIcon from '../../assets/icons/Search.svg';

const SearchWrapper = styled(Box)`
  width: 100%;
  max-width: 33.5rem;
  display: flex;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding-left: 1.6rem;
  margin-right: 1.6rem;
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 10px 16px;
  border: none;
  background: #ffffff;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(51, 55, 88, 0.3);
  }

  :-ms-input-placeholder {
    color: rgba(51, 55, 88, 0.3);
  }
`;
const FilterButton = styled.button`
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 0.4rem;
  padding: 0 1.4rem;
`;
let searchTimeout;
const Search = (props) => {
  function modSetSearchString(val) {
    props.setSearchString(val);
    if (val) props.setPage(1);
  }
  useDebounced(props.value, modSetSearchString, searchTimeout);
  return (
    <Flex>
      <SearchWrapper className={'search-wrapper'}>
        {props.removeIcon ? null : (
          <Flex cursor={'pointer'} className="search-image">
            <img src={SearchIcon} alt="i" className="search" style={{ width: '20px' }} />
          </Flex>
        )}
        <SearchBox
          data-testid={'search-input'}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
      </SearchWrapper>
      {props.filter && (
        <FilterButton onClick={props.handleFilter}>
          <img src={FilterIcon} alt="" />
        </FilterButton>
      )}
    </Flex>
  );
};

export { Search };
