import React from 'react';
import { Grid } from '@chakra-ui/react';

import { NoPermission } from 'components/common/PermissionError';

import plus from '../../../../assets/icons/plus copy.svg';
import {
  Button,
  Card,
  Dropdown,
  EmptyState,
  Pagination,
  SpinnerComponent,
  Tabs,
} from '../../../../components';

import { useJobsView } from './hooks';
import {
  ButtonStyle,
  Container,
  Flex,
  Jobs,
  LineStyle,
  StatusBar,
  StatusStyle,
  Style,
  TabView,
} from './styles';

const JobsView = () => {
  const {
    tabs,
    setSelected,
    selectedButton,
    jobs,
    length,
    sortingList,
    loading,
    sortedField,
    setSortedField,
    addJob,
    page,
    setPage,
    hasNextPage,
    refetchJobs,
    totalPages,
    errorMessage,
  } = useJobsView();

  return (
    <Container>
      <Flex>
        <Tabs
          tabs={tabs}
          setSelected={setSelected}
          selected={selectedButton}
          style={Style}
          buttons={{ width: '100%' }}
          lineStyle={LineStyle}
          activeStyle={'#3E7EEE'}
          withLine={true}
          withBorder={true}
        />
        <div className="button">
          <Button
            dataTestId="new-job"
            title={'New Job Post'}
            isDisabled={false}
            style={ButtonStyle}
            img={plus}
            onClick={addJob}
          />
        </div>
      </Flex>
      {!loading && (
        <TabView>
          <StatusBar>
            <h1>
              {length} {selectedButton === 'all' ? ' ' : selectedButton} Jobs
            </h1>
            <div className="sort">
              <span>Sort by:</span>
              <Dropdown
                list={sortingList}
                value={sortedField}
                style={StatusStyle}
                onChange={(e) => setSortedField(e.target.value)}
              />
            </div>
          </StatusBar>
          <Jobs>
            <div className="cards">
              {selectedButton === 'all' && (
                <Card
                  refetchJobs={refetchJobs}
                  dataTextId="handleCard"
                  jobs={jobs}
                  path={'/admin/jobs'}
                />
              )}
              {selectedButton === 'active' && (
                <Card
                  refetchJobs={refetchJobs}
                  dataTextId="handleCard"
                  jobs={jobs}
                  path={'/admin/jobs'}
                />
              )}
              {selectedButton === 'closed' && (
                <Card
                  refetchJobs={refetchJobs}
                  dataTextId="handleCard"
                  jobs={jobs}
                  path={'/admin/jobs'}
                />
              )}
              {selectedButton === 'draft' && (
                <Card
                  refetchJobs={refetchJobs}
                  dataTextId="handleCard"
                  jobs={jobs}
                  path={'/admin/jobs'}
                />
              )}
            </div>
          </Jobs>
          {hasNextPage && <Pagination page={page} setPage={setPage} totalPages={totalPages} />}
        </TabView>
      )}
      {loading && (
        <Grid minH="20rem" placeContent="center" mt="20rem">
          <SpinnerComponent data-testid="loading" />
        </Grid>
      )}

      {jobs?.length === 0 && !loading && !errorMessage && <EmptyState message={'No Jobs Found'} />}
      {errorMessage && !loading && <NoPermission message={errorMessage} />}
    </Container>
  );
};

export { JobsView };
