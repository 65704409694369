export const columns = [
  {
    Header: 'Employee Name',
    accessor: 'employeeName',
  },
  {
    Header: 'Query Title',
    accessor: 'queryTitle',
  },
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Queried By',
    accessor: 'queriedBy',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];
