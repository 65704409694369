import React from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

import {
  Button,
  ConfigureBox,
  EmptyState,
  ModalBackButton,
  SectionBox,
  SpinnerComponent,
} from 'components';
import SectionView from 'components/admin/performance/section-box/SectionView';

import { useEditTemplate } from './hooks';

const Container = styled.div`
  margin: 40px 24px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  overflow-y: scroll;

  .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px;
  }
`;
const View = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px;
  gap: 32px;
  border: 1px solid #f2f2f2;

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    /* Black - HRMs */

    color: #23273b;
  }
  @media only screen and (max-width: 900px) {
    width: 1110px;
  }
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
  border: 1px solid #f2f2f2;

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    /* Black - HRMs */

    color: #23273b;
  }
`;

const saveButton = {
  background: '#3E7EEE',
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  width: '207px',
  color: '#ffffff',
  padding: '12px 48px',
};

const cancelButton = {
  border: '1px solid #23273b',
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: 600,
  padding: '12px 48px',
};

const EditTemplate = () => {
  const {
    goBack,
    handleSectionActions,
    state,
    setSectionData,
    sectionData,
    selected,
    templateData,
    onSaveSection,
    onSaveTemplate,
    templateLoading,
    isTesting,
    onEdit,
    editReviewLoading,
    setOpenEdit,
    openEdit,
    validateSection,
    validateTemplate,
    validateQuestions,
  } = useEditTemplate();

  return (
    <Container>
      <Flex gap={'24px'} alignItems={'center'}>
        <ModalBackButton onClick={goBack} data-testid={'backButton'} />
        <h1 className="heading">Edit Review Template</h1>
      </Flex>
      {templateLoading && !isTesting ? (
        <Grid>
          <SpinnerComponent />
        </Grid>
      ) : (
        <Flex gap={'24px'} margin={'24px 0'}>
          <View>
            <h2>{templateData?.name}</h2>
            <Sections>
              {openEdit && (
                <SectionBox
                  setSectionData={setSectionData}
                  sectionData={sectionData}
                  handleSectionActions={handleSectionActions}
                  onSaveSection={onSaveSection}
                  isDisabled={validateSection}
                />
              )}

              {state?.length ? (
                state?.map((section, index) => (
                  <SectionView
                    key={index}
                    id={index}
                    section={section}
                    handleSectionActions={handleSectionActions}
                    selected={selected}
                    setSectionData={setSectionData}
                    sectionData={sectionData}
                    onSaveSection={onEdit}
                    validateSection={validateSection}
                  />
                ))
              ) : (
                <EmptyState message={'No Sections Found'} />
              )}
              <Flex justifyContent={'space-between'} gap={'8px'}>
                <Button
                  dataTestId={'cancelTemplate'}
                  title={'Cancel'}
                  style={cancelButton}
                  onClick={goBack}
                />
                <Button
                  dataTestId={'saveTemplate'}
                  title={'Save Template'}
                  isDisabled={validateTemplate}
                  style={saveButton}
                  isLoading={editReviewLoading}
                  onClick={onSaveTemplate}
                />
              </Flex>
            </Sections>
          </View>
          <ConfigureBox
            setSectionData={setSectionData}
            sectionData={sectionData}
            selected={selected}
            setOpenEdit={setOpenEdit}
            validateQuestions={validateQuestions}
            openEdit={openEdit}
          />
        </Flex>
      )}
    </Container>
  );
};

export { EditTemplate };
