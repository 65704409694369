import { Flex, Grid } from '@chakra-ui/react';

import { SectionsIcons } from 'components/shared/Svgs';

import { useApplicantsFormSections } from '../../../../../../context';
import { createInput, INPUT_TYPES } from '../helpers';

function ConfigureControls() {
  const { appendRef, panelRef } = useApplicantsFormSections();

  function onAddSection() {
    const append = appendRef.current;
    const panel = panelRef.current;
    if (append && panel) {
      append(createInput({ type: INPUT_TYPES.Section, name: '', children: [], isEditing: true }));
      setTimeout(() => {
        const inputs = panel.querySelectorAll('.section-edit-input');
        const input = inputs[inputs.length - 1];
        input?.scrollIntoView();
        input?.focus();
      }, 0);
    }
  }

  return (
    <Flex flexFlow="column" alignItems="center" p="1.6rem">
      <ConfigureActionButton w="14.2rem" mb="1.6rem" onClick={onAddSection}>
        {SectionsIcons} Add Section
      </ConfigureActionButton>
      {/* <ConfigureActionButton w="14.2rem">{SectionsIcons} Templates</ConfigureActionButton> */}
    </Flex>
  );
}

function ConfigureActionButton(props) {
  return (
    <Grid
      h="3rem"
      templateColumns="repeat(2, max-content)"
      alignItems="center"
      gap="8px"
      px="2.4rem"
      border="1px solid var(--grey-150)"
      color="brand.grey.700"
      fontSize="1.2rem"
      cursor="pointer"
      _hover={{ bg: 'var(--grey-100)' }}
      {...props}
    />
  );
}

export { ConfigureControls };
