import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Flex, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';

import {
  FETCH_APP_SETTINGS,
  UPDATE_APP_SETTINGS,
} from '../../../../../query/admin/leave-management';

import { Container } from './Container';
import { ToggleSwitch } from './ToggleButton';

const isTesting = process.env.NODE_ENV === 'test';

export function OverAllSettings({ toggleVisibility, leaveVisibility, setVisibility }) {
  const [getLeaveSettings, { data: fetchData }] = useLazyQuery(FETCH_APP_SETTINGS);
  const [updateAppSetting] = useMutation(UPDATE_APP_SETTINGS, {
    refetchQueries: [
      {
        query: FETCH_APP_SETTINGS,
        variables: {},
      },
    ],
  });

  const [profileBalance, setProfileBalance] = React.useState();

  useEffect(() => {
    getLeaveSettings();
    const leaveSettings = fetchData?.fetchAppSettings?.data[0]?.leaveSettings[0];

    if (leaveSettings !== null && leaveSettings !== undefined) {
      setProfileBalance(leaveSettings.profileBalanceDisplayDate);
      setVisibility(leaveSettings.leaveVisibility);
    }
  }, [fetchData]);

  async function updateVisibilitySettings() {
    setVisibility((prev) => !prev);
    await updateAppSetting({
      variables: {
        data: {
          leaveSettings: [
            {
              leaveVisibility: !leaveVisibility,
              profileBalanceDisplayDate: profileBalance,
            },
          ],
        },
      },
    });
  }

  async function updateProfileBalance(value) {
    setProfileBalance(value);
    await updateAppSetting({
      variables: {
        data: {
          leaveSettings: [
            {
              leaveVisibility: leaveVisibility,
              profileBalanceDisplayDate: value,
            },
          ],
        },
      },
    });
  }

  return (
    <Container>
      <Text fontSize={'1.6rem'} fontWeight={'600'} color={'var(--black)'}>
        Overall Settings
      </Text>

      {isTesting ? (
        <Flex padding={'1rem 0'}>
          <ToggleSwitch onClick={toggleVisibility} />
          <Text fontSize={'1.2rem'}>Leave Visibility</Text>
        </Flex>
      ) : (
        <Flex padding={'1rem 0'}>
          <ToggleSwitch onChange={updateVisibilitySettings} checked={leaveVisibility} />
          <Text fontSize={'1.2rem'}>Leave Visibility</Text>
        </Flex>
      )}

      <Stack padding={'1rem 0'} gap={'0.8rem'}>
        <Text fontSize={'1.2rem'}>Profile Balance Display Date</Text>
        <RadioGroup onChange={updateProfileBalance} value={profileBalance}>
          <Stack direction="row" gap={'1.6rem'}>
            <Radio value="Year-End" size={'lg'}>
              Year End
            </Radio>
            <Radio value="Today" size={'lg'}>
              Today
            </Radio>
          </Stack>
        </RadioGroup>
      </Stack>
    </Container>
  );
}
