import styled from '@emotion/styled';

export const Candidates = styled.div``;
export const Header = styled.div`
  padding: 24px 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;

  .header-cont {
    display: flex;
    gap: 32px;

    .greeter {
      display: flex;
      gap: 12px;
      flex-direction: column;
    }

    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #83889d;
      text-transform: uppercase;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }

    p {
      text-transform: capitalize;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #83889d;
    }

    .dot {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #83889d;
    }
  }

  .buttons {
    display: flex;
    gap: 16px;
    color: #fff;

    .dropdown {
      width: 145px;
    }
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    gap: 30px;

    .header-cont {
      flex-direction: column;
      gap: 30px;
    }
`;

export const GridView = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 22px 0px 35px;

  .total-indicator {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    color: #23273b;
  }

  span {
    padding: 4px 8px;
    /* Black - HRMs */
    color: white;
    background: #23273b;
    border-radius: 4px;
  }

  .tabs {
    border-radius: 4px;
    background-color: #f3f4f6;
    padding: 4px;
  }
`;
export const View = styled.div`
  width: 100%;
  overflow: auto;
`;

export const GridButton = {
  padding: '12px 16px',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16px',
};

export const TableStyle = {
  fontWeight: 700,
  fontSize: '14px',
  padding: '16px',
  lineHeight: '16px',
  letterSpacing: '-0.5px',
  background: '#fff',
  color: '#23273B',
};

export const BackButton = {
  color: '#23273B',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '24px',
};

export const ShareButton = {
  border: '1px solid #3E7EEE',
  borderRadius: '4px',
  color: '#23273B',
  padding: '0px 16px',
  height: '40px',
  fontWeight: '500',
  fontSize: '14px',
};

export const StatusDropdown = {
  background: '#999FA3',
};
