import React from 'react';
import styled from '@emotion/styled';

export function LeftButton(props) {
  return (
    <CalendarControl type="button" {...props}>
      <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.78147 8.22059C5.04716 7.95375 5.07047 7.53704 4.85198 7.2439L4.77919 7.15993L1.8277 4.22141L4.77919 1.28088C5.04603 1.01519 5.07113 0.598577 4.85391 0.304496L4.78147 0.220221C4.51578 -0.0466194 4.09917 -0.0717239 3.80509 0.145497L3.72081 0.217934L0.234815 3.68893C-0.0328393 3.95544 -0.0571718 4.37358 0.161818 4.66765L0.234815 4.75188L3.72081 8.22288C4.01434 8.51514 4.48921 8.51412 4.78147 8.22059Z"
          fill="#23273B"
        />
      </svg>
    </CalendarControl>
  );
}

export function RightButton(props) {
  return (
    <CalendarControl type="button" {...props}>
      <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.218528 8.77919C-0.0471641 8.51235 -0.0704718 8.09563 0.148015 7.80249L0.220815 7.71853L3.1723 4.78L0.220815 1.83947C-0.0460254 1.57378 -0.0711298 1.15717 0.146091 0.86309L0.218528 0.778814C0.48422 0.511974 0.90083 0.48687 1.19491 0.704091L1.27919 0.776527L4.76519 4.24753C5.03284 4.51403 5.05717 4.93217 4.83818 5.22625L4.76519 5.31047L1.27919 8.78147C0.985661 9.07373 0.510789 9.07271 0.218528 8.77919Z"
          fill="#23273B"
        />
      </svg>
    </CalendarControl>
  );
}

const CalendarControl = styled.button`
  height: 4rem;
  width: 4rem;
  display: grid;
  place-content: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background: transparent;

  &:hover {
    background: #f2f2f2;
  }
`;
