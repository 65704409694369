import React from 'react';
import { motion } from 'framer-motion';

const svgVariants = {
  hidden: {
    opacity: 0,
    rotate: -180,
  },
  visible: {
    opacity: 1,
    rotate: 0,
    transition: {
      duration: 0.5,
    },
  },
};
const pathVariants = {
  hidden: {
    opacity: 0.5,
    pathLength: 0,
    x: -10,
  },
  visible: {
    opacity: 1,
    x: 0,
    pathLength: 1,
    transition: {
      duration: 2.5,
      ease: 'easeInOut',
      repeat: Infinity,
    },
  },
};

const SvgAnimation = () => {
  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      animate="visible"
      width="161"
      height="133"
      viewBox="0 0 161 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <motion.path
          d="M289.428 151.913C289.428 234.967 224.966 302.253 145.5 302.253C66.0342 302.253 1.57242 234.967 1.57242 151.913C1.57242 68.8584 66.0342 1.57242 145.5 1.57242C224.966 1.57242 289.428 68.8584 289.428 151.913Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M145.503 302.616C225.196 302.616 289.799 235.145 289.799 151.916C289.799 68.6869 225.196 1.21631 145.503 1.21631C65.8107 1.21631 1.20728 68.6869 1.20728 151.916C1.20728 235.145 65.8107 302.616 145.503 302.616Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
          variants={pathVariants}
          initial="hidden"
          animate="delay"
        />
        <motion.path
          d="M281.264 151.908C281.264 230.254 220.457 293.722 145.5 293.722C70.5431 293.722 9.73648 230.254 9.73648 151.908C9.73648 73.5626 70.5431 10.0939 145.5 10.0939C220.457 10.0939 281.264 73.5626 281.264 151.908Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M145.503 294.086C220.686 294.086 281.635 230.433 281.635 151.913C281.635 73.3923 220.686 9.73901 145.503 9.73901C70.319 9.73901 9.37061 73.3923 9.37061 151.913C9.37061 230.433 70.319 294.086 145.503 294.086Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <motion.path
          d="M273.101 151.91C273.101 225.547 215.949 285.198 145.501 285.198C75.0526 285.198 17.901 225.547 17.901 151.91C17.901 78.2734 75.0526 18.622 145.501 18.622C215.949 18.622 273.101 78.2734 273.101 151.91Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <motion.path
          d="M145.504 285.561C216.179 285.561 273.472 225.725 273.472 151.914C273.472 78.1023 216.179 18.2664 145.504 18.2664C74.829 18.2664 17.5356 78.1023 17.5356 151.914C17.5356 225.725 74.829 285.561 145.504 285.561Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M264.933 151.915C264.933 220.843 211.436 276.677 145.497 276.677C79.5572 276.677 26.0607 220.843 26.0607 151.915C26.0607 82.9876 79.5572 27.1535 145.497 27.1535C211.436 27.1535 264.933 82.9876 264.933 151.915Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.499 277.039C211.665 277.039 265.303 221.02 265.303 151.917C265.303 82.8148 211.665 26.7961 145.499 26.7961C79.3326 26.7961 25.6943 82.8148 25.6943 151.917C25.6943 221.02 79.3326 277.039 145.499 277.039Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M256.769 151.91C256.769 216.129 206.928 268.146 145.497 268.146C84.0662 268.146 34.2248 216.129 34.2248 151.91C34.2248 87.6909 84.0662 35.674 145.497 35.674C206.928 35.674 256.769 87.6909 256.769 151.91Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.5 268.504C207.157 268.504 257.14 216.302 257.14 151.909C257.14 87.5149 207.157 35.3135 145.5 35.3135C83.8425 35.3135 33.8594 87.5149 33.8594 151.909C33.8594 216.302 83.8425 268.504 145.5 268.504Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M248.61 151.915C248.61 211.425 202.423 259.625 145.501 259.625C88.5791 259.625 42.3927 211.425 42.3927 151.915C42.3927 92.4048 88.5791 44.2052 145.501 44.2052C202.423 44.2052 248.61 92.4048 248.61 151.915Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.497 259.986C202.646 259.986 248.974 211.602 248.974 151.917C248.974 92.2325 202.646 43.8484 145.497 43.8484C88.3484 43.8484 42.0203 92.2325 42.0203 151.917C42.0203 211.602 88.3484 259.986 145.497 259.986Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M240.445 151.916C240.445 206.717 197.914 251.099 145.501 251.099C93.0873 251.099 50.5561 206.717 50.5561 151.916C50.5561 97.1146 93.0873 52.7323 145.501 52.7323C197.914 52.7323 240.445 97.1146 240.445 151.916Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.5 251.46C198.139 251.46 240.812 206.894 240.812 151.918C240.812 96.9416 198.139 52.3748 145.5 52.3748C92.8598 52.3748 50.1868 96.9416 50.1868 151.918C50.1868 206.894 92.8598 251.46 145.5 251.46Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M232.282 151.91C232.282 202.003 193.406 242.568 145.502 242.568C97.5971 242.568 58.7209 202.003 58.7209 151.91C58.7209 101.818 97.5971 61.2531 145.502 61.2531C193.406 61.2531 232.282 101.818 232.282 151.91Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.5 242.93C193.631 242.93 232.649 202.18 232.649 151.913C232.649 101.646 193.631 60.8967 145.5 60.8967C97.369 60.8967 58.3511 101.646 58.3511 151.913C58.3511 202.18 97.369 242.93 145.5 242.93Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M223.298 151.914C223.298 196.826 188.443 233.192 145.498 233.192C102.553 233.192 67.6974 196.826 67.6974 151.914C67.6974 107.001 102.553 70.6349 145.498 70.6349C188.443 70.6349 223.298 107.001 223.298 151.914Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.501 233.553C188.672 233.553 223.67 197.003 223.67 151.915C223.67 106.828 188.672 70.2773 145.501 70.2773C102.329 70.2773 67.332 106.828 67.332 151.915C67.332 197.003 102.329 233.553 145.501 233.553Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M215.139 151.909C215.139 192.113 183.938 224.661 145.502 224.661C107.066 224.661 75.8654 192.113 75.8654 151.909C75.8654 111.705 107.066 79.1564 145.502 79.1564C183.938 79.1564 215.139 111.705 215.139 151.909Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.502 225.024C184.164 225.024 215.506 192.291 215.506 151.912C215.506 111.534 184.164 78.8003 145.502 78.8003C106.839 78.8003 75.4966 111.534 75.4966 151.912C75.4966 192.291 106.839 225.024 145.502 225.024Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M206.975 151.914C206.975 187.409 179.43 216.14 145.502 216.14C111.575 216.14 84.0294 187.409 84.0294 151.914C84.0294 116.419 111.575 87.6877 145.502 87.6877C179.43 87.6877 206.975 116.419 206.975 151.914Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.504 216.499C179.658 216.499 207.346 187.583 207.346 151.914C207.346 116.244 179.658 87.3281 145.504 87.3281C111.351 87.3281 83.6633 116.244 83.6633 151.914C83.6633 187.583 111.351 216.499 145.504 216.499Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M198.811 151.91C198.811 182.696 174.921 207.61 145.502 207.61C116.084 207.61 92.1935 182.696 92.1935 151.91C92.1935 121.124 116.084 96.2101 145.502 96.2101C174.921 96.2101 198.811 121.124 198.811 151.91Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M190.643 151.909C190.643 177.986 170.408 199.083 145.498 199.083C120.588 199.083 100.353 177.986 100.353 151.909C100.353 125.832 120.588 104.735 145.498 104.735C170.408 104.735 190.643 125.832 190.643 151.909Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M182.476 151.915C182.476 173.283 165.896 190.562 145.495 190.562C125.094 190.562 108.514 173.283 108.514 151.915C108.514 130.546 125.094 113.267 145.495 113.267C165.896 113.267 182.476 130.546 182.476 151.915Z"
          stroke="#047EFF"
          strokeWidth="1.14484"
        />
        <path
          d="M145.497 190.924C166.125 190.924 182.847 173.46 182.847 151.917C182.847 130.374 166.125 112.91 145.497 112.91C124.869 112.91 108.147 130.374 108.147 151.917C108.147 173.46 124.869 190.924 145.497 190.924Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <path
          d="M145.319 181.552C161.126 181.552 173.94 168.161 173.94 151.643C173.94 135.125 161.126 121.734 145.319 121.734C129.513 121.734 116.699 135.125 116.699 151.643C116.699 168.161 129.513 181.552 145.319 181.552Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
        />
        <motion.path
          d="M145.32 176.612C158.516 176.612 169.214 165.433 169.214 151.643C169.214 137.852 158.516 126.673 145.32 126.673C132.123 126.673 121.426 137.852 121.426 151.643C121.426 165.433 132.123 176.612 145.32 176.612Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M145.321 171.673C155.907 171.673 164.488 162.705 164.488 151.643C164.488 140.58 155.907 131.612 145.321 131.612C134.735 131.612 126.153 140.58 126.153 151.643C126.153 162.705 134.735 171.673 145.321 171.673Z"
          stroke="#047EFF"
          strokeWidth="0.572418"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
      </g>
    </motion.svg>
  );
};

export { SvgAnimation };
