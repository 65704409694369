import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_AGE_REPORT } from 'query';

import { CustomButton, EmptyState, ModalBackButton, SpinnerComponent } from 'components';

import arrowDown from '../../../../assets/images/arrowdown.svg';

import { exportCsv, getAgeFrequency, getCsv, modifyAgeProfileCsv } from './helpers';

export function AgesProfileReport() {
  const { data: ageReport, loading } = useQuery(FETCH_AGE_REPORT);
  const navigate = useNavigate();

  let data = {
    datasets: [
      {
        data: getAgeFrequency(ageReport?.retrieveAgeReport?.data || []),
        backgroundColor: ['#80F571', '#F5F071', '#EC5757', '#71F5EC', '#7CC5F9', '#7CA1F9'],
        barThickness: 58,
        lable: '',
      },
    ],

    labels: ['<20 y/o', '20-30', '31-40', '40-50', '50-70', '>70 y/o'],
  };

  return (
    <Wrapper>
      <Flex
        border={'solid 1px var(--grey-150)'}
        alignItems={'center'}
        borderRadius={'4px'}
        gap={'24px'}
        p={'24px'}
        backgroundColor={'var(--white)'}>
        <ModalBackButton
          onClick={() => navigate('/admin/reports')}
          data-testid="modal-back-button"
        />
        <Heading className="header-text">Age Profile </Heading>
      </Flex>
      <Flex justifyContent="flex-end" marginBottom="24px" marginTop="24px">
        <CustomButton
          srcRight={arrowDown}
          name={'Export'}
          mW={'122px'}
          h={'48px'}
          testId={'exportCsv'}
          onClick={() => {
            exportCsv(
              getCsv(modifyAgeProfileCsv, getAgeFrequency(ageReport?.retrieveAgeReport?.data)),
              'ageProfile'
            );
          }}
        />
      </Flex>

      {loading ? (
        <Grid placeItems={'center'} h={'500px'}>
          <SpinnerComponent />
        </Grid>
      ) : (
        <Stack
          border={'solid 1px var(--grey-150)'}
          p={'24px'}
          gap={'24px'}
          borderRadius={'4px'}
          backgroundColor={'var(--white)'}>
          <Text fontSize={'16px'}>This report shows the distribution of age in your company</Text>
          {ageReport?.retrieveAgeReport?.data.length === 0 ? (
            <EmptyState />
          ) : (
            <Box textAlign="center">
              <div>
                <Bar
                  data={data}
                  options={{
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        grid: {
                          display: false,
                        },
                      },
                    },
                    plugins: {
                      legend: false,
                    },
                  }}
                />
              </div>
            </Box>
          )}
        </Stack>
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  padding: 24px;
`;
