import React, { lazy, Suspense } from 'react';
import { Box, Menu, MenuButton, MenuList } from '@chakra-ui/react';
const EmojiPicker = lazy(() => import('emoji-picker-react'));

import { SpinnerComponent } from '../../../components';

export function EmojiMenu(props) {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton>
            <Box fontSize="24px">😍</Box>
          </MenuButton>
          {isOpen ? (
            <MenuList>
              <Box w={'348px'} h={'448px'} bg={'white'} padding={'0'}>
                <Suspense fallback={<SpinnerComponent />}>
                  <EmojiPicker onEmojiClick={props.onEmojiClick} />
                </Suspense>
              </Box>
            </MenuList>
          ) : null}
        </>
      )}
    </Menu>
  );
}
