import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { EditLeaveRequest } from 'pages/admin/leave-management/Requests/component';
import { createActionHandler } from 'utils';

import {
  Calendar,
  EventsActionContainer,
  EventsPopupAction,
  FullModal,
  ModalBackButton,
  SimpleModal,
  SpinnerComponent,
} from 'components';

import { DeletePrompt, Summary, TimeLine } from './component';
import { EmployeeLeaveHook } from './leavehook';

const dropdownAction = {
  edit: 'Edit',
  delete: 'Delete',
};

const EmployeeSingleLeaveRequestView = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  const {
    setModalState,
    editAction,
    refetch,
    deleteAction,
    showModal,
    modalType,
    loading,
    approvalFlow,
    reason,
    summary,
    date,
    toDate,
    leaveRequestData,
  } = EmployeeLeaveHook({ id });

  const actionOnData = createActionHandler({
    editAction,
    deleteAction,
  });

  return (
    <>
      <SingleRoot data-testid="single-leave">
        <FullModal showModal={true} noHeader mt="8rem">
          <div className="modal--header">
            <Flex gap="2.4rem">
              <ModalBackButton
                onClick={() => navigate('/employee/leave')}
                data-testid="modal-back-btn"
              />
              <Text textTransform={'capitalize'}>
                View Request - {!loading ? `${leaveRequestData?.leaveTypeId?.name || 'N/A'}` : ''}
              </Text>
            </Flex>
            <EventsActionContainer>
              <EventsPopupAction
                actionOnData={actionOnData}
                dropdownActions={dropdownAction}
                danger="Force Deny"
              />
            </EventsActionContainer>
          </div>
          <div className="modal--content">
            {loading ? (
              <SpinnerComponent h="70vh" />
            ) : (
              <>
                <div className="content--top">
                  <div className="calender--wrap">
                    <div className="calender">
                      <Calendar
                        isRange
                        date={date}
                        setDate={() => {}}
                        toDate={toDate}
                        setToDate={() => {}}
                      />
                    </div>
                    <Summary summary={summary} noHeading />
                  </div>
                  <TimeLine approvalFlow={approvalFlow} reason={reason} />
                </div>
              </>
            )}
          </div>
        </FullModal>
      </SingleRoot>
      <SimpleModal showModal={showModal} padding="2.4rem">
        {modalType === 'edit' ? (
          <EditLeaveRequest
            cancel={setModalState}
            editData={leaveRequestData}
            leaveId={id}
            refetch={refetch}
            employee={true}
          />
        ) : null}
        {modalType === 'delete' ? <DeletePrompt cancel={setModalState} leaveId={id} /> : null}
      </SimpleModal>
    </>
  );
};

const SingleRoot = styled.div`
  background: var(--bg);
  height: 76.3rem;
  padding: 2.4rem;
  .right-modal-overlay {
    padding: 2.4rem;
  }
  .modal--header {
    position: sticky;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    padding-bottom: 1.2rem;
    width: 100%;
    p {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3.3rem;
      color: var(--black);
    }
  }

  .modal--content {
    display: grid;
    padding: 2rem 0;
    grid-gap: 2.4rem;
    width: 100%;

    .content--top {
      display: grid;
      grid: 1fr / 1fr;
      grid-gap: 2.4rem;
      @media (min-width: 1200px) {
        grid: 1fr / 1.75fr 1fr;
      }
    }
  }

  .calender--wrap {
    padding: 2.4rem;
    border: 1px solid var(--grey-150);
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4.8rem;
    @media (min-width: 900px) {
      align-items: center;
      flex-direction: row;
    }

    .calender {
      width: 32rem;
      min-width: 50%;
      padding-bottom: 4rem;
    }
  }

  .leave-summary {
    display: grid;
    grid-gap: 3.2rem;
    position: relative;
    padding-left: 4.8rem;
    border: none;

    &:before {
      content: '';
      border: 1px solid var(--grey-150);
      position: absolute;
      top: 0;
      bottom: 0;
    }

    .title {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.1rem;
      letter-spacing: -0.02em;
      color: var(--black);
      margin-bottom: 0.8rem;
    }

    .subtitle {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.1rem;
      letter-spacing: -0.02em;
      color: var(--grey-600);
    }
    .subtitle:first-letter {
      text-transform: capitalize;
    }
  }
`;

export { EmployeeSingleLeaveRequestView };
