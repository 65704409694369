import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import BulkIcon from '../../../assets/icons/B-onboarding.svg';
import download from '../../../assets/icons/Download.svg';
import gridView from '../../../assets/icons/gridLayout.svg';
import printer from '../../../assets/icons/printer.svg';
import SingleIcon from '../../../assets/icons/S-onboarding.svg';
import tableView from '../../../assets/icons/tableView.svg';
import { EventsActionContainer, EventsPopupAction } from '../../../components';

const IconWrapper = styled(Flex)`
  background-color: #fff;
  width: 4.7rem;
  height: 4.7rem;
  border-radius: 0.4rem;
  justify-content: center;
  border: 1px solid #f2f2f2;
  img {
    width: 2.2rem;
    height: 2.2rem;
  }
`;
const CustomButton = styled.button`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #fff;
`;
const ButtonTab = styled(CustomButton)`
  ${({ active }) =>
    `
    background: ${active ? '#F2F2F2' : '#fff'};
  `};
  ${({ layoutType }) =>
    `
    border-top-right-radius: ${layoutType === 'table' ? 'inherit' : ''};
    border-bottom-right-radius: ${layoutType === 'table' ? 'inherit' : ''};
    border-top-left-radius: ${layoutType === 'grid' ? 'inherit' : ''};
    border-bottom-left-radius: ${layoutType === 'grid' ? 'inherit' : ''};
  `}
`;

export const OnbTypeWrapper = styled(Flex)`
  width: 16rem;
  height: 16rem;
  margin-bottom: 2.4rem;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.4rem;
  align-items: center;
  border: 1px solid #f2f2f2;
  :hover {
    border: 1px solid rgba(62, 126, 238, 0.3);
  }
`;

export const Kebab = ({ dropdownActions, actionOnData }) => {
  return (
    <>
      <EventsActionContainer>
        <EventsPopupAction
          largeKebab
          dropdownActions={dropdownActions}
          actionOnData={actionOnData}
          // email={email}
          // id={id}
        />
      </EventsActionContainer>
    </>
  );
};

export const DownloadIcon = (props) => {
  return (
    <IconWrapper>
      <button onClick={props.export}>
        <img src={download} alt="i" />
      </button>
    </IconWrapper>
  );
};
const handlePrint = () => {
  window.print();
};

export const Print = () => {
  return (
    <IconWrapper onClick={handlePrint}>
      <button>
        <img src={printer} alt="i" />
      </button>
    </IconWrapper>
  );
};

export const Layout = ({ activeTab, gridViewTab, tableViewTab }) => {
  return (
    <Flex
      data-testid="layout"
      borderRadius={'0.4rem'}
      border="1px solid #F2F2F2"
      height={'4.8rem'}
      width="9.6rem">
      <ButtonTab
        layoutType={'grid'}
        onClick={() => gridViewTab('gridLayout')}
        active={activeTab === 'gridLayout' ? 'active' : ''}
        data-testid="gridLayout">
        <img src={gridView} alt="i" />
      </ButtonTab>
      <ButtonTab
        layoutType={'table'}
        onClick={() => tableViewTab('tableLayout')}
        active={activeTab === 'tableLayout' ? 'active' : ''}
        data-testid="tableLayout">
        <img src={tableView} alt="i" />
      </ButtonTab>
    </Flex>
  );
};

export { BulkIcon, SingleIcon };
