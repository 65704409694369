import { gql } from '@apollo/client';

export const CREATE_REVIEW = gql`
  mutation addReview($data: AddReviewInput!) {
    addReview(data: $data) {
      status
      message
      data {
        _id
        title
        frequency
        schedule {
          startDate
          endDate
        }
        reviewPeriod {
          startDate
          endDate
        }
        isSeenByReviewee
        isSeenByAll
      }
    }
  }
`;

export const EDIT_REVIEW = gql`
  mutation updateReview($filters: ReviewFilters!, $data: ReviewUpdateData!) {
    updateReview(filters: $filters, data: $data) {
      status
      message
      data {
        title
        status
        schedule {
          startDate
          endDate
        }
        frequency
      }
    }
  }
`;

export const RETRIEVE_REVIEWS = gql`
  query retrieveReviews($filters: ReviewFilters, $page: Int) {
    retrieveReviews(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      pageCount
      totalCount
      data {
        _id
        title
        createdAt
        frequency
        status
        schedule {
          startDate
          endDate
        }
        reviewPeriod {
          startDate
          endDate
        }
        reviewCycle {
          employee {
            _id
          }
          reviewers {
            _id
          }
        }
        reviewType {
          name
          description
          score
        }
        templateId {
          _id
        }
      }
    }
  }
`;

export const RETRIEVE_REVIEWS_STATUS = gql`
  query retrieveReviewStatus {
    retrieveReviewStatus {
      status
      message
      data {
        status
        count
      }
    }
  }
`;

export const RETRIEVE_SINGLE_REVIEW_REPORT = gql`
  query retrieveSingleReviewReport($reviewId: ObjectID!, $employeeId: ObjectID!) {
    retrieveSingleReviewReport(reviewId: $reviewId, employeeId: $employeeId) {
      status
      message
      data {
        reportData {
          report {
            review_doc {
              _id
              title
              frequency
              reviewPeriod {
                startDate
                endDate
              }
              isSeenByAll
              isSeenByReviewee
            }
            reviewers {
              comments
              reviewType
              responses {
                section {
                  title
                  questions {
                    questionType
                    question
                    options {
                      value
                      choice
                      isAnswer
                    }
                  }
                }
              }
            }
            reviewee {
              _id
              firstName
              lastName
            }
          }
        }
        scoreData {
          _id
          reviewScore
        }
      }
    }
  }
`;
