import React, { useState } from 'react';
import { Box, Grid, Stack, Text } from '@chakra-ui/react';

import { SimpleModal } from 'components';

import calendar from '../../../../../assets/icons/UpcomingEventsCalendar.svg';
import { getAllUpcomingEvents, showUpcomingEvents } from '../helpers';

import { GroupedUpcomingEvents } from './GroupedUpcomingEvents';
import { SendBirthdayWish } from './Modals';

export function UpcomingEvents({ upcomingEvents }) {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');

  function toggleModal() {
    setShowModal((prev) => !prev);
  }

  return (
    <>
      <div className="upcomingEvents__container">
        <Text paddingBottom={'8px'} fontWeight={'600'}>
          Upcoming Events
        </Text>
        {showUpcomingEvents(getAllUpcomingEvents(upcomingEvents)) ? (
          <Stack
            gap={'2rem'}
            border={'1px solid #edf2f7'}
            borderRadius={'0.4rem'}
            backgroundColor={'#fafcff'}
            padding={'1.2rem'}
            height={'32.5rem'}
            maxH={'35rem'}
            overflow={'auto'}>
            <GroupedUpcomingEvents
              upcomingEvents={upcomingEvents}
              setName={setName}
              setUserId={setUserId}
              toggleModal={toggleModal}
            />
          </Stack>
        ) : (
          <>
            <Grid
              p={'1.75rem 4.5rem'}
              borderRadius={'0.4rem'}
              gap={'2rem'}
              placeItems={'center'}
              border={'1px solid #edf2f7'}
              backgroundColor={'#fafcff'}
              padding={'1.2rem'}
              height={'32.5rem'}
              maxH={'35rem'}>
              <Box>
                <img src={calendar} alt="" />
                <Stack>
                  <Text fontSize={'1.6rem'}>No upcoming Events</Text>
                  <Text fontSize={'1.2rem'}>Nothing to show here</Text>
                </Stack>
              </Box>
            </Grid>
          </>
        )}
      </div>

      <SimpleModal showModal={showModal}>
        <SendBirthdayWish closeModal={toggleModal} name={name} userId={userId} />
      </SimpleModal>
    </>
  );
}
