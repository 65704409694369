import React from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Button } from 'components';

import plus from '../../../assets/icons/plus copy.svg';
import { Tabs } from '../../../components/shared/tabs';

import { LeaveLayoutHook } from './basehook';
import { LeaveSettingsPage } from './leave-settings';
import { RequestPage } from './Requests';
import { CreateLeaveType, TypesAndPolicies } from './types-and-policies';

export const LeaveManagementView = () => {
  const { tabs, setSelected, selectedButton, createLeaveType, showModal, setShowModal } =
    LeaveLayoutHook();

  return (
    <PageRoot data-testid="leave-layout">
      <Header>
        {selectedButton !== 'policies' && <MainHeading>{`LEAVE ${selectedButton}`}</MainHeading>}
        {selectedButton === 'policies' && (
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            marginBottom={'32px'}
            className={'main-heading'}>
            TYPES AND POLICIES
            <Button
              dataTestId="create-leave-type"
              title={'Create Leave Type'}
              isDisabled={false}
              style={ButtonStyle}
              img={plus}
              onClick={createLeaveType}
            />
          </Flex>
        )}
        {showModal && <CreateLeaveType showModal={showModal} setShowModal={setShowModal} />}
        <Tabs tabs={tabs} setSelected={setSelected} selected={selectedButton} />
      </Header>

      <Content>
        {selectedButton === 'requests' && <RequestPage />}
        {selectedButton === 'settings' && <LeaveSettingsPage />}
        {selectedButton === 'policies' && (
          <TypesAndPolicies showModal={showModal} setShowModal={setShowModal} />
        )}
      </Content>
    </PageRoot>
  );
};

const PageRoot = styled.div`
  padding: 0 2.4rem;
`;

const Header = styled.div`
  border-bottom: 1px solid var(--grey-150);
  position: sticky;
  top: 6rem;
  background: var(--bg);
  z-index: 10;
  padding-top: 2.4rem;

  .main-heading {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 600;
    color: #23273b;
    margin-bottom: 2.4rem;
    text-transform: uppercase;
  }

  .tab-btn-toggle {
    :hover {
      border-bottom: 1px solid #3e7eee;
    }
  }
`;
const Content = styled.div`
  width: 100%;
  height: auto;
  padding-top: 2.4rem;
`;

const ButtonStyle = {
  padding: '16px',
  fontWeight: 400,
  fontSize: '16px',
  color: '#fff',
  borderRadius: '3px',
};
export const MainHeading = styled.p`
  min-height: 6rem;
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 600;
  color: #23273b;
  margin-bottom: 2.4rem;
  text-transform: uppercase;
`;
