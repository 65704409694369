import { gql } from '@apollo/client';

export const FETCH_ALL_USERS = gql`
  query fetchAllAdmins($filter: AdminFilter, $page: Int) {
    fetchAllAdmins(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      pageCount
      data {
        _id
        firstName
        lastName
        status
        createdAt
        imageUrl
        email
        phoneNumber
        isDefaultAdmin
        location
        role {
          rolename
          _id
        }
      }
    }
  }
`;

export const INVITE_ADMIN = gql`
  mutation addNewAdmin($data: AddNewAdminInput!) {
    addNewAdmin(data: $data) {
      status
      message
      data {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

export const EDIT_ADMIN = gql`
  mutation editAdminDetails($data: AdminUpdateInput!) {
    editAdminDetails(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;
export const DELETE_ADMIN = gql`
  mutation deleteAdmin($data: AdminUpdateInput!) {
    deleteAdmin(data: $data) {
      status
      message
    }
  }
`;
