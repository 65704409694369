import { ConfigurePanelRoot } from '../../ApplicantsForm/components/ConfigurePanel';

import { DraggableScoreCards } from './DraggableScoreCards';

function ConfigurePanel() {
  return (
    <ConfigurePanelRoot>
      <header className="header">Configure</header>
      <DraggableScoreCards />
    </ConfigurePanelRoot>
  );
}

export { ConfigurePanel };
