import React from 'react';
import { useWatch } from 'react-hook-form';
import { TiPlus } from 'react-icons/ti';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { BaseButton, BaseDropdown, SimpleModal } from '../../../../../../components';
import { useWorkScheduleForm } from '../../../../../../context/workScheduleForm';
import { getDateOptions } from '../../helpers';

import { ActionDropdown } from './ActionDropdown';
import { BlackoutPeriodForm } from './BlackoutPeriodForm';
import { ScheduleTableWrapper } from './ScheduleTableWrapper';

const dateFieldName = 'startDate';
const toDateFieldName = 'endDate';
// const dummyHolidays = [
// {
//   id: Math.random(),
//   name: 'Pre-Christmas Push',
//   observed: new Date(2022, 10, 20),
//   to: new Date(2022, 11, 20),
// },
// ];

function BlackoutPeriods(props) {
  const { onSaveSchedule = () => {} } = props;
  const [showBlackoutPeriodModal, setShowBlackoutPeriodModal] = React.useState(false);
  // const [periods, setPeriods] = React.useState(dummyHolidays);
  const [editing, setEditing] = React.useState(null);
  const { setValue, control, startDate: current, setStartDate: setCurrent } = useWorkScheduleForm();
  const periods = useWatch({ control, name: 'blackoutPeriods' }) || [];
  periods.sort((a, b) => a[dateFieldName] - b[dateFieldName]);

  const actions = [
    { name: 'edit', display: 'Edit Holiday', method: handleEditHoliday },
    { name: 'delete', display: 'Delete Holiday', method: handleDeleteHoliday },
  ];

  function setPeriods(newPeriods) {
    setValue('blackoutPeriods', newPeriods);
  }

  function getDayUpdateHandler(id, payload) {
    return (day) => (day.id === id ? { ...day, ...payload } : day);
  }

  function onSave(payload, id = null) {
    if (id) {
      setPeriods(periods.map(getDayUpdateHandler(id, payload)));
    } else {
      setPeriods([...periods, { id: Math.random(), ...payload }]);
    }
    onSaveSchedule();
    closeCustomHolidayModal();
  }

  function closeCustomHolidayModal() {
    setShowBlackoutPeriodModal(false);
    setEditing(null);
  }

  function handleAddBlackoutPeriodClick() {
    setShowBlackoutPeriodModal(true);
  }

  function handleEditHoliday(id) {
    setEditing(periods.find((h) => h.id === id));
    setShowBlackoutPeriodModal(true);
  }

  function handleDeleteHoliday(id) {
    setPeriods(periods.filter((day) => day.id !== id));
    onSaveSchedule();
  }

  return (
    <HolidayRoot className="holiday">
      <header className="holiday__header">
        <h2>Blackout Periods</h2>

        <div className="options">
          <BaseDropdown
            options={getDateOptions()}
            onChange={(v) => setCurrent(v.value)}
            value={current}
            border="1px solid #f2f2f2"
            w="100%"
            h="4.2rem"
          />
          <BaseButton
            data-testid="add-blackout-period"
            variant="outline-blue"
            h="4.2rem"
            smp
            onClick={handleAddBlackoutPeriodClick}>
            <TiPlus />
            Add Blackout Period
          </BaseButton>
        </div>
      </header>

      <ScheduleTableWrapper className="scroll-bar">
        <table className="holiday-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Date Observed</th>
              <th>
                <Box w="2.4rem"></Box>
              </th>
            </tr>
          </thead>
        </table>
        {periods.length ? (
          <div className="body-table scroll-bar">
            <table>
              <tbody>
                {periods.map((holiday) => (
                  <tr key={holiday.id}>
                    <td>{holiday.name}</td>
                    <td>
                      {holiday[dateFieldName]?.toDateString()} -{' '}
                      {holiday[toDateFieldName]?.toDateString()}
                    </td>
                    <td>
                      <ActionDropdown actionList={actions} id={holiday.id} name={holiday.name} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}

        {!periods.length ? (
          <NoHolidays>
            <h2>No blackout periods saved for {current}.</h2>
          </NoHolidays>
        ) : null}
      </ScheduleTableWrapper>

      <SimpleModal width="77rem" showModal={showBlackoutPeriodModal}>
        <BlackoutPeriodForm editing={editing} onSave={onSave} onCancel={closeCustomHolidayModal} />
      </SimpleModal>
    </HolidayRoot>
  );
}

const HolidayRoot = styled.div`
  margin-top: 4.8rem;

  .holiday__header {
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    @media (min-width: 992px) {
      align-items: center;
      flex-flow: row;
    }

    .options {
      display: grid;
      grid: auto-flow 1fr / 1fr;
      gap: 0.8rem;
      margin-top: 2.4rem;

      @media (min-width: 992px) {
        grid: 1fr / 13rem max-content max-content;
        margin-top: 0;
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const NoHolidays = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 22rem;
  margin-top: 0.4rem;
  border: 1px solid var(--grey-150);
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.05em;
    margin-bottom: 1rem;
  }
`;

export { BlackoutPeriods };
