import { Box, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AnimatedSection } from 'components/AnimateSection';

import verify from '../../../assets/icons/verify.svg';
import dashboard from '../../../assets/images/dashboardImage.png';
import linear from '../../../assets/images/layerLinear.png';

const Wrapper = styled(Grid)`
  font-family: Inter;
  grid-template-columns: 1fr 1fr;
  background-image: url(${linear});
  background-size: cover;
  background-color: #EDEEF4;
  padding: 80px 0;
  gap: 54px;
  width: calc(100% + 6%);

  @media screen and (max-width: 968px) {
      grid-template-columns: 1fr;
      background-image: none;
      width: 100%;

      .heading {
        &-text {
          max-width: 100%;
          font-size: 36px;
      }
        &-description {
          font-size: 16px;
        }
    }

      .section-image {
          display: none;
      }
  }

  .heading {
    gap: 24px;
    padding: 23px 0;

    &-text {
        font-size: 46px;
        max-width: 530px;
        font-weight: 700;
        line-height: 72px; /* 156.522% */
        letter-spacing: -0.92px; 
    }

    &-description {
        color: var(--gray-600, #475467);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
    }
  }

    .section-3 
    {
        &-text {
          gap: 47px;
          height: fit-content;
        }

        &-grid {
            grid-template-columns: repeat(2, 281px);
            gap: 32px;
            align-content: stretch;

            @media(max-width: 768px){
            grid-template-columns: repeat(1, 1fr);

            }
            
            &-item {
                display: flex;
                flex-direction: column;
                padding: 22px 20px;
                gap: 20px;
                border-radius: 20px;
                border: 1.5px solid var(--card-stroke-color, #E3E6EE);
                background: var(--card-fill-color, #F7F8FA);

                .grid {
                    &-text {
                        gap: 12px;
                    }

                    &-heading {
                        color: #101828;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 14.5px; /* 80.556% */
                        letter-spacing: -0.36px;
                    }

                    &-description {
                        color: #5d6c81;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 20px; /* 166.667%   
                    }
                }
            }
        }
    }
`;

const textArray = [
  {
    heading: 'Performance Management',
    description: 'Evaluate employee performance to enhance productivity and growth.',
  },
  {
    heading: 'Leave and Exit management',
    description:
      'Simplify and automate leave requests and approvals. Smoothly manage employee exits and off-boarding processes.',
  },
];

export const Section3 = () => {
  return (
    <div className="container">
      <Wrapper>
        <Grid className="section-3-text">
          <AnimatedSection>
            <Grid className="heading">
              <Text className="heading-text">
                <Box as={'span'} color={'#3E7EEE'}>
                  Ready
                </Box>{' '}
                to Revolutionize Your HR Processes?
              </Text>
              <Text className="heading-description">
                Take your HR management to the next level with HRMS. Streamline your operations,
                enhance productivity, and drive organizational success.
              </Text>
            </Grid>
          </AnimatedSection>

          <AnimatedSection>
            <Grid className="section-3-grid">
              {textArray.map(({ heading, description }) => (
                <GridItem key={heading} className="section-3-grid-item">
                  <Box className="image">
                    <img src={verify} />
                  </Box>
                  <Grid className="grid-text">
                    <Text className="grid-heading">{heading}</Text>
                    <Text className="grid-description">{description}</Text>
                  </Grid>
                </GridItem>
              ))}
            </Grid>
          </AnimatedSection>
        </Grid>
        <Image className="section-image" src={dashboard} />
      </Wrapper>
    </div>
  );
};
