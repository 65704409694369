import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import check from '../../assets/icons/check.svg';
import { Button } from '../../components';

const Container = styled.div`
  background-color: #fff;
  text-align: center;
  border: 1px solid #f2f2f2;
  padding: 114px 0;
  margin: 34px 150px;

  figure {
    display: flex;
    justify-content: center;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */
    color: #333758;
    margin-top: 26px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    color: #333758;
    opacity: 0.6;
    margin-top: 12px;
  }

  @media only screen and (max-width: 900px) {
    background-color: transparent;
    border: none;
    margin: 0px auto;
    padding: 50px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
`;

const ButtonStyle = {
  margin: '32px auto',
  padding: '12px 64px',
  color: '#fff',
  backgroundColor: '#3e7eee',
  borderRadius: '3px',
};

const RoutingMessage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <figure>
        <img src={check} alt="" />
      </figure>
      <h1>You have successfully applied</h1>
      <p>
        To track your application progress and to receive real-time updates,
        <br />
        please create an account below.
      </p>
      <Button title="Create your account" style={ButtonStyle} onClick={() => navigate(`signup`)} />
    </Container>
  );
};

export { RoutingMessage };
