import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

function CustomTable(props) {
  const data = useMemo(() => props.data || []);

  const hasNextPage = useMemo(() => props.hasNextPage);
  const page = useMemo(() => props.page);

  const columns = useMemo(() => props.columns || []);

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <table {...getTableProps()}>
        <thead style={props.tableStyle}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} style={props.headerStyle} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th style={props.style} key={i} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()} className="space">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr style={props.bodyStyle} key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td key={i} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* {hasNextPage && ( */}
      {data.length !== 0 && (
        <Flex justify={{ base: 'center', lg: 'flex-end' }} cursor="pointer">
          <Box mt="20" mb="5">
            <Flex fontWeight="bold">
              {page > 1 && (
                <Text color="#36CA8B" onClick={() => props.setPage(page - 1)}>
                  <ArrowLeftIcon mr="4" /> Previous Page
                </Text>
              )}
              {hasNextPage && data.length === (props.pageLength || 10) && (
                <>
                  <Box border="1px solid #F2F2F2" mr="8" ml="8"></Box>
                  <Text color="#36CA8B" onClick={() => props.setPage(page + 1)}>
                    View Next Page <ArrowRightIcon ml="4" />
                  </Text>
                </>
              )}
            </Flex>
          </Box>
        </Flex>
      )}
      {/* )} */}
    </>
  );
}

const TableWrapper = styled(Box)`
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;

  table {
    overflow-x: scroll;
    border: none;
    width: 100%;
    max-width: 100%;
  }

  tbody:before {
    line-height: 16px;
    content: '.';
    color: white; /* bacground color */
    display: block;
  }
`;

export { CustomTable, TableWrapper };
