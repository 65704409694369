import { Route, Routes } from 'react-router-dom';
import { EmployeeDashboard } from 'layouts';
import {
  CreateTraining,
  EmployeeAnnouncement,
  EmployeeLeave,
  EmployeeOnboarding,
  EmployeeOverview,
  EmployeePerformance,
  EmployeeSingleLeaveRequestView,
  EmployeeTimesheet,
  Inbox,
  LeaveRequestPage,
  LineManagerLeaveApproval,
  PerformanceReview,
  ProfileView,
  Query,
  ReviewPage,
  SingleReviewPage,
  Training,
} from 'pages';

import { GeneralMessageSubscription } from '../pages/inbox/GeneralMessageSubscription';

import EmployeePrivateRoute from './PrivateRouteEmployee';

function EmployeeRoutes() {
  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <EmployeePrivateRoute authRoute="/employee/login">
              <EmployeeDashboard />
            </EmployeePrivateRoute>
          }>
          <Route path="" element={<EmployeeOverview />}></Route>
          <Route path="onboard" element={<EmployeeOnboarding />}></Route>
          <Route path="leave" element={<EmployeeLeave />}></Route>
          <Route path="leave-request" element={<LeaveRequestPage />}></Route>
          <Route path="leave-request/:id" element={<EmployeeSingleLeaveRequestView />}></Route>
          <Route path="appraisal" element={<EmployeePerformance />}></Route>
          <Route path="appraisal-review" element={<ReviewPage />}></Route>
          <Route path="appraisal-review/:id" element={<SingleReviewPage />}></Route>
          <Route path="training" element={<Training />}></Route>
          <Route path="profile" element={<ProfileView />}></Route>
          <Route path="leave" element={<EmployeeLeave />}></Route>
          <Route path="training" element={<Training />}></Route>
          <Route path="training-create" element={<CreateTraining />}></Route>
          <Route path="profile" element={<EmployeeOverview />}></Route>
          <Route path="performance-review" element={<PerformanceReview />}></Route>
          <Route path="query" element={<Query />}></Route>
          <Route path="announcements" element={<EmployeeAnnouncement />}></Route>
          <Route path="timesheets" element={<EmployeeTimesheet />}></Route>
          <Route path="leave-approval" element={<LineManagerLeaveApproval />}></Route>

          <Route path="inbox" element={<Inbox />} />
        </Route>
      </Routes>
      <GeneralMessageSubscription />
    </>
  );
}

export { EmployeeRoutes };
