import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { CREATE_LEAVE_POLICY, FETCH_LEAVE_POLICY } from 'query/admin/leave-management/leave-policy';

import { EditLeaveTypeAndPolicy, FullModal } from 'components';

import { Box } from '../create-leave-type';

const CreateLeavePolicy = ({ setShowModal, showModal }) => {
  const initialState = {
    name: '',
    duration: 0,
    durationType: '',
    leaveTypeId: showModal,
  };
  const [state, setState] = useState(initialState);
  const { successToast, errorToast } = useToastify();
  const [addLeavePolicy, { loading: LeavePolicyLoading }] = useMutation(CREATE_LEAVE_POLICY);

  function goBack() {
    setShowModal('');
  }

  const isDisabled =
    !(state?.name && state?.leaveTypeId && state?.duration && state?.durationType) ||
    LeavePolicyLoading;

  const onSave = async () => {
    try {
      const { duration, durationType, ...rest } = state;
      const res = await addLeavePolicy({
        variables: {
          data: {
            leaveDuration: { duration, durationType },
            ...rest,
          },
        },
        refetchQueries: [
          {
            query: FETCH_LEAVE_POLICY,
            variables: {},
          },
        ],
      });
      const { data } = res;
      if (data && data.addLeavePolicy.status === 201) {
        successToast('Leave Policy added sucessfully');
        goBack();
      } else errorToast(data.addLeavePolicy.message);
    } catch (err) {
      errorToast(err.message);
    }
  };

  React.useEffect(() => {
    setState({ leaveTypeId: showModal, ...initialState });
  }, [showModal]);

  return (
    <Box>
      <FullModal noHeader showModal={showModal} mt="8.3rem">
        <EditLeaveTypeAndPolicy
          isDisabled={isDisabled}
          leavePolicy={true}
          title={'Create Leave Policy'}
          setState={setState}
          goBack={goBack}
          value={state}
          onClick={onSave}
          loading={LeavePolicyLoading}
        />
      </FullModal>
    </Box>
  );
};

export { CreateLeavePolicy };
