import { Controller, useFormState } from 'react-hook-form';

import { useJobDetailsForm, useOptions } from '../../../../context';
import { inputError } from '../../../../utils';
import { BaseDropdown } from '../../../shared';
import { JobInputLabel } from '../JobInputLabel';

function DepartmentInput() {
  const { control } = useJobDetailsForm();
  const { getOption } = useOptions();
  const formState = useFormState({ control });
  const path = 'department';
  return (
    <>
      <JobInputLabel>Department</JobInputLabel>
      <Controller
        control={control}
        name={path}
        render={({ field }) => (
          <BaseDropdown
            placeholder="Select department"
            w="100%"
            options={getOption(path)}
            {...field}
          />
        )}
      />
      {inputError(formState.errors, path)}
    </>
  );
}

export { DepartmentInput };
