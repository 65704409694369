import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastify } from 'hooks/useToastify';
import { CREATE_TEAM } from 'query/admin/training';
import * as yup from 'yup';

import { BaseInput, CustomButton } from 'components';

export const TeamSchema = yup.object().shape({
  name: yup.string().required('Team is a required field'),
});

export const AddTeams = ({ closeModal, refetch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(TeamSchema),
  });

  const { successToast, errorToast } = useToastify();

  const [createTeam, { loading }] = useMutation(CREATE_TEAM);

  const onSubmit = async (data) => {
    const dataToSend = { name: data?.name };

    try {
      const result = await createTeam({ variables: { data: dataToSend } });
      const status = result?.data?.createTeam?.status;
      if (status === 201) {
        const message = result?.data?.createTeam?.message;
        successToast(message);
      } else if (status === 409) {
        const message = result?.data?.createTeam?.message;
        errorToast(message);
      }
    } catch (error) {
      errorToast('Error Occured');
    } finally {
      refetch();
      closeModal();
    }
  };

  return (
    <DepartmentRoot>
      <h1>Create Team</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="form" data-testid="form">
        <BaseInput {...register('name')} label="Team" placeholder="Team" type="text" />
        <ErrorMessage>{errors?.name?.message}</ErrorMessage>
        <CustomButton
          name="Create team"
          variant="primary"
          h="4.8rem"
          type="submit"
          loading={loading}
        />
      </form>
    </DepartmentRoot>
  );
};

const ErrorMessage = styled.p`
  color: var(--error);
  font-size: 1.2rem;
  text-transform: capitalize;
`;

export const DepartmentRoot = styled.div`
  max-width: 42rem;
  margin: auto;
  h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    color: var(--black);
    font-weight: 500;
  }
  .form {
    margin-top: 4.8rem;
    display: grid;
    grid-gap: 1.6rem;

    .inputType {
      border: 1px solid transparent;
    }

    .designations {
      max-width: 36rem;
    }

    .remove-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.8rem;
      height: 3.4rem;
      background: #fafcff;
      border: 1px solid #f2f2f2;
      border-radius: 50%;
    }
  }

  .add-designation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      img {
        margin-right: 0.95rem;
      }
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
      letter-spacing: -0.117188px;
      text-decoration-line: underline;
      color: var(--green);
    }
  }
`;
