import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { getItem, logout } from 'utils';

import { CustomButton } from 'components';

import Design from '../../../../assets/images/emp-onboard-design.svg';
import Logo from '../../../../assets/images/logo-blue.svg';

export const EmployeeWelcome = () => {
  const navigate = useNavigate();
  const hideWelcome = () => {
    navigate('/employee/onboard');
  };

  const user = getItem('hrm_user');

  const logOut = () => {
    logout();
    navigate('/login');
  };
  return (
    <>
      <WelcomeRoot>
        <div className="header">
          <img src={Logo} alt="logo" />
          <p onClick={logOut}>Sign out</p>
        </div>
        <div className="welcome-content">
          <p className="welcome-content-greeting">{`Hello ${user?.firstName}!`}</p>
          <h1>{`Glad to have you join us at ${user?.companyId?.companyName}`}</h1>
          <p className="welcome-content-message">
            A personal account has been set up for you, Let’s start your onboarding journey and set
            you for success on your first day.
          </p>
          <CustomButton
            onClick={hideWelcome}
            name="Start Onboarding"
            variant={'primary'}
            h="4.8rem"
            mW="22.2rem"
          />
        </div>
        <div className="design">
          <img src={Design} alt="design" />
        </div>
      </WelcomeRoot>
    </>
  );
};

const WelcomeRoot = styled.div`
  background: var(--white);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 13000;
  display: grid;
  place-content: center;

  .header {
    position: absolute;
    left: 82px;
    right: 82px;
    top: 68px;
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
      font-family: 'Karla';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      text-align: right;
      letter-spacing: -0.117188px;
      color: #333758;
    }
  }

  .welcome-content {
    max-width: 45rem;
    text-align: center;

    .welcome-content-greeting {
      font-family: 'Karla';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.117188px;
      color: #333758;
      margin-bottom: 2px;
    }
    h1 {
      font-family: 'Karla';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.117188px;
      color: #333758;
      margin-bottom: 16px;
    }
    .welcome-content-message {
      font-family: 'Karla';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.117188px;
      color: #85879b;
      margin-bottom: 35px;
    }
  }

  .design {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`;
