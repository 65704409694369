export const dummyData = [
  {
    leaveTypeId: { name: 'Annual Leave' },
    summary: {
      entitled: '20',
      spent: '5',
      requested: '3',
      balance: '15',
    },
    durationType: 'days',
  },
  {
    leaveName: 'Sick Leave',
    summary: {
      entitled: '20',
      spent: '5',
      requested: '3',
      balance: '15',
    },
    durationType: 'days',
  },
  {
    leaveName: 'Maternity Leave',
    summary: {
      entitled: '20',
      spent: '5',
      requested: '3',
      balance: '15',
    },
    durationType: 'weeks',
  },
  {
    leaveName: 'Vacation Leave',
    summary: {
      entitled: '20',
      spent: '5',
      requested: '3',
      balance: '15',
    },
    durationType: 'weeks',
  },
  {
    leaveName: 'Paternity Leave',
    summary: {
      entitled: '20',
      spent: '5',
      requested: '3',
      balance: '15',
    },
    durationType: 'weeks',
  },
  {
    leaveName: 'Mental Health Leave',
    summary: {
      entitled: '20',
      spent: '5',
      requested: '3',
      balance: '15',
    },
    durationType: 'weeks',
  },
];

export const columns = [
  {
    Header: 'Type',
    accessor: 'col1',
  },
  {
    Header: 'Description',
    accessor: 'col2',
  },
  {
    Header: 'Request Date(s)',
    accessor: 'col3',
  },
  {
    Header: 'Count As',
    accessor: 'col4',
  },
  {
    Header: 'Status',
    accessor: 'col5',
  },
];
