import { useNavigate } from 'react-router-dom';
import { Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

function Report({ heading, paragraph, link, testId }) {
  const navigate = useNavigate();

  return (
    <Stack
      gap={'16px'}
      height={'145px'}
      border={'solid 1px var(--grey-150)'}
      borderRadius={'4px'}
      minH={'180px'}
      data-testid={testId}
      cursor={'pointer'}
      _hover={{ border: 'solid 1px var(--primary)' }}
      onClick={() => navigate(`/admin/reports/${link}`)}
      p={'24px'}>
      <Heading fontSize={'16px'}>{heading}</Heading>
      <Text fontSize={'14px'} color={'var(--grey-600)'}>
        {paragraph}
      </Text>
    </Stack>
  );
}

export function General() {
  return (
    <Div>
      <Report
        heading={'Training Report'}
        paragraph={'This report shows the information of training programs.'}
        link={'training-report'}
        testId={'click-button1'}
      />

      <Report
        heading={'Hiring and Exit'}
        paragraph={'This report shows how many people were hired or terminated in a given period'}
        link={'hire-exits'}
        testId={'click-button2'}
      />

      <Report
        heading={'Age Report'}
        paragraph={'This report shows the distribution of age in your company.'}
        link={'ages'}
        testId={'click-button3'}
      />
      <Report
        heading={'Department Profile'}
        paragraph={
          'This report shows the distribution of employess across departments in your company'
        }
        link={'department-profile'}
        testId={'click-button4'}
      />
      <Report
        heading={'Headcount History'}
        paragraph={'This report shows the  number of people employed in a particular department.'}
        link={'head-count'}
        testId={'click-button5'}
      />
      <Report
        heading={'Asset Report'}
        paragraph={'This report shows the detailed overview of each asset.'}
        link={'assets'}
        testId={'click-button6'}
      />
    </Div>
  );
}

const Div = styled.div`
  background: #ffffff;
  display: grid;
  grid-template-columns: auto;
  padding: 24px;
  gap: 24px;
  border: solid 1px var(--grey-150);
  border-radius: 4px;
  justify-content: center;

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
