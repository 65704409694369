import * as React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { postRequest } from 'query/admin/auth/httpRequest';

import { BaseDropdown, CustomInput, DropFile } from '../../../../components';
import { APPLICANTS_FORM_BREAKPOINT, inputError } from '../../../../utils';
import { INPUT_TYPES } from '../../../admin/jobs/add-jobs/ApplicantsForm/helpers';

import { InputFieldList, useFieldArrayContext } from './InputFieldList';
import { InputLabel } from './InputLabel';

export function InputField(props) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const fieldData = useWatch({ control, name: props.path });
  const fieldValuePath = `${props.path}.value`;
  const textFieldProps = { ...register(fieldValuePath), placeholder: fieldData.name };
  const attachmentProps = { ...props, fieldValuePath, fieldData };
  const optionsProps = { options: fieldData.options, fieldValuePath };

  const isSection = fieldData.type === INPUT_TYPES.Section;
  const isTextarea = fieldData.type === INPUT_TYPES.TextArea;
  const fieldTypes = {
    [INPUT_TYPES.TextField]: <CustomInput {...textFieldProps} />,
    [INPUT_TYPES.TextArea]: <CustomInput type={'textarea'} {...textFieldProps} />,
    [INPUT_TYPES.DatePicker]: <CustomInput type={'date'} {...textFieldProps} />,
    [INPUT_TYPES.Hyperlink]: <CustomInput type={'link'} {...textFieldProps} />,
    [INPUT_TYPES.Dropdown]: <DropdownOptions {...optionsProps} />,
    [INPUT_TYPES.Attachment]: <AttachmentField {...attachmentProps} />,
    [INPUT_TYPES.RadioButton]: <RadioOptions {...optionsProps} />,
    [INPUT_TYPES.Checkbox]: <CheckboxOptions />,
    [INPUT_TYPES.Section]: <SectionView {...props} fieldData={fieldData} />,
  };

  return (
    <Grid
      gridColumn={
        isSection || isTextarea
          ? { base: 'span 1', [APPLICANTS_FORM_BREAKPOINT]: 'span 2' }
          : 'span 1'
      }
      templateColumns="100%"
      alignItems="flex-start"
      templateRows="repeat(2, max-content)">
      {!isSection ? (
        <InputLabel>
          {fieldData.name}
          {fieldData.required ? <span style={{ color: 'var(--red)' }}>*</span> : null}
        </InputLabel>
      ) : null}
      {fieldTypes[fieldData.type]}
      {inputError(errors, fieldValuePath)}
    </Grid>
  );
}

function DropdownOptions({ options = [], attrs, fieldValuePath }) {
  const optionList = options.map((option) => ({ value: option, label: option }));
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      render={({ field }) => (
        <BaseDropdown options={optionList} {...field} {...attrs} h="4.2rem" w="100%" />
      )}
      name={fieldValuePath}
    />
  );
}

const acceptableFileTypes = {
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/msword': [],
};

function AttachmentField(props) {
  const { control } = useFormContext();
  const { update } = useFieldArrayContext();
  const files = useWatch({ control, name: props.fieldValuePath }) || [];
  const isMultiple = props.fieldData.multiple;
  const isSingle = !isMultiple;
  const hasFile = files.length > 0;
  const [loading, setLoading] = React.useState(false);

  async function setFiles(func) {
    let acceptedFiles = func([]);
    setLoading(true);
    acceptedFiles = await uploadMultiple(acceptedFiles);
    setLoading(false);
    update(props.index, {
      ...props.fieldData,
      value: isMultiple ? [...files, ...acceptedFiles] : [acceptedFiles[0]],
    });
  }

  const url = process.env.REACT_APP_HRM_V3_API_BASE_URL;

  const formData = new FormData();

  async function uploadMultiple(arr) {
    const res = await Promise.all(
      arr.map(async (item) => {
        formData.append('file', item);
        return {
          name: item.name,
          url: (await postRequest(`${url}/upload/document`, formData)).data.data.url,
        };
      })
    );
    return res;
  }

  function removeFile(fileIndex) {
    update(props.index, {
      ...props.fieldData,
      value: files.filter((f, i) => i !== fileIndex),
    });
  }

  // if (loading) return <SpinnerComponent />;

  return (
    <Box>
      {!(isSingle && hasFile) ? (
        <DropFile setFiles={setFiles} accept={acceptableFileTypes} loading={loading} />
      ) : null}
      <Grid mt={'16px'} gap="8px" className="file-list">
        {files?.map((file, fileIndex) => (
          <Grid
            key={file?.name ?? file}
            templateColumns="auto max-content"
            alignItems="center"
            gap="16px"
            p="8px 16px"
            border="1px solid var(--grey-250)"
            borderRadius="4px">
            {file?.name ?? file}{' '}
            <span style={{ cursor: 'pointer' }}>
              <AiFillCloseCircle size="20" onClick={() => removeFile(fileIndex)} />
            </span>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const RadioLabel = styled.label`
  display: grid;
  grid: 1fr / auto-flow max-content;
  align-items: center;
  gap: 8px;
  margin-right: 3.2rem;
  margin-bottom: 4px;

  span {
    font-size: 1.4rem;
  }

  input[type='radio'],
  input[type='checkbox'] {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--grey-300);
    appearance: none;
    cursor: pointer;

    &:checked {
      border-color: var(--primary);
    }

    &:checked:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 8px;
      width: 8px;
      border-radius: inherit;
      background: var(--primary);
      transform: translate(-50%, -50%);
    }
  }

  &.checkbox {
    input[type='checkbox'] {
      border-radius: 3px;

      &:checked:before {
        border-radius: 1px;
        height: 9px;
        width: 9px;
        transform: translate(-51%, -51%);
      }
    }
  }
`;

function RadioOptions({ options = [], fieldValuePath }) {
  const { register } = useFormContext();
  return (
    <Flex py="8px" flexDirection={options.length > 2 ? 'column' : 'row'}>
      {options.map((option) => (
        <RadioLabel key={option}>
          <input
            type="radio"
            {...register(fieldValuePath)}
            value={option}
            className="radio-input"
          />{' '}
          <span>{option}</span>
        </RadioLabel>
      ))}
    </Flex>
  );
}

function CheckboxOptions({ options = [], fieldValuePath }) {
  const { register } = useFormContext();
  return (
    <Flex py="8px" flexDirection={options.length > 2 ? 'column' : 'row'} flexWrap="wrap">
      {options.map((option) => (
        <RadioLabel className="checkbox" key={option}>
          <input
            type="checkbox"
            {...register(fieldValuePath)}
            value={option}
            className="radio-input"
          />{' '}
          <span>{option}</span>
        </RadioLabel>
      ))}
    </Flex>
  );
}

function SectionView(props) {
  const { control } = useFormContext();
  const fieldData = useWatch({ control, name: props.path });

  return (
    <GridItem gridColumn={{ base: 'span 1', [APPLICANTS_FORM_BREAKPOINT]: 'span 2' }}>
      <SectionWrapper>
        <Box className="section-header">{fieldData.name}</Box>
        <Grid
          className={'section-body'}
          w={'100%'}
          templateColumns={{ base: '100%', [APPLICANTS_FORM_BREAKPOINT]: '1fr 1fr' }}
          alignContent={'flex-start'}
          gap={'32px 24px'}>
          <InputFieldList listName={`${props.path}.children`} />
        </Grid>
      </SectionWrapper>
    </GridItem>
  );
}

const SectionWrapper = styled.section`
  .section-header {
    padding: 16px;
    border-radius: 8px 8px 0 0;
    background: var(--grey-150);
    font-weight: 600;
    letter-spacing: -0.02em;
  }

  .section-body {
    padding: 16px;
    border: 1px solid var(--grey-150);
  }
`;
