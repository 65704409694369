import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { CustomButton } from 'components';

import { HeadingText, TextText } from './tab-views/onboard-components/Heading';

export const DoneOnboarding = () => {
  const navigate = useNavigate();
  const done = () => {
    navigate('/employee');
  };
  return (
    <>
      <DoneRoot>
        <Box
          background="#FAFCFF"
          border="1px solid #F2F2F2"
          borderRadius="4px"
          padding="97px 32px"
          w="464px">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M28.0003 53.0833C21.3478 53.0833 14.9678 50.4406 10.2637 45.7366C5.55969 41.0325 2.91699 34.6525 2.91699 28C2.91699 21.3475 5.55969 14.9674 10.2637 10.2634C14.9678 5.55934 21.3478 2.91664 28.0003 2.91664C31.9093 2.9037 35.7649 3.82361 39.247 5.59997C39.4523 5.70415 39.6351 5.84775 39.7849 6.02257C39.9347 6.19739 40.0486 6.4 40.1201 6.61883C40.1916 6.83767 40.2193 7.06845 40.2016 7.29799C40.1839 7.52753 40.1212 7.75134 40.017 7.95664C39.9128 8.16194 39.7692 8.34471 39.5944 8.49451C39.4196 8.64432 39.217 8.75822 38.9981 8.82972C38.7793 8.90122 38.5485 8.92892 38.319 8.91123C38.0894 8.89355 37.8656 8.83082 37.6603 8.72664C34.6734 7.18719 31.3606 6.38701 28.0003 6.39331C23.7325 6.39331 19.5606 7.65856 16.0117 10.0291C12.4628 12.3997 9.69644 15.7692 8.06217 19.7117C6.42789 23.6542 5.99911 27.9926 6.83002 32.1788C7.66093 36.3649 9.71423 40.2107 12.7304 43.2301C15.7465 46.2495 19.5901 48.307 23.7753 49.1424C27.9606 49.9778 32.2995 49.5537 36.2438 47.9237C40.188 46.2937 43.5605 43.531 45.9349 39.9847C48.3093 36.4384 49.579 32.2678 49.5837 28C49.5958 27.8057 49.5958 27.6109 49.5837 27.4166C49.5403 26.9525 49.6832 26.4902 49.9807 26.1314C50.2783 25.7725 50.7062 25.5466 51.1703 25.5033C51.6345 25.46 52.0968 25.6028 52.4556 25.9004C52.8144 26.1979 53.0403 26.6258 53.0837 27.09V28C53.0775 34.6506 50.4328 41.0271 45.7301 45.7297C41.0274 50.4324 34.6509 53.0771 28.0003 53.0833Z"
              fill="#1AA551"
            />
            <path
              d="M27.5801 35.9566C27.3533 35.96 27.1283 35.9161 26.9194 35.8277C26.7105 35.7393 26.5223 35.6084 26.3668 35.4433L15.0968 24.3833C14.769 24.0551 14.585 23.6103 14.585 23.1466C14.585 22.6828 14.769 22.238 15.0968 21.9099C15.4317 21.5863 15.8793 21.4054 16.3451 21.4054C16.8109 21.4054 17.2584 21.5863 17.5934 21.9099L27.6034 31.7799L50.1201 9.63659C50.4551 9.31296 50.9026 9.13208 51.3684 9.13208C51.8342 9.13208 52.2818 9.31296 52.6168 9.63659C52.9445 9.96471 53.1285 10.4095 53.1285 10.8733C53.1285 11.337 52.9445 11.7818 52.6168 12.1099L28.8634 35.4433C28.6964 35.6126 28.4962 35.7456 28.2754 35.8339C28.0545 35.9223 27.8178 35.964 27.5801 35.9566Z"
              fill="#1AA551"
            />
          </svg>
          <HeadingText headingText="You’re all set!" textAlign="center" mb="8px" />
          <TextText subText="Enjoy your first day at work" textAlign="center" mb="32px" />
          <CustomButton variant="primary" name="Back to Home Page" mw="400px" onClick={done} />
        </Box>
      </DoneRoot>
    </>
  );
};

const DoneRoot = styled.div`
  background: #fafcff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 15rem);
  z-index: 20;

  svg {
    margin: auto;
    margin-bottom: 18px;
  }
`;
