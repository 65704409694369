import { useMutation } from '@apollo/client';
import { useToastify } from 'hooks/useToastify';
import { LINE_MANAGE_UPDATE_LEAVE } from 'query';

export const useUpdateLeaveApproval = (refetch, refetchAll = () => {}) => {
  const { successToast, errorToast } = useToastify();
  const [updateLeave, { loading }] = useMutation(LINE_MANAGE_UPDATE_LEAVE);

  async function onUpdateLeave(leaveId, action, reason) {
    try {
      const res = await updateLeave({
        variables: {
          action,
          leaveId,
          reason,
        },
      });

      if (res.data.lineManagerUpdateLeave.status === 200) {
        successToast(res.data.lineManagerUpdateLeave.message);
        refetch();
        refetchAll();
      }
    } catch (error) {
      errorToast('failed updating leave request');
    }
  }
  return {
    loading,
    onUpdateLeave,
  };
};
