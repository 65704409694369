import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Grid, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { BaseButton } from '../BaseButton';
import { BaseFile } from '../BaseFile';
import { BaseDropdown, BaseInput } from '..';

export const BaseForm = (props) => {
  const { children, mainState, onSubmit, className, formOptions, setEdit, edit, ...rest } = props;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    ...formOptions,
    defaultValues: mainState,
    values: mainState,
    resolver: yupResolver(props?.schema),
  });

  const getInputFields = (formData) => {
    const InputFields = Array?.isArray(formData)
      ? formData?.map((input) => {
          return (
            <Box className="fields-wrapper" key={input?.name || input?.label}>
              {props.leftLabel ? (
                <Box>
                  <Text as="label" className="label" htmlFor={input?.name}>
                    {input?.label}
                  </Text>
                  {input?.description ? (
                    <Text className="description"> {input?.description} </Text>
                  ) : (
                    ''
                  )}
                </Box>
              ) : null}
              {input.newSection ? (
                <Box className={`${input.newSection ? 'section-head' : ''}`}>
                  <Text as="label" className="label" htmlFor={input?.name}>
                    {input.newSection}
                  </Text>
                  {input.newSection ? <hr className="line" /> : ''}
                </Box>
              ) : null}
              {input?.multipleInputs?.length ? (
                <Grid
                  className="multiple-inputs"
                  gridTemplateColumns={`repeat(${input?.multipleInputs?.length}, 1fr)`}>
                  {input?.multipleInputs?.map((el) => (
                    <Box key={el?.name} className={`${el.hidden ? 'hide' : ''}`}>
                      <BaseInput
                        type={el?.type}
                        id={el?.name}
                        label={el.label}
                        data-testid={el?.name}
                        error={errors?.[el?.name]?.message}
                        {...register(el?.name, { required: input?.isRequired })}
                        name={el?.name}
                        placeholder={el?.placeholder}
                        isRequired={input?.isRequired}
                        disabled={edit}
                      />
                    </Box>
                  ))}
                </Grid>
              ) : (
                <>
                  {![
                    'button',
                    'dropdown',
                    'checkbox',
                    'tel',
                    'file',
                    'toggle',
                    'navigate-text',
                    'text-editor',
                    'otp',
                    'specification',
                    'creatable',
                    'form-buttons',
                  ]?.includes(input?.type) ? (
                    <BaseInput
                      label={props.leftLabel ? '' : input?.label}
                      type={input?.type}
                      id={input?.name}
                      rightIcon={input?.icon}
                      data-testid={input?.name}
                      error={errors?.[input?.name]?.message}
                      {...register(input?.name, { required: input?.isRequired })}
                      name={input?.name}
                      placeholder={input?.placeholder}
                      isRequired={input?.isRequired}
                      isDisabled={!edit}
                    />
                  ) : null}
                  {input?.type === 'dropdown' ? (
                    <Box>
                      <Controller
                        name={input?.name} // for the gender field
                        control={control} // obtained from the useForm hook
                        render={({ field: { onChange, value } }) => {
                          return (
                            <BaseDropdown
                              label={props.leftLabel ? '' : input?.label}
                              data-testid={input?.name}
                              options={input?.options}
                              withImage={input?.withImage}
                              w={'100%'}
                              name={input?.name} // for the gender field
                              creatable={input?.creatable}
                              control={control}
                              onChange={(e) =>
                                onChange({
                                  target: {
                                    name: input?.name,
                                    value: e.value,
                                  },
                                })
                              }
                              value={value}
                              error={errors?.[input?.name]?.message}
                              placeholder={input?.placeholder}
                              isDisabled={edit}
                            />
                          );
                        }}
                      />
                    </Box>
                  ) : null}
                  {input?.type === 'file' ? (
                    <Controller
                      name={input?.name}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <BaseFile
                          name={input?.name}
                          edit={edit}
                          onChange={onChange}
                          value={value || ''}
                        />
                      )}
                    />
                  ) : null}
                </>
              )}
            </Box>
          );
        })
      : null;
    return InputFields;
  };

  return (
    <form className={'base-form'} {...rest} onSubmit={handleSubmit(onSubmit)} data-testid={'form'}>
      <Grid pos={'relative'} zIndex={'10'} className="form__wrapper" gap={'20px'}>
        <Grid gap={'20px'} className={`${className ? className : ''}`}>
          {props.formName ? (
            <Box className="heading" alt={props.formDesc}>
              <Grid className="desktop" gap={'1.2em'}>
                <p> {props.formName}</p>
                {props.formDesc ? <p className="desc">{props.formDesc}</p> : ''}
              </Grid>
              {!edit ? (
                <BaseButton type={'button'} onClick={() => setEdit(true)}>
                  Edit Your Profile
                </BaseButton>
              ) : null}
            </Box>
          ) : null}
          {props.sections
            ? props.formData?.map((input, i) =>
                Array?.isArray(input.section) ? (
                  <Grid gap={'2.4rem'} key={input.section}>
                    {getInputFields(input.section)}
                    {i === props.formData?.length - 1 ? children : ''}
                  </Grid>
                ) : null
              )
            : getInputFields(props.formData)}
        </Grid>
        {props.sections ? '' : children}
      </Grid>
    </form>
  );
};
