import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { InputField } from './InputField';

const fieldArrayContext = React.createContext();

export function useFieldArrayContext() {
  return React.useContext(fieldArrayContext);
}

export function InputFieldList(props) {
  const { control } = useFormContext();
  const arrayMethods = useFieldArray({ control, name: props.listName });
  const { fields } = arrayMethods;
  return (
    <fieldArrayContext.Provider value={arrayMethods}>
      {fields.map((field, index) => (
        <InputField key={field.id} index={index} path={`${props.listName}.${index}`} {...field} />
      ))}
    </fieldArrayContext.Provider>
  );
}
