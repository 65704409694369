export const columns = [
  {
    Header: 'Employee Name',
    accessor: 'employeeName',
  },
  {
    Header: 'Employee ID',
    accessor: 'employeeId',
  },
  { Header: 'Date', accessor: 'date' },
  {
    Header: 'Time',
    accessor: 'time',
  },
  {
    Header: 'In',
    accessor: 'in',
  },
  {
    Header: 'Out',
    accessor: 'out',
  },
];
export const tData = [
  {
    _id: 1,
    firstName: 'Gawusu',
    lastName: 'Razak',
    employeeId: 'ENY012',
    time: '8 Hours 46 Minutes',
    timeIn: '9:34 AM',
    timeOut: '5:34 PM',
  },
  {
    _id: 2,
    firstName: 'Jason',
    lastName: 'Appiatu',
    employeeId: 'ENY024',
    time: '8 Hours 46 Minutes',
    timeIn: '9:34 AM',
    timeOut: '5:34 PM',
  },
];
