import React from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Heading } from '@chakra-ui/react';
import { APPLICANTS_FORM_BREAKPOINT } from 'utils';

import { SpanDouble, SpinnerComponent } from 'components';

import { FETCH_PIPELINE } from '../../../../../../query/admin/jobs';

import PipelineCard from './PipelineCard';

function HiringPipeline() {
  // const [stages] = React.useState(dummyPipeline || []);
  const { data, loading } = useQuery(FETCH_PIPELINE);
  const stages = !loading ? [...data.fetchStages.data]?.sort((a, b) => a.rank - b.rank) : [];
  return (
    <>
      <Heading fontSize="2rem" lineHeight="2.3rem" mt="2.4rem" mb="3.2rem">
        Attach Score Card to Pipeline
      </Heading>
      {loading && <SpinnerComponent h="40rem" />}
      {!loading && (
        <Grid
          templateColumns={{ base: '1fr', [APPLICANTS_FORM_BREAKPOINT]: '1fr 1fr' }}
          autoRows="max-content"
          gap="2.4rem">
          {stages.map((stage) => (
            <SpanDouble key={stage.id}>
              <PipelineCard stage={stage} />
            </SpanDouble>
          ))}
        </Grid>
      )}
    </>
  );
}

// const dummyPipeline = [
//   { title: 'New Applied', id: 1, colorCode: '#3D7471' },
//   { title: 'Screening', id: 2, colorCode: '#92606f' },
//   { title: 'Assessment', id: 3, colorCode: '#FAA515' },
//   { title: 'Interview', id: 4, colorCode: '#EDA269' },
//   { title: 'Hired', id: 5, colorCode: '#589f58' },
// ];

export { HiringPipeline };
