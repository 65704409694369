import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastify } from 'hooks/useToastify';
import { BaseLayout } from 'layouts';
import { FilesPreview } from 'pages/admin/assessment/component/filesDisplay';
import { CREATE_EMPLOYEE_TRAINING, FETCH_EMPLOYEE_TRAINING } from 'query';
import { getItem } from 'utils';

import {
  BaseDropdown,
  BaseInput,
  CheckboxWrapper,
  CustomButton,
  CustomCheckbox,
  ModalBackButton,
} from 'components';
import { useTrainingComponent } from 'components/admin/training/hooks';

import calendar from '../../../../assets/icons/Calendar copy.svg';

import { AttachFile } from './component';
import { options, toTrack } from './data';
import { trainingSchema } from './data';

const isTesting = process.env.NODE_ENV === 'test';

const defaultValues = isTesting
  ? {
      name: 'Flutter dev',
      description: 'learn mobile development',
      attachedLink: 'http://somelink',
      details: ['cost'],
      schedule: { startDate: '02-02-2023', endDate: '03-02-2023' },
      repeatProgram: {
        isRepeated: 'repeat',
        repeatType: { label: 'Monthly', value: 'monthly' },
        repeatIn: '5',
      },
    }
  : {};

export const CreateTraining = () => {
  const [files, setFiles] = React.useState([]);
  const { getDateDiffInSeconds } = useTrainingComponent();

  const user = getItem('hrm_user');

  const { errorToast, successToast } = useToastify();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(trainingSchema) });

  const fieldName = 'details';

  const [createTraining, { loading }] = useMutation(CREATE_EMPLOYEE_TRAINING);

  const navigate = useNavigate();

  async function onSubmit(data) {
    const details = data?.details.filter((det) => {
      return det;
    });
    const id = user?._id;

    if (!getDateDiffInSeconds(data?.schedule?.startDate, data?.schedule?.endDate)) {
      errorToast('Due date should be greater than the start date');
      return;
    }

    const repeatType = data?.repeatProgram?.repeatType?.value;
    const isRepeated = data?.repeatProgram?.isRepeated === 'repeat' ? true : false;
    const repeatIn = Number(data?.repeatProgram?.repeatIn);

    const toSend = {
      ...data,
      details: details,
      repeatProgram: { isRepeated: isRepeated, repeatType: repeatType, repeatIn: repeatIn },
      employeeId: [id],
      isShared: false,
    };

    const variables = { data: toSend };

    try {
      const res = await createTraining({
        variables: files?.length !== 0 ? { ...variables, file: files[0] } : { ...variables },
        awaitRefetchQueries: true,
        refetchQueries: {
          query: FETCH_EMPLOYEE_TRAINING,
          variables: {
            filter: {
              status: 'upcoming',
            },
          },
        },
      });
      const response = res?.data?.createTraining;
      if (response?.status === 201) {
        const message = response?.message;
        successToast(message);
        reset();
        navigate('/employee/training');
      } else if (response?.status === 409) {
        const errMessage = response?.message;
        errorToast(errMessage);
      }
    } catch (error) {
      errorToast('Error Occured');
    } finally {
      reset({});
    }
  }

  const shouldDisable = watch('repeatProgram.isRepeated');
  const disabled = shouldDisable === 'repeat' ? false : true;

  const filterFiles = (filename) => {
    setFiles((prev) => prev?.filter((file) => file?.name !== filename));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="create-training">
      <BaseLayout>
        <>
          <Flex gap="2.4rem" alignItems={'center'}>
            <ModalBackButton
              data-testid="modal-back-btn"
              onClick={() => navigate('/employee/training')}
              type={'button'}
            />
            <p>Create a new training program</p>
          </Flex>

          <CustomButton
            loading={loading}
            name="Create"
            type="submit"
            variant="primary"
            mW="150px"
            h="48px"
          />
        </>
        <Content>
          <div className="content_item">
            <BaseInput
              labelSize={'12px'}
              {...register(`name`)}
              label="Name"
              isRequired
              placeholder={'Training Name'}
              fontW={'600'}
            />
            <ErrorMessage>{errors?.name?.message || null}</ErrorMessage>
            <BaseInput
              type="textarea"
              rows="4"
              label="Description"
              isRequired
              placeholder={'Description'}
              {...register(`description`)}
              labelSize={'12px'}
              fontW={'600'}
            />
            <ErrorMessage>{errors?.description?.message || null}</ErrorMessage>
            <Grid gap="0.8rem">
              <AttachFile type="file" setFiles={setFiles} />
              <FilesPreview files={files} addclose={true} removeFile={filterFiles} />
              <AttachFile type="link" register={register} />
            </Grid>
            <div className="checkboxes">
              <p className="heading">
                Select the training details you want to track for this program
              </p>
              {toTrack?.map(({ value, label }, index) => {
                return (
                  <CheckboxWrapper key={label}>
                    <CustomCheckbox
                      value={value}
                      label={label}
                      {...register(`${fieldName}.${index}`)}
                    />
                  </CheckboxWrapper>
                );
              })}
            </div>
            <div className="dates">
              <p className="heading">Schedule</p>
              <div className="dates__content">
                <Box width="322px">
                  <BaseInput
                    type="date"
                    label="Start Date"
                    rightIcon={calendar}
                    isRequired
                    {...register(`schedule.startDate`)}
                    fontSize={'12px'}
                    labelSize={'12px'}
                  />
                  <ErrorMessage>{errors?.schedule?.startDate?.message || null}</ErrorMessage>
                </Box>

                <Box width="322px">
                  <BaseInput
                    type="date"
                    label="Due Date"
                    rightIcon={calendar}
                    isRequired
                    {...register(`schedule.endDate`)}
                    fontSize={'12px'}
                    labelSize={'12px'}
                  />
                  <ErrorMessage>{errors?.schedule?.endDate?.message || null}</ErrorMessage>
                </Box>
              </div>
            </div>
            <div className="does__repeat">
              <p className="heading">Does this program repeat?</p>

              <CustomCheckbox
                label="Does repeat"
                value="repeat"
                showLabel={true}
                {...register(`repeatProgram.isRepeated`)}
              />
              <Flex className="does__repeat--content">
                <p>Repeat</p>
                <BaseInput
                  type="number"
                  {...register(`repeatProgram.repeatIn`)}
                  maxW={'120px'}
                  height="40px"
                  disabled={disabled}
                />

                <Controller
                  defaultValue=""
                  control={control}
                  name="repeatProgram.repeatType"
                  render={({ field }) => (
                    <BaseDropdown
                      {...field}
                      options={options}
                      w="120px"
                      h="40px"
                      border={'1px solid #F2F2F2'}
                      isDisabled={disabled}
                    />
                  )}
                />
              </Flex>
            </div>
          </div>
        </Content>
      </BaseLayout>
    </form>
  );
};

const ErrorMessage = styled.p`
  color: var(--error);
  font-size: 1.2rem;
  text-transform: capitalize;
`;

const Content = styled.div`
  .content_item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin: auto;
    max-width: 862px;
    border: 1px solid #3e7eee;
    border-radius: 4px;
    padding: 16px 24px;
  }

  .heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #23273b;
    margin-bottom: 1.6rem;
  }

  .checkboxes {
    display: grid;
    gap: 1.4rem;
  }

  .dates {
    &__content {
      display: grid;
      gap: 1.6rem;
      margin-top: 1.6rem;
      @media (min-width: 500px) {
        display: flex;
        align-items: center;
      }
    }
  }
  .does__repeat {
    &--content {
      display: grid;
      gap: 1.6rem;
      max-width: max-content;
      margin-top: 1.6rem;
      @media (min-width: 500px) {
        display: flex;
        align-items: center;
      }
    }
  }

  .footer {
    display: grid;
    gap: 1.6rem;
    alignitems: center;
    @media (min-width: 500px) {
      display: flex;
      align-items: center;
    }
  }
`;
