import React, { createContext, useContext, useState } from 'react';

const GoalDetailsContext = createContext();

function GoalDetailsProvider(props) {
  const [list, setList] = useState(props.initialValue);

  return (
    <GoalDetailsContext.Provider value={{ list, setList }}>
      {props.children}
    </GoalDetailsContext.Provider>
  );
}

function useGoalDetailsContext() {
  return useContext(GoalDetailsContext);
}

export { GoalDetailsContext, GoalDetailsProvider, useGoalDetailsContext };
