import React from 'react';
import { VscKebabVertical } from 'react-icons/vsc';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatDate } from 'utils';

import { MenuDropDown } from '../shared';

import { ModalBackButton } from './ModalButton';

const FullModal = (props) => {
  const {
    showModal,
    setShowModal,
    title,
    children,
    dropDownActions,
    actionOnData,
    addedOn,
    mt,
    padding,
  } = props;
  return (
    <>
      {showModal && (
        <Wrapper
          className="full-modal-wrapper"
          style={{ marginTop: mt ? `calc(${mt} - 3.2rem)` : '', ...props.style }}>
          <RightOverlay className="right-modal-overlay" padding={padding || '0 3.2rem'}>
            {!props.noHeader && (
              <Header backgroundColor="#fff">
                <Grid
                  className="content"
                  alignItems="center"
                  gridTemplateColumns="max-content 1fr"
                  w="100%">
                  <ModalBackButton
                    data-testid="full-modal-back-btn"
                    onClick={() => setShowModal()}
                  />
                  <Text className="username">{title}</Text>
                </Grid>

                <div className="close">
                  {props.dontShowActions ? null : (
                    <>
                      <Text>Added on {formatDate(addedOn)}</Text>
                      <Box className="btn__wrapper">
                        <MenuDropDown
                          variant={'update-employee-dropdown'}
                          actionOnData={actionOnData}
                          dropDownActions={dropDownActions}>
                          <Box mr={'1rem'}>
                            <VscKebabVertical size={14} />
                          </Box>
                          {'More Action'}
                        </MenuDropDown>
                      </Box>
                    </>
                  )}
                </div>
              </Header>
            )}
            <Box className="modal__content">{children}</Box>
          </RightOverlay>
        </Wrapper>
      )}
    </>
  );
};

const RightOverlay = styled(Box)`
  max-width: 100%;
  width: 100%;
  height: 100%;
  background: var(--white);
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  @media (min-width: 920px) {
    //max-width: 115rem;
    width: 100%;
  }

  .modal__content {
    height: 100%;
    overflow-x: auto;
    padding-bottom: 15rem;
    position: relative;

    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    @media (min-width: 920px) {
      max-width: 115rem;
      //margin-inline: auto;
    }
  }
`;
const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1200;
  padding: 3.2rem 1.9rem 0 2.4rem;
  @media (min-width: 768px) {
    width: calc(100% - 25rem);
  }
`;

const Header = styled(Box)`
  display: grid;
  grid: 1fr / 1fr;
  width: 100%;
  justify-content: space-between;
  gap: 1.4rem;
  align-items: center;
  background-color: transparent;
  color: white;
  position: sticky;
  top: 0;
  height: 14rem;
  padding-bottom: 1.4rem;
  z-index: 5;

  &.without-button {
    padding: 4rem 3.2rem;
  }

  @media (min-width: 880px) {
    height: 8rem;
    grid: 1fr / 1fr max-content;
    gap: 2.4rem;
    padding-bottom: 0;
  }

  p {
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: -0.05em;
    white-space: break-spaces;
    color: #364469;
    font-weight: 600;
    margin-left: 2.4rem;
  }

  .content {
    .username {
      text-align: right;
      text-transform: capitalize;
    }

    @media (min-width: 880px) {
      .username {
        text-align: left;
      }
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 880px) {
      justify-content: flex-end;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #83889d;
    }

    .btn__wrapper {
      margin-left: 1.7rem;
    }
  }
`;

export { FullModal };
