import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { inputError } from 'utils';

import Calendar from '../../../../assets/icons/dateCalendar.svg';
import { BaseInput, CustomButton } from '../../../shared';

import { TaskHook } from './hook';
import { Wrapper } from './styles';
import { RecurringDays, RecurringPeriod, TaskCategories, ToggleSwitch } from './taskComponents';

const testState = {
  priority: '',
  repeatInterval: '',
  completed: false,
};

const AddTask = ({ closeModal, defaultdata, editing }) => {
  const initialState = {
    priority: '',
    repeatInterval: '',
    completed: false,
  };
  const mainState = process.env.NODE_ENV === 'test' ? testState : initialState;
  const [state, setState] = useState(mainState);
  const [showRecurring, setShowRecurring] = useState(false);
  const [taskCategory, setTaskCat] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [recurringDays, setRecurringDays] = useState([]);

  const { saveTask, loading, updating } = TaskHook({
    editing,
    state,
    closeModal,
    id: defaultdata?._id,
    recurringDays,
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: {} });

  const formatIncomingDate = (dateVal) => {
    return dateVal?.substr(0, 10);
  };
  React.useEffect(() => {
    setTaskCat(defaultdata.priority);
    setSelectedPeriod(defaultdata.repeatInterval);
    const daysToRepeat = defaultdata?.daysToRepeat;
    const days = editing ? [...daysToRepeat] : [];
    setRecurringDays([...new Set(days)]);
    const date = formatIncomingDate(defaultdata?.date);

    reset({ ...defaultdata, date: date });
  }, [defaultdata]);

  const checkIfRecurring = (e) => {
    const { checked } = e.target;
    setShowRecurring(checked);
  };
  const handleTaskCat = (e) => {
    setTaskCat(e);
    setState((prevState) => ({ ...prevState, priority: e }));
  };
  const handleSelectedPeriod = (period) => {
    setSelectedPeriod(period);
    setState((prevState) => ({ ...prevState, repeatInterval: period }));
  };
  const CheckHandler = (e) => {
    const val = e.target.value;
    setRecurringDays((prev) =>
      recurringDays.includes(val) ? prev.filter((cur) => cur !== val) : [...prev, val]
    );
    // setState((prevState) => ({ ...prevState, daysToRepeat: recurringDays }));
  };

  return (
    <Wrapper onSubmit={handleSubmit(saveTask)} data-testid={'task'}>
      <Box width="100%" mt="2rem">
        <BaseInput
          data-testid={'title'}
          placeholder={'Add Title'}
          type="text"
          name={'title'}
          {...register('title', { required: 'Add a task title' })}
        />
        {inputError(errors, 'title')}
      </Box>
      <TaskCategories taskCat={taskCategory} handleTaskCat={handleTaskCat} />
      <Box width="100%" mt="1rem">
        <BaseInput
          data-testid={'date'}
          label="Date"
          type="date"
          min={new Date().toISOString().split('T')[0]}
          right={'1rem'}
          color={'rgba(0,0,0,0.5)'}
          rightIcon={Calendar}
          name={'date'}
          {...register('date', { required: 'Add a task date' })}
        />
        {inputError(errors, 'date')}
      </Box>
      <Grid width="100%" templateColumns={'repeat(2, 1fr)'} gridGap={'1.6rem'}>
        <Box>
          <BaseInput
            data-testid={'startTime'}
            type="time"
            color={'rgba(0,0,0,0.5)'}
            name={'startTime'}
            {...register('startTime', { required: 'Add a start time' })}
          />
          {inputError(errors, 'startTime')}
        </Box>
        <Box>
          <BaseInput
            data-testid={'endTime'}
            type="time"
            color={'rgba(0,0,0,0.5)'}
            name={'endTime'}
            {...register('endTime', { required: 'Add an end time' })}
          />
          {inputError(errors, 'endTime')}
        </Box>
      </Grid>
      <Box>
        <BaseInput
          className="text-area"
          label="Description"
          data-testid={'description'}
          type="textarea"
          color={'rgba(0,0,0,0.5)'}
          rows={'3'}
          id={'description'}
          name={'description'}
          {...register('description', { required: 'Add a task description' })}
        />
        {inputError(errors, 'description')}
      </Box>
      <Flex>
        <ToggleSwitch label="Recurring Activity" onChange={checkIfRecurring} id="toggle" />
      </Flex>
      <RecurringDays
        isRecurring={showRecurring}
        recurringDays={recurringDays}
        CheckHandler={CheckHandler}
      />
      <RecurringPeriod
        isRecurring={true}
        selectedPeriod={selectedPeriod}
        handleSelectedPeriod={handleSelectedPeriod}
      />
      <Flex gap="0.8rem" justifyContent={'flex-end'} mt={'1.6rem'}>
        <CustomButton
          h={'4.8rem'}
          mW={'13.2rem'}
          variant={'secondary'}
          name={'Cancel'}
          type="button"
          handleButtonClick={closeModal}
        />
        <CustomButton
          h={'4.8rem'}
          mW={'13.2rem'}
          variant={'primary'}
          name={editing ? 'Edit' : 'Create'}
          type="submit"
          loading={loading || updating}
        />
      </Flex>
    </Wrapper>
  );
};

export { AddTask };
