import { gql } from '@apollo/client';

export const FETCH_EMPLOYEE_DETAILS = gql`
  query fetchUsersFiltered($filters: UserFilters, $page: Int) {
    fetchUsersFiltered(filters: $filters, page: $page) {
      status
      message
      data {
        firstName
        lastName
        email
        status
        staffId
        imageUrl
        isDefaultAdmin
        isLineManager
        lineManagerStatus
        isDisabled
        location
        bloodGroup
        bloodType
        disabilityType
        notableAilment
        gender
        team {
          _id
          name
        }
        companyId
        dob
        workingFormat
        maritalStatus
        phoneNumber
        workLocation {
          address
          state
          city
        }
        emergencyContact {
          fullName
          phoneNumber
          relationship
        }
        employmentInfo {
          employmentType
          hireDate
          monthlyPayRate
          compensationTypes
          hoursPerWeek
        }
        department {
          name
        }
        address {
          address
          state
          country
          district
        }
        bankAccount {
          accountName
          accountNo
          accountType
          bankName
          paymentType
          pensionAccountNo
          pensionFundAdministrator
        }
        designation {
          name
        }
      }
    }
  }
`;

export const EDIT_EMPLOYEE_DETAILS = gql`
  mutation editEmployeeDetails($data: EditUserInput!) {
    editEmployeeDetails(data: $data) {
      status
      message
      data {
        _id
        firstName
        lastName
        middleName
        email
        phoneNumber
        imageUrl
        status
        isDefaultAdmin
        companyId
        gender
        dob
      }
    }
  }
`;

export const FETCH_EMPLOYEE_DOCUMENTS = gql`
  query employeeFetchDocuments {
    employeeFetchDocuments {
      status
      message
      data {
        employee {
          firstName
          lastName
          imageUrl
        }
        _id
        createdAt
        type
        name
        url
        size
      }
    }
  }
`;

export const EDIT_EMPLOYEE_PROFILE_PIC = gql`
  mutation uploadProfilePic($file: Upload!) {
    uploadProfilePic(file: $file) {
      status
      message
    }
  }
`;

export const UPLOAD_EMPLOYEE_DOCUMENT = gql`
  mutation uploadEmployeeDocument($document: EmployeeDocumentInput!) {
    uploadEmployeeDocument(document: $document) {
      status
      message
      data {
        url
      }
    }
  }
`;
export const DELETE_EMPLOYEE_DOCUMENT = gql`
  mutation deleteEmployeeDocument($data: DeleteEmployeeDocument!) {
    deleteEmployeeDocument(data: $data) {
      message
      status
      data {
        _id
        type
        name
        url
      }
    }
  }
`;
export const FETCH_EMPLOYEE_EVENT = gql`
  query FetchEmployeeEvents {
    fetchEmployeeEvents {
      status
      message
      data {
        birthdays {
          _id
          firstName
          imageUrl
          email
          lastName
          dob
        }
        anniversaries {
          _id
          imageUrl
          firstName
          lastName
          email
          employmentInfo {
            hireDate
          }
        }
      }
    }
  }
`;
export const FETCH_NOTIFICATIONS = gql`
  query fetchNotifications($filter: NotificationFilter!) {
    fetchNotifications(filter: $filter) {
      status
      message
      data {
        _id
        data {
          message
          action_id
          category
          sender {
            _id
            firstName
            lastName
            imageUrl
          }
        }
        type
        status
        readAt
        createdAt
      }
    }
  }
`;
export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($notificationId: [ObjectID!]!, $data: NotificationInputData!) {
    updateNotification(notificationId: $notificationId, data: $data) {
      status
      message
      data {
        _id
        data {
          message
        }
        type
        status
        readAt
        createdAt
      }
    }
  }
`;

export const SEND_BIRTHDAY_WISH = gql`
  mutation sendBirthdayWish($data: BirthdayInputData!) {
    sendBirthdayWish(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const EDIT_QUERY = gql`
  mutation editQuery($filters: QueryFilters!, $data: QueryInput!) {
    editQuery(filters: $filters, data: $data) {
      status
      message
    }
  }
`;

export const FETCH_USER_QUERIES = gql`
  query fetchUserQueries($filter: QueryFilters, $page: Int) {
    fetchUserQueries(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      totalCount
      pageCount
      data {
        _id
        subject
        content
        response
        createdAt
        issuer {
          firstName
          lastName
        }
        recepient {
          firstName
          lastName
        }
        status
      }
    }
  }
`;

export const FETCH_QUERIES = gql`
  query fetchQueries($filter: QueryFilters, $page: Int) {
    fetchQueries(filter: $filter, page: $page) {
      status
      message
      pageCount
      data {
        _id
        subject
        content
        response
        createdAt
        issuer {
          _id
          firstName
          lastName
        }
        recepient {
          _id
          firstName
          lastName
        }
        status
      }
    }
  }
`;
