export const APPLICANTS_FORM_BREAKPOINT = 'slg';
export const DND_TYPES = {
  ApplicantInputOption: 'Applicant Input Option',
  ScoreCard: 'Score Card',
};

export const JOB_STATUS = {
  Active: 'active',
  Closed: 'closed',
  Draft: 'draft',
};

export const PIPELINE_COLORS = {
  1: '#FA4A4A',
  2: '#00BB9E',
  3: '#FF9F2D',
  4: '#9747FF',
  5: '#3D7471',
};
export const isTesting = process.env.NODE_ENV === 'test';
