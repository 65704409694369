import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import { RUN_APPLICATION_FILTER_TRACKER } from 'query/admin/candidates';

import {
  BaseDropdown,
  BaseInput,
  CheckboxWrapper,
  CustomButton,
  CustomCheckbox,
  CustomRadio,
} from 'components';

import calendar from '../../../../assets/icons/Calendar copy.svg';
import { useToastify } from '../../../../hooks/useToastify';

import { getFilters, getObj, includeLabel, removeFields } from './helpers';

function RunFilter({ closeModal, filters, jobId, refetchFns }) {
  const [formValues, setFormValues] = useState([]);
  const { register, handleSubmit, control } = useForm({
    defaultValues: getObj(filters),
  });

  useEffect(() => {
    setFormValues(filters.filter((filter) => filter.value !== null));
  }, []);

  const [runApplicationFilter, { loading }] = useMutation(RUN_APPLICATION_FILTER_TRACKER, {});

  function handleCheckBoxChange(name) {
    const filt = filters?.find((el) => el.name === name);
    if (formValues.find((el) => el.name === name) === undefined) {
      setFormValues([...formValues, filt]);
    } else {
      const filterData = formValues.filter((el) => el.name !== name);
      setFormValues(filterData);
    }
  }

  const { successToast, errorToast } = useToastify();
  async function onhandleSubmit(data) {
    const res = await runApplicationFilter({
      variables: {
        data: {
          jobId,
          filters: getFilters(removeFields(data, formValues), filters),
        },
      },
    });

    await Promise.all(refetchFns.map(async (fn) => fn()));

    if (res?.data?.runApplicationFilterTracker?.status === 200) {
      closeModal();
      successToast(res?.data?.runApplicationFilterTracker?.message);
    } else {
      errorToast(res?.data?.runApplicationFilterTracker?.message);
    }
  }

  return (
    <Stack p={'24px'}>
      <form onSubmit={handleSubmit(onhandleSubmit)}>
        <Stack gap={'24px'}>
          <Stack p={'24px'} border={'solid 1px var(--grey-100)'} borderRadius={'4px'}>
            <Heading>Run ATS Filter</Heading>

            <Grid gridTemplateColumns={'1fr 1fr 1fr'} gap={'20px'} p={'34px 0'}>
              {filters.map((el, index) => {
                return (
                  <Flex key={`${el.name}${index}`} gap={'10px'}>
                    <CheckboxWrapper>
                      <CustomCheckbox
                        variant={'green-checkbox'}
                        id={el.name}
                        label={el.name}
                        fS={'8px'}
                        checked={formValues?.find((f) => f.name === el.name)}
                        onChange={() => handleCheckBoxChange(el.name)}
                      />
                    </CheckboxWrapper>
                  </Flex>
                );
              })}
            </Grid>

            <Stack gap={'32px'} display={'grid'} gridTemplateColumns={'1fr'}>
              {formValues.map((el) => {
                if (el.type === 'Date Picker') {
                  return (
                    <Stack key={el.name}>
                      <Text fontSize={'12px'} fontWeight={'600'}>
                        {el.name}
                      </Text>
                      <Flex gap={'16px'}>
                        <BaseInput
                          label={'From'}
                          labelSize={'12px'}
                          type={'date'}
                          rightIcon={calendar}
                          {...register(`${el.name}.from`, { required: true })}
                        />
                        <BaseInput
                          label={'To'}
                          labelSize={'12px'}
                          type={'date'}
                          rightIcon={calendar}
                          {...register(`${el.name}.to`, { required: true })}
                        />
                      </Flex>
                    </Stack>
                  );
                } else if (el.type === 'Radio Button') {
                  return (
                    <Flex key={el.name}>
                      <CustomRadio
                        {...register(el.name, { required: true })}
                        options={includeLabel(el.options)}
                        showLabel={true}
                      />
                    </Flex>
                  );
                } else if (el.type === 'Text Field') {
                  return (
                    <BaseInput
                      key={el.name}
                      type={'text'}
                      labelSize={'12px'}
                      fontW={'600'}
                      label={el.name}
                      {...register(el.name, { required: true })}
                    />
                  );
                } else if (el.type === 'Dropdown') {
                  return (
                    <Controller
                      key={el.name}
                      defaultValue={el.value}
                      control={control}
                      name={el.name}
                      render={({ field }) => (
                        <BaseDropdown
                          label={el.name}
                          {...field}
                          placeholder={el.name}
                          options={includeLabel(el.options)}
                          w={'100%'}
                        />
                      )}
                    />
                  );
                }
              })}
            </Stack>
          </Stack>
          <Flex gap={'8px'} justifyContent={'flex-end'}>
            <CustomButton
              name={'Cancel'}
              h={'4.8rem'}
              type={'button'}
              mW={'11.8rem'}
              variant={'black--outline'}
              onClick={closeModal}
            />
            <CustomButton
              name={'Run Filter'}
              h={'4.8rem'}
              type={'submit'}
              loading={loading}
              mW={'11.8rem'}
              variant={'black'}
            />
          </Flex>
        </Stack>
      </form>
    </Stack>
  );
}

export default RunFilter;
