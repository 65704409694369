import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastify } from 'hooks/useToastify';
import { EDIT_ADMIN, INVITE_ADMIN } from 'query';
import { InviteAdminSchema } from 'schemas';

import { BaseDropdown, CustomButton } from 'components';

import { AdminOptions } from './adminOptions';

export const AdminInvite = ({ refetch, setShow, isEditing, oneAdmin, success }) => {
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: isEditing ? oneAdmin : {},
    resolver: yupResolver(InviteAdminSchema),
  });

  const { successToast, errorToast } = useToastify();

  const [addNewUser] = useMutation(INVITE_ADMIN);
  const [editAdmin] = useMutation(EDIT_ADMIN);

  const onSubmit = async (data) => {
    const dataToSend = {
      ...data,
      role: data.role.value,
    };

    const editData = {
      _id: data._id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      role: data.role.value,
    };

    const adminName = `${data.firstName} ${data.lastName}`;
    setLoading(true);
    try {
      const res = isEditing
        ? await editAdmin({ variables: { data: editData } })
        : await addNewUser({ variables: { data: dataToSend } });

      if (!isEditing && res?.data?.addNewAdmin?.status === 201) {
        const message = res?.data?.addNewAdmin?.message;
        successToast(message);
        success({ positive: true, adminName: adminName });
      } else if (isEditing && res?.data?.editAdminDetails?.status === 200) {
        const message = res?.data?.editAdminDetails?.message;
        successToast(message);
      }
    } catch (error) {
      errorToast(error?.message || 'Error Ocured');
    } finally {
      reset();
      setLoading(false);
      refetch();
      setShow({ show: false });
    }
  };

  return (
    <>
      <InviteForm
        register={register}
        control={control}
        handleSubmit={handleSubmit}
        loading={loading}
        onSubmit={onSubmit}
        isEditing={isEditing}
        errors={errors}
      />
    </>
  );
};

function InviteForm(props) {
  const { register, control, handleSubmit, onSubmit, loading, isEditing, errors } = props;
  const colSpan = { base: 2, md: 1 };
  const longSpan = { base: 2, md: 2 };

  const { roleOptions, refetch } = AdminOptions();

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <Flex w="100%" margin="3.2rem auto 0" justifyContent="center">
      <Box w="40rem">
        <Flex direction="column">
          <Text textAlign="center" fontWeight="500" fontSize="2.4rem" lineHeight="3.2rem">
            {isEditing ? 'Edit Admin' : ' Invite Admin'}
          </Text>
          <Text
            textAlign="center"
            mt="0.8rem"
            fontWeight="400"
            fontSize="1.6rem"
            lineHeight="2.4rem">
            Enter details to send invite
          </Text>
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '3.8rem' }}>
          <SimpleGrid columns={2} gap="1.6rem">
            <GridItem colSpan={colSpan}>
              <FormControl>
                <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
                  First Name
                </FormLabel>
                <Input
                  {...register('firstName')}
                  placeholder="First Name"
                  fontSize="1.4rem"
                  height="4.8rem"
                  backgroundColor="#F7F9FB"
                  border="none"
                />
                <ErrorMessage>{errors?.firstName?.message || null}</ErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={colSpan}>
              <FormControl>
                <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
                  Last Name
                </FormLabel>
                <Input
                  {...register('lastName')}
                  fontSize="1.4rem"
                  height="4.8rem"
                  backgroundColor="#F7F9FB"
                  border="none"
                  placeholder="Last Name"
                />
                <ErrorMessage>{errors?.lastName?.message || null}</ErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={longSpan}>
              <FormControl>
                <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
                  Email
                </FormLabel>
                <Input
                  {...register('email')}
                  type="email"
                  fontSize="1.4rem"
                  height="4.8rem"
                  placeholder="Enter Email"
                  backgroundColor="#F7F9FB"
                  border="none"
                />
                <ErrorMessage>{errors?.email?.message || null}</ErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={longSpan}>
              <Controller
                defaultValue=""
                control={control}
                name="role"
                render={({ field }) => (
                  <BaseDropdown
                    {...field}
                    w="322"
                    labelSize="1.4rem"
                    label="Role"
                    options={roleOptions}
                  />
                )}
              />
              <ErrorMessage>{errors?.role?.message || null}</ErrorMessage>
            </GridItem>
            <GridItem colSpan={longSpan}>
              <FormControl>
                <FormLabel fontSize="1.4rem" color="#333758" fontWeight="400">
                  Location
                </FormLabel>
                <Input
                  {...register('location')}
                  type="text"
                  fontSize="1.4rem"
                  height="4.8rem"
                  backgroundColor="#F7F9FB"
                  placeholder="Enter Location"
                />
                <ErrorMessage>{errors?.location?.message || null}</ErrorMessage>
              </FormControl>
            </GridItem>
          </SimpleGrid>
          <Box mt="5.5rem">
            <CustomButton
              type="submit"
              name={isEditing ? 'Edit Admin' : 'Send Invite'}
              loading={loading}
            />
          </Box>
        </form>
      </Box>
    </Flex>
  );
}

const ErrorMessage = styled.p`
  color: var(--error);
  font-size: 1.2rem;
  text-transform: capitalize;
`;
