import React from 'react';
import styled from '@emotion/styled';
import { useHandleCreateJob, useJobDetailsForm } from 'context';

import RichTextEditor from 'components/admin/jobs/job-details/RichTextEditor';

import { useEditField } from '../hooks';

import { FieldWrapper } from './FieldWrapper';

import { cx } from '@emotion/css';

function JobDescription() {
  const { watch, setValue } = useJobDetailsForm();
  const jobDescription = watch('description');
  const [description, setDescription] = React.useState(() => jobDescription);
  const { onEditJob } = useHandleCreateJob();

  function onChange(value) {
    setDescription(value);
  }

  function onSave() {
    setValue('description', description);
    onEditJob();
  }

  function reset(val) {
    setDescription(val);
  }

  const editOptions = useEditField({ onSave, reset, defaults: jobDescription });
  const { isEditing } = editOptions;

  return (
    <FieldWrapper label="Job Description" editable editOptions={editOptions}>
      <DescriptionRoot className={cx({ preview: !isEditing })} isEditing={isEditing}>
        {!isEditing && <RichTextEditor initialValue={description} preview />}
        {isEditing && <RichTextEditor initialValue={description} onChange={onChange} />}
      </DescriptionRoot>
    </FieldWrapper>
  );
}

const DescriptionRoot = ({ isEditing, ...props }) => {
  const wrapperRef = React.useRef();

  React.useLayoutEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const textbox = wrapper.querySelector("[role='textbox']");
    if (!isEditing) textbox.removeAttribute('contenteditable');
    else textbox.setAttribute('contenteditable', true);
  }, [isEditing]);

  return (
    <DescriptionWrapper {...props} ref={wrapperRef}>
      {props.children}
    </DescriptionWrapper>
  );
};

const DescriptionWrapper = styled.div`
  line-height: 1.7;
  font-weight: 300;
  font-size: 14px;
  margin-top: 1.6rem;

  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 12px;
  }

  h1,
  h2 {
    font-weight: 700;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 16px;
  }

  ul,
  ol {
    padding-left: 1.6rem;
  }

  blockquote {
    padding-left: 2rem;
    padding-block: 8px;
    background: var(--grey-100);
    border-left: 4px solid var(--grey-300);
    color: var(--grey-500);
    margin-block: 4px;
  }

  &:not(.preview) [role='textbox'] {
    padding: 1.6rem 2.4rem;
    border: 1px solid var(--grey-150);
    min-height: 14.4rem !important;

    /* &:focus-within,
    &:focus-visible,
    &:focus {
      box-shadow: 0 0 1px 1px #3e7eee;
    } */
  }
`;

export { JobDescription };
