import React from 'react';
import { RiAsterisk } from 'react-icons/ri';
import styled from '@emotion/styled';

import { BaseInput, DropFile } from 'components';

const acceptableFileTypes = {
  'image/*': ['.jpeg', '.png'],
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/msword': [],
};

const AttachFile = React.forwardRef(({ type, setFiles, required, name, register }, ref) => {
  const [showField, setShowField] = React.useState('file');
  const [show, setShow] = React.useState(false);

  const handleShow = (t) => {
    setShowField(t);
    setShow(!show);
  };
  return (
    <Root data-testid="attach-file" ref={ref}>
      <button type="button" className="btn" onClick={() => handleShow(type)}>
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.54544 3.18183V10.5C6.54544 11.9064 5.40634 13.0455 3.99999 13.0455C2.59365 13.0455 1.45452 11.9064 1.45452 10.5V2.54545C1.45452 1.66726 2.16723 0.954546 3.04542 0.954546C3.9236 0.954546 4.63635 1.66726 4.63635 2.54545V9.22727C4.63635 9.57726 4.35317 9.86362 3.99999 9.86362C3.64681 9.86362 3.36364 9.57726 3.36364 9.22727V3.18183H2.40909V9.2273C2.40909 10.1055 3.12181 10.8182 3.99999 10.8182C4.87818 10.8182 5.59089 10.1055 5.59089 9.22727V2.54545C5.59089 1.1391 4.45179 0 3.04545 0C1.6391 0 0.5 1.1391 0.5 2.54545V10.5C0.5 12.4345 2.06865 14 3.99999 14C5.93134 14 7.49998 12.4345 7.49998 10.5V3.18183H6.54544Z"
            fill="#3E7EEE"
          />
        </svg>
        {`Attach ${type}`}
        <span>{required ? <RiAsterisk fontSize={'6px'} color="#fa4a4a" /> : '(optional)'}</span>
      </button>
      {show && showField === 'file' ? (
        <DropFile setFiles={setFiles} accept={acceptableFileTypes} />
      ) : null}
      {show && showField === 'link' ? (
        <BaseInput name={name} placeholder="https://" {...register('attachedLink')} />
      ) : null}
    </Root>
  );
});

AttachFile.displayName = 'AttachFile';

const Root = styled.div`
  position: relative;
  .content {
    width: 100%;
    max-width: 100%;
    padding: 1.6rem 2.4rem;
    border: 1px solid var(--grey-150);
    border-radius: 0.4rem;
  }
  .btn {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.117188px;
    text-decoration-line: underline;
    color: #3e7eee;
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    text-transform: capitalize;

    svg {
      margin-right: 11.5px;
    }

    span {
      color: #83889d;
      font-style: italic;
    }
  }
`;

export { AttachFile };
