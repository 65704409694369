import React from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_LINE_MANAGER_REVIEWS } from 'query';
import { getGraphQLResponse } from 'utils';

export const usePerformanceReview = () => {
  const [searchString, setSearchString] = React.useState('');
  const [search, setSearch] = React.useState('');

  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState('');

  const { data, loading, refetch } = useQuery(FETCH_LINE_MANAGER_REVIEWS, {
    variables: {
      filters: { teamId: filter, searchString },
      page,
    },
  });

  const performanceReviewResponse = React.useMemo(() => getGraphQLResponse(data) || [], [data]);

  return {
    page,
    filter,
    loading,
    setFilter,
    setPage,
    refetch,
    searchString,
    setSearch,
    search,
    setSearchString,
    performanceReviews: performanceReviewResponse?.data || [],
    pageCount: performanceReviewResponse?.pageCount,
  };
};
