import { useDrag } from 'react-dnd';
import { Box, Grid } from '@chakra-ui/react';
import { DND_TYPES } from 'utils';

import { INPUT_TYPES } from '../helpers';

import { InputTypeIcon } from './InputTypeIcon';

function ConfigureFormFields() {
  return (
    <Box p="1.6rem" display={{ base: 'none', md: 'block' }}>
      <Box fontSize="1.4rem" fontWeight="600" mb="2.4rem">
        Drag Field
      </Box>
      <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap="1.6rem">
        {Object.values(INPUT_TYPES)
          .filter((type) => type !== INPUT_TYPES.Section)
          .map((type) => (
            <FieldOption key={type} type={type}>
              <Box transform="scale(0.75)">
                <InputTypeIcon type={type} />
              </Box>
              {type}
            </FieldOption>
          ))}
      </Grid>
    </Box>
  );
}

function FieldOption({ type, ...props }) {
  const [{ isDragging }, drag] = useDrag({
    type: DND_TYPES.ApplicantInputOption,
    item: { type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <Grid
      ref={drag}
      opacity={isDragging ? '0.5' : 1}
      bg="#fff"
      h="3rem"
      templateColumns="repeat(2, max-content)"
      alignItems="center"
      gap="8px"
      px="16px"
      border={'1px solid var(--grey-150)'}
      color="brand.grey.700"
      fontSize="1.2rem"
      cursor="move"
      _hover={{ boxShadow: '0 4px 12px rgba(0,0,0,0.05)' }}
      userSelect="none"
      {...props}
    />
  );
}

export { ConfigureFormFields };
