import React from 'react';
import styled from '@emotion/styled';

const Initials = styled.div`
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  background-color: gray;
  color: white;
  font-weight: bold;
  margin-right: 16px;
`;

const PersonInitials = ({ creator }) => {
  const initials = `${creator.firstName[0]}${creator.lastName[0]}`;
  return <Initials>{initials}</Initials>;
};

export default PersonInitials;
