import { Text } from '@chakra-ui/react';

import { StatusType } from 'components/tables/TableConfig';
export const columns = [
  {
    Header: 'Name',
    accessor: 'col1',
  },
  {
    Header: 'No. of Designation',
    accessor: 'col2',
  },
  {
    Header: 'Created By',
    accessor: 'col3',
  },
  {
    Header: 'Date Created',
    accessor: 'col4',
  },
];

export const Status = ({ status }) => {
  return (
    <StatusType className="status" status={status}>
      <div className="dot">
        <div className="span"></div>
      </div>
      <Text textTransform={'capitalize'}>{status}</Text>
    </StatusType>
  );
};
