import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const ToggleSwitch = styled(Checkbox)`
  position: relative;
  width: 2.8rem;
  min-width: 3.1rem;
  height: 1.6rem;
  border-radius: 1rem;
  background: var(--grey-600);
  appearance: none;
  cursor: pointer;
  transition: background-color 100ms linear;

  &:before {
    content: '';
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    background: #fff;
    left: 2px;
    top: 2px;
    transition: left 100ms ease;
  }

  &:checked {
    background: var(--green);
  }

  &:checked:before {
    left: 17px;
  }
`;

function Checkbox(props) {
  return (
    <Flex alignItems={'center'} gap="1rem">
      <input data-testid="leaveVisibility-btn" type="checkbox" {...props} />
      <label className="toggle-label" htmlFor={props.id}>
        {props.label}
      </label>
    </Flex>
  );
}
