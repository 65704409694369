import React, { useEffect, useState } from 'react';
import { Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { CheckboxWrapper, CustomCheckbox } from 'components';

const Checkboxes = styled(Grid)`
  gap: 16px;

  letter-spacing: -0.117188px;
  font-weight: 400;
  font-size: 12px;
`;

const SelectTrainingDetails = ({ handleTrainingDetails, state }) => {
  const length = state?.length;

  const checkbox = [
    { label: 'Expiration Date', value: 'expiration_date' },
    { label: 'Instructor', value: 'instructor' },
    { label: 'Hours', value: 'hours' },
    { label: 'Credits', value: 'credits' },
    { label: 'Cost', value: 'cost' },
    { label: 'Results (i.e. Pass/Fail)', value: 'result' },
    { label: 'Certificate', value: 'certificate' },
  ];

  const [isChecked, setIsChecked] = useState(state || []);

  const handleChecked = (e) => {
    setIsChecked((prev) =>
      isChecked.includes(e) ? prev.filter((cur) => cur !== e) : [...prev, e]
    );
  };

  useEffect(() => {
    handleTrainingDetails(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setIsChecked(state || []);
  }, [length]);
  return (
    <>
      <Checkboxes>
        <Text className="heading">Select Training Details</Text>

        {checkbox?.map((milestone, index) => (
          <CheckboxWrapper data-testid={'checkbox'} key={index}>
            <CustomCheckbox
              textColor={'#23273b'}
              label={milestone?.label}
              variant={'green-checkbox'}
              checked={isChecked?.includes(milestone?.value)}
              handleChecked={() => handleChecked(milestone?.value)}
            />
          </CheckboxWrapper>
        ))}
      </Checkboxes>
    </>
  );
};

export { SelectTrainingDetails };
