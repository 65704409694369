import { gql } from '@apollo/client';

export const FETCH_EMPLOYEE_DETAILS = gql`
  query fetchEmployeeDetails {
    fetchEmployeeDetails {
      status
      message
      data {
        firstName
        lastName
        isDefaultAdmin
        isDisabled
        status
        gender
        email
        imageUrl
        phoneNumber
        maritalStatus
        staffId
        bloodGroup
        bloodType
        isDisabled
        notableAilment
        disabilityType
        dob
        birthdayCountdown
        workAnniversary
        employmentInfo {
          employmentType
          compensationTypes
          employmentType
          hireDate
          monthlyPayRate
          workEmail
        }
        department {
          name
        }
        address {
          address
          country
          state
          district
        }
        bankAccount {
          paymentType
          bankName
          accountNo
          pensionAccountNo
          pensionFundAdministrator
          accountName
          accountType
        }
        designation {
          name
          _id
        }
        emergencyContact {
          fullName
          relationship
          email
          country
          state
          city
          address
          phoneNumber
          occupation
        }
      }
    }
  }
`;

export const EDIT_EMPLOYEE_DETAILS = gql`
  mutation editEmployeeDetails($data: EditUserInput!) {
    editEmployeeDetails(data: $data) {
      status
      message
      data {
        _id
        firstName
        lastName
        middleName
        email
        phoneNumber
        imageUrl
        status
        isDefaultAdmin
        companyId
        gender
        dob
        address {
          address
          state
          country
          district
        }
        emergencyContact {
          fullName
          relationship
          email
          country
          state
          city
          address
          phoneNumber
          occupation
        }
        bankAccount {
          pensionAccountNo
          accountName
          accountType
          accountNo
          bankName
          pensionFundAdministrator
        }
      }
    }
  }
`;

export const UPLOAD_PROFILE_PIC = gql`
  mutation uploadProfilePic($file: Upload!) {
    uploadProfilePic(file: $file) {
      status
      message
    }
  }
`;

export const FETCH_SINGLE_EMPLOYEE_DETAILS = gql`
  query fetchUsersFiltered($filters: UserFilters, $page: Int) {
    fetchUsersFiltered(filters: $filters, page: $page) {
      status
      message
      data {
        _id
        firstName
        lastName
        isDefaultAdmin
        isDisabled
        status
        gender
        email
        imageUrl
        phoneNumber
        isLineManager
        maritalStatus
        staffId
        bloodGroup
        bloodType
        isDisabled
        notableAilment
        disabilityType
        dob
        birthdayCountdown
        workAnniversary
        team {
          _id
          name
        }
        employmentInfo {
          employmentType
          compensationTypes
          employmentType
          hireDate
          monthlyPayRate
          workEmail
        }
        department {
          name
          _id
        }
        address {
          address
          country
          state
          district
        }
        bankAccount {
          paymentType
          bankName
          accountNo
          pensionAccountNo
          pensionFundAdministrator
          accountName
          accountType
        }
        designation {
          name
          _id
        }
        emergencyContact {
          fullName
          relationship
          email
          country
          state
          city
          address
          phoneNumber
          occupation
        }
      }
    }
  }
`;
