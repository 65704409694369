import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useQuery } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { FETCH_ALL_EMPLOYEES } from 'query';
import { getItem } from 'utils';

import { CustomButton, Pagination, Search, SimpleModal } from 'components';

import plus from '../../../assets/icons/plusLarge.svg';

import { SingleEmployeeView } from './EmployeeView';
import { EmployeeTable } from './EmpTable';
import { DownloadIcon, Kebab, Layout, Print } from './Icons';
import { Onboarding } from './Onboarding';
import { SendMessage } from './SendMessage';
import {
  ESubHeading,
  HeaderContentWrapper,
  MainHeading,
  MainWrapper,
  SearchContentWrapper,
  Stroke,
} from './styles';

const initialState = { empId: '', showViewModal: false };
function viewUserReducer(state, action) {
  return { ...state, ...action };
}

const EmployeePage = () => {
  const [tableData, setTableData] = React.useState([]);
  const [activeTab, setActiveTab] = useState('tableLayout');
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = React.useState('');
  const [onboardType, setOnboardType] = React.useState(false);
  const [searchString, setSearchString] = React.useState('');
  const [{ showViewModal, empId }, setShowViewModal] = React.useReducer(
    viewUserReducer,
    () => initialState
  );
  const filters = { isDefaultAdmin: false };

  const { errorToast } = useToastify();

  const { data, loading, refetch } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      page: page,
      filters: searchString ? { ...filters, searchString: searchString } : { ...filters },
    },
  });

  React.useEffect(() => {
    const employeesData = data?.fetchUsersFiltered?.data;
    setTableData(employeesData);
  }, [data, searchString]);

  const fetchData = data?.fetchUsersFiltered;
  const totalCount = fetchData?.totalCount;
  const totalPages = fetchData?.pageCount || 1;

  const handleSelectAll = (e) => {
    const { checked: isChecked } = e.target;
    if (!isChecked) {
      return setChecked([]);
    }
    let checkedCopy = Array(tableData?.length);
    tableData?.forEach((elem, i) => {
      checkedCopy[i] = elem._id;
    });
    setChecked(checkedCopy);
  };

  React.useEffect(() => {
    handleSelectAll({ target: { checked: selectAll } });
  }, [selectAll]);

  const closeModal = () => {
    setShowModal(false);
    setChecked([]);
  };
  const handleButtonClick = () => {
    setOnboardType(true);
  };
  const handleLayoutView = (val) => {
    setActiveTab(val);
  };
  const sendBulkMessage = (email) => {
    if (email) {
      setChecked((prev) => (checked.includes(email) ? [...prev] : [...prev, email]));
      setShowModal(true);
    } else if (checked.length === 0) {
      errorToast('Select an employee(s) first');
    } else {
      setChecked([...checked]);
      setShowModal(true);
    }
  };
  const actionOnData = ({ action }) => {
    switch (action) {
      case 'send-message':
        sendBulkMessage();
        break;
      case 'select-all':
        setSelectAll(!selectAll);
        handleSelectAll({ target: { checked: selectAll } });
        break;
    }
  };

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const dropdownActions = ['Select All', 'Send Message'];

  async function exportCsv() {
    const token = getItem('token');
    fetch('https://hrmapi-v3.enyata.com/api/v3/export/employees', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `employees.csv`);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  }

  return (
    <>
      <MainWrapper data-testid="employee-page">
        <HeaderContentWrapper>
          <Box>
            <MainHeading>EMPLOYEES</MainHeading>
            <ESubHeading>Total: {totalCount} people</ESubHeading>
          </Box>
          <Box w={'14.6rem'}>
            <CustomButton
              handleButtonClick={handleButtonClick}
              name={'Add Employee'}
              variant={'primary'}
              h={'4rem'}
              fW={'400'}
              bR={'0.4rem'}
              fS={'1.4rem'}
              src={plus}
            />
          </Box>
        </HeaderContentWrapper>
        <Onboarding onboardType={onboardType} setOnboardType={setOnboardType} />
        <SearchContentWrapper>
          <Flex>
            <Search
              placeholder={'Search Employee'}
              value={search}
              setPage={setPage}
              setSearchString={setSearchString}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </Flex>
          <Flex alignItems={'center'} gap="2.4rem">
            <Text>{checked.length} Selected</Text>
            <Kebab dropdownActions={dropdownActions} actionOnData={actionOnData} />

            <Flex justifyContent={'center'} alignItems="center">
              <Stroke />
            </Flex>
            <DownloadIcon export={exportCsv} />
            <Print />
            <Layout
              activeTab={activeTab}
              tableViewTab={handleLayoutView}
              gridViewTab={handleLayoutView}
            />
          </Flex>
        </SearchContentWrapper>
        <EmployeeTable
          tData={tableData}
          setTData={setTableData}
          activeTab={activeTab}
          setSelectAll={setSelectAll}
          handleSelectAll={handleSelectAll}
          selectAll={selectAll}
          checked={checked}
          setChecked={setChecked}
          setShowViewModal={setShowViewModal}
          loading={loading}
          refetch={refetch}
          setShowModal={setShowModal}
        />
        <Onboarding />
        {showViewModal && (
          <SingleEmployeeView
            empId={empId}
            showViewModal={showViewModal}
            setShowViewModal={setShowViewModal}
            refetch={refetch}
          />
        )}

        <SimpleModal
          width="426px"
          title={'Send Message'}
          showModal={showModal}
          closeModal={closeModal}>
          <Header>
            <p>Send Message(s)</p>
            <IoClose cursor={'pointer'} size="22" onClick={() => closeModal()} />
          </Header>
          <SendMessage closeModal={closeModal} userIds={checked} />
        </SimpleModal>
      </MainWrapper>
      <Box mt="1rem" position="fixed" right={'4rem'} left={'27.2rem'} bottom={'1.2rem'}>
        {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
      </Box>
    </>
  );
};

const Header = styled(Flex)`
  justify-content: space-between;
  padding: 2.4rem;
  margin-bottom: 2.4rem;

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.117188px;
    color: #23273b;
  }
`;

export { EmployeePage };
