import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import plus from '../../../assets/icons/plus copy.svg';
import { BaseButton } from '../../../components';
import { Tabs } from '../../../components/shared/tabs';

import { Category } from './category/Category';
import { Log } from './log/Log';
import { useAssetManagement } from './AssetManagement.hooks';

export function AssetManagement() {
  const navigate = useNavigate();
  const { selectedButton, setSelectedButton, tabs } = useAssetManagement();

  return (
    <PageRoot>
      <Header>
        <Flex justifyContent="space-between" alignItems="center" mb="32px" minHeight="5.6rem">
          <h1>Asset Management</h1>

          <BaseButton h="5.6rem" smp onClick={() => navigate('/admin/asset/add')}>
            <img src={plus} alt="" />
            Add an Asset
          </BaseButton>
        </Flex>
        <Tabs tabs={tabs} setSelected={setSelectedButton} selected={selectedButton} />
      </Header>
      <Content className="scroll-bar">
        {selectedButton === 'log' ? <Log /> : null}
        {selectedButton === 'category' ? <Category /> : null}
      </Content>
    </PageRoot>
  );
}

const PageRoot = styled.div`
  height: calc(100% - 108px);
  padding: 0 24px;
`;

const Header = styled.div`
  border-bottom: 1px solid var(--grey-150);
  position: sticky;
  top: 0;
  background: var(--bg);
  z-index: 10;
  padding-top: 2.4rem;

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .tab-btn-toggle {
    :hover {
      border-bottom: 1px solid #3e7eee;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 144px);
  overflow: auto;
  padding-block: 24px;
`;
