import React from 'react';

const ApplicantsFormSectionsContext = React.createContext();

function ApplicantsFormSectionsProvider(props) {
  const appendRef = React.useRef(null);
  const panelRef = props.panelRef;

  return (
    <ApplicantsFormSectionsContext.Provider value={{ appendRef, panelRef }}>
      {props.children}
    </ApplicantsFormSectionsContext.Provider>
  );
}

function useApplicantsFormSections() {
  const context = React.useContext(ApplicantsFormSectionsContext);
  if (!context)
    throw Error('useApplicantsFormSections must be wrapped in ApplicantsFormSectionsProvider');
  return context;
}

export { ApplicantsFormSectionsProvider, useApplicantsFormSections };
