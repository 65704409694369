import React from 'react';
import { useFieldArray } from 'react-hook-form';
import styled from '@emotion/styled';

import { CheckboxWrapper, CustomCheckbox } from 'components';

import { permissionsCols } from './tableConfig';

export const Table = ({ register, control, watch }) => {
  const { fields, update } = useFieldArray({
    control,
    name: 'permissions',
  });
  return (
    <Wrap className="permission-wrapper">
      <table>
        <thead>
          <tr>
            {permissionsCols.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => {
            const allFields = ['create', 'read', 'update', 'delete'];
            const allChecked = allFields.every((f) =>
              watch(`permissions.${index}.privileges`).includes(f)
            );
            const onClick = (e) => {
              if (e.target.checked) {
                update(index, { ...field, privileges: allFields });
              } else {
                update(index, { ...field, privileges: [] });
              }
            };

            return (
              <tr key={field.id}>
                <td>{field.moduleName}</td>
                <td>
                  <CheckboxWrapper>
                    <CustomCheckbox
                      variant={'blue-checkbox'}
                      {...register(`permissions.${index}.privileges`)}
                      value="create"
                    />
                  </CheckboxWrapper>
                </td>
                <td>
                  <CheckboxWrapper>
                    <CustomCheckbox
                      variant={'blue-checkbox'}
                      {...register(`permissions.${index}.privileges`)}
                      value="read"
                    />
                  </CheckboxWrapper>
                </td>
                <td>
                  <CheckboxWrapper>
                    <CustomCheckbox
                      variant={'blue-checkbox'}
                      {...register(`permissions.${index}.privileges`)}
                      value="update"
                    />
                  </CheckboxWrapper>
                </td>
                <td>
                  <CheckboxWrapper>
                    <CustomCheckbox
                      variant={'blue-checkbox'}
                      {...register(`permissions.${index}.privileges`)}
                      value="delete"
                    />
                  </CheckboxWrapper>
                </td>
                <td>
                  {/* <input type="checkbox" /> */}
                  <CheckboxWrapper>
                    <CustomCheckbox
                      variant={'blue-checkbox'}
                      checked={allChecked}
                      onChange={onClick}
                    />
                  </CheckboxWrapper>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Wrap>
  );
};

const Wrap = styled.div`
  min-width: 920px;
  overflow-x: auto;
`;
