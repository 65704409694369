import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ModalBackButton, SpinnerComponent } from '../../../../../components';
import { RETRIEVE_REVIEWS } from '../../../../../query';
import ReviewsReport from '../ReviewTable/ReviewReport/ReviewsReport';

export function ReviewsRevieweeList() {
  const params = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery(RETRIEVE_REVIEWS, {
    variables: {
      filters: { _id: params.id },
    },
  });
  const title = data?.retrieveReviews?.data?.[0]?.title;

  return (
    <Box
      backgroundColor="white"
      m="24px"
      p="24px"
      mb={'80px'}
      boxShadow="0 0 4px rgba(0,0,0, 0.08)">
      <Header>
        <ModalBackButton onClick={() => navigate(-1)} />
        <h1 style={{ marginLeft: '16px' }}>{title}</h1>
      </Header>

      {loading ? <SpinnerComponent h="440px" /> : <ReviewsReport data={data} />}
    </Box>
  );
}

const Header = styled.div`
  display: flex;

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;
