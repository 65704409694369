import React from 'react';
import { useForm } from 'react-hook-form';
import { TiPlus } from 'react-icons/ti';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import EmptyStateIcon from 'assets/icons/empty-state-folders.svg';
import PrivateHardDrive from 'assets/icons/hard-drive-blue.svg';
import SharedHardDrive from 'assets/icons/hard-drive-orange.svg';
import PrivateFolderIcon from 'assets/icons/private-folder-icon.svg';
import SharedFolderIcon from 'assets/icons/shared-folder-icon.svg';
import { useToastify } from 'hooks/useToastify';
import { FETCH_ALL_DEPARTMENTS } from 'query';
import { DELETE_FOLDER, EDIT_FOLDER, SHARE_FOLDER } from 'query/admin/document/document';
import { formatDate } from 'utils';

import {
  BaseButton,
  BaseInput,
  CustomButton,
  DeletePromptModal,
  EventsActionContainer,
  EventsPopupAction,
  FullModal,
  ModalBackButton,
  SimpleModal,
  TableComponent,
  ViewDocument,
} from 'components';

import plus from '../../../assets/icons/plus copy.svg';
import { UserImage } from '../employee/tableConfig';

import { SingleFolderColumns } from './documentTableData';
import { formatFileSize, formatText, getExtFromLink } from './helper';
import { ShareFolderModal } from './ShareFolderModal';

export const RenderIcon = ({ tab }) => {
  return (
    <>{tab === 'private' ? <img src={PrivateFolderIcon} /> : <img src={SharedFolderIcon} />}</>
  );
};

export const DocumentName = ({
  name,
  type,
  tab,
  id,
  url,
  handleDoubleclick,
  handleRightClick,
  setMousePoints,
}) => {
  return (
    <DocumentNameWrapper
      gap="10px"
      alignItems={'center'}
      cursor="pointer"
      userSelect={'none'}
      data-testid="document-name"
      onContextMenu={(e) => {
        e.preventDefault();
        handleRightClick({ id, name, url, type });
        setMousePoints({ x: e.pageX, y: e.pageY });
      }}
      onDoubleClick={() => handleDoubleclick({ type, id, url, name })}>
      {type === 'file' ? getExtFromLink(url) : <RenderIcon tab={tab} />}
      <p className="file-name">{name}</p>
    </DocumentNameWrapper>
  );
};

export const SingleFolder = ({
  folderType,
  header,
  tabType,
  addDocument,
  addFolder,
  isClicked,
  setPath,
  setFolderId,
  // folderId,
  folderName,
  tab,
  refetch,
  privateRootRefetch,
  sharedRootRefetch,
}) => {
  const [viewModal, setViewModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [rightClicked, setRightClicked] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [optionType, setOptionType] = React.useState('');
  const [openShareModal, setOpenShareModal] = React.useState(false);
  // const [id, setId] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [singleObject, setSingleObject] = React.useState({});
  const [mousePoints, setMousePoints] = React.useState({ x: 0, y: 0 });
  const [selectedFolderName, setSelectedFolderName] = React.useState('');
  const [selectedName, setSelectedName] = React.useState('');
  const [documentUrl, setDocumentUrl] = React.useState('');
  const [documentName, setDocumentName] = React.useState('');
  const { errorToast, successToast } = useToastify();
  const { watch, register, handleSubmit, reset } = useForm();
  const isTesting = process.env.NODE_ENV === 'test';

  if (isTesting) {
    folderType = {
      _id: '63907c1e9a2838f244804efe',
      name: 'edit root folder',
      subFiles: [
        {
          _id: '639bf0f3b5d15c0503d480d5',
          name: 'first dco',
          url: 'https://photow-profile-images.s3.amazonaws.com/1671164146781YovRAWsmJZ6gOuG6_to3K.pdf',
          employee: {
            _id: '637f4aa4976df8f85b7aca02',
            firstName: 'Isaac',
            lastName: 'Anane',
            imageUrl: null,
          },
        },
        {
          _id: '639e3d7376198f6b50f34d07',
          name: 'new document',
          url: 'https://photow-profile-images.s3.amazonaws.com/1671314803310Ge9a6DCrKOSkyufiWHOwW.pdf',
          employee: {
            _id: '637f4aa4976df8f85b7aca02',
            firstName: 'Isaac',
            lastName: 'Anane',
            imageUrl: null,
          },
        },
      ],
      subFolders: [
        {
          _id: '63c526c28f21f4063656034b',
          name: '20',
          createdBy: {
            _id: '637f4aa4976df8f85b7aca02',
            firstName: 'Isaac',
            lastName: 'Anane',
            imageUrl: null,
          },
          updatedAt: '2023-01-16T10:28:18.402Z',
          subFolders: [],
        },
        {
          _id: '639ee1c876198f6b50f35292',
          name: 'new1234',
          createdBy: {
            _id: '637f4aa4976df8f85b7aca02',
            firstName: 'Isaac',
            lastName: 'Anane',
            imageUrl: null,
          },
          updatedAt: '2023-01-13T10:42:06.258Z',
          subFolders: [],
        },
        {
          _id: '639b3825b5d15c0503d470ab',
          name: 'Edited folder',
          createdBy: {
            _id: '637f4aa4976df8f85b7aca02',
            firstName: 'Isaac',
            lastName: 'Anane',
            imageUrl: null,
          },
          updatedAt: '2022-12-17T21:44:49.510Z',
          subFolders: [
            {
              _id: '639b8899b5d15c0503d47b25',
            },
          ],
        },
      ],
    };
  }

  const [editFolder, { loading }] = useMutation(EDIT_FOLDER, {});

  const [deleteFolder, { loading: deleteLoading }] = useMutation(DELETE_FOLDER, {});

  const [shareFolder, { loading: shareLoading }] = useMutation(SHARE_FOLDER, {});

  const { data: allDepartments } = useQuery(FETCH_ALL_DEPARTMENTS);

  function toggleModal() {
    setViewModal((prev) => !prev);
  }
  function toggleEditModal() {
    setEditModal((prev) => !prev);
  }

  const onDeleteFolder = async () => {
    try {
      const response = await deleteFolder({ variables: { _id: id } });
      if (response?.data?.deleteFolder?.status === 200) {
        successToast('Folder deleted sucessfully');
        refetch();
        privateRootRefetch();
        sharedRootRefetch();
      } else errorToast(response?.data?.deleteFolder?.message);
    } catch (error) {
      const message = error?.response?.data?.message || 'Error Occured';
      errorToast(message);
    }
  };

  const actionOnData = ({ action, name, type, id, url }) => {
    switch (action) {
      case 'view':
        if (type === 'folder') {
          setPath('active');
          setFolderId(id);
        } else {
          toggleModal();
          setDocumentUrl(url);
          setDocumentName(name);
        }
        setRightClicked(false);
        break;
      case 'edit':
        toggleEditModal();
        setSelectedFolderName(name);
        setSelectedName(name);
        setSelectedId(id);
        setRightClicked(false);
        break;
      case 'delete':
        // onDeleteFolder(id);
        setShow(true);
        setId(id);
        setRightClicked(false);
        break;
      case 'share':
        setOpenShareModal(true);
        setId(id);
        break;
      default:
        break;
    }
  };

  const dropdownActionsFolder = {
    view: 'View',
    share: 'Share',
    edit: 'Edit',
    delete: 'Delete',
  };
  const dropdownActionsFile = {
    view: 'View',
  };

  function getAll(data) {
    let arr = [];
    if (data == null || data == undefined) {
      return arr;
    }
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'subFolders') {
        for (let event of value) {
          arr.push({ ...event, type: 'folder' });
        }
      } else {
        for (let event of value) {
          arr.push({ ...event, type: 'file' });
        }
      }
    });
    return arr;
  }

  function handleDoubleclick({ type, id, url, name }) {
    if (type === 'folder') {
      setPath('active');
      setFolderId(id);
    } else {
      toggleModal();
      setDocumentUrl(url);
      setDocumentName(name);
    }
  }

  function handleRightClick({ id, name, url, type }) {
    setRightClicked(true);
    setSingleObject({ id, type, name, url });
  }

  const newdocumentTableData = getAll({
    subFolders: folderType?.subFolders,
    subFiles: folderType?.subFiles,
  }).map(({ _id, name, createdAt, createdBy, updatedAt, employee, type, url, size }) => {
    return {
      col1:
        (
          <DocumentName
            name={name}
            type={type}
            tab={tab}
            url={url}
            id={_id}
            handleDoubleclick={handleDoubleclick}
            handleRightClick={handleRightClick}
            setMousePoints={setMousePoints}
          />
        ) || 'N/A',
      col2: UserImage({
        imageUrl: createdBy?.imageUrl || employee?.imageUrl,
        name: createdBy
          ? createdBy?.firstName + ' ' + createdBy?.lastName
          : employee?.firstName + ' ' + employee?.lastName,
        firstName: createdBy?.firstName || employee?.firstName,
        lastName: createdBy?.lastName || employee?.lastName,
      }),
      col3: size ? formatFileSize(size, 2) : '--',
      col4: formatDate(updatedAt) || formatDate(createdAt) || 'N/A',
      col5:
        type === 'folder' ? (
          <EventsActionContainer>
            <EventsPopupAction
              id={_id}
              type={type}
              name={name}
              actionOnData={actionOnData}
              dropdownActions={dropdownActionsFolder}></EventsPopupAction>
          </EventsActionContainer>
        ) : (
          <EventsActionContainer>
            <EventsPopupAction
              id={_id}
              type={type}
              name={name}
              url={url}
              actionOnData={actionOnData}
              dropdownActions={dropdownActionsFile}></EventsPopupAction>
          </EventsActionContainer>
        ),
    };
  });

  const onEditFolder = async (e) => {
    e.preventDefault();
    const dataToSend = {
      _id: selectedId,
      name: selectedName,
    };
    try {
      const response = await editFolder({ variables: { data: dataToSend } });
      if (response?.data?.editFolder?.status === 200) {
        successToast('Folder name updated sucessfully');
        setSelectedName('');
        toggleEditModal();
        refetch();
        privateRootRefetch();
        sharedRootRefetch();
      } else errorToast(response?.data?.editFolder?.message);
    } catch (error) {
      const message = error?.response?.data?.message || 'Error Occured';
      errorToast(message);
    }
  };

  function handleSelectedName(val) {
    setSelectedName(val);
  }

  function handleCheckBoxChange(id, name) {
    setChecked((prev) =>
      checked.some((e) => e.id === id)
        ? prev.filter((cur) => cur.id !== id)
        : [...prev, { id, name }]
    );
  }

  const checkedBool = allDepartments?.fetchCompanyDepartments?.data?.map((emp) => {
    return checked.some((e) => e.id === emp?.id);
  });

  React.useEffect(() => {
    watch('sharedRight') === 'departments'
      ? setOptionType('Departments')
      : setOptionType('Everyone');
  });

  async function onShareFolder(data) {
    const selectedDepartments = checked.map((e) => {
      return e?.id;
    });
    const dataToSend =
      data?.sharedRight === 'departments'
        ? { _id: id, sharedRight: data?.sharedRight, sharedWith: selectedDepartments }
        : { _id: id, sharedRight: data?.sharedRight, sharedWith: [] };

    try {
      const response = await shareFolder({ variables: { data: dataToSend } });
      response?.data?.shareFolder?.status === 200
        ? successToast('Folder shared successfully')
        : errorToast(response?.data?.shareFolder?.message);
      reset();
      setOpenShareModal(false);
      refetch();
      privateRootRefetch();
      sharedRootRefetch();
    } catch (error) {
      errorToast('Error occurred');
    }
  }

  return (
    <>
      <Wrapper onClick={() => setRightClicked(false)}>
        {rightClicked && (
          <ContextMenu top={mousePoints.y} left={mousePoints.x}>
            {singleObject?.type === 'folder'
              ? Object.entries(dropdownActionsFolder).map(([key, action]) => {
                  const actionText = formatText(action);
                  return (
                    <div
                      key={key}
                      className={action}
                      data-testid={`right-${action}`}
                      onClick={() => actionOnData({ ...singleObject, action: actionText })}>
                      {action}
                    </div>
                  );
                })
              : Object.entries(dropdownActionsFile).map(([key, action]) => {
                  const actionText = formatText(action);
                  return (
                    <div
                      key={key}
                      className={action}
                      data-testid={`${action}`}
                      onClick={() => actionOnData({ ...singleObject, action: actionText })}>
                      {action}
                    </div>
                  );
                })}
          </ContextMenu>
        )}
        <div className="tab-section-header">
          {isClicked ? (
            <div className="single-folder">
              <ModalBackButton
                data-testid={'back-btn'}
                onClick={() => {
                  setPath('');
                  setFolderId(null);
                }}
              />
              <h1>{folderName}</h1>
            </div>
          ) : (
            <div className="header-title">
              {tabType === 'private' ? (
                <img src={PrivateHardDrive} className="drive-icon" />
              ) : (
                <img src={SharedHardDrive} className="drive-icon" />
              )}

              <h1>{header}</h1>
            </div>
          )}
          <div className="modal-buttons">
            <BaseButton variant="outline-blue" onClick={addFolder} data-testid="addFolderBtn">
              <TiPlus />
              Add Folder
            </BaseButton>
            <BaseButton onClick={addDocument} data-testid="addDocumentBtn">
              <img src={plus} alt="" />
              Add Document
            </BaseButton>
          </div>
        </div>
        <hr />
        {getAll({
          subFolders: folderType?.subFolders,
          subFiles: folderType?.subFiles,
        }).length < 1 ? (
          <Box className="empty-state">
            <img src={EmptyStateIcon} alt="" />
            <h3 className="empty-state-header">No Folders and Files</h3>
            <p className="empty-state-sub-header">
              Keep your colleague up to date by uploading new company documents here.
            </p>
          </Box>
        ) : (
          <Box>
            <TableComponent
              minW={'750px'}
              columns={SingleFolderColumns}
              data={newdocumentTableData}
              noSort
              noCheckbox
              noAction
            />
          </Box>
        )}
      </Wrapper>

      <FullModal showModal={viewModal} noHeader padding={'0'}>
        <ViewDocument
          closeModal={toggleModal}
          documentName={documentName}
          documentUrl={documentUrl}
        />
      </FullModal>
      <SimpleModal showModal={editModal} width={'749px'}>
        <FormWrapper>
          <h1>{`Edit ${selectedFolderName} folder name`}</h1>
          <form onSubmit={onEditFolder}>
            <Stack border={'1px solid #F2F2F2'} borderRadius="4px" mt={'24px'} padding="24px">
              <BaseInput
                label="Folder Name"
                placeholder="Folder name"
                isRequired
                value={selectedName}
                onChange={(e) => handleSelectedName(e.target.value)}
              />
            </Stack>
            <Flex mt={'24px'} justifyContent={'flex-end'} gap={'0.8rem'}>
              <CustomButton
                variant="black--outline"
                name={'Cancel'}
                mW={'9.4rem'}
                h={'4rem'}
                onClick={toggleEditModal}
              />
              <CustomButton
                variant="black"
                name={'Save'}
                mW={'9.4rem'}
                h={'4rem'}
                loading={loading}
                type="submit"
              />
            </Flex>
          </form>
        </FormWrapper>
      </SimpleModal>
      <ShareFolderModal
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        checked={checkedBool}
        checkedData={checked}
        handleCheckBoxChange={handleCheckBoxChange}
        departmentData={allDepartments?.fetchCompanyDepartments?.data}
        id={allDepartments?.fetchCompanyDepartments?.data.map((e) => e?.id)}
        name={allDepartments?.fetchCompanyDepartments?.data.map((e) => e?.name)}
        optionType={optionType}
        register={register}
        handleSubmit={handleSubmit(onShareFolder)}
        loading={shareLoading}
      />
      <DeletePromptModal
        show={show}
        cancel={() => setShow(false)}
        deleteItem={() => {
          onDeleteFolder();
        }}
        loading={deleteLoading}
      />
    </>
  );
};

const Wrapper = styled.div`
  max-width: 100%;
  height: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 8px;
  background: var(--white);
  thead th {
    background: #f3f4f6;
  }

  .document-icon {
    width: 24px;
    aspect-ratio: 1;
  }

  .single-folder {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  hr {
    padding-bottom: 40px;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #23273b;
  }

  .header-title {
    display: flex;
    align-items: center;
    gap: 13px;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #23273b;
    }
  }

  .modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 1010px) {
      flex-direction: row;
    }
  }
  .drive-icon {
    width: 40px;
    height: 40px;
  }

  .tab-section-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 8px;
    -webkit-align-items: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 32px 24px;

    @media (min-width: 1400px) {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding: 32px 24px;
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 104px;
      aspect-ratio: 1;
    }
  }

  .empty-state-header {
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #23273b;
    margin-top: 24px;
  }
  .empty-state-sub-header {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #83889d;
    margin-top: 8px;
  }
`;

const FormWrapper = styled(Box)`
  padding: 24px;
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.117188px;
    color: #23273b;
  }

  form {
    padding: 0;
  }
`;

const ContextMenu = styled.div`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  width: 138px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}

  div {
    padding: 5px;
  }

  div:hover {
    background: #e2e8f0;
    border-radius: 4px;
    padding: 5px;
  }
`;

const DocumentNameWrapper = styled(Flex)`
  .file-name {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
