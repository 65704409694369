import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useToastify } from 'hooks/useToastify';

const ApplicantsFormContext = React.createContext();

function ApplicantsFormProvider(props) {
  const { children, initialValue } = props;
  const init = React.useMemo(() => initialValue || {}, []);
  const { errorToast } = useToastify();
  const formMethods = useForm(init);
  const { control } = formMethods;
  const formState = useFormState({ control });
  window.fm = formState;

  function getErrors(errorObject) {
    const errorList = Object.entries(errorObject);
    if (errorList.length) {
      errorList.forEach(([, errorDetails]) => {
        const { message = '' } = errorDetails || {};
        if (message) errorToast(message);
        if (!message && errorDetails) {
          getErrors(errorDetails);
        }
      });
    }
  }

  React.useEffect(() => {
    getErrors(formState.errors);
  }, [formState.errors]);

  return (
    <ApplicantsFormContext.Provider value={formMethods}>{children}</ApplicantsFormContext.Provider>
  );
}

function useApplicantForm() {
  const context = React.useContext(ApplicantsFormContext);
  if (!context) throw Error('useApplicantForm must be wrapped in ApplicantsFormProvider');
  return context;
}

export { ApplicantsFormProvider, useApplicantForm };
