import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import { FETCH_EXIT_HIRING_REPORT } from 'query';

import { BaseInput, CustomButton, EmptyState, ModalBackButton, SpinnerComponent } from 'components';

import calendar from '../../../../assets/icons/Calendar copy.svg';
import arrowDown from '../../../../assets/images/arrowdown.svg';

import { exportCsv, getCsv, hiringAndExits, modifyHiringExitCsv } from './helpers';

export function HireExits() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    from: '',
    to: '',
  });

  function clearFilter() {
    setFilter({ from: '', to: '' });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFilter((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const { data: hiringexits, loading } = useQuery(FETCH_EXIT_HIRING_REPORT, {
    variables: {
      filters: {
        startDate: filter.from,
        endDate: filter.to,
      },
    },
  });

  let data = {
    datasets: [
      {
        id: 1,
        label: 'additions',
        data: hiringAndExits(hiringexits?.retrieveExitAndHiringReport?.data)?.active || [],
        backgroundColor: ['#80F571'],
      },
      {
        id: 2,
        label: 'exited',
        data: hiringAndExits(hiringexits?.retrieveExitAndHiringReport?.data)?.exited || [],
        backgroundColor: ['#EC5757'],
      },
    ],
    barThickness: 62,

    labels: hiringAndExits(hiringexits?.retrieveExitAndHiringReport?.data)?.label || [],
  };

  return (
    <Stack p={'24px'}>
      <Flex
        border={'solid 1px var(--grey-150)'}
        alignItems={'center'}
        borderRadius={'4px'}
        gap={'24px'}
        p={'24px'}
        backgroundColor={'var(--white)'}>
        <ModalBackButton
          onClick={() => navigate('/admin/reports')}
          data-testid="modal-back-button"
        />
        <Heading className="header-text">Hiring and Exits </Heading>
      </Flex>

      <Flex justifyContent={'flex-end'} padding="30px" alignItems={'end'} gap={'48px'}>
        <Flex justifyContent={'flex-end'} alignItems={'end'} gap={'16px'}>
          <Flex gap={'16px'}>
            <BaseInput
              label={'From'}
              type="date"
              width={'inherit'}
              name={'from'}
              data-testid={'date-from'}
              value={filter.from}
              rightIcon={calendar}
              onChange={handleChange}
            />

            <BaseInput
              label={'To'}
              type="date"
              rightIcon={calendar}
              name={'to'}
              data-testid={'date-to'}
              value={filter.to}
              onChange={handleChange}
            />
          </Flex>
          <Text
            onClick={clearFilter}
            fontSize={'14px'}
            data-testid={'clearFilter'}
            color={'var(--grey-500)'}
            marginBottom={'16px'}
            cursor={'pointer'}>
            Clear Filters
          </Text>
        </Flex>
        <CustomButton
          srcRight={arrowDown}
          name={'Export'}
          mW={'122px'}
          h={'48px'}
          onClick={() => {
            exportCsv(
              getCsv(
                modifyHiringExitCsv,
                hiringAndExits(hiringexits?.retrieveExitAndHiringReport?.data)
              ),
              'hiringExits'
            );
          }}
        />
      </Flex>

      {loading ? (
        <Grid placeItems={'center'} h={'500px'}>
          <SpinnerComponent />
        </Grid>
      ) : (
        <>
          {hiringexits?.retrieveExitAndHiringReport?.data?.length === 0 ? (
            <EmptyState />
          ) : (
            <Box
              padding={'24px'}
              background={'#FFFFFF'}
              border={'1px solid #F2F2F2'}
              borderRadius={'4px'}>
              <h2
                style={{
                  textAlign: 'start',
                  color: 'black',
                  fontWeight: '400px',
                  fontSize: '16px',
                }}
                className="min-text">
                This report shows how many people were hired or terminated in a given period{' '}
              </h2>

              <Bar
                data={data}
                options={{
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </Box>
          )}
        </>
      )}
    </Stack>
  );
}
