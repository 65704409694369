import { Button } from '@chakra-ui/react';

export const BackButton = (props) => {
  return (
    <Button
      background="transparent"
      display="grid"
      gridTemplateColumns="repeat(2, max-content)"
      gap="8px"
      fontSize="1.6rem"
      _hover={{ backgroundColor: '#f2f2f2' }}
      {...props}>
      <CaretLeft />
      {props.children || 'Job Opening'}
    </Button>
  );
};

function CaretLeft() {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.08909 1.58934C7.41453 1.2639 7.41453 0.736263 7.08909 0.410826C6.76366 0.0853888 6.23602 0.0853888 5.91058 0.410826L0.910583 5.41083C0.5951 5.72631 0.584062 6.2343 0.885543 6.56319L5.46888 11.5632C5.77987 11.9025 6.30701 11.9254 6.64628 11.6144C6.98554 11.3034 7.00846 10.7762 6.69747 10.437L2.65329 6.02514L7.08909 1.58934Z"
        fill="#333758"
      />
    </svg>
  );
}
