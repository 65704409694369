import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import {
  EDIT_ANNOUNCEMENT,
  FETCH_ALL_DEPARTMENTS,
  FETCH_ANNOUNCEMENT,
  FETCH_SINGLE_ANNOUNCEMENT,
} from 'query';

import {
  BaseDropdown,
  BaseInput,
  CustomButton,
  CustomCheckbox,
  NameWithImage,
  SpinnerComponent,
} from 'components';

import RichTextEditor from '../../../../../components/admin/jobs/job-details/RichTextEditor';
import { useToastify } from '../../../../../hooks/useToastify';
import { getDepartments, modifyData, modifyFilters } from '../../helpers';
import { CreateAnnouncementWrapper, RichTextEditorWrapper } from '../../styles';

export function EditAnnouncement(props) {
  const [initialValue, setInitialValue] = useState(props.content);
  const [formData, setFormData] = useState({
    title: '',
    status: '',
    sendTo: '',
    department: [],
    isImportant: false,
  });

  const { data, loading } = useQuery(FETCH_ALL_DEPARTMENTS);

  const { successToast } = useToastify();

  const { data: singleAnnouncement } = useQuery(FETCH_SINGLE_ANNOUNCEMENT, {
    variables: {
      announcementId: props.announcementId,
    },
  });

  useEffect(() => {
    let obj = singleAnnouncement?.fetchSingleAnnouncement?.data;
    if (obj !== undefined) {
      setInitialValue(JSON.parse(obj.content));
      setFormData((prev) => {
        return { ...prev, title: obj.title, sendTo: obj.sendTo, isImportant: obj.isImportant };
      });
    }
  }, [singleAnnouncement]);

  const [editAnnouncementPublished, { loading: loadingPublished }] = useMutation(
    EDIT_ANNOUNCEMENT,
    {
      refetchQueries: [
        {
          query: FETCH_ANNOUNCEMENT,
          variables: {
            filter: {
              type: props.type,
              ...modifyFilters(props.filter),
            },
            page: props.page,
          },
        },
      ],
    }
  );
  const [editAnnouncementDraft, { loading: loadingDraft }] = useMutation(EDIT_ANNOUNCEMENT, {
    refetchQueries: [
      {
        query: FETCH_ANNOUNCEMENT,
        variables: {
          filter: {
            type: props.type,
            ...modifyFilters(props.filter),
          },
          page: props.page,
        },
      },
    ],
  });

  function onChange(value) {
    setInitialValue(value);
  }

  async function onSubmitFormPublished(status) {
    await setFormData((prev) => {
      return { ...prev, status };
    });

    const res = await editAnnouncementPublished({
      variables: {
        announcementId: props.announcementId,
        data: {
          type: singleAnnouncement?.fetchSingleAnnouncement?.data?.type,
          title: formData.title,
          content: JSON.stringify(initialValue),
          isImportant: formData.isImportant,
          sendTo: formData.sendTo,
          departmentIds: getDepartments(formData.share, formData.department),
          status,
        },
      },
    });

    if (res?.data?.editAnnouncement?.status === 200) {
      props.closeModal();
      successToast(res?.data?.editAnnouncement?.message);
    }
  }
  async function onSubmitFormDraft(status) {
    await setFormData((prev) => {
      return { ...prev, status };
    });

    const res = await editAnnouncementDraft({
      variables: {
        announcementId: props.announcementId,
        data: {
          type: singleAnnouncement?.fetchSingleAnnouncement?.data?.type,
          title: formData.title,
          content: JSON.stringify(initialValue),
          isImportant: formData.isImportant,
          sendTo: formData.sendTo,
          departmentIds: getDepartments(formData.share, formData.department),
          status,
        },
      },
    });

    if (res?.data?.editAnnouncement?.status === 200) {
      props.closeModal();
      successToast(res?.data?.editAnnouncement?.message);
    }
  }

  let departmentElements = null;

  if (formData.sendTo === 'department') {
    departmentElements = modifyData(data?.fetchCompanyDepartments?.data).map((el) => {
      return (
        <div key={el.value}>
          <CustomCheckbox
            label={el.label}
            value={formData.department}
            onChange={(e) => {
              if (e.target.checked) {
                setFormData((prev) => {
                  return { ...prev, department: [...prev.department, el.value] };
                });
              } else {
                setFormData((prev) => {
                  return {
                    ...prev,
                    department: prev.department.filter((item) => item !== el.value),
                  };
                });
              }
            }}
          />
        </div>
      );
    });
  } else if (formData.sendTo === 'everyone') {
    departmentElements = null;
  }

  return (
    <CreateAnnouncementWrapper>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <form>
          <Stack gap={'24px'}>
            <Stack
              p={'16px 24px'}
              border={'solid 1px var(--grey-150)'}
              borderRadius={'4px'}
              gap={'32px'}>
              <Heading>{props.label}</Heading>
              <Stack gap={'24px'}>
                <BaseInput
                  value={formData.title}
                  data-testid={'announcement-title'}
                  onChange={(e) =>
                    setFormData((prev) => {
                      return { ...prev, title: e.target.value };
                    })
                  }
                  label={'Subject'}
                  labelSize={'12px'}
                  fontW={'600'}
                  padding={'8px'}
                  placeholder={'Subject'}
                  isRequired
                />
                <CustomCheckbox
                  label={'Mark as important'}
                  value={formData.isImportant}
                  checked={formData.isImportant}
                  testid={'isImportant'}
                  onChange={() =>
                    setFormData((prev) => {
                      return { ...prev, isImportant: !prev.isImportant };
                    })
                  }
                />
              </Stack>

              <Stack>
                <Text fontSize={'12px'} fontWeight={'600'}>
                  Content
                </Text>
                <RichTextEditorWrapper>
                  <RichTextEditor
                    initialValue={initialValue}
                    onChange={onChange}
                    placeholder={'Enter Content'}
                  />
                </RichTextEditorWrapper>
              </Stack>

              <Stack gap={'32px'}>
                <BaseDropdown
                  dataTestId={'shareWith'}
                  placeholder={'share with'}
                  w={'223px'}
                  value={formData.sendTo}
                  onChange={(e) =>
                    setFormData((prev) => {
                      return { ...prev, sendTo: e.value };
                    })
                  }
                  options={[
                    { label: 'Share to Everyone', value: 'everyone' },
                    { label: 'Share to Departments', value: 'department' },
                  ]}
                />

                {formData.share === 'department' && (
                  <Stack gap={'16px'}>{departmentElements}</Stack>
                )}
              </Stack>
              <Flex gap={'32px'} fontWeight={'600'} alignItems={'center'}>
                <Text fontSize={'12px'} color={'var(--grey-500)'}>
                  Issued by:{' '}
                </Text>
                <NameWithImage data={props.issuedBy} />
              </Flex>
            </Stack>

            <Flex justifyContent={'flex-end'} gap={'8px'}>
              <CustomButton
                name={'Save as Draft'}
                variant={'black--outline'}
                mW={'138px'}
                h={'43px'}
                type={'button'}
                loading={loadingDraft}
                onClick={() => onSubmitFormDraft('draft')}
              />
              <CustomButton
                h={'43px'}
                name={'Cancel'}
                variant={'red--outline'}
                onClick={props.closeModal}
                mW={'86px'}
              />
              <CustomButton
                name={'Publish'}
                variant={'black'}
                mW={'98'}
                h={'43px'}
                type={'button'}
                loading={loadingPublished}
                onClick={() => onSubmitFormPublished('published')}
              />
            </Flex>
          </Stack>
        </form>
      )}
    </CreateAnnouncementWrapper>
  );
}
