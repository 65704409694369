import { useQuery } from '@apollo/client';

import { RETRIEVE_GOALS_STATUS, RETRIEVE_REVIEWS_STATUS } from '../../../../query';

export function useOverviewView() {
  const reviewsSummary = {
    'In progress': 0,
    Overdue: 0,
    Scheduled: 0,
    Completed: 0,
  };

  const goalsSummary = {
    'In progress': 0,
    Overdue: 0,
    Scheduled: 0,
    Completed: 0,
  };

  const { data: goalsData, loading: goalsLoading } = useQuery(RETRIEVE_GOALS_STATUS);
  const { data: reviewsData, loading: reviewsLoading } = useQuery(RETRIEVE_REVIEWS_STATUS);
  const goalStatuses = goalsData?.retrieveGoalStatus?.data;
  const reviewStatuses = reviewsData?.retrieveReviewStatus?.data;

  goalStatuses?.forEach((goalStatus) => {
    const status = goalStatus.status.replace('_', ' ');
    const key = status.slice(0, 1).toUpperCase() + status.slice(1);
    goalsSummary[key] = goalStatus.count;
  });

  reviewStatuses?.forEach((reviewStatus) => {
    const status = reviewStatus.status.replace('_', ' ');
    const key = status.slice(0, 1).toUpperCase() + status.slice(1);
    reviewsSummary[key] = reviewStatus.count;
  });

  return {
    reviewsSummary,
    goalsSummary,
    reviewsLoading,
    goalsLoading,
  };
}
