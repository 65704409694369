import React from 'react';
import styled from '@emotion/styled';

const InputWrap = { display: 'grid', gap: '8px' };

const InputField = React.forwardRef(({ placeholder, label, styles, ...rest }, ref) => {
  return (
    <div style={InputWrap || styles} ref={ref}>
      <Label htmlFor="">{label}</Label>
      <InputFieldItem id="assessmentTitle" name={label} placeholder={placeholder} {...rest} />
    </div>
  );
});

InputField.displayName = 'InputField';

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.117188px;
  color: #333758;
`;

const InputFieldItem = styled.input`
  padding: 12px 16px;
  height: 40px;
  background: #fafcff;
  border-radius: 3px;

  &:focus {
    outline: none;
  }
`;

export { InputField };
