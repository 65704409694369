import React from 'react';
import { useMutation } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { useToastify } from 'hooks/useToastify';
import { EDIT_ROLE } from 'query';

import { CustomButton, Pagination, Search, TableComponent } from 'components';

import plus from '../../../../../assets/icons/plusLarge.svg';
import { createActionHandler } from '../../../../../utils/handlers';

import { OnboardingHooks } from './hooks';
import { RolesPageContainer } from './styles';
import { rolesCols } from './tableConfig';

export const RolesTable = (props) => {
  const { setShowEdit, editAction } = props;
  const [search, setSearch] = React.useState('');
  const {
    page,
    totalPages,
    setPage,
    handleChecked,
    checkedBool,
    checked,
    rolesTableData,
    rolesData,
    loading,
    refetch,
    deleteAction,
    setSearchString,
  } = OnboardingHooks();

  const { successToast, errorToast } = useToastify();

  const [editRole] = useMutation(EDIT_ROLE);

  const statusChangeAction = async ({ action, id }) => {
    const new_status = action === 'deactivate' ? 'deactivated' : 'active';

    const editStatusData = {
      _id: id,
      status: new_status,
    };
    try {
      const res = await editRole({ variables: { data: editStatusData } });
      if ([201, 200].includes(res.status)) {
        const message = res?.data?.editRole?.message;
        successToast(message);
      }
    } catch (error) {
      errorToast('Error Occured');
    } finally {
      refetch();
    }
  };

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const actionOnData = createActionHandler({ editAction, statusChangeAction, deleteAction });

  const rolesdropdownActions = ['Edit', 'Deactivate', 'Activate', 'Delete'];

  return (
    <RolesPageContainer data-testid="roles-page">
      <div className="header-wrapper">
        <Search
          placeholder={'Search Role'}
          value={search}
          setSearchString={setSearchString}
          setPage={setPage}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
        <CustomButton
          onClick={() => setShowEdit(true)}
          name={'Create Role'}
          variant={'primary'}
          h={'4.8rem'}
          fW={'500'}
          bR={'0.4rem'}
          fS={'1.6rem'}
          mW={'15rem'}
          src={plus}
        />
      </div>

      <div className="roles-table-wrapper roles-table">
        <TableComponent
          noSort
          noCheckbox
          loading={loading}
          data={rolesTableData}
          columns={rolesCols}
          dropdownActions={rolesdropdownActions}
          handleChecked={handleChecked}
          checked={checked}
          checkedBool={checkedBool}
          ids={rolesData?.map((e) => e._id)}
          status={rolesData?.map((e) => e.status)}
          actionOnData={actionOnData}
          empty={rolesData?.length === 0}
        />
      </div>
      <Box mt="1rem" position="fixed" right={'2.8rem'} left={'29rem'} bottom={'2.4rem'}>
        {totalPages && <Pagination page={page} totalPages={totalPages || 1} setPage={setPage} />}
      </Box>
    </RolesPageContainer>
  );
};
