import styled from '@emotion/styled';

export const RequestRoot = styled.div`
  background: var(--white);
  border: 1px solid var(--grey-150);
  border-radius: 0.4rem;
  height: 76.3rem;
  padding: 2.4rem;

  .table--root {
    overflow-x: auto;
    margin-top: 2.4rem;
    padding-bottom: 5rem;
  }

  thead tr {
    background: #f3f4f6;
  }

  thead th,
  tbody td {
    max-width: 20rem;
  }
  tbody td:first-letter {
    text-transform: capitalize;
  }

  thead th:first-of-type {
    padding-left: 1.6rem;
  }
  thead th:last-child,
  tbody td:last-child {
    padding-left: 0.8rem;
  }

  tbody td:first-of-type {
    padding-left: 1.6rem;
  }
`;
