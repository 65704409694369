import { stringToOption } from 'utils';

function EmployeeEditHook({ editData }) {
  const optionsFromApi = ['gender', 'maritalStatus', 'bloodType', 'bloodGroup'];

  optionsFromApi.forEach((field) => {
    const option = editData[field];
    editData[field] = { label: option, value: option };
  });

  const formatIncomingDate = (dateVal) => {
    return dateVal?.substr(0, 10);
  };

  const checkAddressLength = (address) => {
    return address?.length === 1 ? [...address, {}] : address;
  };

  const employeeEditFormData = {
    ...editData,
    dob: formatIncomingDate(editData?.dob),
    employmentInfo: {
      ...editData?.employmentInfo,
      hireDate: formatIncomingDate(editData?.employmentInfo?.hireDate),
      employmentType: stringToOption(editData?.employmentInfo?.employmentType),
    },
    address: editData?.address?.length === 0 ? [{}, {}] : checkAddressLength(editData?.address),
    department: editData?.department
      ? { label: editData?.department?.name, value: editData?.department?._id }
      : {},
    designation: editData?.designation
      ? { label: editData?.designation?.name, value: editData?.designation?._id }
      : {},
    bankAccount: {
      ...editData?.bankAccount,
      accountType: stringToOption(editData?.bankAccount?.accountType),
      paymentType: stringToOption(editData?.bankAccount?.paymentType),
    },
    emergencyContact: {
      ...editData?.emergencyContact,
    },
  };

  return {
    employeeEditFormData,
  };
}

export { EmployeeEditHook };
