import React from 'react';
import { useDrag } from 'react-dnd';
import { Box, Grid } from '@chakra-ui/react';

import { useScoreCardContext } from '../../../../../../context';
import { DND_TYPES } from '../../../../../../utils';

function DraggableScoreCards() {
  const { list } = useScoreCardContext();
  return (
    <Box p="1.6rem 2.4rem">
      <Box fontSize="1.4rem" fontWeight="600" mb="2.4rem">
        Drag & Drop Field
      </Box>
      <Grid templateColumns="1fr" gap="1.6rem">
        {list.map((item) => (!item.stage ? <DraggableCard key={item.id} item={item} /> : null))}
      </Grid>
    </Box>
  );
}

function DraggableCard({ item }) {
  const [{ isDragging }, drag] = useDrag({
    item,
    type: DND_TYPES.ScoreCard,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <Grid
      opacity={isDragging ? '0.4' : 1}
      bgColor="brand.bg"
      py="1.5rem"
      placeContent="center"
      textAlign="center"
      border={isDragging ? '1px dashed var(--grey-300)' : '1px solid var(--grey-150)'}
      userSelect="none"
      cursor="move"
      ref={drag}>
      {item.title}
    </Grid>
  );
}

export { DraggableScoreCards };
