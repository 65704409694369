import React from 'react';
import { Box } from '@chakra-ui/react';
import { Chart, registerables } from 'chart.js';

import { labelColors } from './OverviewView';

Chart.register(...(registerables ?? []));

export function GoalsChart({ summary }) {
  const canvasRef = React.createRef();
  const ctxRef = React.createRef();
  const chartRef = React.createRef();
  const chartData = React.useMemo(() => {
    const data = [];
    const labels = [];
    const colors = [];
    Object.entries(summary).forEach(([label, value]) => {
      data.push(value);
      labels.push(label);
      colors.push(labelColors[label]);
    });
    return { data, labels, colors };
  }, [summary]);

  function mountChart() {
    const canvasEl = canvasRef.current;
    if (!ctxRef.current) ctxRef.current = canvasEl?.getContext('2d');
    chartRef.current?.destroy();
    chartRef.current = new Chart(ctxRef.current, {
      type: 'doughnut',
      options: {
        cutout: '70%',
        plugins: {
          legend: { display: false },
        },
      },
      data: {
        labels: chartData.labels,
        datasets: [
          {
            data: chartData.data,
            backgroundColor: chartData.colors,
            borderWidth: 0,
          },
        ],
      },
    });
  }

  React.useEffect(() => {
    const canvasEl = canvasRef.current;
    if (!canvasEl) return;
    try {
      mountChart();
    } catch {
      mountChart();
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, []);
  return (
    <Box w="200px" maxW="100%" mx="auto" position="relative" pt="100%">
      <Box position="absolute" inset="0">
        <canvas id="goal-chart" style={{ width: '100%', height: '100%' }} ref={canvasRef}></canvas>
      </Box>
    </Box>
  );
}
