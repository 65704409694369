import { useEffect, useState } from 'react';

function useOnboardDetailsView() {
  let tabs = [
    { displayName: `Basics`, value: 'basics' },
    { displayName: `Employment `, value: 'employment' },
    { displayName: `Contacts `, value: 'contacts' },
    { displayName: `Emergency `, value: 'emergency' },
    { displayName: `Account `, value: 'account' },
  ];

  const Tabs = {
    basics: 'basics',
    employment: 'employment',
    contacts: 'contacts',
    emergency: 'emergency',
    account: 'account',
  };
  const [selectedButton, setSelectedButton] = useState('basics');

  const setSelected = (tab) => {
    Object.keys(Tabs).forEach((key) => {
      if (Tabs[key] === tab) {
        setSelectedButton(Tabs[key]);
      }
    });
  };

  const setPreviousTab = () => {
    tabs.map((key, index) => {
      return selectedButton === key.value
        ? setSelectedButton(tabs[index - 1].value)
        : selectedButton;
    });
  };
  const setNextTab = () => {
    tabs.map((key, index) => {
      return selectedButton === key.value
        ? setSelectedButton(tabs[index + 1].value)
        : selectedButton;
    });
  };

  useEffect(() => {
    setSelected(selectedButton);
  }, [selectedButton]);

  return {
    tabs,
    setSelected,
    selectedButton,
    setPreviousTab,
    setNextTab,
  };
}

export { useOnboardDetailsView };
