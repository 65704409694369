import { gql } from '@apollo/client';

export const FETCH_APPLICANT_APPLICATION = gql`
  query fetchJobApplications($filter: ApplicantFilter, $page: Int!) {
    fetchJobApplications(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      totalCount
      data {
        _id
        title
        country
        type
        createdBy {
          _id
          firstName
          lastName
          imageUrl
        }
        department {
          _id
          name
        }
        applicants {
          _id
          firstName
          lastName
          imageUrl
          createdAt
          email
          applicationRating
          stage {
            _id
            title
            rank
          }
          stageDoc {
            _id
            title
            rank
          }
        }
        createdAt
      }
    }
  }
`;

export const SCHEDULE_INTERVIEW = gql`
  mutation scheduleInterview($data: InterviewInputData!) {
    scheduleInterview(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const STAGING = gql`
  query Query {
    fetchStages {
      status
      message
      data {
        _id
        title
        colorCode
        rank
      }
    }
  }
`;

export const UPDATE_APPLICATION_STAGE = gql`
  mutation updateApplicationStage($data: ApplicationStageData!) {
    updateApplicationStage(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const FETCH_SINGLE_APPLICANT = gql`
  query fetchSingleApplication($filter: ApplicantFilter) {
    fetchSingleApplication(filter: $filter) {
      status
      message
      data {
        _id
        firstName
        lastName
        imageUrl
        email
        createdAt
        jobId {
          _id
          title
          createdBy {
            _id
            firstName
            lastName
            imageUrl
          }
          department {
            _id
            name
          }
        }
        stage {
          _id
          title
          colorCode
          rank
        }
        applicationData {
          name
          value
          type
          children {
            type
            name
            value
          }
        }
      }
    }
  }
`;

export const FETCH_JOBS = gql`
  query Query($filter: JobFilters, $page: Int!) {
    fetchJobs(filter: $filter, page: $page) {
      status
      message
      hasNextPage
      totalCount
      data {
        _id
        title
        city
        state
        country

        type
        applicants {
          _id
          firstName
          lastName
          email
        }
        status
      }
    }
  }
`;

export const FETCH_APPLICATION_FILTER_FIELDS = gql`
  query fetchApplicationFilterFields($filter: ApplicantFilter) {
    fetchApplicationFilterFields(filter: $filter) {
      status
      message
      data {
        _id
        jobId
        filters {
          name
          type
          value
          options
        }
      }
    }
  }
`;

export const RUN_APPLICATION_FILTER_TRACKER = gql`
  mutation runApplicationFilterTracker($data: JobApplicationFilterDataInput!) {
    runApplicationFilterTracker(data: $data) {
      status
      message
      data {
        _id
        jobId
        filters {
          name
          type
          value
          options
        }
      }
    }
  }
`;

export const FETCH_INTERVIEWS = gql`
  query fetchInterview($filter: InterviewFilter!) {
    fetchInterview(filter: $filter) {
      status
      message
      data {
        _id
        interviewName
        date
        startTime
        endTime
        address
        interviewers {
          _id
        }
        link
        type
        additionalNotes
        createdAt
        createdBy {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export const UPDATE_INTERVIEWS = gql`
  mutation editInterview($filters: UpdateFilter!, $data: UpdateInterviewInput!) {
    editInterview(filters: $filters, data: $data) {
      status
      message
      data {
        _id
        interviewName
        interviewers {
          _id
        }
        date
        startTime
        endTime
        type
        link
      }
    }
  }
`;

export const DELETE_INTERVIEW = gql`
  mutation removeInterview($interviewId: ObjectID!) {
    removeInterview(interviewId: $interviewId) {
      status
      message
      data {
        _id
        interviewName
        interviewers {
          _id
        }
        date
        startTime
        endTime
        type
        link
      }
    }
  }
`;
