import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { DELETE_LEAVE, FETCH_EMPLOYEE_LEAVE, FETCH_LEAVE_HISTORY } from 'query';

import { CustomButton } from 'components';

const DeletePrompt = ({ cancel, leaveId }) => {
  const [deleteLeave, { loading }] = useMutation(DELETE_LEAVE);

  const { errorToast } = useToastify();

  const navigate = useNavigate();

  async function deleteRquest() {
    try {
      const res = await deleteLeave({
        variables: { leaveId: leaveId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: FETCH_EMPLOYEE_LEAVE,
          },
          {
            query: FETCH_LEAVE_HISTORY,
          },
        ],
      });
      const message = res?.data?.deleteLeaveRequest?.message;
      errorToast(message);
    } catch (error) {
      return error;
    } finally {
      cancel({ showModal: false });
      navigate('/employee/leave');
    }
  }

  return (
    <DeleteRoot data-testid="approve-prompt">
      <h2>Are you sure?</h2>
      <p>This action will delete this leave request</p>

      <Flex justifyContent={'flex-end'} gap="0.8rem" mt="6.4rem">
        <CustomButton
          onClick={() => cancel({ showModal: false })}
          name="Cancel"
          variant="black--outline"
          mW="9.5rem"
          h="4.3rem"
        />
        <CustomButton
          onClick={deleteRquest}
          loading={loading}
          name="Delete"
          mW="9.5rem"
          h="4.3rem"
          bgColor={'var(--error)'}
        />
      </Flex>
    </DeleteRoot>
  );
};

const DeleteRoot = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 1.6rem 2.4rem;
  border: 1px solid var(--grey-150);
  border-radius: 0.4rem;

  h2 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.117188px;
    color: var(--black);
    margin-bottom: 1.6rem;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: var(--black);
  }
`;

export { DeletePrompt };
