import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Box, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Pagination, Search, SpinnerComponent, TableComponent } from 'components';
import { Status } from 'components/tables/TableConfig';

import { useReviewCycleForm } from '../../../../../../context/reviewCycleForm';
import { FETCH_ALL_EMPLOYEES } from '../../../../../../query';
import { formatDate } from '../../../../../../utils';
import { UserImage } from '../../../../employee/tableConfig';

import { tableColumns } from './TableData';

export function Employees() {
  // const [checked, setChecked] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [sortBy, setSortBy] = useState('');
  const [setTData] = React.useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = React.useState('');
  const [searchString, setSearchString] = React.useState('');
  const filters = { isDefaultAdmin: false };

  const { control, setValue, getValues } = useReviewCycleForm();
  const reviewCycle = useWatch({ control, name: 'reviewCycle' }) ?? [];
  const checked = reviewCycle.map((empObj) => empObj.employee);

  const [selectAll, setSelectAll] = React.useState(false);

  function setChecked(val) {
    if (typeof val === 'function') setValue('reviewCycle', val(checked));
    else {
      const value = val.map((empId) => (typeof empId === 'object' ? empId : { employee: empId }));
      setValue('reviewCycle', value);
    }
  }

  const { data, loading } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      page: page,
      filters: searchString ? { ...filters, searchString: searchString } : { ...filters },
    },
  });
  window.g = getValues;

  React.useEffect(() => {
    const employeesData = data?.fetchUsersFiltered?.data || [];
    setTableData(employeesData);
  }, [data, searchString]);

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const newTableData = tableData.map(
    ({ firstName, imageUrl, lastName, employmentInfo, status, team, gradeLevel }) => {
      const name = `${firstName} ${lastName}`;
      return {
        col1: UserImage({ name, imageUrl, firstName, lastName }),
        col2: team?.name || '',
        col3: gradeLevel?.name || '',
        col4: formatDate(employmentInfo.hireDate) || '',
        col5: Status({ status }) || '',
      };
    }
  );

  const keys = {
    Name: 'name',
    Team: 'team',
    status: 'status',
    'Date Hired': 'date_hired',
  };

  const handleChecked = (id) => {
    setChecked(() =>
      checked.includes(id)
        ? reviewCycle.filter((cur) => cur.employee !== id)
        : [...reviewCycle, { employee: id }]
    );
  };

  const handleSelectAll = (e) => {
    const { checked: isChecked } = e.target;
    if (!isChecked) return setChecked([]);
    let checkedCopy = Array(tableData?.length);
    tableData?.forEach((elem, i) => {
      checkedCopy[i] = { employee: elem._id };
    });
    setChecked(checkedCopy);
  };

  function modSetSelectAll(val) {
    setSelectAll(val);
    handleSelectAll({ target: { checked: val } });
  }

  React.useEffect(() => {
    setSelectAll(() => tableData.every((emp) => checked.includes(emp._id)));
  }, [checked]);

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];
    const sortedData = tableData?.map((e) => {
      return {
        ...e,
        date_hired: e?.employmentInfo?.hireDate || '',
        name: `${e.firstName} ${e.lastName}`,
        team: e?.team || '',
      };
    });
    if (key) {
      sortedData.sort((a, b) => {
        return desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
      });
      setTData(sortedData);
    }
  };
  const dropdownActions = {
    edit: 'edit',
  };
  const actionOnData = () => {};
  const checkedBool = tableData?.map((emp) => {
    return checked.includes(emp._id);
  });

  return (
    <Wrapper overflowX={'auto'}>
      <Box my="24px">
        <Search
          placeholder={'Search Employee'}
          value={search}
          setPage={setPage}
          setSearchString={setSearchString}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </Box>
      {!loading ? (
        <Stack>
          <TableComponent
            //   noCheckbox
            key={sortBy}
            checked={checked}
            columns={tableColumns}
            data={newTableData}
            ids={tableData?.map((e) => e._id)}
            dropdownActions={dropdownActions}
            actionOnData={actionOnData}
            checkedBool={checkedBool}
            handleMultiSortBy={handleMultiSortBy}
            handleChecked={handleChecked}
            setAllChecked={modSetSelectAll}
            allChecked={selectAll}
          />
          {
            <Pagination
              page={page}
              totalPages={data?.fetchUsersFiltered?.pageCount || 1}
              setPage={setPage}
            />
          }
        </Stack>
      ) : null}
      {loading ? <SpinnerComponent h="20rem" /> : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: var(--white);

  thead th {
    background: #f3f4f6;
  }
`;
