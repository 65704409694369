import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReviewCycleFormProvider } from 'context/reviewCycleForm';

import { BaseButton, FullModal, ModalBackButton, SimpleModal } from '../../../../../components';

import { ReviewDetails } from './components/ReviewDetails';
import { Employees } from './Employees/Employees';
import { Reviewers } from './Reviewers/Reviewers';
import Summary from './summary/Summary';
import { FlatSteps, SelectTemplate } from './components';

export function CreateReview(props) {
  const steps = [
    { name: 'Review Details', component: ReviewDetails },
    { name: 'Employees', component: Employees },
    { name: 'Reviewers', component: Reviewers },
    { name: 'Select Template', component: SelectTemplate },
  ];
  const [activeStep, setActiveStep] = React.useState(() => 0);
  const [showEndModal, setShowEndModal] = React.useState(false);
  const ActiveComponent = steps[activeStep]['component'];

  const handleBackClick = () => {
    if (activeStep === 0) props.setShowModal(false);
    else setActiveStep(activeStep - 1);
  };

  const handleNextClick = () => {
    if (activeStep < steps.length - 1) setActiveStep(activeStep + 1);
    else setShowEndModal(true);
  };

  return (
    <Wrapper>
      <FullModal {...props} noHeader mt="8.3rem">
        <header className="header">
          <ModalBackButton onClick={handleBackClick} />
          <span className="header-text">Create a review cycle</span>
          <Flex justifyContent="flex-end">
            {activeStep < steps.length ? (
              <>
                <Box w="8px"></Box>
                <BaseButton onClick={handleNextClick}>Next</BaseButton>
              </>
            ) : null}
          </Flex>
        </header>

        <Box maxW="1106px" mx="auto">
          <FlatSteps
            steps={steps}
            active={activeStep}
            setActive={setActiveStep}
            position="sticky"
            zIndex="20"
            top="88px"
            bg="white"
            py="24px"
          />
          <ReviewCycleFormProvider>
            <ActiveComponent />
            <SimpleModal showModal={showEndModal} setShowModal={setShowEndModal}>
              <Box w="833px" maxW="100%" bg="brand.white" p="24px">
                <Summary setShowEndModal={setShowEndModal} setShowModal={props.setShowModal} />
              </Box>
            </SimpleModal>
          </ReviewCycleFormProvider>
        </Box>
      </FullModal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .header {
    position: sticky;
    z-index: 20;
    top: 0;
    padding: 2.4rem 0;
    display: grid;
    grid: 1fr / max-content max-content auto;
    gap: 2.4rem;
    align-items: center;
    background-color: var(--white);

    &-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.05em;
    }
  }
`;
