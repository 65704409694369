import React, { useState } from 'react';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';

import addscorecard from '../../../../../assets/icons/addscorecard.svg';
import { Button, EditCard, ScoreCard } from '../../../../../components';
import { useScoreCardContext } from '../../../../../context';

const Container = styled.div`
  max-width: 746px;
  border: 1px solid #f2f2f2;
  padding: 24px 32px;
  //margin-bottom: 16px;

  .heading {
    font-weight: 600;
    font-size: 20px;
    color: #23273b;
  }
`;

const AddCard = {
  padding: '8px 24px',
  border: '1px solid #3E7EEE',
  borderRadius: '4px',
  color: '#3E7EEE',
};

const ScoreCardView = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const scoreCardType = [
    { label: 'Rating and comments', value: 'Rating and comments' },
    { label: 'Rating', value: 'Rating' },
  ];

  const { list, setList } = useScoreCardContext();

  const initialState = {
    id: '',
    title: '',
    hint: '',
    stage: null,
    type: {
      label: '',
      value: '',
    },
  };

  const [state, setState] = useState(initialState);

  const RemoveCard = (id) => {
    const newList = list.filter((item) => item.id !== id);
    setList(newList);
  };

  const handleChange = (e) => {
    const { name, value, label } = e.target;

    if (label) {
      setState((prevState) => ({
        ...prevState,
        [name]: { label, value },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const isDisabled = !(state?.title && state?.hint && state?.type.label);

  const onSave = () => {
    const newList = list?.concat({ ...state, id: uuidv4() });
    setList(newList);
    onDelete();
  };

  const onDelete = () => {
    setIsOpen(false);
    setState(initialState);
  };

  return (
    <Container style={props.style} className="scroll-bar">
      <h1 className="heading">Customize Score Card</h1>
      <ScoreCard
        handleRemove={RemoveCard}
        list={list}
        setList={setList}
        handleChange={handleChange}
        scoreCardType={scoreCardType}
        onSave={onSave}
        onDelete={onDelete}
        state={state}
        setState={setState}
        isDisabled={isDisabled}
      />
      <EditCard
        Heading={'Add Score Card'}
        isOpen={isOpen}
        handleChange={handleChange}
        scoreCardType={scoreCardType}
        onSave={onSave}
        onDelete={onDelete}
        isDisabled={isDisabled}
      />

      <Button
        style={AddCard}
        img={addscorecard}
        title={'Add Score Card'}
        onClick={() => setIsOpen(true)}
      />
    </Container>
  );
};

export { ScoreCardView };
