import React from 'react';
import { GridItem } from '@chakra-ui/react';
import { APPLICANTS_FORM_BREAKPOINT } from 'utils';

const SpanDouble = React.forwardRef(function ({ md, ...props }, ref) {
  const span = { colSpan: { base: 1, [md ? 'md' : [APPLICANTS_FORM_BREAKPOINT]]: 2 } };
  return <GridItem {...span} {...props} w="100%" position="relative" ref={ref} />;
});

SpanDouble.displayName = 'SpanDouble';

const SpanSingle = React.forwardRef(function (props, ref) {
  const span = { colSpan: 1 };
  return <GridItem {...span} {...props} w="100%" position="relative" ref={ref} />;
});
SpanSingle.displayName = 'SpanSingle';

export { SpanDouble, SpanSingle };
