import { Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';

import AsideDetail from './AsideDetail';

function ConfigureOtherDetails() {
  return (
    <ConfigurePanelRoot>
      <header className="header">Job Details</header>
      <Grid templateColumns="100%" bg="white" p="1.2rem">
        {asideContent.map((item) => (
          <AsideDetail key={item.label} item={item} />
        ))}
      </Grid>
    </ConfigurePanelRoot>
  );
}

const asideContent = [
  { label: 'Job Created', name: 'createdAt', type: 'date', canEdit: false },
  { label: 'Hiring Manager', name: 'hiringManager', type: 'dropdown', canEdit: false },
  { label: 'Job Deadline', name: 'deadline', type: 'date', canEdit: true },
  { label: 'Department', name: 'department', type: 'dropdown', canEdit: true },
  { label: 'Job Type', name: 'type', type: 'dropdown', canEdit: true },
  { label: 'Experience', name: 'experience', type: 'dropdown', canEdit: true },
  { label: 'Country', name: 'country', type: 'text', canEdit: true },
  { label: 'State', name: 'state', type: 'text', canEdit: true },
  { label: 'City', name: 'city', type: 'text', canEdit: true },
  { label: 'Salary', name: 'salary', type: 'text', canEdit: true },
];

const ConfigurePanelRoot = styled.div`
  border: 1px solid var(--grey-100);
  border-radius: 8px 8px 0 0;

  .header {
    height: 5.6rem;
    display: flex;
    align-items: center;
    padding-inline: 2.4rem;
    border-radius: 8px 8px 0 0;
    background: var(--grey-150);
    font-weight: 600;
    top: 0;
  }

  @media (min-width: 768px) {
    position: sticky;
    top: 0;
  }
`;

export { ConfigureOtherDetails };
