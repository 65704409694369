import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Heading, Stack, Text, Textarea } from '@chakra-ui/react';

import { CustomButton, SpinnerComponent } from 'components';

import { useToastify } from '../../../../../../hooks/useToastify';
import { EDIT_QUERY, FETCH_QUERIES } from '../../../query';
import { formatDate } from '../../helpers';

const isTesting = process.env.NODE_ENV === 'test';
export function NotificationModal(props) {
  const { data, loading: queryLoading } = useQuery(FETCH_QUERIES, {
    variables: {
      filter: {
        _id: props.note?.data?.action_id,
      },
    },
  });

  const [editQuery, { loading }] = useMutation(EDIT_QUERY);
  const [showTextarea, setTextarea] = useState(false);
  const [response, setResponse] = useState(data?.fetchQueries?.data[0]?.response);
  const { successToast } = useToastify();

  async function handleSubmit() {
    if (!showTextarea) {
      setTextarea(true);
      return;
    }

    if (response === '') return;
    const res = await editQuery({
      variables: {
        filters: {
          _id: props.note?.data?.action_id,
        },
        data: {
          status: 'active',
          response: response,
        },
      },
    });

    if (res?.data?.editQuery?.status === 200) {
      props.closeModal();
      successToast(res.data?.editQuery?.message);
    }
  }

  const newsFeed = ['news', 'memo', 'announcement'];

  return (
    <div className="notification-modal">
      {queryLoading ? (
        <SpinnerComponent />
      ) : (
        <>
          <Stack
            p={'1.6rem'}
            gap={'1.6rem'}
            border={'solid 1px var(--grey-150)'}
            borderRadius={'0.4rem'}>
            {props?.note?.type === 'alert' || data?.fetchQueries?.data[0]?.response ? (
              <Heading>Notification</Heading>
            ) : (
              <Heading>Action Required?</Heading>
            )}
            {props?.note?.type === 'alert' ? (
              <>
                {newsFeed.includes(props?.note?.data?.category) ? (
                  <Stack color={'var(--grey-500)'}>
                    <Text _hover={{ textDecoration: 'underline' }}>
                      <Link to={'/employee/announcements'}>
                        click to open news feed: <strong>{props?.note?.data?.message}</strong>
                      </Link>
                    </Text>
                  </Stack>
                ) : (
                  <Text color={'var(--grey-500)'}>{props?.note?.data?.message}</Text>
                )}
              </>
            ) : (
              <Stack>
                <Text color={'var(--grey-500)'}>
                  {' '}
                  Subject: {props?.note?.data?.message.split('.').shift()}
                </Text>
                <Text color={'var(--grey-500)'}>
                  {props?.note?.data?.message.split('.').splice(1)}
                </Text>
              </Stack>
            )}

            {showTextarea && (
              <Stack>
                <Text fontWeight={'500'}>Response</Text>
                <Textarea
                  data-testid={'response-input'}
                  placeholder={'Response'}
                  value={response}
                  name={response}
                  onChange={(e) => setResponse(e.target.value)}
                  fontSize={'1.6rem'}
                  backgroundColor={'#FAFCFF'}
                  h={'19.1rem'}
                />
              </Stack>
            )}

            {data?.fetchQueries?.data[0]?.response && (
              <>
                <Text fontWeight={'500'}>Response</Text>
                <Stack
                  background={'#FAFCFF'}
                  h={'19.1rem'}
                  p={'16px'}
                  border={'solid 1px var(--grey-150)'}
                  borderRadius={'4px'}>
                  <Text>{data?.fetchQueries?.data[0]?.response}</Text>
                </Stack>
              </>
            )}

            <Box>
              <Text>
                <strong>Date:</strong> {formatDate(props?.note?.createdAt)}
              </Text>
              <Text textTransform={'capitalize'}>
                <strong>Time:</strong>{' '}
                {new Date(props?.note?.createdAt).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              </Text>
            </Box>

            <Flex justifyContent={'flex-end'} gap={'1rem'}>
              <CustomButton
                name={'Cancel'}
                fS={'1.4rem'}
                h={'4.3rem'}
                onClick={props?.closeModal}
                mW={'10.5rem'}
                variant={'black--outline'}
              />

              {((!data?.fetchQueries?.data[0]?.response &&
                props?.note?.type === 'action_required') ||
                isTesting) && (
                <CustomButton
                  name={'Response'}
                  h={'4.3rem'}
                  mW={'10.5rem'}
                  variant={'black'}
                  fS={'1.4rem'}
                  loading={loading}
                  onClick={handleSubmit}
                />
              )}
            </Flex>
          </Stack>
        </>
      )}
    </div>
  );
}
