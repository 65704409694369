import React from 'react';
import { Box, Grid } from '@chakra-ui/react';

import { ParticipantsTypeStack } from './ParticipantsTypeStack';
import { ResponseAccessStack } from './ResponseAccessStack';
import { ReviewPeriodStack } from './ReviewPeriodStack';
import { ScheduleStack } from './ScheduleStack';
import { TitleStack } from './TitleStack';

export function ReviewDetails(props) {
  return (
    <Box
      maxW="752px"
      mx="auto"
      mt="24px"
      p="24px 32px"
      border="1px solid transparent"
      borderColor="brand.grey.150"
      {...props}>
      <Grid templateColumns="1fr" gap="40px">
        <TitleStack />
        <ScheduleStack />
        <ReviewPeriodStack />
        <ResponseAccessStack />
        <ParticipantsTypeStack />
      </Grid>
    </Box>
  );
}
