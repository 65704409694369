import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { FullModal, ModalBackButton } from 'components';

import { AssessmentQuestions } from './QuestionsView';
import { PreviewRoot } from './style';

export const PreviewQuestions = ({ pData, show, setShow }) => {
  return (
    <PreviewRoot data-testid="preview-page">
      <FullModal showModal={show} noHeader mt="8rem">
        <Flex gap="2.4rem" className="modal--header">
          <ModalBackButton onClick={() => setShow(false)} data-testid="modal-back-btn" />
          <Text className="assessment-title" textTransform={'capitalize'}>
            {pData?.title}
          </Text>
        </Flex>

        <div className="assessment__content">
          <div className="assessment__summary">
            <div className="assessment__summary--header">
              <p>Section</p>
              <p>Duration</p>
              <p>Deadline</p>
            </div>
            <div className="assessment__summary--row">
              <p>{pData?.sections?.length}</p>
              <p>{pData?.duration}</p>
              <p>{pData?.deadline}</p>
            </div>
          </div>

          {pData?.sections?.map((sec, j) => {
            return (
              <div key={sec?.name} className="assessment__section">
                <div className="top">
                  <p className="title">
                    <span>Section: </span>
                    {sec?.name}
                  </p>
                  <p className="title">
                    <span>Instructions: </span>
                    {sec?.instructions}
                  </p>

                  <AssessmentQuestions questions={sec?.questions} index={j} optIsObj={true} />
                </div>
              </div>
            );
          })}
        </div>
      </FullModal>
    </PreviewRoot>
  );
};
