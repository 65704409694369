import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const completedStatus = {
  color: '#36CA8B',
  background: 'rgba(54, 202, 139, 0.1)',
  border: '1px solid #36CA8B',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  borderRadius: ' 20px',
};
export const NotStartedStatus = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(153, 159, 163, 0.1)',
  border: '1px solid #999FA3',
  borderRadius: ' 20px',
};

export const InProgressStatus = {
  color: 'var(--black)',
  width: 'max-content',
  padding: '0.6rem 1.4rem',
  background: 'rgba(240, 168, 0, 0.1)',
  border: '1px solid #F0A800',
  borderRadius: ' 20px',
};
export const StatusType = styled(Flex)`
  ${({ status }) => (status === 'completed' || status === 'approved' ? completedStatus : null)}
  ${({ status }) => (status === 'scheduled' || status === 'in progress' ? InProgressStatus : null)}
  ${({ status }) => (status === 'overdue' || status === 'not started' ? NotStartedStatus : null)}
`;

export const ReviewReportStatus = ({ status }) => {
  return (
    <StatusType className="status" status={status}>
      <Text textTransform={'capitalize'}>{status}</Text>
    </StatusType>
  );
};
