import { useForm } from 'react-hook-form';
import { Box, Flex, FormLabel, Heading, Image, Textarea } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { CustomButton } from 'components';

import closeIcon from '../../../../../assets/icons/close.svg';
import { useUpdateLeaveApproval } from '../hooks/useUpdateLeaveApproval';

export function RejectLeave({ closeModal, leaveId, refetch, refetchAllLeaveRequest }) {
  const { loading, onUpdateLeave } = useUpdateLeaveApproval(refetch, refetchAllLeaveRequest);

  const { register, handleSubmit } = useForm({
    defaultValues: { reason: '' },
  });

  async function onSubmit(data) {
    await onUpdateLeave(leaveId, 'deny', data.reason);
    closeModal();
  }
  return (
    <Root>
      <Flex justifyContent={'space-between'}>
        <Heading fontSize={'2.4rem'}>Reject Approval</Heading>
        <Image src={closeIcon} onClick={closeModal} cursor={'pointer'} data-testid={'closeModal'} />
      </Flex>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box gap={'10px'}>
          <FormLabel
            htmlFor="reason"
            color={'var(--grey-500)'}
            fontSize={'14px'}
            fontWeight={'500s'}>
            Reason for Approval Rejection
          </FormLabel>
          <Textarea
            backgroundColor={'var(--grey-100)'}
            id="reason"
            {...register('reason', { required: true })}
            fontSize={'14px'}
            placeholder={'Add Reason'}
            rows={'5'}
          />
        </Box>

        <Flex justifyContent={'flex-end'} marginTop={'3.2rem'}>
          <CustomButton
            variant={'red'}
            name={'Reject Request'}
            h={'4.8rem'}
            type={'submit'}
            mW={'14.8rem'}
            loading={loading}
          />
        </Flex>
      </form>
    </Root>
  );
}

const Root = styled.div`
  width: 300px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: 500px) {
    width: 375px;
  }
  @media screen and (min-width: 600px) {
    width: 450px;
  }
  @media screen and (min-width: 700px) {
    width: 560px;
  }
`;
