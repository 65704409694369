import dustbin from 'assets/icons/Delete-red.svg';
import edit from 'assets/icons/edit-blue-sm.svg';
import fileIcon from 'assets/icons/file.svg';
import radio from 'assets/icons/radio.svg';
import sectionImage from 'assets/icons/sectionimage.svg';
import mchoice from 'assets/icons/square checkbox solid.svg';
import addsection from 'assets/icons/uim_window-section.svg';

const icons = {
  multipleChoice: mchoice,
  radio: radio,
};

export { addsection, dustbin, edit, fileIcon, icons, sectionImage };
