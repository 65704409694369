import { format, parseISO } from 'date-fns';

export function formatDateLabel(date) {
  const dateLabel = new Date(date).toLocaleDateString('en-uk', {
    day: `2-digit`,
    month: 'short',
    year: 'numeric',
  });
  return dateLabel;
}

export const formatDate = (value) => {
  if (value) {
    return format(parseISO(value), 'MMMM dd');
  } else {
    return null;
  }
};
