import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { UserImage } from 'pages/admin/employee/tableConfig';
import { FETCH_ALL_LEAVE_REQUEST } from 'query';
import { createActionHandler } from 'utils';

import { Pagination, Search, SimpleModal, TableComponent } from 'components';
import { Status } from 'components/tables/TableConfig';

import { ApprovePrompt, DenyPrompt, EditLeaveRequest, ForceDenyPrompt } from './component';
import { columns } from './data';
import { RequestHook } from './hooks';
import { RequestRoot } from './styles';

const dropdown = {
  view: 'View',
  edit: 'Edit',
  approve: 'Approve',
  deny: 'Deny',
};
import { durationDisplay, formatDateRange } from './helper';

const RequestPage = () => {
  let navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [requestData, setData] = React.useState([]);
  const [searchString, setSearchString] = React.useState('');

  const { setModalState, denyAction, editData, showModal, modalType, leaveId } = RequestHook();

  const filter = {};

  const {
    data: allRequest,
    loading: loadingAll,
    refetch,
  } = useQuery(FETCH_ALL_LEAVE_REQUEST, {
    variables: {
      filter: searchString ? { ...filter, name: searchString } : { ...filter },
      page: page,
    },
  });

  const totalPages = allRequest?.fetchLeaveRequests?.pageCount || 1;

  React.useEffect(() => {
    const fetchedData = allRequest?.fetchLeaveRequests.data;
    setData(fetchedData);
  }, [allRequest]);

  const viewUser = async ({ id }) => {
    navigate({ pathname: `request/${id}` });
  };

  const editAction = async ({ id }) => {
    const singleL = requestData?.find((leave) => {
      return leave?._id === id;
    });
    setModalState({ showModal: true, modalType: 'edit', editData: singleL, leaveId: id });
  };

  const approveAction = ({ id }) => {
    setModalState({ showModal: true, modalType: 'approve', leaveId: id });
  };

  const actionOnData = createActionHandler({
    viewUser,
    approveAction,
    denyAction,
    editAction,
  });

  const tableData = requestData?.map(
    ({
      employeeId,
      leaveTypeId,
      description,
      leaveStatus,
      startDate,
      endDate,
      count,
      imageUrl,
      durationType,
    }) => {
      const { firstName, lastName } = employeeId;
      const name = `${firstName} ${lastName}`;
      const duration = durationType === null ? '' : durationType;
      return {
        col1: UserImage({ name, imageUrl, firstName, lastName }),
        col2: leaveTypeId?.name || 'N/A',
        col3: description || 'N/A',
        col4: formatDateRange({ startDate, endDate }) || 'N/A',
        col5: durationDisplay({ count, duration }) || '',
        col6: Status({ status: leaveStatus }) || '',
      };
    }
  );

  const keys = {
    Status: 'leaveStatus',
  };

  const handleMultiSortBy = (column, desc = false) => {
    setSortBy(column + desc);
    const key = keys[column];
    const sortedData = requestData?.map((e) => {
      return {
        ...e,
        leaveStatus: e?.leaveStatus,
      };
    });
    if (key) {
      sortedData.sort((a, b) => {
        return desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
      });
      setData(sortedData);
    }
  };

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  return (
    <RequestRoot data-testid="leave-request">
      <Search
        placeholder={'Search Employee'}
        value={search}
        setPage={setPage}
        setSearchString={setSearchString}
        onChange={(e) => handleSearchChange(e.target.value)}
      />
      <div className="table--root">
        <TableComponent
          noSort
          include={'Status'}
          noCheckbox
          key={sortBy}
          loading={loadingAll}
          columns={columns}
          data={tableData}
          dropdownActions={dropdown}
          actionOnData={actionOnData}
          handleMultiSortBy={handleMultiSortBy}
          empty={requestData?.length === 0}
          ids={requestData?.map((e) => e._id)}
        />
        <Box mt="1rem">
          {+totalPages && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
        </Box>
      </div>
      <SimpleModal
        setShowModal={() => setModalState({ showModal: false })}
        showModal={showModal}
        padding="2.4rem"
        width={modalType === 'edit' ? '' : '75rem'}>
        {modalType === 'approve' && (
          <ApprovePrompt
            cancel={setModalState}
            leaveId={leaveId}
            force={modalType === 'force-approve' ? true : false}
            refetch={refetch}
          />
        )}
        {modalType === 'deny' && (
          <DenyPrompt cancel={setModalState} leaveId={leaveId} refetch={refetch} />
        )}
        {modalType === 'force_deny' && (
          <ForceDenyPrompt cancel={setModalState} leaveId={leaveId} refetch={refetch} />
        )}
        {modalType === 'edit' && (
          <>
            <EditLeaveRequest
              cancel={setModalState}
              editData={editData}
              leaveId={leaveId}
              refetch={refetch}
            />
          </>
        )}
      </SimpleModal>
    </RequestRoot>
  );
};

export { RequestPage };
