import { gql } from '@apollo/client';

export const ADD_EMPLOYEE = gql`
  mutation addNewEmployee($data: AddNewEmployeeInput!) {
    addNewEmployee(data: $data) {
      status
      message
      data {
        _id
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const FETCH_ALL_EMPLOYEES = gql`
  query fetchUsersFiltered($filters: UserFilters, $page: Int) {
    fetchUsersFiltered(filters: $filters, page: $page) {
      status
      message
      hasNextPage
      pageCount
      totalCount
      data {
        _id
        firstName
        lastName
        status
        imageUrl
        email
        phoneNumber
        team {
          _id
          name
        }
        isDefaultAdmin
        employmentInfo {
          hireDate
        }
        department {
          name
          designation {
            name
          }
        }
      }
    }
  }
`;

export const RETRIEVE_BULK_EMPLOYEES = gql`
  query retrieveBulkEmployees($employeeIds: [ObjectID!]) {
    retrieveBulkEmployees(employeeIds: $employeeIds) {
      status
      message
      data {
        _id
        firstName
        lastName
        imageUrl
        lineManager {
          firstName
          lastName
        }
      }
    }
  }
`;
