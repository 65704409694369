import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ButtonBlue from 'assets/icons/addButtonBlue.svg';
import { useToastify } from 'hooks/useToastify';
import { DELETE_INTERVIEW, FETCH_INTERVIEWS, SCHEDULE_INTERVIEW } from 'query/admin/candidates';

import { EmptyState, SpinnerComponent } from 'components';

import { ScheduleInterview } from '../../Modals/ScheduleInterviewModal';
import { RenderInterview } from '../../RenderInterview';
import { HeadingText } from '../CandidateInfoComponents';

const isTesting = process.env.NODE_ENV === 'test';

export const CandidateInterview = ({ user }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [state, setState] = React.useState(null);
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const { errorToast, successToast } = useToastify();

  let userId;

  if (isTesting) {
    userId = 'mockId';
  } else {
    userId = user?._id;
  }

  const [scheduleInterview, { loading }] = useMutation(SCHEDULE_INTERVIEW);
  const [removeInterview, { loading: interviewLoading }] = useMutation(DELETE_INTERVIEW);
  const {
    data,
    loading: fetchInterviewLoading,
    refetch,
  } = useQuery(FETCH_INTERVIEWS, {
    variables: {
      filter: {
        applicationId: userId,
      },
    },
  });

  const interviewData = data?.fetchInterview?.data;

  React.useEffect(() => {
    watch('type')?.value === 'Physical' ? setState('Physical') : setState('Online');
  }, [watch('type')]);

  const onSubmit = async (data) => {
    const interviewsArea = data?.interviewers?.map((value) => {
      return value.value;
    });
    const dataToSend = {
      ...data,
      interviewers: [...interviewsArea],
      applicationId: user?._id,
      type: data.type.label,
    };

    try {
      const response = await scheduleInterview({ variables: { data: dataToSend } });
      response?.data?.scheduleInterview?.status === 201
        ? successToast(response?.data?.scheduleInterview?.message)
        : errorToast(response?.data?.scheduleInterview?.message);
      setOpenModal(false);
      refetch();
    } catch (error) {
      errorToast('Error occured');
    } finally {
      reset();
    }
  };

  const onDeleteInterview = async (id) => {
    try {
      const response = await removeInterview({
        variables: {
          interviewId: id,
        },
      });
      response?.data?.removeInterview?.status === 200
        ? successToast(response?.data?.removeInterview?.message)
        : errorToast(response?.data?.removeInterview?.message);
      setShow(false);
      refetch();
    } catch (error) {
      errorToast(error);
    }
  };
  function toggleModal() {
    setOpenModal(!openModal);
  }

  return (
    <InterviewWrapper>
      <Flex justify={'space-between'} alignItems={'center'}>
        <HeadingText headingText={'Interview List'} />
        <Flex
          className="add-schedule"
          gap={'8px'}
          onClick={() => setOpenModal(true)}
          data-testid="add-schedule-btn">
          <img src={ButtonBlue} />
          <HeadingText headingText={'Add Schedule Interview'} />
        </Flex>
      </Flex>
      <hr />
      {fetchInterviewLoading ? (
        <Grid minH="20rem" placeContent="center" mt="20rem">
          <SpinnerComponent data-testid="loading" />
        </Grid>
      ) : (
        <div className="interviews">
          {interviewData.length > 0 ? (
            <RenderInterview
              interviewData={interviewData}
              applicantInfo={user}
              loading={interviewLoading}
              onDelete={onDeleteInterview}
              refetch={refetch}
              show={show}
              setShow={setShow}
            />
          ) : (
            <EmptyState message={'No Interviews Scheduled '} />
          )}
        </div>
      )}
      <ScheduleInterview
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={user?._id}
        register={register}
        control={control}
        errors={errors}
        state={state}
        loading={loading}
        handleSubmit={handleSubmit(onSubmit)}
        toggleModal={toggleModal}
      />
    </InterviewWrapper>
  );
};

const InterviewWrapper = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 16px;

  hr {
    margin-top: 12px;
  }

  .interviews {
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    margin-top: 12px;
  }

  .add-schedule {
    cursor: pointer;
  }
`;
