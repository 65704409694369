import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

export const Tabs = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { doNotTrackUrl } = props;

  if (!doNotTrackUrl) {
    React.useLayoutEffect(() => {
      if (location.hash) {
        const stripped = location.hash.replace('#', '');
        props.setSelected(stripped);
      } else {
        navigate(location.pathname + `#${props.selected}`);
      }
    }, [location]);

    React.useEffect(() => {
      navigate(location.pathname + `#${props.selected}`);
    }, [props.selected]);
  }

  function setSelected(tab) {
    props.setSelected(tab);

    if (props.closeModal) props.setCloseModal(false);
  }

  return (
    <ButtonGroup className={'buttons'} style={props.width}>
      {props.tabs.map(({ displayName, value }) => (
        <ButtonToggle
          className={`tab-btn-toggle ${props.selected === value ? 'active' : ''}`}
          active={props.selected === value}
          style={props.style}
          onClick={() => setSelected(value)}
          key={value}
          withLine={props.withLine}>
          {displayName}
        </ButtonToggle>
      ))}
      <div className="line" style={props.lineStyle}></div>
    </ButtonGroup>
  );
};

const ButtonGroup = styled.div`
  position: relative;
  display: flex;

  .line {
    position: absolute;
    margin: auto;
    bottom: 0;
    background: #f2f2f2;
    border-radius: 10px;
  }
`;

const Button = styled.button`
  box-sizing: content-box;
  line-height: 19px;
  white-space: nowrap;
  color: var(--grey-500);
  border: none;
  cursor: pointer;
  background: none;
  padding: 0.8rem 1rem 1.6rem;
  border-radius: 1px;
  border-bottom: 1px solid transparent;
  z-index: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  :hover {
    border-bottom: 1px solid #3e7eee;
    color: #3e7eee;
  }

  &:not(:last-child) {
    margin-right: 30px;
  }
`;

const ButtonToggle = styled(Button)`
  ${({ active, withLine }) =>
    active &&
    `
    &.active
    { 
      border-bottom: 1px solid #3E7EEE;
      color: ${withLine ? '#363740' : '#3E7EEE'};
    }
 `}
`;
