import React from 'react';
import { useForm } from 'react-hook-form';

const EmployeeEditFormContext = React.createContext();

function EmployeeEditFormProvider(props) {
  const { employeeData, children, options = {} } = props;

  const formMethods = useForm({
    defaultValues: React.useMemo(() => {
      return employeeData;
    }, [props]),
    ...options,
  });

  React.useEffect(() => {
    formMethods.reset(employeeData);
  }, [employeeData]);

  return (
    <EmployeeEditFormContext.Provider value={formMethods}>
      {children}
    </EmployeeEditFormContext.Provider>
  );
}

function useEmployeeEditForm() {
  const context = React.useContext(EmployeeEditFormContext);
  if (!context) throw Error('useEmployeeEditForm must be wrapped in EmployeeEditFormProvider');
  return context;
}

export { EmployeeEditFormContext, EmployeeEditFormProvider, useEmployeeEditForm };
