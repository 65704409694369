import { formatDate } from 'utils';

function getAllAge(data) {
  let arr = [];
  for (let report of data) {
    Object.entries(report).forEach(([, value]) => {
      if (typeof value === 'object') {
        for (let person of value) {
          arr.push(person.age);
        }
      }
    });
  }
  return arr;
}

function groupingAges(data) {
  let obj = {
    '<20': 0,
    '20-30': 0,
    '31-40': 0,
    '40-50': 0,
    '50-70': 0,
    '>70': 0,
  };

  for (let i = 0; i < data.length; i++) {
    if (data[i] < 20) {
      obj['<20']++;
    } else if (data[i] >= 21 && data[i] <= 30) {
      obj['20-30']++;
    } else if (data[i] >= 31 && data[i] <= 40) {
      obj['31-40']++;
    } else if (data[i] >= 41 && data[i] <= 50) {
      obj['40-50']++;
    } else if (data[i] >= 51 && data[i] <= 70) {
      obj['50-70']++;
    } else if (data[i] >= 71) {
      obj['>70']++;
    }
  }

  return obj || [];
}

function ageFrequency(data) {
  let arr = [];
  Object.entries(data).forEach(([, value]) => {
    arr.push(value);
  });
  return arr;
}

export function getAgeFrequency(data) {
  if (data === null || data === undefined) return;
  return ageFrequency(groupingAges(getAllAge(data)));
}
function extractReport(data) {
  let arr = [];
  for (let reports of data) {
    Object.entries(reports).forEach(([, value]) => {
      if (typeof value === 'object') {
        for (let report of value) {
          arr.push(report);
        }
      }
    });
  }
  return arr;
}

function groupingHiringPeroid(data) {
  let obj = {};
  for (let item of data) {
    if (!Object.keys(obj).includes(`${getMonthName(item.month)}${formatYear(item.year)}`)) {
      obj[`${getMonthName(item.month)}${formatYear(item.year)}`] = [item];
    } else {
      obj[`${getMonthName(item.month)}${formatYear(item.year)}`].push(item);
    }
  }
  return obj;
}

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'short',
  });
}

function formatYear(year) {
  let s = `${year}`;
  let t = `${s[2]}${s[3]}`;
  return t;
}

function getStatus(data) {
  let obj = {};

  Object.entries(data).forEach(([key, value]) => {
    if (!Object.keys(obj).includes(key)) {
      obj[key] = { active: 0, exited: 0 };
      for (let item of value) {
        if (item.status === 'active') {
          obj[key]['active']++;
        } else {
          obj[key]['exited']++;
        }
      }
    }
  });

  return obj;
}

function label(data) {
  let obj = {
    label: [],
    active: [],
    exited: [],
  };

  Object.entries(data).forEach(([key, value]) => {
    obj['label'].push(key);
    obj['active'].push(value.active);
    obj.exited.push(value.exited);
  });

  return obj;
}

export function hiringAndExits(data) {
  if (data === null || data === undefined) return;

  return label(getStatus(groupingHiringPeroid(extractReport(data))));
}

// Department Profile

export function getDeptFreq(data) {
  let obj = {};
  if (data === null || data === undefined) {
    return obj;
  }
  for (let reports of data) {
    obj[reports.report[0].department] = reports.count;
  }

  return obj;
}

function getDeptLabels(data) {
  let arr = [];
  if (data === null || data === undefined) {
    return arr;
  }
  Object.entries(data).forEach(([key]) => {
    arr.push(key);
  });
  return arr;
}

export function getLabelColors(data) {
  const colors = [
    '#A296F1',
    '#7CA1F9',
    '#80F571',
    '#F5F071',
    '#EC5757',
    '#71F5EC',
    '#7CC5F9',
    '#DE96F1',
    '#F196D1',
    '#F19697',
    '#96F1EB',
    '#F1D496',
  ];
  const obj = {};
  const labels = getDeptLabels(getDeptFreq(data));
  for (let i = 0; i < labels.length; i++) {
    obj[labels[i]] = colors[i];
  }

  return obj;
}

export function deptLabelColors(data) {
  let arr = [];
  if (data === null || data === undefined) return arr;
  let count = 0;
  Object.entries(getLabelColors(data)).forEach(([key, value]) => {
    let obj = {};
    obj['_id'] = ++count;
    obj['label'] = key;
    obj['color'] = value;

    arr.push(obj);
  });

  return arr;
}

// Head Count functions

function getReport(data) {
  let arr = [];
  for (let report of data) {
    Object.entries(report).forEach(([, value]) => {
      if (typeof value === 'object') {
        for (let person of value) {
          arr.push(person);
        }
      }
    });
  }
  return arr;
}

function groupingDate(data) {
  let obj = {};
  for (let item of data) {
    if (!Object.keys(obj).includes(`${getMonthNameLong(item.month)} '${formatYear(item.year)}`)) {
      obj[`${getMonthNameLong(item.month)} '${formatYear(item.year)}`] = [item];
    } else {
      obj[`${getMonthNameLong(item.month)} '${formatYear(item.year)}`].push(item);
    }
  }
  return obj;
}

function getMonthNameLong(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'long',
  });
}

function transform(data) {
  let arr = [];
  let count = 0;
  Object.entries(data).forEach(([key, value]) => {
    let obj = { _id: ++count, Month: key };
    for (let i = 0; i < value.length; i++) {
      if (!Object.keys(obj).includes(value[i].name)) {
        if (value[i].name === null || value[i].name === undefined) continue;
        obj[value[i].name] = 1;
      } else {
        obj[value[i].name]++;
      }
    }
    arr.push(obj);
  });
  return arr;
}

function getColumnNames(data) {
  let set = new Set();

  for (let month of data) {
    Object.entries(month).forEach(([key]) => {
      set.add(key);
    });
  }
  return Array.from(set);
}

function getColumnNamesObj(data) {
  let arr = [];
  for (let dept of data) {
    let obj = { Header: dept, accessor: dept.toLowerCase() };
    arr.push(obj);
  }
  //arr.push({ Header: 'Action', accessor: 'action' });
  arr.shift();
  return arr;
}

function getHeadCount(data) {
  return transform(groupingDate(getReport(data)));
}

export function getHeadCountColumns(data) {
  if (data === null || data === undefined) return [];
  return getColumnNamesObj(getColumnNames(transform(groupingDate(getReport(data)))));
}

function getDataLower(data) {
  let arr = [];
  let obj = {};
  for (let item of data) {
    Object.entries(item).forEach(([key, value]) => {
      let key1 = key.toLowerCase();
      obj[key1] = value;
    });
    arr.push(obj);
    obj = {};
  }

  return arr;
}

function fillData(data) {
  let column = getColumnNames(getHeadCount(data));
  let columnData = getHeadCount(data);

  for (let i = 0; i < column.length; i++) {
    if (column[i] === '_id') continue;
    for (let item of columnData) {
      if (!Object.keys(item).includes(column[i])) {
        item[column[i]] = 0;
      }
    }
  }

  return columnData;
}

export function getHeadCountData(data) {
  if (data === null || data === undefined) return [];
  return getDataLower(fillData(data));
}

// csv
export function modifyAgeProfileCsv(frequency) {
  const labels = ['<20 y/o', '20-30', '31-40', '40-50', '50-70', '>70 y/o'];
  let arr = [];
  if (frequency === undefined || frequency.length === 0) return arr;
  for (let i = 0; i < labels.length; i++) {
    let obj = { 'age-range': labels[i], frequeny: frequency[i] };
    arr.push(obj);
  }
  return arr;
}

export function modifyDeptCsv(data) {
  let arr = [];
  Object.entries(data).forEach(([key, value]) => {
    let obj = { department: key, frequency: value };
    arr.push(obj);
  });
  return arr;
}

export function modifyHeadCountCsv(data) {
  let arr = [];
  for (let item of data) {
    let obj = {};
    Object.entries(item).forEach(([key, value]) => {
      if (key !== '_id') {
        obj[key] = value;
      }
    });
    arr.push(obj);
  }
  return arr;
}

export function modifyHiringExitCsv(data) {
  let arr = [];
  for (let i = 0; i < data.label['length']; i++) {
    let obj = {
      month: data.label[i],
      additions: data.active[i],
      exited: data.exited[i],
    };
    arr.push(obj);
  }
  return arr;
}

export function modifyAssetCsv(data) {
  let arr = [];
  if (data === null || data === undefined) return arr;
  for (let item of data) {
    let obj = {};
    Object.entries(item).forEach(([key, value]) => {
      if (key !== '_id') {
        if (key === 'assignedTo') {
          obj[key] = `${value?.firstName || ''} ${value?.lastName || ''}`;
        } else if (key === 'dateAssigned') {
          obj[key] = formatDate(value);
        } else {
          obj[key] = value || ' ';
        }
      }
    });
    arr.push(obj);
  }
  return arr;
}

export function modifyLearningCsv(data) {
  let arr = [];
  for (let item of data) {
    let obj = {};
    obj['Training Name'] = item?.name || '';
    obj['Employee Enrolled'] = `${item?.employee?.firstName || ''} ${
      item?.employee?.firstName || ''
    }`;
    if (item.employees !== null) {
      for (let x of item.employees) {
        obj['Employee Enrolled'] = `${obj['Employee Enrolled']}, ${x.firstName} ${x.lastName}`;
      }
    }
    obj['Type'] = `${item?.isShared ? 'shared' : 'individual'}`;
    obj['Due Date'] = item?.schedule?.endDate || '';
    obj['Completion Date'] = item?.schedule?.startDate || '';
    obj['cost'] = item?.cost || '';
    obj['status'] = item?.status || '';
    arr.push(obj);
  }
  return arr;
}

// csv main function
export function getCsv(func, data) {
  if (data === undefined) return '';
  const json = func(data);
  const fields = Object.keys(json[0]);
  const replacer = function (key, value) {
    return value === null ? '' : value;
  };
  let csv = json.map(function (row) {
    return fields
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(',');
  });
  csv.unshift(fields.join(',')); // add header column
  csv = csv.join('\r\n');
  return csv;
}

// export csv
export function exportCsv(blob, name) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.csv`);

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
}
