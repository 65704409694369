import React from 'react';
import styled from '@emotion/styled';

export const BaseLayout = ({ children }) => {
  return (
    <BaseRoot>
      <div className="content">
        <div className="header">{children[0]}</div>
        <div className="content__body">{children[1]}</div>
      </div>
    </BaseRoot>
  );
};

const BaseRoot = styled.div`
  width: 100%;
  height: calc(100vh - 8.4rem);
  padding: 24px;

  .text-16-400 {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #23273b;
  }

  .sub-heading {
    margin-top: 0.8rem;
  }

  .content {
    background: var(--white);
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    height: calc(100vh - 8.4rem);
    padding: 0rem 2.4rem 3rem;
    overflow-y: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .header {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #23273b;
      display: flex;
      flex-direction: column;
      background: var(--white);
      position: sticky;
      top: 0;
      margin-bottom: 4rem;
      padding: 2.4rem 0 0.8rem 0;
      z-index: 1;
      gap: 2.4rem;

      @media (min-width: 820px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__body {
    }
  }
`;
