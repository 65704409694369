import React from 'react';
import styled from '@emotion/styled';

import { CustomButton } from 'components';

import Logo from '../../assets/images/enyataLogo.svg';

export const FallBackComponent = ({ resetErrorBoundary }) => {
  return (
    <FallBackWrapper>
      <img src={Logo} alt="Logo" />
      <h1>Something went wrong</h1>
      <CustomButton
        name={'Try again'}
        onClick={resetErrorBoundary}
        variant="black"
        mW="9.5rem"
        h="4.3rem"
      />
    </FallBackWrapper>
  );
};

const FallBackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  height: 90vh;
`;
