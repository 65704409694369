import { gql } from '@apollo/client';

export const FETCH_EMPLOYEE_TRAINING = gql`
  query fetchActiveOrUpcomingTraining($filter: TrainingStatusFilter) {
    fetchActiveOrUpcomingTraining(filter: $filter) {
      status
      message
      data {
        _id
        name
        schedule {
          startDate
          endDate
        }
        status
      }
    }
  }
`;

export const FETCH_SINGLE_TRAINING = gql`
  query fetchSingleTraining($filter: SingleTrainingFilter) {
    fetchSingleTraining(filter: $filter) {
      status
      message
      data {
        schedule {
          endDate
        }
        description
        name
        completionDate
        cost
        results
        attachedFile
        userAttachedFile
        note
      }
    }
  }
`;

export const CREATE_EMPLOYEE_TRAINING = gql`
  mutation createTraining($data: CreateTrainingInput!, $file: Upload) {
    createTraining(data: $data, file: $file) {
      status
      message
      data {
        _id
        name
        description
        details
        schedule {
          startDate
          endDate
        }
        repeatProgram {
          isRepeated
          repeatIn
          repeatType
        }
        status
        isShared
      }
    }
  }
`;

export const FETCH_COMPLETED_TRAINING = gql`
  query fetchCompletedTraining {
    fetchCompletedTraining {
      status
      message
      data {
        _id
        trainingId {
          _id
          name
          status
          schedule {
            startDate
            endDate
          }
        }
      }
    }
  }
`;

export const RECORD_COMPLETION = gql`
  mutation addLearningResponse($data: LearningInput!, $file: Upload) {
    addLearningResponse(data: $data, file: $file) {
      status
      message
      data {
        completionDate
        results
        attachedFile
        attachedLink
        cost
        note
        trainingId {
          _id
        }
      }
    }
  }
`;

export const REMOVE_EMPLOYEE_TRAINING = gql`
  mutation removeTraining($trainingId: ObjectID!) {
    removeTraining(trainingId: $trainingId) {
      status
      message
    }
  }
`;
