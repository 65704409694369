import React, { createRef, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { BsPlusLg } from 'react-icons/bs';
import { BsCheck2Circle } from 'react-icons/bs';
import { CgSpinner } from 'react-icons/cg';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import testCSV from '../../../assets/template/test_csv2.csv';
import { useToastify } from '../../../hooks/useToastify';
import { BULK_UPLOAD } from '../../../query';
import { CustomButton } from '../../shared';

const UploadWrapper = styled.div`
  border: 1px dashed #3e7eee;
  margin-top: 3.4rem;
  height: 16rem;
  border-radius: 0.6rem;
  cursor: pointer;
  padding: 2.4rem 0;
  p {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: -0.117188px;
    color: #333758;
    text-align: center;
  }
  .drop__zone {
    justify-content: center;
    align-items: center;
  }
`;

const EmployeeFileUpload = (props) => {
  const [files, setFiles] = useState([]);
  const [sentArray, setSentArray] = useState([]);
  let [index, setIndex] = useState(0);

  const [uploadExistingUsers, { loading }] = useMutation(BULK_UPLOAD);
  const { successToast, errorToast } = useToastify();

  async function sendData() {
    const file = files[index];

    const res = await uploadExistingUsers({
      variables: {
        file: file,
      },
    });

    const { data } = res;
    if (data && data.uploadExistingUsers.status === 200) {
      successToast(data.uploadExistingUsers.message);
      setSentArray((current) => [...current, true]);
    } else errorToast('Upload unsuccessful');

    if (index !== files?.length - 1) {
      setIndex(index++);
      await sendData();
    }
  }

  useEffect(() => {
    if (sentArray[files?.length - 1] === true) {
      setTimeout(() => props.closeModal(), 1000);
    }
  }, [index, sentArray]);

  const handleUploadCSV = async () => {
    try {
      await sendData();
    } catch (err) {
      errorToast(err.message);
    }
  };

  const handleOnDrop = (acceptedFiles) => {
    setFiles((prev) => {
      const myFiles = new Set(prev?.map((file) => file.name));
      const processedFiles = acceptedFiles?.filter((file) => !myFiles?.has(file.name));
      return [...prev, ...processedFiles];
    });
  };

  const handleOnRemove = (file) => {
    setFiles((prev) => {
      return prev.filter((f) => f.name !== file);
    });
  };

  const btnDisabled = files?.length === 0;

  const acceptableFileTypes = {
    'text/csv': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'application/vnd.ms-excel': [],
  };

  const dropzoneRef = createRef();

  return (
    <>
      <UploadWrapper data-testid={'employee-upload'}>
        <Dropzone
          ref={dropzoneRef}
          onDrop={(acceptedFiles) => handleOnDrop(acceptedFiles)}
          accept={acceptableFileTypes}
          data-testid={'ondrop'}
          className={'dropZone'}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <Flex {...getRootProps()} className="drop__zone">
                <input {...getInputProps()} data-testid={'drop-zone'} />
                <div>
                  <Flex justifyContent={'center'}>{<BsPlusLg color={'var(--primary)'} />}</Flex>
                  <Text>Drag and drop CSV file here</Text>
                  <Text>or</Text>
                  <Text textDecoration={'underline'}>Browse files</Text>
                </div>
              </Flex>
            </section>
          )}
        </Dropzone>
      </UploadWrapper>
      <Box h={'12rem'} mt={'2.4rem'}>
        <DownLoadTemplate />
      </Box>
      <Box>
        <DisplayFile
          loading={loading}
          data-testId="remove-file"
          files={files}
          sentArray={sentArray}
          index={index}
          sliceFile={handleOnRemove}
        />
      </Box>
      <Flex width={'100%'} justifyContent={'center'}>
        <CustomButton
          name={'Create Account'}
          variant={btnDisabled ? 'grey--button' : 'primary'}
          disabled={btnDisabled}
          onClick={() => handleUploadCSV()}
          mW={'21.5rem'}
        />
      </Flex>
    </>
  );
};

const FileItem = styled(Flex)`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 1.2rem 1.6rem;
  max-width: 28.5rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  .icon.loading {
    animation: spin 500ms linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .file-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const DisplayFile = (props) => {
  return (
    <>
      {props?.files?.map((file, index) => (
        <FileItem key={file.path} className={'display__file'} data-testid="display-file">
          <Text className="file-text">{file.name}</Text>
          {props.loading && index === props.index ? (
            <div className="icon loading">
              <CgSpinner size={20} />
            </div>
          ) : props.sentArray[index] === true ? (
            <BsCheck2Circle color="green" size={20} />
          ) : (
            <Flex
              data-testid="close-file"
              cursor={'pointer'}
              p="2px"
              onClick={() => props.sliceFile(file.name)}>
              <IoClose cursor={'pointer'} />
            </Flex>
          )}
        </FileItem>
      ))}
    </>
  );
};

const DTemplate = styled(Box)`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 1.2rem 1.6rem;
  max-width: 25.5rem;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  .download__template {
    color: var(--blue);
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const DownLoadTemplate = () => {
  return (
    <DTemplate>
      <Link className="download__template" to={testCSV} target="_blank" download>
        Download Templates
      </Link>
    </DTemplate>
  );
};

export { DisplayFile, DownLoadTemplate, EmployeeFileUpload };
