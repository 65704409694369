import { EDIT_EMPLOYEE } from 'query';

export const employeeDefault = [
  {
    _id: '637f5230c4189bfab704cdc2',
    firstName: 'kwabena',
    lastName: 'kwabena',
    status: 'active',
    gender: 'male',
    workingFormat: 'remote',
    email: 'kwabenaagyapong@enyata.com',
    imageUrl:
      'https://photow-profile-images.s3.us-west-2.amazonaws.com/1670576952152Uzli2BVQwElDnYF4EpTVI.svg',
    phoneNumber: '+233323567809',
    maritalStatus: 'single',
    team: 'Code for fun',
    staffId: 'eny/001',
    bloodGroup: '',
    bloodType: '',
    isDisabled: 'no',
    notableAilment: null,
    disabilityType: null,
    dob: '2022-10-30T00:00:00.000Z',
    location: 'Accra',
    birthdayCountdown: 269,
    workAnniversary: 292,
    employmentInfo: {
      employmentType: 'full time',
      compensationTypes: [''],
      hireDate: '2022-11-22T00:00:00.000Z',
      monthlyPayRate: 'US$10000',
      workEmail: 'kwabenaagyapong@enyata.com',
    },
    address: [{ address: '', country: null, state: null, district: null }],
    department: {
      _id: '637f50d9c4189bfab704cd88',
      name: 'Engineering',
    },
    designation: { name: 'Frontend', _id: '43920hjeruitqenm4n289' },
    bankAccount: {
      paymentType: 'weekly',
      bankName: 'Stanbic',
      accountNo: '14567890987654',
      pensionAccountNo: '8973127314321',
      pensionFundAdministrator: 'ssnit',
      accountName: 'Sam Johnsom',
      accountType: 'students',
    },
    emergencyContact: {
      fullName: 'Sam Johnson',
      relationship: 'Brother',
      email: null,
      country: 'Ghana',
      state: 'Greater Accra',
      city: 'Medina Estates',
      address: 'new legon',
      phoneNumber: '2335593830',
      occupation: null,
    },
  },
];

export const editMocks = {
  request: {
    query: EDIT_EMPLOYEE,
    variables: { id: employeeDefault?._id, data: employeeDefault[0] },
  },
  result: {
    data: {
      editUser: {
        data: {},
        status: 200,
        message: 'Employee Edited successfully',
      },
    },
  },
};

export const createData = {
  address: [
    { address: 'new legon', district: 'ayawaso west', country: 'ghana', state: 'greater accra' },
    { address: '', district: '', country: '', state: '' },
  ],
  bankAccount: {
    accountName: '',
    accountNo: '',
    accountType: { value: 'students', label: 'Students' },
    bankName: 'Stanbic',
    paymentType: { value: 'monthly', label: 'Monthly' },
    pensionAccountNo: '874234218347281',
    pensionFundAdministrator: 'ssnit',
  },
  bloodGroup: { label: 'AB', value: 'AB' },
  bloodType: { label: 'AB', value: 'AB' },
  department: { label: 'Engineer', value: '827903rnhwjdnuiyewqw' },
  designation: { label: 'frontend', value: 'nbm72i6382beh183711' },
  disabilityType: '',
  dob: '2020-02-12',
  email: 'salma@enyata.com',
  emergencyContact: {
    address: '',
    city: { value: 'accra', label: 'accra' },
    country: { value: 'ghana', label: 'ghana' },
    fullName: '',
    occupation: '',
    phoneNumber: '',
    relationship: '',
    state: { value: 'greater accra', label: 'greater accra' },
  },
  employmentInfo: {
    employeeBenefits: [],
    compensationTypes: { value: 'salary', label: 'Salary' },
    employmentType: { label: 'Part time', value: 'part time' },
    hireDate: '2023-01-04',
    monthlyPayRate: '10000',
    workEmail: 'salma@enyata.com',
  },
  firstName: 'sam',
  lastName: 'sam',
  gender: { value: 'male', label: 'male' },
  maritalStatus: { value: 'Divorced', label: 'Divorced' },
  workLocation: { value: '63b6d3dc5ba02ed9878f6d45', label: 'kuku hill' },
  isDisabled: { value: 'no', label: 'no' },
  notableAilment: '',
  location: 'Toronto',
  phoneNumber: '7642369824',
  staffId: 'eny/409',
  team: 'Dreame team',
};
