import { gql } from '@apollo/client';

export const FETCH_ASSESSMENT_TITLES = gql`
  query fetchTitles($filter: AssessmentFilters) {
    fetchTitles(filter: $filter) {
      status
      message
      data {
        _id {
          id
          title
        }
      }
    }
  }
`;

export const FETCH_ASSESSMENT_BY_TITLE = gql`
  query fetchByTitle($title: String!) {
    fetchByTitle(title: $title) {
      status
      message
      data {
        _id
        title
        duration
        deadline
        sections {
          name
          instructions
          questions {
            question
            questionType
            options
            correctAnswers
            points
            attachedFiles
          }
        }
      }
    }
  }
`;

export const CREATE_ASSESSMENT = gql`
  mutation createAssessment($data: AssessmentInput!) {
    createAssessment(data: $data) {
      status
      message
      data {
        _id
        title
        duration
        deadline
        sections {
          name
          instructions
          questions {
            question
            questionType
            options
            correctAnswers
            points
            attachedFiles
          }
        }
      }
    }
  }
`;

export const DELETE_ASSESSMENT = gql`
  mutation deleteAssessment($id: ObjectID!) {
    deleteAssessment(id: $id) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const EDIT_ASSESSMENT = gql`
  mutation editAssessment($filters: AssessmentFilters, $data: AssessmentInput) {
    editAssessment(filters: $filters, data: $data) {
      status
      message
      data {
        _id
        title
        duration
        deadline
        sections {
          name
          instructions
          questions {
            attachedFiles
            question
            questionType
            options
            correctAnswers
            points
          }
        }
      }
    }
  }
`;
